import React, { useCallback, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  IconButton,
  Flex,
  Heading,
  Text,
  Box,
  Skeleton,
} from '@chakra-ui/react';

interface Props {
  title: string;
  pagename?: string;
  goBackPage?: boolean;
  goBackLink?: string;
  loading?: boolean;
  childrenJustify?: string;
  fullSkeleton?: boolean;
}

const SectionHeader: React.FC<Props> = ({
  title,
  pagename,
  goBackPage,
  goBackLink,
  loading = false,
  children,
  childrenJustify,
  fullSkeleton = false,
}) => {
  const history = useHistory();

  useEffect(() => {
    document.title = `${title || '...'} | MyIPAC`;
  }, [title]);

  const handleGoBack = useCallback(() => {
    if (goBackLink) {
      history.push(goBackLink);
    } else {
      history.goBack();
    }
  }, [history, goBackLink]);

  return (
    <Flex
      flexDir={['column', 'row']}
      justifyContent={['flex-start', childrenJustify || 'flex-start']}
      mb={[30, 50]}
      alignItems={['center', 'flex-start']}
    >
      <Flex
        alignItems="center"
        w={childrenJustify && !loading ? 'auto' : 'full'}
        mb={[2, 0]}
      >
        {(goBackPage || goBackLink) && (
          <IconButton
            aria-label="Voltar"
            icon={<FiArrowLeft />}
            size="lg"
            borderRadius="md"
            mr={3}
            onClick={handleGoBack}
          />
        )}

        <Flex
          alignItems="flex-start"
          justifyContent="center"
          flexDir="column"
          mr={['auto', 0]}
          textAlign="left"
          width="full"
        >
          {!loading ? (
            <>
              <Heading as="h3" size="lg">
                {title}
              </Heading>
              <Text color="gray.500" fontSize="sm">
                {pagename}
              </Text>
            </>
          ) : (
            <>
              <Skeleton width={fullSkeleton ? '100%' : '50%'} height="36px" />
              {pagename && (
                <Skeleton
                  width={fullSkeleton ? '80%' : '30%'}
                  height="20px"
                  mt={1}
                />
              )}
            </>
          )}
        </Flex>
      </Flex>

      <Box>{children}</Box>
    </Flex>
  );
};

export default SectionHeader;
