import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  FiMail,
  FiFile,
  FiFolderPlus,
  FiLink,
  FiExternalLink,
  FiChevronDown,
} from 'react-icons/fi';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Image,
  Flex,
  InputGroup,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Checkbox,
  Heading,
  GridItem,
  FormControl,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import {
  EditorState,
  convertToRaw,
  Modifier,
  ContentState,
  CompositeDecorator,
  Editor as EditorOriginal,
  SelectionState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import filesize from 'filesize';
import htmlToDraft from 'html-to-draftjs';
import { uniqueId } from 'lodash';
import queryString from 'query-string';
import * as Yup from 'yup';

import {
  Pop as PopInterface,
  Activity,
  Demand,
  PopFile,
} from '~/modules/activityExecution/@types/activity';
import AttachedFiles, {
  NewFileDB,
} from '~/modules/activityExecution/components/AttachedFiles';
import FilesGrid, {
  FilesGridLocal,
  PopFileSelect,
} from '~/modules/activityExecution/components/AttachedFiles/FilesGrid';
import FileClassification, {
  IFile,
} from '~/modules/activityExecution/components/FileClassification';
import FileList from '~/modules/activityExecution/components/FileList';
import { FolderWithCrumbs } from '~/modules/activityExecution/components/NavigationFoldersDrive';
import Input from '~/shared/components/InputChakra';
import CheckboxChakra from '~/shared/components/InputChakra/CheckboxChakra';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';
import SelectChakra, {
  Option,
} from '~/shared/components/InputChakra/SelectChakra';
import SwitchChakra from '~/shared/components/InputChakra/SwitchChakra';
import TextareaChakra from '~/shared/components/InputChakra/Textarea';
import ListFiles from '~/shared/components/ListFiles';
import Loading from '~/shared/components/Loading';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import Pagination, { IPaginationProps } from '~/shared/components/Pagination';
import Search from '~/shared/components/Search';
import SectionHeader from '~/shared/components/SectionHeader';
import Upload from '~/shared/components/Upload';
import api from '~/shared/services/api';
import FileIconWithType, { getPreviewFile } from '~/utils/getFileIconWithType';
import getValidationErrors from '~/utils/getValidationErrors';

import { Container } from './styles';

export interface PopFilePaginate extends IPaginationProps {
  data: PopFile[];
}

interface Params {
  demandId?: string;
  activityId?: string;
}

interface LinkCustom {
  entityKey: string;
  contentState: ContentState;
  children: any;
}

interface DemandActivitiesList {
  id: number;
  empresa_id: number;
  status_id: number;
  nome: string;
  descricao: string;
  atividades: ActivityFile[];
}

interface ActivityFile extends Activity {
  pop_files: PopFileSelect[];
}

interface ActivityFilter {
  types: InfoActivity[];
  characters: InfoActivity[];
  responsibilities: Responsibility[];
  orders: number[];
  /* saved_files: PopFile[]; */
}

interface InfoActivity {
  id: string;
  nome: string;
  descricao: string;
  label: string;
  value: string;
}

interface Responsibility extends InfoActivity {
  ocupado: boolean;
}

const ActivityCreate: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { demandId, activityId } = useParams<Params>();

  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const pg = queryParams?.pg;
  const demId = demandId || queryParams?.dem;

  const goBackDemand = useCallback(() => {
    /*  const params = {};

    const paramPg = pg ? `pg=${pg}` : ``; */

    history.push({
      pathname: `/demandas/${demId}`,
      search: pg ? `pg=${pg}` : '',
    });
  }, [history, demId, pg]);

  const acceptedFormats = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/docx',
    'application/pdf',
    'text/plain',
    'application/msword',
    'application/x-zip-compressed',
    '.csv',
    '.doc',
    '.xls',
    '.xlsx',
    '.xlsm',
    '.xltm',
    '.zip',
    '.rar',
    '.7z',
    '.tar',
    'text/*',
    'image/*',
  ];

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formRef = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);
  const formRefSelectFile = useRef<FormHandles>(null);
  const refEditor = useRef<Editor>(null);

  const [loadingActivity, setLoadingActivity] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');

  // const [editorState, setEditorState] = useState<EditorState>();
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const [editorReference, setEditorReference] = useState<any>();
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);

  const [activity, setActivity] = useState<ActivityFile>({} as ActivityFile);
  const [activityFilter, setActivityFilter] = useState<ActivityFilter>();
  const [savedFile, setSavedFile] = useState<PopFilePaginate>(
    {} as PopFilePaginate,
  );

  // const [selectedFiles, setSelectedFiles] = useState<PopFile[]>([]);

  const [page, setPage] = useState(1);

  const [toggleAllResponsibility, setToggleAllResponsibility] = useState(
    !activityId,
  );

  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<FolderWithCrumbs | null>(
    null,
  );
  const [lastOrder, setLastOrder] = useState(0);

  /* const [steps, setSteps] = useState(1); */

  const addToast = useToast();

  useEffect(() => {
    async function getInfoActivity(): Promise<void> {
      try {
        const { data } = await api.get<ActivityFile>(`/activity/${activityId}`);
        // console.log(data);
        const dataActivity = data.pop_files.length
          ? {
              ...data,
              pop_files: data.pop_files.map((file) => {
                return {
                  ...file,
                  dirOriginal: file.dir,
                  dir: `${process.env.REACT_APP_API_STORAGE}/${file.dir}`,
                  isChecked: true,
                  isDownloading: false,
                };
              }),
            }
          : data;

        setActivity(dataActivity);

        if (!data.responsibilities?.length) {
          setToggleAllResponsibility(true);
        }

        if (data?.pop) {
          const content = htmlToDraft(data.pop);
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(content.contentBlocks),
            ),
          );
        } else {
          setEditorState(EditorState.createEmpty());
        }
      } finally {
        setLoadingActivity(false);
      }
    }

    async function getSavedFile(): Promise<void> {
      const { data } = await api.get<PopFilePaginate>(`activity/file`);
      // console.log(data);
      setSavedFile({
        ...data,
      });

      // setPage((state) => state + 1);
    }

    async function getFiltersActivity(): Promise<void> {
      try {
        const { data: filter } = await api.get<ActivityFilter>(
          `/activity/filters`,
          {
            params: { demand_id: demId },
          },
        );

        setActivityFilter({
          ...filter,
          types: filter.types.map((tipo) => {
            return {
              ...tipo,
              label: tipo.nome,
              value: tipo.id.toString(),
            };
          }),
          characters: filter.characters.map((charac) => {
            return {
              ...charac,
              label: charac.nome,
              value: charac.id.toString(),
            };
          }),
          responsibilities: filter.responsibilities.map((resp) => {
            return {
              ...resp,
              id: resp.id,
              label: resp.nome,
              value: resp.id,
            };
          }),
          orders: filter.orders,

          /* responsibilitySelect.concat(
              filter.responsibilities.map((resp) => {
                return {
                  ...resp,
                  id: resp.id,
                  label: resp.nome,
                  value: resp.id,
                };
              }),
            ), */
        });
        if (!activityId) {
          setLastOrder(
            filter?.orders.length
              ? filter.orders[filter.orders.length - 1] + 1
              : 1,
          );
        }
      } finally {
        setLoadingFilters(false);
      }
    }

    if (activityId) {
      getInfoActivity();
    }

    getFiltersActivity();
    getSavedFile();
  }, [addToast, queryParams, demId, activityId]);

  const handleNextPage = useCallback(() => {
    setLoadingFiles(true);

    api
      .get<PopFilePaginate>('/activity/file', {
        params: { page: page + 1 },
      })
      .then((response) => {
        setSavedFile((state) => ({
          ...response.data,
          data: [...state.data, ...response.data.data],
        }));
        setCurrentPage(response.data.current_page);

        // console.log(response.data);

        setPage(page + 1);
      })
      .finally(() => {
        setLoadingFiles(false);
      });
  }, [page]);

  const handleRemoveFile = useCallback((id) => {
    setUploadedFiles((state) => state.filter((file) => file.id !== id));
  }, []);

  const submitFile = useCallback((files: File[]) => {
    const uploadFiles: IFile[] = files.map((file) => {
      return {
        file,
        id: uniqueId(),
        name: file.name,
        readableSize: filesize(file.size),
        originalSize: file.size,
        preview: URL.createObjectURL(file),
      };
    });

    // console.log(files);

    const upFiles = uploadFiles.map((upfile) => {
      return {
        name: upfile.name,
        type: upfile.file?.type,
      };
    });

    // Como sempre é realizado uma consulta no backend para validar o arquivo é necessário um loading ao subir os arquivos
    function validateFile(): void {
      api
        .post(`activity/file/verify`, {
          file: upFiles,
        })
        .then((resp) => {
          /* Arquivos encontrados no BD */
          const { data } = resp;

          data.forEach((fileDb: any) => {
            // console.log(data, uploadFiles[fileDb.key])
            // const file = uploadFiles[fileDb.key];

            uploadFiles[fileDb.key] = {
              ...uploadFiles[fileDb.key],
              id: fileDb.id,
              savedFile: true,
              exists: true,
            };
          });
        })
        .finally(() => {
          /*  const existentFiles = uploadFiles.filter((upFile) => upFile.exists);
          if (existentFiles.length) {
            existentFiles.find((exist) => exist.exists === )
          }

          const exclusiveFiles = collectionFiles.filter((collFile) => {
            const exclusive = !uploadedFiles.find(
              (upFile) => upFile.savedFile && upFile.id === collFile.id,
            );

            if (!exclusive) checkedFilesId.push(collFile.id);

            return exclusive;
          }); */

          // Dessa forma utilizamos o valor do estado e não gera dependência
          setUploadedFiles((state) => [...uploadFiles, ...state]);
        });
    }

    validateFile();
  }, []);

  const convertedTextHtml = useMemo((): string => {
    const currentContent = editorState?.getCurrentContent();

    if (!currentContent?.hasText()) return '';
    // console.log(currentContent);
    const rawContentState = convertToRaw(currentContent);

    return draftToHtml(rawContentState);
  }, [editorState]);

  const handleCreateActivity = useCallback(
    async (data) => {
      setLoadingCreate(true);

      try {
        formRef.current?.setErrors({});

        const validation = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatórios'),
          type_activity_id: Yup.string().required(
            'Tipo de atividade é obrigatório',
          ),
          character_activity_id: Yup.string().required(
            'Característica da atividade é obrigatório',
          ),
          /* responsibility: Yup.array()
            .of(Yup.number())
            .required('Selecione pelo menos um Cargo de execução'), */
          description: Yup.string().required('Descrição é obrigatoria'),
          duration: Yup.string().required('Duração da atividade é obrigatoria'),
        });

        await validation.validate(data, {
          abortEarly: false,
        });

        data.last_order = lastOrder;
        data.demand_id = demId;

        const pop = convertedTextHtml;

        const dataFiles = new FormData();

        if (pop) dataFiles.append('pop', pop);

        dataFiles.append('order', data.order);
        dataFiles.append('last_order', data.last_order);
        dataFiles.append('name', data.name);
        dataFiles.append('type_activity_id', data.type_activity_id);
        dataFiles.append('character_activity_id', data.character_activity_id);
        dataFiles.append('description', data.description);
        dataFiles.append('duration', data.duration);
        dataFiles.append('demand_id', data.demand_id);
        dataFiles.append('attach_file', String(Number(!!data?.attach_file)));
        dataFiles.append('send_email', String(Number(!!data?.send_email)));

        if (data.responsibility.length) {
          data.responsibility.forEach((resp: any, i: number) => {
            dataFiles.append(`responsibility[${i}]`, resp);
          });
        }

        if (uploadedFiles.length) {
          uploadedFiles.forEach((file, i) => {
            if (file.file) dataFiles.append(`file[${i}]`, file?.file);
            else dataFiles.append(`saved_file[${i}]`, file.id);
          });
        }

        await api.post<DemandActivitiesList>(`activity`, dataFiles);

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Atividade cadastrada!',
          description: 'A atividade foi cadastrada com sucesso.',
          isClosable: true,
        });

        goBackDemand();
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          /* console.log(err); */
          return;
        }

        /* Só atualizamos o estado loading do botão ao dar erro ao cadastrar a atividade, caso contrário seria redirecionado para
        a página de listagem de atividades, se atualizado no finally esse estado, ao redirecionar para a outra página no try, tentaria
        atualizar o estado em um componente já desmontado */
        setLoadingCreate(false);

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            err.response?.data?.error ||
            'Ocorreu um erro ao cadastrar sua atividade',
          isClosable: true,
        });
      }
    },
    [
      addToast,
      lastOrder,
      demId,
      uploadedFiles,
      convertedTextHtml,
      goBackDemand,
    ],
  );

  const handleEditActivity = useCallback(
    async (data) => {
      setLoadingCreate(true);

      try {
        formRef.current?.setErrors({});

        const validation = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          type_activity_id: Yup.string().required(
            'Tipo de atividade é obrigatório',
          ),
          character_activity_id: Yup.string().required(
            'Característica da atividade é obrigatório',
          ),
          /* responsibility: Yup.array()
            .of(Yup.number())
            .required('Selecione pelo menos um Cargo de execução'), */
          description: Yup.string().required('Descrição é obrigatoria'),
          duration: Yup.string().required('Duração da atividade é obrigatoria'),
        });

        await validation.validate(data, {
          abortEarly: false,
        });

        data.last_order = lastOrder;
        data.demand_id = demId;
        data.attach_file = !!data.attach_file.length;
        data.send_email = !!data.send_email.length;

        data.pop = convertedTextHtml;

        if (data?.saved_files?.length) {
          data.saved_files = data.saved_files.map(
            (file: any) => file.map((f: any) => f)?.[0],
          );

          data.saved_files = data.saved_files.filter((file: any) => !!file);
        } else {
          data.saved_files = [];
        }

        // savedFile = Arquivo foi selecionado da lista dos arquivos no banco de dados
        // exists = Arquivo foi subido através do componente Upload, porém no banco de dados foi encontrado o mesmo nome e tipo do arquivo

        /* No caso de exists relacionamos o arquivo com a atividade através do método anterior e também submetemos o arquivo para atualizá-lo
        no servidor, porém o arquivo não será salvo novamente no BD apenas terá o seu tamanho atualizado e consequentemente a coluna updated_at */

        const dbFiles = uploadedFiles.filter(
          (upfiles) => upfiles.savedFile && !upfiles.exists,
        );

        // console.log(dbFiles);

        if (dbFiles.length) {
          dbFiles.forEach((db) => data.saved_files?.push(db.id));
        }

        // console.log(data.saved_files);

        await api.put<DemandActivitiesList>(`activity/${activityId}`, data);

        // savedFile = Arquivo foi selecionado da lista dos arquivos no banco de dados
        // exists = Arquivo foi subido através do componente Upload, porém no banco de dados foi encontrado o mesmo nome e tipo do arquivo

        /* No caso de exists relacionamos o arquivo com a atividade através do método anterior e também submetemos o arquivo para atualizá-lo
        no servidor, porém o arquivo não será salvo novamente no BD apenas terá o seu tamanho atualizado e consequentemente a coluna updated_at */
        const newFiles = uploadedFiles.filter(
          (upfiles) => !upfiles.savedFile || upfiles.exists,
        );

        if (newFiles.length) {
          const dataFiles = new FormData();

          uploadedFiles.forEach((file, i) => {
            if (file.file) dataFiles.append(`file[${i}]`, file?.file);
          });

          if (activityId) dataFiles.append('activity_id', activityId);

          await api.post(`activity/upload`, dataFiles);
        }
        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Atividade editada!',
          description: 'A atividade foi editada com sucesso.',
          isClosable: true,
        });

        goBackDemand();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          /* console.log(err); */
          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            err.response?.data?.error ||
            'Ocorreu um erro ao editar sua atividade',
          isClosable: true,
        });
      } finally {
        setLoadingCreate(false);
      }
    },
    [
      addToast,
      lastOrder,
      demId,
      convertedTextHtml,
      activityId,
      uploadedFiles,
      goBackDemand,
    ],
  );

  function handleSelectFile(fileId: number): void {
    setActivity((state) => ({
      ...state,
      pop_files: state?.pop_files.map((file) => {
        return file.id === fileId
          ? { ...file, isChecked: !file.isChecked }
          : file;
      }),
    }));
  }

  function handleSelectOrder(orders?: number[]): Option[] {
    const options = orders?.map((ord) => {
      return {
        label: ord.toString(),
        value: ord,
      };
    });

    if (!activityId)
      options?.push({ label: lastOrder.toString(), value: lastOrder });

    return options || [];
  }

  const Link: React.FC<LinkCustom> = ({
    entityKey,
    contentState,
    children,
  }): any => {
    const { url } = contentState.getEntity(entityKey).getData();
    return (
      <a
        style={{ color: 'blue', fontStyle: 'italic' }}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  };

  const findLinkEntities = (
    contentBlock: any,
    callback: any,
    contentState: any,
  ): any => {
    contentBlock.findEntityRanges((character: any) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    }, callback);
  };

  const createLinkDecorator = (): CompositeDecorator =>
    new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ]);

  function handleMentionFile(name: string, url: string): void {
    const contentState = editorState?.getCurrentContent();

    const selection = editorState?.getSelection();

    // editorReference?.focus();

    // editorState?.mustForceSelection()

    // if (editorState) EditorState.moveSelectionToEnd(editorState);
    // const decorator = createLinkDecorator();

    // Obtém a âncora e o foco (seleção inicial e final de um texto, ou apenas a posição atual do cursor)
    // const selection = editorState?.getSelection();

    // Tentar adicionar o focu por aqui

    // console.log(contentState, selection);

    if (!contentState || !selection) return;

    const contentStateEntity = contentState.createEntity('LINK', 'MUTABLE', {
      // target: '_blank',
      url,
    });

    const entityKey = contentStateEntity.getLastCreatedEntityKey();

    /* const contentStateLink = Modifier.applyEntity(
        contentStateEntity,
        selection,
        entityKey,
      ); */

    /* RichUtils.toggleInlineStyle(editorState, 'STRIKEOUT'), */

    const contentStateLink = Modifier.replaceText(
      contentStateEntity,
      selection,
      name,
      undefined,
      // editorState?.getCurrentInlineStyle(),
      entityKey,
    );

    // const newState = EditorState.forceSelection(editorState, selection);

    const nextOffset = selection.getFocusOffset() + name.length;

    // o nextOffset + 1 é só para adicionar um espaço para facilitar que o usuário continue digitando
    const newOffset = selection.merge({
      anchorOffset: nextOffset,
      focusOffset: nextOffset,
      hasFocus: true,
    });

    // const newState = EditorState.moveFocusToEnd(editorState);
    // setEditorState(editorFocus);

    const newState = EditorState.createWithContent(contentStateLink);

    setEditorState(EditorState.acceptSelection(newState, newOffset));

    /* setEditorState(
      EditorState.set(newState, {
        currentContent: contentStateLink,
        acceptSelection: nextOffset,
      }),
    ); */

    /* const editorFocus = EditorState.forceSelection(editorState, selection);
    setEditorState(editorFocus); */

    /* setEditorState((state) => ({
      ...state,
      isSelectionAtEndOfContent: true,
    })); */

    /* if (editorState && selection) {
      const editorFocus = EditorState.forceSelection(editorState, selection);
      setEditorState(editorFocus);
    } */
  }

  const handleSelectFiles = useCallback(
    (data) => {
      /* A posição file é um array e cada uma dos índices possui um array, selecionar
    apenas o primeiro indice desses arrays, que contém a opção */
      const select: string[] = [];
      /* console.log(data); */
      data.file.forEach((file: any) => {
        if (file[0]) {
          select.push(file[0]);
        }
        /* file.push([]); */
      });
      // console.log(select);
      const selectedFile =
        savedFile.data.filter(
          (file) => !!select.find((sel) => Number(sel) === file.id),
        ) || [];

      const collectionFiles: IFile[] = selectedFile.map((selFile) => {
        return {
          id: selFile.id.toString(),
          name: selFile.nome_original,
          name_slug: selFile.nome,
          readableSize: selFile.tamanho,
          file: null,
          url: selFile.dir,
          preview: `${process.env.REACT_APP_API_STORAGE}/${selFile.dir}`,
          savedFile: true,
          exists:
            uploadedFiles.find((upFile) => Number(upFile.id) === selFile.id)
              ?.exists || false,
        };
      });

      const checkedFilesId: string[] = [];

      const exclusiveFiles = collectionFiles.filter((collFile) => {
        const exclusive = !uploadedFiles.find(
          (upFile) => upFile.savedFile && upFile.id === collFile.id,
        );

        if (!exclusive) checkedFilesId.push(collFile.id);

        return exclusive;
      });

      // const excludeFiles = collectionFiles.filter((colFile))

      // console.log(exclusiveFiles);

      onClose();
      // console.log(selectedFile);
      setUploadedFiles((state) => [
        ...exclusiveFiles,
        ...state.filter(
          (upFile) => !upFile.savedFile || checkedFilesId.includes(upFile.id),
        ),
      ]);
    },
    [onClose, uploadedFiles, savedFile],
  );

  const handleSearchFiles = useCallback(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    api.get('activity/file', {
      params: search,
      cancelToken: source.token,
    });
  }, [search]);

  return (
    <Box pos="relative" w="full">
      <Container>
        <SectionHeader
          title={activityId ? 'Editar atividade' : 'Cadastrar atividade'}
          /* loading={!loaded} */
          pagename={`Pagina para ${
            activityId ? 'editar' : 'cadastrar'
          } atividades`}
          goBackPage
          // goBackLink={linkDemandPage}
        />
      </Container>
      {((activityId && (loadingActivity || loadingFilters)) ||
        (!activityId && loadingFilters)) && (
        <Box
          pos="absolute"
          top={0}
          left={0}
          w="full"
          h="500px"
          bg="rgba(255,255,255,0.5)"
        >
          <Loading />
        </Box>
      )}
      {((activityId && !loadingActivity && !loadingFilters) ||
        (!activityId && !loadingFilters)) && (
        <>
          <Box minH={200} width="full">
            {/* O Switch Chakra seta o valor padrão apenas por meio do initialData */}
            <Form
              ref={formRef}
              onSubmit={!activityId ? handleCreateActivity : handleEditActivity}
              style={{ width: '100%' }}
              initialData={{
                ...activity,
                attach_file: [activity?.attach_file ? 1 : 0],
                send_email: [activity?.send_email ? 2 : 0],
                character_activity_id: activity?.character_activity_id?.toString(),
                type_activity_id: activity?.type_activity_id?.toString(),
              }}
            >
              <Stack>
                <Flex>
                  <Flex flexDirection="column" width={['full', 'auto']}>
                    <Flex flexDirection={['column', 'row']}>
                      <InputGroup>
                        <Input
                          label="Ordem / Nome"
                          name="name"
                          type="text"
                          placeholder="Nome da atividade"
                          autoFocus={!activityId}
                          width="full"
                          leftAddon={
                            <SelectChakra
                              defaultValue={activity?.order || lastOrder}
                              name="order"
                              options={handleSelectOrder(
                                activityFilter?.orders,
                              )}
                              isInline
                              style={{ borderWidth: 0 }}
                              focusBorder="transparent"
                            />
                          }
                        />
                      </InputGroup>
                      <Box w={['full', '350px']} ml={['none', '260px']}>
                        <Input
                          label="Duração da Atividade"
                          name="duration"
                          type="time"
                          toUpperCase
                        />
                      </Box>
                    </Flex>
                    <Flex flexDir={['column', 'row']}>
                      <Flex flexDir="column">
                        <Flex flexDir={['column', 'row']}>
                          <Box mb={3}>
                            <Text mb={2}>Tipo de atividade</Text>
                            {!!activityFilter?.types.length && (
                              <>
                                <RadioChakra
                                  name="type_activity_id"
                                  options={activityFilter?.types}
                                />
                              </>
                            )}
                          </Box>
                          <Box ml={[0, 20]} mb={3}>
                            <Text mb={2}>Característica da atividade</Text>
                            {!!activityFilter?.characters.length && (
                              <RadioChakra
                                name="character_activity_id"
                                options={activityFilter.characters}
                              />
                            )}
                          </Box>
                        </Flex>
                        <Box mt={[3, 10]} mb={[3, 0]}>
                          <SwitchChakra
                            options={[
                              {
                                id: 1,
                                label: 'Permitir anexo de arquivos ',
                                value: 1,
                              },
                            ]}
                            // defaultChecked={activity?.attach_file
                            id="attach_file"
                            name="attach_file"
                            isReversed
                            icon={FiFile}
                          />
                          {/* <FaPaperclip />
                      <Text ml={3}>Permitir anexo de arquivos</Text> */}
                          <SwitchChakra
                            icon={FiMail}
                            options={[
                              {
                                id: 2,
                                label: 'Permitir envio de e-mail',
                                value: 2,
                              },
                            ]}
                            id="send_email"
                            name="send_email"
                            isReversed
                          />
                        </Box>
                      </Flex>
                      <Flex>
                        <Box mb={3} ml={['none', '260px']}>
                          <Text mb={2}>Cargos de execução</Text>

                          <Checkbox
                            isChecked={toggleAllResponsibility}
                            isDisabled={!toggleAllResponsibility}
                          >
                            Todos
                          </Checkbox>

                          {!!activityFilter?.responsibilities.length && (
                            <CheckboxChakra
                              name="responsibility"
                              options={activityFilter?.responsibilities.map(
                                (resp) => {
                                  return {
                                    ...resp,
                                    isChecked: !!activity?.responsibilities?.find(
                                      (atvResp) =>
                                        atvResp.id === Number(resp.id),
                                    ),
                                  };
                                },
                              )}
                              hasChecked={(status) =>
                                setToggleAllResponsibility(!status)
                              }
                            />
                          )}
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                <TextareaChakra
                  label="Descrição"
                  placeholder="Descrição da Atividade"
                  name="description"
                  defaultValue={activity?.description}
                  rows={4}
                />
                <Flex justifyContent="space-between">
                  <Heading size="sm">POP</Heading>
                  <Button
                    mr={[0, 3]}
                    mb={[3, 0]}
                    colorScheme="blue"
                    onClick={onOpen}
                  >
                    <FiFolderPlus />
                    <Text ml={2}>Escolher arquivos salvos</Text>
                  </Button>
                </Flex>
                <Box mb={5}>
                  <Upload
                    onUpload={submitFile}
                    showFormat
                    accept={acceptedFormats}
                  />
                  {!!uploadedFiles.length && (
                    <FileList
                      files={uploadedFiles}
                      removeFile={(id) => handleRemoveFile(id)}
                      selectedFolder={!!selectedFolder}
                      setFileMention={(name, url) =>
                        handleMentionFile(name, url)
                      }
                      defaultLock={false}
                      readOnlyName
                      showUploading={false}
                    />
                  )}

                  {/* <Button
                    variant="solid"
                    colorScheme="blue"
                    size="sm"
                    type="button"
                    ml="auto"
                    mt={3}
                    onClick={() => handleMentionFile()}
                  >
                    Referenciar
                  </Button> */}
                </Box>

                <GridItem mt={4} borderWidth={1} rounded="sm">
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="draft-editor"
                    onEditorStateChange={(editor) => setEditorState(editor)}
                    editorStyle={{
                      padding: '1rem',
                    }}
                    toolbar={{
                      link: {
                        showOpenOptionOnHover: false,
                        defaultTargetOption: '_blank',
                      },
                    }}
                    editorRef={(ref) => {
                      setEditorReference(ref);
                    }}
                    // ref={refEditor}
                  />
                  {/* {editorState && (
                    <EditorOriginal
                      editorState={editorState}
                      onChange={(editor) => setEditorState(editor)}
                      // ref=
                    />
                  )} */}
                </GridItem>

                {!!activity?.pop_files?.length && (
                  <Box width="full" mt={10}>
                    <FilesGridLocal
                      switchSelectedFile={(fileId) => handleSelectFile(fileId)}
                      files={activity.pop_files}
                      sendEmail
                      setDownloadingFile={() => null}
                      openModalDeleteSavedFile={() => null}
                      showVertical
                      enablePreview
                      setFileMention={(name, url) => {
                        handleMentionFile(name, url);
                        // editorReference?.focus();
                      }}
                    />
                  </Box>
                )}

                {/* <FileClassification
                activityData={activityDetails}
                uploadedFiles={uploadedFiles}
                OnUploadedFiles={handleLoadingNewSavedFiles}
                updateFile={handleUpdateFile}
                handleRemove={handleRemoveFile}
                existFileClassification={handleExistFileClassifications}
                title="Arquivos do POP"
              /> */}
              </Stack>
            </Form>
            <Button
              display="flex"
              variant="solid"
              colorScheme="green"
              isLoading={loadingCreate}
              size="lg"
              type="submit"
              ml="auto"
              mt={3}
              onClick={() => formRef.current?.submitForm()}
            >
              Salvar
            </Button>
          </Box>
          <ModalChakra
            title="Arquivos POP"
            size="4xl"
            onClose={onClose}
            isOpen={isOpen}
            onSubmit={() => formRefSelectFile.current?.submitForm()}
            // isLoading={loadingFiles}
          >
            <Form
              ref={formRefSelectFile}
              onSubmit={handleSelectFiles}
              /* initialData={{}} */
            >
              <Box position="relative">
                {loadingFiles && <LoadingAbsolute z_index={1111} />}
                {/* <Search
                  loading={loadingSearch}
                  results={savedFile.total}
                  searchValue={(value) => setSearch(value)}
                  searchClear={() => setSearch('')}
                  tooltipPlacement="top-start"
                /> */}
                <Box
                  maxHeight="500px"
                  overflowY="auto"
                  mt={3}
                  position="relative"
                >
                  {!!savedFile?.data?.length &&
                    savedFile.data.map((file, i) => {
                      const urlApiStorage = `${process.env.REACT_APP_API_STORAGE}/${file.dir}`;
                      return (
                        <Flex
                          key={file.id}
                          background="gray.100"
                          mb={3}
                          p={3}
                          alignItems="center"
                          justifyContent="space-between"
                          _hover={{ bg: 'gray.50' }}
                        >
                          <CheckboxChakra
                            name={`file[${i}]`}
                            options={[
                              {
                                id: file.id.toString(),
                                label: file.nome_original,
                                value: file.id.toString(),
                                icon: (
                                  <Image
                                    borderRadius="sm"
                                    height="full"
                                    width="35px"
                                    src={
                                      file.mimetype && !file.errorLoadPreview
                                        ? getPreviewFile(
                                            urlApiStorage,
                                            file.mimetype,
                                            file.extensao,
                                          )
                                        : FileIconWithType({ type: 'default' })
                                    }
                                    objectFit="cover"
                                    onError={() =>
                                      setSavedFile((state) => ({
                                        ...state,
                                        data: state.data.map((saved) => {
                                          return saved.id === file.id
                                            ? {
                                                ...saved,
                                                errorLoadPreview: true,
                                              }
                                            : saved;
                                        }),
                                      }))
                                    }
                                  />
                                ),
                                variantColor: 'blue',
                                isChecked: !!uploadedFiles.find(
                                  (upFile) =>
                                    upFile.savedFile &&
                                    file.id === Number(upFile.id),
                                ),
                              },
                            ]}
                          />
                          <a href={urlApiStorage} target="__blank">
                            <FiExternalLink />
                          </a>
                        </Flex>
                      );
                    })}
                </Box>
              </Box>
            </Form>
            {savedFile.current_page < savedFile.last_page && (
              <Stack p={2} justifyContent="center">
                <Button
                  colorScheme="teal"
                  variant="ghost"
                  rightIcon={<FiChevronDown />}
                  onClick={handleNextPage}
                >
                  Carregar mais
                </Button>
              </Stack>
            )}
          </ModalChakra>
        </>
      )}
    </Box>
  );
};

export default ActivityCreate;
