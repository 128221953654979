/* eslint-disable consistent-return */
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import formatTimeToSeconds from '~/utils/formatTimeToSeconds';

import { useAuth } from './auth';

interface TimerState {
  seconds: number;
  minutes: number;
  hours: number;
}

interface LastUpdate {
  date?: Date;
  timestamp: number;
  planningId?: number;
  isRunning: boolean;
}

interface TimerContextData {
  /*  seconds: number;
  minutes: number; */
  hours: number;
  isRunning: boolean;
  activityNotification?: ActivityNotification;
  start(planningId: number, time_lapsed?: string): void;
  pause(): void;
  reset(): void;
  notify(event: string, msg: string): void;
  // lunchBreakTime?: string; // Pausa para o almoço
  // lastShiftTime: string; // Último turno
}

interface ActivityNotification {
  event: string;
  msg: string;
  received_at: number;
}

const TimerContext = createContext<TimerContextData>({} as TimerContextData);

const TimerProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [lastUpdateActivity, setlastUpdateActivity] = useState<LastUpdate>(
    () => {
      const lastUpdate = localStorage.getItem('@MyIPAC:timerLastUpdate');

      if (lastUpdate) {
        return JSON.parse(lastUpdate);
      }

      return {};
    },
  );

  const lastUpdateTimeRef = useRef(Date.now());
  const intervalRef = useRef<number>();

  const timerRef = useRef(0);

  /* const [timer, setTimer] = useState<TimerState>(() => {
    const timerStorage = localStorage.getItem('@MyIPAC:timer');

    if (timerStorage) {
      return JSON.parse(timerStorage);
    }

    return {
      seconds: 0,
      minutes: 0,
      hours: 0,
    };
  }); */

  const [timer, setTimer] = useState(() => {
    const timerStorage = localStorage.getItem('@MyIPAC:timer');

    // console.log(timerStorage);

    if (timerStorage) {
      return Number(timerStorage);
    }

    return 0;
  });

  const [isRunning, setIsRunning] = useState(
    lastUpdateActivity.isRunning || false,
  );

  const [
    activityNotification,
    setActivityNotification,
  ] = useState<ActivityNotification>();

  useEffect(() => {
    // exit early when we reach 0
    if (isRunning) {
      // save intervalId to clear the interval when the
      // component re-renders

      // Ao setar no localstorage o objeto date muda, verificar forma de obter as informações do objeto novamente

      const { timestamp: lastUpdateTimestamp } = lastUpdateActivity;

      const activityDurationSec = timerRef.current ? timerRef.current : 0;

      intervalRef.current = setInterval(() => {
        const now = Date.now();

        const timestampDiffSec = (now - lastUpdateTimestamp) / 1000;

        const totalDurationSec = activityDurationSec + timestampDiffSec;

        setTimer(totalDurationSec);

        localStorage.setItem('@MyIPAC:timer', totalDurationSec.toString());
        localStorage.setItem(
          '@MyIPAC:timerLastUpdate',
          JSON.stringify({
            ...lastUpdateActivity,
            date: new Date(),
            timestamp: Date.now(),
          }),
        );
      }, 1000);
    }

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalRef.current);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [isRunning, lastUpdateActivity, user]);

  const start = useCallback((planningId: number, time_lapsed?: string) => {
    setIsRunning(true);
    if (time_lapsed) {
      setTimer(formatTimeToSeconds(time_lapsed));

      /* É necessário utilizar a ref ao invés do estado para não gerar dependência e consequentemente gerar concorrência
      da atualização do estado e a função setInterval */
      timerRef.current = formatTimeToSeconds(time_lapsed);
    }
    setlastUpdateActivity({
      date: new Date(),
      timestamp: Date.now(),
      planningId,
      isRunning: true,
    });
  }, []);

  const pause = useCallback(
    () => {
      setIsRunning(false);
      setlastUpdateActivity((state) => ({
        ...state,
        isRunning: false,
      }));

      const lsTimerLastUpdateString = localStorage.getItem(
        '@MyIPAC:timerLastUpdate',
      );

      const lsTimerLastUpdate = lsTimerLastUpdateString
        ? JSON.parse(lsTimerLastUpdateString)
        : {};

      /* if (lsTimerLastUpdate) {
      console.log(JSON.parse(lsTimerLastUpdate));
    } */

      localStorage.setItem(
        '@MyIPAC:timerLastUpdate',
        JSON.stringify({
          ...lsTimerLastUpdate,
          isRunning: false,
        }),
      );
    },
    [
      /* lastUpdateActivity */
    ],
  );

  const reset = useCallback(() => {
    setIsRunning(false);
    setTimer(0);
    localStorage.removeItem('@MyIPAC:timer');
    localStorage.removeItem('@MyIPAC:timerLastUpdate');
  }, []);

  const notify = useCallback((event: string, msg: string) => {
    setActivityNotification({ event, msg, received_at: Date.now() });
  }, []);

  return (
    <TimerContext.Provider
      value={{
        hours: timer,
        isRunning,
        start,
        pause,
        reset,
        notify,
        activityNotification,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

function useTimer(): TimerContextData {
  const context = useContext(TimerContext);

  if (!context) {
    throw new Error('useTimer must be used within an TimerProvider');
  }

  return context;
}

export { TimerProvider, useTimer };
