import React, { createContext, useContext, useCallback } from 'react';

import { ThemeProvider, DefaultTheme } from 'styled-components';

import dark from '~/styles/themes/dark';
import light from '~/styles/themes/light';
import usePersistedState from '~/utils/usePersistedState';

interface ThemeContextData {
  toggleTheme(): void;
  theme: DefaultTheme;
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

const CustomThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = usePersistedState<DefaultTheme>(
    '@MyIPAC:theme',
    light,
  );

  const toggleTheme = useCallback(() => {
    setTheme(theme.title === 'light' ? dark : light);
  }, [theme.title, setTheme]);

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

function useTheme(): ThemeContextData {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useThme must be used within a ThemeProvider');
  }

  return context;
}

export { CustomThemeProvider, useTheme };
