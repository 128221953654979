import React, { useState, useCallback, useEffect } from 'react';
import { FiMessageCircle } from 'react-icons/fi';

import { Box, Flex, Skeleton, Text, useDisclosure } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import { Markup } from 'interweave';

import { useAuth } from '~/hooks/auth';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import api from '~/shared/services/api';

import { DevolutionData } from '../../@types/activity';
import { ActivityDevolutionData } from './styles';

interface Props {
  id: number;
  obs?: string;
  onCloseDevolution(): void;
  /* isOpen: boolean; */
}

const ActivityDevolution: React.FC<Props> = ({
  id,
  obs,
  onCloseDevolution,
}) => {
  const [
    modalDevolutionActivityOpen,
    setModalDevolutionActivityOpen,
  ] = useState(false);

  const [
    modalDevolutionActivityData,
    setModalDevolutionActivityData,
  ] = useState<DevolutionData>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();

    api.get<DevolutionData>(`plannings/devolution/${id}`).then((response) => {
      if (!response.data) return;

      const item: DevolutionData = {
        ...response.data,
        created_at: format(
          parseISO(response.data.created_at),
          "dd/MM/yyyy 'às' HH:mm",
        ),
        observation: obs,
      };

      setModalDevolutionActivityData(item);
    });
  }, [id, onOpen, obs]);

  return (
    <ModalChakra
      title="Atividade Devolvida"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        onCloseDevolution();
      }}
      size="md"
    >
      <ActivityDevolutionData>
        {modalDevolutionActivityData ? (
          <>
            {modalDevolutionActivityData?.observation && (
              <Box mb={5}>
                <Flex alignItems="center" mb={1}>
                  <FiMessageCircle size={20} />
                  <Text ml={1} fontWeight="bold">
                    Observação
                  </Text>
                </Flex>
                <Box background="gray.50" p={2} borderRadius={4}>
                  <Markup content={modalDevolutionActivityData?.observation} />
                </Box>
              </Box>
            )}
            <ul>
              <li>
                <strong>Devolução:</strong>
                <small>{modalDevolutionActivityData?.created_at}</small>
              </li>
              <li>
                <strong>De:</strong>
                <small>
                  {modalDevolutionActivityData?.responsible_devolution_name}
                </small>
              </li>
              <li>
                <strong>Ocorrência:</strong>
                <small>{modalDevolutionActivityData?.atv_name}</small>
              </li>
              <li>
                <strong>Executante:</strong>
                <small>{modalDevolutionActivityData?.func_name}</small>
              </li>
              <li>
                <strong>Motivo:</strong>
                <small>{modalDevolutionActivityData?.reason_devolution}</small>
              </li>
            </ul>
          </>
        ) : (
          <div>
            <Box mb={5}>
              <Skeleton height="20px" mb={1} />
              <Skeleton height="80px" />
            </Box>
            <Skeleton height="20px" mb="8px" />
            <Skeleton height="20px" mb="8px" />
            <Skeleton height="20px" mb="8px" />
            <Skeleton height="20px" mb="8px" />
            <Skeleton height="20px" mb="8px" />
          </div>
        )}
      </ActivityDevolutionData>
    </ModalChakra>
  );
};

export default ActivityDevolution;
