import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';

import {
  Box,
  Flex,
  Button,
  useToast,
  IconButton,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import { parse } from 'date-fns/esm';
import moment from 'moment';
import * as Yup from 'yup';

import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import { IPaginationProps } from '~/shared/components/Pagination';
import RangeDateWithTwoInput from '~/shared/components/RangeDateWithTwoInput';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import exportExcel from '~/utils/exportExcel';
import getValidationErrors from '~/utils/getValidationErrors';

import BadgeStatusInfo from '../../components/BadgeStatusInfo';

interface ActivityExecutionReportData {
  id: number;
  funcionario_id: number;
  funcionario: string;
  alocacao_real: string;
  atividade_duracao: string;
  atendimento_id: number;
  competencia: string | null;
  inicio_atendimento: string;
  fim_atendimento: string | null;
  demanda_id: number;
  demanda_nome: string;
  atividade_nome: string;
  nome_fantasia: string;
  razao_social: string;
  status_id: number;
  status_nome: string;
  status_cor: string;
  status_nome_original: string;
}

interface ActivityExecutionReportPaginated extends IPaginationProps {
  data: ActivityExecutionReportData[];
}

interface FilterDataSelect {
  id: number;
  nome: string;
  value: string;
  label: string;
}

interface FilterData {
  departments: FilterDataSelect[];
  employees: FilterDataSelect[];
  companies: FilterDataSelect[];
  demands: FilterDataSelect[];
}

interface Filters {
  start_date: string;
  end_date: string;
  department_id?: string;
  employee_id?: string;
  company_id?: string;
  demand_id?: string;
}

const ActivitiesExecutionReport: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const addToast = useToast();

  const [loading, setLoading] = useState(false);

  const [report, setReport] = useState<ActivityExecutionReportPaginated>(
    {} as ActivityExecutionReportPaginated,
  );

  const [periodError, setPeriodError] = useState(false);

  // const [page, setPage] = useState(1);

  const formattedCurrentDate = format(new Date(), 'yyyy-MM-dd');

  const [filters, setFilters] = useState<Filters>({
    start_date: formattedCurrentDate,
    end_date: formattedCurrentDate,
  });

  const [filtersSelect, setFiltersSelect] = useState<FilterData>(
    {} as FilterData,
  );

  const [loadingFilters, setLoadingFilters] = useState(false);

  const [loadingExcel, setLoadingExcel] = useState(false);

  function getBgCell(statusId: number): string {
    let bg = '';

    switch (statusId) {
      case 14:
        bg = 'FFFFC000';
        break;
      case 15:
        bg = 'FF538DD5';
        break;
      case 16:
        bg = 'FF00B050';
        break;
      case 17:
        bg = 'DD6B20';
        break;

      default:
        bg = 'FF000001';
        break;
    }

    return bg;
  }

  const thead: THeadProps[] = useMemo(
    () => [
      { title: 'Responsável' },
      { title: 'Empresa' },
      { title: 'Demanda' },
      { title: 'Atividade' },
      { title: 'Competência' },
      { title: 'Status Atual' },
      {
        title: 'Execução contabilizada do período',
        help:
          'A duração é contabilizada quando há alguma pausa ou quando a atividade é finalizada, a duração exibida no relatório dependerá do período filtrado',
      },
      { title: 'Início da execução' },
      { title: 'Fim da execução' },
    ],
    [],
  );

  const getActivitiesExecutionData = useCallback(
    (
      page: number,
      startDate?: string,
      endDate?: string,
      department_id?: string,
      employee_id?: string,
      demand_id?: string,
      company_id?: string,
    ) => {
      setLoading(true);

      api
        .get<ActivityExecutionReportPaginated>('reports/activity-execution', {
          params: {
            start_date: startDate || formattedCurrentDate,
            end_date: endDate || formattedCurrentDate,
            department_id: department_id || null,
            employee_id: employee_id || null,
            demand_id: demand_id || null,
            company_id: company_id || null,
            page,
          },
        })
        .then((resp) => {
          const { data } = resp.data;

          setReport({
            ...resp.data,
            data: data.map((dt) => ({
              ...dt,
              inicio_atendimento: format(
                parseISO(dt.inicio_atendimento),
                "dd/MM/yyyy 'às' HH:mm",
              ),
              fim_atendimento: dt.fim_atendimento
                ? format(parseISO(dt.fim_atendimento), "dd/MM/yyyy 'às' HH:mm")
                : '',
            })),
          });
        })
        .catch((err) => {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Erro ao buscar os dados',
            description: err.response.data?.error,
          });
        })
        .finally(() => setLoading(false));
    },
    [addToast, formattedCurrentDate],
  );

  useEffect(() => {
    getActivitiesExecutionData(1);
  }, [formattedCurrentDate, getActivitiesExecutionData]);

  useEffect(() => {
    setLoadingFilters(true);

    const {
      start_date,
      end_date,
      department_id,
      employee_id,
      demand_id,
      company_id,
    } = filters;

    api
      .get<FilterData>('/reports/activity-execution/filters', {
        params: {
          start_date,
          end_date,
          department_id: department_id || null,
          employee_id: employee_id || null,
          demand_id: demand_id || null,
          company_id: company_id || null,
        },
      })
      .then((response) => {
        const { data } = response;
        setFiltersSelect({
          employees: data.employees.map((emp) => {
            return {
              id: emp.id,
              nome: emp.nome,
              value: emp.id.toString(),
              label: emp.nome,
            };
          }),
          companies: data.companies.map((comp) => {
            return {
              id: comp.id,
              nome: comp.nome,
              value: comp.id.toString(),
              label: comp.nome,
            };
          }),
          demands: data.demands.map((dem) => {
            return {
              id: dem.id,
              nome: dem.nome,
              value: dem.id.toString(),
              label: dem.nome,
            };
          }),
          departments: data.departments.map((dep) => ({
            id: dep.id,
            nome: dep.nome,
            value: dep.id.toString(),
            label: dep.nome,
          })),
        });
      })
      .finally(() => {
        setLoadingFilters(false);
      });
  }, [filters]);

  const handleSubmit = useCallback(
    async (formData: any) => {
      const { date } = formData;

      try {
        formRef.current?.setErrors({});
        setPeriodError(false);

        const schema = Yup.object().shape({
          date: Yup.array()
            .of(Yup.string().required('Data obrigatória'))
            .required(),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const formDataStartDate = date[0]
          ? format(parse(date[0], 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
          : '';

        const formDataEndDate = date[1]
          ? format(parse(date[1], 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
          : '';

        if (
          filters.start_date !== formDataStartDate ||
          filters.end_date !== formDataEndDate
        ) {
          // Necessário limpar os filtros da tabela, mantendo apenas do período
          setFilters({
            start_date: formDataStartDate,
            end_date: formDataEndDate,
            department_id: '',
            employee_id: '',
            demand_id: '',
            company_id: '',
          });

          getActivitiesExecutionData(1, formDataStartDate, formDataEndDate);

          formRef.current?.clearField('department_id');
          formRef.current?.clearField('employee_id');
          formRef.current?.clearField('demand_id');
          formRef.current?.clearField('company_id');
        } else {
          setFilters({
            start_date: formDataStartDate,
            end_date: formDataEndDate,
            department_id: formData.department_id,
            employee_id: formData.employee_id,
            demand_id: formData.demand_id,
            company_id: formData.company_id,
          });

          getActivitiesExecutionData(
            1,
            formDataStartDate,
            formDataEndDate,
            formData.department_id,
            formData.employee_id,
            formData.demand_id,
            formData.company_id,
          );
        }

        // console.log('teste');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setPeriodError(true);

          /* const errors = getValidationErrors(err);
          if (errors[`date[0]`]) {
            errors.date = 'Período obrigatório';
          }
          formRef.current?.setErrors(errors); */
        }
      }

      // console.log(formData);
    },
    [getActivitiesExecutionData, filters],
  );

  const handleExportExcel = useCallback(async () => {
    setLoadingExcel(true);

    const {
      start_date,
      end_date,
      department_id,
      employee_id,
      demand_id,
      company_id,
    } = filters;

    api
      .get<ActivityExecutionReportData[]>('reports/activity-execution', {
        params: {
          start_date,
          end_date,
          department_id: department_id || null,
          employee_id: employee_id || null,
          demand_id: demand_id || null,
          company_id: company_id || null,
        },
      })
      .then((resp) => {
        let name = `Relatório de Execução de Atividades ${format(
          parseISO(start_date),
          'dd-MM-yyyy',
        )}`;

        if (start_date !== end_date) {
          name += ` a ${format(parseISO(end_date), 'dd-MM-yyyy')}`;
        }

        exportExcel({
          fileExtension: '.xlsx',
          fileType:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
          title: name,
          name,
          colName: thead.map((col) => {
            return col.title;
          }),
          thead: thead.map((col) => {
            return { title: col.title };
          }),
          titleDataOrder: [
            'funcionario',
            'nome_fantasia',
            'demanda_nome',
            'atividade_nome',
            'competencia',
            'status_nome',
            'alocacao_real',
            'inicio_atendimento',
            'fim_atendimento',
          ],
          data: resp.data.map((dt) => ({
            funcionario: dt.funcionario,
            nome_fantasia: dt.nome_fantasia,
            demanda_nome: dt.demanda_nome,
            atividade_nome: dt.atividade_nome,
            competencia: dt.competencia || '',
            status_nome: {
              v: dt.status_nome,
              s: {
                font: {
                  color: { rgb: getBgCell(dt.status_id) },
                  bold: true,
                },
              },
            },
            alocacao_real: dt.alocacao_real,
            inicio_atendimento: format(
              parseISO(dt.inicio_atendimento),
              'dd/MM/yyyy HH:mm',
            ),
            fim_atendimento: dt.fim_atendimento
              ? format(parseISO(dt.fim_atendimento), 'dd/MM/yyyy HH:mm')
              : '-',
          })),
          titleLine: 1,
          enableAutoFilter: true,
        });
      })
      .finally(() => setLoadingExcel(false));
  }, [filters, thead]);

  return (
    <Box position="relative" width="full" minHeight="100vh">
      <SectionHeader
        title="Execução de atividades"
        pagename="Relatório de execução de atividades"
        goBackPage
      />

      {/* {loading && (
        <Box
          pos="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          bg="rgba(255,255,255,0.5)"
          zIndex={1000}
        >
          <Loading />
        </Box>
      )} */}

      <>
        <Form
          ref={formRef}
          initialData={{
            date: [
              moment(filters.start_date || formattedCurrentDate),
              moment(filters.end_date || formattedCurrentDate),
            ],
          }}
          onSubmit={handleSubmit}
        >
          <Flex
            flexDir={['column', 'row']}
            alignItems="flex-end"
            width="full"
            mb={4}
          >
            <Box width="full" maxWidth="400px" mb={[4, 0]}>
              <RangeDateWithTwoInput
                name="date"
                mb={0}
                showError={periodError}
              />
            </Box>

            <Button
              // justifyContent="flex-start"
              aria-label="Buscar período"
              icon={<FiSearch />}
              height="48px"
              width={['full', '48px']}
              ml={4}
              onClick={() => formRef.current?.submitForm()}
              // background="trasnparente"
              // variant="solid"
              boxShadow="none"
              colorScheme="blue"
            >
              <Flex>
                <FiSearch />
                <Text ml={2} display={['block', 'none']}>
                  Buscar
                </Text>
              </Flex>
            </Button>
          </Flex>
          {filters.start_date && filters.end_date && !!report?.data?.length && (
            <Flex
              flexDir={['column', 'row']}
              justifyContent="space-between"
              alignItems="flex-end"
              mb={4}
            >
              <Flex
                width="full"
                flexDir={['column', 'row']}
                mb={[4, 0]}
                alignItems="flex-end"
              >
                <Box mr={[0, 3]} mb={[4, 0]} width="full">
                  <SelectChakra
                    name="department_id"
                    label="Departamento"
                    placeholder={!loadingFilters ? 'Todos' : 'Carregando...'}
                    options={filtersSelect.departments}
                    onChange={() => {
                      formRef.current?.submitForm();
                    }}
                    disabled={loadingFilters}
                    isInline
                    width="full"
                  />
                </Box>
                <Box width="full" mr={[0, 3]} mb={[4, 0]}>
                  <SelectChakra
                    name="employee_id"
                    label="Funcionário"
                    placeholder={!loadingFilters ? 'Todos' : 'Carregando...'}
                    options={filtersSelect.employees}
                    onChange={() => {
                      formRef.current?.submitForm();
                    }}
                    disabled={loadingFilters}
                    isInline
                    width="full"
                  />
                </Box>

                <Box width="full" mr={[0, 3]} mb={[4, 0]}>
                  <SelectChakra
                    name="company_id"
                    label="Empresa"
                    placeholder={!loadingFilters ? 'Todas' : 'Carregando...'}
                    options={filtersSelect.companies}
                    onChange={() => {
                      formRef.current?.submitForm();
                    }}
                    disabled={loadingFilters}
                    isInline
                    width="full"
                  />
                </Box>
                <Box width="full">
                  <SelectChakra
                    name="demand_id"
                    label="Demanda"
                    placeholder={!loadingFilters ? 'Todas' : 'Carregando...'}
                    options={filtersSelect.demands}
                    onChange={() => {
                      formRef.current?.submitForm();
                    }}
                    disabled={loadingFilters}
                    isInline
                    width="full"
                  />
                </Box>
                <Box ml={[0, 4]} mt={[4, 0]} width={['full', 'auto']}>
                  <Button
                    colorScheme="green"
                    bgColor="green.600"
                    size="md"
                    onClick={handleExportExcel}
                    _hover={{ backgroundColor: '#38A169' }}
                    isLoading={loadingExcel}
                    disabled={loading}
                    width="full"
                  >
                    <FaFileExcel />
                    <Text display="inline-block" ml={2}>
                      Exportar para Excel
                    </Text>
                  </Button>
                </Box>
              </Flex>
            </Flex>
          )}
        </Form>

        {!loading && !report.data?.length && (
          <Alert status="info">
            <AlertIcon />
            Nenhuma atividade foi executada no período buscado ou o filtro
            realizado não trouxe nenhum resultado
          </Alert>
        )}

        {(loading || !!report?.data?.length) && (
          <Table
            theadData={thead}
            loading={loading}
            pagination={{
              current_page: report.current_page,
              last_page: report.last_page,
              per_page: report.per_page,
              to: report.to,
              total: report.total,
            }}
            newPage={(pg) =>
              getActivitiesExecutionData(
                pg,
                filters.start_date,
                filters.end_date || '',
                filters.department_id,
                filters.employee_id,
                filters.demand_id,
                filters.company_id,
              )
            }
          >
            {report?.data?.map((data) => (
              <Box as="tr" key={data.id} _hover={{ bg: 'gray.50' }}>
                <Box as="td" p={2}>
                  {data.funcionario}
                </Box>
                <Box as="td" p={2}>
                  {data.nome_fantasia}
                </Box>
                <Box as="td" p={2}>
                  {data.demanda_nome}
                </Box>
                <Box as="td" p={2}>
                  {data.atividade_nome}
                </Box>
                <Box as="td" p={2}>
                  {data.competencia ?? ''}
                </Box>
                <Box as="td" p={2} textAlign="center">
                  <BadgeStatusInfo
                    status={data.status_nome}
                    statusColor={data.status_nome_original}
                  />
                </Box>
                <Box as="td" p={2}>
                  {data.alocacao_real}
                </Box>
                <Box as="td" p={2}>
                  {data.inicio_atendimento}
                </Box>
                <Box as="td" p={2}>
                  {data.fim_atendimento || '-'}
                </Box>
              </Box>
            ))}
          </Table>
        )}
      </>
    </Box>
  );
};

export default ActivitiesExecutionReport;
