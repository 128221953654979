import React, { useMemo, useCallback } from 'react';
import {
  FiMessageCircle,
  FiBookOpen,
  FiList,
  FiAlertOctagon,
  FiInfo,
  FiChevronRight,
  FiRotateCcw,
  FiPaperclip,
  FiCheckCircle,
} from 'react-icons/fi';

import {
  Badge,
  Box,
  IconButton,
  Flex,
  Icon,
  useToast,
  Text,
} from '@chakra-ui/react';

import clockImg from '~/assets/stopwatch.png';
import {
  ActivityProps,
  Pop,
} from '~/modules/activityExecution/@types/activity';

import {
  CActivity,
  Container,
  Content,
  Header,
  Row,
  AttendanceDescription,
  CInfoActivity,
} from './styles';

interface Props {
  data: ActivityProps;
  pop?(data: Pop): void;
  attendanceActivities(): void;
  modalDevolution?(id: number, observation?: string): void;
  viewPlanningInfo(): void;
}

const Activity: React.FC<Props> = ({
  data,
  pop,
  attendanceActivities,
  modalDevolution,
  children,
  viewPlanningInfo,
}) => {
  // const addToast = useToast();

  const formattedColorSituation = useMemo(() => {
    if (data.situation === 'Pausado') {
      return 'yellow';
    }
    if (data.situation === 'Finalizado') {
      return 'green';
    }
    if (data.situation === 'Iniciado') {
      return 'blue';
    }
    return 'gray';
  }, [data]);

  const handlePopOpen = useCallback(() => {
    if (pop) {
      pop({
        name: data.name,
        description: data?.activity_description || '',
        pop: data?.activity_pop || '',
        pop_files: data?.pop_files || [],
      });
    }
  }, [pop, data]);

  const handleAttendanceActivitiesModal = useCallback(() => {
    attendanceActivities();
  }, [attendanceActivities]);

  const handleDevolutionModal = useCallback(() => {
    if (!modalDevolution) return;

    modalDevolution(data.id, data.observation);
  }, [modalDevolution, data.id, data.observation]);

  return (
    <CActivity id={`containerActivity-${data.id}`}>
      <Header
        display="flex"
        flexDirection={['column-reverse', 'row', 'row']}
        justifyContent="space-between"
        alignItems={['flex-end', 'flex-start']}
        mb="2px"
      >
        <h3 title="Código do atendimento">
          <Badge colorScheme="blue" mr={1}>
            {data.attendance_id}
          </Badge>
          {data.name}
        </h3>

        <Badge
          variant="solid"
          mb={[1, 0]}
          colorScheme={formattedColorSituation}
        >
          {data.situation}
        </Badge>

        {/* Exibição mobile apenas */}
        <Box
          as="span"
          ml="auto"
          mb={1}
          borderRadius="sm"
          backgroundColor="trasnparent"
          display={{ base: 'flex', md: 'none' }}
        >
          {data.description && (
            <button type="button" className="help" disabled>
              <FiMessageCircle size={18} />

              <AttendanceDescription>
                <strong>Descrição do atendimento:</strong>
                <small>{data.description}</small>
              </AttendanceDescription>
            </button>
          )}

          {(data.activity_pop || data.activity_description) && (
            <IconButton
              aria-label="Clique para ver o POP"
              title="Clique para ver o POP"
              icon={<FiBookOpen />}
              size="sm"
              variant="ghost"
              onClick={handlePopOpen}
            />
          )}

          <IconButton
            aria-label="Ver atividades do atendimento"
            title="Ver atividades do atendimento"
            icon={<FiList />}
            size="sm"
            variant="ghost"
            onClick={handleAttendanceActivitiesModal}
          />

          {data.returned === 1 && (
            <IconButton
              aria-label="Atividade devolvida"
              title="Atividade devolvida"
              icon={<FiRotateCcw />}
              size="sm"
              variant="ghost"
              onClick={handleDevolutionModal}
            />
          )}
          {!!data?.attach_file && data.situation_id !== 14 && (
            <IconButton
              aria-label="Anexos"
              title="Anexos"
              icon={<FiPaperclip />}
              size="sm"
              variant="ghost"
              onClick={viewPlanningInfo}
            />
          )}
        </Box>
      </Header>
      <CInfoActivity className="container-info-activity">
        <Flex
          position="absolute"
          height="full"
          alignItems="flex-end"
          borderBottomRightRadius="sm"
          borderTopRightRadius="sm"
          className="btn-info-activity"
          zIndex={2}
        >
          <Flex height="full" className="container-slider-options">
            <Flex
              position="relative"
              align-items="center"
              borderTopRadius="sm"
              borderTopLeftRadius="sm"
              className="slider-options"
              backgroundColor="blue.600"
              py={3}
            >
              <FiChevronRight
                size={20}
                className="slider-options-icon"
                color="#FFFF"
              />
              <Box
                alignSelf="center"
                className="slider-options-buttons"
                whiteSpace="nowrap"
                mx={4}
                py={1}
                id={`atv-${data.id}`}
              >
                {(data.activity_pop || data.activity_description) && (
                  <IconButton
                    aria-label="Clique para ver o POP"
                    title="Clique para ver o POP"
                    icon={<FiBookOpen />}
                    size="lg"
                    variant="solid"
                    background="#FFFF"
                    color="#323c79"
                    _focus={{
                      boxShadow: 'none',
                    }}
                    onClick={handlePopOpen}
                  />
                )}

                <IconButton
                  aria-label="Ver atividades do atendimento"
                  title="Ver atividades do atendimento"
                  icon={<FiList />}
                  size="lg"
                  variant="solid"
                  background="#FFFF"
                  color="#323c79"
                  onClick={handleAttendanceActivitiesModal}
                  _focus={{
                    boxShadow: 'none',
                  }}
                />

                {data.returned === 1 && (
                  <IconButton
                    aria-label="Atividade devolvida"
                    title="Atividade devolvida"
                    icon={<FiRotateCcw />}
                    size="lg"
                    variant="solid"
                    background="#FFFF"
                    color="#323c79"
                    onClick={handleDevolutionModal}
                    _focus={{
                      boxShadow: 'none',
                    }}
                  />
                )}

                {/* Testar se sem o  && data.situation_id !== 14 é possível utilizar */}
                {(!!data.count_files ||
                  (!!data?.count_files_attendance && !!data.predecessors)) && (
                  <Box display="inline-block" ml={2} position="relative">
                    <IconButton
                      aria-label="Anexos"
                      title="Anexos"
                      icon={<FiPaperclip />}
                      size="lg"
                      variant="solid"
                      background="#FFFF"
                      color="#323c79"
                      onClick={viewPlanningInfo}
                      _focus={{
                        boxShadow: 'none',
                      }}
                    />
                    {/* Verifica se a atividade em questão possui arquivos para exibir  */}
                    {!!data.count_files && (
                      <Flex position="absolute" top={-1} right={-2}>
                        <Badge
                          variant="solid"
                          colorScheme="red"
                          fontSize="12px"
                          minWidth="18px"
                          minHeight="18px"
                          borderRadius="100%"
                          textAlign="center"
                        >
                          <span>{data.count_files}</span>
                        </Badge>
                      </Flex>
                    )}
                  </Box>
                )}
              </Box>
            </Flex>
          </Flex>
        </Flex>
        {/* Não exibe em mobile */}
        <Container>
          <Content>
            {data.competence && (
              <span className="competence">
                <strong>Competência:</strong>
                <small>{data.competence}</small>
              </span>
            )}
            <span>
              <strong>Demanda:</strong>
              <small>{data.demand}</small>
            </span>
            <span>
              <strong>Empresa:</strong>
              <small>{data.company}</small>
            </span>

            <span className="planning-execution-info">
              <strong>Planejamento:</strong>
              <Flex>
                <small>
                  <span>
                    <small>{data.formatted_start_date}</small>
                    <small>às</small>
                    <small className="colorblue">
                      {data.formatted_start_time}
                    </small>
                  </span>
                </small>
                <Flex
                  alignItems="center"
                  ml="20px"
                  mt="-2px"
                  title="Tempo máximo para execução da atividade"
                >
                  <img
                    src={clockImg}
                    alt="Clock"
                    style={{
                      height: '18px',
                      opacity: '75%',
                    }}
                  />
                  <span style={{ marginLeft: '0.25rem', paddingTop: '2px' }}>
                    {data.time_left}
                  </span>
                </Flex>
              </Flex>
            </span>
            {data.situation_id === 16 && !!data?.real_allocation && (
              <span className="realized-group">
                <div className="badge-finish">
                  <strong>Realizado</strong>
                  <FiCheckCircle />
                </div>
                <Flex wrap="wrap" ml="5px">
                  <Flex>
                    <small>{data.formatted_real_start_date}</small>
                    <small>às</small>
                    <small className="colorblue">
                      {data.formatted_real_start_time}
                    </small>
                  </Flex>
                  <Flex>
                    <small>àté</small>
                    {data.formatted_real_start_date !==
                      data.formatted_real_end_date && (
                      <>
                        <small>{data.formatted_real_end_date}</small>
                        <small>às</small>
                      </>
                    )}
                    <small className="colorblue">
                      {data.formatted_real_end_time}
                    </small>
                    <Flex
                      alignItems="center"
                      ml="20px"
                      mt="-2px"
                      title="Tempo gasto para a realização da atividade"
                    >
                      <img
                        src={clockImg}
                        alt="Clock"
                        style={{
                          height: '18px',
                          opacity: '75%',
                        }}
                      />
                      <span
                        style={{ marginLeft: '0.25rem', paddingTop: '3px' }}
                      >
                        {data?.real_allocation}
                      </span>
                    </Flex>
                  </Flex>
                </Flex>
              </span>
            )}
          </Content>

          {children && (
            <Box display="flex" justifyContent={['center', 'start']}>
              <Row>{children}</Row>
            </Box>
          )}
        </Container>
      </CInfoActivity>
      <hr />
    </CActivity>
  );
};

export default Activity;
