import React, { useRef } from 'react';

import { Box, Text, Flex } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import SelectChakra, {
  Option,
} from '~/shared/components/InputChakra/SelectChakra';
import formatSecondsToTime from '~/utils/formatSecondsToTime';

interface ActivityAlertDialogProps {
  msg: string;
  time: number;
  // onChange(time: string): void;
}

const ActivityAlertDialog: React.FC<ActivityAlertDialogProps> = ({
  msg,
  time,
  // onChange,
}) => {
  return (
    <Box>
      <Text mb={4}>{msg}</Text>
      <Flex justifyContent="center" mb={6}>
        <Text fontWeight="bold" fontFamily="monospace" fontSize="28px">
          {formatSecondsToTime(time)}
        </Text>
      </Flex>

      <Text fontSize="sm">
        <b>OBS:</b> Se você fechar este aviso sem confirmar a atividade será
        pausada automaticamente em até 30s após o tempo acima acabar
      </Text>
    </Box>
  );
};

export default ActivityAlertDialog;
