import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  FaBuilding,
  FaCalendarAlt,
  FaClipboard,
  FaClock,
  FaEdit,
  FaEye,
  FaFileAlt,
  FaFlag,
  FaMinus,
  FaSyncAlt,
  FaTrash,
  FaUser,
  FaCheck,
  FaPlay,
} from 'react-icons/fa';
import { FiRotateCw } from 'react-icons/fi';
import { useParams, useLocation } from 'react-router-dom';

import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import queryString from 'query-string';

import { Attendance } from '~/modules/activityExecution/@types/activity';
import Highlights from '~/shared/components/Highlights';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';

interface AttendancesStatus {
  attendances_status_id: number;
  nome: string;
  total: string;
  color: string;
  description: string;
}

interface Planning {
  planning_employee_demand: PlanningEmployeeDemand[];
  activities_not_in_attendance: ActivitiesNotInAttendance[];
  total_activities_expected_duration: TotalActivitiesExpectedDuration[];
  users: Users[];
}

interface PlanningEmployeeDemand {
  employee_attendance_id: number;
  observation?: string;
  duration: string;
  fatal_date?: string;
  fatal_hours?: string;
  employee_situation: number;
  priority: number;
  super_allocation: number;
  attendance_id: number;
  employee_id: number;
  activities_id: number;
  demand_id: number;
  start_attendance?: string;
  end_attendance?: string;
  start_date_planned: string;
  end_date_planned: string;
  start_hours_planned: string;
  end_hours_planned: string;
  minimum_start?: string;
  lapse: number;
  allocation_activities?: string;
  status: string;
  status_id: number;
  name: string;
  activities: string;
  orders: number;
  pop: string;
  activities_description: string;
  color: string;
  format_start_planned?: string;
  format_end_planned?: string;
}

interface TotalActivitiesExpectedDuration {
  preview_duration: string;
  real_duration?: string;
}

interface Users {
  id: number;
  name: string;
  email: string;
  avatar: string;
  avatar_old_name: string;
  cover: string;
  created_at: string;
  cover_old_name: string;
  responsibility: string;
  responsibility_id: number;
  nickname: string;
  department_id: number;
  departmnet_name: string;
  is_active: number;
  value: number;
  label: string;
}

interface ActivitiesNotInAttendance {
  activities_id: number;
  activities: string;
  ordem: number;
  duration: string;
  duration_min?: string;
  type_id: number;
  type_activities: string;
  type_description: string;
}

interface Params {
  attendanceId?: string;
}

interface QueryParams {
  demand_id?: string;
}

const AttendancesPlanning: React.FC = () => {
  const { attendanceId } = useParams<Params>();
  const location = useLocation();

  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const formRef = useRef<FormHandles>(null);
  const formRefReplan = useRef<FormHandles>(null);
  const [attendance, setAttendance] = useState<Attendance>();
  const [employees, setEmployees] = useState<Users[]>([]);
  const [planningEmployeeDemand, setPlanningEmployeeDemand] = useState<
    PlanningEmployeeDemand[]
  >([]);

  const [
    totalActivitiesExpectedDuration,
    setTotalActivitiesExpectedDuration,
  ] = useState<TotalActivitiesExpectedDuration[]>([]);

  const [activitiesNotInAttendance, setActivitiesNotInAttendance] = useState<
    ActivitiesNotInAttendance[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [loadingDemandInfo, setLoadingDemandInfo] = useState(true);

  const {
    isOpen: isOpenReplan,
    onOpen: onOpenReplan,
    onClose: onCloseReplan,
  } = useDisclosure();

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: '#',
      },
      {
        title: 'Funcionário',
      },
      {
        title: 'Planejamento Previsto',
      },
      {
        title: 'Início mínimo',
      },
      {
        title: 'Atividade',
      },
      {
        title: 'Data/Hora Fatal',
      },
      {
        title: 'Status',
      },
      {
        title: 'Planejamento Realizado',
      },
    ];
  }, []);

  const attendancesPlannings = useCallback(() => {
    setLoading(true);
    api
      .get<Planning>(`/attendances/${attendanceId}/plannings`, {
        params: { demand_id: queryParams.demand_id },
      })

      .then((resp) => {
        const {
          planning_employee_demand,
          activities_not_in_attendance,
          total_activities_expected_duration,
          users,
        } = resp.data;

        setPlanningEmployeeDemand(
          planning_employee_demand?.map((planning) => {
            return {
              ...planning,
              start_date_planned: planning.start_date_planned
                ? format(parseISO(planning.start_date_planned), 'dd/MM/yyyy')
                : '',
              duration:
                planning.start_date_planned && planning.duration
                  ? format(
                      parseISO(
                        `${planning.start_date_planned} ${planning.duration}`,
                      ),
                      "HH'h'mm",
                    )
                  : '',
              minimum_start: planning.minimum_start
                ? format(
                    parseISO(planning.minimum_start),
                    "dd/MM/yyyy 'às' HH'h'mm",
                  )
                : 'NÃO',
              fatal_date: planning.fatal_date
                ? format(
                    parseISO(planning.fatal_date),
                    "dd/MM/yyyy 'às' HH'h'mm",
                  )
                : 'NÃO',
              format_start_planned:
                planning.start_date_planned && planning.start_hours_planned
                  ? format(
                      parseISO(
                        `${planning.start_date_planned} ${planning.start_hours_planned}`,
                      ),
                      'HH:mm',
                    )
                  : '',
              format_end_planned:
                planning.end_date_planned && planning.end_hours_planned
                  ? format(
                      parseISO(
                        `${planning.end_date_planned} ${planning.end_hours_planned}`,
                      ),
                      'HH:mm',
                    )
                  : '',
            };
          }),
        );

        setActivitiesNotInAttendance(activities_not_in_attendance);

        setEmployees(
          users?.map((emp) => {
            return {
              ...emp,
              label: emp.name,
              value: emp.id,
            };
          }),
        );

        setTotalActivitiesExpectedDuration(total_activities_expected_duration);
      })

      .finally(() => {
        setLoading(false);
      });
  }, [attendanceId, queryParams.demand_id]);

  const demandInfo = useCallback(() => {
    setLoadingDemandInfo(true);
    api
      .get<Attendance>(`/attendances/${attendanceId}`)
      .then((response) => {
        const { data } = response;

        setAttendance({
          ...data,
          created_at: format(
            parseISO(data.created_at),
            "dd/MM/yyyy 'às' HH'h'mm'min'",
          ),
        });
      })
      .finally(() => setLoadingDemandInfo(false));
  }, [attendanceId]);

  useEffect(() => {
    attendancesPlannings();
    demandInfo();
  }, [attendancesPlannings, demandInfo]);

  const getIcon = useCallback((statusId) => {
    switch (statusId) {
      case 15:
        return <FaPlay color="blue" size={12} />;
      case 16:
        return <FaCheck color="green" size={12} />;

      default:
        return <FaClock size={12} />;
    }
  }, []);

  const getColor = useCallback((statusId) => {
    switch (statusId) {
      case 15:
        return 'blue';
      case 16:
        return 'green';

      default:
        return 'gray';
    }
  }, []);

  return (
    <Box pos="relative">
      {loading && loadingDemandInfo && (
        <LoadingAbsolute z_index={1111} min_height={500} />
      )}
      <SectionHeader
        title="Planejamento"
        pagename="Planejamento para atendimento"
        goBackPage
      >
        <Flex>
          <Button colorScheme="yellow" variant="outline" mr={[0, 4]}>
            <FaEye /> <Text ml="5px">VER ATENDIMENTO</Text>
          </Button>
          <Button variant="outline" colorScheme="cyan">
            <FaSyncAlt /> <Text marginLeft="5px"> DEMANDA RECORRENTE</Text>
          </Button>
        </Flex>
      </SectionHeader>

      <Text fontSize="2xl">{`Atendimento | Codº ${attendanceId}`}</Text>

      <Highlights
        data={[
          {
            icon: FaFlag,
            label: 'Status:',
            text: attendance?.status.name,
          },
        ]}
      />

      <Flex justifyContent="space-between">
        <Highlights
          data={[
            {
              icon: FaClipboard,
              label: 'Demanda:',
              text: attendance?.demand.name,
            },
          ]}
        />
        <Highlights
          data={[
            {
              icon: FaBuilding,
              label: 'Empresa:',
              text: attendance?.company_client?.name,
            },
          ]}
        />
        <Highlights
          data={[
            {
              icon: FaCalendarAlt,
              label: 'Data de solicitação:',
              text: attendance?.created_at,
            },
          ]}
        />
        <Highlights
          data={[
            {
              icon: FaUser,
              label: 'Solicitante',
              text: attendance?.user.name,
            },
          ]}
        />
      </Flex>

      <Flex justifyContent="space-between">
        <Box>
          <Highlights
            data={[
              {
                icon: FaFileAlt,
                label: 'Descrição:',
                text: attendance?.description,
              },
            ]}
          />
        </Box>

        {attendance?.competence && (
          <Box>
            <Badge variant="solid" colorScheme="gray" mr="5px">
              Competência:
            </Badge>
            <Badge>{attendance?.competence}</Badge>
          </Box>
        )}
      </Flex>

      <Form onSubmit={() => ''} ref={formRef}>
        {activitiesNotInAttendance?.map((activities, idx) => {
          const num = idx + 1;
          return (
            <Box mt="30px" key={num}>
              <Tooltip label="Remover" aria-label="Remover" placement="top">
                <Badge variant="solid" colorScheme="red" userSelect="none">
                  <FaMinus />
                </Badge>
              </Tooltip>

              <Flex justifyContent="space-between" mb="20px" mt="10px">
                <Text color="#707486">{`${activities.ordem} - ${activities.activities}`}</Text>

                <Box>
                  <Badge>Tipo:</Badge>
                  <Tooltip
                    label={activities.type_description}
                    aria-label={activities.type_description}
                  >
                    <Badge colorScheme="blue">
                      {activities.type_activities}
                    </Badge>
                  </Tooltip>
                </Box>
              </Flex>

              <Wrap>
                <WrapItem mt="20px">
                  <SelectChakra
                    name={`employee[${activities.ordem}]`}
                    options={employees.map((users) => {
                      return { ...users, label: users.name, value: users.id };
                    })}
                    label="Funcionário"
                    placeholder="Selecione um funcionário"
                  />
                </WrapItem>

                <WrapItem mt="20px">
                  <InputChakra
                    type="datetime-local"
                    label="Início Mínimo"
                    name={`minimum_start[${activities.ordem}]`}
                    width="auto"
                  />
                </WrapItem>
                {/* </Flex> */}

                {/* <Flex justifyContent="center"> */}
                <WrapItem mt="20px">
                  <InputChakra
                    type="datetime-local"
                    label="Data Fatal"
                    name={`fatal_date[${activities.ordem}]`}
                    width="auto"
                  />
                </WrapItem>

                <WrapItem mt="20px">
                  <InputChakra
                    type="time"
                    label="Duração da ativade"
                    name={`activitie_duration[${activities.ordem}]`}
                    width="auto"
                  />
                </WrapItem>
              </Wrap>
            </Box>
          );
        })}

        {/* {planningEmployeeDemand.map((planningEmp) => {
          return ( */}

        <Box mt="30px">
          <Table theadData={tableTitles}>
            {planningEmployeeDemand?.map((planning) => {
              return (
                <Box as="tr" key={planning.orders}>
                  <Box as="td">
                    <Flex justifyContent="center">
                      <IconButton
                        aria-label="editar"
                        icon={<FaEdit />}
                        variant="ghost"
                        colorScheme="yellow"
                        onClick={onOpenReplan}
                      />
                    </Flex>
                  </Box>
                  <Box as="td">
                    <Flex alignItems="center" justifyContent="center">
                      <Box width="16px">
                        <FaEye size="16px" />
                      </Box>
                      <Text ml="5px">{planning.name}</Text>
                    </Flex>
                  </Box>
                  <Box as="td">
                    <Flex justifyContent="center">
                      <Text color="#787f9a">{planning.start_date_planned}</Text>
                    </Flex>
                    <Flex justifyContent="center" alignItems="center">
                      <Badge>{planning.format_start_planned}</Badge>
                      <Text mx={2} color="#787f9a">
                        às
                      </Text>
                      <Badge>{planning.format_end_planned}</Badge>
                      <Text mx={1} color="#787f9a">
                        |
                      </Text>
                      <Tooltip
                        aria-label="Duração da atividade"
                        label="Duração da atividade"
                      >
                        <Text fontSize="sm" as="b">
                          {planning.duration}
                        </Text>
                      </Tooltip>
                    </Flex>
                  </Box>

                  <Box as="td">
                    <Flex justifyContent="center">
                      <Badge
                        colorScheme={
                          planning.minimum_start !== 'NÃO' ? 'green' : ''
                        }
                      >
                        {planning.minimum_start}
                      </Badge>
                    </Flex>
                  </Box>
                  <Box as="td">
                    <Text color="#787f9a">{`${planning.orders} - ${planning.activities}`}</Text>
                  </Box>
                  <Box as="td" justifyContent="center">
                    <Flex justifyContent="center">
                      <Badge
                        colorScheme={
                          planning.fatal_date !== 'NÃO' ? 'yellow' : ''
                        }
                      >
                        {planning.fatal_date}
                      </Badge>
                    </Flex>
                  </Box>
                  <Box as="td">
                    <Flex justifyContent="center">
                      <Tooltip
                        label={planning.status}
                        aria-label={planning.status}
                        placement="top"
                      >
                        {/* <Badge
                          display="flex"
                          alignItems="center"
                          height="20px"
                          width="20px"
                          colorScheme={planning.status_id === 16 ? 'green' : ''}
                        > */}
                        <IconButton
                          aria-label="icon"
                          icon={getIcon(planning.status_id)}
                          variant="ghost"
                          colorScheme={getColor(planning.status_id)}
                          cursor="unset"
                        />
                        {/* </Badge> */}
                      </Tooltip>
                    </Flex>
                  </Box>
                  <Box as="td">
                    <Flex justifyContent="center">
                      {planning.status_id === 14 ? (
                        <IconButton
                          aria-label="Cancelar"
                          icon={<FaTrash />}
                          variant="ghost"
                          colorScheme="red"
                        />
                      ) : (
                        <Tooltip
                          label="Devolução de atividade"
                          aria-label="Devolução"
                          placement="top"
                        >
                          <IconButton
                            aria-label="Cancelar"
                            icon={<FiRotateCw />}
                            variant="ghost"
                            colorScheme="gray"
                          />
                        </Tooltip>
                      )}
                    </Flex>
                  </Box>
                </Box>
              );
            })}
          </Table>
        </Box>

        {/*  );
        })} */}

        <Flex justifyContent="space-between" mt="30px">
          <Checkbox>Confirmar planejamento ?</Checkbox>

          <Button colorScheme="green" variant="solid">
            Registar
          </Button>
        </Flex>
      </Form>

      <ModalChakra
        title="Replanejar Atividade"
        onClose={() => {
          onCloseReplan();
        }}
        isOpen={isOpenReplan}
        titleButtonCancel="Cancelar"
        onSubmit={() => formRefReplan.current?.submitForm()}
      >
        <Form onSubmit={() => ''} ref={formRefReplan}>
          {planningEmployeeDemand?.map((replan) => {
            return (
              <Box key={replan.orders}>
                <Text color="#89889c">Atividade</Text>

                <Text mb="30px" fontSize="2xl">
                  {replan.activities}
                </Text>

                <SelectChakra
                  label="Selecionar Funcionário"
                  options={employees.map((users) => {
                    return { ...users, label: users.name, value: users.id };
                  })}
                  name={`employee[${replan.orders}]`}
                  defaultValue={replan.employee_id}
                />

                <InputChakra
                  type="datetime-local"
                  label="Data e hora início mínimo"
                  name={`minimum_start[${replan.orders}]`}
                  width="auto"
                  defaultValue={replan.minimum_start}
                />
                <Text color="#718096">Data e hora faltal</Text>
                <Flex alignItems="center">
                  <Box mr="20px">
                    <InputChakra
                      type="date"
                      name={`fatal_date[${replan.orders}]`}
                      width="auto"
                      defaultValue={replan.fatal_date}
                    />
                  </Box>

                  <Box>
                    <InputChakra
                      type="time"
                      name={`fatal_time[${replan.orders}]`}
                      width="auto"
                      defaultValue={replan.fatal_hours}
                    />
                  </Box>
                </Flex>

                <InputChakra
                  type="time"
                  label="Duração da ativade"
                  name={`activitie_duration[${replan.orders}]`}
                  width="auto"
                  defaultValue={replan.duration}
                />
              </Box>
            );
          })}
        </Form>
      </ModalChakra>
    </Box>
  );
};

export default AttendancesPlanning;
