import { ChartColor, Scriptable } from 'chart.js';

export type themeGraph = 'delay' | 'alocation' | 'status';

export default function selectTheme(
  theme?: themeGraph,
): ChartColor | ChartColor[] | Scriptable<ChartColor> {
  const delayTheme = [
    '#b22222',
    'rgba(0, 0, 0, 0.15)',
    '#ff0000',
    'rgba(0, 0, 0, 0.06)',
  ];
  const alocationTheme = ['#090979', '#b2dfee94', '#00BFFF', '#b2dfee94'];

  // arrumar cor dos atrasados e pendentes
  const statusActTheme = ['#1A365D', '#ed8946', '#A0AEC0'];

  /* if (typeof theme !== 'string' && theme !== undefined) {
    throw new Error();
  } */

  switch (theme) {
    case 'delay':
      return delayTheme;

    case 'alocation':
      return alocationTheme;

    case 'status':
      return statusActTheme;

    default:
      return alocationTheme;
  }
}

export function selectTwoColorTheme(theme?: themeGraph): string[] {
  const delayTheme = ['#b22222', '#ff0000'];

  const alocationTheme = ['#090979', '#00BFFF'];

  switch (theme) {
    case 'delay':
      return delayTheme;

    case 'alocation':
      return alocationTheme;

    default:
      return alocationTheme;
  }
}
