import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import {
  FaTimesCircle,
  FaPlayCircle,
  FaCheckCircle,
  FaFileExcel,
  FaStopCircle,
  FaFileAlt,
  FaCalendarAlt,
  FaClock,
  FaStopwatch,
  FaRedo,
} from 'react-icons/fa';

import {
  Box,
  Button,
  Flex,
  useToast,
  Text,
  Tooltip,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Empty } from 'antd';
import { format, parseISO } from 'date-fns';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import useLinkStatusAttendance, {
  useUrlLinkStatusAttendance,
} from '~/hooks/useLinkStatusAttendance';
import { Demand } from '~/modules/activityExecution/@types/activity';
import { Company } from '~/modules/management/@types/management';
import AvatarCustom from '~/shared/components/AvatarCustom';
import InputChakra from '~/shared/components/InputChakra';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import exportExcel, { Thead } from '~/utils/exportExcel';
import getValidationErrors from '~/utils/getValidationErrors';

import { IPaginationProps } from '../../../../shared/components/Pagination';
import BadgeStatusAttendance from '../../components/BadgeStatusAttendance';
import BadgeStatusInfo from '../../components/BadgeStatusInfo';

interface FormData {
  demand_id: number;
  client_company_id: number;
  attendance_status: string;
  competence: string;
  order: number;
  start_date: string;
  end_date: string;
  employer_id: number;
}

interface SituationAttedance {
  attendance_id: number;
  archived?: boolean;
  competence: string;
  month_competence: number;
  status_id: number;
  created_at: string;
  status: string;
  status_color: string;
  status_original_color: string;
  count_delay: number;
  count_planning_delay: number;
  count_plannings_employee: number;
}

interface ExtraSituationAttendance {
  id_alterdata: number;
  company_name: string;
  competence: string;
  attendances: SituationAttedance[];
}

interface SelectData extends SelectYear {
  competences: number[];
  competencesSelect: SelectYear[];
}

interface SelectYear {
  id: number;
  name: string;
  value: number;
  label: string;
}

export interface Attendances {
  attendance_id: number;
  demand_id?: number;
  archived?: boolean;
  status_id: number;
  created_at: string;
  status: string;
  status_cor: string;
  date_sets_week: string;
  month: number;
  year: number;
  year_reference: number;
  week_year: number;
  day_week: number;
}

interface AttendanceAdvancedExport {
  id: number;
  competence: string;
  description: string;
  archived: number;
  created_at: string;
  company_id: number;
  company_name: string;
  demand_id: number;
  demand_name: string;
  activity_id: number;
  activity_name: string;
  activity_order: number;
  user_id: number;
  user: string;
  attendance_status_id: number;
  attendance_status: string;
  attendance_status_color: string;
  plan_status_id: number;
  plan_status: string;
  plan_status_color: string;
  real_allocation: string;
  start_date: string;
  end_date: string;
  real_start_date: string;
  real_end_date: string;
}

interface SelectedEmployee {
  id: number;
  name: string;
  avatar?: string;
}

interface DemandSelect extends Demand {
  value: number;
  label: string;
}

interface CompanySelect extends Company {
  value: number;
  label: string;
}

interface Props {
  attendances: ActivityDataProps;
  default: Default[];
  employeers: { id: number; nome: string }[];
}

interface Employeer {
  id: number;
  name: string;
  avatar?: string;
}

interface UserSelect extends Employeer {
  value: number;
  label: string;
}

interface ActivityDataProps extends IPaginationProps {
  employees: UserSelect[];
  data: ActivityProps[];
}

interface ActivityProps {
  atendimento_id: number;
  competencia: string;
  status_id: number;
  status: string;
  status_cor: string;
  descricao: string;
  arquivado: boolean;
  created_at: string;
  company_id: number;
  company_name: string;
  demand_id: number;
  demand_name: string;
  activities: Activity[];
}

interface Activity {
  id: number;
  atendimento_id: number;
  alocacao_real: string;
  alocacao_real_segundos: string;
  atividade_duracao: string;
  data_fatal: string;
  hora_fatal: string;
  status_id: number;
  status_nome: string;
  prioridade: boolean;
  superalocacao: boolean;
  funcionario_id: number;
  atividade_id: number;
  data_inicio_planejado: string;
  data_fim_planejado: string;
  hora_inicio_planejado: string;
  hora_fim_planejado: string;
  inicio_minimo: string;
  lapso: string;
  alocacao_atividade: string;
  fim_atendimento: string;
  devolvida: boolean;
  nome: string;
  icone: string;
  atividade: string;
  ordem: number;
  descricao_atividade: string;
  cor: string;
  nome_original: string;
  devolucao: number;
}

interface Default {
  id: number;
  nome: string;
  pop: string;
  descricao: string;
  obrigatoriedade: boolean;
  duracao: string;
  duracao_min: string;
  ordem: number;
  demanda_id: number;
  atividade_tipo_id: number;
  atividade_caracteristica_id: number;
  anexar_arquivo: boolean;
  enviar_email: boolean;
  created_at: string;
  updated_at: string;
  atividade_tipo_nome: string;
  atividade_caracteristica_nome: string;
}

interface DataSelect {
  demand: DemandSelect[];
  company: CompanySelect[];
  status: {
    id: number;
    name: string;
    value: number;
    label: string;
  }[];
  competence: {
    id: number;
    name: string;
    value: number;
    label: string;
  }[];
}

interface ActivityDefault extends THeadProps {
  ordem: number;
  titleExcel: string;
  ttr: number; // tempo total realizado
  tmr: number; // tempo medio realizado
}

interface TimeTotal extends THeadProps {
  nome: string;
  pop: string;
  descricao: string;
  obrigatoriedade: boolean;
  duracao: string;
  duracao_min: string;
  ordem: number;
  demanda_id: number;
  atividade_tipo_id: number;
  atividade_caracteristica_id: number;
  anexar_arquivo: boolean;
  enviar_email: boolean;
  created_at: string;
  updated_at: string;
  atividade_tipo_nome: string;
  atividade_caracteristica_nome: string;
  tempo_total_realizado: string;
  tempo_medio_realizado: string;
  quantidade: number;
}

const ActivitiesReport: React.FC = () => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const can = useCan();

  const addToast = useToast();
  const linkStatusUrl = useUrlLinkStatusAttendance();

  // const [page, setPage] = useState(0);
  const [selectedDemand, setSelectedDemand] = useState(0);
  const [selectedCompetence, setSelectedCompetence] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState<
    SelectedEmployee | undefined
  >(() => {
    if (!can('list-user')) {
      return {
        id: Number(user.id),
        name: user.name,
        avatar: user.avatar,
      };
    }
    return undefined;
  });
  const [selectedOrder, setSelectedOrder] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [loadingSelect, setLoadingSelect] = useState(true);
  const [loadingCalculator, setLoadingCalculator] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [dataFilters, setDataFilters] = useState<DataSelect>({} as DataSelect);

  /* const [demandFilters, setDemandFilters] = useState<DataSelect[]>([]);
  const [companyFilters, setCompanyFilters] = useState<DataSelect[]>([]); */

  // const [timerActivity, setTimerActivity] = useState<TimeTotal[]>([]);
  const [
    activitiesAttendances,
    setActivitiesAttendances,
  ] = useState<ActivityDataProps>({} as ActivityDataProps);

  const [activitiesDefault, setActivitiesDefault] = useState<ActivityDefault[]>(
    [],
  );

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Empresas',
      },
      {
        title: 'Descrição/Competência',
        width: '300px',
      },
    ];
  }, []); /* .concat(activitiesName); */

  // Manter os dados TTR e TMR para renderizar apenas uma vez indepentende de paginar ou nao (buscar os dados apenas quando filtrar a demanda)
  useEffect(() => {
    if (firstRender) {
      setLoading(true);
    } else {
      setLoadingSelect(true);
    }

    api
      .get<DataSelect>('activities/report/filters', {
        params: {
          demand_id: selectedDemand,
          client_company_id: selectedCompany,
          status_id: selectedStatus,
          competence: selectedCompetence,
        },
      })
      .then((response) => {
        const { data } = response;

        setDataFilters({
          company: data.company.map((comp) => {
            return {
              ...comp,
              label: comp.name,
              value: comp.id,
            };
          }),
          demand: data.demand.map((dem) => {
            return {
              ...dem,
              label: dem.name,
              value: dem.id,
            };
          }),
          status: data.status.map((sts) => {
            return {
              ...sts,
              label: sts.name,
              value: sts.id,
            };
          }),
          competence: data.competence.map((dem) => {
            return {
              ...dem,
              label: dem.name,
              value: dem.id,
            };
          }),
        });
      })
      .finally(() => {
        setFirstRender(false);
        setLoading(false);
        setLoadingSelect(false);
      });
  }, [
    selectedCompany,
    selectedDemand,
    firstRender,
    selectedStatus,
    selectedCompetence,
  ]);

  const calculatorTimer = useCallback(
    async (
      demand_id: number,
      client_company_id: number,
      order: number,
      start_date: string,
      end_date: string,
      employer_id?: number,
    ) => {
      setLoadingCalculator(true);
      const params: {
        demand_id: number;
        client_company_id: number;
        order: number;
        start_date: string;
        end_date: string;
        employer_id?: number;
      } = {
        demand_id,
        client_company_id,
        order,
        start_date,
        end_date,
        employer_id,
      };

      await api
        .get('time/performed', { params })
        .then((response) => {
          const { data } = response;

          setActivitiesDefault((state) => [
            ...state.map((atv: any) => {
              const atvFind = data.find((dt: any) => dt.id === atv.id);

              return {
                ...atv,
                titleExcel: `${atvFind.ordem}.${atvFind.nome}`,
                children: (
                  <Flex flexDir="column">
                    <Flex>
                      <Text fontWeight="extrabold" mr={1}>
                        TTR:
                      </Text>
                      <Text fontWeight="400">
                        {atvFind?.tempo_total_realizado || '00:00:00'}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text fontWeight="extrabold" mr={1}>
                        TMR:
                      </Text>
                      <Text fontWeight="400">
                        {atvFind.tempo_medio_realizado || '00:00:00'}
                      </Text>
                    </Flex>
                  </Flex>
                ),
                badge: atvFind.quantidade,
              };
            }),
          ]);
        })
        .finally(() => setLoadingCalculator(false));
    },
    [],
  );

  const getStatusAttendences = useCallback(
    async (
      numPage: number,
      demand_id: number,
      client_company_id: number,
      attendance_status: string,
      competence: string,
      order: number,
      start_date: string,
      end_date: string,
      employer_id?: number,
    ): Promise<void> => {
      setReportLoading(true);
      // setPage(numPage);
      const params: {
        page: number;
        demand_id: number;
        client_company_id: number;
        attendance_status: string;
        competence: string;
        order: number;
        start_date: string;
        end_date: string;
        employer_id?: number;
      } = {
        page: numPage,
        demand_id,
        client_company_id,
        attendance_status,
        competence,
        order,
        start_date,
        end_date,
      };

      if (employer_id) {
        params.employer_id = employer_id;
      }

      await api
        .get<Props>(`activities/report`, {
          params,
        })
        .then((response) => {
          const activPage = response.data;
          const { attendances, default: def } = activPage;

          if (employer_id) {
            params.employer_id = employer_id;
            const foundEmployee = activitiesAttendances?.employees?.find(
              (emp) => emp.id === employer_id,
            );

            if (foundEmployee) {
              setSelectedEmployee({
                id: employer_id,
                name: foundEmployee.name,
                avatar: foundEmployee?.avatar,
              });
            }
          } else {
            setSelectedEmployee(undefined);
          }

          setActivitiesAttendances({
            ...attendances,
            data: attendances.data.map((att) => {
              return {
                ...att,
                created_at: format(
                  parseISO(att.created_at),
                  "dd/MM/yyyy' às 'HH'h'mm'",
                ),
                activities: att.activities.map((atv) => {
                  /* console.assert(
                    atv.fim_atendimento,
                    `Deu ruim, azedo o caldo ${atv.fim_atendimento}`,
                    atv,
                  ); */
                  return {
                    ...atv,
                    data_inicio_planejado: format(
                      parseISO(atv.data_inicio_planejado),
                      'dd/MM/yyyy',
                    ),
                    data_fim_planejado: format(
                      parseISO(atv.data_fim_planejado),
                      'dd/MM/yyyy',
                    ),
                    fim_atendimento: atv.fim_atendimento
                      ? format(
                          parseISO(atv.fim_atendimento),
                          "dd/MM/yyyy' às 'HH:mm'",
                        )
                      : atv.fim_atendimento, // erro ao pesquisar atualização mensal prime
                  };
                }),
              };
            }),
            employees: activPage.employeers.map((emp) => {
              return { ...emp, value: emp.id, label: emp.nome, name: emp.nome };
            }),
          });

          setActivitiesDefault(
            def.map((actDef: any) => {
              return {
                ...actDef,
                title: `${actDef.ordem}.${actDef.nome}`,
                help: `${actDef.ordem}.${actDef.nome}`,
                ordem: actDef.ordem,
              };
            }),
          );
        })
        .catch((err) => {
          setActivitiesAttendances({} as ActivityDataProps);
          if (err.response.status === 404 && employer_id) {
            /*  addToast({
              position: 'top-right',
              title:,
              status: 'warning',
              isClosable: true,
            }); */
            throw new Error(
              can('list-user')
                ? 'O funcionário selecionado não possui atividades para esta demanda'
                : 'Você não tem atividades na demanda ou empresa selecionada',
            );
          }
          throw err.response.error;
        })
        .finally(() => setReportLoading(false));
    },
    [activitiesAttendances, can],
  );

  /** Define as cores relacionadas aos status de atendimento e planejamento  */
  function getColorCell(statusId: number): string {
    let bg = '';

    switch (statusId) {
      case 14:
        bg = '8a8a8a';
        break;
      case 11:
      case 15:
        bg = 'FF538DD5';
        break;
      case 12:
      case 16:
        bg = 'FF00B050';
        break;
      case 10:
      case 17:
        bg = 'FFFFC000';
        break;

      default:
        bg = 'FF000001';
        break;
    }

    return bg;
  }

  const getFilename = useCallback(
    (exportMode: string): string => {
      const demandName = dataFilters.demand.find(
        (dem) => dem.id === selectedDemand,
      );

      let name = `Relatório de Atividades (${exportMode}) - ${demandName?.name}`;

      if (selectedCompany) {
        name += ` - ${
          dataFilters.company.find((comp) => {
            // console.log(comp.id, selectedCompany);
            return comp.id === Number(selectedCompany);
          })?.name
        }`;
      }

      if (selectedEmployee) name += ` - ${selectedEmployee.name}`;

      if (selectedStartDate || selectedEndDate) {
        const reportStartDate = selectedStartDate
          ? format(parseISO(selectedStartDate), 'dd-MM-yy')
          : 'indeterminado';
        const reportEndDate = selectedEndDate
          ? format(parseISO(selectedEndDate), 'dd-MM-yy')
          : 'indeterminado';

        // console.log(reportStartDate, reportEndDate);

        name += ` de ${reportStartDate} a ${reportEndDate}`;
      }

      return name;
    },
    [
      dataFilters,
      selectedCompany,
      selectedDemand,
      selectedStartDate,
      selectedEndDate,
      selectedEmployee,
    ],
  );

  const handleExportTable = useCallback(
    (dataActivities: ActivityProps[]): void => {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      // Fazer um for utilizando o range para o header

      const demandName = dataFilters.demand.find(
        (dem) => dem.id === selectedDemand,
      );
      const ws: any = {};

      let sheetTitle = `${demandName?.name}`;

      if (selectedEmployee) sheetTitle += ` (${selectedEmployee.name})`;

      const headerHeight = 2; // Linhas que o titulo da tabela ocupa, os dados iniciarão na linha seguinte

      ws.A1 = {
        v: sheetTitle,
        s: {
          font: { bold: true, sz: 18 },
        },
      };

      const rangeData = {
        s: { c: 0, r: 0 },
        e: {
          c: activitiesDefault.length + 1,
          r: dataActivities.length + headerHeight,
        },
      };
      const rangeHeader = {
        s: { c: 0, r: headerHeight },
        e: { c: activitiesDefault.length + 1, r: headerHeight },
      };

      const borderStyle = {
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
        },
      };

      const cellStyles = {
        font: {
          sz: 14,
          color: { rgb: '#FF000000' },
          bold: true,
        },
      };

      const wscols: { wch: number }[] = [];
      // row
      for (let row = rangeHeader.s.r; row <= rangeHeader.e.r; row += 1) {
        for (let col = rangeHeader.s.c; col <= rangeHeader.e.c; col += 1) {
          const cellRef = XLSX.utils.encode_cell({ c: col, r: row });

          if (col === 0) {
            ws[cellRef] = { v: 'Empresas', s: cellStyles };
            wscols.push({ wch: 20 });
          } else if (col === 1) {
            ws[cellRef] = {
              v: 'Competência/Descrição',
              s: cellStyles,
            };
            wscols.push({ wch: 30 });
          } else {
            ws[cellRef] = {
              v: activitiesDefault[col - 2].titleExcel,
              s: {
                ...cellStyles,
                alignment: {
                  horizontal: 'center',
                  vertical: 'center',
                  wrapText: 1,
                },
              },
            };
            wscols.push({ wch: 30 });
          }
        }
      }
      ws['!cols'] = wscols;

      // row
      dataActivities.forEach((comp, iComp) => {
        const rowRef = iComp + (headerHeight + 1);

        const cellRefCompanies = XLSX.utils.encode_cell({ c: 0, r: rowRef });
        const cellRefDesc = XLSX.utils.encode_cell({ c: 1, r: rowRef });

        const link = linkStatusUrl(selectedDemand, comp.atendimento_id);

        ws[cellRefCompanies] = {
          v: [
            `${comp.company_name}\nAten. ${comp.atendimento_id}\n${comp.status}`,
          ],
          s: {
            font: { bold: true },
            alignment: { wrapText: 1 },
          },
          l: {
            Target: link,
            Tooltip: `Clique para ir para o atendimento ${comp.atendimento_id}`,
          },
        };

        let valueCompDesc = '';

        if (comp.descricao) {
          valueCompDesc += `
Descrição: ${comp.descricao}`;
        }

        if (comp.competencia) {
          valueCompDesc += `
Competência: ${comp.competencia}`;
        }

        const createdAt = format(parseISO(comp.created_at), 'dd/MM/yyyy HH:mm');

        valueCompDesc += `
Solicitação: ${createdAt}`;

        ws[cellRefDesc] = {
          v: valueCompDesc,
          s: { alignment: { horizontal: 'center', wrapText: 1 } },
        };

        // column
        activitiesDefault.forEach((atvDefault) => {
          // Verificar se não está considerando atividades devolvidas

          const statusAtv = comp.activities.find(
            (atv) => atv.ordem === atvDefault.ordem,
          );
          const formData = statusAtv?.data_inicio_planejado
            ? format(parseISO(statusAtv.data_inicio_planejado), 'dd/MM/yyyy')
            : statusAtv?.data_inicio_planejado;
          const cellRefDefaultActivity = XLSX.utils.encode_cell({
            c: atvDefault.ordem + 1,
            r: rowRef,
          });

          if (!statusAtv) {
            // Necessário pois o cabeçalho da tabela é feito com o método json_to_sheet a partir dos índices do primeiro objeto do array
            ws[cellRefDefaultActivity] = { v: '' };
          } else {
            const value = `${statusAtv.nome}
${statusAtv.status_nome}
${formData}
Tempo de Execução: ${statusAtv?.alocacao_real || '00:00:00'}`;

            ws[cellRefDefaultActivity] = {
              v: value,
              s: {
                alignment: { wrapText: 1 },
                font: {
                  color: { rgb: getColorCell(statusAtv.status_id) },
                  bold: true,
                },
              },
            };
          }
        });
      });

      ws['!ref'] = XLSX.utils.encode_range(rangeData);
      ws['!autofilter'] = { ref: XLSX.utils.encode_range(rangeHeader) };

      const wb = {
        Sheets: { Plan1: ws },
        SheetNames: ['Plan1'],
      };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, getFilename('original') + fileExtension);
    },
    [
      activitiesDefault,
      dataFilters,
      selectedDemand,
      selectedEmployee,
      linkStatusUrl,
      getFilename,
    ],
  );

  const handleExportSimpleTable = useCallback(
    (dataActivities: ActivityProps[]): void => {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      // Fazer um for utilizando o range para o header

      const demandName = dataFilters.demand.find(
        (dem) => dem.id === selectedDemand,
      );
      const ws: any = {};

      let sheetTitle = `${demandName?.name}`;

      if (selectedEmployee) sheetTitle += ` (${selectedEmployee.name})`;

      const headerHeight = 2; // Linhas que o titulo da tabela ocupa, os dados iniciarão na linha seguinte

      ws.A1 = {
        v: sheetTitle,
        s: {
          font: { bold: true, sz: 18 },
        },
      };

      const rangeData = {
        s: { c: 0, r: 0 },
        e: {
          c: activitiesDefault.length + 5,
          r: dataActivities.length + headerHeight,
        },
      };
      const rangeHeader = {
        s: { c: 0, r: headerHeight },
        e: { c: activitiesDefault.length + 5, r: headerHeight },
      };

      const borderStyle = {
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
        },
      };

      const cellStyles = {
        font: {
          sz: 14,
          color: { rgb: '#FF000000' },
          bold: true,
        },
      };

      const wscols: { wch: number }[] = [];
      // row
      for (let row = rangeHeader.s.r; row <= rangeHeader.e.r; row += 1) {
        for (let col = rangeHeader.s.c; col <= rangeHeader.e.c; col += 1) {
          const cellRef = XLSX.utils.encode_cell({ c: col, r: row });

          switch (col) {
            case 0:
              ws[cellRef] = {
                v: 'Empresa',
                s: { ...cellStyles, ...borderStyle },
              };
              wscols.push({ wch: 35 });
              break;

            case 1:
              ws[cellRef] = {
                v: 'Atendimento',
                s: { ...cellStyles, ...borderStyle },
              };
              wscols.push({ wch: 13 });
              break;

            case 2:
              ws[cellRef] = {
                v: 'Status Atend.',
                s: { ...cellStyles, ...borderStyle },
              };
              wscols.push({ wch: 20 });
              break;

            case 3:
              ws[cellRef] = {
                v: 'Competência',
                s: { ...cellStyles, ...borderStyle },
              };
              wscols.push({ wch: 9 });
              break;

            case 4:
              ws[cellRef] = {
                v: 'Data de solicitação',
                s: { ...cellStyles, ...borderStyle },
              };
              wscols.push({ wch: 30 });
              break;

            case 5:
              ws[cellRef] = {
                v: 'Descrição',
                s: { ...borderStyle, ...cellStyles },
              };
              wscols.push({ wch: 30 });
              break;

            default:
              ws[cellRef] = {
                v: activitiesDefault[col - 6].titleExcel,
                s: {
                  ...borderStyle,
                  ...cellStyles,
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: 1,
                  },
                },
              };
              wscols.push({ wch: 30 });
          }
        }
      }
      ws['!cols'] = wscols;

      // row
      dataActivities.forEach((comp, iComp) => {
        const rowRef = iComp + (headerHeight + 1);

        const cellRefCompany = XLSX.utils.encode_cell({ c: 0, r: rowRef });
        const cellRefAttendanceId = XLSX.utils.encode_cell({ c: 1, r: rowRef });
        const cellRefStatus = XLSX.utils.encode_cell({ c: 2, r: rowRef });
        const cellRefCompetence = XLSX.utils.encode_cell({ c: 3, r: rowRef });
        const cellRefCreatedAt = XLSX.utils.encode_cell({ c: 4, r: rowRef });
        const cellRefDesc = XLSX.utils.encode_cell({ c: 5, r: rowRef });

        ws[cellRefCompany] = {
          v: comp.company_name,
          s: {
            ...borderStyle,
            font: { bold: true },
          },
        };

        const link = linkStatusUrl(selectedDemand, comp.atendimento_id);

        ws[cellRefAttendanceId] = {
          v: comp.atendimento_id,
          s: {
            ...borderStyle,
            font: { bold: true, underline: true },
          },
          l: {
            Target: link,
            Tooltip: `Clique para ir para o atendimento ${comp.atendimento_id}`,
          },
        };

        ws[cellRefStatus] = {
          v: comp.status || '',
          s: {
            ...borderStyle,
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: 1,
            },
            font: {
              color: { rgb: getColorCell(comp.status_id) },
              bold: true,
            },
          },
        };

        ws[cellRefCompetence] = {
          v: comp.competencia || '',
          s: borderStyle,
        };

        ws[cellRefCreatedAt] = {
          v: format(parseISO(comp.created_at), "dd/MM/yyyy HH:mm'"),
          s: {
            ...borderStyle,
            alignment: { wrapText: 1 },
          },
        };

        ws[cellRefDesc] = {
          v: comp.descricao || '',
          s: {
            ...borderStyle,
            alignment: { wrapText: 1 },
          },
        };

        // column
        activitiesDefault.forEach((atvDefault) => {
          // Verificar se não está considerando atividades devolvidas

          const statusAtv = comp.activities.find(
            (atv) => atv.ordem === atvDefault.ordem,
          );

          const cellRefDefaultActivity = XLSX.utils.encode_cell({
            c: atvDefault.ordem + 5,
            r: rowRef,
          });

          if (!statusAtv) {
            // Necessário pois o cabeçalho da tabela é feito com o método json_to_sheet a partir dos índices do primeiro objeto do array
            ws[cellRefDefaultActivity] = {
              v: '',
              s: {
                ...borderStyle,
              },
            };
          } else {
            ws[cellRefDefaultActivity] = {
              v: statusAtv.status_nome,
              s: {
                ...borderStyle,
                alignment: {
                  vertical: 'center',
                  horizontal: 'center',
                  wrapText: 1,
                },
                font: {
                  color: { rgb: getColorCell(statusAtv.status_id) },
                  bold: true,
                },
              },
            };
          }
        });
      });

      ws['!ref'] = XLSX.utils.encode_range(rangeData);
      ws['!autofilter'] = { ref: XLSX.utils.encode_range(rangeHeader) };

      const wb = {
        Sheets: { Plan1: ws },
        SheetNames: ['Plan1'],
      };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, getFilename('simples') + fileExtension);
    },
    [
      activitiesDefault,
      dataFilters.demand,
      selectedDemand,
      selectedEmployee,
      linkStatusUrl,
      getFilename,
    ],
  );

  const handleExportAdvancedTable = useCallback(
    (dataAttendances: AttendanceAdvancedExport[]): void => {
      const demandName = dataFilters.demand.find(
        (dem) => dem.id === selectedDemand,
      );

      const colNames = [
        'Empresa',
        'Atendimento',
        'Status Atend.',
        'Competência',
        'Data de solicitação',
        'Descrição',
        'Atividade',
        'Executante',
        'Status Atv.',
        'Data de início planejamento',
        'Data de fim planejamento',
        'Data de início execução',
        'Data de fim execução',
        'Tempo de execução',
      ];

      exportExcel({
        title: demandName?.name || 'RELATÓRIO DE ATIVIDADES',
        name: getFilename('avançado'),
        colName: colNames.map((thead) => {
          return thead;
        }),
        thead: colNames.map((thead, key) => {
          const titleConfig: Thead = {
            title: thead,
          };

          switch (key) {
            case 0:
              titleConfig.width = 25;
              break;
            case 1:
              titleConfig.width = 13;
              break;
            case 2:
              titleConfig.width = 15;
              break;
            case 3:
              titleConfig.width = 9;
              break;
            case 6:
              titleConfig.width = 60;
              break;
            case 8:
              titleConfig.width = 15;
              break;
            default:
              titleConfig.width = 30;
          }

          return titleConfig;
        }),
        titleDataOrder: [
          'company_name',
          'id',
          'attendance_status',
          'competence',
          'created_at',
          'description',
          'activity_name',
          'user',
          'plan_status',
          'start_date',
          'end_date',
          'real_start_date',
          'real_end_date',
          'real_allocation',
        ],
        data: dataAttendances.map((att) => {
          // console.log(att.created_at);

          const link = linkStatusUrl(selectedDemand, att.id);

          return {
            company_name: att.company_name,
            id: {
              v: att.id.toString(),
              s: { font: { bold: true, underline: true } },
              l: {
                Target: link,
                Tooltip: `Clique para ir para o atendimento ${att.id}`,
              },
            },
            attendance_status: {
              v: att.attendance_status,
              s: {
                font: {
                  color: { rgb: getColorCell(att.attendance_status_id) },
                  bold: true,
                },
              },
            },
            competence: att.competence || '',
            created_at: format(parseISO(att.created_at), 'dd/MM/yyyy HH:mm'),
            description: att.description || '',
            activity_name: `${att.activity_order} - ${att.activity_name}`,
            user: att.user,
            plan_status: {
              v: att.plan_status,
              s: {
                font: {
                  color: { rgb: getColorCell(att.plan_status_id) },
                  bold: true,
                },
              },
            },
            start_date: format(parseISO(att.start_date), 'dd/MM/yyyy'),
            end_date: format(parseISO(att.end_date), 'dd/MM/yyyy'),
            real_start_date: att.real_start_date
              ? format(parseISO(att.real_start_date), 'dd/MM/yyyy HH:mm')
              : '',
            real_end_date: att.real_end_date
              ? format(parseISO(att.real_end_date), 'dd/MM/yyyy HH:mm')
              : '',
            real_allocation: att.real_allocation || '',
          };
        }),
        titleLine: 1,
        enableAutoFilter: true,
      });
    },
    [dataFilters.demand, selectedDemand, linkStatusUrl, getFilename],
  );

  const getStatusAttendencesExcel = useCallback(
    (
      demand_id: number,
      client_company_id: number,
      attendance_status: string,
      competence: string,
      order: number,
      start_date: string,
      end_date: string,
      employer_id,
      exportMode,
    ): void => {
      setExportLoading(true);

      const params: {
        demand_id: number;
        client_company_id: number;
        attendance_status: string;
        competence: string;
        order: number;
        start_date: string;
        end_date: string;
        employer_id?: number;
        advanced_mode?: number;
      } = {
        demand_id,
        client_company_id,
        attendance_status,
        competence,
        order,
        start_date,
        end_date,
        employer_id,
        advanced_mode: exportMode === 3 ? 1 : 0,
      };

      api
        .get(`activities/report`, {
          params,
        })
        .then((response) => {
          const { data } = response;
          // setMonthlyAttendancesExcel(data);
          if (exportMode === 2) {
            handleExportSimpleTable(data.attendances);
          } else if (exportMode === 3) {
            handleExportAdvancedTable(data.attendances);
          } else {
            handleExportTable(data.attendances);
          }

          // setSelectedDemand(demandId);
        })
        .catch((err) => {
          // console.log(err);
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'warning',
            title:
              err.response?.data?.error || 'Não foi possível gerar o relatório',
          });
        })
        .finally(() => setExportLoading(false));
    },
    [
      handleExportTable,
      handleExportSimpleTable,
      handleExportAdvancedTable,
      addToast,
    ],
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          demand_id: Yup.string().required('Necessário escolher uma demanda'),
          // company: Yup.string().required('Necessário escolher uma empresa'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const userId = !can('list-user') ? Number(user.id) : undefined;

        await getStatusAttendences(
          1,
          data.demand_id,
          data.client_company_id,
          data.attendance_status,
          data.competence,
          data.order,
          data.start_date,
          data.end_date,
          data.employer_id ? Number(data.employer_id) : userId,
        );

        await calculatorTimer(
          data.demand_id,
          data.client_company_id,
          data.order,
          data.start_date,
          data.end_date,
          data.employer_id ? Number(data.employer_id) : userId,
        );

        // formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else if (err instanceof Error) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'warning',
            title: err.message,
            // description: 'Limpe o filtro e tente novamente',
          });
        } else {
          // Possivel de nunca chegar, já que todos os erros caem na classe genérica error
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'warning',
            title:
              'Nenhum atendimento encontrado com o filtro realizado' ||
              err.response?.data?.error,
            description: 'Limpe o filtro e tente novamente',
          });
        }
      }
    },
    [getStatusAttendences, calculatorTimer, can, user, addToast],
  );

  const handleCleanFilter = useCallback(() => {
    try {
      formRef.current?.reset();
      setActivitiesAttendances({} as ActivityDataProps);
      setSelectedCompany(0);
      setSelectedDemand(0);
    } catch (err) {
      if (err.response?.status < 500) {
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          isClosable: true,
        });
      }
    }
  }, [addToast]);

  return (
    <Box position="relative" width="full" height="full" minHeight="100vh">
      <SectionHeader
        title="Relatório de Atividades"
        pagename="Visualizar o andamento das atividades das demandas para cada empresa durante um intervalo de datas (Opcional)."
        goBackLink="/dashboard"
      />

      {loading && (
        <Box
          pos="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          bg="rgba(255,255,255,0.5)"
          zIndex={6666}
        >
          <Loading />
        </Box>
      )}

      {!loading && (
        <>
          <Box>
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              initialData={{
                order: '1',
                demand_id: selectedDemand,
                client_company_id: selectedCompany,
              }}
            >
              <Flex
                flexDirection={['column', 'column', 'column', 'row']}
                flex={1}
                width="full"
                alignItems="flex-end"
                // mx={4}
                mb={4}
              >
                <Box mr={[0, 0, 0, 3]} mb={[4, 4, 4, 0]} width="full">
                  <SelectChakra
                    name="demand_id"
                    label="Demanda"
                    placeholder={
                      !loadingSelect ? 'Selecione uma demanda' : 'Carregando...'
                    }
                    options={dataFilters.demand}
                    onChange={(el) => {
                      setSelectedDemand(Number(el.target.value));
                    }}
                    disabled={loadingSelect}
                    mr={3}
                    isInline
                    width="full"
                  />
                </Box>
                <Box width="full" mr={[0, 0, 0, 3]} mb={[4, 4, 4, 0]}>
                  <SelectChakra
                    name="client_company_id"
                    label="Empresa"
                    placeholder={!loadingSelect ? 'Todas' : 'Carregando...'}
                    options={dataFilters.company}
                    onChange={(el) => {
                      setSelectedCompany(Number(el.target.value));
                      // setActivitiesAttendances({} as ReportsAttedanceProps);
                    }}
                    disabled={loadingSelect}
                    mr={3}
                    isInline
                    width="full"
                  />
                </Box>
                <Box
                  mr={[0, 0, 0, 3]}
                  mb={[4, 4, 4, 0]}
                  width="full"
                  // width={['full', 'full', 'full', '150px']}
                  // maxWidth={['full', 'full', 'full', '150px']}
                >
                  <SelectChakra
                    name="competence"
                    label="Competência"
                    placeholder={!loadingSelect ? 'Todas' : 'Carregando...'}
                    options={dataFilters.competence}
                    onChange={(el) => {
                      setSelectedCompetence(el.target.value);
                    }}
                    disabled={loadingSelect}
                    isInline
                    width="full"
                  />
                </Box>
                <Box width="full" mr={[0, 0, 0, 3]} mb={[4, 4, 4, 0]}>
                  <SelectChakra
                    name="attendance_status"
                    label="Status"
                    placeholder={!loadingSelect ? 'Todos' : 'Carregando...'}
                    options={dataFilters.status}
                    onChange={(el) => {
                      setSelectedStatus(el.target.value);
                    }}
                    disabled={loadingSelect}
                    isInline
                    width="full"
                  />
                </Box>
                <Flex
                  width="full"
                  flexDirection={['column', 'column', 'column', 'row']}
                >
                  <Box width="full" mb={[4, 4, 4, 0]}>
                    <InputChakra
                      name="start_date"
                      type="date"
                      label="Data início"
                      onChange={(el) => setSelectedStartDate(el.target.value)}
                      isInline
                      width="full"
                    />
                  </Box>
                  <Box ml={[0, 0, 0, 3]} width="full">
                    <InputChakra
                      name="end_date"
                      type="date"
                      label="Data fim"
                      onChange={(el) => setSelectedEndDate(el.target.value)}
                      isInline
                      width="full"
                    />
                  </Box>
                </Flex>
              </Flex>
              <RadioChakra
                name="order"
                label="Ordene as atividades"
                isInline
                onChangeCustom={(value) => {
                  setSelectedOrder(Number(value));
                }}
                options={[
                  {
                    label: 'Ordem alfabética',
                    value: '1',
                  },
                  {
                    label: 'Mais recentes',
                    value: '2',
                  },
                ]}
              />
              <Flex
                flexDirection={['column', 'column', 'column', 'row']}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  display="flex"
                  justifyItems="flex-end"
                  colorScheme="blue"
                  size="lg"
                  onClick={() => formRef.current?.submitForm()}
                  maxWidth="200px"
                  width="full"
                  mr={[0, 0, 0, 4]}
                  mb={[4, 4, 4, 0]}
                >
                  <Text>Filtrar</Text>
                </Button>

                <Button
                  display="flex"
                  justifyItems="flex-end"
                  colorScheme="gray"
                  size="lg"
                  onClick={() => handleCleanFilter()}
                  maxWidth="200px"
                  width="full"
                >
                  <Text>Limpar filtro</Text>
                </Button>
              </Flex>

              {((!!activitiesAttendances?.data?.length &&
                !!activitiesDefault.length) ||
                reportLoading) && (
                <Flex
                  flexDir={['column', 'column', 'column', 'row']}
                  mb={5}
                  mt={5}
                  justifyContent="space-between"
                  alignItems={['center', 'center', 'center', 'flex-end']}
                >
                  <Box
                    mb={['1.5rem', '1.5rem', '1.5rem', 0]}
                    alignItems="center"
                    display="inline-block"
                  >
                    <Text fontWeight={600}>Legenda:</Text>
                    <Box display="flex" mt={2}>
                      <Box>
                        <Flex alignItems="center">
                          <FaStopCircle color="#ED8936" size={15} />{' '}
                          <Text ml={1}>Pendente</Text>
                        </Flex>
                        <Flex alignItems="center">
                          <FaCheckCircle color="#38A169" size={15} />{' '}
                          <Text ml={1}>Concluido</Text>
                        </Flex>
                      </Box>
                      <Box ml={3}>
                        <Flex alignItems="center">
                          <FaPlayCircle color="#3182ce" size={15} />{' '}
                          <Text ml={1}>Em andamento</Text>
                        </Flex>
                        <Flex alignItems="center">
                          <FaTimesCircle color="#6c757d" size={15} />{' '}
                          <Text ml={1}>Não Iniciado</Text>
                        </Flex>
                      </Box>
                    </Box>
                  </Box>

                  <Flex
                    flexDir={['column', 'column', 'column', 'row']}
                    alignItems={['center', 'center', 'center', 'flex-end']}
                  >
                    {/* Esse componente só será exibido se a pessoa tiver permissão */}
                    <Flex alignItems="center" mb={[4, 4, 4, 0]}>
                      {/* {!!selectedEmployee && page === 1 && reportLoading ? (
                          <AvatarCustom size="sm" />
                        ) : (
                          !!selectedEmployee && (
                            <AvatarCustom
                              src={selectedEmployee?.avatar}
                              name={selectedEmployee?.nome}
                              size="sm"
                            />
                          )
                        )} */}
                      {!!selectedEmployee && (
                        <AvatarCustom
                          src={selectedEmployee?.avatar}
                          name={selectedEmployee?.name}
                          size="sm"
                        />
                      )}
                      {can('list-user') ? (
                        <Flex
                          // minWidth={['auto', '300px']}
                          justifyContent="center"
                          ml={2}
                        >
                          <SelectChakra
                            name="employer_id"
                            sizeCustom="md"
                            placeholder="Funcionário"
                            options={activitiesAttendances.employees}
                            onChange={() => formRef.current?.submitForm()}
                            disabled={reportLoading}
                            isInline
                          />
                        </Flex>
                      ) : (
                        // provisorio, usuarios sem permissao devem conseguir filtrar apenas as demandas em que eles participam(se for possivel)
                        <>
                          {selectedEmployee && (
                            <Flex
                              // minWidth={['auto', '300px']}
                              justifyContent="center"
                              ml={2}
                            >
                              <InputChakra
                                name="current_employee"
                                defaultValue={user.name}
                                sizeCustom="md"
                                readOnly
                                disabled={reportLoading}
                                isInline
                              />
                            </Flex>
                          )}
                        </>
                      )}
                    </Flex>
                    <Menu>
                      <MenuButton
                        as={Button}
                        colorScheme="green"
                        bgColor="green.600"
                        _hover={{ backgroundColor: '#38A169' }}
                        isLoading={exportLoading}
                        disabled={
                          reportLoading || loadingCalculator || exportLoading
                        }
                        ml={[0, 0, 0, 4]}
                        w={['full', 'auto']}
                        size="md"
                      >
                        <Flex alignItems="center">
                          <FaFileExcel />
                          <Text display="inline" ml={2}>
                            Exportar para Excel
                          </Text>
                        </Flex>
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() =>
                            getStatusAttendencesExcel(
                              selectedDemand,
                              selectedCompany,
                              selectedStatus,
                              selectedCompetence,
                              selectedOrder,
                              selectedStartDate,
                              selectedEndDate,
                              selectedEmployee?.id,
                              1,
                            )
                          }
                        >
                          Exportação Original
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            getStatusAttendencesExcel(
                              selectedDemand,
                              selectedCompany,
                              selectedStatus,
                              selectedCompetence,
                              selectedOrder,
                              selectedStartDate,
                              selectedEndDate,
                              selectedEmployee?.id,
                              2,
                            )
                          }
                        >
                          Exportação Simples
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            getStatusAttendencesExcel(
                              selectedDemand,
                              selectedCompany,
                              selectedStatus,
                              selectedCompetence,
                              selectedOrder,
                              selectedStartDate,
                              selectedEndDate,
                              selectedEmployee?.id,
                              3,
                            )
                          }
                        >
                          Exportação Avançada
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
              )}
            </Form>
          </Box>

          {(!!activitiesAttendances?.data?.length &&
            !!activitiesDefault.length) ||
          reportLoading ? (
            <>
              <Table
                id="tableAttendances"
                /* freezeThead */
                loading={reportLoading || exportLoading}
                theadData={tableTitles.concat(activitiesDefault)}
                noOfLines={2}
                tHeadBorder
                pagination={{
                  current_page: activitiesAttendances.current_page,
                  last_page: activitiesAttendances.last_page,
                  per_page: activitiesAttendances.per_page,
                  to: activitiesAttendances.to,
                  total: activitiesAttendances.total,
                }}
                newPage={(pg) =>
                  getStatusAttendences(
                    pg,
                    selectedDemand,
                    selectedCompany,
                    selectedStatus,
                    selectedCompetence,
                    selectedOrder,
                    selectedStartDate,
                    selectedEndDate,
                    selectedEmployee?.id,
                  )
                }
              >
                {!reportLoading &&
                  !!activitiesAttendances?.data?.length &&
                  activitiesAttendances.data.map((actAtt) => {
                    let i = 0;
                    return (
                      <Box
                        as="tr"
                        key={actAtt.atendimento_id}
                        _hover={{ bg: 'gray.50' }}
                      >
                        <Box
                          as="td"
                          p={3}
                          borderWidth={2}
                          borderColor="gray.100"
                          width="170px"
                          verticalAlign="middle"
                          textAlign="center"
                        >
                          <Box marginBottom={2}>
                            <Text as="b">{actAtt.company_name}</Text>
                          </Box>
                          {/* <Text fontSize="sm">
                            Aten: {actAtt.atendimento_id}
                          </Text> */}
                          <BadgeStatusAttendance
                            attendance={{
                              attendance_id: actAtt.atendimento_id,
                              status_id: actAtt.status_id,
                              status: actAtt.status,
                            }}
                            showCodeAttendance
                            variant="statusHighlighted"
                          />
                        </Box>

                        <Box
                          p={2}
                          as="td"
                          borderRight="2px solid #edf2f7"
                          verticalAlign="middle"
                        >
                          <>
                            {actAtt.descricao ? (
                              <Flex alignItems="center" flexDir="column">
                                <Tooltip
                                  aria-label={actAtt.descricao}
                                  label={actAtt.descricao}
                                  zIndex={1}
                                  placement="auto-start"
                                >
                                  <Flex alignItems="center">
                                    <FaFileAlt />
                                    <Text isTruncated maxW={200} ml={2}>
                                      {actAtt.descricao}
                                    </Text>
                                  </Flex>
                                </Tooltip>
                                <Flex alignItems="center">
                                  <FaCalendarAlt />
                                  <Text as="b" ml={2}>
                                    Solicitação:
                                  </Text>
                                </Flex>
                                <Text ml={2}>{actAtt.created_at}</Text>
                              </Flex>
                            ) : (
                              <Flex alignItems="center" flexDir="column">
                                <Flex alignItems="center">
                                  <FaCalendarAlt />
                                  <Text as="b" ml={2}>
                                    Solicitação:
                                  </Text>
                                </Flex>
                                <Text ml={2}>{actAtt.created_at}</Text>
                              </Flex>
                            )}
                            {actAtt.competencia && (
                              <Flex justifyContent="center" mt={3}>
                                <Tag colorScheme="blue">
                                  {actAtt.competencia}
                                </Tag>
                              </Flex>
                            )}
                          </>
                        </Box>

                        <>
                          {activitiesDefault.map((actDef, index) => {
                            // Observar pq estava fazendo repetindo a key da box vazia
                            const atv = actAtt.activities[i];

                            const { length } = actAtt.activities;

                            if (!length || actDef.ordem !== atv.ordem) {
                              return (
                                <Box
                                  as="td"
                                  key={`${
                                    actAtt.atendimento_id
                                  }-${index.toString()}`}
                                />
                              );
                            }

                            if (i < length - 1) {
                              i += 1;
                            }

                            return (
                              <Box
                                key={`${actAtt.atendimento_id}_${atv.ordem}`}
                                as="td"
                                p={3}
                                borderWidth={2}
                                borderColor="gray.100"
                                width="300px"
                                verticalAlign="initial"
                              >
                                <Box
                                  display="flex"
                                  flexDir="column"
                                  alignItems="initial"
                                >
                                  <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    mb={2}
                                  >
                                    <BadgeStatusInfo
                                      onlyIcon
                                      status={atv.status_nome}
                                      statusColor={atv.nome_original}
                                    />
                                    {atv.devolucao && (
                                      <Tooltip
                                        aria-label={actAtt.descricao}
                                        label={actAtt.descricao}
                                        zIndex={1}
                                        placement="auto-start"
                                      >
                                        <>
                                          <FaRedo />
                                          <Text ml={1}>{atv.devolucao}x</Text>
                                        </>
                                      </Tooltip>
                                    )}
                                  </Flex>
                                  <Text
                                    display="flex"
                                    justifyContent="center"
                                    as="b"
                                    isTruncated
                                    minW={100}
                                  >
                                    {atv.nome}
                                  </Text>
                                  {atv.fim_atendimento &&
                                  atv.status_id === 16 ? (
                                    <>
                                      <Flex justifyContent="start" mt={3}>
                                        <FaClock />
                                        <Text fontSize="sm" ml={2}>
                                          {atv.fim_atendimento}
                                        </Text>
                                      </Flex>
                                      <Flex justifyContent="start">
                                        <FaStopwatch />
                                        <Text fontSize="sm" ml={2}>
                                          {atv.alocacao_atividade}
                                        </Text>
                                      </Flex>
                                    </>
                                  ) : (
                                    <>
                                      {atv.data_inicio_planejado ===
                                      atv.data_fim_planejado ? (
                                        <>
                                          <Flex justifyContent="center" mt={2}>
                                            {atv.data_inicio_planejado && (
                                              <Tag>
                                                {atv.data_inicio_planejado}
                                              </Tag>
                                            )}
                                          </Flex>
                                          <Flex justifyContent="center" mt={2}>
                                            <FaStopwatch />
                                            <Text fontSize="sm" ml={2}>
                                              {atv.atividade_duracao}
                                            </Text>
                                          </Flex>
                                        </>
                                      ) : (
                                        <>
                                          <Flex justifyContent="center">
                                            {atv.data_inicio_planejado && (
                                              <Tag>
                                                {atv.data_inicio_planejado}
                                              </Tag>
                                            )}
                                          </Flex>
                                          <Text
                                            textAlign="center"
                                            fontSize="sm"
                                            my={2}
                                          >
                                            Até
                                          </Text>
                                          <Flex justifyContent="center">
                                            {atv.data_fim_planejado && (
                                              <Tag>
                                                {atv.data_fim_planejado}
                                              </Tag>
                                            )}
                                          </Flex>
                                          <Flex justifyContent="center" mt={2}>
                                            <FaStopwatch />
                                            <Text fontSize="sm" ml={2}>
                                              {atv.atividade_duracao}
                                            </Text>
                                          </Flex>
                                        </>
                                      )}
                                    </>
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                        </>
                      </Box>
                    );
                  })}
              </Table>
            </>
          ) : (
            <Empty
              style={{ fontSize: '25px', color: '#838383' }}
              description="Escolha uma demanda, empresa e o periodo para listar as informações relacionadas."
              imageStyle={{ width: '100%', height: 200 }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ActivitiesReport;
