import React, { Fragment } from 'react';
import {
  FiLock,
  FiPlayCircle,
  FiMail,
  FiCheckCircle,
  FiRepeat,
} from 'react-icons/fi';

import { IconButton } from '@chakra-ui/react';

import CheckedPage from '~/shared/components/CheckedPage';

import { ActivityProps, Pop, BeforeActivityProps } from '../../@types/activity';
import Activity from '../Activity';
import TimerControl from '../TimerControl';
import { GroupExecutionButtons } from './style';

interface Props {
  activities: ActivityProps[];
  runningActivity: ActivityProps | null;
  titleNoneActivity: string;
  isSessionUser: boolean;
  pop(data: Pop): void;
  beforeActivity(data: BeforeActivityProps): void;
  attendanceActivities(data: BeforeActivityProps): void;
  planningInfo(activityId: number, companyHasFolderStructure?: boolean): void;
  lastDevolutionInfo(planningId: number, observation?: string): void;
  devolutionActivity(
    attendanceId: number,
    activityId: number,
    activityData: ActivityProps,
  ): void;
  startActivity(activity: ActivityProps): void;
  finishActivity(activity: ActivityProps): void;
  sendEmail(planningId: number): void;
}

const ListActivities: React.FC<Props> = ({
  activities,
  runningActivity,
  isSessionUser,
  pop,
  beforeActivity,
  attendanceActivities,
  titleNoneActivity,
  planningInfo,
  lastDevolutionInfo,
  devolutionActivity,
  startActivity,
  finishActivity,
  sendEmail,
}) => {
  return (
    <>
      {activities.length ? (
        activities.map((activity) => (
          <Fragment key={activity.id}>
            {activity.situation_id !== 19 && (
              <Activity
                data={activity}
                pop={(dataPop) => pop(dataPop)}
                attendanceActivities={() =>
                  attendanceActivities({
                    attendance: activity.attendance_id,
                    activity_id: activity.activity_id,
                    demand: activity.demand,
                    company: activity.company,
                    attendance_id: activity.attendance_id,
                    description: activity.description,
                  })
                }
                viewPlanningInfo={() =>
                  planningInfo(
                    activity.id,
                    activity.company_has_folder_structure,
                  )
                }
                modalDevolution={(id, obs) => lastDevolutionInfo(id, obs)}
              >
                {activity.blocked && activity.situation_id !== 16 && (
                  <IconButton
                    type="button"
                    backgroundColor="red.50"
                    borderColor="red.200"
                    borderWidth={1}
                    py={6}
                    px={4}
                    _focus={{
                      boxShadow: 'none',
                    }}
                    ml={[0, 2]}
                    aria-label="Atividade bloqueada"
                    icon={<FiLock />}
                    variant="ghost"
                    colorScheme="red"
                    size="lg"
                    onClick={() =>
                      beforeActivity({
                        attendance: activity.attendance_id,
                        activity_id: activity.activity_id,
                        demand: activity.demand,
                        company: activity.company,
                        attendance_id: activity.attendance_id,
                        description: activity.description,
                      })
                    }
                    title="Atividade bloqueada até que suas predecessoras sejam finalizadas"
                  />
                )}
                {activity.minimum_start &&
                  !activity.blocked &&
                  activity.situation_id !== 16 && (
                    <TimerControl startTime={activity.minimum_start}>
                      <GroupExecutionButtons>
                        {isSessionUser &&
                          runningActivity?.id !== activity.id &&
                          runningActivity?.situation_id !== 15 && (
                            <IconButton
                              aria-label="Executar atividade"
                              icon={<FiPlayCircle />}
                              variant="ghost"
                              bg="blue.50"
                              size="lg"
                              colorScheme="blue"
                              title="Executar atividade"
                              onClick={() => startActivity(activity)}
                            />
                          )}
                        {!!activity.send_email &&
                          runningActivity?.id === activity.id &&
                          runningActivity?.situation_id === 15 &&
                          isSessionUser && (
                            <IconButton
                              aria-label="Enviar e-mail"
                              icon={<FiMail />}
                              variant="ghost"
                              bg="yellow.50"
                              size="lg"
                              colorScheme="yellow"
                              title="Enviar e-mail"
                              onClick={() => sendEmail(activity.id)}
                            />
                          )}
                        {activity.situation_id !== 14 && (
                          <IconButton
                            aria-label="Finalizar atividade"
                            icon={<FiCheckCircle />}
                            variant="ghost"
                            bg="green.50"
                            size="lg"
                            colorScheme="green"
                            title="Finalizar atividade"
                            onClick={() => finishActivity(activity)}
                          />
                        )}

                        {activity.predecessors === 1 && (
                          <IconButton
                            aria-label="Devolver atividades"
                            icon={<FiRepeat />}
                            variant="ghost"
                            bg="orange.50"
                            size="lg"
                            colorScheme="orange"
                            title="Problemas para realizar a atividade atual por falha em alguma atividade anterior? Escolha as atividades anteriores para devolução imediata"
                            onClick={() =>
                              devolutionActivity(
                                activity.attendance_id,
                                activity.activity_id,
                                activity,
                              )
                            }
                          />
                        )}
                      </GroupExecutionButtons>
                    </TimerControl>
                  )}
                {!activity.minimum_start &&
                  !activity.blocked &&
                  activity.situation_id !== 16 && (
                    <GroupExecutionButtons>
                      {isSessionUser &&
                        runningActivity?.id !== activity.id &&
                        runningActivity?.situation_id !== 15 && (
                          <IconButton
                            aria-label="Executar atividade"
                            icon={<FiPlayCircle />}
                            variant="ghost"
                            bg="blue.50"
                            size="lg"
                            colorScheme="blue"
                            title="Executar atividade"
                            onClick={() => startActivity(activity)}
                          />
                        )}
                      {!!activity.send_email &&
                        runningActivity?.id === activity.id &&
                        runningActivity?.situation_id === 15 &&
                        isSessionUser && (
                          <IconButton
                            aria-label="Enviar e-mail"
                            icon={<FiMail />}
                            variant="ghost"
                            bg="yellow.50"
                            size="lg"
                            colorScheme="yellow"
                            title="Enviar e-mail"
                            onClick={() => sendEmail(activity.id)}
                          />
                        )}
                      {activity.situation_id !== 14 && (
                        <IconButton
                          aria-label="Finalizar atividade"
                          icon={<FiCheckCircle />}
                          variant="ghost"
                          bg="green.50"
                          size="lg"
                          colorScheme="green"
                          title="Finalizar atividade"
                          onClick={() => finishActivity(activity)}
                        />
                      )}

                      {activity.predecessors === 1 && (
                        <IconButton
                          aria-label="Devolver atividades"
                          icon={<FiRepeat />}
                          variant="ghost"
                          bg="orange.50"
                          size="lg"
                          colorScheme="orange"
                          title="Problemas para realizar a atividade atual por falha em alguma atividade anterior? Escolha as atividades anteriores para devolução imediata"
                          onClick={() =>
                            devolutionActivity(
                              activity.attendance_id,
                              activity.activity_id,
                              activity,
                            )
                          }
                        />
                      )}
                    </GroupExecutionButtons>
                  )}
              </Activity>
            )}
          </Fragment>
        ))
      ) : (
        <CheckedPage title={titleNoneActivity} />
      )}
    </>
  );
};

export default ListActivities;
