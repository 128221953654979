import 'react-day-picker/lib/style.css';

import React, { useEffect, useState } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { FiHelpCircle, FiInfo, FiSlash } from 'react-icons/fi';

import { Box, Flex, Skeleton, Text, Tooltip } from '@chakra-ui/react';

import { Container } from './styles';

interface Props {
  disabledDays: Date[];
  onMonthChange(month: Date): void;
  selectedDays: Date;
  onDayClick(day: Date, modifiers: DayModifiers): void;
  initialMonth: Date;
  filterActivated: boolean;
}

const CalendarPlanning: React.FC<Props> = ({
  disabledDays,
  onMonthChange,
  selectedDays,
  onDayClick,
  initialMonth,
  filterActivated,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const intervalId = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => clearInterval(intervalId);
  }, [initialMonth]);

  return (
    <Container>
      {loading ? (
        <Box width="full" height="full">
          <Skeleton width="full" height="20px" mb={1} />

          <Box width="full" height="full" bg="gray.50" pb={2}>
            <Skeleton
              width="full"
              height="59px"
              mb="8px"
              borderBottomRightRadius={0}
              borderBottomLeftRadius={0}
            />

            <Flex justifyContent="space-between" mb={2}>
              <Skeleton width="35px" height="35px" borderRadius="md" />
              <Skeleton width="35px" height="35px" borderRadius="md" />
              <Skeleton width="35px" height="35px" borderRadius="md" />
              <Skeleton width="35px" height="35px" borderRadius="md" />
              <Skeleton width="35px" height="35px" borderRadius="md" />
              <Skeleton width="35px" height="35px" borderRadius="md" />
              <Skeleton width="35px" height="35px" borderRadius="md" />
            </Flex>

            <Flex justifyContent="space-between" mb={2}>
              <Box width="36px" height="40px" bgColor="transparent" />
              <Box width="36px" height="40px" bgColor="transparent" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
            </Flex>

            <Flex justifyContent="space-between" mb={2}>
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
            </Flex>

            <Flex justifyContent="space-between" mb={2}>
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
            </Flex>

            <Flex justifyContent="space-between" mb={2}>
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
            </Flex>

            <Flex justifyContent="space-between" mb={2}>
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Skeleton width="36px" height="40px" borderRadius="md" />
              <Box width="36px" height="40px" bgColor="transparent" />
              <Box width="36px" height="40px" bgColor="transparent" />
            </Flex>
          </Box>
        </Box>
      ) : (
        <>
          <Flex justifyContent="flex-end">
            <Tooltip
              label="Escolha uma data para ver o planejamento ou as atividades que já foram realizadas no dia"
              aria-label="ícone de ajuda"
              placement="top"
            >
              <Flex alignSelf="self-end" alignItems="center">
                <Text fontSize="14px" mr={1} color="#718096">
                  Ajuda
                </Text>
                <FiHelpCircle size={16} color="#718096" />
              </Flex>
            </Tooltip>
          </Flex>

          <DayPicker
            weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
            disabledDays={[{ daysOfWeek: [0] }, ...disabledDays]}
            modifiers={{
              available: { daysOfWeek: [1, 2, 3, 4, 5, 6] },
            }}
            onMonthChange={onMonthChange}
            selectedDays={selectedDays}
            onDayClick={onDayClick}
            initialMonth={initialMonth}
            months={[
              'Janeiro',
              'Fevereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro',
            ]}
          />
        </>
      )}
      {filterActivated && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="full"
          mt="87px"
          height="calc(100% - 87px)"
          background="rgba(255, 255, 255, 0.9)"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Flex
            w="full"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FiSlash size={65} color="#718096" />
            <Flex mt={2} alignItems="center" w="full" justifyContent="center">
              <Text
                fontFamily="Roboto"
                fontSize="15px"
                padding="5px"
                textAlign="center"
                color="#718096"
                lineHeight={1}
                w="full"
              >
                Para usar o calendário, limpe o filtro ativo
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}
    </Container>
  );
};

export default CalendarPlanning;
