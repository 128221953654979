/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiCheck, FiFolder, FiFolderPlus } from 'react-icons/fi';

import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import { ActivityDetails } from '../../pages/PlanningInfo';
import { NewFileDB } from '../AttachedFiles';
import FileList from '../FileList';
import NavigationFolders, {
  ModalFolders,
  BreadcrumbFolder,
  FolderWithCrumbs,
} from '../NavigationFoldersDrive';

export interface FilesClassification {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IFile {
  id: string;
  name: string;
  name_slug?: string;
  readableSize: string;
  originalSize?: number;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  progressCompleted?: boolean;
  basename?: string;
  dirname?: string;
  error?: boolean;
  message?: string;
  url?: string;
  classification_id?: string;
  savedFile?: boolean;
  exists?: boolean;
}

interface DataForm {
  files: IFile[];
}

interface ResponseUploadedFile {
  info_file: NewFileDB;
  error?: boolean;
}

interface Props {
  activityData: ActivityDetails;
  uploadedFiles: IFile[];
  OnUploadedFiles(newFiles: NewFileDB): void;
  updateFile(id: string, data: any): void;
  handleRemove(id: string): void;
  existFileClassification(status: boolean): void;
  title?: string;
}

const FileClassification: React.FC<Props> = ({
  activityData,
  uploadedFiles,
  OnUploadedFiles,
  updateFile,
  handleRemove,
  existFileClassification,
  title,
}) => {
  const addToast = useToast();
  const formRef = useRef<FormHandles>(null);
  const finalRef = React.useRef<HTMLDivElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);

  const [selectedFolder, setSelectedFolder] = useState<FolderWithCrumbs | null>(
    null,
  );

  const [filesClassification, setFilesClassification] = useState<
    FilesClassification[]
  >([]);

  useEffect(() => {
    api.get<FilesClassification[]>('files-classification').then((response) => {
      if (response.data.length) setFilesClassification(response.data);
      else existFileClassification(false);
    });
  }, [existFileClassification]);

  function handleLoading(): boolean {
    if (!loading) return false;

    if (loading && !!uploadedFiles.length) {
      setLoading(false);
      return false;
    }
    if (loading && uploadedFiles.filter((state) => state.error === true)) {
      setLoading(false);
      return false;
    }

    return true;
  }

  // Agora o processo de upload será executado em 2 passos, primeiro subimos os arquivos, tendo sucesso cadastramos suas referencias no BD
  const processUpload = useCallback(
    (dataForm: IFile) => {
      const fileUploaded = uploadedFiles.filter(
        (file) => file.id === dataForm.id,
      );

      const data = new FormData();
      if (fileUploaded[0]?.file) {
        data.append('file', fileUploaded[0]?.file, dataForm.name_slug);
        data.append('folder_google_drive_id', selectedFolder?.folder?.id || '');
        data.append('planning_id', activityData.id.toString());
        data.append('attendance_id', activityData.attendance_id.toString());
        data.append('file_name', dataForm.name);
        data.append('file_name_slug', dataForm.name_slug || '');
        data.append(
          'file_classification_id',
          dataForm.classification_id?.toString() || '',
        );
      }

      api
        .post<ResponseUploadedFile>(`cloud/planning/file/attach`, data, {
          onUploadProgress: (progressEvent) => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );

            /* console.log(
              `O arquivo ${dataForm.name} está ${progress}% carregado... `,
            ); */

            updateFile(dataForm.id, { progress });
          },
        })
        .then((response) => {
          // console.log(
          //   `O arquivo ${dataForm.name} já foi enviado para o servidor!`,
          // );

          updateFile(dataForm.id, { progressCompleted: true });

          if (response.data?.error) {
            updateFile(dataForm.id, {
              error: true,
            });
          } else {
            handleRemove(dataForm.id);

            const { info_file } = response.data;

            OnUploadedFiles(info_file);

            addToast({
              title: `Arquivo ${info_file.file_name} salvo com sucesso!`,
              position: 'top-right',
              isClosable: true,
              status: 'success',
            });
          }
        })
        .catch(() => {
          // console.error(
          //   `Houve um problema ao fazer upload do arquivo ${dataForm.name} no servidor`,
          // );
          // console.log(err);

          // Payload Too Large - código 413 (Arquivo ultrapassou o limite de tamanho - by Laravel)

          updateFile(dataForm.id, {
            error: true,
          });
        });
    },
    [
      addToast,
      updateFile,
      uploadedFiles,
      selectedFolder,
      OnUploadedFiles,
      activityData,
      handleRemove,
    ],
  );

  const handleSubmit = useCallback(
    async (data: DataForm) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          files: Yup.array(
            Yup.object().shape({
              name: Yup.string().required('Necessário nomear o arquivo'),
              classification_id: Yup.string().required(
                'Necessário classificar o arquivo',
              ),
            }),
          ).required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // const { files } = data;

        // const dataUploads = uploadedFiles.map((file) => ({
        //   ...file,
        //   file_classification_id: files?.find(
        //     (f: { file_name_slug: string; file_classification_id: string }) =>
        //       f.file_name_slug === file.name_slug,
        //   ).file_classification_id,
        // }));

        // console.log(files);

        // console.log(dataUploads);
        data.files.forEach(processUpload);
        // await api
        //   .post(`attendance/activities/attach`, {
        //     client_company_id: activityData.company_client_id,
        //     attendance_id: activityData.attendance_id,
        //     planning_id: activityData.id,
        //     user_id: user.id,
        //     data_files: files,
        //     files: uploadedFiles,
        //   })
        //   .then((response) => {
        //     console.log(response.data);
        //   });

        // setUploadedFiles([]);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          // console.log(errors);
          /**
           * files[0].file_classification_id: "Necessário classificar o arquvio"
           * files[1].file_classification_id: "Necessário classificar o arquvio"
           */
          return;
        }

        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
            isClosable: true,
          });

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            'Ocorreu um erro ao realizar o upload dos arquivos, tente novamente.',
          isClosable: true,
        });
      }
    },
    [addToast, processUpload],
  );

  return (
    <>
      <Box width="full" my={5} pb={5} borderBottomWidth={1}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading size="md" color="blue.500">
            {title || 'Classifique os Documentos'}
          </Heading>

          <Flex flexDirection="column" alignItems="center">
            <Tooltip
              aria-label={
                selectedFolder
                  ? 'Os documentos serão salvos na pasta '
                  : 'Selecione a pasta onde serão salvo os documentos'
              }
              label={
                selectedFolder
                  ? 'Os documentos serão salvos na pasta '
                  : 'Selecione a pasta onde serão salvo os documentos'
              }
              placement="auto"
              hasArrow
            >
              <IconButton
                size="lg"
                aria-label="Selecionar pasta"
                icon={<FiFolder />}
                colorScheme={selectedFolder ? 'green' : 'gray'}
                onClick={onOpen}
              />
            </Tooltip>
            {selectedFolder ? (
              <Text fontSize="sm" color="cyan.500" maxWidth="220px" isTruncated>
                {selectedFolder?.folder?.name}
              </Text>
            ) : (
              <Text fontSize="xs" color="red.500">
                Selecione uma pasta
              </Text>
            )}
          </Flex>
        </Flex>
        <Form onSubmit={handleSubmit} ref={formRef}>
          {!!uploadedFiles.length && (
            <>
              <FileList
                files={uploadedFiles}
                attendanceId={activityData.attendance_id}
                // planningId={activityData.id}
                companyClientId={activityData.company_client_id}
                removeFile={(id) => handleRemove(id)}
                selectedFolder={!!selectedFolder}
                filesClassification={filesClassification}
              />
              <Flex justifyContent="flex-end">
                <Button
                  colorScheme="green"
                  onClick={() => formRef.current?.submitForm()}
                  mt={2}
                  mb={4}
                  disabled={!selectedFolder || loading}
                  isLoading={loading}
                >
                  Salvar
                </Button>
              </Flex>
            </>
          )}
        </Form>
      </Box>

      {/* NavigationFolders só é renderizado ao chamar o modal nesse caso  */}
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecionar Pasta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <NavigationFolders
              clientCompany={{
                id: activityData.company_client_id,
                name: activityData.company,
              }}
              previousSelectedFolder={selectedFolder}
              handleSelectedFolder={(folder: FolderWithCrumbs | null) =>
                setSelectedFolder(folder)
              }
              navigationMode="modal"
              onCloseModal={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileClassification;
