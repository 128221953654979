import React from 'react';

import { Empty } from 'antd';

// import emptyPageImg from '~/assets/empty-list.png';

// import { Container, Title } from './styles';

interface Props {
  title?: string;
}

const EmptyPage: React.FC<Props> = ({ title }) => {
  return (
    <Empty
      description={title || false}
      imageStyle={{ width: '100%', height: 400 }}
    />
    /* <Container>
      <img src={emptyPageImg} alt="Nada para exibir" />
      {title && <Title>{title}</Title>}
    </Container> */
  );
};

export default EmptyPage;
