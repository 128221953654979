import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 300px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: auto;
    width: 100%;
    max-width: 450px;
  }
`;

export const Title = styled.h3`
  margin-top: 25px;
  font-size: 16px;
  color: #656565;
`;
