import styled from 'styled-components';

interface StatusProps {
  color: string;
}

interface CardActivityProps {
  selected: boolean;
}

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    background: transparent;
    padding: 10px;
    transition: all 0.2s;

    &:hover {
      background: #eee;
    }

    &:active {
      transform: scale(0.9);
    }
  }
`;

export const Content = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const ContainerCard = styled.div`
  width: 100%;
  margin: 20px 0;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
`;

export const Card = styled.div`
  width: 100%;

  border: 1px solid #f7f7f7;
  border-radius: 5px;

  padding-bottom: 10px;
  text-align: left;

  strong {
    width: 100%;
    height: 30px;
    background: #829aac;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 5px 5px 0 0;
    padding-left: 10px;
  }

  small {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
`;

export const ContentActivity = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const CardActivity = styled.div<CardActivityProps>`
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #f8f9fe;
  margin-bottom: 10px;

  background: ${(props) => (props.selected ? '#f2f8ff' : '#fff')};

  > svg {
    margin: 0 0 0 15px;
  }

  > div {
    //padding-left: 10px;

    h3 {
      font-weight: 500;
      font-size: 16px;
    }

    > span {
      font-size: 13px;
      line-height: 20px;

      display: flex;
      align-items: center;
      vertical-align: middle;

      svg {
        margin-right: 5px;
      }

      &.spotlight {
        color: #169bd6;
        font-weight: 500;
        margin-top: 5px;

        small {
          font-size: 13px;
        }
      }

      strong {
        font-size: 13px;
      }

      small {
        margin-left: 5px;
        font-size: 13px;

        &.colorblue {
          color: #169bd6;
          font-weight: 500;
        }

        span {
          display: flex;
          align-items: center;
        }

        img {
          height: 20px;
          margin-left: 20px;
        }
      }
    }
  }

  .predecessors-list {
    ul {
      list-style-type: none;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;

    > svg {
      margin: 15px 0 0;
    }

    > div {
      padding-left: 0;

      h3 {
        margin-top: 10px;
      }
    }
  }
`;

export const ActivityStatus = styled.div<StatusProps>`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 0 5px 5px 5px;
  border: 1px solid #d5dee5;
  width: 120px;
  margin-left: auto;

  span {
    display: flex;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    margin-right: 10px;
    background: ${(props) => props.color};
  }

  small {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }

  @media (max-width: 480px) {
    margin-right: auto;
    margin-left: auto;
  }
`;

export const AttendanceInfo = styled.div`
  .label {
    color: #6a6e74;
    font-weight: 500;
    font-size: 0.875rem;
  }

  textarea {
    width: 100%;
    border-radius: 5px;
    padding: 0.6rem;
    background-color: #fcfcfc;
  }
`;
