import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { FiEdit, FiMail, FiPlus, FiTrash, FiEye } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  IconButton,
  InputGroup,
  Stack,
  useDisclosure,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import queryString from 'query-string';
import * as Yup from 'yup';

import { Demand } from '~/modules/activityExecution/@types/activity';
import BadgeStatusInfo from '~/modules/dashboard/components/BadgeStatusInfo';
import AlertDialog from '~/shared/components/AlertDialog';
import Input from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import TextareaChakra from '~/shared/components/InputChakra/Textarea';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import { Container } from './styles';

export interface DemandProps extends IPaginationProps {
  data: Demand[];
}

interface StatusSelect {
  value: number;
  label: string;
}

interface QueryParams {
  id?: string;
  pg?: string;
}

const DemandActivities: React.FC = () => {
  const statusDem: StatusSelect[] = [
    { value: 8, label: 'Ativa' },
    { value: 9, label: 'Inativa' },
  ];

  const history = useHistory();
  const location = useLocation();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formRef = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);

  const cancelDeleteRef = useRef(null);
  const btnCreateRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const [demandEdit, setDemandEdit] = useState<Demand>({} as Demand);

  const [demandDelete, setDemandDelete] = useState(0);

  const [modalSuccess, setModalSuccess] = useState(0);

  const [demand, setDemand] = useState<DemandProps>({} as DemandProps);

  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const [page, setPage] = useState(
    queryParams?.pg ? Number(queryParams?.pg) : 1,
  );

  /* const handleGetParams = useCallback((): string => {
    const paramsInfoData = {
      ...queryParams,
    };

    return queryString.stringify(paramsInfoData);
  }, [queryParams]); */

  /* const [steps, setSteps] = useState(1); */

  const addToast = useToast();

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Demanda',
      },
      {
        title: 'Duração',
        help:
          'Representa a duração total das atividades cadastradas na demanda',
      },
      {
        title: 'Descrição',
      },
      {
        title: 'Status',
      },
      {
        title: 'Ações',
      },
    ];
  }, []);

  useEffect(() => {
    setLoaded(false);

    api
      .get(`demand`, {
        params: { page },
      })
      .then((response) => {
        const demandD = response.data;
        setDemand({
          ...demandD,
          data: demandD.data.map((dem: any) => {
            return {
              ...dem,
              name: dem.nome,
              company_id: dem.empresa_id,
              description: dem.descricao,
              total_duration_activities: dem.duracao_total_atividade,
              status: {
                ...dem.status,
                name: dem.status,
                color_id: dem.cor_id,
                icon: dem.icone,
                color: {
                  ...dem.cor,
                  name: dem.nome_original,
                },
              },
            };
          }),
        });
      })

      .finally(() => setLoaded(true));
  }, [page]);

  const handleDemandActivitiesList = (demandId: number): void => {
    history.push({
      pathname: `demandas/${demandId}`,
      search: `pg=${page}`,
    });
  };

  const handleEditDemand = useCallback(
    (data: Demand) => {
      setDemandEdit(data);
      onOpenEdit();
    },
    [onOpenEdit],
  );

  const handleEditDemandForm = useCallback(
    async (data: Demand) => {
      setLoadingEdit(true);
      try {
        formRef.current?.setErrors({});

        const validation = Yup.object().shape({
          name: Yup.string().required('Nome da demanda é obrigatório'),
          status_id: Yup.number().required('Status é obrigatório'),
        });
        await validation.validate(data, {
          abortEarly: false,
        });

        const response = await api.put<Demand>(`demand/${demandEdit.id}`, data);

        const { data: newDemand } = response;

        setDemand((state) => {
          return {
            ...state,
            data: [
              ...state.data.map((dataDemand) => {
                return dataDemand.id === demandEdit.id
                  ? {
                      ...newDemand,
                      total_duration_activities:
                        dataDemand.total_duration_activities,
                    }
                  : dataDemand;
              }),
            ],
          };
        });

        formRef.current?.reset();

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Demanda atualizada!',
          description: 'A Demanda foi atualizada com sucesso.',
          isClosable: true,
        });

        // setLoadReload((state) => state + 1);

        onCloseEdit();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current?.setErrors(errors);
          return;
        }
        const { error } = err.response.data;

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            error || 'Ocorreu um erro ao atualizar a demanda, tente novamente.',
          isClosable: true,
        });
      } finally {
        setLoadingEdit(false);
      }
    },
    [addToast, onCloseEdit, demandEdit.id],
  );

  const handleOpenModalDelete = useCallback(
    (id: number) => {
      setDemandDelete(id);
      onOpenDelete();
    },
    [onOpenDelete],
  );

  const handleSubmitDelete = useCallback(() => {
    if (demand) {
      onCloseDelete();
    }

    api
      .delete(`demand/${demandDelete}`)
      .then(() => {
        const filteredDemand = demand.data.filter((deman) => {
          return deman.id !== demandDelete;
        });
        if (!filteredDemand.length) {
          setPage((state) => state - 1);
        }
        setDemand((state) => {
          return {
            ...state,
            data: filteredDemand,
            total: state.total - 1,
            to: state.to - 1,
          };
        });
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Demanda deletada!',
          description: 'Demanda deletada com sucesso!',
        });

        setModalSuccess(modalSuccess + 1);
      })
      .catch((err) => {
        addToast({
          duration: 10000,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Demanda não deletada!',
          description:
            err.response.data?.error || 'Não foi possível apagar a demanda',
        });
      });
  }, [addToast, modalSuccess, onCloseDelete, demand, demandDelete]);

  const handleCreateDemand = useCallback(
    async (data) => {
      setLoadingCreate(true);
      try {
        formRef.current?.setErrors({});

        const validation = Yup.object().shape({
          name: Yup.string().required('Nome da demanda é obrigatório'),
          status_id: Yup.number().required('Status é obrigatório'),
        });

        await validation.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('demand', data);
        const { data: newDemand } = response;

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Demanda cadastrada!',
          description: 'A demanda foi cadastrada com sucesso.',
          isClosable: true,
        });

        formRef.current?.reset();

        // Como a demanda foi criada agora não é preciso adicionar duração de atividades, já que ainda não possui
        setDemand((state) => {
          return {
            ...state,
            total: state.total + 1,
            to: state.to + 1,
            data: [
              ...state.data,
              { ...newDemand, total_duration_activities: '00h00' },
            ],
          };
        });
        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            err.response?.data?.error ||
            'Ocorreu um erro ao cadastrar a demanda',
          isClosable: true,
        });
      } finally {
        setLoadingCreate(false);
      }
    },
    [addToast, onClose],
  );

  const handleCloseDemand = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Box pos="relative" w="full">
      <Container>
        <SectionHeader
          title="Controle de Demandas"
          /* loading={!loaded} */
          pagename="Demandas"
        >
          <Button
            colorScheme="blue"
            isLoading={!loaded}
            isDisabled={!loaded}
            onClick={onOpen}
            ref={btnCreateRef}
          >
            <FiPlus />
            Cadastrar
          </Button>
        </SectionHeader>
      </Container>
      {!loaded && (
        <Box
          pos="absolute"
          top={0}
          left={0}
          w="full"
          h="500px"
          bg="rgba(255,255,255,0.5)"
        >
          <Loading />
        </Box>
      )}
      {demand && demand?.data?.length && loaded ? (
        <Table
          theadData={tableTitles}
          pagination={{
            current_page: demand.current_page,
            last_page: demand.last_page,
            per_page: demand.per_page,
            to: demand.to,
            total: demand.total,
          }}
          newPage={(pg) => setPage(pg)}
        >
          {loaded &&
            demand.data?.map((dem) => (
              <Box as="tr" key={String(dem.id)} _hover={{ bg: 'gray.50' }}>
                <Box as="td" py={3}>
                  {dem.name}
                </Box>
                <Box as="td" py={3} textAlign="center">
                  {dem.total_duration_activities}
                </Box>
                <Box as="td" py={3}>
                  {dem.description}
                </Box>
                <Box as="td" py={3}>
                  <Box>
                    <BadgeStatusInfo
                      status={dem.status.name}
                      statusColor={dem.status.color.name}
                    />
                  </Box>
                </Box>
                <Box as="td" py={3} className="row">
                  <Flex justifyContent="flex-end">
                    <IconButton
                      aria-label="Visualizar"
                      icon={<FiEye />}
                      variant="ghost"
                      bg="blue.50"
                      ml={[0, 2]}
                      colorScheme="blue"
                      onClick={() => handleDemandActivitiesList(dem.id)}
                    />
                    <IconButton
                      aria-label="Editar"
                      icon={<FiEdit />}
                      variant="ghost"
                      bg="yellow.50"
                      ml={[0, 2]}
                      colorScheme="yellow"
                      onClick={() => handleEditDemand(dem)}
                    />
                    <IconButton
                      aria-label="Deletar"
                      icon={<FiTrash />}
                      variant="ghost"
                      bg="red.50"
                      ml={[0, 2]}
                      colorScheme="red"
                      onClick={() => handleOpenModalDelete(dem.id)}
                    />
                  </Flex>
                </Box>
              </Box>
            ))}
        </Table>
      ) : (
        <Alert status="info" variant="left-accent" mx={-4} my={4}>
          <AlertIcon />
          Ainda não há nenhuma demanda cadastrada
        </Alert>
      )}

      <ModalChakra
        title="Atualizar Demanda"
        onClose={onCloseEdit}
        isOpen={isOpenEdit}
        onSubmit={() => formRefEdit.current?.submitForm()}
        isLoading={loadingEdit}
      >
        <Form
          initialData={demandEdit}
          ref={formRefEdit}
          onSubmit={handleEditDemandForm}
        >
          <FormControl>
            <Input
              icon={FiMail}
              label="Nome"
              name="name"
              type="text"
              placeholder="Digite o nome da demanda"
              autoFocus
              toUpperCase
            />
            <SelectChakra label="Status" options={statusDem} name="status_id" />
            <TextareaChakra
              label="Descrição"
              placeholder="Descrição da Demanda"
              name="description"
            />
          </FormControl>
        </Form>
      </ModalChakra>
      <AlertDialog
        title="Deletar Demanda?"
        description="Tem certeza que deseja deletar a demanda selecionada"
        isOpen={isOpenDelete}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDelete}
        onSubmit={handleSubmitDelete}
      />

      {loaded && (
        <Drawer
          preserveScrollBarGap
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnCreateRef}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              Cadastrando Demanda
            </DrawerHeader>

            <DrawerBody overflowY="auto">
              <Box minH={200}>
                <Form ref={formRef} onSubmit={handleCreateDemand}>
                  <Stack>
                    <InputGroup mb={0}>
                      <Input
                        icon={FiMail}
                        label="Nome"
                        name="name"
                        type="text"
                        placeholder="Digite o nome da demanda"
                        autoFocus
                        toUpperCase
                      />
                    </InputGroup>
                    <SelectChakra
                      options={statusDem}
                      name="status_id"
                      label="Status"
                    />
                    <TextareaChakra
                      label="Descrição"
                      placeholder="Descrição da Demanda"
                      name="description"
                    />
                  </Stack>
                </Form>
              </Box>
            </DrawerBody>

            <DrawerFooter justifyContent="flex-end">
              {loaded && (
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => onClose()}
                  size="lg"
                >
                  Fechar
                </Button>
              )}

              <Button
                variant="solid"
                colorScheme="blue"
                isLoading={loadingCreate}
                /* isDisabled={!loadingCreate} */
                size="lg"
                type="submit"
                ml="auto"
                onClick={() => formRef.current?.submitForm()}
              >
                Finalizar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

export default DemandActivities;
