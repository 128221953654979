import React, { useState, useEffect } from 'react';

import './step.css';
import 'intro.js/introjs.css';
import { Steps, StepsProps } from 'intro.js-react';

import { Replace } from '~/helpers/Replace';

/* Com o uso do replace substituimos alguns tipos que eram obrigatórios para tipos opcionais */
interface StepsIntroProps
  extends Replace<StepsProps, { initialStep?: number }> {
  hideButtons?: boolean;
  showDoneButton?: boolean;
}

const StepsIntro: React.FC<StepsIntroProps> = ({
  enabled,
  steps,
  initialStep = 0,
  onComplete,
  hideButtons,
  onExit,
  showDoneButton = true,
}) => {
  return (
    <Steps
      enabled={enabled}
      steps={steps}
      initialStep={initialStep}
      onExit={onExit}
      onComplete={onComplete}
      options={{
        showButtons: !hideButtons,
        disableInteraction: false,
        nextToDone: showDoneButton,
        prevLabel: 'Anterior',
        nextLabel: 'Próximo',
        doneLabel: 'Concluir',
        /* showStepNumbers: true, */
        hidePrev: true,
        buttonClass: 'introjs-button',
      }}
    />
  );
};

export default StepsIntro;
