import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Flex,
  Skeleton,
  Text,
  Button,
  useToast,
  Link,
  Grid,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import axios from 'axios';
import { subDays, format, parseISO } from 'date-fns';
import Pusher from 'pusher-js';

import { Replace } from '~/helpers/Replace';
import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import DoughnutChart from '~/modules/dashboard/components/Doughnut';
import {
  WorkDeal,
  EvaluationPeriod,
} from '~/modules/gdp/@types/performanceReview';
import BarChartPerformanceReview from '~/shared/components/Chart/BarChartPerformanceReview';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import selectTheme from '~/utils/getThemeGraphics';

import ChartBoxSkeleton from '../../components/ChartBoxSkeleton';
import PieChart from '../../components/PieChart';
import { Demands } from '../AnalyzeDemand';
import { Container, Card, CardBox } from './styles';

interface TotalAct {
  total_delay: number;
  real_delay: number;
  total_paused: number;
  total_pending: number;
  total_running: number;
  total_general: number;
  total_regular: number;
  real_regular: number;
}

interface TotalAlloc {
  alocacao_real_percent: number;
  total_alocacao_real_percent: number;
  alocacao_prevista_percent: number;
  total_alocacao_prevista_percent: number;
}

export interface EvaluationPeriodAvg extends EvaluationPeriod {
  total_score?: number;
  count_concepts?: number;
  avg_score?: number;
}

interface WorkDealSelect {
  id: number;
  year: number;
  evaluation_periods: EvaluationPeriodAvg[];
  value?: number;
  label?: string;
}

type WorkDealAvgEvaluations = Replace<
  WorkDeal,
  { evaluation_periods: EvaluationPeriodAvg[] }
>;

interface CountActivities {
  countActivitiesRunning: number;
  countActivitiesPaused: number;
  countTotalOpenActivities: number;
  countTotalActNotStarted: number;
}

interface TotalEvaluation {
  count_published_evaluations: number;
  count_saved_evaluations: number;
  count_published_notes: number;
  count_saved_notes: number;
}

interface QtyNotes {
  id: number;
  title: string;
  note: number;
  evaluations_count: number;
}

const Dashboard: React.FC = () => {
  const can = useCan();
  const { user } = useAuth();
  const formAvaregeRef = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const addToast = useToast();
  const history = useHistory();

  const [loadingStatusEvaluations, setLoadingStatusEvaluations] = useState(
    true,
  );
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [loadingQtyNotes, setLoadingQtyNotes] = useState(true);
  const [loadingAttendance, setLoadingAttendance] = useState(true);
  const [loadingDelay, setLoadingDelay] = useState(true);
  const [loadingAllocation, setLoadingAllocation] = useState(true);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [loadingAverageEvaluation, setLoadingAverageEvaluation] = useState(
    true,
  );
  const [loadingYearSelected, setLoadingYearSelected] = useState(false);

  const [evaluations, setEvaluations] = useState<number[]>([]);
  const [notes, setNotes] = useState<number[]>([]);

  const [periodId, setPeriodId] = useState('');
  const [year, setYear] = useState('');

  const [statusRunning, setStatusRunning] = useState(0);
  const [statusPaused, setStatusPaused] = useState(0);
  const [statusTotalNotStarted, setStatusTotalNotStarted] = useState(0);
  const [totalStatus, setTotalStatus] = useState<number[]>([]);
  const [totalStatusDelay, setTotalStatusDelay] = useState<number[]>([]);
  const [realStatusDelay, setRealStatusDelay] = useState<number[]>([]);
  const [totalAllocation, setTotalAllocation] = useState<number[]>([0, 0]);
  const [realAlocation, setRealAlocation] = useState<number[]>([0, 0]);

  const [workDeals, setWorkDeals] = useState<WorkDealAvgEvaluations[]>([]);
  const [lastWorkDeals, setLastWorkDeal] = useState<WorkDealSelect>();
  const [
    lastEvaluationPeriod,
    setLastEvaluationPeriod,
  ] = useState<EvaluationPeriodAvg>();
  const [evaluationPeriods, setEvaluationPeriods] = useState<
    EvaluationPeriodAvg[]
  >([]);
  const [selectedEvaluationPeriods, setSelectedEvaluationPeriods] = useState<
    EvaluationPeriod[]
  >([]);
  const [selectYear, setselectYear] = useState(0);
  const [workDealsSelect, setWorkDealsSelect] = useState<WorkDealSelect[]>([]);
  const [quantityNotes, setQuantityNotes] = useState<QtyNotes[]>([]);
  /* const [selectedWorkDeal, setSelectedWorkDeal] = useState<WorkDealSelect>(
    {} as WorkDealSelect,
  ); */
  // const [demands, setDemands] = useState<OptionDemand[]>([]);

  // const [paused, setPaused] = useState(0);

  const currentYear = format(new Date(), 'yyyy');

  const canCreatePerformanceReviews = can('create-performance-reviews');

  const name = useMemo(() => {
    return user.name.split(' ')[0];
  }, [user.name]);

  const legendEvaluationsQtyChartLabes = useMemo(() => {
    return {
      labels: [
        'Avaliação publicada',
        'Avaliação salvas',
        'Notas publicadas',
        'Notas salvas',
      ],
      dataLabels: [
        'published_evaluations',
        'saved_evaluations',
        'published_notes',
        'saved_notes',
      ],
    };
  }, []);
  const legendQtyChartLabes = useMemo(() => {
    return {
      labels: [
        'Muito abaixo do esperado',
        'Pouco abaixo do esperado',
        'Desempenho esperado',
        'Pouco acima do esperado',
        'Muito acima do esperado',
      ],
      dataLabels: [
        'much_lower_than_expected',
        'lower_than_expected',
        'expected_performance',
        'slightly_above_expected',
        'much_higher_than_expected',
      ],
    };
  }, []);
  const demandChartLabes = useMemo(() => {
    return {
      labels: [
        'Atrasadas planejamento',
        'Atrasada data fatal',
        'Previsão de atraso',
        'Em execução',
      ],
      dataLabels: [
        'delayed',
        'delayed_fatal_date',
        'delay_forecast',
        'running',
      ],
    };
  }, []);
  const delayActivities = useMemo(() => {
    return {
      labels: ['Atrasadas', 'No prazo', 'Atrasadas Reais', 'Outras'],
      dataLabels: ['delayed', 'regular', 'real_delay', 'real_regular'],
    };
  }, []);
  const allocationTotal = useMemo(() => {
    return {
      labels: [
        'Alocação Prevista',
        'Alocação Prevista Restante',
        'Alocação Realizada',
        'Alocação Realizada Restante',
      ],
      dataLabels: [
        'allocation_prevista',
        'allocation_prev',
        'allocation_real',
        'allocation',
      ],
    };
  }, []);

  const loadDemandsData = useCallback((source: any) => {
    setLoadingAttendance(true);

    api
      .get<Demands>('analytic/demand', {
        cancelToken: source.token,
      })
      .then((response) => {
        const dataDemand = response.data;
        const optionsDemands = dataDemand.demands?.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        const {
          delayed,
          delayed_fatal_date,
          delay_forecast,
          running,
        } = dataDemand.total;

        // setDemands([{ label: 'Todas', value: 'all' }, ...optionsDemands]);
        setTotalStatus([delayed, delayed_fatal_date, delay_forecast, running]);
      })
      .finally(() => {
        setLoadingAttendance(false);
      });
  }, []);

  const loadDemandsDelay = useCallback((source: any) => {
    setLoadingDelay(true);

    api
      .get<TotalAct>('analytic/activities/delay', {
        cancelToken: source.token,
      })
      .then((response) => {
        /* setDelay(total); */
        const {
          total_delay,
          total_regular,
          real_regular,
          real_delay,
        } = response.data;
        setTotalStatusDelay([total_delay, total_regular]);
        setRealStatusDelay([real_delay, real_regular]);
      })
      .finally(() => {
        setLoadingDelay(false);
      });
  }, []);

  const loadAllocation = useCallback((source: any) => {
    setLoadingAllocation(true);

    const currentDate = new Date();
    const startDate = subDays(currentDate, 7);

    const format_currentDate = format(currentDate, 'yyyy-MM-dd');
    const format_startDate = format(startDate, 'yyyy-MM-dd');

    api
      .get<TotalAlloc>('allocation', {
        cancelToken: source.token,
        params: { start_date: format_startDate, end_date: format_currentDate },
      })
      .then((response) => {
        const {
          alocacao_prevista_percent,
          total_alocacao_prevista_percent,
          alocacao_real_percent,
          total_alocacao_real_percent,
        } = response?.data;
        // console.log(response.data);

        const alloc_prev = alocacao_prevista_percent
          ? alocacao_prevista_percent.toFixed(1)
          : 0;
        const total_prev = total_alocacao_prevista_percent
          ? total_alocacao_prevista_percent.toFixed(1)
          : 100;
        const alloc_real = alocacao_real_percent
          ? alocacao_real_percent.toFixed(1)
          : 0;
        const total_real = total_alocacao_real_percent
          ? total_alocacao_real_percent.toFixed(1)
          : 100;
        setTotalAllocation([Number(alloc_prev), Number(total_prev)]);
        setRealAlocation([Number(alloc_real), Number(total_real)]);
      })
      .finally(() => {
        setLoadingAllocation(false);
      });
  }, []);
  const statusChartLabes = useMemo(() => {
    return {
      labels: [
        'Atividades em execução',
        'Atividades Pausadas',
        'Atividades Pendentes',
      ],
      dataLabels: ['running', 'paused', 'pending'],
    };
  }, []);

  const statusActivities = useMemo(() => {
    return {
      labels: ['Em execução', 'Pausadas'],
      dataLabels: ['running', 'paused'],
    };
  }, []);

  const loadActivities = useCallback(async (source: any) => {
    setLoadingActivities(true);

    await api
      .get<CountActivities>('/analytic/activities/status', {
        cancelToken: source.token,
      })
      .then((response) => {
        const {
          countActivitiesRunning,
          countActivitiesPaused,
          countTotalActNotStarted,
        } = response.data;
        setStatusRunning(countActivitiesRunning);
        setStatusPaused(countActivitiesPaused);
        setStatusTotalNotStarted(countTotalActNotStarted);
      })
      .finally(() => {
        setLoadingActivities(false);
      });
  }, []);

  const handleAvarage = useCallback(
    (data) => {
      const { work_deal_year } = data;
      setLoadingAverageEvaluation(true);
      api
        .get<WorkDealAvgEvaluations[]>('/analytic/performance-reviews/avg', {
          params: {
            user_id: user.id,
            year: work_deal_year || currentYear,
          },
        })
        .then((resp) => {
          const workDeal = resp.data;

          setWorkDeals(workDeal);

          const evTotalEvaluationPeriods: EvaluationPeriodAvg[] = [];

          workDeal.forEach((wd) => {
            evTotalEvaluationPeriods.push(...wd.evaluation_periods);
          });

          setEvaluationPeriods(evTotalEvaluationPeriods);
        })
        .finally(() => setLoadingAverageEvaluation(false));
    },
    [user.id, currentYear],
  );

  const handlePerformanceReviewQtyNotes = useCallback(
    async (period_id) => {
      setLoadingQtyNotes(true);
      await api
        .get('/analytic/performance-reviews/notes/qty', {
          params: {
            user_id: user.id,
            evaluation_period_id: period_id,
          },
        })
        .then((response) => {
          const { data } = response;
          // console.log(data);

          setQuantityNotes(data);
        })
        .finally(() => {
          setLoadingQtyNotes(false);
        });
    },
    [user.id],
  );

  const performanceReviewEvaluations = useCallback(
    async (yearSelect, period) => {
      setLoadingStatusEvaluations(true);
      await api
        .get<TotalEvaluation>('analytic/performance-reviews/evaluations/qty', {
          params: {
            evaluator_user_id: user.id,
            work_deal_id: lastEvaluationPeriod
              ? lastEvaluationPeriod.work_deal_id
              : yearSelect,
            evaluation_period_id: period,
          },
        })
        .then((response) => {
          const {
            count_published_evaluations,
            count_published_notes,
            count_saved_evaluations,
            count_saved_notes,
          } = response.data;
          setEvaluations([
            count_published_evaluations,
            count_saved_evaluations,
          ]);
          setNotes([count_published_notes, count_saved_notes]);
        })
        .finally(() => setLoadingStatusEvaluations(false));
    },
    [user.id, lastEvaluationPeriod],
  );

  const performanceReviewQty = useCallback(async () => {
    setLoadingStatusEvaluations(true);
    if (lastEvaluationPeriod) {
      await api
        .get<TotalEvaluation>('analytic/performance-reviews/evaluations/qty', {
          params: {
            evaluator_user_id: user.id,
            work_deal_id: lastEvaluationPeriod.work_deal_id,
            evaluation_period_id: lastEvaluationPeriod.id,
          },
        })
        .then((response) => {
          const {
            count_published_evaluations,
            count_published_notes,
            count_saved_evaluations,
            count_saved_notes,
          } = response.data;
          setEvaluations([
            count_published_evaluations,
            count_saved_evaluations,
          ]);
          setNotes([count_published_notes, count_saved_notes]);
        })
        .finally(() => setLoadingStatusEvaluations(false));
    }
  }, [user.id, lastEvaluationPeriod]);

  const performanceReviewOptions = useCallback(async () => {
    setLoadingOptions(true);

    /* Passando o param user_id busca apenas os anos em que o funcionário teve avaliação,
    caso ele não possua avaliação ainda, não será exibido o select */
    await api
      .get<WorkDealSelect[]>('/reports/performance-reviews/options', {
        params: {
          user_id: user.id,
          is_evaluator: canCreatePerformanceReviews ? 1 : 0,
        },
      })
      .then((response) => {
        const { data } = response;
        setWorkDealsSelect(data);

        if (user.is_subscribed) {
          const lastData = data.length;
          const dataWorkDeal = data[lastData - 1];
          const lastEvaluationPeriodData =
            dataWorkDeal?.evaluation_periods.length;
          const dataEvaluation =
            dataWorkDeal.evaluation_periods[lastEvaluationPeriodData - 1];

          setLastWorkDeal(data[lastData - 1]);
          setLastEvaluationPeriod(dataEvaluation);
        }

        setSelectedEvaluationPeriods(
          data.flatMap((wd) => wd.evaluation_periods),
        );

        if (data[0]?.year) handleAvarage(data[0]?.year);
      })
      .finally(() => {
        setLoadingOptions(false);
      });
  }, [user.id, handleAvarage, canCreatePerformanceReviews, user.is_subscribed]);

  /* // Private channel configuration
  useEffect(() => {
    // console.log(localStorage.getItem('@MyIPAC:token'));

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_ENV || '', {
      cluster: 'sa1',
    });

    const channelUser = `bsc-channel`;

    const channel = pusher.subscribe(channelUser);
    // You can bind more channels here like this

    channel.bind('new-bsc-score', (data: any) => {
      // Exibe a notificação
      if (Notification.permission === 'granted' && !!data?.message) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const notification = new Notification('MyIPAC', { body: data.message });
      }
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [user.id]); */

  useEffect(() => {
    performanceReviewOptions();
  }, [performanceReviewOptions]);

  useEffect(() => {
    setLoadingStatusEvaluations(true);
    if (lastEvaluationPeriod) {
      api
        .get<TotalEvaluation>('analytic/performance-reviews/evaluations/qty', {
          params: {
            evaluator_user_id: user.id,
            work_deal_id: lastEvaluationPeriod.work_deal_id,
            evaluation_period_id: lastEvaluationPeriod.id,
          },
        })
        .then((response) => {
          const {
            count_published_evaluations,
            count_published_notes,
            count_saved_evaluations,
            count_saved_notes,
          } = response.data;
          setEvaluations([
            count_published_evaluations,
            count_saved_evaluations,
          ]);
          setNotes([count_published_notes, count_saved_notes]);
        })
        .finally(() => setLoadingStatusEvaluations(false));
    }
  }, [user.id, lastEvaluationPeriod]);

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    async function getDashboardGraph(): Promise<void> {
      await loadActivities(source);
      await loadDemandsDelay(source);
      await loadDemandsData(source);
      await loadAllocation(source);
    }

    getDashboardGraph();

    return () => {
      source.cancel('Cancelado pelo usuário');
    };
  }, [loadDemandsData, loadDemandsDelay, loadAllocation, loadActivities]);

  const handleSelectDemand = useCallback(
    (value: string | number): void => {
      const indexLabel = demandChartLabes.dataLabels.findIndex(
        (label) => label === value,
      );
    },
    [demandChartLabes],
  );

  const handleSelectDemandDelay = useCallback(
    (value: string | number): void => {
      const indexLabel = delayActivities.dataLabels.findIndex(
        (label) => label === value,
      );
    },
    [delayActivities],
  );

  const handleYearSelected = useCallback(
    (data) => {
      setLoadingYearSelected(true);
      setYear(data.work_deal_year);

      setSelectedEvaluationPeriods(
        workDealsSelect.flatMap((wd) => wd.evaluation_periods),
      );

      setLoadingYearSelected(false);
    },
    [workDealsSelect],
  );

  return (
    <Container>
      <SectionHeader
        title={`Olá ${name}, Bem vindo(a)!`}
        pagename="Painel de controle"
      />
      {user.is_subscribed && (
        <>
          {canCreatePerformanceReviews && lastEvaluationPeriod && (
            <Box>
              <Form
                initialData={{
                  work_deal_year: lastEvaluationPeriod.work_deal_id.toString(),
                  period_id: '5',
                }}
                onSubmit={handleYearSelected}
                ref={formRef}
              >
                <Flex flexDir={['column', 'row']} maxWidth="580px">
                  {!loadingOptions && (
                    <>
                      <SelectChakra
                        name="work_deal_year"
                        label="Ano da Avaliação"
                        placeholder="selecione"
                        options={workDealsSelect.map((wd) => {
                          return {
                            ...wd,
                            value: wd.id.toString(),
                            label: wd.year.toString(),
                          };
                        })}
                        // onChange={() => handleYearSelected()}
                        onChange={() => formRef.current?.submitForm()}
                      />
                      <SelectChakra
                        width="300px"
                        name="period_id"
                        label="Periodos avaliativos"
                        placeholder="Selecione o ano da avaliação"
                        options={selectedEvaluationPeriods.map((wd) => {
                          return {
                            ...wd,
                            value: wd.id.toString(),
                            label: `${format(
                              parseISO(wd.start_date),
                              'dd/MM/yyyy',
                            )} até ${format(
                              parseISO(wd.end_date),
                              'dd/MM/yyyy',
                            )}`,
                          };
                        })}
                        onChange={(el) => {
                          setPeriodId(el.currentTarget.value);
                          performanceReviewEvaluations(
                            year,
                            el.currentTarget.value,
                          );
                        }}
                      />
                    </>
                  )}
                  {/* : (
                <Box>
                  <Text>Periodos avaliativos</Text>
                  <Skeleton width="288px" height="48px" />
                </Box>
              )} */}
                </Flex>
              </Form>
              <Flex justifyContent="center" mx="50px">
                {!loadingStatusEvaluations ? (
                  <DoughnutChart
                    data={{
                      datasets: [
                        {
                          data: [...evaluations, 0, 0],
                          label: 'Dataset 1',
                        },
                        {
                          data: [0, 0, ...notes],
                          label: 'Dataset 2',
                        },
                      ],
                      title: 'Status de Avaliação',
                      labels: legendEvaluationsQtyChartLabes.labels,
                      dataLabels: legendEvaluationsQtyChartLabes.dataLabels,
                    }}
                    selectChart={() => null}
                    height={25}
                    width={25}
                  />
                ) : (
                  <ChartBoxSkeleton title="Status de Avaliação" />
                )}
              </Flex>
            </Box>
          )}
          <Box>
            {!!workDealsSelect.length && (
              <>
                {!loadingOptions && (
                  <>
                    <Form onSubmit={handleAvarage} ref={formAvaregeRef}>
                      <Flex maxWidth="400px">
                        <SelectChakra
                          name="work_deal_year"
                          label="Ano da Avaliação"
                          options={workDealsSelect.map((wd) => {
                            return {
                              ...wd,
                              value: wd.year.toString(),
                              label: wd.year.toString(),
                            };
                          })}
                          onChange={() => formAvaregeRef.current?.submitForm()}
                          // defaultValue={selectedWorkDeal?.id?.toString()}
                        />
                      </Flex>
                    </Form>
                    <Flex
                      flexDir={['column', 'row']}
                      align="center"
                      justifyContent="space-around"
                      id="containerEmployeeBsc"
                      width="100%"
                      height={['auto', '400px']}
                      mt={[3, 0]}
                      mb="80px"
                    >
                      <Box>
                        <BarChartPerformanceReview
                          id={1}
                          title="Média das avaliações"
                          name="average_evaluations"
                          stepSize={1}
                          maxYAxes={5}
                          loading={loadingAverageEvaluation}
                          averagePoints={workDeals.flatMap((wd) => {
                            return wd.evaluation_periods;
                          })}
                          selectChart={(value) =>
                            handlePerformanceReviewQtyNotes(value)
                          }
                        />
                      </Box>
                      {quantityNotes.length ? (
                        <PieChart
                          data={{
                            values: quantityNotes.map((qty) => {
                              return !loadingQtyNotes
                                ? qty.evaluations_count
                                : 0;
                            }),
                            title: 'Quantidades em suas respectivas notas',
                            labels: legendQtyChartLabes.labels,
                            dataLabels: legendQtyChartLabes.dataLabels,
                          }}
                          selectChart={() => ''}
                          height={25}
                          width={25}
                        />
                      ) : (
                        <Flex justifyContent="center">
                          <Text fontSize="2xl">
                            Clique em uma barra do grafico.
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </>
                )}
              </>
            )}
          </Box>
        </>
      )}

      {!user.is_subscribed && (
        <Grid
          gridTemplateColumns={[
            'repeat(auto-fit, minmax(250px, 500px))',
            'repeat(auto-fit, minmax(250px, 500px))',
            'repeat(auto-fit, minmax(250px, 500px))',
            'repeat(2, minmax(250px, 500px))',
          ]}
          // gridTemplateRows={['390px', '515px', '515px', '440px', '515px']}
          gap="20px"
          justifyContent="space-evenly"
          /* flexDir={['column', 'column', 'column', 'column', 'row']} */
        >
          {!loadingAttendance ? (
            <>
              {!!totalStatus.length &&
                !totalStatus.every((status) => status === 0) && (
                  <Box>
                    <Card to="/relatorios/analise-de-demandas">
                      <DoughnutChart
                        data={{
                          datasets: [{ data: totalStatus }],
                          title: 'Atendimentos por status',
                          labels: demandChartLabes.labels,
                          dataLabels: demandChartLabes.dataLabels,
                        }}
                        selectChart={(value) => handleSelectDemand(value)}
                        height={25}
                        width={25}
                      />
                    </Card>
                  </Box>
                )}
            </>
          ) : (
            <ChartBoxSkeleton title="Atendimentos por Status" />
          )}
          {!loadingDelay ? (
            <>
              {!!totalStatusDelay.length &&
                !totalStatusDelay.every((statusDelay) => statusDelay === 0) && (
                  <Box>
                    <Card to="/relatorios/analise-de-atividades">
                      <DoughnutChart
                        colors={selectTheme('delay')}
                        data={{
                          datasets: [
                            {
                              data: [...totalStatusDelay, 0, 0],
                              label: 'Dataset 1',
                            },
                            {
                              data: [0, 0, ...realStatusDelay],
                              label: 'Dataset 2',
                            },
                          ],
                          title: 'Total Atrasadas',
                          labels: delayActivities.labels,
                          dataLabels: delayActivities.dataLabels,
                        }}
                        height={25}
                        width={25}
                        selectChart={() => ''}
                      />
                    </Card>
                  </Box>
                )}
            </>
          ) : (
            <ChartBoxSkeleton title="Total Atrasadas" />
          )}
          {!loadingAllocation ? (
            <>
              {/*   {!!totalAllocation.length &&
              (totalAllocation[0] > 0 || realAlocation[0] > 0) && ( */}
              <Box>
                <Card to="/relatorios/alocacao">
                  <DoughnutChart
                    colors={selectTheme('alocation')}
                    data={{
                      datasets: [
                        {
                          data: [...totalAllocation, null, null],
                          label: 'Dataset 1',
                        },
                        {
                          data: [null, null, ...realAlocation],
                          label: 'Dataset 2',
                        },
                      ],
                      title: 'Alocação Geral de 7 dias',
                      labels: allocationTotal.labels,
                      dataLabels: allocationTotal.dataLabels,
                      // options: {plugins: {datalabels: {formatter: }}}
                    }}
                    height={25}
                    width={25}
                    selectChart={(value) => handleSelectDemandDelay(value)}
                  />
                </Card>
              </Box>
              {/* )} */}
            </>
          ) : (
            <ChartBoxSkeleton title="Alocação Geral de 7 dias" />
          )}

          {!loadingActivities ? (
            <>
              {(statusRunning > 0 || statusPaused > 0) && (
                <Box>
                  <CardBox>
                    <DoughnutChart
                      colors={selectTheme('status')}
                      data={{
                        datasets: [
                          {
                            data: [
                              statusRunning,
                              statusPaused,
                              // statusTotalNotStarted,
                            ],
                            label: 'Status das Atividades',
                          },
                        ],
                        options: {
                          legend: {
                            display: true,
                            position: 'bottom',
                          },
                        },
                        title: 'Status das Atividades',
                        labels: statusActivities.labels,
                        dataLabels: statusActivities.dataLabels,
                      }}
                      height={25}
                      width={25}
                      selectChart={(value) => {
                        // console.log(value === 'paused');
                        // setPaused(value === 'paused' ? 1 : 0);
                        history.push({
                          pathname: `controle/atividades/status`,
                          search: value === 'paused' ? 'paused=1' : '',
                        });
                      }}
                    />
                  </CardBox>
                </Box>
              )}
            </>
          ) : (
            <ChartBoxSkeleton title="Status das Atividades" />
          )}
        </Grid>
      )}

      {/* {can('list-responsibility') && <CardsDashboard />} */}
    </Container>
  );
};

export default Dashboard;
