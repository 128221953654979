import styled from 'styled-components';

export const PopFiles = styled.div`
  #containerFiles {
    display: grid;
    /* O elemento se alongará até haver no mínimo o espaço para conter outro do seu mesmo tamanho,
    definido no parâmetro min de minmax. fr representa uma fração do espaço disponível no container do grid*/
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    gap: 1rem;
  }
`;

export const PopContent = styled.div`
  a {
    color: #40a9ff;
    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    padding-left: 2rem;
  }
`;

export const LinkDownload = styled.div`
  transition: color 250ms;

  & button * {
    transition: all 250ms;
    color: #1a202c !important;
  }
`;
