import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;

  > div#removeFilters {
    height: 19px;
    align-items: center;
    display: flex;
    font-size: 13px;

    button {
      margin-left: 10px;
      height: 100%;
      //border-radius: 2px;
      //background-color: #fff5f5;
      color: #a90a0a;
      padding: 1px 5px;
      transition: all 0.2s;
      font-size: 13px;
      //text-decoration: underline;

      display: flex;

      &:active {
        transform: scale(0.9);
      }

      svg {
        margin-right: 5px;
      }
    }
  }

  > div#filters {
    width: 100%;
    margin: 10px -5px;

    display: flex;
    flex-wrap: wrap;

    .badge {
      //position: relative;
      max-width: 260px;
      height: 24px;
      display: flex;
      align-items: center;
      border-radius: 22px;
      border: 1px solid #2089dc;
      padding: 10px 8px;
      //padding-right: 8px;
      margin: 5px;

      /* .group-label {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 4px;
          border-right: 1px solid #2089dc;
          background-color: #43c573;
          color: white;
          margin-right: 0.25rem;
          border-top-left-radius: 22px;
          border-bottom-left-radius: 22px;

          > .label {
            font-weight: bolder;
            //position: absolute;

            //text-transform: uppercase;
            font-size: 11px;
          }
        } */

      strong {
        background: #2f2e38;
        color: #eee;
        height: 100%;
        padding: 5px 8px;
        font-size: 13px;
        text-transform: capitalize;
        border-radius: 5px 0 0 5px;
        line-height: 20px;
        font-weight: 400;
      }

      p {
        display: block;
        font-family: 'Inter';
        font-weight: 500;
        color: #2089dc;
        font-size: 13px;
      }
    }
  }
`;
