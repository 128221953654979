import { fadeIn } from 'react-animations';

import { Box } from '@chakra-ui/react';
import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled(Box)`
  animation: 0.4s ${fadeInAnimation};

  > div.isloading {
    position: absolute;
    top: 30px;
    left: 0;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: space-between;

  width: auto;
  min-width: 250px;
  max-width: 650px;
  text-align: left;
  box-sizing: border-box;

  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #081132;
  }

  #attendance_activity {
    align-items: center;
    margin-bottom: 1rem;
    span {
      //border: 1px solid #1a202c;
      margin: 0 5px 0 0;
      font-size: 15px;
      font-weight: 600;
    }
  }

  > span button {
    position: relative;
    background: #f7fafc;
    border: 0;
    padding: 13px 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    transition: all 0.2s;
    box-shadow: 0 1px 4px 0 rgb(48 108 176 / 12%),
      0 2px 3px 0 rgb(48 108 176 / 15%) !important;
    color: rgb(43 108 176 / 80%);

    > svg {
      margin: 0 !important;
    }

    > p {
      display: none;
    }

    &:active {
      transform: scale(0.9);
    }

    & + button {
      margin-left: 0.5rem;
    }

    &:hover {
      background: #edf2f7;
    }
    svg {
      margin-right: 8px;
    }

    @media (min-width: 480px) {
      > p {
        display: block;
        margin-top: 5px;
      }
    }
  }

  #infoExecution {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px;
  }

  @media (max-width: 480px) {
    h3 {
      text-align: center;
    }

    > span button {
      margin-bottom: 20px;
    }

    > div {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 10px;

  strong {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2.5px;
    color: #2f2e38;
  }

  textarea {
    border-radius: 5px;
    background: #fff;
    border: 1px solid rgba(71, 98, 235, 0.1);
    padding: 10px;
    max-width: 500px;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    color: #718096;
  }
`;

export const TimeInformation = styled.div`
  display: flex;
  flex-direction: column;
  small.label {
    font-size: 13px;
    line-height: 15px;
    color: #8694a0;
    margin-left: 30px;
  }
`;

export const Information = styled.div`
  display: flex;
  width: auto;
  font-size: 16px;
  line-height: 19px;
  color: #2f2e38;

  svg {
    margin-right: 10px;
  }

  &.planned-date-time {
    align-items: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    small {
      font-size: 13px;
      line-height: 15px;
      color: #8694a0;
    }

    span {
      display: inline;
      font-weight: 600;
      width: 26px;
      margin-right: 5px;
    }
  }
`;

export const ContentTimer = styled.div`
  flex: 1;
  min-width: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;

  div.buttons {
    display: flex;
    justify-content: space-between;
  }

  button {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(134, 148, 160, 0.1);
    color: #8694a0;
    margin-top: 20px;
    padding: 5px 10px;
    transition: all 0.2s;

    &.success {
      background: #2bba85;
      color: #fff;
    }

    &:active {
      transform: scale(0.9);
    }

    & + button {
      margin-left: 20px;
    }

    svg {
      margin-right: 8px;
    }
  }
`;

export const Timer = styled.div`
  position: relative;
  width: 230px;
  height: 230px;
  border-radius: 100%;
  background: #2f2e38;
  border: 2px solid #2c2b35;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  small {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #8694a0;
  }

  strong {
    font-size: 40px;
    line-height: 47px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2bba85;
    font-weight: 500;
  }
`;

export const TimerCircle = styled.svg`
  position: absolute;
  top: -2px;
  left: -2px;
  width: 230px;
  height: 230px;
  border-radius: 100%;
  background: transparent;
  border: 10px solid #2bba85;
`;

export const PostIt = styled.div`
  & {
    //line-height: 1;
    width: 230px;
    //margin: 10px;
    min-height: 50px;
    max-height: 250px;
    padding: 15px 10px;
    position: relative;
    border: 1px solid #e8e8e8;
    //border-top: 60px solid #fdfd86;
    font-size: 12px;
    border-bottom-right-radius: 60px 5px;
    display: inline-block;
    background: #ffff88; /* Old browsers */
    background: -moz-linear-gradient(
      -45deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right bottom,
      color-stop(81%, #ffff88),
      color-stop(82%, #ffff88),
      color-stop(82%, #ffff88),
      color-stop(100%, #ffffc6)
    ); /* Chrome,Safari4+ */
    background: linear-gradient(
      135deg,
      #bee3f8 81%,
      #bee3f8 82%,
      #bee3f8 82%,
      #bee3f8 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffff88', endColorstr='#ffffc6',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }

  /* &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 0 100px 100px 0;
    border-color: #ddd #344;
    transition: all ease 0.5s;
  } */

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: -0px;
    bottom: 20px;
    width: 200px;
    height: 25px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.4);
    -moz-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    -webkit-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    -o-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    -ms-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    transform: matrix(-1, -0.1, 0, 1, 0, 0);
  }
`;
