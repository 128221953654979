import styled from 'styled-components';

export const TimeLineContainer = styled.div`
  margin-bottom: 5rem;
  ul {
    padding-left: 15px;
    max-height: 300px;
    overflow-y: auto;
  }

  li {
    list-style: none;
  }
`;

interface ColorTextStatus {
  statusId: number;
}

export const UlTimeLine = styled.ul`
  li:nth-child(2) strong:before {
    content: '';
    position: absolute;
    border-radius: 100%;
    background: #fff;
    border: 2px solid #0ac600;
    width: 15px;
    height: 15px;
    top: 4px;
    left: -8px;
  }
  li:nth-child(2) strong:hover:before {
    transform: none;
  }
`;

export const LiTimeLine = styled.li<ColorTextStatus>`
  height: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  border-left: 1px solid #e6eaee;

  strong {
    color: ${({ theme, statusId }) => {
      switch (statusId) {
        case 1:
          return theme.colors.success;
        case 2:
          return theme.colors.warning;
        case 3:
          return theme.colors.primary;
        case 4:
          return theme.colors.notification;
        case 5:
          return theme.colors.danger;
        case 6:
          return theme.colors.info;

        default:
          return 1;
      }
    }};
  }

  /* &:nth-child(1) strong:before {
    content: '';
    position: absolute;
    border-radius: 100%;
    background: #fff;
    border: 2px solid #0ac600;
    width: 15px;
    height: 15px;
    top: 4px;
    left: -8px;
  } */

  strong:before {
    content: '';
    position: absolute;
    top: 8px;
    left: -4px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #e6eaee;
    transition: all 0.2s ease-out;
  }

  strong:hover:before {
    transform: scale(2);
  }

  /*  strong.active:before {
    background: #fff;
    border: 2px solid #0ac600;
    width: 15px;
    height: 15px;
    top: 4px;
    left: -8px;
  } */

  button {
    position: absolute;
    right: 0;
    top: -5px;
    border-radius: 100%;
    color: #9e9e9e;
    background: transparent;
    /* width: 20px;
    height: 20px; */

    &:hover {
      //color: #0ac600;
      background: transparent;
    }
    &:active {
      background: transparent;
    }
    &:focus {
      box-shadow: none;
    }
  }

  /* button:nth-child(3) {
    display: none;
  } */
`;
