import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiFolder, FiPlay, FiRotateCw, FiTrash } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import iconGoogleDrive from '~/assets/google-drive.svg';
import AlertDialog from '~/shared/components/AlertDialog';
import SectionHeader from '~/shared/components/SectionHeader';
import Table from '~/shared/components/Table';
import api from '~/shared/services/api';

interface CompanyFolderEstructure {
  company_id: number;
  company_name: string;
  id?: string;
  name?: string;
  company_client_id?: number;
  executing?: boolean;
  deleting?: boolean;
  error?: boolean;
}

interface PaginationData {
  current_page: number;
  from: number;
  last_page: number;
  to: number;
  per_page: number;
  total: number;
}

interface DataProps {
  current_page: number;
  data: CompanyFolderEstructure[];
  from: number;
  last_page: number;
  to: number;
  per_page: number;
  total: number;
}

const EstructureDefault: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const history = useHistory();

  const [companies, setCompanies] = useState<CompanyFolderEstructure[]>([]);
  const [
    deletedCompanyStructure,
    setDeleteCompanyStructure,
  ] = useState<CompanyFolderEstructure>({} as CompanyFolderEstructure);

  const [paginationData, setPaginationData] = useState<PaginationData>(
    {} as PaginationData,
  );
  const [limitPage, setLimitPage] = useState(20);
  const [
    selectCompanyExecute,
    setSelectCompanyExecute,
  ] = useState<CompanyFolderEstructure>({} as CompanyFolderEstructure);

  const addToast = useToast();
  // const {user} = useAuth();

  const handleEndLoadCompanies = useCallback(
    (pageD: number, limitPageD: number) => {
      api
        .get<DataProps>('cloud/company/structure', {
          params: {
            page: pageD,
            limitPage: limitPageD,
          },
        })
        .then((response) => {
          setCompanies(response.data?.data);
          const {
            per_page,
            to,
            total,
            current_page,
            from,
            last_page,
          } = response.data;
          setPaginationData({
            per_page,
            to,
            total,
            current_page,
            from,
            last_page,
          });
        })
        .catch(() => {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Não foi possível carregar os dados, erro no servidor',
          });
        });
    },
    [addToast],
  );

  useEffect(() => {
    handleEndLoadCompanies(1, 20);
  }, [handleEndLoadCompanies]);

  const handleNewLimitPage = useCallback(
    (newLimit: number) => {
      setLimitPage(newLimit);
      handleEndLoadCompanies(1, newLimit);
    },
    [handleEndLoadCompanies],
  );

  const handleNewPage = useCallback(
    (newPage: number) => {
      handleEndLoadCompanies(newPage, limitPage);
    },
    [handleEndLoadCompanies, limitPage],
  );

  const handleOpenExecute = useCallback(
    (company: CompanyFolderEstructure) => {
      setSelectCompanyExecute(company);
      onOpen();
    },
    [onOpen],
  );

  const processExecute = useCallback((companyExecute) => {
    api
      .post(
        `cloud/company/structure`,
        {
          company_client_id: companyExecute.company_id,
          company_client_folder_name: companyExecute.company_name,
        },
        {
          onUploadProgress: () => {
            setCompanies((state) =>
              state.map((company) =>
                company.company_id === companyExecute.company_id
                  ? { ...company, executing: true }
                  : company,
              ),
            );
          },
        },
      )
      .then((response) => {
        // console.log(`O arquivo já foi enviado para o servidor!`);
        setCompanies((state) =>
          state.map((company) =>
            company.company_id === response.data?.company_client_id
              ? {
                  ...company,
                  executing: false,
                  id: response.data?.id,
                  company_client_id: response.data?.company_client_id,
                }
              : company,
          ),
        );
      })
      .catch((err) => {
        // console.error(
        //  `Houve um problema ao fazer upload do arquivo no servidor`,
        // );
        // console.log(err);
        setCompanies((state) =>
          state.map((company) =>
            company.company_id === err.data?.company_client_id
              ? { ...company, executing: false, error: true }
              : company,
          ),
        );
      });
  }, []);

  const handleSetCompanyDeleteStructure = useCallback(
    (company: CompanyFolderEstructure) => {
      setDeleteCompanyStructure(company);
    },
    [],
  );

  const onCloseDeleteCompanyStructure = useCallback(
    () => setDeleteCompanyStructure({} as CompanyFolderEstructure),
    [],
  );

  const handleDeleteStructure = useCallback(() => {
    setCompanies((state) =>
      state.map((company) =>
        company.company_id === deletedCompanyStructure.company_id
          ? { ...company, deleting: true }
          : company,
      ),
    );

    onCloseDeleteCompanyStructure();

    api
      .delete('cloud/company/structure', {
        params: { client_company_id: deletedCompanyStructure.company_id },
      })
      .then(() => {
        setCompanies((state) =>
          state.map((company) =>
            company.company_id === deletedCompanyStructure.company_id
              ? { ...company, deleting: false, id: undefined }
              : company,
          ),
        );
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Apagado com sucesso!',
          description: `A estrutura padrão de arquivos foi apagada para a empresa ${deletedCompanyStructure.company_name}`,
        });
      })
      .catch((err) => {
        setCompanies((state) =>
          state.map((company) =>
            company.company_id === deletedCompanyStructure.company_id
              ? { ...company, deleting: false, error: true }
              : company,
          ),
        );

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao apagar estrutura padrão',
          description:
            err.response.data?.error ||
            `Não foi possível apagar a estrutura padrão de arquivos de ${deletedCompanyStructure.company_name}`,
        });
      });
  }, [addToast, deletedCompanyStructure, onCloseDeleteCompanyStructure]);

  const handleSubmit = useCallback(() => {
    processExecute(selectCompanyExecute);
    onClose();
  }, [onClose, selectCompanyExecute, processExecute]);

  const handleVerifyEstructureExecute = useCallback(
    (companyVerify: CompanyFolderEstructure) => {
      processExecute(companyVerify);
    },
    [processExecute],
  );

  const handleNavigateShowFolder = useCallback(
    (id: string | undefined) => {
      if (!id) return;
      history.push(`/documentos/estrutura-padrao/${id}`);
    },
    [history],
  );

  return (
    <>
      <Box>
        <SectionHeader
          title="Estrutura Padrão"
          pagename="Crie e gerencie estrutura padrão para empresas"
        />

        <Table
          theadData={[
            {
              title: 'Empresa',
            },
            {
              title: 'Status',
            },
            {
              title: 'Ações',
            },
          ]}
          showTotalPage={(totalPerPage) => handleNewLimitPage(totalPerPage)}
          totalRegistiesTitle={
            paginationData.total
              ? `Total de empresas - ${paginationData.total}`
              : 'Carregando...'
          }
          pagination={paginationData}
          newPage={(pg) => handleNewPage(pg)}
        >
          {companies?.map((company) => {
            return (
              <Box
                key={`${company.id}c${company.company_id}`}
                as="tr"
                _hover={{ bg: 'gray.50' }}
              >
                <Box as="td" p="15px">
                  <Flex alignItems="center">
                    <IconButton
                      aria-label="Ver estrutura"
                      title="Ver estrutura"
                      icon={<FiFolder />}
                      colorScheme="gray"
                      variant="ghost"
                      mr={2}
                      isDisabled={!company.id}
                      onClick={() => handleNavigateShowFolder(company.id)}
                    />
                    <Text
                      isTruncated
                      width={['200px', '400px']}
                      display="inline"
                      title={company.name || company.company_name}
                    >
                      {company.name || company.company_name}
                    </Text>
                  </Flex>
                </Box>
                <Box as="td" p="15px" width="250px">
                  {company.id ? (
                    <Tag
                      size="md"
                      width="100px"
                      textAlign="center"
                      justifyContent="center"
                      borderRadius="20px"
                      variant="subtle"
                      colorScheme="green"
                    >
                      Ok
                    </Tag>
                  ) : (
                    <Tag
                      size="md"
                      width="100px"
                      textAlign="center"
                      justifyContent="center"
                      borderRadius="20px"
                      variant="subtle"
                      colorScheme={company.executing ? 'yellow' : 'gray'}
                    >
                      {company.executing && !company.error && 'Executando...'}

                      {company.deleting && !company.error && 'Apagando...'}

                      {!company.executing && !company.deleting && 'Pendente'}

                      {company.error && !company.executing && 'Falhou'}
                    </Tag>
                  )}
                </Box>
                <Box as="td" p="15px" width="250px" textAlign="right">
                  {company.id ? (
                    <>
                      <Button
                        title="Gerenciar pasta compartilhada do cliente"
                        variant="ghost"
                        colorScheme="gray"
                        mr={2}
                      >
                        <Image src={iconGoogleDrive} alt="Google Drive" />
                      </Button>
                      <IconButton
                        aria-label="Verificar estrutura"
                        title="Verificar estrutura"
                        icon={<FiRotateCw />}
                        colorScheme={company.executing ? 'gray' : 'blue'}
                        mr={2}
                        onClick={() => handleVerifyEstructureExecute(company)}
                        isLoading={company.executing}
                      />
                      <IconButton
                        aria-label="Apagar estrutura"
                        title="Apagar estrutura"
                        icon={<FiTrash />}
                        colorScheme={company.deleting ? 'gray' : 'red'}
                        onClick={() => handleSetCompanyDeleteStructure(company)}
                        isLoading={company.deleting}
                      />
                    </>
                  ) : (
                    <IconButton
                      aria-label="Criar estrutura"
                      title="Criar estrutura"
                      icon={<FiPlay />}
                      colorScheme={company.executing ? 'gray' : 'green'}
                      onClick={() => handleOpenExecute(company)}
                      isLoading={company.executing}
                    />
                  )}
                </Box>
              </Box>
            );
          })}
        </Table>
      </Box>

      <AlertDialog
        title="Criar estrutura de pastas?"
        isOpen={isOpen}
        isLoading={!selectCompanyExecute.company_id && !isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        onSubmit={() => handleSubmit()}
      >
        <Text>
          A estrutura de pastas padrão será criada para a empresa abaixo no
          drive da IPAC.
        </Text>
        <Heading size="sm" color="blue.500" my={3}>
          <Box as={FiFolder} width="40px" display="inline" />{' '}
          {selectCompanyExecute.company_name}
        </Heading>
      </AlertDialog>

      <AlertDialog
        title={`Apagar estrutura padrão de arquivos para ${deletedCompanyStructure.company_name}?`}
        isOpen={!!deletedCompanyStructure.company_id}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDeleteCompanyStructure}
        onSubmit={() => handleDeleteStructure()}
      >
        <Text>
          Todas as pastas criadas no drive assim como todos os arquivos que
          possam estar contidos nelas serão apagados
        </Text>
      </AlertDialog>
    </>
  );
};

export default EstructureDefault;
