import { Flex, Tab } from '@chakra-ui/react';
import styled, { css } from 'styled-components';

interface TabChakraProps {
  isDisabled: boolean;
  show: boolean;
}

export const TabChakra = styled(Tab)<TabChakraProps>`
  // display: ${(props) => (props.show ? 'none' : 'none')};
  border: 1px solid #2c5282 !important;
  margin-right: 5px;
  border-top-right-radius: 8px;
  & ~ & {
    margin-left: 0.4rem;
  }
  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;

export const FlexCustom = styled(Flex)`
  /* button {
    padding-right: 0;
  } */
  position: relative;
`;
