import React, { useRef, useCallback } from 'react';

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  DrawerProps,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

interface Props extends DrawerProps {
  title?: string;
  initialData?: object;
  loading?: boolean;
  submit(value: any): void;
  onClose(): void;
}

const Filter: React.FC<Props> = ({
  title,
  initialData,
  loading = false,
  submit,
  onClose,
  children,
  ...rest
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    (data) => {
      submit(data);
      onClose();
    },
    [submit, onClose],
  );

  return (
    <Drawer
      preserveScrollBarGap
      placement="right"
      onClose={onClose}
      size="xs"
      {...rest}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">{title || 'Filtro'}</DrawerHeader>

        <DrawerBody overflowY="auto">
          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            {children}
          </Form>
        </DrawerBody>

        <DrawerFooter>
          {!loading && (
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
          )}
          <Button
            variant="solid"
            colorScheme="blue"
            onClick={() => formRef.current?.submitForm()}
            isLoading={loading}
            isDisabled={loading}
          >
            Filtrar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default Filter;
