import React, { useEffect, useMemo, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import {
  Box,
  useToast,
  Text,
  Heading,
  Stack,
  AlertIcon,
  Alert,
  Icon,
  Tooltip,
  Skeleton,
  Flex,
} from '@chakra-ui/react';
import { Empty } from 'antd';

import Loading from '~/shared/components/Loading';
import api from '~/shared/services/api';

import { ContainerStructureConsumption, TrBorder } from './styles';

interface CompanyConsumption {
  company_id: number;
  company_name: string;
  count_attendances: number;
  real_allocation: number;
  real_allocation_hours: string;
  total_shifts_hours: string;
  total_shifts: number;
  employee_consumption_percent: number;
}

interface TotalConsumption {
  employee_id: number;
  count_attendances: number;
  real_allocation: number;
  real_allocation_hours: string;
  total_shifts_hours: string;
  total_shifts: number;
  employee_consumption_percent: number;
}

interface DataStructureConsumption {
  total_consumption: TotalConsumption;
  companies_consumption: CompanyConsumption[];
}

interface Props {
  employee: number;
}

const StructureConsumption: React.FC<Props> = ({ employee }) => {
  const addToast = useToast();

  const [totalConsumption, setTotalConsumption] = useState<TotalConsumption>(
    {} as TotalConsumption,
  );

  const [companiesConsumption, setCompaniesConsumption] = useState<
    CompanyConsumption[]
  >({} as CompanyConsumption[]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    api
      .get<DataStructureConsumption>(
        `/reports/structure-consumption/personal/${employee}`,
      )
      .then((response) => {
        setTotalConsumption(response.data.total_consumption);
        setCompaniesConsumption(response.data.companies_consumption);
        setLoading(false);
      });
  }, [setTotalConsumption, addToast, employee]);

  return (
    <>
      {!loading ? (
        <>
          {totalConsumption && totalConsumption.real_allocation && (
            <ContainerStructureConsumption
              as="div"
              id="container_structure_consumption"
            >
              <Tooltip
                label="Representa o percentual de tempo que uma empresa consumiu do total da sua jornada de trabalho em um determinado período"
                placement="auto"
              >
                <h3>Distribuição da sua alocação</h3>
              </Tooltip>
              <Flex mt={1} ml="2px">
                <FiInfo size="13px" style={{ marginTop: '1px' }} />
                <small style={{ marginLeft: '3px' }}>Últimos 7 dias</small>
              </Flex>
              <Box
                as="table"
                w="auto"
                bg="#fff"
                borderRadius="10px"
                boxShadow="base"
              >
                <Box
                  as="thead"
                  w="full"
                  fontWeight="medium"
                  fontSize="sm"
                  borderTopWidth={1}
                  borderBottomWidth={1}
                  borderColor="gray.100"
                  bg="gray.50"
                >
                  <TrBorder as="tr">
                    <th
                      rowSpan={2}
                      style={{
                        maxWidth: '280px',
                        width: '280px',
                        padding: '10px 20px',
                        verticalAlign: 'center',
                      }}
                    >
                      <Text fontSize="sm" textAlign="center">
                        EMPRESAS
                      </Text>
                    </th>
                    {/* <Box as="th" px="10px" py="20px" pb="5px" width="150px">
                Quant. de Atendimentos
              </Box> */}
                    <Box as="th" px="10px" py="10px" width="150px">
                      <Text fontSize="sm" textAlign="center">
                        TOTAL
                      </Text>
                    </Box>
                  </TrBorder>
                  <TrBorder as="tr">
                    {/* <Box as="th" px="10px" py="20px" pb="5px" width="150px">
                {totalConsumption.count_attendances || 0}
              </Box> */}
                    <Box
                      as="th"
                      px="10px"
                      py="10px"
                      width="150px"
                      fontSize="1rem"
                      textAlign="center"
                    >
                      {`${
                        totalConsumption.employee_consumption_percent || 0
                      } %`}
                    </Box>
                  </TrBorder>
                </Box>
                <Box as="tbody">
                  {companiesConsumption?.length &&
                    companiesConsumption.map((company) => {
                      return (
                        <TrBorder as="tr" key={company.company_id}>
                          <Box as="td" px="10px" py="10x" maxWidth="150px">
                            <Heading
                              fontSize="0.9rem"
                              title={company.company_name}
                              isTruncated
                            >
                              {company.company_name}
                            </Heading>
                          </Box>
                          {/* <Box as="td" px="10px" py="20px" pb="5px" width="150px">
                      {company.count_attendances}
                    </Box> */}
                          <Box
                            as="td"
                            px="10px"
                            py="10px"
                            width="150px"
                            fontSize="15px"
                            textAlign="center"
                          >
                            {`${company.employee_consumption_percent || 0} %`}
                          </Box>
                        </TrBorder>
                      );
                    })}
                </Box>
              </Box>
            </ContainerStructureConsumption>
          )}
        </>
      ) : (
        <ContainerStructureConsumption as="div">
          <h3>Distribuição da sua alocação</h3>
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        </ContainerStructureConsumption>
      )}
    </>
  );
};

export default StructureConsumption;
