import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  FaArrowAltCircleRight,
  FaArrowRight,
  FaAngleRight,
  FaEye,
  FaTrash,
} from 'react-icons/fa';
import { FiSearch, FiArrowRight, FiExternalLink } from 'react-icons/fi';
import { useParams, Link, useLocation } from 'react-router-dom';

import {
  Box,
  Text,
  Heading,
  Flex,
  IconButton,
  ButtonGroup,
  Button,
  Icon,
  useToast,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import queryString from 'query-string';

import {
  ActivityProps,
  Pop,
  ActivityControl,
  FilterProps,
} from '~/modules/activityExecution/@types/activity';
import RunningActivity from '~/modules/activityExecution/components/RunningActivity';
import Planning from '~/modules/activityExecution/pages/Planning';
import {
  Member,
  CorporateEmail,
  Department,
} from '~/modules/management/@types/management';
import AlertDialog from '~/shared/components/AlertDialog';
import FilterTags from '~/shared/components/FilterTags';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';

import { User } from '../../@types/user';

interface Props {
  planningId: number;
  employee: number;
  company: number;
  finish(): void;
  updateActivity(
    status: 'Pausado' | 'Finalizado' | 'Iniciado' | 'Não Iniciado',
    status_id: number,
  ): void;
}

interface Params {
  userId?: string;
}

interface ActivitiesStatusProp {
  activitiesExecution: Activity[];
  activitiesPaused: Activity[];
  departments: Department[];
}

interface Activity {
  id: number;
  atendimento_id: number;
  demanda_id: number;
  funcionario_id: number;
  atividade_duracao: string;
  inicio_planejamento: string; // = inicio_atendimento
  status_id: number;
  data_inicio_planejado: string;
  at_tempo_restante: string;
  at_tempo_excedido?: string;
  hora_inicio: string;
  demanda: string;
  atividade: string;
  status: string;
  status_cores: string;
  funcionario: string;
  empresa: string;
}

export interface OptionDepartment {
  label: string;
  value: string | number;
}

interface QueryParams {
  paused?: boolean;
}

const ActivitiesStatus: React.FC = () => {
  const refForm = useRef<FormHandles>(null);
  const cancelRef = useRef(null);
  const { userId } = useParams<Params>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFinish,
    onOpen: onOpenFinish,
    onClose: onCloseFinish,
  } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const addToast = useToast();
  const [department, setDepartment] = useState<OptionDepartment[]>([]);
  const [activitiesSts, setActivitiesSts] = useState<Activity[]>([]);
  const [departmentId, setDepartmentId] = useState<number | null>(null);

  const [currentActivity, setCurrentActivity] = useState<{
    id: number;
    atividade: string;
    funcionario_id: number;
    inExecution: boolean;
    status_id: number;
  }>();

  const location = useLocation();
  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const goLink = useMemo(() => {
    return `/dashboard`;
  }, []);

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: ' ',
      },
      {
        title: 'Funcionário',
      },
      {
        title: 'Demanda',
      },
      {
        title: 'Atividade',
      },
      {
        title: 'Empresa',
      },
      {
        title: 'Duração',
      },
      {
        title: 'Data Início	',
      },
      {
        title: 'Hora Início',
      },
    ];
  }, []);

  const msgTitleAlert =
    currentActivity?.status_id === 17 ? 'Executar' : 'Pausar';

  const msgContAlert =
    currentActivity?.status_id === 17 ? 'continuar' : 'pausar';

  const variantColorAlert =
    currentActivity?.status_id === 17 ? 'blue' : 'yellow';

  useEffect(() => {
    if (departmentId) {
      setLoadingTable(true);
    } else {
      setLoading(true);
    }

    api
      .get<ActivitiesStatusProp>(`/analytic/activities/list`, {
        params: {
          dep_id: departmentId,
        },
      })
      .then((response) => {
        const {
          departments,
          activitiesPaused,
          activitiesExecution,
        } = response.data;

        const optionsDepartments = departments.map((dep) => {
          return {
            ...dep,
            label: dep.name,
            value: dep.id,
          };
        });

        setDepartment([
          {
            label: 'Selecione um departamento',
            value: '',
          },
          ...optionsDepartments,
        ]);

        // console.log(activitiesPaused, activitiesExecution);
        setActivitiesSts([...activitiesExecution, ...activitiesPaused]);

        // console.log('teste');
      })
      .finally(() => {
        setLoadingTable(false);
        setLoading(false);
      });
  }, [setLoading, departmentId, setLoadingTable]);

  const handleCleanFilter = useCallback(() => {
    try {
      refForm.current?.reset();
      setDepartment([]);
      setDepartmentId(null);
    } catch (err) {
      if (err.response?.status < 500) {
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          isClosable: true,
        });
      }
    }
  }, [addToast]);

  const handleFinishedActivity = useCallback(async () => {
    if (!currentActivity) {
      return;
    }

    setLoading(true);

    try {
      setLoadingTable(true);
      const data = {
        planning_id: currentActivity?.id,
        employee: currentActivity?.funcionario_id,
      };

      await api.post('activity/finish', { ...data });

      setActivitiesSts((state) =>
        state.filter((act) => act.id !== data.planning_id),
      );
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Atividade finalizada!',
        description: `A atividade '${currentActivity?.atividade}' foi finalizada com sucesso.`,
        status: 'success',
      });
    } catch (err) {
      if (err.response.status < 500) {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
        });

        return;
      }

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Erro ao finalizar atividade!',
        description: 'Não foi possível finalizar a atividade, tente novamente.',
        status: 'error',
      });
    } finally {
      setLoadingTable(false);
      setLoading(false);
      onCloseFinish();
    }
  }, [currentActivity, addToast, onCloseFinish, setLoading]);

  const handleActivity = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingTable(true);
      const data = {
        planning_id: currentActivity?.id,
        employee: currentActivity?.funcionario_id,
        status_id: currentActivity?.status_id,
      };

      await api.post('activity/execution', data);

      if (data.status_id === 17) {
        const selectedAct = activitiesSts.find(
          (act) => act.id === data.planning_id,
        );

        const pausedAct = activitiesSts.filter(
          (act) => act.status_id === 17 && act.id !== data.planning_id,
        );
        let exeAct = activitiesSts.filter((act) => act.status_id === 15);

        exeAct = selectedAct
          ? [{ ...selectedAct, status_id: 15 }, ...exeAct]
          : exeAct;

        setActivitiesSts([...exeAct, ...pausedAct]);
      } else {
        const selectedAct = activitiesSts.find(
          (act) => act.id === data.planning_id,
        );

        const exeAct = activitiesSts.filter(
          (act) => act.status_id === 15 && act.id !== data.planning_id,
        );
        let pausedAct = activitiesSts.filter((act) => act.status_id === 17);

        pausedAct = selectedAct
          ? [{ ...selectedAct, status_id: 17 }, ...pausedAct]
          : pausedAct;

        setActivitiesSts([...exeAct, ...pausedAct]);
      }

      const msg = data.status_id === 17 ? 'retomada' : 'pausada';
      addToast({
        position: 'top-right',
        isClosable: true,
        title: `Atividade ${msg}!`,
        description: `A atividade ${currentActivity?.atividade} foi ${msg} com sucesso.`,
        status: 'success',
      });
    } catch (err) {
      if (err.response.status < 500) {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
        });

        return;
      }

      const msgErr = currentActivity?.status_id === 17 ? 'retomar' : 'pausar';
      addToast({
        position: 'top-right',
        isClosable: true,
        title: `Erro ao ${msgErr} atividade!`,
        description: `Não foi possível ${msgErr} a atividade, tente novamente.`,
        status: 'error',
      });
    } finally {
      setLoading(false);
      setLoadingTable(false);
      onClose();
    }
  }, [currentActivity, addToast, onClose, activitiesSts]);

  return (
    <Box pos="relative">
      <SectionHeader
        goBackLink={goLink}
        title="Controle de Atividades"
        pagename="Controle de Atividades"
      />

      {/* {loading && <LoadingAbsolute z_index={1111} min_height={500} />} */}

      <>
        <Form
          onSubmit={(dep) => setDepartmentId(dep.department_id)}
          ref={refForm}
          initialData={{ department_id: departmentId }}
        >
          <Flex>
            <SelectChakra
              name="department_id"
              label="Departamento"
              placeholder={loadingTable ? 'Carregando...' : ''}
              options={department}
              mr={3}
              onChange={() => refForm.current?.submitForm()}
            />
          </Flex>
          {departmentId ? (
            <Flex justifyContent="right">
              <Button
                display="flex"
                justifyItems="flex-end"
                colorScheme="gray"
                size="lg"
                onClick={() => handleCleanFilter()}
                maxWidth="200px"
                width="full"
              >
                <Text>Limpar filtro</Text>
              </Button>
            </Flex>
          ) : (
            ''
          )}
        </Form>

        <Accordion defaultIndex={queryParams.paused ? [1] : [0]} allowMultiple>
          <AccordionItem>
            <AccordionButton>
              <Flex alignItems="center">
                <FaAngleRight />
                <Text fontSize="xl">Atividades em execução</Text>
              </Flex>
            </AccordionButton>
            <Text fontSize="sm">
              Total de atividades:{' '}
              {loadingTable
                ? 'carregando...'
                : activitiesSts.filter((status) => status.status_id === 15)
                    ?.length}
            </Text>
            <AccordionPanel>
              <Table theadData={tableTitles} loading={loadingTable}>
                {activitiesSts
                  .filter((status) => status.status_id === 15)
                  ?.map((atividadesExecucao) => {
                    return (
                      <Box as="tr" key={atividadesExecucao.id}>
                        <Box as="td" py={3} px={4}>
                          <ButtonGroup
                            variant="outline"
                            fontSize="sm"
                            alignItems="center"
                            spacing={2}
                          >
                            <Button
                              colorScheme="yellow"
                              width="7rem"
                              boxShadow="0 4px 5px 0 rgba(0,0,0,.16), 0 4px 10px 0 rgba(0,0,0,.12)"
                              onClick={() => {
                                setCurrentActivity({
                                  id: atividadesExecucao.id,
                                  atividade: atividadesExecucao.atividade,
                                  funcionario_id:
                                    atividadesExecucao.funcionario_id,
                                  inExecution: true,
                                  status_id: atividadesExecucao.status_id,
                                });
                                onOpen();
                              }}
                              isLoading={loading}
                            >
                              PAUSAR
                            </Button>
                            <Button
                              onClick={() => {
                                setCurrentActivity({
                                  id: atividadesExecucao.id,
                                  atividade: atividadesExecucao.atividade,
                                  funcionario_id:
                                    atividadesExecucao.funcionario_id,
                                  inExecution: true,
                                  status_id: atividadesExecucao.status_id,
                                });
                                onOpenFinish();
                              }}
                              colorScheme="green"
                              // submitButtonColor="green.500"
                              boxShadow="0 4px 5px 0 rgba(0,0,0,.16), 0 4px 10px 0 rgba(0,0,0,.12)"
                              width="7rem"
                            >
                              FINALIZAR
                            </Button>
                          </ButtonGroup>
                        </Box>

                        <Box as="td" py={2} px={3} fontSize="sm" color="black">
                          <Link
                            to={`/planejamento?employee=${atividadesExecucao.funcionario_id}`}
                            target="_blanck"
                          >
                            <Flex alignItems="baseline">
                              <Box mr="3px">
                                <FaEye
                                  color="#33b5e5"
                                  fontSize="md"
                                  width="14px"
                                  height="14px"
                                />
                              </Box>

                              {atividadesExecucao.funcionario}
                            </Flex>
                          </Link>
                        </Box>

                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {atividadesExecucao.demanda}
                        </Box>

                        <Box as="td" py={2} px={3} fontSize="sm" color="black">
                          <a
                            href={`${process.env.REACT_APP_OLDMYIPAC}/planejamento/index/${atividadesExecucao.demanda_id}?aten=${atividadesExecucao.atendimento_id}&pg=1`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {atividadesExecucao.atividade}
                            <Flex justifyContent="right">
                              <FiExternalLink
                                color="#33b5e5"
                                fontSize="md"
                                width="14px"
                                height="14px"
                              />
                            </Flex>
                          </a>
                        </Box>

                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {atividadesExecucao.empresa}
                        </Box>

                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {atividadesExecucao.atividade_duracao}
                        </Box>

                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {format(
                            parseISO(atividadesExecucao.inicio_planejamento),
                            'dd/MM/yyyy',
                          )}
                        </Box>

                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {format(
                            parseISO(atividadesExecucao.inicio_planejamento),
                            'HH:mm:ss',
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              </Table>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Flex alignItems="center">
                <FaAngleRight />
                <Text fontSize="xl">Atividades pausadas</Text>
              </Flex>
            </AccordionButton>
            <Text fontSize="sm">
              Total de atividades:{' '}
              {loadingTable
                ? 'carregando...'
                : activitiesSts.filter((status) => status.status_id === 17)
                    ?.length}
            </Text>
            <AccordionPanel>
              <Table theadData={tableTitles} loading={loadingTable}>
                {activitiesSts
                  .filter((status) => status.status_id === 17)
                  ?.map((atividadesPausadas) => {
                    return (
                      <Box as="tr" key={atividadesPausadas.id}>
                        <Box as="td" py={3} px={4}>
                          <ButtonGroup
                            variant="outline"
                            fontSize="sm"
                            alignItems="center"
                            spacing={2}
                          >
                            <Button
                              colorScheme="blue"
                              width="7rem"
                              boxShadow="0 4px 5px 0 rgba(0,0,0,.16), 0 4px 10px 0 rgba(0,0,0,.12)"
                              onClick={() => {
                                setCurrentActivity({
                                  id: atividadesPausadas.id,
                                  atividade: atividadesPausadas.atividade,
                                  funcionario_id:
                                    atividadesPausadas.funcionario_id,
                                  inExecution: false,
                                  status_id: atividadesPausadas.status_id,
                                });
                                onOpen();
                              }}
                            >
                              CONTINUAR
                            </Button>
                            <Button
                              onClick={() => {
                                setCurrentActivity({
                                  id: atividadesPausadas.id,
                                  atividade: atividadesPausadas.atividade,
                                  funcionario_id:
                                    atividadesPausadas.funcionario_id,
                                  inExecution: false,
                                  status_id: atividadesPausadas.status_id,
                                });
                                onOpenFinish();
                              }}
                              colorScheme="green"
                              // submitButtonColor="green.500"
                              width="7rem"
                              boxShadow="0 4px 5px 0 rgba(0,0,0,.16), 0 4px 10px 0 rgba(0,0,0,.12)"
                            >
                              FINALIZAR
                            </Button>
                          </ButtonGroup>
                        </Box>
                        <Box as="td" py={2} px={3} fontSize="sm" color="black">
                          <Link
                            to={`/planejamento?employee=${atividadesPausadas.funcionario_id}`}
                            target="_blank"
                          >
                            <Flex alignItems="baseline">
                              <Box mr="3px">
                                <FaEye
                                  color="#33b5e5"
                                  fontSize="md"
                                  width="14px"
                                  height="14px"
                                />
                              </Box>

                              {atividadesPausadas.funcionario}
                            </Flex>{' '}
                          </Link>
                        </Box>

                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {atividadesPausadas.demanda}
                        </Box>

                        <Box as="td" py={2} px={3} fontSize="sm" color="black">
                          <a
                            href={`${process.env.REACT_APP_OLDMYIPAC}/planejamento/index/${atividadesPausadas.demanda_id}?aten=${atividadesPausadas.atendimento_id}&pg=1`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {atividadesPausadas.atividade}
                            <Flex justifyContent="right">
                              <FiExternalLink
                                color="#33b5e5"
                                fontSize="md"
                                width="14px"
                                height="14px"
                              />
                            </Flex>
                          </a>
                        </Box>

                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {atividadesPausadas.empresa}
                        </Box>
                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {atividadesPausadas.atividade_duracao}
                        </Box>

                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {format(
                            parseISO(atividadesPausadas.inicio_planejamento),
                            'dd/MM/yyyy',
                          )}
                        </Box>

                        <Box
                          as="td"
                          py={2}
                          px={3}
                          color="#787f9a"
                          fontSize="sm"
                        >
                          {format(
                            parseISO(atividadesPausadas.inicio_planejamento),
                            'HH:mm:ss',
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {/*    <Box id="pausedActivities">



        </Box> */}
      </>

      <AlertDialog
        title="Finalizar Atividade?"
        description={`Está pronto para finalizar a atividade: ${currentActivity?.atividade}`}
        isOpen={isOpenFinish}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setCurrentActivity(undefined);
          onCloseFinish();
        }}
        onSubmit={() => handleFinishedActivity()}
        VariantColor="green"
        submitButtonColor="green.500"
        isLoading={loading}
      />

      <AlertDialog
        title={`${msgTitleAlert} Atividade?`}
        description={`Está pronto para ${msgContAlert} a atividade: ${currentActivity?.atividade}`}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setCurrentActivity(undefined);
          onClose();
        }}
        onSubmit={() => handleActivity()}
        VariantColor={`${variantColorAlert}`}
        submitButtonColor={`${variantColorAlert}.500`}
        isLoading={loading}
      />
    </Box>
  );
};

export default ActivitiesStatus;
