import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  FaBan,
  FaEdit,
  FaEraser,
  FaFileAlt,
  FaFolderOpen,
  FaLightbulb,
  FaPlusSquare,
  FaSearch,
  FaStream,
  FaSyncAlt,
  FaTimesCircle,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  IconButton,
  Progress,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import AlertDialog from '~/shared/components/AlertDialog';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import TextareaChakra from '~/shared/components/InputChakra/Textarea';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import CardAttendance from '../../components/CardAttendance';
import { ProgressLabelCustom } from './styles';

interface Data {
  id: number;
  demand_id: number;
  competence?: string;
  description?: string;
  referent?: string;
  created_at: string;
  archived: number;
  demand_name: string;
  status_name: string;
  status_id: number;
  status_color: string;
  requester: string;
  company_name: string;
  total_activities: number;
  total_activities_completed?: number;
  final_data_planning?: string;
  final_hours_planning?: string;
  fatal_data_activities?: string;
  fatal_hours_activities?: string;
  fatal_prev_late_date?: string;
  start_date_prev_late?: string;
  percent_activities_completed: number;
}

interface AttendancesPag extends IPaginationProps {
  data: Data[];
}

interface CountAttendance {
  attendances_status_id: number;
  nome: string;
  total: string;
  color: string;
  description: string;
}

interface Attendance {
  attendances: AttendancesPag;
  count_attendances: CountAttendance[];
}

interface AttendanceDemand {
  id: number;
  name: string;
  min_data: string;
  max_date: string;
  label: string;
  value: number;
}

interface AttendanceCompany {
  id: number;
  name: string;
  min_data: string;
  max_date: string;
  label: string;
  value: number;
}

interface Demand {
  id: number;
  nome: string;
  label: string;
  value: number;
}

interface Company {
  company_id: number;
  name: string;
  razao_social: string;
  label: string;
  value: number;
}

interface Filters {
  attendances_demand: AttendanceDemand[];
  attendances_company: AttendanceCompany[];
  demands: Demand[];
  companies: Company[];
}

interface CancelAttendances {
  id: number;
}

interface ToFileAttendances {
  id: number;
}

interface DemandSelected {
  id: number;
  name?: string;
}

interface DateFilter {
  start_date: string;
  end_date: string;
}

const Attendances: React.FC = () => {
  const history = useHistory();
  const can = useCan();

  const formRef = useRef<FormHandles>(null);
  const formRefCreate = useRef<FormHandles>(null);
  const formRefUpdate = useRef<FormHandles>(null);
  const ToFileRef = useRef(null);
  const CancelRef = useRef(null);
  const btnRefEdit = useRef(null);
  const EditAttendanceRef = useRef<FormHandles>(null);
  const FormRefFilters = useRef<FormHandles>(null);

  const { user } = useAuth();

  const addToast = useToast();

  const [attendance, setAttendance] = useState<AttendancesPag>(
    {} as AttendancesPag,
  );
  const [
    countPendingAttendances,
    setCountPendingAttendances,
  ] = useState<CountAttendance>({} as CountAttendance);
  const [
    countInProgressAttendances,
    setCountInProgressAttendances,
  ] = useState<CountAttendance>({} as CountAttendance);
  const [
    countConcludedAttendances,
    setCountConcludedAttendances,
  ] = useState<CountAttendance>({} as CountAttendance);
  const [
    countCanceledAttendances,
    setCountCanceledAttendances,
  ] = useState<CountAttendance>({} as CountAttendance);

  const [demand, setDemand] = useState<Demand[]>([]);
  const [company, setCompany] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedDemand, setSelectedDemand] = useState(0);

  const [selectedAttendanceEdit, setSelecteAttendanceEdit] = useState<Data>();

  const [dataFilters, setDataFilters] = useState<Filters>({} as Filters);

  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingToFile, setLoadingToFile] = useState(false);
  const [loadingEditAttendance, setLoadingEditAttendance] = useState(false);
  const [loadingCreateAttendance, setLoadingCreateAttendance] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [
    cancelAttendanceId,
    setCancelAttendanceId,
  ] = useState<CancelAttendances>();
  const [
    toFileAttendanceId,
    setToFileAttendanceId,
  ] = useState<ToFileAttendances>();

  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
  } = useDisclosure();

  const {
    isOpen: isOpenCancel,
    onOpen: onOpenCancel,
    onClose: onCloseCancel,
  } = useDisclosure();

  const {
    isOpen: isOpenToFile,
    onOpen: onOpenToFile,
    onClose: onCloseToFile,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  const [page, setPage] = useState(1);

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'ID',
      },
      {
        title: '',
      },
      {
        title: 'Atividades/Planejamento',
      },
      {
        title: 'Demanda',
      },

      {
        title: 'Referente',
      },

      {
        title: 'Empresa Cliente',
      },
      {
        title: 'Solicitante',
      },
      {
        title: 'Data Da Solicitação',
      },
      {
        title: 'Ações',
        align: 'right',
      },
    ];
  }, []);

  const handleFilters = useCallback(() => {
    setLoadingFilters(true);
    api
      .get<Filters>('attendances/filters', {
        params: {
          demand_id: selectedDemand,
          client_company_id: selectedCompany,
        },
      })
      .then((response) => {
        const { data } = response;

        setDemand(
          data.demands.map((dem) => {
            return {
              ...dem,
              label: dem.nome,
              value: dem.id,
            };
          }),
        );

        setCompany(
          data.companies.map((comp) => {
            return {
              ...comp,
              label: comp.name,
              value: comp.company_id,
            };
          }),
        );
        setDataFilters({
          companies: data.companies.map((comp) => {
            return {
              ...comp,
              label: comp.name,
              value: comp.company_id,
            };
          }),
          demands: data.demands.map((dem) => {
            return {
              ...dem,
              label: dem.nome,
              value: dem.id,
            };
          }),
          attendances_company: data.attendances_company.map((attComp) => {
            return {
              ...attComp,
              label: attComp.name,
              value: attComp.id,
            };
          }),
          attendances_demand: data.attendances_demand.map((attDem) => {
            return {
              ...attDem,
              label: attDem.name,
              value: attDem.id,
            };
          }),
        });
      })
      .finally(() => setLoadingFilters(false));
  }, [selectedCompany, selectedDemand]);

  const handleDateFilter = useCallback((data) => {
    setStartDate(data.start_date);
    setEndDate(data.end_date);
  }, []);

  const handleClearFilters = useCallback(() => {
    try {
      setLoadingTable(true);
      formRef.current?.reset();
      setStartDate('');
      setEndDate('');
    } catch (err) {
      if (err.response?.status < 500) {
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          isClosable: true,
        });
      }
    } finally {
      setLoadingTable(false);
    }
  }, [addToast]);

  async function cancelAttendance(): Promise<void> {
    try {
      setLoadingCancel(true);

      await api.patch<CancelAttendances>(
        `/attendances/${cancelAttendanceId?.id}/cancel`,
      );

      setAttendance((state) => ({
        ...state,
        data: state?.data.map((att) => {
          return att.id === cancelAttendanceId?.id
            ? {
                ...att,
                status_id: 13,
              }
            : att;
        }),
      }));

      setCountCanceledAttendances((state) => ({
        ...state,
        total: state.total + 1,
      }));

      setCountPendingAttendances((state) => ({
        ...state,
        total: (Number(state.total) - 1).toString(),
      }));

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Atendimento Cancelado!',
        description: `Atendimento cancelado com sucesso!`,
        status: 'success',
      });
    } catch (err) {
      // console.log(err);
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Não foi possível cancelar o atendimento',
        description: err.response.data?.error || 'Tente novamente, mais tarde',
        status: 'error',
      });
    } finally {
      setLoadingCancel(false);
      onCloseCancel();
    }
  }

  async function toFileAttendance(): Promise<void> {
    try {
      setLoadingToFile(true);
      await api.patch<ToFileAttendances>(
        `/attendances/${toFileAttendanceId?.id}/to-file`,
        { to_file: 1 },
      );

      setAttendance((state) => ({
        ...state,
        data: state.data.filter((att) => att.id !== toFileAttendanceId?.id),
      }));

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Atendimento Arquivado!',
        description: `Atendimento arquivado com sucesso!`,
        status: 'success',
      });
    } catch (err) {
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Não foi possível arquivar o atendimento',
        description:
          err?.response?.data?.error || 'Tente novamente, mais tarde',
        status: 'error',
      });
    } finally {
      setLoadingToFile(false);
      onCloseToFile();
    }
  }

  const handleCreateAttendance = useCallback(
    async (dataCreate) => {
      // console.log(data);
      setLoadingCreateAttendance(true);
      setLoadingTable(true);
      try {
        formRefCreate.current?.setErrors({});

        const schema = Yup.object().shape({
          company_client_id: Yup.string().required('Selecione uma empresa'),
          demand_id: Yup.string().required('Selecione uma demanda'),
        });

        await schema.validate(dataCreate, {
          abortEarly: false,
        });

        const response = await api.post('/attendances', dataCreate);

        const demandName = demand.find(
          (state) => state.id === Number(response.data.demanda_id),
        );

        const companyName = company.find(
          (state) =>
            state.company_id === Number(response.data.empresa_cliente_id),
        );

        // console.log(demandName, companyName);
        setAttendance((state) => ({
          ...state,
          data: [
            {
              ...dataCreate,
              percent_activities_completed: 0,
              created_at: response.data.created_at,
              usuario_id: user,
              updated_at: response.data.updated_at,
              id: response.data.id,
              company_name: companyName?.name,
              demand_name: demandName?.nome,
            },
            ...state.data,
          ],
          per_page: state.per_page + 1,
          to: state.to + 1,
          total: state.total + 1,
        }));

        setCountPendingAttendances((state) => ({
          ...state,
          total: state?.total + 1,
        }));

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Atendimento cadastrado!',
          description: 'Atendimento cadastrado com sucesso!',
        });

        onCloseCreate();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefCreate.current?.setErrors(errors);

          return;
        }

        const error = err.response?.data?.error;

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao tentar cadastrar atendimetno',
          description: error,
        });
      } finally {
        setLoadingCreateAttendance(false);
        setLoadingTable(false);
      }
    },
    [addToast, onCloseCreate, user, company, demand],
  );

  const handleEditAttendance = useCallback(
    (dataEditAttendances) => {
      setLoadingEditAttendance(true);
      // setLoadingTable(true);

      api
        .put<Data>(`/attendances/${selectedAttendanceEdit?.id}`, {
          demand_id: dataEditAttendances?.demand_id,
          competence: dataEditAttendances?.competence,
          description: dataEditAttendances?.description,
        })
        .then(() => {
          setAttendance((state) => ({
            ...state,
            data: state?.data.map((att) => {
              return att?.id === selectedAttendanceEdit?.id
                ? {
                    ...att,
                    description: dataEditAttendances.description,
                    competence: dataEditAttendances.competence,
                    referent: dataEditAttendances.referent,
                  }
                : att;
            }),
          }));
          setSelecteAttendanceEdit(undefined);
          onCloseEdit();

          addToast({
            position: 'top-right',
            isClosable: true,
            title: 'Atendimento Editado!',
            description: `Atendimento editado com sucesso!`,
            status: 'success',
          });
        })

        .catch((err) => {
          addToast({
            position: 'top-right',
            isClosable: true,
            title: 'Não foi possível editar o atendimento',
            description:
              err.response.data?.error || 'Tente novamente, mais tarde',
            status: 'error',
          });
        })

        .finally(() => {
          setLoadingEditAttendance(false);
        });
    },
    [addToast, onCloseEdit, selectedAttendanceEdit],
  );

  useEffect(() => {
    setLoadingTable(true);

    api
      .get<Attendance>(`/attendances`, {
        params: {
          page,
          demand_id: selectedDemand,
          client_company_id: selectedCompany,
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((resp) => {
        const { attendances, count_attendances } = resp.data;

        setAttendance(attendances);

        count_attendances.forEach((count) => {
          switch (count.attendances_status_id) {
            case 10:
              setCountPendingAttendances({
                nome: count.nome,
                color: '#FEEBC8',
                description:
                  'Trata-se de atendimentos que ainda não tem nenhuma atividade INICIADA.',
                total: count.total,
                attendances_status_id: count.attendances_status_id,
              });

              break;
            case 11:
              setCountInProgressAttendances({
                nome: count.nome,
                color: '#BEE3F8',
                description:
                  'Trata-se de atendimentos com uma ou mais atividades EM ANDAMENTO.',
                total: count.total,
                attendances_status_id: count.attendances_status_id,
              });

              break;
            case 12:
              setCountConcludedAttendances({
                nome: count.nome,
                color: '#C6F6D5',
                description: 'Trata-se de atendimentos que já foram CONCLUÍDO.',
                total: count.total,
                attendances_status_id: count.attendances_status_id,
              });

              break;
            case 13:
              setCountCanceledAttendances({
                nome: count.nome,
                color: '#FED7D7',
                description: 'Trata-se de atendimentos que foram CANCELADO.',
                total: count.total,
                attendances_status_id: count.attendances_status_id,
              });

              break;
            default:
              '';
              break;
          }
        });
        handleFilters();
      })
      .finally(() => {
        setLoading(false);
        setLoadingTable(false);
      });
  }, [
    handleFilters,
    page,
    selectedCompany,
    selectedDemand,
    endDate,
    startDate,
  ]);

  const handleAttendancesPlanning = (
    attendancesId: number,
    demandId: number,
  ): void => {
    history.push({
      pathname: `/gerenciar/atendimentos/${attendancesId}`,
      search: `demand_id=${demandId}`,
    });
  };

  return (
    <Box pos="relative">
      {loading && <LoadingAbsolute z_index={1111} min_height={500} />}
      <SectionHeader
        title="Gerenciando Atendimentos"
        pagename="Gerenciar os atendimentos"
        goBackPage
      >
        <Flex flexDir={['column', 'column', 'column', 'row']}>
          {can('list-attendances') && (
            <Tooltip
              label="Registrar um novo atendimento"
              aria-label="Registrar um novo atendimento"
            >
              <Button
                colorScheme="blue"
                mr={[0, 0, 0, 3]}
                mb={[3, 3, 3, 0]}
                onClick={onOpenCreate}
                isLoading={loading}
              >
                <FaPlusSquare />
                <Text ml="5px">NOVO ATENDIMENTO</Text>
              </Button>
            </Tooltip>
          )}

          <Tooltip
            label="Registrar uma nova demanda recorrente para uma empresa"
            aria-label="Registrar uma nova demanda recorrente para uma empresa"
          >
            <Button color="gray" onClick={onOpenUpdate} isLoading={loading}>
              <FaSyncAlt /> <Text ml="5px">NOVA RECORRÊNCIA</Text>
            </Button>
          </Tooltip>
        </Flex>
      </SectionHeader>

      {!loading && (
        <>
          <Grid
            templateColumns={{
              base: '1fr',
              lg: 'repeat(auto-fit, minmax(320px, 1fr))',
            }}
            templateRows="200px"
            gap={[2, 2]}
            justifyContent="flex-start"
          >
            {countPendingAttendances && (
              <CardAttendance
                color={countPendingAttendances.color}
                description={countPendingAttendances.description}
                title={countPendingAttendances.nome}
                total={countPendingAttendances.total}
              />
            )}

            {countInProgressAttendances && (
              <CardAttendance
                color={countInProgressAttendances.color}
                description={countInProgressAttendances.description}
                title={countInProgressAttendances.nome}
                total={countInProgressAttendances.total}
              />
            )}

            {countConcludedAttendances && (
              <CardAttendance
                color={countConcludedAttendances.color}
                description={countConcludedAttendances.description}
                title={countConcludedAttendances.nome}
                total={countConcludedAttendances.total}
              />
            )}

            {countCanceledAttendances && (
              <CardAttendance
                color={countCanceledAttendances.color}
                description={countCanceledAttendances.description}
                title={countCanceledAttendances.nome}
                total={countCanceledAttendances.total}
              />
            )}
          </Grid>

          <Form onSubmit={handleDateFilter} ref={formRef}>
            <Box mt={6} mb={4}>
              <Flex
                alignItems="center"
                flexDir={['column', 'column', 'column', 'column', 'row']}
                mr={[0, '10px']}
              >
                <Box minWidth="300px" mr={[0, 3]}>
                  <InputChakra
                    name="start_date"
                    label="Data inicial"
                    type="date"
                    disabled={loadingTable}
                    defaultValue={startDate}
                  />
                </Box>
                <Box minWidth="300px">
                  <InputChakra
                    name="end_date"
                    label="Data fim"
                    type="date"
                    disabled={loadingTable}
                    defaultValue={endDate}
                  />
                </Box>

                <Button
                  onClick={handleClearFilters}
                  isDisabled={loadingTable}
                  minWidth="160px"
                  mb={[3, 3, 3, 3, 0]}
                  mx={[0, 3]}
                >
                  <FaEraser /> <Text ml="5px">Limpar Filtros</Text>
                </Button>

                <Button
                  onClick={() => formRef.current?.submitForm()}
                  isDisabled={loadingTable}
                  minWidth="160px"
                >
                  <FaSearch /> <Text ml="5px">Filtrar</Text>
                </Button>
              </Flex>
            </Box>
          </Form>

          {attendance?.data?.length ? (
            <>
              <Form onSubmit={handleFilters} ref={FormRefFilters}>
                <Table
                  theadData={tableTitles}
                  loading={loadingTable}
                  pagination={{
                    current_page: attendance.current_page,
                    last_page: attendance.last_page,
                    per_page: attendance.per_page,
                    to: attendance.to,
                    total: attendance.total,
                  }}
                  newPage={(pg) => setPage(pg)}
                >
                  <Box as="tr">
                    <Box as="td" color="#89889c" />
                    <Box as="td" color="#89889c" />
                    <Box as="td" color="#89889c" />
                    <Box as="td" color="#89889c">
                      <SelectChakra
                        name="demand_id"
                        placeholder={loadingFilters ? 'Carregando...' : 'Todas'}
                        borderColor="white"
                        options={dataFilters.attendances_demand}
                        onChange={(el) => {
                          setSelectedDemand(Number(el.target.value));
                        }}
                        disabled={loadingFilters}
                        defaultValue={selectedDemand}
                      />
                    </Box>
                    <Box as="td" color="#89889c" />
                    <Box as="td" color="#89889c">
                      <SelectChakra
                        name="client_company_id"
                        placeholder={loadingFilters ? 'Carregando...' : 'Todas'}
                        borderColor="white"
                        options={dataFilters.attendances_company}
                        onChange={(el) => {
                          setSelectedCompany(Number(el.target.value));
                        }}
                        disabled={loadingFilters}
                        defaultValue={selectedCompany}
                      />
                    </Box>
                  </Box>

                  {attendance?.data.map((att) => {
                    return (
                      <Box
                        as="tr"
                        key={att.id}
                        onClick={() =>
                          handleAttendancesPlanning(att.id, att.demand_id)
                        }
                        zIndex={10}
                        cursor="pointer"
                      >
                        <Box as="td" pr="10px">
                          <Text fontSize="sm">{att.id}</Text>
                        </Box>

                        <Box as="td">
                          {att.status_id === 13 ? (
                            <>
                              <Flex>
                                {att.final_data_planning ? (
                                  <Tooltip
                                    label="Este atendimento está com uma (ou mais) atividade(s) com entrega atrasada(s) em relação ao planejamento"
                                    aria-label="Aviso de atraso de atendimento"
                                  >
                                    <Box>
                                      <IconButton
                                        justifyContent="flex-start"
                                        aria-label="Alerta de atividade atrasada"
                                        icon={<FaLightbulb />}
                                        background="trasnparente"
                                        variant="link"
                                        boxShadow="none"
                                        colorScheme="red"
                                      />
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  <FaLightbulb />
                                )}
                                <Tooltip
                                  label="Cancelado"
                                  aria-label="Atendidmento Cancelado"
                                >
                                  <Box ml="40px">
                                    <FaTimesCircle color="red" />
                                  </Box>
                                </Tooltip>
                              </Flex>
                            </>
                          ) : (
                            <>
                              <Flex alignItems="center">
                                {att.final_data_planning ? (
                                  <Tooltip
                                    label="Este atendimento está com uma (ou mais) atividade(s) com entrega atrasada(s) em relação ao planejamento"
                                    aria-label="Aviso de atraso de atendimento"
                                  >
                                    <Box>
                                      <FaLightbulb color="red" />
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  <FaLightbulb />
                                )}
                                <Tooltip
                                  label="Concluídas | Total atividades definidas"
                                  aria-label="descrição"
                                >
                                  <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    mx={2}
                                  >
                                    <Badge mr={1}>
                                      {att.total_activities_completed || '0'}
                                    </Badge>{' '}
                                    |
                                    <Badge ml={1}>
                                      {att.total_activities || '0'}
                                    </Badge>
                                  </Flex>
                                </Tooltip>
                              </Flex>
                            </>
                          )}
                        </Box>
                        <Box as="td">
                          <Progress
                            maxWidth="190px"
                            value={att.percent_activities_completed}
                            colorScheme={att.status_color}
                          >
                            <ProgressLabelCustom
                              fontSize="xs"
                              color={`${att.percent_activities_completed}`}
                              value={att.percent_activities_completed}
                            >
                              {att.percent_activities_completed.toFixed(2)}%
                            </ProgressLabelCustom>
                          </Progress>
                        </Box>

                        <Tooltip
                          label={att.description}
                          aria-label="Descrição da demanda"
                        >
                          <Box as="td">
                            <Flex alignItems="center">
                              <Box width="16px">
                                <FaFileAlt size="16px" />
                              </Box>

                              <Text ml="5px">{att.demand_name}</Text>
                            </Flex>

                            {att.competence && (
                              <Stack direction="row">
                                <Badge variant="solid" colorScheme="gray">
                                  Competência:
                                </Badge>
                                <Badge>{att.competence}</Badge>
                              </Stack>
                            )}
                          </Box>
                        </Tooltip>

                        <Box as="td" textAlign="center">
                          <Text>{att.referent || '--'}</Text>
                        </Box>

                        <Box as="td">
                          <Text>{att.company_name}</Text>
                        </Box>

                        <Box as="td">{att.requester}</Box>

                        <Box as="td">
                          <Text>
                            {format(
                              parseISO(att?.created_at),
                              "dd/MM/yyyy 'ás' HH:mm",
                            )}
                          </Text>
                        </Box>

                        <Box as="td">
                          <Flex>
                            {/* <Tooltip
                              label="Visualizar detalhes do atendimento"
                              aria-label="visualizar"
                            >
                              <IconButton
                                aria-label="visualizar"
                                icon={<FaEye />}
                                variant="ghost"
                                colorScheme="blue"
                              />
                            </Tooltip> */}

                            <Tooltip
                              label="Editar atendimento"
                              aria-label="Editar"
                            >
                              <IconButton
                                zIndex={1000}
                                aria-label="Editar"
                                icon={<FaEdit />}
                                variant="ghost"
                                colorScheme="yellow"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSelecteAttendanceEdit(att);
                                  onOpenEdit();
                                }}
                              />
                            </Tooltip>

                            {att.status_id === 12 || att.status_id === 13 ? (
                              <Tooltip
                                label="Arquivar atendimento"
                                aria-label="Arquivar"
                              >
                                <IconButton
                                  aria-label="Arquivar"
                                  icon={<FaFolderOpen />}
                                  variant="ghost"
                                  colorScheme="teal"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setToFileAttendanceId({ id: att.id });
                                    onOpenToFile();
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ''
                            )}

                            {att.status_id === 10 ? (
                              <Tooltip
                                label="Cancelar atendimento"
                                aria-label="Cancelar"
                              >
                                <IconButton
                                  aria-label="Cancelar"
                                  icon={<FaBan />}
                                  variant="ghost"
                                  colorScheme="red"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setCancelAttendanceId({
                                      id: att.id,
                                    });
                                    onOpenCancel();
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ''
                            )}
                          </Flex>
                        </Box>
                      </Box>
                    );
                  })}
                </Table>
              </Form>
            </>
          ) : (
            <Flex justifyContent="center">
              <Alert status="info">
                <AlertIcon />
                <Text>
                  {' '}
                  Nenhum atendimento encontrado! Limpe os filtros e tente
                  realizar uma nova consulta.
                </Text>
              </Alert>
            </Flex>
          )}

          <ModalChakra
            title="Novo Atendimento"
            onClose={() => {
              onCloseCreate();
            }}
            isOpen={isOpenCreate}
            titleButtonCancel="Cancelar"
            onSubmit={() => formRefCreate.current?.submitForm()}
            isLoading={loadingCreateAttendance}
          >
            <Form onSubmit={handleCreateAttendance} ref={formRefCreate}>
              <SelectChakra
                name="company_client_id"
                options={company.map((comp) => {
                  return {
                    ...comp,
                    label: comp.name,
                    value: comp.company_id,
                  };
                })}
                placeholder={
                  loadingFilters ? 'Carregando...' : 'Selecionar Empresa'
                }
                required
                label="Empresa"
                mb="10px"
              />
              <SelectChakra
                name="demand_id"
                options={demand.map((dem) => {
                  return { ...dem, value: dem.id, label: dem.nome };
                })}
                placeholder={
                  loadingFilters ? 'Carregando...' : 'Selecionar Demanda'
                }
                required
                label="Demanda"
              />

              <InputChakra type="text" name="referent" label="Referente" />

              <InputChakra
                type="text"
                maxLength={7}
                minLength={7}
                name="competence"
                label="Competência"
                width="103px"
              />

              <Box>
                <Flex alignItems="center">
                  <FaStream />
                  <Text
                    display="flex"
                    alignItems="center"
                    color="#718096"
                    ml={2}
                  >
                    Descrição
                  </Text>
                </Flex>
                <TextareaChakra
                  name="description"
                  maxLength={250}
                  placeholder="No máximo 250 caracteres"
                />
              </Box>
            </Form>
          </ModalChakra>

          <ModalChakra
            title="Nova Recorrência"
            onClose={() => {
              onCloseUpdate();
            }}
            isOpen={isOpenUpdate}
            titleButtonCancel="Cancelar"
            onSubmit={() => formRefUpdate.current?.submitForm()}
            isLoading={loadingFilters}
          >
            <Form onSubmit={() => ''} ref={formRefUpdate}>
              <SelectChakra
                name="company_client"
                options={company.map((comp) => {
                  return {
                    ...comp,
                    label: comp.name,
                    value: comp.company_id,
                  };
                })}
                placeholder={
                  loadingFilters ? 'Carregando...' : 'Selecionar Empresa'
                }
                required
                label="Empresa"
                mb="10px"
              />
              <SelectChakra
                name="Demand"
                options={demand.map((dem) => {
                  return { ...dem, value: dem.id, label: dem.nome };
                })}
                label="Demanda"
                placeholder={
                  loadingFilters ? 'Carregando...' : 'Selecionar Demanda'
                }
                required
              />
            </Form>
          </ModalChakra>

          <AlertDialog
            title="Cancelar Atendimento"
            description={`Deseja realmente cancelar o antendimento?
                Caso tenha atividade planejadas elas serão deletadas.`}
            isOpen={isOpenCancel}
            onClose={onCloseCancel}
            onSubmit={() => cancelAttendance()}
            isLoading={loadingCancel}
            leastDestructiveRef={CancelRef}
          />
          <AlertDialog
            title="Arquivar Atendimento"
            description="O atendimento será arquivado! Deseja continuar?"
            isOpen={isOpenToFile}
            onClose={onCloseToFile}
            onSubmit={() => toFileAttendance()}
            isLoading={loadingToFile}
            leastDestructiveRef={ToFileRef}
            VariantColor="blue"
            submitButtonColor="blue.500"
          />
          {selectedAttendanceEdit && (
            <Drawer
              preserveScrollBarGap
              isOpen={isOpenEdit}
              placement="right"
              onClose={onCloseEdit}
              size="sm"
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  Editar Atendimento
                </DrawerHeader>

                <DrawerBody>
                  <Form ref={EditAttendanceRef} onSubmit={handleEditAttendance}>
                    <SelectChakra
                      name="demand_id"
                      placeholder={selectedAttendanceEdit.demand_name}
                      defaultValue={selectedAttendanceEdit.demand_id}
                      disabled
                      label="Demanda"
                      options={[
                        {
                          value: selectedAttendanceEdit.demand_id,
                          label: selectedAttendanceEdit.demand_name,
                        },
                      ]}
                    />

                    <InputChakra
                      type="text"
                      name="referent"
                      label="Referente"
                      defaultValue={selectedAttendanceEdit.referent}
                    />

                    <InputChakra
                      type="text"
                      maxLength={7}
                      minLength={7}
                      name="competence"
                      label="Competência"
                      width="103px"
                      defaultValue={selectedAttendanceEdit.competence}
                    />

                    <Box>
                      <Flex alignItems="center">
                        <FaStream />
                        <Text
                          display="flex"
                          alignItems="center"
                          color="#718096"
                          ml={2}
                        >
                          Descrição
                        </Text>
                      </Flex>
                      <TextareaChakra
                        name="description"
                        maxLength={250}
                        placeholder="No máximo 250 caracteres"
                        defaultValue={selectedAttendanceEdit.description}
                      />
                    </Box>
                  </Form>
                </DrawerBody>

                <DrawerFooter>
                  <Button variant="ghost" mr={3} onClick={onCloseEdit}>
                    Cancelar
                  </Button>

                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => EditAttendanceRef.current?.submitForm()}
                    isLoading={loadingEditAttendance}
                  >
                    Confirmar
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          )}
        </>
      )}
    </Box>
  );
};

export default Attendances;
