import React from 'react';

export default async function askNotificationPermission(): Promise<string> {
  // Check if the browser supports notifications
  if (!('Notification' in window)) {
    // console.log('This browser does not support notifications.');
    return 'denied';
  }

  return new Promise((resolve, reject) => {
    let timedOut = false;

    const timeout = setTimeout(() => {
      // console.log('timed out');
      resolve('default');
      timedOut = true;
    }, 10000);

    Notification.requestPermission()
      .then((permission) => {
        // console.log(permission);
        resolve(permission);

        if (timedOut && permission !== 'default') {
          window.location.reload();
        }
      })
      .catch(() => {
        // console.log('Permission request failed:', error);
        reject(
          new Error('Falha ao solicitar permissão para exibir notificação'),
        );
      })
      .finally(() => clearTimeout(timeout));
  });

  // O usuário ainda não definiu se é para permitir as notificações, solicitamos a permissão
  /* if (Notification.permission === 'default') {
    try {
      const permission = await Notification.requestPermission();

      return permission === 'granted';
    } catch (err) {
      alert('Erro ao solicitar permissão de notificação');
    }
  }

  return false; */
}
