import styled from 'styled-components';

interface ActivityContentProps {
  inProgress?: boolean;
}

export const Container = styled.div`
  width: 100%;
  margin-top: 80px;

  display: flex;
  flex-direction: column;

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #8694a0;
    text-transform: uppercase;
    padding: 20px;
    border-bottom: 1px solid #d5dee5;
    margin-bottom: 30px;
  }
`;

export const Content = styled.div`
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;

  > button {
    border: 0;
    background: transparent;
    font-size: 14px;
    color: #8694a0;
    margin-top: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  > ul {
    list-style: none;
    width: 100%;
    padding-left: 25px;
    color: #8694a0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 45px;

      padding: 0 20px;

      strong {
        font-size: 16px;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: #2f2e38;
  margin-bottom: 30px;

  img {
    height: 41px;
    margin-right: 10px;
  }

  strong {
    font-size: 20px;
    line-height: 23px;
  }
`;

export const ActivityContainer = styled.div`
  padding-left: 50px;

  display: flex;
  flex-direction: column;
`;

export const Activity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-height: 100px;
  padding: 10px 0;
  border-bottom: 1px solid #f8f9fe;

  @media (max-width: 780px) {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
`;

export const ActivityHeader = styled.div`
  display: flex;
  flex-direction: column;
  color: #8694a0;

  div {
    display: flex;

    span {
      border-radius: 5px;
      padding: 2px 5px;
      color: #fff;
      background: #8694a0;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }

    margin-bottom: 10px;
  }

  p {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 13px;

    & + p {
      margin-left: 20px;
    }

    svg {
      margin-right: 10px;
    }

    small {
      display: flex;
      align-items: center;
    }
  }
`;

export const ActivityContent = styled.div<ActivityContentProps>`
  display: flex;
  align-items: center;

  margin-left: 20px;

  > img {
    height: 43px;
    margin-right: 10px;
    opacity: ${(props) => (props.inProgress ? 0.5 : 1)};
  }

  > span {
    display: flex;
    flex-direction: column;

    strong {
      font-weight: 500;
      font-size: 18px;
      color: #8694a0;
    }

    small {
      color: #d5dee5;
      font-size: 11px;
      line-height: 13px;
    }
  }

  button {
    border: 2px solid #d5dee5;
    border-radius: 0px 10px 10px 10px;
    height: 38px;
    padding: 0 10px;
    background: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 140px;

    span {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      margin-right: 10px;
      background: ${(props) => (props.inProgress ? '#169bd6' : '#cecece')};
    }
  }

  @media (max-width: 780px) {
    margin-left: 0;

    & + div {
      margin-left: 20px;
    }
  }
`;
