import { Link } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

/* export const ChartBoxSkeleton = styled(Box)`
  height: 390px;
  background: #eef5ff;

  @media (min-width: 480px) {
    height: 515px;
  }
  @media (min-width: 992px) {
    height: 440px;
  }
  @media (min-width: 1280px) {
    height: 515px;
  }
`; */

export const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background: rgb(238, 245, 255);
  min-width: 300px;
  color: ${({ theme }) => theme.colors.text};

  border-bottom: 4px solid transparent;
  transition: all 0.2s;

  &:hover {
    border-color: ${darken(0.05, 'rgb(238, 245, 255)')};
  }

  @media (min-width: 1600px) {
    max-width: 700px;
  }
`;

export const CardBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background: rgb(238, 245, 255);
  min-width: 300px;
  color: ${({ theme }) => theme.colors.text};

  border-bottom: 4px solid transparent;
  transition: all 0.2s;

  &:hover {
    border-color: ${darken(0.05, 'rgb(238, 245, 255)')};
  }

  @media (min-width: 1600px) {
    max-width: 700px;
  }
`;
