import React from 'react';
import { useCallback } from 'react';

import ChartActivity from '~/modules/activityExecution/components/ChartActivity';

import { Container } from './styles';

const ActivityAnalysis: React.FC = () => {
  const handleSelect = useCallback(() => {
    //
  }, []);
  return (
    <Container>
      <h4>Análise de atividades</h4>

      <ChartActivity selectDate={handleSelect} simpledisplay />
    </Container>
  );
};

export default ActivityAnalysis;
