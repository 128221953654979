import React, { useState, useEffect } from 'react';
import { FiEye } from 'react-icons/fi';

import {
  Box,
  Flex,
  IconButton,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';

interface Demand {
  id: number;
  qtd_planejamentos: number;
  competencia?: string;
  descricao?: string;
  nome_empresa: string;
  nome_demanda: string;
  demanda_id: number;
  created_at: string;
  createdFormated?: string;
}

interface Props {
  demands?: Demand[] | undefined;
  status: string;
}

const TableDemand: React.FC<Props> = ({ demands, status }) => {
  const [demandList, setDemandList] = useState<Demand[] | undefined>([]);

  useEffect(() => {
    const formatedDemandList = demands?.map((demand) => {
      return {
        ...demand,
        createdFormated: format(
          parseISO(demand.created_at),
          "dd/MM/yyyy 'às' HH:mm",
        ),
      };
    });

    setDemandList(formatedDemandList);
  }, [demands]);

  return (
    <Flex overflowX="auto" w="full" mb={5}>
      <Box as="table" w="full" style={{ borderSpacing: 0 }}>
        <Box as="thead" w="full" p={4} fontWeight="bold" color="gray.500">
          <Box as="tr" borderBottomWidth={2}>
            <Box as="td" p={2} fontSize="sm">
              CÓD. ATEND.
            </Box>
            <Box as="td" p={2} fontSize="sm" width="245px">
              QTD. ATIVIDADES <br /> ({status.toUpperCase()})
            </Box>
            <Box as="td" p={2} fontSize="sm">
              DEMANDA
            </Box>
            <Box as="td" p={2} fontSize="sm">
              EMPRESA
            </Box>
            <Box as="td" p={2} fontSize="sm">
              COMPETENCIA
            </Box>
            <Box as="td" p={2} fontSize="sm">
              DATA CRIAÇÃO
            </Box>
            <Box as="td" p={2} fontSize="sm">
              Ações
            </Box>
          </Box>
        </Box>
        <Box as="tbody" w="full" color="gray.800">
          {demandList &&
            demandList.length > 0 &&
            demandList?.map((demand) => (
              <Box as="tr" w="full" key={String(demand.id)}>
                <Box as="td" p={2} fontSize="sm">
                  <Tag>{demand.id}</Tag>
                </Box>
                <Box
                  as="td"
                  p={2}
                  fontSize="sm"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {demand.qtd_planejamentos}
                </Box>
                <Box as="td" p={2} fontSize="sm">
                  {demand.nome_demanda.length > 19 ? (
                    <Tooltip
                      aria-label={demand.nome_demanda}
                      label={demand.nome_demanda}
                      zIndex={1}
                    >
                      <Text isTruncated maxW={150}>
                        {demand.nome_demanda}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text isTruncated maxW={150}>
                      {demand.nome_demanda}
                    </Text>
                  )}
                </Box>
                <Box as="td" p={2} fontSize="sm">
                  {demand.nome_empresa.length > 17 ? (
                    <Tooltip
                      aria-label={demand.nome_empresa}
                      label={demand.nome_empresa}
                      zIndex={1}
                    >
                      <Text isTruncated maxW={150}>
                        {demand.nome_empresa}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text isTruncated maxW={150}>
                      {demand.nome_empresa}
                    </Text>
                  )}
                </Box>
                <Box as="td" p={2} fontSize="sm">
                  {demand?.competencia && <Tag>{demand.competencia}</Tag>}
                </Box>
                <Box as="td" p={2} fontSize="sm">
                  <Tag>
                    {/* <Icon name="calendar" size="12px" mr={1} /> */}
                    <TagLabel>{demand?.createdFormated}</TagLabel>
                  </Tag>
                </Box>
                <Box as="td" p={2} fontSize="sm">
                  <Tooltip
                    aria-label="Visualizar atendimento"
                    label="Visualizar atendimento"
                    zIndex={1}
                  >
                    <a
                      href={`${process.env.REACT_APP_OLDMYIPAC}/planejamento/index/${demand.demanda_id}?aten=${demand.id}&pg=1`}
                      target="__blank"
                    >
                      <IconButton
                        aria-label="Ver atendimento"
                        icon={<FiEye />}
                      />
                    </a>
                  </Tooltip>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Flex>
  );
};

export default TableDemand;
