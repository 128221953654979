/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormControl, FormErrorMessage, Heading } from '@chakra-ui/react';
import { useField } from '@unform/core';
import { DatePicker, message } from 'antd';
import 'antd/dist/antd.css';

interface RangeValue {
  _d: Date;
}

interface Dates {
  from: string;
  to: string;
}

interface Props {
  name: string;
  label?: string;
  onChange?(dates: Date[]): void;
  onBlur?(dates: Date[]): void;
  containerStyle?: object;
  mb?: number;
  /* Exibir input com borda vermelha sem uma mensagem de erro para inputs inline principalmente */
  showError?: boolean;
}

const RangeDateWithTwoInput: React.FC<Props> = ({
  name,
  label,
  onChange,
  containerStyle,
  mb = 4,
  showError = false,
}) => {
  const { RangePicker } = DatePicker;

  const [dates, setDates] = useState<Date[]>([]);

  const inputRefs = useRef<HTMLInputElement[]>([]);

  /* Se for necessário definir algum valor padrão, setar no initalData do componente Form com o tipo moment */
  const { fieldName, registerField, defaultValue = [], error } = useField(name);

  const [datesDefault, setDatesDefault] = useState<string[]>([
    defaultValue.length ? defaultValue[0]?.format('DD/MM/YYYY') : '',
    defaultValue.length ? defaultValue[1]?.format('DD/MM/YYYY') : '',
  ]);

  useEffect(() => {
    // console.log(defaultValue);
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.map((ref) => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref) => {
          ref.value = '';
        });
      },
      /* setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref, index) => {
          console.log(`ref:`, ref, `values:`, values[index]);

          console.log('value', values[index].toString());
          ref.value = values[index];
        });
      }, */
    });
  }, [defaultValue, fieldName, registerField, dates]);

  useEffect(() => {
    if (onChange && dates.length) {
      onChange(dates);
    }
  }, [dates, onChange]);

  const handleChange = useCallback((datesCalendar, dateStringsCalendar) => {
    const parseDates: RangeValue[] = datesCalendar;
    // console.log('change', dateStringsCalendar);

    setDates(parseDates?.map((date: RangeValue) => date._d) || []);
    setDatesDefault(dateStringsCalendar || []);
    if (dateStringsCalendar && dateStringsCalendar[0]) {
      message.info(
        `Datas selecionadas: ${dateStringsCalendar[0]} até ${dateStringsCalendar[1]}`,
      );
    }
  }, []);

  return (
    <FormControl mb={mb} style={containerStyle} isInvalid={!!error} flex={1}>
      <Heading
        as="h5"
        size="sm"
        fontWeight={500}
        mb={1}
        cursor="default"
        color="gray.500"
      >
        {label || 'Selecione a data de início e termino'}
      </Heading>
      <RangePicker
        defaultValue={defaultValue}
        onChange={handleChange}
        // onBlur={(el) => console.log(el.target.value)}
        format="DD/MM/yyyy"
        style={{
          height: '3rem',
          borderRadius: '5px',
          borderColor: error || showError ? '#E53E3E' : '#E2E8F0',
          boxShadow: error || showError ? '#E53E3E' : '#E2E8F0',
          outlineColor: error || showError ? '#E53E3E' : '#E2E8F0',
          color: '#2f2e38',
          width: '100%',
        }}
      />
      {error && <FormErrorMessage mb={2}>{error}</FormErrorMessage>}

      <input
        type="hidden"
        name={`${name}[0]`}
        ref={(ref: HTMLInputElement) => {
          inputRefs.current[0] = ref as HTMLInputElement;
        }}
        defaultValue={datesDefault[0]}
      />
      <input
        type="hidden"
        name={`${name}[1]`}
        ref={(ref: HTMLInputElement) => {
          inputRefs.current[1] = ref as HTMLInputElement;
        }}
        defaultValue={datesDefault[1]}
      />
    </FormControl>
  );
};

export default RangeDateWithTwoInput;
