import React, { useRef, useState, useEffect, useCallback } from 'react';

import {
  Box,
  Text,
  Button,
  Flex,
  useDisclosure,
  useToast,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { Form } from '@unform/web';

import { useAuth } from '~/hooks/auth';
import AlertDialog from '~/shared/components/AlertDialog';
import SelectChakra, {
  Option,
} from '~/shared/components/InputChakra/SelectChakra';
import SectionHeader from '~/shared/components/SectionHeader';
import Select from '~/shared/components/Select';
import api from '~/shared/services/api';

interface PerformanceReviewEvaluation {
  total_team_evaluations_generated: number;
  total_responsible_evaluations_generated: number;
}

const SettingsPerformanceReview: React.FC = () => {
  const workDealOptions: Option[] = [
    {
      value: '1',
      label: 'Semestral',
    },
    {
      value: '2',
      label: 'Anual',
      disabled: true,
    },
  ];

  const evaluationPeriodOptions: Option[] = [
    {
      value: '1',
      label: 'Bimestral',
    },
    {
      value: '2',
      label: 'Trimestral',
      disabled: true,
    },
    {
      value: '3',
      label: 'Semestral',
      disabled: true,
    },
  ];

  const cancelRefGenerateEvaluations = useRef(null);
  const [loadingGenerateEvaluations, setLoadingGenerateEvaluations] = useState(
    false,
  );

  const [reload, setReload] = useState(0);

  useEffect(() => {
    reload;
  }, [reload]);

  const addToast = useToast();

  const {
    isOpen: isOpenGenerateEvaluations,
    onOpen: onOpenGenerateEvaluations,
    onClose: onCloseGenerateEvaluations,
  } = useDisclosure();

  const handleGenerateEvaluations = useCallback(() => {
    setLoadingGenerateEvaluations(true);

    api
      .post<PerformanceReviewEvaluation>(
        'reports/performance-reviews/evaluations/execution',
      )
      .then(() => {
        /* Renderizar novamente o componente buscando as avaliações */

        /*  const {
          total_responsible_evaluations_generated,
          total_team_evaluations_generated,
        } = resp.data;

        const totalEvaluationsGenerated =
          total_responsible_evaluations_generated +
          total_team_evaluations_generated; */

        // const msg = `Foram geradas ao todo ${totalEvaluationsGenerated} avaliações no período selecionado`;

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Avaliações geradas com sucesso!',
          // description: msg,
          status: 'success',
          duration: 7000,
        });

        setReload((state) => state + 1);
      })
      .catch((err) => {
        addToast({
          position: 'top-right',
          isClosable: true,
          title:
            'Não foi possível gerar as avaliações no acordo de trabalho atual',
          description: err?.response.data?.error || '',
          status: 'error',
          duration: 7000,
        });
      })
      .finally(() => {
        setLoadingGenerateEvaluations(false);
        onCloseGenerateEvaluations();
      });
  }, [addToast, onCloseGenerateEvaluations]);

  return (
    <Box>
      <SectionHeader title="Configuração de Avaliações" />
      <Form onSubmit={() => null}>
        {/* <Flex justifyContent="space-between"> */}
        <Stack
          align={['unset', 'center']}
          direction={['column', 'row']}
          spacing={4}
        >
          <SelectChakra
            name="workdeal"
            options={workDealOptions}
            label="Acordo de trabalho"
            width="100%"
          />
          <SelectChakra
            name="evaluation_period"
            options={evaluationPeriodOptions}
            label="Periodo Avaliativo"
            width="100%"
          />
          <Button mt={4} colorScheme="blue" onClick={onOpenGenerateEvaluations}>
            Gerar Avaliações
          </Button>
        </Stack>
        {/* </Flex> */}
      </Form>
      {/* Necessário obter o objeto do período avaliativo */}

      <AlertDialog
        title="Gerar avaliações de desempenho para sua empresa no acordo de trabalho atual"
        description="Antes de gerar as avaliações certifique-se que a estrutura dos departamentos já foi criada nos corretamente, com seus lideres e colaboradores"
        isOpen={isOpenGenerateEvaluations}
        leastDestructiveRef={cancelRefGenerateEvaluations}
        onClose={onCloseGenerateEvaluations}
        onSubmit={() => handleGenerateEvaluations()}
        isLoading={loadingGenerateEvaluations}
        VariantColor="blue"
        submitButtonColor="blue.500"
      />
    </Box>
  );
};

export default SettingsPerformanceReview;
