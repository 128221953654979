import styled from 'styled-components';

export const ActivityDevolutionData = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ul {
    list-style: none;
  }

  strong {
    font-size: 14px;
  }

  small {
    font-size: 14px;
    margin-left: 10px;
  }
`;
