import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  FiFileText,
  FiCalendar,
  FiUser,
  FiClock,
  FiLifeBuoy,
  FiBookOpen,
  FiFlag,
} from 'react-icons/fi';

import { Box, Flex, Skeleton, Tag, useToast } from '@chakra-ui/react';

import { ActivityProps as Activity } from '~/modules/activityExecution/@types/activity';

import { Container, Content, Description, Information } from './styles';

interface Props {
  activityDetails: Activity;
  isLoading?: boolean;
}

const ActivityAttendanceInfo: React.FC<Props> = ({
  activityDetails,
  isLoading = false,
}) => {
  const colorScheme = useMemo(() => {
    return {
      Pausado: 'yellow',
      Iniciado: 'blue',
      Finalizado: 'green',
      'Não Iniciado': 'gray',
    };
  }, []);

  return (
    <Container
      mb="60px"
      /*     backgroundImage={[
        'linear-gradient(to right, #f6f7ff00, #fbfbff)',
        'linear-gradient(190deg, #f6f7ff00, #fbfbff)',
      ]} */
      position="relative"
      pt="10px"
      pl={[0, '30px']}
    >
      {isLoading ? (
        <Box>
          <Skeleton height="27px" maxWidth="380px" mb={6} />

          {activityDetails.description && (
            <Box mb={4}>
              <Skeleton height="86px" maxWidth="488px" />
            </Box>
          )}

          <Flex mb={2}>
            <Skeleton height="20px" width="20px" mr="10px" />
            <Box>
              <Skeleton height="34px" w="300px" mb={1} />
            </Box>
          </Flex>
          <Flex mb={2}>
            <Skeleton height="20px" width="20px" mr="10px" />
            <Box>
              <Skeleton height="34px" w="300px" mb={1} />
            </Box>
          </Flex>
          <Flex mb={2}>
            <Skeleton height="20px" width="20px" mr="10px" />
            <Box>
              <Skeleton height="34px" w="300px" mb={1} />
            </Box>
          </Flex>
          <Flex mb={2}>
            <Skeleton height="20px" width="20px" mr="10px" />
            <Box>
              <Skeleton height="34px" w="300px" mb={1} />
            </Box>
          </Flex>
          <Flex mb={2}>
            <Skeleton height="20px" width="20px" mr="10px" />
            <Box>
              <Skeleton height="34px" w="300px" mb={1} />
            </Box>
          </Flex>
          {activityDetails.fatal_date && (
            <Flex mb={2}>
              <Skeleton height="20px" width="20px" mr="10px" />
              <Box>
                <Skeleton height="34px" w="300px" mb={1} />
              </Box>
            </Flex>
          )}
          {activityDetails.competence && (
            <Flex mb={2}>
              <Skeleton height="20px" width="20px" mr="10px" />
              <Box>
                <Skeleton height="34px" w="300px" mb={1} />
              </Box>
            </Flex>
          )}
        </Box>
      ) : (
        <>
          <Content>
            <Flex
              alignItems="center"
              justifyContent={['center', 'start']}
              flexDirection={['column', 'row']}
              mb={6}
            >
              <h3>
                <strong>{activityDetails.name}</strong>
              </h3>
              <Tag
                variant="outline"
                size="sm"
                ml={[0, 2]}
                mt={[2, 0]}
                colorScheme={colorScheme[activityDetails.situation]}
              >
                {activityDetails.situation}
              </Tag>
            </Flex>

            <Box mb={4}>
              {activityDetails.description && (
                <Description>
                  <strong>Observações:</strong>
                  <textarea
                    disabled
                    name="obs"
                    id="obsId"
                    cols={20}
                    rows={4}
                    defaultValue={activityDetails.description}
                  />
                </Description>
              )}
            </Box>

            <Box>
              {activityDetails.demand && (
                <Information>
                  <FiFileText size={20} />
                  <div>
                    <strong>{activityDetails.demand}</strong>
                    <small>Demanda</small>
                  </div>
                </Information>
              )}
              {activityDetails.company && (
                <Information>
                  <FiUser size={20} />
                  <div>
                    <strong>{activityDetails.company}</strong>
                    <small>Cliente</small>
                  </div>
                </Information>
              )}
              {activityDetails.formatted_estimated_time && (
                <Information>
                  <FiCalendar size={20} />
                  <div>
                    <strong>{activityDetails.formatted_estimated_time}</strong>
                    <small>Entrega prevista</small>
                  </div>
                </Information>
              )}
              {activityDetails.formatted_start_and_date_time && (
                <Information>
                  <FiClock size={20} />
                  <div>
                    <strong>
                      {activityDetails.formatted_start_and_date_time}
                    </strong>
                    <small>Horário de execução (planejada)</small>
                  </div>
                </Information>
              )}
              {activityDetails.formatted_duration && (
                <Information>
                  <FiLifeBuoy size={20} />
                  <div>
                    <strong>{activityDetails.formatted_duration}</strong>
                    <small>Tempo para execução</small>
                  </div>
                </Information>
              )}
              {activityDetails.fatal_date && (
                <Information>
                  <FiCalendar size={20} />
                  <div>
                    <strong>{activityDetails.fatal_date}</strong>
                    <small>Prazo máximo para entrega</small>
                  </div>
                </Information>
              )}
              {activityDetails.competence && (
                <Information>
                  <FiCalendar size={20} />
                  <div>
                    <strong>{activityDetails.competence}</strong>
                    <small>Competência</small>
                  </div>
                </Information>
              )}
            </Box>
          </Content>
        </>
      )}
    </Container>
  );
};

export default ActivityAttendanceInfo;
