import React, { ReactNode, useEffect } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { Button, Collapse, Flex, Tag, Text, useToast } from '@chakra-ui/react';
import filesize from 'filesize';

import { DropContainer, UploadMessage } from './styles';

interface UploadProps {
  onUpload: Function;
  showFormat: boolean;
  accept: string | string[];
}

const Upload: React.FC<UploadProps> = ({
  onUpload,
  showFormat = false,
  accept,
}: UploadProps) => {
  const maxFileSize = process.env.REACT_APP_UPLOAD_MAX_SIZE
    ? Number.parseInt(process.env.REACT_APP_UPLOAD_MAX_SIZE, 10)
    : undefined;
  const [show, setShow] = React.useState(false);

  const addToast = useToast();

  const handleToggle = (): void => setShow(!show);

  function handleOnDropReject(rejects: FileRejection[]): void {
    if (maxFileSize) {
      rejects.forEach((reject) => {
        reject.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            addToast({
              title: `O arquivo ${reject.file.name} é muito grande`,
              position: 'top-right',
              isClosable: true,
              status: 'warning',
              description: `O limite de tamanho para upload de arquivos é de ${filesize(
                maxFileSize,
                { base: 10 },
              )}`,
            });
          } else if (err.code === 'file-invalid-type') {
            addToast({
              title: `O tipo do arquivo ${reject.file.name} não é permitido`,
              position: 'top-right',
              isClosable: true,
              status: 'error',
            });
          }
        });
      });
    }
  }

  function renderDragMessage(
    isDragActive: boolean,
    isDragRejest: boolean,
  ): ReactNode {
    // console.log(isDragRejest);
    if (!isDragActive) {
      return (
        <UploadMessage>
          Selecione ou arraste o(s) arquivo(s) aqui.
        </UploadMessage>
      );
    }

    if (isDragRejest) {
      return (
        <UploadMessage type="error">
          Alguns arquivos não são suportados
        </UploadMessage>
      );
    }

    return (
      <UploadMessage type="success">Solte o(s) arquivo(s) aqui</UploadMessage>
    );
  }

  return (
    <>
      {showFormat && (
        <>
          <Collapse startingHeight={0} in={show}>
            <Flex flexDir="column">
              <Text w="120px" fontSize="12px">
                Formatos aceitos:
              </Text>
              <Flex flexWrap="wrap">
                <Tag size="sm" m={1}>
                  PDF
                </Tag>
                <Tag size="sm" m={1}>
                  JPG
                </Tag>
                <Tag size="sm" m={1}>
                  PNG
                </Tag>
                <Tag size="sm" m={1}>
                  GIF
                </Tag>
                <Tag size="sm" m={1}>
                  TXT
                </Tag>
                <Tag size="sm" m={1}>
                  CSV
                </Tag>
                <Tag size="sm" m={1}>
                  DOC
                </Tag>
                <Tag size="sm" m={1}>
                  XLS
                </Tag>
                <Tag size="sm" m={1}>
                  XLSX
                </Tag>
                <Tag size="sm" m={1}>
                  XLSM
                </Tag>
                <Tag size="sm" m={1}>
                  XLTM
                </Tag>
                <Tag size="sm" m={1}>
                  ZIP
                </Tag>
                <Tag size="sm" m={1}>
                  RAR
                </Tag>
              </Flex>
            </Flex>
          </Collapse>
          <Button
            leftIcon={show ? <FiEyeOff /> : <FiEye />}
            size="sm"
            onClick={handleToggle}
            mt={1}
            variant="ghost"
            colorScheme="teal"
            mb={1}
          >
            {show ? 'Ocultar' : 'Ver formatos permitidos'}
          </Button>
        </>
      )}
      <Dropzone
        accept={accept}
        onDropRejected={(rejectedFiles) => {
          /*     console.log('Rejeitados');
          console.log(rejectedFiles); */

          handleOnDropReject(rejectedFiles);
        }}
        onDropAccepted={(acceptedFiles) => {
          /*  console.log('Aceitos');
          console.log(acceptedFiles); */

          onUpload(acceptedFiles);
        }}
        maxSize={maxFileSize}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }): any => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} data-testid="upload" />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    </>
  );
};

export default Upload;
