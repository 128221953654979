import styled from 'styled-components';

interface DoughnutItemButtonProps {
  color: string;
}

interface StatusProps {
  color: string;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    margin-right: 5px;
    border-radius: 5px;
    background: #5f7d95;
    color: #fff;
    font-size: 13px;
    padding: 1px 3px;
    font-weight: 400;
  }

  a:hover strong {
    color: #40a9ff;
  }

  > strong,
  a strong {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #3d4a56;
    margin-bottom: 5px;
  }

  button {
    border: none;
    background: transparent;
    display: flex;
    align-items: flex-start;
    color: #3d4a56;
  }
`;

export const DoughnutItems = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

export const DoughnutItemButton = styled.button<DoughnutItemButtonProps>`
  background: transparent;
  position: relative;
  outline: none;
  text-align: left;
  margin-left: 15px;
  color: #7d7d7d;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;

  border: 0;

  display: flex;
  align-items: flex-start;

  &:hover {
    color: #169bd6;
  }

  &::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 2px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: ${(props) => props.color || '#000'};
  }

  &:disabled,
  &[disabled='disabled'] {
    cursor: default;
    color: #7d7d7d;
    text-decoration: line-through;
  }
`;

export const ContentModal = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 500px;
  overflow-y: auto;

  @media (max-width: 480px) {
    max-height: 400px;
  }
`;

export const CActivity = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerActivity = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  border-bottom: 1px solid #f8f9fe;
  padding: 0 0 5px 5px;
  margin-bottom: 20px;

  border-left: 1px solid #5f7d95;

  @media (max-width: 480px) {
    flex-direction: column;
    border-left: 0;
    padding: 0 0 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > span {
    color: #8694a0;
    font-size: 13px;
    line-height: 20px;

    display: flex;
    align-items: center;
    vertical-align: middle;

    &.spotlight {
      color: #169bd6;
      font-weight: 500;
      margin-top: 5px;

      small {
        font-size: 13px;
      }
    }

    strong {
      font-size: 13px;
    }

    small {
      margin-left: 5px;
      font-size: 13px;

      &.colorblue {
        color: #169bd6;
        font-weight: 500;
      }

      span {
        display: flex;
        align-items: center;
      }

      img {
        height: 20px;
        margin-left: 20px;
      }
    }
  }

  @media (max-width: 480px) {
    width: 100%;

    & + div {
      margin-top: 20px;
    }
  }
`;

export const TimeLeft = styled.div`
  display: flex;
  align-items: center;
  color: #8694a0;
  margin-right: 40px;

  img {
    height: 50px;
    margin-right: 5px;
  }

  div {
    display: flex;
    flex-direction: column;

    strong {
      color: #8694a0;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
    }

    small {
      color: #8694a9;
      font-size: 11px;
      line-height: 13px;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    margin: 0 0 20px;
  }
`;

export const ActivityStatus = styled.div<StatusProps>`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 0 5px 5px 5px;
  border: 1px solid #d5dee5;
  margin-top: 5px;
  width: 120px;

  span {
    display: flex;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    margin-right: 10px;
    background: ${(props) => props.color};
  }

  small {
    color: #8694a0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const BtnDate = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background: transparent;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;

  strong {
    font-size: 15px;
    color: #169bd6;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  > svg {
    color: #8694a0;
    margin-left: 8px;
  }

  &:active {
    transform: translateX(5px);
  }
`;
