import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { FiChevronDown, FiEye, FiXCircle, FiEyeOff } from 'react-icons/fi';

import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Tag,
  HStack,
  Text,
  RadioGroup,
  Radio,
  useToast,
  Heading,
  Tooltip,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';

import { Replace } from '~/helpers/Replace';
import { Department } from '~/modules/management/@types/management';
import {
  CardGraphicDepartment,
  DepartmentChart,
} from '~/shared/components/CardGraphicDepartment';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getOptionsDataLabel from '~/utils/getOptionsDataLabel';
import getValidationErrors from '~/utils/getValidationErrors';

import BadgeStatusInfo from '../../components/BadgeStatusInfo';
import { Card, ContainerCards } from './styles';

interface DepartmentTotalDelay extends DepartmentChart {
  total_delay: number;
  total_real_delay: number;
  total_open_activities: number;
  total_regular: number;
  total_real_regular: number;
}

interface DepartmentDelay extends DepartmentTotalDelay {
  subordinate_departments?: DepartmentTotalDelay[];
}

interface EmployeeDelay {
  id: number;
  nome: string;
  departamento_id: number;
  label: string;
  value: string;
}

interface TotalAct extends IPaginationProps {
  total_delay: number;
  real_delay: number;
  departments: DepartmentDelay[];
  employees: EmployeeDelay[];
  // delay_planning: PlanningDelay;
  total_general: number;
  total_regular: number;
  real_regular: number;
}

interface PlanningDelay extends IPaginationProps {
  data: Planning[];
}

interface Planning {
  planning_id: number;
  attendance_id: number;
  demand_id: number;
  competence: string;
  description: string;
  created_at: string;
  demand: string;
  company: string;
  user: string;
  activity: string;
  duration: string;
  start_date_planned: string;
  fatal_date: string;
  status: string;
  color: string;
}

export interface Demand {
  nome: string;
  id: string;
}

export interface QuantityStatusDemand {
  delayed: number;
  delayed_fatal_date: number;
  delay_forecast: number;
  running: number;
}

export interface Demands {
  demands: Demand[];
  total: QuantityStatusDemand;
}

export interface OptionDemand {
  label: string;
  value: string | number;
}
export interface DepartmentProps {
  data: Department[];
  labels: string[];
  dataLabels: number[];
  value: number[];
}

export interface DemandData {
  id: number;
  competencia?: string;
  descricao?: string;
  nome_empresa: string;
  nome_demanda: string;
  demanda_id: number;
  created_at: string;
}

interface AllDepartment {
  id: number;
  name: string;
  isManage: boolean;
  value: number;
  label: string;
}

interface AllDepartmentDelay extends AllDepartment {
  real_delay: boolean;
}

interface SelectedDepartmentDelay {
  id: number;
  employee_id?: number;
  isManage: boolean;
  real_delay: boolean;
}

const AnalyzeActivities: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const addToast = useToast();

  const [loaded, setLoaded] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingCleanFilter, setLoadingCleanFilter] = useState(false);
  const [loadingDelayPlannings, setLoadingDelayPlannings] = useState(false);
  const [hideEye, setHideEye] = useState(false);

  const [totalActivities, setTotalActivities] = useState<TotalAct>(
    {} as TotalAct,
  );

  const [delayPlannings, setDelayPlannings] = useState<PlanningDelay>();

  const [
    selectedDepartmentDelay,
    setSelectedDepartmentDelay,
  ] = useState<SelectedDepartmentDelay>();
  const [selectedDelay, setSelectedDelay] = useState('Atrasadas Gerais');

  // Coleciona as gerencias e equipes no mesmo nível
  const [allDepartments, setAllDepartments] = useState<AllDepartmentDelay[]>(
    [],
  );

  const [allEmployeesDelay, setAllEmployeesDelay] = useState<EmployeeDelay[]>(
    [],
  );

  const [selectEmployeesDelay, setSelectEmployeesDelay] = useState<
    EmployeeDelay[]
  >([]);

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Demanda',
      },
      {
        title: 'Empresa',
      },
      {
        title: 'Competência',
      },
      {
        title: 'Atividade',
      },
      {
        title: 'Executante',
      },
      {
        title: 'Status Atividade',
        align: 'center',
      },
      {
        title: 'Ações',
      },
    ];
  }, []);

  const demandChartLabels = useMemo(() => {
    return {
      labels: ['Atrasadas Gerais', 'No Prazo', 'Atrasadas Reais', 'Outras'],
      dataLabels: ['delayed', 'regular', 'delayed_real', 'other'],
    };
  }, []);

  const loadActivitiesDelay = useCallback(() => {
    setLoaded(false);
    setTotalActivities({} as TotalAct);

    api
      .get<TotalAct>('/analytic/activities/delay', {
        params: {
          list_departments: 1,
          list_employees: 1,
        },
      })
      .then((response) => {
        const {
          departments: departmentsDelay,
          employees: employeesDelay,
        } = response.data;

        const collectDepartments: AllDepartmentDelay[] = [];

        setTotalActivities({
          ...response.data,
          departments: departmentsDelay.map((dep) => {
            const dataLabels = demandChartLabels.dataLabels.map(
              (lab) => `${lab}-${dep.id}`,
            );

            collectDepartments.push({
              id: dep.id,
              name: dep.name,
              isManage: true,
              real_delay: false,
              label: dep.name,
              value: dep.id,
            });

            const totalActivitiesSum = dep.total_delay + dep.total_regular;
            const delayPercent = (dep.total_delay * 100) / totalActivitiesSum;

            const totalRealActivitiesSum =
              dep.total_real_delay + dep.total_real_regular;
            const delayRealPercent =
              (dep.total_real_delay * 100) / totalRealActivitiesSum;

            return {
              ...dep,
              center_top_value: delayPercent,
              center_lower_value: delayRealPercent,
              dataChart: {
                datasets: [
                  {
                    borderWidth: [5, 5],
                    datalabels: {
                      display: [false, false, false, false],
                    },
                    // borderColor: '#fed7d750',
                    data: [dep.total_delay, dep.total_regular, null, null],
                    // weight: 0.7,
                  },
                  {
                    borderWidth: [5, 5],
                    datalabels: {
                      display: [false, false, false, false],
                    },

                    // borderColor: '#fed7d750',
                    data: [
                      null,
                      null,
                      dep.total_real_delay,
                      dep.total_real_regular,
                    ],
                    // weight: 1,
                  },
                ],
                labels: demandChartLabels.labels,
                dataLabels,
                options: { legend: { display: false } },
                // options,
              },
              subordinate_departments: dep.subordinate_departments?.map(
                (depSub) => {
                  const dataLabelsSub = demandChartLabels.dataLabels.map(
                    (lab) => `${lab}-${depSub.id}`,
                  );

                  collectDepartments.push({
                    id: depSub.id,
                    name: depSub.name,
                    isManage: false,
                    real_delay: false,
                    label: depSub.name,
                    value: depSub.id,
                  });

                  const totalActivitiesSumSub =
                    depSub.total_delay + depSub.total_regular;
                  const delayPercentSub =
                    (depSub.total_delay * 100) / totalActivitiesSumSub;

                  const totalRealActivitiesSumSub =
                    depSub.total_real_delay + depSub.total_real_regular;
                  const delayRealPercentSub =
                    (depSub.total_real_delay * 100) / totalRealActivitiesSumSub;

                  return {
                    ...depSub,
                    center_top_value: delayPercentSub,
                    center_lower_value: delayRealPercentSub,
                    dataChart: {
                      datasets: [
                        {
                          borderWidth: [5, 5],
                          datalabels: {
                            display: [false, false, false, false],
                          },
                          // borderColor: ['transparent', 'transparent'],
                          data: [
                            depSub.total_delay,
                            depSub.total_regular,
                            null,
                            null,
                          ],

                          align: 'end',
                          // weight: 0.7,
                        },
                        {
                          borderWidth: [5, 5],
                          datalabels: {
                            display: [false, false, false, false],
                          },
                          // borderWidth: 5,
                          // borderColor: ['transparent', 'transparent'],
                          data: [
                            null,
                            null,
                            depSub.total_real_delay,
                            depSub.total_real_regular,
                          ],
                          align: 'end',
                          // weight: 1,
                        },
                      ],
                      labels: demandChartLabels.labels,
                      dataLabels: dataLabelsSub,
                      options: { legend: { display: false } },
                    },
                  };
                },
              ),
            };
          }),
        });

        setAllDepartments(collectDepartments.filter((dep) => dep.id !== 1));

        const employeeDelaySelect = employeesDelay.map((emp) => ({
          id: emp.id,
          nome: emp.nome,
          departamento_id: emp.departamento_id,
          label: emp.nome,
          value: emp.id.toString(),
        }));

        setAllEmployeesDelay(employeeDelaySelect);

        setSelectEmployeesDelay(employeeDelaySelect);

        // setTotalActivities();
      })
      .finally(() => setLoaded(true));
  }, [demandChartLabels]);

  const listDelayPlanningsDepartment = useCallback(
    async (
      numPage: number,
      realDelay: boolean,
      departmentId?: number,
      isManage?: boolean,
      employeeId?: number,
    ) => {
      setLoadingDelayPlannings(true);

      const routeBaseDelay = 'analytic/activities/delay/plannings';
      let data: PlanningDelay;

      try {
        const real = Number(realDelay);
        const manage = isManage ? Number(isManage) : 0;

        if (departmentId) {
          const { data: dados } = await api.get<PlanningDelay>(
            `${routeBaseDelay}/department/${departmentId}`,
            {
              params: {
                page: numPage,
                real,
                manage,
                employee_id: employeeId || null,
              },
            },
          );

          data = dados;
        } else {
          const { data: dados } = await api.get<PlanningDelay>(routeBaseDelay, {
            params: {
              page: numPage,
              real,
              manage,
              employee_id: employeeId || null,
            },
          });

          data = dados;
        }

        data.data = data.data.map((dd) => {
          return {
            ...dd,
            created_at: format(parseISO(dd.created_at), 'dd/MM/yyyy'),
          };
        });

        if (numPage === 1) {
          setDelayPlannings(data);
        } else {
          setDelayPlannings((state) => ({
            ...data,
            // Sobrescrevemos tudo de paginação, mas em data, mantemos o que tinha no estado e adicionamos os novos resultados
            data: [...(state?.data || []), ...data.data],
          }));
        }
      } finally {
        setLoadingDelayPlannings(false);
      }
    },
    [],
  );

  useEffect(() => {
    loadActivitiesDelay();
    listDelayPlanningsDepartment(1, false);
  }, [loadActivitiesDelay, listDelayPlanningsDepartment]);

  const handleSelectDepartment = useCallback(
    (departmentId: string) => {
      // console.log(departmentId);

      if (!departmentId) {
        setSelectEmployeesDelay(allEmployeesDelay);
        return;
      }

      setSelectEmployeesDelay(
        allEmployeesDelay.filter(
          (emp) => emp.departamento_id === Number(departmentId),
        ),
      );
    },
    [allEmployeesDelay],
  );

  const handleSelectDemand = useCallback(
    (value: string | number): void => {
      // console.log(value);
      const valueString = value.toString();
      const nameDelay = valueString.slice(0, valueString.indexOf('-'));

      if (nameDelay !== 'delayed' && nameDelay !== 'delayed_real') return;

      const delay = nameDelay === 'delayed_real';

      const depId = Number(valueString.substring(valueString.indexOf('-') + 1));

      formRef.current?.setData({
        delay: String(Number(delay)),
        department: depId,
      });

      const foundDepartment = allDepartments.find((dp) => dp.id === depId);

      setSelectedDepartmentDelay({
        id: depId,
        real_delay: delay,
        isManage: !!foundDepartment?.isManage,
      });

      // Ao filtrar a consulta através do gráfico listamos todos os funcionários novamente
      handleSelectDepartment(depId.toString());

      formRef.current?.clearField('employee_id');

      listDelayPlanningsDepartment(
        1,
        delay,
        foundDepartment?.id,
        foundDepartment?.isManage,
      );
    },
    [allDepartments, listDelayPlanningsDepartment, handleSelectDepartment],
  );

  const handleCleanFilter = useCallback(async () => {
    setLoadingCleanFilter(true);
    setLoadingDelayPlannings(true);
    try {
      formRef.current?.reset();
      // console.log(formRef.current?.getData());

      setSelectedDepartmentDelay(undefined);
      setSelectEmployeesDelay(allEmployeesDelay);
      await listDelayPlanningsDepartment(1, false);

      // formRef.current?.reset();
    } catch (err) {
      if (err.response?.status < 500) {
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          isClosable: true,
        });

        return;
      }

      addToast({
        position: 'top-right',
        status: 'error',
        title: 'Ooops! Ocorreu um erro no servidor.',
        isClosable: true,
      });
    }
    setLoadingCleanFilter(false);
    setLoadingDelayPlannings(false);
  }, [addToast, listDelayPlanningsDepartment, allEmployeesDelay]);

  const handleSubmit = useCallback(
    async (data: any) => {
      // formRef.current?.reset();
      // console.log(formRef.current?.getData());
      try {
        setLoadingSubmit(true);
        setLoadingDelayPlannings(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          delay: Yup.string().required('Necessário escolher uma opção'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const isManage = !!allDepartments.find(
          (dep) => dep.id === Number(data?.department),
        )?.isManage;

        setSelectedDepartmentDelay({
          id: data?.department,
          isManage,
          real_delay: data.delay === '1',
          employee_id: data?.employee_id,
        });

        await listDelayPlanningsDepartment(
          1,
          data.delay,
          data?.department,
          isManage,
          data?.employee_id,
        );

        // formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
            isClosable: true,
          });

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops! Ocorreu um erro no servidor.',
          isClosable: true,
        });
      }
      setLoadingSubmit(false);
      setLoadingDelayPlannings(false);
    },
    [addToast, listDelayPlanningsDepartment, allDepartments],
  );

  return (
    <Box position="relative">
      {!loaded && <LoadingAbsolute z_index={1111} min_height={500} />}
      <Flex justifyContent="space-between" flexDir={['column', 'row']}>
        <SectionHeader
          title="Gráfico de Atrasos"
          pagename="Visualize o percentual de atrasos por departamento em relação a quantidade total de atividades"
          goBackLink="/"
          goBackPage
        />
        <Button
          leftIcon={!hideEye ? <FiEye size="20px" /> : <FiEyeOff size="20px" />}
          aria-label="ocultar gráficos"
          onClick={() => setHideEye(!hideEye)}
          mb={[3, 0]}
          shadow="md"
        >
          Ocultar
        </Button>
      </Flex>

      {loaded && !hideEye && (
        <Flex justifyContent="center">
          <ContainerCards>
            {totalActivities &&
              !!totalActivities.departments?.length &&
              totalActivities.departments.map((dep) => {
                return dep.total_delay === 0 ? (
                  ''
                ) : (
                  <Card key={dep.id} mb={[5, 0]}>
                    <Box w="full" boxShadow="md">
                      <CardGraphicDepartment
                        avatar={dep.responsible?.avatar}
                        name={dep.responsible?.name}
                        department={dep}
                        enablePluginDataLabel
                        theme="delay"
                        selectChart={(value) => handleSelectDemand(value)}
                      />
                    </Box>
                  </Card>
                );
              })}
          </ContainerCards>
        </Flex>
      )}
      <Divider w="full" my={5} />
      {delayPlannings && (
        <>
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={{ delay: '0' }}
          >
            <Flex
              flexDir={{ base: 'column', lg: 'row' }}
              alignItems={{ base: 'start', lg: 'center' }}
              // justifyContent="space-between"
            >
              {/* {!!selectedDelay &&
                {
                  <Tag colorScheme="red" mb={2}>
                    <Badge variant="subtle" mr={2} bg="red.200" color="white">
                      Status:
                    </Badge>

                    <Text fontWeight={600}>{selectedDelay}</Text>
                  </Tag>
                }} */}

              <Flex flexDir="column" mr={4} minWidth="">
                <Heading
                  as="h5"
                  size="sm"
                  mb={1}
                  fontWeight={500}
                  cursor="default"
                  color="gray.500"
                  marginBottom={4}
                >
                  Atrasos
                </Heading>
                <RadioChakra
                  flexDir={['column', 'row']}
                  name="delay"
                  justifyContent="space-between"
                  options={[
                    {
                      label: 'Atrasadas Gerais',
                      value: '0',
                      colorScheme: 'red',
                    },
                    {
                      label: 'Atrasadas Reais',
                      value: '1',
                      colorScheme: 'red',
                    },
                  ]}
                  wrapOption="nowrap"
                />
              </Flex>
              <Flex
                width="full"
                flexDir={{ base: 'column', lg: 'row' }}
                alignItems="center"
              >
                <Box ml={{ base: 0, lg: 8 }} width="full">
                  <SelectChakra
                    options={allDepartments}
                    name="department"
                    label="Departamentos"
                    placeholder="Todos"
                    onChange={(el) =>
                      handleSelectDepartment(el.currentTarget.value)
                    }
                    width="full"
                    // minWidth="315px"
                  />
                </Box>
                <Box mx={{ base: 0, lg: 4 }} width="full">
                  <SelectChakra
                    options={selectEmployeesDelay}
                    name="employee_id"
                    label="Funcionários"
                    placeholder="Todos"
                    width="full"
                  />
                </Box>
              </Flex>
              <Flex
                // justifyContent="center"
                alignItems="center"
                flexDir={{ base: 'column', lg: 'row' }}
                width={{ base: 'full', lg: 'auto' }}
                mt={{ base: 3, lg: 0 }}
                mb={{ base: 3, lg: 0 }}
              >
                <Box width="full" minWidth="200px" mr={{ base: 0, lg: 3 }}>
                  <Button
                    onClick={() => formRef.current?.submitForm()}
                    colorScheme="green"
                    width="full"
                    // maxWidth={['full', '200px']}
                    isLoading={loadingSubmit}
                    isDisabled={loadingCleanFilter}
                  >
                    Filtrar
                  </Button>
                </Box>
                <Box mt={{ base: 3, lg: 0 }} width="full" minWidth="200px">
                  <Button
                    onClick={() => handleCleanFilter()}
                    colorScheme="gray"
                    width="full"
                    // maxWidth={['full', '200px']}
                    isLoading={loadingCleanFilter}
                    isDisabled={loadingSubmit}
                  >
                    Limpar Filtro
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </Form>

          <Divider w="full" />
          <Table
            loading={loadingDelayPlannings}
            isLoadingScroll
            theadData={tableTitles}
          >
            {!!delayPlannings.data.length &&
              delayPlannings.data.map((plan) => (
                <Box
                  p={2}
                  as="tr"
                  key={plan.planning_id}
                  _hover={{ bg: 'gray.50' }}
                >
                  <Tooltip
                    aria-label={plan.demand}
                    label={plan.demand}
                    zIndex={1}
                  >
                    <Text isTruncated maxW={250} p={2} as="td">
                      {plan.demand}
                    </Text>
                  </Tooltip>
                  <Box p={2} as="td">
                    <Tooltip
                      aria-label={plan.company}
                      label={plan.company}
                      zIndex={1}
                    >
                      <Text isTruncated maxW={250}>
                        {plan.company}
                      </Text>
                    </Tooltip>
                  </Box>
                  <Box p={2} as="td">
                    {plan.competence && <Tag>{plan.competence}</Tag>}
                  </Box>
                  <Tooltip
                    aria-label={plan.activity}
                    label={plan.activity}
                    zIndex={1}
                  >
                    <Text isTruncated maxW={150} p={2} as="td">
                      {plan.activity}
                    </Text>
                  </Tooltip>
                  <Tooltip aria-label={plan.user} label={plan.user} zIndex={1}>
                    <Text isTruncated maxW={150} p={2} as="td">
                      {plan.user}
                    </Text>
                  </Tooltip>

                  <Box p={2} as="td" textAlign="center">
                    <BadgeStatusInfo
                      status={plan.status}
                      statusColor={plan.color}
                    />
                  </Box>
                  <Box p={2} as="td" className="row" textAlign="end">
                    <HStack spacing={2} justify="center">
                      <a
                        href={`${process.env.REACT_APP_OLDMYIPAC}/planejamento/index/${plan.demand_id}?aten=${plan.attendance_id}&pg=1`}
                        target="__blank"
                      >
                        <IconButton
                          aria-label="Ver planejamento"
                          icon={<FiEye />}
                          variant="ghost"
                          bg="gray.100"
                          ml={{ base: 0, lg: 2 }}
                          colorScheme="gray"
                        />
                      </a>
                    </HStack>
                  </Box>
                </Box>
              ))}
          </Table>
          {!loadingSubmit &&
            !loadingCleanFilter &&
            delayPlannings.current_page < delayPlannings.last_page && (
              <Flex justifyContent="center" mt={3}>
                <Button
                  colorScheme="teal"
                  variant="ghost"
                  isDisabled={
                    loadingSubmit || loadingCleanFilter || loadingDelayPlannings
                  }
                  rightIcon={<FiChevronDown />}
                  onClick={() =>
                    listDelayPlanningsDepartment(
                      delayPlannings.current_page + 1,
                      !!selectedDepartmentDelay?.real_delay,
                      selectedDepartmentDelay?.id,
                      selectedDepartmentDelay?.isManage,
                      selectedDepartmentDelay?.employee_id,
                    )
                  }
                >
                  Carregar mais
                </Button>
              </Flex>
            )}
        </>
      )}
    </Box>
  );
};

export default AnalyzeActivities;
