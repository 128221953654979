import React from 'react';

import { Notification } from '~/modules/dashboard/@types/notification';

export default function getPerformanceReviewNotifications(): Notification[] {
  const notificationStr = localStorage.getItem('@MyIPAC:notification');

  if (!notificationStr) return [];

  const notifications: Notification[] = JSON.parse(notificationStr);

  return notifications.filter((noti) => noti.type === 'performance_review');
}
