import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { FiTrash, FiEdit, FiPlus, FiDownload } from 'react-icons/fi';

import {
  Box,
  Text,
  Flex,
  IconButton,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

import { PopFilePaginate } from '~/modules/management/pages/DemandActivities/DemandActivitiesList/ActivityCreate';
import AlertDialog from '~/shared/components/AlertDialog';
import Loading from '~/shared/components/Loading';
import Search from '~/shared/components/Search';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import download from '~/utils/downloadFile';

import { Container } from './styles';

const Files: React.FC = () => {
  const cancelDeleteRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loaded, setLoaded] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(1);

  const [fileDelete, setFileDelete] = useState(0);
  const [savedFile, setSavedFile] = useState<PopFilePaginate>(
    {} as PopFilePaginate,
  );

  const addToast = useToast();
  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Id',
      },
      {
        title: 'Nome',
      },
      {
        title: 'Tamanho',
      },
      {
        title: 'Salvo por',
      },
      {
        title: 'Salvo em',
      },
      {
        title: 'Atualizado em',
      },
      {
        title: 'Ações',
      },
    ];
  }, []);
  const getFiles = useCallback(
    async (numPage: number, searchFile?: string): Promise<() => void> => {
      setLoaded(false);
      if (searchFile) {
        setLoadingSearch(true);
      }

      const { CancelToken } = axios;
      const source = CancelToken.source();

      api
        .get<PopFilePaginate>('activity/file', {
          params: { page: numPage, search: searchFile },
          cancelToken: source.token,
        })
        .then((response) => {
          const filePage = response.data;
          setSavedFile({
            ...filePage,

            data: filePage.data.map((file) => {
              return {
                ...file,
                created_at: format(
                  parseISO(file.created_at),
                  "dd/MM/yyyy 'às' HH:mm",
                ),
                updated_at: format(
                  parseISO(file.updated_at),
                  "dd/MM/yyyy 'às' HH:mm",
                ),
              };
            }),
          });
          setPage(filePage.current_page);
          setSearch(searchFile || '');
        })
        .finally(() => {
          setLoadingSearch(false);
          setLoaded(true);
        });
      return () => {
        source.cancel('Operação cancelada pelo usuário');
      };
    },
    [],
  );

  useEffect(() => {
    getFiles(1);
  }, [getFiles]);

  /* useEffect(() => {
    setLoaded(false);

    if (search) {
      setLoadingSearch(true);
    }

    api
      .get('activity/file', {
        params: { page, search },
      })
      .then((response) => {
        const files = response.data;
        setSavedFile({
          ...files,
        });
      })
      .finally(() => {
        setLoaded(true);
        setLoadingSearch(false);
      });
  }, [page, search]); */

  const handleOpenModalDelete = useCallback(
    (id: number) => {
      setFileDelete(id);
      onOpen();
    },
    [onOpen],
  );

  const handleDelete = useCallback(() => {
    if (savedFile) {
      onClose();
    }

    setLoaded(false);
    api
      .delete(`activity/delete/${fileDelete}`)
      .then(() => {
        const filteredFile = savedFile.data.filter(
          (file) => file.id !== fileDelete,
        );
        if (!filteredFile.length) {
          getFiles(page - 1, search);
        }
        setSavedFile((state) => {
          return {
            ...state,
            data: filteredFile,
            total: state.total - 1,
            to: state.to - 1,
          };
        });
        addToast({
          position: 'top-right',
          title: 'Arquivo removido',
          description: 'O arquivo foi removido com sucesso',
          status: 'success',
          isClosable: true,
        });
      })
      .catch((err) => {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Arquivo não deletado!',
          description: 'Erro ao deletar o arquivo',
        });
      })
      .finally(() => {
        setLoaded(true);
      });
  }, [onClose, savedFile, fileDelete, addToast, page, getFiles, search]);

  return (
    <Box pos="relative">
      <Container>
        <SectionHeader title="Arquivos" pagename="Página de Arquivos" />
      </Container>

      <Search
        loading={loadingSearch}
        results={savedFile.total}
        searchValue={(value) => getFiles(1, value)}
        searchClear={() => getFiles(1, '')}
        tooltipPlacement="top-start"
        subject="arquivo"
      />

      {!loaded && (
        <Box top={0} left={0} w="full" h="500px" bg="rgba(255,255,255,0.5)">
          <Loading />
        </Box>
      )}

      {savedFile && !!savedFile.data?.length && loaded && (
        <Table
          theadData={tableTitles}
          pagination={{
            current_page: savedFile.current_page,
            last_page: savedFile.last_page,
            per_page: savedFile.per_page,
            to: savedFile.to,
            total: savedFile.total,
          }}
          newPage={(pg) => getFiles(pg, search)}
        >
          {loaded &&
            savedFile.data.map((files) => (
              <Box as="tr" key={String(files.id)} _hover={{ bg: 'gray.50' }}>
                <Box as="td" py={1}>
                  {files.id}
                </Box>
                <Box as="td" py={1}>
                  {files.nome_original}
                </Box>
                <Box as="td" py={1}>
                  {files.tamanho}
                </Box>
                <Box as="td" py={1}>
                  {files.usuario}
                </Box>
                <Box as="td" py={1} minW="160px">
                  {files.created_at}
                </Box>
                <Box as="td" py={1} minW="160px">
                  {files.updated_at}
                </Box>
                <Box as="td" py={1} className="row">
                  <Flex justifyContent="flex-end">
                    <IconButton
                      aria-label="Download"
                      icon={<FiDownload />}
                      variant="ghost"
                      bg="blue.50"
                      ml={[1, 3]}
                      colorScheme="blue"
                      onClick={() =>
                        download(
                          document.body,
                          files.dir,
                          files.nome_original,
                          files.mimetype,
                        )
                      }
                    />
                    <IconButton
                      aria-label="Deletar"
                      icon={<FiTrash />}
                      variant="ghost"
                      bg="red.50"
                      ml={[1, 2]}
                      colorScheme="red"
                      onClick={() => handleOpenModalDelete(files.id)}
                    />
                  </Flex>
                </Box>
              </Box>
            ))}
        </Table>
      )}

      <AlertDialog
        title="Deletar Arquivo?"
        description="Tem certeza que deseja deletar o Arquivo selecionado"
        isOpen={isOpen}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onClose}
        onSubmit={handleDelete}
      />
    </Box>
  );
};

export default Files;
