import React from 'react';

import { Box, Heading } from '@chakra-ui/react';

import {
  IDemand,
  FilterData,
  ModalFilter,
} from '../../pages/IndividualPerformance';
import { TrBox } from '../../pages/IndividualPerformance/styles';
import TableItemIndividualPerformance from '../TableItemIndividualPerformance';

interface Props {
  demand: IDemand;
  showTime?: boolean;
  onOpen(data: ModalFilter): void;
  comparativeEmployee?: string;
  filter: FilterData;
}

const TableRowIndividualPerformance: React.FC<Props> = ({
  demand,
  showTime = true,
  onOpen,
  comparativeEmployee,
  filter,
}) => {
  return (
    <>
      <Box
        as="table"
        w="full"
        style={{
          borderSpacing: 0,
          borderCollapse: 'collapse',
          border: '1px solid transparent',
        }}
      >
        <Box as="tbody" w="full" p={4} fontSize="md">
          <TrBox as="tr">
            <td colSpan={4}>
              <Heading fontSize="1rem" py={3} isTruncated>
                {demand.name}
              </Heading>
            </td>
          </TrBox>
        </Box>
      </Box>

      {demand.activities?.length &&
        demand.activities.map((activity) => {
          return (
            <TableItemIndividualPerformance
              key={`${activity.activity_id}999${activity.demand_id}`}
              activity={activity}
              showTime={showTime}
              onOpenModal={(data) => onOpen(data)}
              comparativeEmployee={comparativeEmployee}
              filter={filter}
            />
          );
        })}
    </>
  );
};

export default TableRowIndividualPerformance;
