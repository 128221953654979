export default {
  title: 'dark',

  colors: {
    primary: '#323C79',
    info: '#169bd6',
    secundary: '#D5DEE5',

    background: '#2f2e38',
    shape_primary: '#ffffff',
    background_gradient:
      'linear-gradient(90deg, #050059 0%, #090979 28.13%, rgba(17, 27, 145, 0.98) 100%),#ffffff',
    title: '#FFF',
    text: '#f4ede8',
    disabled: '#cecece',
    link: '#f4ede8',
    link_light: '#fff',

    menu_link: '#2F2E38',
    menu_sublink: '#8694A0',
    menu_title: '#081132',

    shape_input: '#f4f5f75e',
    shape_border_input: '#f4f5f7fa',
    placeholder_input: '#666360',
    text_input: '#f4ede8',

    table_title: '#8694A0',
    table_text: '#8694A0',
    table_text_hover: '#2F2E38',

    shape_row: '#f4f5f7fa',

    danger: '#E24A4A',
    success: '#09CC1D',
    notification: '#76A1DF',
    warning: '#FFA11A',
  },

  fonts: {},
};
