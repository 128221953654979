import styled, { css } from 'styled-components';

interface ButtonProps {
  loading?: number;
  typeColor:
    | 'success'
    | 'danger'
    | 'info'
    | 'secundary'
    | 'primary'
    | 'notification'
    | 'warning';
}

export const BtnContainer = styled.button<ButtonProps>`
  background: transparent;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 0;
  color: ${({ theme, typeColor }) =>
    theme.colors[typeColor] || theme.colors.info};
  font-weight: 500;
  transition: all 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #fff;
    background: ${({ theme, typeColor }) =>
      theme.colors[typeColor] || theme.colors.info};

    svg {
      color: #fff;
    }
  }

  &:active {
    transform: scale(0.9);
  }

  ${(props) =>
    props.loading &&
    css`
      opacity: 0.6;
      cursor: initial;
    `}

  ${(props) =>
    !props.loading &&
    css`
      &:hover {
        background: ${props.theme.colors[props.typeColor] ||
        props.theme.colors.info};
        color: #fff;
      }
    `}
`;
