import React from 'react';

import { Box, Tooltip, Button } from '@chakra-ui/react';

import useLinkStatusAttendance from '~/hooks/useLinkStatusAttendance';

import BadgeStatusAttendance, {
  Props as PropsBadge,
} from '../BadgeStatusAttendance';
import { ButtonBadge } from './styles';

interface Props extends PropsBadge {
  demand_id: number;
}

const LinkBadgeStatusAttendance: React.FC<Props> = ({
  attendance,
  demand_id,
  showCodeAttendance = false,
  showIconExternalLink = true,
  onlyIcon = false,
}) => {
  const linkStatus = useLinkStatusAttendance();

  return attendance.status_id !== 13 ? (
    <>
      {onlyIcon && (
        <Button
          size="sm"
          background="none"
          m={1}
          onClick={() =>
            linkStatus(demand_id, attendance.attendance_id, attendance.archived)
          }
          className="attendance-link"
          style={{ display: 'inline-block', whiteSpace: 'nowrap', zIndex: 9 }}
        >
          <BadgeStatusAttendance
            attendance={attendance}
            showCodeAttendance={showCodeAttendance}
            showIconExternalLink={showIconExternalLink}
            onlyIcon={onlyIcon}
          />
        </Button>
      )}

      {!onlyIcon && (
        <>
          <ButtonBadge
            _hover={{
              background: 'none',
              boxShadow: 'none',
            }}
            _focus={{ boxShadow: 'none' }}
            height="auto"
            borderRadius="10px"
            background="none"
            m={1}
            p={0}
            onClick={() =>
              linkStatus(
                demand_id,
                attendance.attendance_id,
                attendance.archived,
              )
            }
            className="attendance-link"
          >
            <BadgeStatusAttendance
              attendance={attendance}
              showCodeAttendance={showCodeAttendance}
              showIconExternalLink={showIconExternalLink}
            />
          </ButtonBadge>
        </>
      )}
    </>
  ) : (
    <Box className="attendance-link" cursor="default" whiteSpace="nowrap">
      <BadgeStatusAttendance
        attendance={attendance}
        showCodeAttendance={showCodeAttendance}
        onlyIcon={onlyIcon}
      />
    </Box>
  );
};

export default LinkBadgeStatusAttendance;
