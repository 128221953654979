import React from 'react';
// import ReactLoading from 'react-loading';

import logoAnimate from '~/assets/gif-logo-mini-transparent.gif';

import { Container } from './styles';

const Loading: React.FC = () => {
  return (
    <Container className="isloading">
      <div>
        <img
          src={logoAnimate}
          alt="gif-logo"
          style={{
            height: '100%',
            width: '150px',
            marginBottom: '8px',
            opacity: 0.65,
          }}
        />
        <strong>Carregando...</strong>
      </div>
    </Container>
  );
};

export default Loading;
