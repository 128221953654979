import { Button } from '@chakra-ui/react';
import styled from 'styled-components';

export const ButtonBadge = styled(Button)`
  svg.external-link {
    display: none !important;
  }

  &:hover svg {
    display: inline !important;
  }
`;
