import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  Fragment,
} from 'react';
import { FaSearch, FaEraser, FaBuilding, FaFileExcel } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { useLocation, useParams, Link } from 'react-router-dom';

import {
  Box,
  Text,
  Flex,
  IconButton,
  Tooltip,
  Select,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
  Heading,
  Button,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import queryString from 'query-string';

import Highlights from '~/shared/components/Highlights';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';

interface ConsumptionEmployee extends IPaginationProps {
  data: Data[];
}

interface Data {
  funcionario_id: number;
  employee_name: string;
  qtd_aten: number;
  real_allocation: string;
  available_time: string;
  real_allocation_sec: string;
  total_shifts_sec: string;
  employee_consumption: string;
  total_consumption_department: string;
  total_consumption_provider: string;
}

interface TotalConsumptionEmployee {
  real_allocation: string;
  avaliable_time: string;
  department_consumption: string;
  provider_consumption: string;
}

interface Departments {
  department_id: number;
  department_name: string;
}

interface Company {
  id: number;
  id_alterdata: number;
  cnpj: string;
  empresa_grupo_id: number;
  created_at: string;
  updated_at: string;
  name: string;
  company_name: string;
}

interface StructureConsumptionDepartment {
  consumption_employee: ConsumptionEmployee;
  total_consumption_department: TotalConsumptionEmployee;
  departments: Departments[];
  company: Company;
}

interface Params {
  empresaId?: string;
}

interface QueryParams {
  department_id?: number;
  start_date?: string;
  end_date?: string;
}

export const StructureConsumptionDepartment: React.FC = () => {
  const [loading, setLoading] = useState(true);
  // const [firstRender, setFirstRender] = useState(true);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const { empresaId } = useParams<Params>();
  const location = useLocation();
  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(queryParams?.start_date);
  const [endDate, setEndDate] = useState(queryParams?.end_date);
  const [filters, setFilters] = useState<QueryParams>({
    start_date: queryParams?.start_date,
    end_date: queryParams?.end_date,
  });

  const [selectedDepartmentId, setSelectedDepartmentId] = useState(
    queryParams.department_id,
  );

  const goLink = useMemo(() => {
    return `/relatorios/consumo-estrutura${location.search}`;
  }, [location]);

  const [companies, setCompanies] = useState<Company>();
  const [departments, setDepartments] = useState<Departments[]>([]);
  const [
    totalConsumptionEmployee,
    setTotalConsumptionEmployee,
  ] = useState<TotalConsumptionEmployee>();
  const [
    consumptionEmployee,
    setConsumptionEmployee,
  ] = useState<ConsumptionEmployee>();

  /* const [selectedDep, setSelectedDep] = useState(
    queryParams?.dep ? queryParams?.dep : '',
  );
  const [selectedEmp, setSelectedEmp] = useState(
    queryParams?.emp ? queryParams?.emp : '',
  );
  const [selectedDataInicio, setSelectedDataInicio] = useState(
    queryParams?.dt_inicio ? queryParams?.dt_inicio : '',
  );
  const [selectedDataFim, setSelectedDataFim] = useState(
    queryParams?.dt_fim ? queryParams?.dt_fim : '',
  ); */

  const tableTitle = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Funcionário',
      },
      {
        title: 'Alocação Real (Horas)',
      },
      {
        title: 'Tempo Disponível (Horas)',
      },
      {
        title: 'Consumo (Individual)',
      },
      {
        title: 'Consumo (Departamento)',
      },
      {
        title: 'Consumo (Fornecedor)',
      },
    ];
  }, []);

  useEffect(() => {
    /* if (firstRender) { */
    setLoading(true);
    setLoadingFilters(true);
    /* } else {
      setLoading(true);
     } */

    api
      .get<StructureConsumptionDepartment>(
        `/reports/structure-consumption/companies/${empresaId}`,
        {
          params: {
            page,
            department_id: selectedDepartmentId || queryParams?.department_id,
            start_date: filters?.start_date,
            end_date: filters?.end_date,
          },
        },
      )

      .then((response) => {
        // console.log(response.data);
        const {
          total_consumption_department,
          consumption_employee,
          departments: dep,
          company,
        } = response.data;

        setTotalConsumptionEmployee(total_consumption_department);
        setConsumptionEmployee(consumption_employee);

        setDepartments(dep);
        setCompanies(company);

        setSelectedDepartmentId(
          selectedDepartmentId || queryParams?.department_id,
        );
      })

      .finally(() => {
        setLoading(false);
        setLoadingFilters(false);
      });
  }, [page, empresaId, filters, queryParams, selectedDepartmentId]);

  const handleNewPage = useCallback((pageNew: number) => {
    setPage(pageNew);
  }, []);

  const handleSubmit = useCallback((data: QueryParams) => {
    setFilters({
      start_date: data.start_date,
      end_date: data.end_date,
    });
    setSelectedDepartmentId(data.department_id);
    setPage(1);
  }, []);

  return (
    <Box pos="relative">
      {/* {loading && <LoadingAbsolute z_index={1111} min_height={500} />} */}

      <SectionHeader
        title="Consumo de Estrutura - Funcionários"
        pagename="Visualizar o percentual de consumo de estrutura com base no tempo disponível dos funcionários e a hora gasta na realização das demandas das empresas clientes"
        goBackLink={goLink}
      />

      <Breadcrumb display="flex" justifyContent="left" mb="30px">
        <BreadcrumbItem color="#989898">
          <BreadcrumbLink
            href={`/relatorios/consumo-estrutura/?start_date=${filters.start_date}&end_date=${filters.end_date}`}
          >
            Consumo de Estrutura - Empresas
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem color="#989898">
          <Text>Consumo de Estrutura - Funcionários</Text>
        </BreadcrumbItem>
      </Breadcrumb>
      {/* {!loading ? (
        <Skeleton width="240px" height="36px"  />
      ) : ( */}

      <Highlights
        data={[
          {
            icon: FaBuilding,
            label: 'Empresa',
            text: loadingFilters ? 'Carregando...' : companies?.company_name,
          },
        ]}
      />

      {/* )} */}

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          start_date: filters?.start_date,
          end_date: filters?.end_date,
          /* department_id: queryParams.department_id, */
        }}
      >
        <Flex alignItems="center" justifyContent="center" mb="16px">
          {loadingFilters ? (
            <Box flex={1} mr="30px">
              <Heading
                as="h5"
                size="sm"
                fontWeight={500}
                mb={1}
                cursor="default"
                color="gray.500"
              >
                Departamento
              </Heading>
              <Skeleton height="48px" />
            </Box>
          ) : (
            <>
              <SelectChakra
                background="#e9ecef"
                borderColor=""
                name="department_id"
                options={
                  departments?.length
                    ? departments?.map((dp) => {
                        return {
                          label: dp.department_name,
                          value: dp.department_id.toString(),
                        };
                      })
                    : []
                }
                // placeholder={loadingFilters ? 'Carregando...' : ''}
                defaultValue={selectedDepartmentId}
                mb="0"
                mr="30px"
                label="Departamento"
                disabled={loadingFilters}
              />
              {/* <Box width="full" height="48px" bg="#000" /> */}
            </>
          )}

          {loadingFilters ? (
            <Box flex={1} mr="8px">
              <Heading
                as="h5"
                size="sm"
                fontWeight={500}
                mb={1}
                cursor="default"
                color="gray.500"
              >
                Período início
              </Heading>
              <Skeleton height="48px" />
            </Box>
          ) : (
            <InputChakra
              background="#e9ecef"
              borderColor=""
              name="start_date"
              width="full"
              defaultValue={filters?.start_date}
              type="month"
              mb="0px"
              label="Período início"
            />
          )}

          {loadingFilters ? (
            <Skeleton height="40px" marginTop="20px" marginRight="38px" />
          ) : (
            <IconButton
              aria-label="Limpar Início"
              icon={<FiX />}
              variant="ghost"
              colorScheme="red"
              mr="30px"
              mt="15px"
              onClick={() => formRef.current?.clearField('start_date')}
            />
          )}

          {loadingFilters ? (
            <Box flex={1} mr="8px">
              <Heading
                as="h5"
                size="sm"
                fontWeight={500}
                mb={1}
                cursor="default"
                color="gray.500"
              >
                Período Fim
              </Heading>
              <Skeleton height="48px" />
            </Box>
          ) : (
            <InputChakra
              background="#e9ecef"
              borderColor=""
              name="end_date"
              width="full"
              defaultValue={filters?.end_date}
              type="month"
              mb="0px"
              label="Período Fim"
            />
          )}

          {loadingFilters ? (
            <Skeleton height="40px" marginTop="20px" marginRight="38px" />
          ) : (
            <IconButton
              aria-label="Limpar Fim"
              icon={<FiX />}
              variant="ghost"
              colorScheme="red"
              mr="30px"
              mt="15px"
              onClick={() => formRef.current?.clearField('end_date')}
            />
          )}
        </Flex>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="right"
          mb="10px"
        >
          <Tooltip aria-label="Filtrar" label="Filtrar">
            <IconButton
              aria-label="Filtrar"
              icon={<FaSearch />}
              mr="20px"
              width="70px"
              onClick={() => formRef.current?.submitForm()}
              isLoading={loadingFilters}
            />
          </Tooltip>

          <Tooltip aria-label="Limpar Filtro" label="Limpar Filtro">
            <IconButton
              aria-label="Limpar Filtro"
              icon={<FaEraser />}
              width="70px"
              mr="30px"
              onClick={() => formRef.current?.reset()}
            />
          </Tooltip>
        </Box>
      </Form>

      {!loading && (
        <>
          {consumptionEmployee?.data.length ? (
            <>
              <Table
                theadData={tableTitle}
                pagination={
                  consumptionEmployee
                    ? {
                        current_page: consumptionEmployee.current_page,
                        last_page: consumptionEmployee.last_page,
                        per_page: consumptionEmployee.per_page,
                        to: consumptionEmployee.to,
                        total: consumptionEmployee.total,
                      }
                    : undefined
                }
                loading={loading}
                newPage={(pg) => handleNewPage(pg)}
              >
                <Box as="tr">
                  <Box as="td">
                    <Text color="#2e8b57" as="b">
                      TOTAL
                    </Text>
                  </Box>
                  <Box as="td" p="10px">
                    <Text color="#2e8b57" as="b">
                      {totalConsumptionEmployee?.real_allocation}
                    </Text>
                  </Box>
                  <Box as="td" p="10px">
                    <Text color="#2e8b57" as="b">
                      {totalConsumptionEmployee?.avaliable_time}
                    </Text>
                  </Box>
                  <Box as="td" p="10px">
                    <Text color="#2e8b57" as="b">
                      --
                    </Text>
                  </Box>
                  <Box as="td" p="10px">
                    <Text color="#2e8b57" as="b">
                      {`${totalConsumptionEmployee?.department_consumption}%`}
                    </Text>
                  </Box>
                  <Box as="td" p="10px">
                    <Text color="#2e8b57" as="b">
                      {`${totalConsumptionEmployee?.provider_consumption}%`}
                    </Text>
                  </Box>
                </Box>

                {!!consumptionEmployee?.data?.length &&
                  consumptionEmployee.data.map((func) => {
                    return (
                      <Fragment key={func.funcionario_id}>
                        <Box as="tr">
                          <Box as="td" p="10px">
                            {Number(func.real_allocation_sec) >= 30 ? (
                              // <Tooltip label="Clique para verificar as demandas realizadas pelo funcionário">
                              <Link
                                to={`/relatorios/consumo-estrutura/funcionarios/${func.funcionario_id}?company_id=${empresaId}&department_id=${selectedDepartmentId}&start_date=${filters.start_date}&end_date=${filters.end_date}`}
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                }}
                              >
                                <Text>{`${func.employee_name}`}</Text>
                              </Link>
                            ) : (
                              // </Tooltip>
                              <Text>{`${func.employee_name}`}</Text>
                            )}
                          </Box>
                          <Box as="td" p="10px">
                            <Text>{`${func.real_allocation}`}</Text>
                          </Box>
                          <Box as="td" p="10px">
                            <Text>{`${func.available_time}`}</Text>
                          </Box>
                          <Box as="td" p="10px">
                            <Text>{`${func.employee_consumption}%`}</Text>
                          </Box>
                          <Box as="td" p="10px">
                            <Text>{`${func.total_consumption_department}%`}</Text>
                          </Box>
                          <Box as="td" p="10px">
                            <Text>{`${func.total_consumption_provider}%`}</Text>
                          </Box>
                        </Box>
                      </Fragment>
                    );
                  })}
              </Table>
            </>
          ) : (
            <Box
              fontSize="1.2rem"
              fontWeight="400"
              textAlign="center"
              marginTop="150px"
            >
              <Text color="#818182">
                Nenhum dado foi encontrado, verifique os filtros e tente
                novamente
              </Text>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
export default StructureConsumptionDepartment;
