import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';

import { NoteNotification } from '~/modules/dashboard/@types/notification';
import api from '~/shared/services/api';

interface NotificationContextData {
  notifications: NoteNotification[];
  getNotifications(userId: number): Promise<void>;
  removeNotifications(): void;
}

const NotificationContext = createContext<NotificationContextData>(
  {} as NotificationContextData,
);

const NotificationProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<NoteNotification[]>(() => {
    const noti = localStorage.getItem('@MyIPAC:notification');

    // console.log(noti);

    return noti ? JSON.parse(noti) : [];
  });

  const getNotifications = useCallback(async (userId: number) => {
    const notificationResp = await api.get<NoteNotification[]>(
      `/notifications/users/${userId}`,
    );

    // console.log(notificationResp);

    const { data: notificationData } = notificationResp;

    localStorage.setItem(
      '@MyIPAC:notification',
      JSON.stringify(notificationData),
    );

    setNotifications(notificationData);
  }, []);

  const removeNotifications = useCallback(() => {
    localStorage.removeItem('@MyIPAC:notification');
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        getNotifications,
        removeNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification(): NotificationContextData {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification must be used within an AuthProvider');
  }

  return context;
}

export { NotificationProvider, useNotification };
