/* eslint-disable no-alert */
import React, { useEffect, useMemo } from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  layout: React.ComponentType;
  permissions?: string | string[];
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  layout: Layout,
  permissions,
  path,
  ...rest
}) => {
  const { user } = useAuth();
  const can = useCan();

  /* useEffect(() => {
    console.log('Path: ', path);
    console.log(permissions, permissions ? can(permissions) : true);
  }, [path, permissions, can]); */

  const hasPermission = permissions ? can(permissions) : true;

  const homePage = can('dashboard') ? '/dashboard' : '/planejamento';

  const pathRedirect = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    // console.log(isPrivate ? `private ${user ? homePage : '/'}` : 'public /');
    return user ? homePage : '/';
  }, [homePage, user]);

  // const urlRedirect = useMemo(() => {
  //   if (search) {
  //     return new URLSearchParams(search).get('url');
  //   }
  //   return null;
  // }, [search]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user && hasPermission ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: pathRedirect,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
