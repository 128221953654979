import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

// import { format } from 'date-fns';
import Pusher from 'pusher-js';

import { useAuth } from './auth';
import { useTimer } from './timer';

interface RealTimeNotificationContextData {
  pusherInstance?: Pusher;
  existentConnection?: boolean;
}

const RealTimeNotificationContext = createContext<RealTimeNotificationContextData>(
  {} as RealTimeNotificationContextData,
);

const RealTimeNotificationProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const { notify } = useTimer();

  /* Dessa forma, ao alterar os dados do usuário logado, e consequentemente alterar o estado user, não será
  renderizado este componente novamente */

  const userId = user?.id ?? null;
  const companyId = user?.company_id ?? null;

  const [pusherInstance, setPusherInstance] = useState<Pusher>();

  function verifyExistentConnection(): boolean {
    const storagePusherConnected = localStorage.getItem('pusherConnected');

    return storagePusherConnected === 'true';
  }

  const [existentConnection, setExistentConnection] = useState(
    verifyExistentConnection(),
  );

  const pusherRef = useRef<Pusher>();

  useEffect(() => {
    // Só realizará a conexão se não houver uma conexão existente
    function connectPusher(): void {
      if (!userId || verifyExistentConnection()) return;

      pusherRef.current = new Pusher(process.env.REACT_APP_PUSHER_ENV || '', {
        cluster: 'sa1',
        // encrypted: true,
        authEndpoint: `${process.env.REACT_APP_API_HOST}/broadcasting/auth`, // Endpoint para autenticação do canal privado
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@MyIPAC:token')}`,
          },
        },
      });

      localStorage.setItem('pusherConnected', 'true');
      setPusherInstance(pusherRef.current);
      setExistentConnection(true);

      const channelUser = `private-user-channel.${userId}-${companyId}`;

      const channel = pusherRef.current.subscribe(channelUser);

      channel.bind('test-reminder', (msg: string) => {
        if (Notification.permission === 'granted' && !!msg) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const notification = new Notification('MyIPAC', {
            body: msg,
            icon: '/favicon.svg',
          });
        }
      });

      channel.bind('activity-reminder', (data: any) => {
        // Exibe a notificação
        if (Notification.permission === 'granted' && !!data?.message) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const notification = new Notification('MyIPAC', {
            body: data.message,
            icon: '/favicon.svg',
          });

          /* console.log(
            `Alerta recebido em:${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
          ); */

          /* notification.onclick = (ev) => {
            window.open('/planejamento');
          }; */

          // timer.tsx
          notify('activity-reminder', data.message);
        }
      });

      channel.bind('paused-activity', (data: any) => {
        // Exibe a notificação
        if (data?.message) {
          // timer.tsx
          notify('paused-activity', data.message);

          // console.log('teeeeste pause');
        }
      });

      // Executa quando recarrega a página ou sai através de um redirecionamento sem __blank
      window.addEventListener('beforeunload', () => {
        // console.log('saindo...');
        localStorage.removeItem('pusherConnected');

        // Timestamp da data e era do encerramento da conexão em ms
        localStorage.setItem('pusherClosed', Date.now().toString());

        setPusherInstance(undefined);
        setExistentConnection(false);
        pusherRef.current?.disconnect();
      });
    }

    connectPusher();

    function handleStorageChange(event: any): void {
      if (event.key === 'pusherClosed') {
        connectPusher();
      }
    }

    // Houve alterações no localstorage
    window.addEventListener('storage', handleStorageChange);

    /* Baseado em contexto, não podemos cancelar a assinatura a um canal, pois ao trocar de página por exemplo, esse componente
    executa a função de saída do useEffect e não é renderizado novamente, porém, o pusherInstance é mantido */

    return () => {
      // channel.unbind_all();
      // channel.unsubscribe();
      // alert('pusher removed');

      // Apenas executa se ocorrer o logout da aplicação
      window.removeEventListener('storage', handleStorageChange);
      localStorage.removeItem('pusherConnected');
      localStorage.setItem('pusherClosed', Date.now().toString());
      pusherRef.current?.disconnect();
    };
  }, [userId, companyId, notify]);

  return (
    <RealTimeNotificationContext.Provider
      value={{
        pusherInstance,
        existentConnection,
      }}
    >
      {children}
    </RealTimeNotificationContext.Provider>
  );
};

function useRealTimeNotification(): RealTimeNotificationContextData {
  const context = useContext(RealTimeNotificationContext);

  if (!context) {
    throw new Error(
      'useRealTimeNotification must be used within a RealTimeNotificationProvider',
    );
  }

  return context;
}

export { RealTimeNotificationProvider, useRealTimeNotification };
