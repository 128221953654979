import React, { useState, useEffect } from 'react';

import { format, parseISO } from 'date-fns';

import checklistImg from '~/assets/checklist.png';
import clockImg from '~/assets/clock.png';
import companyImg from '~/assets/company.png';
import { useAuth } from '~/hooks/auth';
import { ActivityProps } from '~/modules/activityExecution/@types/activity';
import api from '~/shared/services/api';

import {
  Container,
  Content,
  Header,
  ActivityContainer,
  Activity,
  ActivityHeader,
  ActivityContent,
} from './styles';

interface PlanningData {
  activities: ActivityProps[];
  companies: ActivityProps[];
}

const ActivityHistory: React.FC = () => {
  const { user } = useAuth();

  const [activities, setActivities] = useState<ActivityProps[]>([]);
  const [companies, setCompanies] = useState<ActivityProps[]>([]);

  useEffect(() => {
    api
      .get<PlanningData>(`/plannings/last-activity-end-company/${user.id}`)
      .then((response) => {
        const planning = response.data;

        setActivities(
          planning?.activities
            ?.filter((activity) => activity.situation !== 'Finalizado')
            .map((activity) => {
              return {
                ...activity,
                formatted_start_date: format(
                  parseISO(activity.start_date),
                  'dd/MM/yyyy',
                ),
                formatted_start_time: format(
                  parseISO(`${activity.start_date} ${activity.start_time}`),
                  'HH:mm',
                ),
                formatted_duration: format(
                  parseISO(`${activity.start_date} ${activity.duration}`),
                  "HH:mm'm'",
                ),
                blocked: !!activity.blocked,
              };
            }),
        );
        setCompanies(
          planning?.companies
            ?.filter((activity) => activity.situation !== 'Finalizado')
            .map((activity) => {
              return {
                ...activity,
                formatted_start_date: format(
                  parseISO(activity.start_date),
                  'dd/MM/yyyy',
                ),
                formatted_start_time: format(
                  parseISO(`${activity.start_date} ${activity.start_time}`),
                  'HH:mm',
                ),
                formatted_duration: format(
                  parseISO(`${activity.start_date} ${activity.duration}`),
                  "HH:mm'm'",
                ),
                blocked: !!activity.blocked,
              };
            }),
        );
      });
  }, [user.id]);

  // const { data: planning } = useFetch<PlanningData>(
  //   `/plannings/last-activity-end-company/${user.id}`,
  //   {
  //     company: user.company_id || 1,
  //   },
  // );

  // if (planning?.activities && planning?.companies) {
  //   setActivities(
  //     planning?.activities
  //       ?.filter((activity) => activity.situation !== 'Finalizado')
  //       .map((activity) => {
  //         return {
  //           ...activity,
  //           formatted_start_date: format(
  //             parseISO(activity.start_date),
  //             'dd/MM/yyyy',
  //           ),
  //           formatted_start_time: format(
  //             parseISO(`${activity.start_date} ${activity.start_time}`),
  //             'HH:mm',
  //           ),
  //           formatted_duration: format(
  //             parseISO(`${activity.start_date} ${activity.duration}`),
  //             "HH:mm'm'",
  //           ),
  //           blocked: !!activity.blocked,
  //         };
  //       }),
  //   );
  //   setCompanies(
  //     planning?.companies
  //       ?.filter((activity) => activity.situation !== 'Finalizado')
  //       .map((activity) => {
  //         return {
  //           ...activity,
  //           formatted_start_date: format(
  //             parseISO(activity.start_date),
  //             'dd/MM/yyyy',
  //           ),
  //           formatted_start_time: format(
  //             parseISO(`${activity.start_date} ${activity.start_time}`),
  //             'HH:mm',
  //           ),
  //           formatted_duration: format(
  //             parseISO(`${activity.start_date} ${activity.duration}`),
  //             "HH:mm'm'",
  //           ),
  //           blocked: !!activity.blocked,
  //         };
  //       }),
  //   );
  // }

  return (
    <Container>
      <h1>Histórico de atividades</h1>

      <Content>
        <Header>
          <img src={checklistImg} alt="checklist" />
          <strong>Ultimas atividades executadas</strong>
        </Header>

        <ActivityContainer>
          {activities?.length > 0 &&
            activities.map((activity) => (
              <Activity key={`activity-${activity.id}`}>
                <ActivityHeader>
                  <div>
                    <span>{activity.id}</span>
                    <h2>{activity.name}</h2>
                  </div>

                  <div>
                    <p>
                      <small>Solicitado dia:</small>
                      <small>{activity.formatted_start_date}</small>
                    </p>

                    <p>
                      <small>Empresa:</small>
                      <small>{activity.company}</small>
                    </p>
                  </div>
                </ActivityHeader>

                <ActivityContent inProgress={activity.situation_id === 15}>
                  <img src={clockImg} alt="Clock" />
                </ActivityContent>

                <ActivityContent inProgress={activity.situation_id === 15}>
                  <button type="button">
                    <span />
                    {activity.situation}
                  </button>
                </ActivityContent>
              </Activity>
            ))}
        </ActivityContainer>
      </Content>

      <Content>
        <Header>
          <img src={companyImg} alt="company" />
          <strong>Ultimas empresas atendidas</strong>
        </Header>

        <ul>
          {companies?.length > 0 &&
            companies.map((item) => (
              <li key={`company-${item.id}`}>
                <strong>{item.company}</strong>
                <small>{item.formatted_start_date}</small>
              </li>
            ))}
        </ul>
      </Content>
    </Container>
  );
};

export default ActivityHistory;
