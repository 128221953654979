import { theme, DefaultTheme } from '@chakra-ui/react';

const customTheme = {
  ...theme,
  fonts: {
    body: 'Nunito, Roboto, system-ui, sans-serif',
    heading: 'Nunito, Roboto, system-ui, sans-serif',
    mono: 'Nunito, Roboto, system-ui, sans-serif',
  },
  fontWeights: {
    ...theme.fontWeights,
    normal: 400,
    medium: 500,
    bold: 700,
  },
  radii: {
    ...theme.radii,
    sm: '4px',
    md: '5px',
  },
  fontSizes: {
    ...theme.fontSizes,
    '6xl': '54px',
  },
  colors: {
    ...theme.colors,
    blue: {
      ...theme.colors.blue,
      900: '#090979',
    },
    green: {
      ...theme.colors.green,
      500: '#1fbd2f',
    },
  },
};

export default customTheme;
