import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px #dfe2e8;
  border-radius: 10px;
  width: 100%;
  min-height: 300px;
  height: auto;
  overflow-x: auto;
  padding: 20px;

  display: flex;
  flex-direction: column;

  > h4 {
    padding: 10px 0;
    border-bottom: 1px solid #d5dee5;
    margin-bottom: 30px;
    color: #3d4a56;
  }
`;
