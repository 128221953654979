import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Box } from '@chakra-ui/react';

import SectionHeader from '~/shared/components/SectionHeader';

const EstructureDefaultShow: React.FC = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  if (!params.id) {
    history.push('/documentos/estrutura-padrao');
  }
  // console.log(params.id);
  return (
    <>
      <Box>
        <SectionHeader
          title="A&M CLINICA ODONTOLOGICA"
          pagename="Estrutura de pastas do google drive MyIPAC"
          goBackPage
        />
      </Box>
    </>
  );
};

export default EstructureDefaultShow;
