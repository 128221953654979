import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  height: 64px;
  padding: 0 20px 0 0;

  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    justify-content: space-between;
    padding: 0 20px 0 20px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 2;
    background: ${({ theme }) => theme.colors.background_gradient};
  }
`;

export const MenuAction = styled.button`
  display: none;
  visibility: hidden;

  @media (max-width: 767px) {
    width: 50px;
    margin: 5px 20px 5px 0;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: transparent;
    transition: all 0.2s;
    color: #fff;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }

    &:active {
      transform: scale(0.9);
    }

    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Logo = styled(Link)`
  width: 100%;
  max-width: 250px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 45px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;

  @media (max-width: 480px) {
    justify-content: flex-end;
  }
`;

export const LeftHeader = styled.div`
  display: flex;
  align-items: center;

  /* a {
    color: ${({ theme }) => theme.colors.link_light};
    font-size: 16px;
    padding: 10px 0;

    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  } */
`;

export const RightHeader = styled.div`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    margin-right: 2.5rem;
    padding: 0.5rem;
    background: ${({ theme }) => theme.colors.link_light};
    border-radius: 3px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  img {
    width: 100px;

    /* overflow-clip-margin: content-box;
    overflow: clip;
    vertical-align: middle;
    border-style: none; */
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.link_light};

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    @media (max-width: 780px) {
      padding: 10px;
      margin-left: 20px;
    }
  }

  @media (max-width: 780px) {
    a {
      width: 60px;
      margin-right: -20px;
      margin-top: 5px;
    }
  }
`;
