import { Box, Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const BoxFoldersList = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;

  &.show {
    right: -525px;
    -webkit-animation: slide-in 1s forwards;
    animation: slide-in 1s forwards;
  }

  &.hide {
    display: none;
  }

  @-webkit-keyframes slide-in {
    100% {
      right: 0;
    }
  }
  @keyframes slide-in {
    100% {
      right: 0;
    }
  }

  /*  @-webkit-keyframes slide-out {
    100% {
      left: -525px;
    }
  }
  @keyframes slide-out {
    100% {
      left: -525px;
    }
  } */
`;

export const FlexFolderItem = styled(Flex)`
  @media (max-width: 480px) {
    max-width: 255px;
  }
`;
