import React, { useCallback, useMemo, useState } from 'react';
import { FiArrowRight, FiCalendar } from 'react-icons/fi';

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Text,
  Box,
  Heading,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';

import clockImg from '~/assets/clock.png';
import { useAuth } from '~/hooks/auth';
import { useFetch } from '~/hooks/useFetch';
import { ActivityChart } from '~/modules/activityExecution/@types/activity';
import DoughnutChart from '~/modules/dashboard/components/Doughnut';
import CheckedPage from '~/shared/components/CheckedPage';

import {
  Container,
  Header,
  DoughnutItems,
  DoughnutItemButton,
  Content,
  CActivity,
  ContainerActivity,
  TimeLeft,
  ActivityStatus,
} from './styles';

interface ActivityDTO {
  late: ActivityChart[];
  late_fatal_date: ActivityChart[];
  outhers: ActivityChart[];
}

interface Props {
  selectDate(date: Date): void;
  user_id?: string;
  simpledisplay?: boolean;
}

const ChartActivity: React.FC<Props> = ({
  selectDate,
  user_id,
  simpledisplay = false,
}) => {
  const { user } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentActivities, setCurrentActivities] = useState<ActivityChart[]>(
    [],
  );

  const { data: situationActivities } = useFetch<ActivityDTO>(
    `plannings/situations/${user_id || user.id}`,
  );

  const totalLate = useMemo(() => {
    return situationActivities?.late?.length || 0;
  }, [situationActivities]);

  const totalLateFatalDate = useMemo(() => {
    return situationActivities?.late_fatal_date?.length || 0;
  }, [situationActivities]);

  const totalOuthers = useMemo(() => {
    return situationActivities?.outhers?.length || 0;
  }, [situationActivities]);

  const handleOpenModal = useCallback(
    (situation: string | number) => {
      switch (situation) {
        case 'late':
          setCurrentActivities(
            situationActivities?.late?.map((activity) => {
              return {
                ...activity,
                formattedDate: format(parseISO(activity.date), 'dd/MM/yyyy'),
                formattedHour: format(parseISO(activity.date), 'HH:mm'),
              };
            }) || [],
          );
          break;

        case 'lateFatalDate':
          setCurrentActivities(
            situationActivities?.late_fatal_date?.map((activity) => {
              return {
                ...activity,
                formattedDate: format(parseISO(activity.date), 'dd/MM/yyyy'),
                formattedHour: format(parseISO(activity.date), 'HH:mm'),
              };
            }) || [],
          );
          break;

        case 'outhers':
          setCurrentActivities(
            situationActivities?.outhers?.map((activity) => {
              return {
                ...activity,
                formattedDate: format(parseISO(activity.date), 'dd/MM/yyyy'),
                formattedHour: format(parseISO(activity.date), 'HH:mm'),
              };
            }) || [],
          );
          break;

        default:
          break;
      }

      onOpen();
    },
    [situationActivities, onOpen],
  );

  const handleSelectDate = useCallback(
    (date: string) => {
      selectDate(new Date(date));
      onClose();
    },
    [selectDate, onClose],
  );

  return !totalLate && !totalLateFatalDate && !totalOuthers ? (
    <CheckedPage title="Nenhuma atividade pendente." />
  ) : (
    <Box
      width="full"
      borderRadius="sm"
      marginBottom="30px"
      padding="20px"
      backgroundImage="linear-gradient(45deg, #f6f7ff, #fbfbff)"
    >
      <Heading as="h3" fontSize="md" mb={3} textAlign="center">
        Análise de atividades
      </Heading>

      <Container>
        <DoughnutChart
          data={{
            datasets: [{ data: [totalLate, totalLateFatalDate, totalOuthers] }],
            labels: ['Atrasadas', 'Atrasadas data fatal', 'Outras'],
            dataLabels: ['late', 'lateFatalDate', 'outhers'],
            options: { legend: { display: false } },
          }}
          colors={['#E20105', '#2b2b2b', '#09CC1D']}
          selectChart={(value) => handleOpenModal(value)}
        />

        <DoughnutItems>
          <DoughnutItemButton
            color="#E20105"
            type="button"
            onClick={() => handleOpenModal('late')}
            disabled={totalLate === 0}
          >
            Atrasadas
          </DoughnutItemButton>
          <DoughnutItemButton
            color="#2b2b2b"
            type="button"
            onClick={() => handleOpenModal('lateFatalDate')}
            disabled={totalLateFatalDate === 0}
          >
            Atrasadas data fatal
          </DoughnutItemButton>
          <DoughnutItemButton
            color="#09CC1D"
            type="button"
            onClick={() => handleOpenModal('outhers')}
            disabled={totalOuthers === 0}
          >
            Outras
          </DoughnutItemButton>
        </DoughnutItems>
      </Container>

      <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent borderRadius="sm">
          <ModalHeader>Atividades</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex direction="column" w="100%">
              {currentActivities?.map((activity) => (
                <CActivity key={activity.id}>
                  <Header>
                    <a
                      href={`${process.env.REACT_APP_OLDMYIPAC}/planejamento/index/${activity.demand_id}?aten=${activity.attendance_id}&pg=1`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>
                        <span>{activity.attendance_id}</span>
                        {activity.name}
                      </strong>
                    </a>
                  </Header>
                  <ContainerActivity>
                    <Content>
                      <Box as="span" w="100%">
                        <strong>Demanda:</strong>
                        <Text isTruncated width="100%" mr={2} ml={1}>
                          {activity.demand}
                        </Text>
                      </Box>
                      <span>
                        <strong>Empresa:</strong>
                        <small>{activity.company}</small>
                      </span>
                      <span>
                        <Flex>
                          <FiCalendar size={18} />
                          <strong
                            className="mobile-none"
                            style={{ marginLeft: '2px' }}
                          >
                            Planejamento:
                          </strong>
                        </Flex>

                        <small>
                          <span>
                            <small>{activity.formattedDate}</small>
                            <small>às</small>
                            <small className="colorblue">
                              {activity.formattedHour}
                            </small>
                          </span>
                        </small>
                        <small>
                          <span>
                            <img src={clockImg} alt="Clock" />
                            <small>{activity.duration}</small>
                          </span>
                        </small>
                      </span>
                    </Content>

                    <TimeLeft>
                      <ActivityStatus color="#D5DEE5">
                        <small>{activity.situation}</small>
                      </ActivityStatus>
                    </TimeLeft>

                    {!simpledisplay && (
                      <Content>
                        <Button
                          leftIcon={<FiCalendar />}
                          rightIcon={<FiArrowRight />}
                          variant="ghost"
                          colorScheme="blue"
                          mt={2}
                          onClick={() => handleSelectDate(activity.date)}
                        >
                          {activity.formattedDate}
                        </Button>
                      </Content>
                    )}
                  </ContainerActivity>
                </CActivity>
              ))}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ChartActivity;
