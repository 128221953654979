import ReactLoading from 'react-loading';

import { Flex, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const TextTitle = styled.h1`
  font-family: Nunito;
  font-weight: 700;
  font-size: 80px;
  text-align: center;
  color: #2d3748;
  @media (max-width: 768px) {
    font-size: 44px;
  }
`;
export const TextDesc = styled.p`
  font-family: Nunito;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  color: #2d3748;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

export const ImgWeb = styled.img`
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`;

export const ImgMob = styled.img`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const FlexBack = styled(Flex)``;

export const ReactLoad = styled(ReactLoading)`
  width: 70px !important;
  @media (min-width: 768px) {
    width: 150px !important;
  }
`;
