import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppProvider from './hooks';
import Routes from './routes';
import ScrollToTop from './shared/components/ScrollToTop';
import GlobalStyle from './styles/global';

const App: React.FC = () => {
  return (
    <AppProvider>
      <Router>
        <ScrollToTop />
        <Routes />
      </Router>
      <GlobalStyle />
    </AppProvider>
  );
};
export default App;
