import styled, { keyframes } from 'styled-components';

const transformAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface StatusProps {
  color: string;
}

export const ActivityInfoBox = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  div.info-finalizadas {
    strong,
    small {
      color: #3182ce;
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Container = styled.div`
  display: block !important;
  width: 100%;
  border: 1px solid #d5dee5;
  padding: 0.75rem;
  margin-bottom: 20px;
  border-radius: 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  /*
    &.spotlight {
      color: #169bd6;
      font-weight: 500;
      margin-top: 5px;

      small {
        font-size: 13px;
      }
    }
 */
  div strong {
    color: #8694a0;

    line-height: 20px;
    font-size: 13px;
  }

  div small {
    color: #8694a0;
    line-height: 20px;
    margin-left: 5px;
    font-size: 13px;
  }

  @media (max-width: 1100px) {
    width: 100%;

    & + div {
      margin-top: 20px;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.35rem;

  h3 {
    color: #3182ce;
    font-weight: 500;
    font-size: 17px;
    span {
      //border: 1px solid #1a202c;
      margin: 0 5px 0 0;
      font-size: 13px;
      font-weight: 600;
    }
  }

  button {
    background: transparent;
    border: 0;
    margin-left: 10px;
    padding: 4px;
    color: #8694a0;
    transition: all 0.2s;

    &.help {
      cursor: help;

      &:active {
        transform: scale(1);
      }
    }

    &:active {
      transform: scale(0.9);
    }

    &:hover {
      color: #3182ce;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const TimeLeft = styled.div`
  display: flex;
  flex-direction: column;
  color: #8694a0;

  img {
    height: 50px;
  }

  div.info-time {
    display: flex;
    flex-direction: column;

    strong {
      color: #8694a0;
      font-weight: bold;
      font-size: 18px;
    }

    p {
      color: #8694a9;
      font-size: 13px;
      margin: 0;
    }
  }

  @media (max-width: 1100px) {
    width: 100%;

    margin: 0 0 20px;
  }
`;

export const ActivityStatus = styled.div<StatusProps>`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  padding: 5px 10px;
  border-radius: 0 5px 5px 5px;
  border: 2px solid #d5dee5;

  span {
    display: flex;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    margin-right: 10px;
    background: ${(props) => props.color};
  }

  small {
    color: #8694a0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1100px) {
    justify-content: center;
  }
`;

export const AttendanceDescription = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 200px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  bottom: 20px;
  z-index: 2;

  animation: 0.2s ${transformAnimation};
  display: none;

  strong {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    font-size: 14px;
    background: #2f2e38;
    color: #fff;
    border-radius: 5px 5px 0 0;
    font-weight: 400;
  }

  small {
    padding: 10px;
    font-size: 2px 12px;
    text-align: left;
    color: #8694a9;
  }
`;
