import { Box, Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled.div``;

export const TrBorder = styled(Box)`
  th {
    border-width: 1px;
    border-color: #e2e8f0 !important;
  }

  td {
    border-width: 1px;
    border-color: #e2e8f0 !important;
  }
`;

export const ContainerStructureConsumption = styled(Box)`
  margin-top: 8px;
  background: linear-gradient(45deg, #f6f7ff, #fbfbff);
  padding: 20px 10px;

  h3 {
    color: #2f2e38;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
    text-align: center;
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 18px;
    }
  }
`;
