import React, {
  ButtonHTMLAttributes,
  Fragment,
  useEffect,
  useRef,
} from 'react';
import { FaPlus } from 'react-icons/fa';
import { FiMinus, FiPlus, FiX } from 'react-icons/fi';

import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  TabsProps,
  Tooltip,
  Flex,
  Text,
  Box,
  IconButton,
  InputElementProps,
  Badge,
} from '@chakra-ui/react';
import { Button } from 'antd';

import { Replace } from '~/helpers/Replace';

import ButtonIcon from '../Button/ButtonIcon';
import { FlexCustom, TabChakra } from './styles';

interface Props extends Replace<TabsProps, { children?: React.ReactNode }> {
  data: Data[];
  bgTabPanel?: string;
  tooltip?: boolean;
  onClickTab?(id: number, count?: number): void;
  addRemoveTab?: boolean;
  allowAdd?: boolean;
  fadeEffect?: boolean;
  onClickAdd?(): void;
  onClickRemove?(id: number, count?: number): void;
}

interface Data {
  id: number;
  count?: number;
  stored?: boolean;
  title: string;
  content: React.ReactNode;
  show?: boolean;
  _selected?: string;
  background?: string;
  color?: string;
  new?: boolean;
  allowDelete?: boolean;
}

const Tab: React.FC<Props> = ({
  data,
  bgTabPanel,
  tooltip,
  onClickTab,
  onClickAdd,
  onClickRemove,
  addRemoveTab = false,
  allowAdd,
  fadeEffect,
  ...rest
}) => {
  // useEffect(() => console.log('tab:', data), [data]);

  const tabRefs = useRef<HTMLInputElement[]>([]);

  return (
    <Tabs {...rest}>
      <TabList overflowX="auto" overflowY="hidden" pt={3} pb={1}>
        {data.map((dt, key) => {
          // console.log(dt);
          // const element = document.querySelector(`#tabs-50--tab-${key}`);
          // console.log(element);
          // element ? element?.click() : '';

          // const ref = React.createRef();

          return (
            <Fragment key={`${dt.title}-${key + 1}`}>
              {tooltip ? (
                <Tooltip label={dt.title}>
                  <TabChakra
                    /* _selected={{
                      opacity: '1 !important',
                      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
                    }} */
                    onClick={() => onClickTab && onClickTab(dt.id)}
                    // aria-selected={false}
                    // hidden={dt.show}
                    // display={dt.show ? 'flex' : 'none'}
                  >
                    {dt.title}
                  </TabChakra>
                </Tooltip>
              ) : (
                <FlexCustom display={!dt.show ? 'none' : 'flex'}>
                  <TabChakra
                    _selected={{
                      color: dt?._selected ?? '#000',
                      opacity: '1 !important',
                      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
                    }}
                    style={{ opacity: fadeEffect ? 0.75 : 1 }}
                    background={dt?.background ?? '#fff'}
                    color={dt?.color ?? '#000'}
                    onClick={() => onClickTab && onClickTab(dt.id, dt?.count)}
                    aria-selected={!!dt.show}
                    hidden={!dt.show}
                    ref={(ref: HTMLInputElement) => {
                      tabRefs.current[key] = ref;
                    }}
                    // display={dt.show ? 'flex' : 'none'}
                  >
                    <Text mr={3} whiteSpace="nowrap">
                      {dt.title}
                    </Text>
                    {/* {dt.new && (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        rounded="50%"
                        position="absolute"
                        right="0px"
                        top="-9px"
                        border="1px solid #fff"
                        background="red.500"
                        width="20px"
                        height="20px"
                        // border="1px solid #fff"
                        // background="green.600"
                        // color="#fff"
                        // position="absolute"
                        // right="-4"
                        // top="0"
                        zIndex={100}
                      />
                    )} */}
                  </TabChakra>
                  {addRemoveTab && dt.allowDelete && (
                    <Box
                      as="span"
                      position="absolute"
                      right={2}
                      top={1}
                      cursor="pointer"
                      onClick={() =>
                        onClickRemove && onClickRemove(dt.id, dt?.count)
                      }
                      background="transparent"
                      borderRadius="100%"
                      zIndex={100}
                    >
                      <FiX color="#ff0000" />
                    </Box>
                  )}
                </FlexCustom>
              )}
            </Fragment>
          );
        })}
        {addRemoveTab && allowAdd && (
          <Tooltip
            aria-label="Adicionar nova anotação"
            label="Adicionar nova anotação"
          >
            <Flex alignItems="center" ml={3}>
              <ButtonIcon
                icon="plus"
                onClick={() => onClickAdd && onClickAdd()}
              />
            </Flex>
          </Tooltip>
        )}
      </TabList>

      <TabPanels bg={bgTabPanel}>
        {data.map((dt, key) => {
          return (
            // continuar quebrando a cabeça (componente <TabPanel />) nao esta sendo exibido por conta de algum erro no display/dt.show
            <TabPanel
              hidden={!dt.show}
              isSelected={!!dt.show}
              // display={dt.show ? 'block' : 'none'}
              key={`${dt.title}-${key + 1}`}
            >
              {dt.content}
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

export default Tab;
