import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import {
  Avatar,
  Box,
  Flex,
  Heading,
  Tooltip,
  useToast,
  Divider,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
  useDisclosure,
  Button,
  Text,
  Collapse,
} from '@chakra-ui/react';
import filesize from 'filesize';
import { uniqueId } from 'lodash';
import queryString from 'query-string';
import { mutate } from 'swr';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import { ActivityProps } from '~/modules/activityExecution/@types/activity';
import { Pop as PopInterface } from '~/modules/activityExecution/@types/activity';
import AlertDialog from '~/shared/components/AlertDialog';
import AvatarCustom from '~/shared/components/AvatarCustom';
import Loading from '~/shared/components/Loading';
import Header from '~/shared/components/SectionHeader';
import Upload from '~/shared/components/Upload';
import api from '~/shared/services/api';
import getFormattedPlanningInfo from '~/utils/getFormattedPlanningInfo';

import ActivityAttendanceInfo from '../../components/ActivityAttendanceInfo';
import ActivityDevolution from '../../components/ActivityDevolution';
import AttachedFiles, { NewFileDB } from '../../components/AttachedFiles';
import FileClassification, { IFile } from '../../components/FileClassification';
import Pop from '../../components/Pop';
import RunningActivity from '../../components/RunningActivity';

interface Params {
  planningId?: string;
}

export interface ActivityDetails extends ActivityProps {
  user_name: string;
  user_avatar: string;
}

export const PlanningInfo: React.FC = () => {
  const acceptedFormats = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/docx',
    'application/pdf',
    'text/plain',
    'application/msword',
    'application/x-zip-compressed',
    '.csv',
    '.doc',
    '.xls',
    '.xlsx',
    '.xlsm',
    '.xltm',
    '.zip',
    '.rar',
    '.7z',
    '.tar',
    'text/*',
    'image/*',
  ];

  const [returnedActivity, setReturnedActivity] = useState<{
    id: number;
    obs?: string;
  }>();

  const [modalPopData, setModalPopData] = useState<PopInterface>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  const addToast = useToast();
  const location = useLocation();
  const { planningId } = useParams<Params>();

  const goLink = useMemo(() => {
    return `/planejamento${location.search}`;
  }, [location]);
  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const isSessionUser = Number(user.id) === Number(queryParams?.employee);

  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);

  const [newSavedFiles, setNewSavedFiles] = useState<NewFileDB | undefined>(
    undefined,
  );

  const [activityDetails, setActivityDetails] = useState<ActivityDetails>(
    {} as ActivityDetails,
  );

  const [existFileClassifications, setExistFileClassifications] = useState(
    true,
  );

  /* Ao passar para o componente o false, ele renderizaria todos os outros componentes que precisassem
  de valores obtidos apenas quando realizado o useEffect, para garantir que os componentes quando loading false
  sejam carregados apenas uma vez, já iniciamos o status com o valor true, ao final do effect, transformamos para
  false, já que independente de ter obtido sucesso ou falha na requisição o loading será false em finally */
  const [loading, setLoading] = useState(true);

  const [isExecutingThisActivity, setIsExecutingThisActivity] = useState(false);

  /* Necessário para acessar o método de deletar arquivos salvos a partir do alert dialog
  que existe neste componente pai do AttachedFiles */

  useEffect(() => {
    api
      .get<ActivityDetails>(
        `plannings/activity/${planningId}/${queryParams.employee}`,
      )
      .then((response) => {
        if (response.data.situation_id === 15) setIsExecutingThisActivity(true);

        setActivityDetails(getFormattedPlanningInfo(response.data));
      })
      .catch((err) => {
        if (err.response.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro no servidor.',
          });

          return;
        }

        addToast({
          title: 'Não foi possível encontrar a atividade!',
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [planningId, queryParams.employee, addToast]);

  const handleExistFileClassifications = useCallback((status: boolean) => {
    setExistFileClassifications(status);
  }, []);

  const handleUpdateActivity = useCallback(
    (
      status: 'Pausado' | 'Finalizado' | 'Iniciado' | 'Não Iniciado',
      status_id: number,
    ) => {
      if (isSessionUser) {
        mutate(`/allocation/personal/${user.id}`);
      }

      setActivityDetails((state) => ({
        ...state,
        situation: status,
        situation_id: status_id,
      }));
    },
    [isSessionUser, user.id],
  );

  const handleFinishedActivity = (): void => {
    setIsExecutingThisActivity(false);

    setActivityDetails((state) => ({
      ...state,
      situation: 'Finalizado',
      situation_id: 16,
    }));
  };

  const handleUpdateFile = useCallback((id, data) => {
    setUploadedFiles((state) =>
      state.map((file) => (file.id === id ? { ...file, ...data } : file)),
    );
  }, []);

  // a prop base do filesize será utilizada apenas para exibição, por default utiliza base 2
  const submitFile = useCallback(
    (files: File[]) => {
      const uploadFiles = files.map((file) => {
        return {
          file,
          id: uniqueId(),
          name: file.name,
          readableSize: filesize(file.size),
          originalSize: file.size,
          preview: URL.createObjectURL(file),
          /* progress: 0,
        uploaded: false,
        error: false,
        url: '', */
        };
      });

      setUploadedFiles([...uploadFiles, ...uploadedFiles]);
    },
    [uploadedFiles],
  );

  const handleRemoveFile = useCallback((id) => {
    setUploadedFiles((state) => state.filter((file) => file.id !== id));
  }, []);

  const handleLoadingNewSavedFiles = useCallback((newFiles: NewFileDB) => {
    setNewSavedFiles(newFiles);
  }, []);

  // Verificar se é o mesmo funcionário da sessão para exibir o botão de enviar e-mail
  return (
    <Box>
      <Header
        title="Anexar Arquivos"
        pagename="Anexe os arquivos relacionados a atividade"
        goBackLink={goLink}
      >
        <Flex
          flexDirection={['column', 'row']}
          alignItems={['center', 'start']}
        >
          <Box mb={[2, 0]}>
            <Tooltip label={activityDetails.user_name}>
              <AvatarCustom
                name={activityDetails.user_name}
                src={activityDetails.user_avatar}
                size="md"
                mr={2}
              />
            </Tooltip>
          </Box>
        </Flex>
      </Header>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          {isSessionUser && isExecutingThisActivity ? (
            <>
              <RunningActivity
                planningId={Number(planningId)}
                employee={Number(queryParams.employee)}
                company={user.company_id || 1}
                isSessionUser={isSessionUser}
                finish={handleFinishedActivity}
                updateActivity={(status, status_id) =>
                  handleUpdateActivity(status, status_id)
                }
                pop={(dataPop) => setModalPopData(dataPop)}
                modalDevolution={(id, obs) => setReturnedActivity({ id, obs })}
                companyHasFolderStructure={
                  activityDetails.company_has_folder_structure
                }
                paramsPushLink={queryString.stringify(queryParams)}
                canSendEmail={activityDetails.send_email}
                warningFinishActivity="Ao finalizar não será mais possível anexar arquivos nessa atividade"
              />
              {modalPopData && (
                <Pop
                  data={modalPopData}
                  onClosePop={() => setModalPopData(undefined)}
                />
              )}
            </>
          ) : (
            <ActivityAttendanceInfo activityDetails={activityDetails} />
          )}
          {/* Se a atividade estiver finalizada podemos exibir apenas a listagem dos arquivos */}

          {!activityDetails.email_sent ? (
            /* Se ao invés de não exibir o componente optassemos por não renderizá-lo sob a condição, quando fosse falsa, o componente
            'fecharia' ou seja todos os arquivos que estavam na lista para subir, seriam esquecidos, ao pausar a atividade em execução */
            <Box
              display={
                isSessionUser &&
                activityDetails.attach_file &&
                activityDetails.situation_id === 15
                  ? 'block'
                  : 'none'
              }
            >
              <Heading size="sm">ANEXAR DOCUMENTOS</Heading>
              <Box>
                <Divider my={3} />

                <Upload
                  onUpload={submitFile}
                  showFormat
                  accept={acceptedFormats}
                />
              </Box>

              {!existFileClassifications && (
                <Box mt={4}>
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertTitle mr={2}>
                      Não é possível realizar uploads
                    </AlertTitle>
                    <AlertDescription>
                      Ainda não há classificações de arquivos registradas.
                    </AlertDescription>
                    <CloseButton position="absolute" right="8px" top="8px" />
                  </Alert>
                </Box>
              )}

              <FileClassification
                activityData={activityDetails}
                uploadedFiles={uploadedFiles}
                OnUploadedFiles={handleLoadingNewSavedFiles}
                updateFile={handleUpdateFile}
                handleRemove={handleRemoveFile}
                existFileClassification={handleExistFileClassifications}
              />
            </Box>
          ) : (
            <Alert
              status="success"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              variant="top-accent"
              p={8}
              mb={8}
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                E-mail enviado com sucesso
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Não é mais possível realizar upload de arquivos!
              </AlertDescription>
            </Alert>
          )}

          <AttachedFiles
            newSavedFiles={newSavedFiles}
            planningId={planningId}
            clientCompany={activityDetails.company_client_id}
            isSessionUser={isSessionUser}
            isFinished={activityDetails.situation_id === 16}
            canAttachFiles={activityDetails.attach_file}
          />
          {returnedActivity && (
            <ActivityDevolution
              id={returnedActivity.id}
              obs={returnedActivity.obs}
              onCloseDevolution={() => setReturnedActivity(undefined)}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default PlanningInfo;
