import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FiMail, FiMessageCircle } from 'react-icons/fi';
import { useLocation, useParams } from 'react-router-dom';

import {
  Box,
  Grid,
  GridItem,
  useToast,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import queryString from 'query-string';
import { mutate } from 'swr';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import { Pop as PopInterface } from '~/modules/activityExecution/@types/activity';
import {
  Department,
  CorporateEmail,
} from '~/modules/management/@types/management';
import AlertDialog from '~/shared/components/AlertDialog';
import InputChakra from '~/shared/components/InputChakra';
import Select from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import getFormattedPlanningInfo from '~/utils/getFormattedPlanningInfo';
import { getValidationErrorUniqueFields } from '~/utils/getValidationErrors';

import ActivityAttendanceInfo from '../../components/ActivityAttendanceInfo';
import ActivityDevolution from '../../components/ActivityDevolution';
import AttachedFiles, { NewFileDB } from '../../components/AttachedFiles';
import Pop from '../../components/Pop';
import RunningActivity from '../../components/RunningActivity';
import { ActivityDetails } from '../PlanningInfo';

export interface DataEmail {
  planning_id?: string;
  email_id: string;
  toInput?: string;
  ccInput?: string;
  bccInput?: string;
  to: string[];
  cc?: string[];
  bcc?: string[];
  subject: string;
  content?: string;
  files?: NewFileDB[];
}

interface CorporateEmailSelect extends CorporateEmail {
  label: string;
  value: number;
}

const SendEmailToClient: React.FC = () => {
  const addToast = useToast();
  const cancelRef = useRef(null);
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  /* O ref também serve como variável mutável de instancia, ele pode armazenar um valor de um estado
  ao realizar a renderização para manter o valor anterior, por exemplo */

  const [returnedActivity, setReturnedActivity] = useState<{
    id: number;
    obs?: string;
  }>();

  const [modalPopData, setModalPopData] = useState<PopInterface>();

  const selectedFiles = useRef<NewFileDB[]>([]);
  // Parâmetro descrito na rota dentro de route :planningId
  const { planningId } = useParams<{ planningId?: string | undefined }>();
  const location = useLocation();
  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const goLink = useMemo(() => {
    return `/planejamento${location.search}`;
  }, [location]);

  const isSessionUser = Number(user.id) === Number(queryParams?.employee);

  const [activityDetails, setActivityDetails] = useState<ActivityDetails>(
    {} as ActivityDetails,
  );
  const [sendLoading, setSendLoading] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(true);
  const [loadedAllowedEmails, setLoadedAllowedEmails] = useState(false);

  const [allowedEmails, setAllowedEmails] = useState<CorporateEmailSelect[]>(
    [],
  );

  const [emailData, setEmailData] = useState<DataEmail>({} as DataEmail);
  const [emailSent, setEmailSent] = useState(false);

  const [from, setFrom] = useState('');

  const [showVerticalAttachedFiles, setShowVerticalAttachedFiles] = useState(
    true,
  );

  const [emailSignature, setEmailSignature] = useState('');

  const [isExecutingThisActivity, setIsExecutingThisActivity] = useState(false);

  const [editorState, setEditorState] = useState<EditorState>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const getStartEmail = useCallback((): string => {
    return `Prezado(a) Cliente, `;
  }, []);

  const getEndEmail = useCallback(
    (attendanceId: number, orderActivity: number): void => {
      const signature = `<br>${user.name}
    <p>${user.responsibility} - ${user.department}</p>
    <p>IPAC CONTABILIDADE</p>
    3561-6080/3561-0025
    <p style="font-style: italic; font-size: 13px; margin-top: 1.5rem">•MyIPAC ${attendanceId} - #${orderActivity}</p>`;
      setEmailSignature(signature);
    },
    [user],
  );

  useEffect(() => {
    // console.log('teste');
    const getPlanningInfo = (): void => {
      api
        .get<ActivityDetails>(
          `plannings/activity/${planningId}/${queryParams.employee}`,
        )
        .then((response) => {
          // console.log(response);
          if (response.data.situation_id === 15)
            setIsExecutingThisActivity(true);
          setActivityDetails(getFormattedPlanningInfo(response.data));

          const { attendance_id, email_sent, order_activity } = response.data;
          getEndEmail(attendance_id, order_activity);
          const content = htmlToDraft(getStartEmail());
          const contentState = ContentState.createFromBlockArray(
            content.contentBlocks,
          );
          setEditorState(EditorState.createWithContent(contentState));

          // console.log(getFormattedPlanningInfo(response.data));
          setEmailSent(email_sent || false);
        })
        .catch((err) => {
          if (err.response.status < 500) {
            addToast({
              position: 'top-right',
              isClosable: true,
              status: 'error',
              title: 'Ooops!!!',
              description:
                err.response.data?.error || 'Ocorreu um erro no servidor.',
            });

            return;
          }

          addToast({
            title: 'Não foi possível encontrar a atividade!',
            position: 'top-right',
            isClosable: true,
            status: 'error',
          });
        })
        .finally(() => setLoadingActivity(false));
    };

    // Verificar se department_id for nulo
    const getDepartmentInfo = (): void => {
      api
        .get<Department>(`department/${user.department_id}`)
        .then((response) => {
          const { corporate_emails } = response.data;

          if (!corporate_emails?.length) {
            addToast({
              title: 'Nenhum e-mail encontrado para seu departamento!',
              description:
                'Por favor, cadastre um e-mail ou solicite ao seu Gerente/Supervisor que o faça.',
              duration: 10000,
              position: 'top',
              isClosable: true,
              status: 'warning',
            });
          }

          if (corporate_emails?.length) {
            setAllowedEmails(
              corporate_emails.map((em) => {
                return {
                  ...em,
                  label: em.email,
                  value: em.id,
                };
              }),
            );
          }
        })
        .finally(() => setLoadedAllowedEmails(true));
    };

    getPlanningInfo();
    getDepartmentInfo();
  }, [
    planningId,
    queryParams.employee,
    addToast,
    user.department_id,
    getStartEmail,
    getEndEmail,
  ]);

  function handleFormatEmails(e: React.FocusEvent<HTMLInputElement>): void {
    const currentValue = e.target.value;
    e.target.value = currentValue
      .replaceAll(';', ',')
      .replaceAll(':', ',')
      .replaceAll('/', ',')
      .replaceAll('|', ',');

    /* if (
      !e.target.value.trimEnd() ||
      e.target.value[e.target.value.length - 1] === ','
    ) */
    /* if (e.key === ' ' || e.code === 'Space' || e.keyCode === 32) {
      String.prototype.trim();
      e.target.value = `${e.target.value.trim()},`;
    } */
  }

  function handleConvertTextToHtml(): string {
    const content = htmlToDraft(emailSignature);
    const contentState = ContentState.createFromBlockArray(
      content.contentBlocks,
    );
    /* Se o e-mail não possuir corpo, as tags html abaixo impossibilitarão a ocorrência de um erro inesperado */
    const currentContent = editorState?.getCurrentContent();

    if (!currentContent?.hasText()) return '<div><br/></div>';

    const rawContentState = convertToRaw(currentContent);
    const rawContentStateSign = convertToRaw(contentState);

    return draftToHtml(rawContentState) + draftToHtml(rawContentStateSign);
  }

  function getEmailArray(email: string): string[] {
    return email.split(',').map((em) => em.trim());
  }

  const validateData = async (data: DataEmail): Promise<void> => {
    try {
      data.to = data.toInput ? getEmailArray(data.toInput) : [];

      if (data.ccInput) data.cc = getEmailArray(data.ccInput);

      if (data.bccInput) data.bcc = getEmailArray(data.bccInput);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email_id: Yup.string().required(
          'Necessário escolher um e-mail remetente',
        ),
        to: Yup.array()
          .of(Yup.string().email().required())
          .required('Necessário informar pelo menos um e-mail destinatário'),
        subject: Yup.string().required(
          'Necessário indicar o assunto do e-mail',
        ),
        cc: Yup.array().of(Yup.string().email().required()).notRequired(),
        bcc: Yup.array().of(Yup.string().email().required()).notRequired(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { email_id, subject, to, toInput, bcc, cc } = data;

      const fromEmail =
        email_id &&
        allowedEmails?.find((em) => em.id === Number(email_id))?.email;

      setFrom(fromEmail || '');

      setEmailData({
        planning_id: planningId,
        email_id,
        subject,
        toInput,
        to,
        cc,
        bcc,
      });

      onOpen();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        // Sempre vai ter, possui todos os erros da validação, incluindo os que foram obtidos através do array
        getValidationErrorUniqueFields(
          {
            to: 'Os seguintes e-mails são inválidos',
            cc: 'Os seguintes e-mails são inválidos',
            bcc: 'Os seguintes e-mails são inválidos',
          },
          'Input',
          err,
          formRef,
        );
      }
    }
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setSendLoading(true);

      const data = emailData;

      /* data.to = toEmail;
      data.subject = subjectEmail; */

      /*  data.email_id =  */

      data.content = handleConvertTextToHtml(); // não pode estar vazio

      /*  if (!selectedFiles.current.length) {
        throw new Error('Não há arquivos selecionados para enviar por e-mail');
      } */

      if (selectedFiles.current) data.files = selectedFiles.current;

      /*  console.log(data); */

      // ENVIAR E-MAIL COM ANEXOS

      await api.post('/attendance-files/send-email', data);

      addToast({
        title: 'Mensagem Enviada com Sucesso!',
        position: 'top-right',
        isClosable: true,
        status: 'success',
        description: selectedFiles.current?.length
          ? 'Sua mensagem com os documentos gerados da(s) atividade(s) foi enviada'
          : 'Sua mensagem foi enviada',
      });

      setEmailSent(true);

      /* Após a validação dos campos obrigatórios, atualizamos o estado dataEmail, o que
      acionará o hook useEffect de AttachedFiles, enviando os arquivos selecionados,
      com as credenciais setadas no estado e passadas para o componenete */
    } catch (err) {
      /* Forma encontrada para verificar exceções lançadas do try, ou decorrente
      da resposta da api */

      addToast({
        title: 'Não foi possível enviar a mensagem',
        position: 'top-right',
        isClosable: true,
        status: 'error',
        description: err.response.data?.error,
      });
    } finally {
      setSendLoading(false);
      onClose();
    }
  };

  const handleFinishedActivity = (): void => {
    setIsExecutingThisActivity(false);

    setActivityDetails((state) => ({
      ...state,
      situation: 'Finalizado',
      situation_id: 16,
    }));
  };

  const handleUpdateActivity = useCallback(
    (
      status: 'Pausado' | 'Finalizado' | 'Iniciado' | 'Não Iniciado',
      status_id: number,
    ) => {
      if (isSessionUser) {
        mutate(`/allocation/personal/${user.id}`);
      }

      setActivityDetails((state) => ({
        ...state,
        situation: status,
        situation_id: status_id,
      }));
    },
    [isSessionUser, user.id],
  );

  return (
    <Box>
      {/* É necessário utilizar o goBackLink, para manter a referência de data e funcionário se voltar para o planejamento */}
      <SectionHeader
        title="Enviar E-mail"
        pagename="Selecionar os documentos que serão enviados para o e-mail do cliente."
        goBackLink={goLink}
      />
      {loadingActivity ? (
        <Loading />
      ) : (
        <>
          <Box>
            {isSessionUser && isExecutingThisActivity ? (
              <>
                <RunningActivity
                  planningId={Number(planningId)}
                  employee={Number(queryParams.employee)}
                  company={user.company_id || 1}
                  isSessionUser={isSessionUser}
                  finish={handleFinishedActivity}
                  updateActivity={(status, status_id) =>
                    handleUpdateActivity(status, status_id)
                  }
                  pop={(dataPop) => setModalPopData(dataPop)}
                  modalDevolution={(id, obs) =>
                    setReturnedActivity({ id, obs })
                  }
                  companyHasFolderStructure={
                    activityDetails.company_has_folder_structure
                  }
                  paramsPushLink={queryString.stringify(queryParams)}
                  canAttachFiles={activityDetails.attach_file}
                  warningFinishActivity="Ao finalizar não será mais possível enviar e-mails por essa atividade"
                />
                {modalPopData && (
                  <Pop
                    data={modalPopData}
                    onClosePop={() => setModalPopData(undefined)}
                  />
                )}
                {returnedActivity && (
                  <ActivityDevolution
                    id={returnedActivity.id}
                    obs={returnedActivity.obs}
                    onCloseDevolution={() => setReturnedActivity(undefined)}
                  />
                )}
              </>
            ) : (
              <ActivityAttendanceInfo activityDetails={activityDetails} />
            )}
            {!!allowedEmails.length && !emailSent && (
              <>
                <Box
                  display={
                    isSessionUser && activityDetails.situation_id === 15
                      ? 'block'
                      : 'none'
                  }
                >
                  <Form ref={formRef} onSubmit={validateData}>
                    <Grid
                      templateColumns="1fr"
                      /* templateRows="1fr" */
                      /* gridTemplateRows={"auto auto auto 1fr" */
                      rowGap={4}
                    >
                      <GridItem>
                        {!!allowedEmails?.length && (
                          <Select
                            name="email_id"
                            options={allowedEmails}
                            placeholder="Selecione o e-mail"
                            label="Remetente"
                            isInline
                          />
                        )}
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <InputChakra
                            onBlur={handleFormatEmails}
                            name="toInput"
                            placeholder="E-mail do(s) destinatário(s)"
                            mb={0}
                            label="Destinatário"
                            isInline
                          />
                          <FormHelperText color="blue.500">
                            Separe os e-mails utilizando vírgula ( , )
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <InputChakra
                          icon={FiMessageCircle}
                          name="subject"
                          placeholder="Assunto"
                          label="Assunto"
                          mb={0}
                          isInline
                        />
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <InputChakra
                            onBlur={handleFormatEmails}
                            name="ccInput"
                            placeholder="Cópia(s)"
                            label="Cc"
                            isInline
                          />
                          <FormHelperText color="blue.500">
                            Separe os e-mails utilizando vírgula ( , )
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <InputChakra
                            onBlur={handleFormatEmails}
                            name="bccInput"
                            placeholder="Cópia(s) Oculta(s)"
                            label="Cco"
                            isInline
                          />
                          <FormHelperText color="blue.500">
                            Separe os e-mails utilizando vírgula ( , )
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                      <GridItem mt={4} borderWidth={1} rounded="sm">
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(editor) =>
                            setEditorState(editor)
                          }
                          editorStyle={{ padding: '1rem' }}
                        />
                      </GridItem>
                    </Grid>
                  </Form>
                  <Box
                    borderWidth={1}
                    background="gray.100"
                    p={4}
                    dangerouslySetInnerHTML={{ __html: emailSignature }}
                  />
                </Box>

                <Box width="full" mt={10}>
                  <AttachedFiles
                    clientCompany={73} // Alterar empresa
                    sendEmail
                    planningId={planningId}
                    sendLoading={sendLoading}
                    isExecuting={activityDetails.situation_id === 15}
                    /* savedFiles estará atualizado porque, o objeto não foi renderizado novamente, ao
              clicar no botão de enviar, então, a ultima renderização (seleção de arquivos) dele,
              é o que está sendo passado */
                    setSelectedFiles={(savedFiles) => {
                      selectedFiles.current = savedFiles;
                      formRef.current?.submitForm();
                    }}
                    title="SELECIONE OS DOCUMENTOS"
                  />
                </Box>

                <AlertDialog
                  title="Enviar e-mail"
                  description={`O e-mail <b>${
                    emailData.subject
                  }</b> será enviado para <b>${
                    emailData?.toInput
                  }</b> a partir do email <b>${from || 'Padrão'}</b> ${
                    selectedFiles.current.length
                      ? `com ${selectedFiles.current.length} anexos`
                      : 'sem anexos'
                  } e ${
                    emailData?.cc?.length || emailData.bcc?.length
                      ? 'com cópias'
                      : 'sem cópias'
                  }<br><br> <small><b>OBS: O seu e-mail ${
                    user.email
                  } e o remetente são copiados por padrão</b></small>`}
                  isOpen={isOpen}
                  htmlContent
                  size="lg"
                  isLoading={sendLoading}
                  leastDestructiveRef={cancelRef}
                  onClose={onClose}
                  onSubmit={() => handleSubmit()}
                />
              </>
            )}
          </Box>
          {emailSent && (
            <Alert
              status="success"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              variant="top-accent"
              p={8}
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                E-mail enviado com sucesso!
              </AlertTitle>
            </Alert>
          )}
        </>
      )}
    </Box>
  );
};

export default SendEmailToClient;
