import { Box, Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled.div``;

export const TrBorder = styled(Box)`
  th {
    border-width: 1px;
    border-color: #e2e8f0 !important;
  }

  td {
    border-width: 1px;
    border-color: #e2e8f0 !important;
  }
`;

export const BoxRadio = styled(Box)`
  .flex-radio-label {
    margin-bottom: 5px;
  }
  .flex-radio-label h5 {
    margin-bottom: 0 !important;
  }
  .help-radio-icon {
    margin-left: 5px;
  }

  .radio-group-stack label {
    margin-right: 10px;
  }

  @media (max-width: 480px) {
    .radio-group-stack {
      flex-direction: column;
      margin-right: 0;
    }

    .radio-group-stack label {
      margin-left: 0;
    }
  }
`;

export const TableTheadFixed = styled(Box)`
  table {
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid transparent;
    overflow: auto;
  }
`;

export const GroupBadge = styled(Flex)`
  .attendance-link:not(:last-child) {
    margin-bottom: 16px;
  }
  .attendance-link:hover .badge-attendance {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
  }
`;

export const TrBox = styled(Box)`
  border-bottom: 1px solid #f7fafc;
  &.activities td:first-child {
    padding-left: 1.25rem;
    position: relative;

    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 1rem;
      height: 100%;
      background-color: #f7fafc;
    }
  }

  &.activities.sub {
    color: #a0aec0;

    td:first-child {
      padding-left: 2.25rem;
      position: relative;

      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 2rem;
        height: 100%;
        background-color: #f7fafc;
      }
    }
  }

  td {
    padding: 0.25rem auto;
  }

  &.activities:hover {
    background-color: #f7fafc;
  }
`;
