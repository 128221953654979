import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop(): null {
  const { pathname } = useLocation();

  // Ao mudar a rota do frontend com o componente é renderizado
  /* Utilizando o history.goBack() vazio para voltar para a página, a rolagem não acontece,
  porém passando um link ele restaura a rolagem (scroll restauration) */
  useEffect(() => {
    // console.log(pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
