import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { ConfigProvider } from 'antd';
import pickerPtBR from 'antd/es/locale/pt_BR';

import theme from '~/styles/chakraTheme';

import { AuthProvider } from './auth';
import { NotificationProvider } from './notification';
import { RealTimeNotificationProvider } from './realTimeNotification';
import { CustomThemeProvider } from './theme';
import { TimerProvider } from './timer';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <NotificationProvider>
      <ChakraProvider theme={theme}>
        <CustomThemeProvider>
          <ConfigProvider locale={pickerPtBR}>
            <TimerProvider>
              <RealTimeNotificationProvider>
                {children}
              </RealTimeNotificationProvider>
            </TimerProvider>
          </ConfigProvider>
        </CustomThemeProvider>
      </ChakraProvider>
    </NotificationProvider>
  </AuthProvider>
);

export default AppProvider;
