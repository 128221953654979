import React from 'react';

import checkedPageImg from '~/assets/checked.png';

import { Container, Title } from './styles';

interface Props {
  title?: string;
}

const CheckedPage: React.FC<Props> = ({ title }) => {
  return (
    <Container>
      <img src={checkedPageImg} alt="Nada para exibir" />
      {title && <Title>{title}</Title>}
    </Container>
  );
};

export default CheckedPage;
