import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { FiEdit, FiPlusCircle } from 'react-icons/fi';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  Button,
  IconButton,
  useToast,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import queryString from 'query-string';
import * as Yup from 'yup';

import { Replace } from '~/helpers/Replace';
import { User } from '~/modules/accessControl/@types/user';
import AvatarCustom from '~/shared/components/AvatarCustom';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import SectionHeader from '~/shared/components/SectionHeader';
import { TimeLineStatus, TimeLine } from '~/shared/components/TimeLineStatus';
import api from '~/shared/services/api';

import {
  UserFormData,
  DepartmentSelect,
  ResponsibilitySelect,
  FiltersProps,
} from '..';

import getValidationErrors from '~/utils/getValidationErrors';

interface Params {
  userId?: string;
}

export interface UserEditProps extends User {
  checkedRegisterWorkShift: number;
  timeline: TimeLine[];
}

export const UserEdit: React.FC = () => {
  const { userId } = useParams<Params>();
  const location = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const goUsers = useMemo(() => {
    return `/controle/usuarios${location.search}`;
  }, [location]);

  const formRef = useRef<FormHandles>(null);
  const addToast = useToast();

  const [user, setUser] = useState<UserEditProps>({} as UserEditProps);
  const [responsibilities, setResponsibilities] = useState<
    ResponsibilitySelect[]
  >([]);
  const [departments, setDepartments] = useState<DepartmentSelect[]>([]);
  const [timeLine, setTimeLine] = useState<TimeLine[]>([]);
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleSendEmail, setToggleSendEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const [loadingEditUser, setLoadingEditUser] = useState(false);
  const [loadReload, setLoadReload] = useState(0);

  useEffect(() => {
    async function showUser(): Promise<void> {
      api
        .get<UserEditProps>(`users/${userId}`)
        .then((response) => {
          const { data } = response;

          const us: UserEditProps = data;

          if (us.department && us.department?.pivot) {
            us.department = {
              ...us.department,
              pivot: {
                ...us.department.pivot,
              },
            };
          }
          setUser(us);
          // console.log(data);
          if (data.timeline) {
            setTimeLine(data.timeline);
          }
        })
        .finally(() => setLoading(false));
    }

    async function getFilters(): Promise<void> {
      api
        .get<FiltersProps>('users/filters')
        .then((response) => {
          const { department, responsibility } = response.data;

          setResponsibilities(
            responsibility.map((item) => ({
              label: item.name,
              value: String(item.id),
            })),
          );

          setDepartments(
            department.map((item) => ({
              label: item.name,
              value: String(item.id),
            })),
          );
        })
        .finally(() => setLoadingFilters(false));
    }

    showUser();
    getFilters();
  }, [userId]);

  const handleSubmitEdit = useCallback(
    async (data: UserFormData) => {
      setLoadingEditUser(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido!'),
          password: Yup.string()
            .nullable()
            .min(6, 'Mínimo 6 caracteres')
            .max(30, 'Máximo 30 caracteres'),
          password_confirmation: Yup.string()
            .when('password', {
              is: (val) => !!val,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
          responsibility: Yup.string().required('Selecione um cargo'),
          department: Yup.string().required('Selecione um cargo'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.send_email = toggleSendEmail;

        const resp = await api.put(`users/${userId}`, data);
        const { data: newUser } = resp;

        setUser(newUser); // verificar se tem a mesma interface

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Usuário editado!',
          description: 'As informações do usuário foram alteradas com sucesso.',
          isClosable: true,
        });

        history.push({
          pathname: `/controle/usuarios`,
        });

        // setLoadReload((state) => state + 1);
      } catch (err) {
        // console.log(err);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          isClosable: true,
        });
      } finally {
        setLoadingEditUser(false);
      }
    },
    [userId, addToast, history, toggleSendEmail],
  );

  return (
    <Box pos="relative">
      <SectionHeader
        title="Controle de usuário"
        pagename={user.name}
        loading={loading}
        goBackPage
      />

      {loading || loadingFilters ? (
        <LoadingAbsolute z_index={1111} min_height={500} />
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          width="700px"
          ml="auto"
          mr="auto"
        >
          <AvatarCustom src={user.avatar} name={user.name} size="2xl" />
          <Text fontSize="3xl" mt={4}>
            {user.name}
          </Text>
          <Flex w="full" borderBottom="1px solid #acacac" mb={4}>
            <Text fontSize="2xl" fontWeight="bold">
              Dados
            </Text>
          </Flex>

          <Box width="700px">
            <Form ref={formRef} onSubmit={handleSubmitEdit}>
              <InputChakra name="name" label="Nome" defaultValue={user.name} />
              <InputChakra
                name="email"
                label="E-mail"
                defaultValue={user.email}
              />

              <Flex alignItems="center" mt={4} mb={2}>
                <FormLabel htmlFor="togglePassword" mb={0}>
                  Deseja alterar senha?
                </FormLabel>
                <Switch
                  id="togglePassword"
                  onChange={() => setTogglePassword(!togglePassword)}
                  defaultIsChecked={togglePassword}
                  size="sm"
                />
              </Flex>

              {togglePassword && (
                <>
                  <Flex alignItems="center" mb={5}>
                    <FormLabel htmlFor="toggleSendEmail" mb={0}>
                      Enviar e-mail com a alteração para o usuário?
                    </FormLabel>

                    <Switch
                      id="toggleSendEmail"
                      onChange={() => {
                        setToggleSendEmail(!toggleSendEmail);
                      }}
                      defaultIsChecked={toggleSendEmail}
                      size="sm"
                    />
                  </Flex>

                  <InputChakra
                    name="password"
                    placeholder=""
                    label="Senha"
                    type="password"
                    minLength={6}
                    maxLength={30}
                    isPassword
                  />
                  <InputChakra
                    name="password_confirmation"
                    label="Confirme sua nova senha"
                    type="password"
                    minLength={6}
                    maxLength={30}
                    isPassword
                  />
                </>
              )}
              <SelectChakra
                label="Cargo"
                name="responsibility"
                placeholder="Selecione um Cargo"
                options={responsibilities}
                defaultValue={user.responsibility?.id}
                containerStyle={{ marginTop: 20 }}
              />

              <SelectChakra
                label="Departamento"
                name="department"
                placeholder="Selecione um Departamento"
                options={departments}
                defaultValue={user.department?.id}
                containerStyle={{ marginTop: 20 }}
              />
            </Form>
            <Flex justifyContent="center" my={4}>
              <Button
                type="submit"
                width="200px"
                colorScheme="green"
                variant="solid"
                onClick={() => formRef.current?.submitForm()}
                isLoading={loadingEditUser}
              >
                Salvar
              </Button>
            </Flex>
            <TimeLineStatus
              userId={userId}
              defaultTimeLine={timeLine}
              checkedRegisterWorkShift={!!user.checkedRegisterWorkShift}
              blockUser={() => {
                setUser((state) => ({
                  ...state,
                  department: undefined,
                }));
                formRef.current?.setFieldValue('department', '');
              }}
            />
          </Box>
        </Flex>
      )}
    </Box>
  );
};
