import { Link } from 'react-router-dom';

import { Flex, Box, Progress } from '@chakra-ui/react';
import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Card = styled(Flex)`
  text-decoration: none;
  //display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background: rgb(238, 245, 255);
  min-width: 250px;
  color: ${({ theme }) => theme.colors.text};

  border-bottom: 4px solid transparent;
  transition: all 0.2s;

  &:hover {
    border-color: ${darken(0.05, 'rgb(238, 245, 255)')};
    text-decoration: none;
  }

  @media (max-width: 480px) {
    width: 300px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: center;
  }
`;

export const PercentEmployee = styled(Flex)`
  flex-wrap: wrap;
  justify-content: flex-start;
  min-height: 110px;

  &:hover {
    cursor: pointer;

    div:first-child {
      border-radius: 8px;
      border: solid 1px #0b51c5;
      color: #0b51c5;
      transition: color 0.25s;
    }
    div p {
      font-size: 17px;
      color: #0b51c5;
      transition-property: color, font-size;
      transition-duration: 0.25s;
    }
  }
`;

interface ProgressProps {
  color: string;
}

export const ProgressCustom = styled(Progress)<ProgressProps>`
  div {
    background-color: ${(props) => props.color};
  }
`;
