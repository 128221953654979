import { Link } from 'react-router-dom';

import { Flex, Box, Progress } from '@chakra-ui/react';
import styled from 'styled-components';

import SectionHeader from '~/shared/components/SectionHeader';

export const BoxCustom = styled.div`
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  width: 200px;
  text-overflow: ellipsis;
`;

export const CustomBoxSectionHeader = styled(Box)`
  div div p {
    @media (min-width: 480px) {
      display: none;
    }
  }

  @media (min-width: 480px) {
    min-width: 250px;
  }
`;
