import styled, { css } from 'styled-components';

interface Props {
  zIndex?: number;
  minHeight?: number;
}

export const Container = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);

  ${(props) =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}

  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight}px;
    `}

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
