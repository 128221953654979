import React, { useCallback, ReactNode, useRef } from 'react';

import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogProps,
  Button,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import SelectChakra, { Option } from '../InputChakra/SelectChakra';

interface Props extends Omit<AlertDialogProps, 'children'> {
  title: string;
  description?: string;
  titleButtonCancel?: string;
  titleButtonConfirm?: string;
  VariantColor?: string;
  submitButtonColor?: string;
  onChange?(value: string): void;
  onSubmit(): void;
  onCancel?(): void;
  isLoading?: boolean;
  children?: ReactNode;
  htmlContent?: boolean;
  isReminder?: boolean;
  reminderOptions?: Option[];
  defaultReminderOption?: string | number;
  reminderLabel?: string;
}

const AlertDialog: React.FC<Props> = ({
  title,
  titleButtonCancel,
  titleButtonConfirm,
  htmlContent = false,
  description,
  VariantColor,
  onSubmit,
  isLoading = false,
  isOpen,
  onClose,
  onCancel,
  leastDestructiveRef,
  children,
  submitButtonColor,
  isReminder,
  reminderLabel,
  reminderOptions,
  onChange,
  defaultReminderOption,
  ...rest
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const ref = useRef<FormHandles>(null);

  return (
    <ChakraAlertDialog
      preserveScrollBarGap
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={onClose}
      {...rest}
    >
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius="sm">
        <AlertDialogHeader fontSize="lg" fontWeight="bold" pr={9}>
          {title}
        </AlertDialogHeader>
        <AlertDialogCloseButton />

        {htmlContent ? (
          <AlertDialogBody
            dangerouslySetInnerHTML={{ __html: description || '' }}
          />
        ) : (
          <AlertDialogBody>{description || children}</AlertDialogBody>
        )}

        <AlertDialogFooter alignItems={isReminder ? 'flex-end' : 'center'}>
          {isReminder ? (
            <Form
              onSubmit={() => null}
              ref={ref}
              initialData={{
                remember_after: defaultReminderOption || '15',
              }}
            >
              <SelectChakra
                label={reminderLabel || `Relembrar após`}
                name="remember_after"
                options={reminderOptions || []}
                onChange={(ev) => {
                  onChange && onChange(ev.target.value);
                }}
                sizeCustom="md"
                isInline
              />
            </Form>
          ) : (
            <>
              {!isLoading && (
                <Button
                  variant="ghost"
                  onClick={() => {
                    onClose();
                    onCancel && onCancel();
                  }}
                >
                  {titleButtonCancel || 'Cancelar'}
                </Button>
              )}
            </>
          )}

          <Button
            colorScheme={VariantColor || 'red'}
            onClick={handleSubmit}
            isLoading={isLoading}
            isDisabled={isLoading}
            bg={submitButtonColor || 'red.500'}
            ml={3}
          >
            {titleButtonConfirm || 'Confirmar'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </ChakraAlertDialog>
  );
};

export default AlertDialog;
