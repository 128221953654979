import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';

import { Box, Text, Img, Flex } from '@chakra-ui/react';

import { LabelCardStyle, InputCardStyle } from './styles';

interface InputImageProps {
  title: string;
  text?: string;
  img: string;
  htmlFor: string;
  valueInput: string;
  selectedSubscription?(): void;
  selectedDoubleClick?(): void;
  disabled?: boolean;
}

const InputImage: React.FC<InputImageProps> = ({
  title,
  text,
  img,
  htmlFor,
  valueInput,
  selectedSubscription,
  selectedDoubleClick,
  disabled = false,
}) => {
  const [checkedCard, setCheckedCard] = useState(false);
  return (
    <>
      <InputCardStyle
        id={htmlFor}
        type="radio"
        name="replanejar"
        value={valueInput}
        hidden
        onClick={selectedSubscription}
      />
      <LabelCardStyle
        htmlFor={htmlFor}
        isChecked={checkedCard}
        onDoubleClick={selectedDoubleClick}
        // onChange={() => setCheckedCard(!checkedCard)}
      >
        <Flex
          alignItems="center"
          cursor={!disabled ? 'pointer' : 'not-allowed'}
          flexDir="column"
          width={['auto', '400px']}
          m={[0, 4]}
          // pb={4}
          borderRadius="8px"
          // border="3px solid transparent"
          // _hover={{ border: '3px solid #0b51c5' }}
        >
          <Img
            boxSize={['auto', '350px']}
            // objectFit="cover"
            src={img}
            alt="vector assinatura"
          />
          <Text mb={3} fontSize="xl">
            {title}
          </Text>
          <Text>{text}</Text>
          <FaCheck color="#0bc55f" />
        </Flex>
      </LabelCardStyle>
    </>
  );
};

export default InputImage;
