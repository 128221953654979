import { Steps } from 'intro.js-react';
import styled, { keyframes, css } from 'styled-components';

interface AvatarLoading {
  loading?: boolean;
}

const fadeInDownAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-250px);
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface HeaderProps {
  background: string;
}

interface ContainerProps {
  showBscBadge?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${(props) =>
    !props.showBscBadge &&
    css`
      #btnProfile span {
        display: none !important;
      }
    `}
`;

export const Header = styled.header`
  height: 250px;
  border-radius: 10px 10px 0 0;
  background: #eee;
  background-size: cover;
  background-position: top center;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  > img {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  label {
    padding: 5px 10px;
    margin: 10px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background: transparent;
    border-radius: 5px;
    color: #1f1f1f;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 300;
    transition: all 0.2s;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.7);

    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    input {
      display: none;
    }

    &:hover {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 0 5px rgba(55, 55, 55, 0.6);
      color: #1f1f1f;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 30%,
      #000000 100%
    );
  }
`;

export const Grid = styled.section`
  display: grid;
  grid-template-areas: 'gridLeft gridRight';
  grid-template-columns: 300px 1fr;
  //grid-gap: 40px;

  @media (max-width: 1366px) {
    grid-template-areas: 'gridLeft' 'gridRight';
    grid-template-columns: 1fr;
  }
`;

export const UserContent = styled.div`
  grid-area: gridLeft;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: -80px auto 0;
  padding-left: 30px;

  width: 100%;

  form {
    position: relative;
    width: 250px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;

    background: #eef5ff;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    font-weight: 500;
    font-size: 13px;
    color: #8694a0;

    animation: 0.4s ${fadeInDownAnimation};

    h1 {
      margin-bottom: 24px;
      font-size: 20px;
      text-align: left;
    }

    > label {
      background: #fff;
      border: 1px solid rgba(71, 98, 235, 0.1);

      input {
        width: 100%;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 780px) {
    padding-left: 0;
  }
`;

export const ProfileContent = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 250px;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > strong {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.title};
  }

  > small {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #8694a0;
    margin-bottom: 5px;

    &.bold {
      font-weight: 500;
    }
  }

  button {
    border: 0;
    height: 40px;
    padding: 0 20px;
    border-radius: 20px;
    color: #ffffff;
    background: #3e4efa;
    margin: 20px auto;
    transition: all 0.2s;
    min-width: 140px;

    &:active {
      transform: scale(0.9);
    }

    &.cancel {
      background: ${({ theme }) => theme.colors.danger};
    }
  }
`;

export const AvatarInput = styled.div<AvatarLoading>`
  margin: -75px auto 0;
  position: relative;
  width: 155px;
  height: 155px;
  align-self: center;
  margin-bottom: 20px;
  border-radius: 50%;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);

  img {
    object-fit: cover;
    object-position: center;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    border: 4px solid #fff;
  }

  label {
    position: absolute;
    width: 155px;
    height: 155px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    top: 0;
    left: 0;
    border: none;
    transition: all 0.2s;
    cursor: pointer;
    opacity: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #fff;
    }

    &:hover {
      opacity: 1;
    }

    ${(props) =>
      props.loading &&
      css`
        background: transparent;
        svg {
          opacity: 0;
        }
      `}
  }
`;

export const LoadingAvatar = styled.div`
  position: absolute;
  width: 155px;
  height: 155px;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ThemeSwitch = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  span {
    margin-right: auto;
  }
`;

export const Content = styled.div`
  grid-area: gridRight;
  padding: 40px 0;
`;

export const ContentGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 30px;

  margin-top: 30px;
`;

export const ButtonsBSC = styled.div`
  display: flex;

  button {
    min-width: 200px;
    margin-bottom: 1rem;
  }

  button ~ button {
    margin-left: 1rem;
  }

  @media (max-width: 769px) {
    flex-direction: column;

    button ~ button {
      margin-left: 0;
    }
  }
`;

export const AccordionColSpan = styled.td`
  column-span: 8;
  text-align: left;
  width: 100%;
`;

export const ContainerTable = styled.div`
  padding: 0 50px;

  /*  table > thead {
    visibility: collapse !important;
  } */
  div:not(:first-child) table > thead {
    visibility: collapse !important;
  }
`;
