import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  FiAtSign,
  FiBookOpen,
  FiEdit,
  FiPlus,
  FiShield,
  FiTrash,
} from 'react-icons/fi';

import {
  Button,
  Box,
  useDisclosure,
  useToast,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import AlertDialog from '~/shared/components/AlertDialog';
import EmptyPage from '~/shared/components/EmptyPage';
import InputChakra from '~/shared/components/InputChakra';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import { Container } from './styles';

interface Permission {
  id: number;
  name: string;
  slug: string;
  description: string;
  created_at: string;
}

interface PermissionProps extends IPaginationProps {
  data: Permission[];
}

const Permission: React.FC = () => {
  const addToast = useToast();

  const cancelDeleteRef = useRef(null);
  const formRef = useRef<FormHandles>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const [isFormEditing, setIsFormEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectPermissionDelete, setSelectPermissionDelete] = useState(0);
  const [modalDataEdit, setModalDataEdit] = useState<Permission>(
    {} as Permission,
  );
  const [modalSuccess, setModalSuccess] = useState(0);
  const [pageSelect, setPage] = useState(1);
  const [permissions, setPermissions] = useState<PermissionProps>(
    {} as PermissionProps,
  );

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Id',
      },
      {
        title: 'Nome',
      },
      {
        title: 'Slug',
      },
      {
        title: 'Descrição',
      },
      {
        title: 'Ações',
      },
    ];
  }, []);

  const loadDataPermissios = useCallback(() => {
    api
      .get<PermissionProps>('permissions', { params: { page: pageSelect } })
      .then((response) => {
        setPermissions(response.data);
      })
      .catch(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro no carregamento dos dados',
          description:
            'Não foi possível listar permissões. Tente novamente mais tarde.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, pageSelect]);

  useEffect(() => {
    setLoading(true);
    loadDataPermissios();
  }, [pageSelect, loadDataPermissios]);

  const handleOpenModalDelete = useCallback(
    (id: number) => {
      setSelectPermissionDelete(id);
      onOpenDelete();
    },
    [onOpenDelete],
  );

  const handleSubmitDelete = useCallback(() => {
    if (permissions) {
      setLoading(true);

      const updatedData = permissions.data.filter(
        (permission) => permission.id !== selectPermissionDelete,
      );

      const newData = {
        ...permissions,
        total: permissions?.total - 1,
        to: permissions?.to - 1,
        data: updatedData,
      };

      setPermissions(newData);
    }

    api
      .delete(`permissions/${selectPermissionDelete}`)
      .then(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Permissão deletada!',
          description: 'Dados deletado com sucesso!',
        });

        setModalSuccess(modalSuccess + 1);
      })
      .catch(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Permissão não deletada!',
          description: 'Erro ao deletar os dados de permissão.',
        });
      })
      .finally(() => {
        setLoading(false);
      });

    onCloseDelete();
  }, [
    selectPermissionDelete,
    addToast,
    modalSuccess,
    permissions,
    onCloseDelete,
  ]);

  const handleNewPage = useCallback((pageNew: number) => {
    setPage(pageNew);
  }, []);

  const handleEditPermission = useCallback(
    (data: Permission) => {
      setIsFormEditing(true);
      setModalDataEdit(data);
      onOpen();
    },
    [onOpen],
  );

  const handleFormCreatePermission = useCallback(() => {
    setIsFormEditing(false);
    setModalDataEdit({} as Permission);
    onOpen();
  }, [setIsFormEditing, onOpen]);

  const handleSubmit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Nome obrigatório')
            .min(4, 'No mínimo 6 dígitos'),
          slug: Yup.string()
            .required('O slug é obrigatório')
            .min(6, 'No mínimo 6 dígitos'),
          description: Yup.string().min(6, 'No mínimo 6 dígitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (modalDataEdit.id) {
          await api.put(`/permissions/${modalDataEdit.id}`, data);

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'success',
            title: 'Permissão editada!',
            description: 'Dados alterado com sucesso!',
          });
        } else {
          await api.post('/permissions', data);

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'success',
            title: 'Permissão cadastrada!',
            description: 'Dados cadastrados com sucesso!',
          });
        }
        loadDataPermissios();

        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        const { error } = err.response.data;

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro na atualização',
          description: error,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, modalDataEdit, onClose, loadDataPermissios],
  );

  return (
    <Box pos="relative">
      <Container>
        <SectionHeader title="Controle de permissões" pagename="Permissões">
          <Button
            variant="solid"
            colorScheme="blue"
            onClick={handleFormCreatePermission}
          >
            <FiPlus size={18} />
            Cadastrar
          </Button>
        </SectionHeader>

        {loading && (
          <Box
            pos="absolute"
            top={0}
            left={0}
            w="full"
            h="400px"
            bg="rgba(255,255,255,0.5)"
          >
            <Loading />
          </Box>
        )}

        {(!permissions || !permissions.data) && !loading ? (
          <EmptyPage title="Nenhum registro de permissão encontrado." />
        ) : (
          permissions &&
          permissions.data?.length > 0 &&
          !loading && (
            <Table
              theadData={tableTitles}
              pagination={{
                current_page: permissions?.current_page,
                last_page: permissions?.last_page,
                per_page: permissions?.per_page,
                to: permissions?.to,
                total: permissions?.total,
              }}
              newPage={(pg) => handleNewPage(pg)}
            >
              {permissions.data?.length > 0 &&
                permissions.data?.map((permission) => (
                  <Box
                    as="tr"
                    key={String(permission.id)}
                    _hover={{ bg: 'gray.50' }}
                  >
                    <Box as="td">{permission.id}</Box>
                    <Box as="td">{permission.name}</Box>
                    <Box as="td">{permission.slug}</Box>
                    <Box as="td">{permission.description}</Box>
                    <Box as="td" className="row" textAlign="right">
                      <HStack spacing={2} justify="flex-end">
                        <IconButton
                          aria-label="Editar"
                          icon={<FiEdit />}
                          variant="ghost"
                          bg="yellow.50"
                          ml={[0, 2]}
                          colorScheme="yellow"
                          onClick={() => handleEditPermission(permission)}
                        />
                        <IconButton
                          aria-label="Deletar"
                          icon={<FiTrash />}
                          variant="ghost"
                          bg="red.50"
                          ml={[0, 2]}
                          colorScheme="red"
                          onClick={() => handleOpenModalDelete(permission.id)}
                        />
                      </HStack>
                    </Box>
                  </Box>
                ))}
            </Table>
          )
        )}
      </Container>

      <ModalChakra
        title={isFormEditing ? 'Atualizar permissão' : 'Cadastrar permissão'}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={() => formRef.current?.submitForm()}
        isLoading={loading}
        size="2xl"
      >
        <Form ref={formRef} onSubmit={handleSubmit} initialData={modalDataEdit}>
          <InputChakra
            label="Nome da permissão"
            name="name"
            type="text"
            placeholder="Nome"
            icon={FiShield}
            autoFocus
          />
          <InputChakra
            label="Slug"
            name="slug"
            type="text"
            placeholder="Slug"
            icon={FiAtSign}
          />
          <InputChakra
            label="Descrição resumida"
            name="description"
            type="text"
            placeholder="Descrição"
            icon={FiBookOpen}
            maxLength={254}
          />
        </Form>
      </ModalChakra>

      <AlertDialog
        title="Deletar permissão?"
        description="Tem certeza que deseja deletar a permissão selecionada?"
        isOpen={isOpenDelete}
        isLoading={loading}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDelete}
        onSubmit={handleSubmitDelete}
      />
    </Box>
  );
};

export default Permission;
