import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px #dfe2e8;
  border-radius: 10px;
  width: 100%;
  min-height: 300px;
  height: auto;
  overflow-x: auto;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > h4 {
    padding: 10px 0;
    border-bottom: 1px solid #d5dee5;
    margin-bottom: 30px;
    color: #3d4a56;
  }

  > div {
    max-width: 270px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2f2e38;

    span {
      text-align: right;
      padding-right: 2px;
    }

    h3 {
      font-weight: bold;
      font-size: 1.6em;
      line-height: 35px;
    }

    h5 {
      font-weight: 500;
      font-size: 1em;
      line-height: 22px;
    }

    h2 {
      font-weight: bold;
      font-size: 3.3em;
      line-height: 84px;
    }

    button {
      background: #3e4efa;
      border-radius: 5px;
      height: 40px;
      width: 100%;
      min-width: 250px;
      border: 0;
      transition: all 0.2s;

      display: flex;

      &:active {
        transform: translateX(10px);
      }

      strong {
        height: 100%;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        height: 100%;
        width: 75px;
        background: #ffffff;
        border: 1px solid #3e4efa;
        border-radius: 0px 5px 5px 0px;
        color: #2f2e38;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 270px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #8694a0;
  }

  img {
    width: 100px;
    margin: 0 auto 30px;
  }
`;
