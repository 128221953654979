import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FiUser, FiLogIn } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Text, Badge, Flex, Box, Input } from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { Hints } from 'intro.js-react';

import { useAuth } from '~/hooks/auth';
import { useNotification } from '~/hooks/notification';
import useCan from '~/hooks/useCan';
import Menu from '~/shared/components/Menu';
import api from '~/shared/services/api';

import AvatarCustom from '../AvatarCustom';
import { Provider } from '../Header';
import SelectChakra from '../InputChakra/SelectChakra';
import HintsIntro from '../Tour/HintsIntro';
import {
  Container,
  Profile,
  ProfileLinks,
  LinkOldSystem,
  FormCustom,
} from './styles';

interface Props {
  showSidebar: boolean;
  companyProvider: Provider[];
}

const Sidebar: React.FC<Props> = ({ showSidebar, companyProvider }) => {
  const { user, completedTourBSC, updateUser } = useAuth();
  const { notifications } = useNotification();
  const refForm = useRef<FormHandles>(null);
  const can = useCan();

  const handleProviderCompany = useCallback(
    async (data) => {
      // console.log(data.provider_company_id);
      await updateUser({ ...user, company_id: data.provider_company_id });
      api.defaults.headers.company = data.provider_company_id;

      // console.log('atualizadas as informações');

      window.location.reload();
    },
    [updateUser, user],
  );

  return (
    <Container active={showSidebar}>
      <Profile>
        <Link to="/perfil">
          <AvatarCustom
            src={user.avatar}
            name={user.name}
            size="xl"
            border="3px solid #fff"
          />
        </Link>
        <Text as="b" color="#fff" textAlign="center" mt={2}>
          {user.name}
        </Text>
        <span>
          {user?.responsibility && <span>{user.responsibility}</span>}
          {/* <span>DP. TI</span> */}
        </span>

        <ProfileLinks>
          <Link id="btnProfile" to="/perfil">
            <FiUser />
            <Text>Perfil</Text>
            {!completedTourBSC && !user.is_subscribed && (
              <Badge background="green.600" color="#fff">
                NOVO
              </Badge>
            )}
          </Link>
          {/* <Link to="/">
            <span>
              <FiBell />
            </span>
            Mensagens
          </Link> */}
        </ProfileLinks>
      </Profile>
      <Flex alignItems="center" justifyContent="center" w="full">
        {!can('select-company-provider') ? (
          <Flex
            className="descktop-none"
            mb={[4, 4, 0]}
            width="100%"
            justifyContent="center"
            alignItems="center"
            display={['flex', 'flex', 'none']}
            px="20px"
          >
            <Input
              readOnly
              width="100%"
              background="#fff"
              defaultValue={
                companyProvider?.find((comp) => {
                  return comp.company_id === user.company_id;
                })?.company_name
              }
            />
          </Flex>
        ) : (
          <>
            <FormCustom
              ref={refForm}
              onSubmit={(value) => handleProviderCompany(value)}
              initialData={{
                provider_company_id: user.company_id?.toString(),
              }}
            >
              {!!companyProvider.length && (
                <Flex display={['flex', 'flex', 'none']} w="full">
                  <SelectChakra
                    name="provider_company_id"
                    options={companyProvider.map((comp) => {
                      return {
                        ...comp,
                        value: comp.company_id,
                        label: comp.company_name,
                      };
                    })}
                    background="#fff"
                    mt={5}
                    width="100%"
                    onChange={() => refForm.current?.submitForm()}
                    /* leftAddon="Empresa" */
                  />
                </Flex>
              )}
            </FormCustom>
          </>
        )}
      </Flex>
      {user.company_id === 1 && (
        <LinkOldSystem href={process.env.REACT_APP_OLDMYIPAC}>
          <FiLogIn size={24} />
          <span>Voltar para versão antiga</span>
        </LinkOldSystem>
      )}

      <Menu notifications={notifications} />
    </Container>
  );
};

export default Sidebar;
