import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  FaUserCog,
  FaInfoCircle,
  FaEnvelope,
  FaPaperclip,
  FaRobot,
  FaUserTie,
  FaSortNumericDown,
  FaEyeSlash,
  FaEye,
} from 'react-icons/fa';
import {
  FiEdit,
  FiPlus,
  FiTrash,
  FiBookOpen,
  FiBook,
  FiClipboard,
  FiClock,
  FiAlignLeft,
  FiMenu,
} from 'react-icons/fi';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  useToast,
  Tooltip,
  Wrap,
  WrapItem,
  Checkbox,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import queryString from 'query-string';
import * as Yup from 'yup';

import {
  Pop as PopInterface,
  Activity,
  Demand,
} from '~/modules/activityExecution/@types/activity';
import Pop from '~/modules/activityExecution/components/Pop';
import ToggleCustom from '~/modules/dashboard/components/ToggleCustom';
import AlertDialog from '~/shared/components/AlertDialog';
import Highlights from '~/shared/components/Highlights';
import CheckboxInput from '~/shared/components/InputChakra/CheckboxChakra';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import { Container } from './styles';

interface Params {
  demandId?: string;
}

interface DemandAttendance extends Demand {
  qtd_atendimento: number;
  activities: Activity[];
}

interface DemandActivitiesList {
  id: number;
  empresa_id: number;
  status_id: number;
  nome: string;
  descricao: string;
  atividades: Activity[];
}

interface ActivityFilter {
  types: TypeActivity[];
  characters: InfoActivity[];
  responsibilities: Responsibility[];
}

interface InfoActivity {
  id: string;
  nome: string;
  descricao: string;
  label: string;
  value: string;
}

interface TypeActivity extends InfoActivity {
  icon: string;
}

interface Responsibility extends InfoActivity {
  ocupado: boolean;
  isChecked?: boolean;
}

const DemandActivitiesList: React.FC = () => {
  const formRefSyncSuccessors = useRef<FormHandles>(null);

  const { demandId } = useParams<Params>();
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const pg = queryParams?.pg;

  const goDemand = useMemo(() => {
    return `/demandas${pg ? `?pg=${pg}` : ''}`;
  }, [pg]);

  const {
    isOpen: isOpenSuccessors,
    onOpen: onOpenSuccessors,
    onClose: onCloseSuccessors,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenHide,
    onOpen: onOpenHide,
    onClose: onCloseHide,
  } = useDisclosure();

  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();

  const cancelDeleteRef = useRef(null);
  const cancelHideRef = useRef(null);

  const [loadingDeleteActivity, setLoadingDeleteActivity] = useState(false);
  const [loadingHideActivity, setLoadingHideActivity] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loadingSyncOrders, setLoadingSyncOrders] = useState(false);
  const [loadingReorder, setLoadingReorder] = useState(false);
  const [reloadActivities, setReloadActivities] = useState(0);

  const [modalPopData, setModalPopData] = useState<PopInterface>();

  const [showHiddenActivities, setShowHiddenActivities] = useState(false);

  const currentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

  const [activityDelete, setActivityDelete] = useState<{
    id: number;
    name: string;
  }>();

  const [activityHidden, setActivityHidden] = useState<{
    id: number;
    name: string;
    hide?: boolean;
  }>();

  /* const [page, setPage] = useState(1); */

  const [demand, setDemand] = useState<DemandAttendance>(
    {} as DemandAttendance,
  );
  const [selectedActivity, setSelectedActivity] = useState<Activity>(
    {} as Activity,
  );
  const [activitiesDeleted, setActivitiesDeleted] = useState<Activity[]>([]);
  const [activitiesEnabled, setActivitiesEnabled] = useState<Activity[]>([]);

  /* const [steps, setSteps] = useState(1); */

  const addToast = useToast();

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Ordem',
      },
      {
        title: 'Atividade',
      },
      {
        title: 'Descrição / POP',
      },
      {
        title: 'Tipo',
      },
      {
        title: 'Caracteristica',
        align: 'center',
      },
      {
        title: 'Permissões',
        align: 'center',
      },
      {
        title: 'Duração',
      },
      {
        title: 'Atividades Sucessora',
      },
      {
        title: 'Ações',
      },
    ];
  }, []);

  function handleGetIcon(typeId: number): any {
    switch (typeId) {
      case 1:
        return <FaUserCog color="#323c79" />;
      case 2:
        return <FaUserTie color="#fb3" />;
      case 3:
        return <FaRobot color="#00a341" />;
      default:
        return null;
    }
  }

  useEffect(() => {
    setLoaded(false);
    setDemand({} as DemandAttendance);
    setShowHiddenActivities(false);

    api
      .get(`demand/${demandId}`)
      .then((response) => {
        const demandD = response.data;
        // const {activities} = demandD.activities;

        if (!demandId) return;

        setDemand({
          ...demandD,
          name: demandD.nome,
          description: demandD.descricao,
          company_id: demandD.empresa_id,
          total_duration_activities: demandD.duracao_total_atividade,
          activities: demandD.atividades.map((demAtv: any) => {
            return {
              ...demAtv,
              name: demAtv.nome,
              description: demAtv.descricao,
              duration: demAtv.duracao,
              order: demAtv.ordem,
              demand_id: demAtv.demanda_id,
              activity_id: demAtv.atividade_tipo_id,
              activity_description: demAtv.atividade_caracteristica_id,
              attach_file: demAtv.anexar_arquivo,
              send_email: demAtv.enviar_emai,
              deleted_at: demAtv.deleted_at,
              type: {
                id: demAtv.atividade_tipo_id,
                name: demAtv.atividade_tipo_nome,
              },
              character: {
                id: demAtv.atividade_caracteristica_id,
                name: demAtv.atividade_caracteristica_nome,
              },
              pop_files: demAtv.arquivo?.map((file: any) => {
                return {
                  ...file,
                  pivot: {
                    atividade_id: demAtv.id,
                    arquivo_id: file.id,
                  },
                };
              }),
            };
          }),
        });
        const atvDelet = demandD.atividades?.filter(
          (atvDel: any) => atvDel.deleted_at,
        );

        const atvEnabled = demandD.atividades?.filter(
          (atvEnbl: any) => !atvEnbl.deleted_at,
        );

        setActivitiesDeleted(atvDelet);

        setActivitiesEnabled(atvEnabled);
      })
      .finally(() => setLoaded(true));
  }, [demandId, reloadActivities]);

  const getPossibleSuccessors = useCallback(
    (activity: Activity): Activity[] => {
      return demand.activities?.filter(
        (act) => act.order > activity.order && !act.deleted_at,
      );
    },
    [demand],
  );

  function handleReorder(): void {
    setLoadingReorder(true);
    api
      .put(`/demand/reorder/${demandId}`)
      .then(() => {
        setDemand((state) => ({
          ...state,
          activities: state.activities.map((act, i) => {
            return { ...act, order: i + 1 };
          }),
        }));
        setReloadActivities((state) => state + 1);

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Atividades reordenadas',
          description: 'Atividades reordenadas com sucesso',
        });
      })
      .catch((err) => {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          duration: 7000,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Atividades não reordenadas!',
          description: 'Erro ao reordenar as atividades.',
        });
      })
      .finally(() => setLoadingReorder(false));
  }

  async function handleSyncSuccessors(data: any): Promise<void> {
    setLoadingSyncOrders(true);
    /* console.log(data); */
    try {
      const validation = Yup.object().shape({
        successors: Yup.array().of(Yup.string()),
      });

      validation.validate(data);

      await api.put(`activity/sucessors/${selectedActivity.id}`, data, {
        params: {
          order: selectedActivity.order,
          sucessors: data.successors,
        },
      });

      setDemand((state) => ({
        ...state,
        activities: state?.activities?.map((act) => {
          return act.id === selectedActivity.id
            ? {
                ...act,
                order: selectedActivity.order,
                successors: data.successors.map((suc: any) => {
                  return {
                    order: demand.activities?.find((a) => a.id === Number(suc))
                      ?.order,
                    id: Number(suc),
                  };
                }),
              }
            : act;
        }),
      }));

      addToast({
        position: 'top-right',
        isClosable: true,
        status: 'success',
        title: 'Sucessoras atualizadas!',
        description: `As Sucessoras da atividade ${selectedActivity.order} - ${selectedActivity.name} foram atualizadas com sucesso`,
      });
      onCloseSuccessors();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRefSyncSuccessors.current?.setErrors(errors);
        /* console.log(err); */
        return;
      }

      addToast({
        position: 'top-right',
        status: 'error',
        title: 'Erro em Atividades Sucessoras',
        description:
          err.response?.data?.error ||
          'Ocorreu um erro ao editar atividades sucessoras',
        isClosable: true,
      });
    } finally {
      setLoadingSyncOrders(false);
    }
  }

  function handleActivityCreate(): void {
    history.push({
      pathname: `/atividade/cadastrar/${demandId}`,
      search: pg ? `pg=${pg}` : '',
    });
  }

  function handleEditActivity(activityId: number): void {
    history.push({
      pathname: `/atividade/editar/${activityId}`,
      search: `dem=${demandId}${pg ? `&pg=${pg}` : ''}`,
    });
  }

  const handleOpenModalHide = useCallback(
    (id: number, name: string, hide?: boolean) => {
      setActivityHidden({ id, name, hide });
      onOpenHide();
    },
    [onOpenHide],
  );

  function handleHideActivity(activityId?: number, hide?: boolean): void {
    setLoadingHideActivity(true);
    // setLoaded(false);
    // handleReorder();

    api
      .patch(`activity/hide/${activityId}`, {
        hide: hide ? 0 : 1,
      })

      .then(() => {
        setDemand((state) => ({
          ...state,
          activities: state.activities.map((act) => {
            return act.id === activityId
              ? {
                  ...act,
                  deleted_at: hide ? undefined : currentDate,
                }
              : act;
          }),
        }));

        hide
          ? setReloadActivities((state) => state + 1)
          : setActivitiesEnabled((state) =>
              state.filter((atv) => atv.id !== activityId),
            );

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Atividade desativada com sucesso!',
          description: 'A atividade foi desativada.',
          isClosable: true,
        });
      })
      .catch((err) => {
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Não foi possível desativar a atividade',
          description:
            err.response?.data?.error ||
            'Ocorreu um erro ao tentar desativar a atividade',
          isClosable: true,
        });
      })
      .finally(() => {
        onCloseHide();
        setLoadingHideActivity(false);
        // setLoaded(true);
      });
  }

  const handleOpenModalDelete = useCallback(
    (id: number, name: string) => {
      setActivityDelete({ id, name });
      onOpenDelete();
    },
    [onOpenDelete],
  );

  const handleSubmitDelete = useCallback(() => {
    setLoadingDeleteActivity(true);

    api
      .delete(`activity/${activityDelete?.id}`)
      .then(() => {
        const newActivities: Activity[] = [];

        // console.log(demand.activities);

        demand.activities.forEach((activ) => {
          if (activ.id !== activityDelete?.id) {
            newActivities.push({
              ...activ,
              successors: activ.successors?.filter(
                (succes) => succes.id !== activityDelete?.id,
              ),
            });
          }
        });

        setDemand((state) => ({
          ...state,
          activities: newActivities,
        }));

        /* let teste;

        setDemand((state) => ({
          ...state,
          activities: state.activities
            .filter((activ) => {
              return activ.id !== activityDelete?.id;
            })
            .map((suc) => {
              teste = suc.successors?.filter(
                (succes) => succes.id !== activityDelete?.id,
              );
              return {
                ...suc,
                successors: suc.successors?.filter(
                  (succes) => succes.id !== activityDelete?.id,
                ),
              };
            }),
        }));
        console.log(teste); */

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'A atividade apagada com sucesso',
          description: `A atividade ${activityDelete?.name} foi apagada`,
        });
      })
      .catch((err) => {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          duration: 7000,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Atividade não deletada!',
          description:
            'Erro ao deletar a atividade, Verifique se a atividade já esta sendo utilizada em algum planejamento',
        });
      })
      .finally(() => {
        setLoadingDeleteActivity(false);
        onCloseDelete();
      });
  }, [addToast, onCloseDelete, activityDelete, demand.activities]);

  return (
    <Box pos="relative" w="full">
      <Container>
        <SectionHeader
          title="Atividades"
          /* loading={!loaded} */
          pagename="Lista as atividades da demanda selecionada"
          goBackLink={goDemand}
        >
          <Flex flexDirection="column" mt={3}>
            <Button
              variant="outline"
              colorScheme="green"
              isLoading={!loaded}
              onClick={() => handleActivityCreate()}
            >
              <FiPlus />
              <Text ml={1}>Cadastrar Atividade</Text>
            </Button>
          </Flex>
        </SectionHeader>
      </Container>

      {loaded && (
        <Box>
          <Flex flexDirection={['column', 'row']} mb={1.5}>
            <Highlights
              data={[
                { icon: FiClipboard, label: 'Demanda:', text: demand?.name },
                {
                  icon: FiClock,
                  label: 'Duração da demanda:',
                  text: demand?.total_duration_activities,
                },
                {
                  icon: FiAlignLeft,
                  label: 'Descrição:',
                  text: demand?.description,
                },
              ]}
            />
          </Flex>

          <Flex justifyContent="space-between">
            {!!demand?.activities?.length && (
              <Button
                mb={5}
                variant="outline"
                colorScheme="blue"
                isLoading={loadingReorder}
                onClick={() => handleReorder()}
              >
                <FaSortNumericDown />
                <Text ml={1}>Reordenar Atividades</Text>
              </Button>
            )}

            {demand.activities?.find((state) => state.deleted_at) && (
              <Box>
                <ToggleCustom
                  toogleAdjust={() =>
                    setShowHiddenActivities((state) => !state)
                  }
                  defaultIsChecked={showHiddenActivities}
                  // loading={loadingHideActivity}
                  tittle="Atividades desativadas"
                  id="hideActivity"
                  icon={<FaEye />}
                />
              </Box>
            )}
          </Flex>
        </Box>
      )}

      {!loaded && (
        <Box
          pos="absolute"
          top={0}
          left={0}
          w="full"
          h="500px"
          bg="rgba(255,255,255,0.5)"
        >
          <Loading />
        </Box>
      )}
      {loaded && (
        <Table theadData={tableTitles}>
          {demand.activities
            ?.filter(
              (atv) =>
                (!showHiddenActivities && !atv.deleted_at) ||
                showHiddenActivities,
            )
            .map((demAtv, i) => {
              return (
                <Box as="tr" key={demAtv.id} _hover={{ bg: 'gray.50' }}>
                  <Box
                    as="td"
                    py={3}
                    pl="15px"
                    opacity={demAtv.deleted_at ? '0.3' : '1'}
                  >
                    {demAtv.order}
                  </Box>
                  <Box as="td" py={3} opacity={demAtv.deleted_at ? '0.3' : '1'}>
                    {demAtv.name}
                  </Box>
                  <Box
                    as="td"
                    flexDirection="row"
                    py={3}
                    opacity={demAtv.deleted_at ? '0.3' : '1'}
                  >
                    <IconButton
                      aria-label="Clique para ver a descrição"
                      title="Clique para ver a descrição"
                      icon={<FiBookOpen />}
                      size="md"
                      variant="solid"
                      background="#FFFF"
                      color="#323c79"
                      boxShadow="0 2px 5px 0 rgb(0 0 0 / 16%)"
                      mr={[2, 3]}
                      mb={[2, 0]}
                      onClick={() =>
                        setModalPopData({
                          name: demAtv.name,
                          description: demAtv?.description || '',
                          pop: '',
                          pop_files: [],
                        })
                      }
                    />
                    {(demAtv.pop || !!demAtv.pop_files?.length) && (
                      <IconButton
                        aria-label="Clique para ver o POP"
                        title="Clique para ver o POP"
                        icon={<FiBook />}
                        size="md"
                        variant="solid"
                        background="#FFFF"
                        color="#323c79"
                        boxShadow="0 2px 5px 0 rgb(0 0 0 / 16%)"
                        mr={[2, 0]}
                        mb={[2, 0]}
                        onClick={() =>
                          setModalPopData({
                            name: demAtv.name,
                            pop: demAtv?.pop || '',
                            pop_files: demAtv.pop_files || [],
                          })
                        }
                      />
                    )}
                  </Box>
                  <Box as="td" py={3} opacity={demAtv.deleted_at ? '0.3' : '1'}>
                    <Box>
                      <Tooltip label={demAtv.type.name} placement="top">
                        <IconButton
                          aria-label={demAtv.type.name}
                          icon={handleGetIcon(demAtv.type.id)}
                          size="md"
                          variant="solid"
                          color="#323c79"
                          background="none"
                          ml={3}
                          _focus={{
                            boxShadow: 'none',
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box
                    as="td"
                    py={3}
                    textAlign="center"
                    opacity={demAtv.deleted_at ? '0.3' : '1'}
                  >
                    <Tooltip label={demAtv.character.name} placement="top">
                      <IconButton
                        aria-label={demAtv.character.name}
                        icon={<FaInfoCircle />}
                        size="md"
                        variant="solid"
                        color="#323c79"
                        boxShadow="0 2px 5px 0 rgb(0 0 0 / 16%)"
                        mr={3}
                        _focus={{
                          boxShadow: 'none',
                        }}
                      />
                    </Tooltip>
                  </Box>
                  <Flex
                    as="td"
                    py={3}
                    justifyContent="space-evenly"
                    opacity={demAtv.deleted_at ? '0.3' : '1'}
                  >
                    {demAtv.attach_file ? (
                      <Tooltip label="Anexar arquivos" placement="top">
                        <IconButton
                          aria-label="Anexar arquivos"
                          title="Anexar arquivos"
                          icon={<FaPaperclip />}
                          size="md"
                          variant="solid"
                          color="#323c79"
                          boxShadow="0 2px 5px 0 rgb(0 0 0 / 16%)"
                          _focus={{
                            boxShadow: 'none',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Box
                        width="40px"
                        height="40px"
                        fontSize="30px"
                        textAlign="center"
                      >
                        <Text>-</Text>
                      </Box>
                    )}
                    {demAtv.send_email ? (
                      <Tooltip
                        label="Enviar e-mail"
                        placement="top"
                        justifyContent="end"
                      >
                        <IconButton
                          aria-label="Enviar e-mail"
                          title="Enviar e-mail"
                          icon={<FaEnvelope />}
                          size="md"
                          variant="solid"
                          color="#323c79"
                          boxShadow="0 2px 5px 0 rgb(0 0 0 / 16%)"
                          _focus={{
                            boxShadow: 'none',
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Box
                        fontSize="30px"
                        width="40px"
                        height="40px"
                        textAlign="center"
                      >
                        <Text>-</Text>
                      </Box>
                    )}
                  </Flex>
                  <Box as="td" py={3} opacity={demAtv.deleted_at ? '0.3' : '1'}>
                    {demAtv.duration}
                  </Box>

                  {!demAtv.deleted_at ? (
                    <>
                      <Box
                        as="td"
                        py={3}
                        opacity={demAtv.deleted_at ? '0.3' : '1'}
                      >
                        {i < activitiesEnabled.length - 1 && (
                          <Flex alignItems="center">
                            <Tooltip
                              label="Atividades Sucessoras"
                              placement="top"
                            >
                              <IconButton
                                aria-label="Definir Atividades Sucessoras"
                                icon={<FiMenu size={14} />}
                                size="xs"
                                variant="solid"
                                colorScheme="green"
                                /* boxShadow="0 2px 5px 0 rgb(0 0 0 / 16%)" */
                                mr={[2, 3]}
                                mb={[2, 0]}
                                onClick={() => {
                                  setSelectedActivity(demAtv);
                                  onOpenSuccessors();
                                }}
                              />
                            </Tooltip>
                            {demAtv?.successors?.length ? (
                              <Wrap alignItems="center">
                                {demAtv.successors.map((atv) => {
                                  const atvName = demand.activities?.find(
                                    (a) => a.id === atv.id,
                                  )?.name;

                                  return (
                                    <WrapItem key={atv.id}>
                                      <Tooltip label={atvName} key={atv.id}>
                                        <Button
                                          size="sm"
                                          variant="outline"
                                          colorScheme="gray"
                                          /* borderWidth={1} */
                                          p={0}
                                        >
                                          <Text fontSize="sm">{atv.order}</Text>
                                        </Button>
                                      </Tooltip>
                                    </WrapItem>
                                  );
                                })}
                              </Wrap>
                            ) : (
                              <Text color="gray.600" fontSize="14px">
                                Nenhuma
                              </Text>
                            )}
                          </Flex>
                        )}
                      </Box>
                    </>
                  ) : (
                    <Box as="td" />
                  )}

                  <Box as="td" py={3} className="row">
                    <Flex justifyContent="flex-end">
                      <Tooltip
                        label={demAtv.deleted_at ? 'Ativar' : 'Desativar'}
                      >
                        <Box>
                          <IconButton
                            aria-label="Desativar"
                            icon={
                              demAtv.deleted_at ? <FaEye /> : <FaEyeSlash />
                            }
                            variant="ghost"
                            bg={demAtv.deleted_at ? 'blue.50' : 'gray.50'}
                            ml={[0, 2]}
                            colorScheme={demAtv.deleted_at ? 'blue' : 'black'}
                            onClick={() =>
                              demand.qtd_atendimento
                                ? onOpenAlert()
                                : handleOpenModalHide(
                                    demAtv.id,
                                    demAtv.name,
                                    !!demAtv.deleted_at,
                                  )
                            }
                            // disabled={!!demand.qtd_atendimento}
                          />
                        </Box>
                      </Tooltip>

                      {!demAtv.deleted_at && (
                        <Tooltip label="Editar">
                          <IconButton
                            aria-label="Editar"
                            icon={<FiEdit />}
                            variant="ghost"
                            bg="yellow.50"
                            ml={[0, 2]}
                            colorScheme="yellow"
                            onClick={() => handleEditActivity(demAtv.id)}
                          />
                        </Tooltip>
                      )}

                      <Tooltip label="Deletar">
                        <IconButton
                          aria-label="Deletar"
                          icon={<FiTrash />}
                          variant="ghost"
                          bg="red.50"
                          ml={[0, 2]}
                          colorScheme="red"
                          onClick={() =>
                            handleOpenModalDelete(demAtv.id, demAtv.name)
                          }
                        />
                      </Tooltip>
                    </Flex>
                  </Box>
                </Box>
              );
            })}
        </Table>
      )}

      {loaded && (
        <ModalChakra
          title="Definir Sucessoras"
          onClose={() => {
            onCloseSuccessors();
            setSelectedActivity({} as Activity);
          }}
          isOpen={isOpenSuccessors}
          onSubmit={() => formRefSyncSuccessors.current?.submitForm()}
          isLoading={loadingSyncOrders}
          size="2xl"
        >
          <Text mb={4} fontWeight={600} fontSize="xl">
            {`${selectedActivity.order} - ${selectedActivity.name}`}
          </Text>
          <Form
            ref={formRefSyncSuccessors}
            onSubmit={(data) => handleSyncSuccessors(data)}
            initialData={{
              order: selectedActivity.order,
              successors: selectedActivity.successors?.map((selected) =>
                selected.id.toString(),
              ),
            }}
          >
            <CheckboxInput
              name="successors"
              options={getPossibleSuccessors(selectedActivity).map((suc) => {
                return {
                  id: suc.id.toString(),
                  value: suc.id.toString(),
                  label: `${suc.order} - ${suc.name}`,
                };
              })}
              enableParentOption
              textParentOption="Marcar todas"
            />
          </Form>
        </ModalChakra>
      )}

      {!!modalPopData && (
        <Pop
          data={modalPopData}
          onClosePop={() => setModalPopData(undefined)}
        />
      )}

      <AlertDialog
        title="Deletar Atividade?"
        description={`Tem certeza que deseja deletar a atividade ${activityDelete?.name}`}
        isOpen={isOpenDelete}
        isLoading={loadingDeleteActivity}
        leastDestructiveRef={cancelDeleteRef}
        onClose={() => {
          setActivityDelete(undefined);
          onCloseDelete();
        }}
        onSubmit={handleSubmitDelete}
      />
      <AlertDialog
        title={`${activityHidden?.hide ? 'Ativar' : 'Desativar'} atividade?`}
        description={`Tem certeza que desaja ${
          activityHidden?.hide ? 'ativar' : 'desativar'
        } a atividade ${activityHidden?.name}`}
        isOpen={isOpenHide}
        isLoading={loadingHideActivity}
        leastDestructiveRef={cancelHideRef}
        onClose={() => {
          setActivityHidden(undefined);
          onCloseHide();
        }}
        onSubmit={() =>
          handleHideActivity(activityHidden?.id, activityHidden?.hide)
        }
        VariantColor="blue"
        submitButtonColor="blue.500"
      />

      <ModalChakra
        title="Não é possível desativar essa atividade."
        onClose={onCloseAlert}
        isOpen={isOpenAlert}
        size="2xl"
      >
        <Text>
          Não é possível desativar atividades nesta demanda, pois ainda há
          atendimentos em aberto que a utilizam.
        </Text>
      </ModalChakra>
    </Box>
  );
};

export default DemandActivitiesList;
