import React, { useCallback, useState } from 'react';
import { FiExternalLink, FiPlusSquare } from 'react-icons/fi';
import ReactLoading from 'react-loading';

import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react';

import { useAuth } from '~/hooks/auth';
import api from '~/shared/services/api';

import {
  IActivity,
  FilterData,
  ModalFilter,
  IAttendance,
  AttendanceDTO,
} from '../../pages/IndividualPerformance';
import { TrBox } from '../../pages/IndividualPerformance/styles';

interface Props {
  showTime: boolean;
  activity: IActivity;
  onOpenModal(data: ModalFilter): void;
  comparativeEmployee?: string;
  filter: FilterData;
}

const TableItemIndividualPerformance: React.FC<Props> = ({
  activity,
  showTime,
  onOpenModal,
  filter,
}) => {
  const addToast = useToast();

  const [loading, setLoading] = useState(false);

  const [attendances, setAttendances] = useState<IAttendance[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  const loadAttendances = useCallback(() => {
    setLoading(true);

    api
      .get<AttendanceDTO>(
        `reports/personal-performance/comparative/attendances/${activity.activity_id}`,
        {
          params: {
            employee_start_date: filter.employee_start_date,
            employee_end_date: filter.employee_end_date,
            general_start_date: filter.general_start_date,
            general_end_date: filter.general_end_date,
            employee: filter.employee,
            comparative_employee: filter.comparative_employee,
            client_company: filter.client_company,
            page: 1,
          },
        },
      )
      .then((response) => {
        setAttendances(response.data?.attendances);
        setTotalPages(response.data?.total_pages);
        setLoading(false);
      })
      .catch((err) => {
        const { error } = err.response.data;

        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro ao carregar dados.',
            isClosable: true,
          });
          setLoading(false);

          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao carregar lista, tente novamente!',
          description: error,
        });

        setLoading(false);
      });
  }, [activity.activity_id, filter, addToast]);

  const handleLoadAttendace = useCallback(() => {
    if (attendances.length) return;

    loadAttendances();
    // onOpenModal();
  }, [loadAttendances, attendances]);

  const handleOpenModal = useCallback(() => {
    onOpenModal({ ...filter, activity_id: activity.activity_id });
  }, [onOpenModal, activity.activity_id, filter]);

  return (
    <AccordionItem>
      <AccordionButton p={0} onClick={handleLoadAttendace}>
        <Box
          as="table"
          w="full"
          style={{
            borderSpacing: 0,
            borderCollapse: 'collapse',
            border: '1px solid transparent',
          }}
        >
          <Box as="tbody" w="full" p={4} fontSize="md" pr={0}>
            <TrBox as="tr" className="activities">
              <Box as="td" width="auto" minWidth="360px">
                <Flex alignItems="center">
                  <IconButton
                    aria-label="Ver atendimentos"
                    title="Ver atendimentos"
                    icon={<FiPlusSquare />}
                    colorScheme="gray"
                    variant="ghost"
                    size="sm"
                    mr={2}
                  />
                  <Text
                    isTruncated
                    maxWidth="298px"
                    title={activity.activity_name}
                  >
                    {activity.activity_name}
                  </Text>
                </Flex>
              </Box>
              <Box as="td" width="150px" textAlign="right" px="10px">
                {showTime
                  ? activity.general_average_allocation
                  : activity.general_count_devolutions}
              </Box>
              <Box as="td" width="150px" textAlign="right" px="10px">
                {showTime ? (
                  <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    width="full"
                  >
                    <Box
                      as="span"
                      display="inline-block"
                      backgroundColor={activity.result_general_color}
                      width="14px"
                      height="14px"
                      borderRadius="100%"
                    />
                    <Box
                      as="span"
                      lineHeight={0}
                      mt="2px"
                      mx={2}
                      color={
                        activity.result_general_color === 'red.500' &&
                        activity.result_opponent_color === 'red.500'
                          ? activity.result_general_color
                          : ''
                      }
                    >
                      {activity.personal_average_allocation}
                    </Box>
                    {activity.result_opponent_color && (
                      <Box
                        as="span"
                        display="inline-block"
                        backgroundColor={activity.result_opponent_color}
                        width="14px"
                        height="14px"
                        borderRadius="100%"
                      />
                    )}
                  </Flex>
                ) : (
                  <Box
                    as="span"
                    mx={2}
                    width="20px"
                    display="inline-block"
                    textAlign="center"
                  >
                    {activity.personal_count_devolutions}
                  </Box>
                )}
              </Box>
              <Box as="td" width="150px" textAlign="right" px="10px">
                {showTime
                  ? activity.opponent_average_allocation
                  : activity.opponent_count_devolutions}
              </Box>
            </TrBox>
          </Box>
        </Box>
      </AccordionButton>
      <AccordionPanel pb={4} pr={0}>
        <Box
          as="table"
          w="full"
          style={{
            borderSpacing: 0,
            borderCollapse: 'collapse',
            border: '1px solid transparent',
          }}
        >
          <Box as="tbody" w="full" p={4} fontSize="md">
            {loading ? (
              <TrBox as="tr" className="activities sub">
                <Box as="td" aria-colspan={4} width="100%" minWidth="200px">
                  <Box as="span" display="flex" alignItems="center">
                    <ReactLoading
                      type="spin"
                      height="100%"
                      width={20}
                      color="#718096"
                    />
                    <Text ml={2}>Carregando...</Text>
                  </Box>
                </Box>
              </TrBox>
            ) : (
              <>
                {attendances?.map((attendance) => {
                  return (
                    <TrBox
                      key={attendance.data.id}
                      as="tr"
                      className="activities sub"
                    >
                      <Box as="td" width="auto" minWidth="200px">
                        <Flex alignItems="center">
                          <a
                            href={`${process.env.REACT_APP_OLDMYIPAC}/planejamento/index/${attendance.data.demanda_id}?aten=${attendance.data.id}&pg=1`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              aria-label="Ver atendimento"
                              title="Ver atendimento"
                              icon={<FiExternalLink />}
                              variant="ghost"
                              colorScheme="blue"
                              mr={2}
                            />
                          </a>
                          <Flex flexDirection="column">
                            <Flex alignItems="center">
                              <Text>Atendimento {attendance.data.id}</Text>
                              <Text
                                isTruncated
                                width="150px"
                                ml={2}
                                pl={2}
                                borderLeftWidth={1}
                                title={attendance.data.nome_fantasia}
                              >
                                {attendance.data.nome_fantasia}
                              </Text>
                            </Flex>
                            {attendance.data.competencia && (
                              <Text fontSize="11px">
                                Competencia: {attendance.data.competencia}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      </Box>
                      <Box as="td" width="150px" textAlign="right" px="10px">
                        {showTime
                          ? attendance.activities.general_total_allocation
                          : attendance.activities.general_total_devolution}
                      </Box>
                      <Box as="td" width="150px" textAlign="right" px="10px">
                        {showTime
                          ? attendance.activities.employee_total_allocation
                          : attendance.activities.employee_total_devolution}
                      </Box>
                      <Box as="td" width="150px" textAlign="right" px="10px">
                        {showTime
                          ? attendance.activities.opponent_total_allocation
                          : attendance.activities.opponent_total_devolution}
                      </Box>
                    </TrBox>
                  );
                })}
              </>
            )}
          </Box>
        </Box>

        {totalPages > 1 && (
          <Flex alignItems="center" justifyContent="center" mt={3}>
            <Button
              variant="ghost"
              colorScheme="gray"
              size="xs"
              onClick={handleOpenModal}
            >
              Ver mais
            </Button>
          </Flex>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default TableItemIndividualPerformance;
