// import useCan from '~/hooks/useCan';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  FaTimesCircle,
  FaPlayCircle,
  FaPauseCircle,
  FaCheckCircle,
  FaFileExcel,
  FaStopCircle,
} from 'react-icons/fa';
import {
  FiFilter,
  FiTrash,
  FiEdit,
  FiClock,
  FiArchive,
  FiExternalLink,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  IconButton,
  useToast,
  Text,
  Heading,
  useDisclosure,
  Radio,
  RadioGroup,
  HStack,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Center,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Empty } from 'antd';
import { format, parseISO } from 'date-fns';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import useLinkStatusAttendance, {
  useUrlLinkStatusAttendance,
} from '~/hooks/useLinkStatusAttendance';
import { User } from '~/modules/accessControl/@types/user';
import { PopContent } from '~/modules/activityExecution/components/Pop/styles';
import { BoxCompany } from '~/modules/management/pages/CompanyClient/styles';
import AvatarCustom from '~/shared/components/AvatarCustom';
import InputChakra from '~/shared/components/InputChakra';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import RangeDateMonthly from '~/shared/components/RangeDateMonthly';
import Search from '~/shared/components/Search';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import Pagination, {
  IPaginationProps,
} from '../../../../shared/components/Pagination';
import BadgeStatusAttendance from '../../components/BadgeStatusAttendance';
import BadgeStatusInfo from '../../components/BadgeStatusInfo';
import LinkBadgeStatusAttendance from '../../components/LinkBadgeStatusAttendance';
import {
  GroupBadge,
  TableTheadFixed,
  BoxRadio,
  IconButtonStatus,
} from './styles';

interface FormData {
  demand: number;
  year: number;
  employee: number;
}

interface FormattedDataCompany {
  'Cód. Alterdata': number;
  Empresas: string;
  Jan?: string;
  Fev?: string;
  Mar?: string;
  Abr?: string;
  Mai?: string;
  Jun?: string;
  Jul?: string;
  Ago?: string;
  Set?: string;
  Out?: string;
  Nov?: string;
  Dez?: string;
}

interface UserSelect extends User {
  value: number;
  label: string;
}

interface MonthlyAttedanceProps extends IPaginationProps {
  employees: UserSelect[];
  data: MonthlyAttendanceData[];
}

interface MonthlyAttendanceData {
  id: number;
  id_alterdata: number;
  company_name: string;
  count_plannings_employee_total: number;
  status_comp: SituationAttedance[];
}

interface SituationAttedance {
  attendance_id: number;
  archived?: boolean;
  competence: string;
  month_competence: number;
  status_id: number;
  created_at: string;
  status: string;
  status_color: string;
  status_original_color: string;
  count_delay: number;
  count_planning_delay: number;
  count_plannings_employee: number;
}

interface ExtraSituationAttendance {
  id_alterdata: number;
  company_name: string;
  competence: string;
  attendances: SituationAttedance[];
}

interface SelectData extends SelectYear {
  competences: number[];
  competencesSelect: SelectYear[];
}

interface SelectYear {
  id: number;
  name: string;
  value: number;
  label: string;
}

/* interface MonthlyDemandProps{
  filter: SelectData[];
} */

interface InitialSelects {
  demands: SelectData[];
}

interface MonthlyDemandsReport {
  period: Period[];
  companies: Companies[];
  search?: string;
  pagination: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    prev_page_url?: number;
    to: number;
    total: number;
  };
}

interface Companies {
  company_id: number;
  company_name: string;
  attendances: Attendances[];
}

export interface Attendances {
  attendance_id: number;
  demand_id?: number;
  archived?: boolean;
  status_id: number;
  created_at: string;
  status: string;
  status_cor: string;
  date_sets_week: string;
  month: number;
  year: number;
  year_reference: number;
  week_year: number;
  day_week: number;
}

interface Period {
  year: number;
  months: Month[];
}

interface Month {
  month: number;
  month_name: string;
  weeks: Array<{
    year: number;
    month_name: string;
    year_reference: number;
    month: number;
    week_year: number;
    start_week: number;
    end_week: number;
  }>;
}

interface SelectedEmployee {
  id: number;
  name: string;
  avatar?: string;
}

const MonthlyDemands: React.FC = () => {
  // const can = useCan();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const can = useCan();

  const addToast = useToast();
  const linkStatus = useLinkStatusAttendance();
  const linkStatusUrl = useUrlLinkStatusAttendance();

  const [selectedDemand, setSelectedDemand] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState<
    SelectedEmployee | undefined
  >(() => {
    if (!can('list-user')) {
      return {
        id: Number(user.id),
        name: user.name,
        avatar: user.avatar,
      };
    }
    return undefined;
  });
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [demandFilters, setDemandFilters] = useState<SelectData[]>([]);

  const [
    monthlyAttendances,
    setMonthlyAttendances,
  ] = useState<MonthlyAttedanceProps>({} as MonthlyAttedanceProps);
  const [monthlyAttendancesExcel, setMonthlyAttendancesExcel] = useState<
    MonthlyAttendanceData[]
  >([]);

  const [
    extraStatusAttendances,
    setExtraStatusAttendances,
  ] = useState<ExtraSituationAttendance>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [
    monthlyDemandsData,
    setMonthlyDemandsData,
  ] = useState<MonthlyDemandsReport>({} as MonthlyDemandsReport);

  const monthNames = useMemo((): {
    title: string;
    align?: 'center';
  }[] => {
    return [
      {
        title: 'Jan',
        align: 'center',
      },
      {
        title: 'Fev',
        align: 'center',
      },
      {
        title: 'Mar',
        align: 'center',
      },
      {
        title: 'Abr',
        align: 'center',
      },
      {
        title: 'Mai',
        align: 'center',
      },
      {
        title: 'Jun',
        align: 'center',
      },
      {
        title: 'Jul',
        align: 'center',
      },
      {
        title: 'Ago',
        align: 'center',
      },
      {
        title: 'Set',
        align: 'center',
      },
      {
        title: 'Out',
        align: 'center',
      },
      {
        title: 'Nov',
        align: 'center',
      },
      {
        title: 'Dez',
        align: 'center',
      },
    ];
  }, []);

  const tableTitles: THeadProps[] = [
    {
      title: 'Empresas',
    },
  ].concat(monthNames);

  useEffect(() => {
    // Necessário porque quando recompila o código o useEffect será chamado novamente
    // setDemandFilters([]);
    setLoading(true);
    function loadFilters(): void {
      api
        .get<SelectData[]>('/reports/monthly-demands/filters')
        .then((response) => {
          // console.log(response.data);
          const { data } = response;

          setDemandFilters(
            data.map((dem) => {
              const { id, name, competences } = dem;
              return {
                id,
                name,
                label: name,
                value: id,
                competences,
                competencesSelect: competences.map((comp) => {
                  return {
                    id: comp,
                    name: comp.toString(),
                    label: comp.toString(),
                    value: comp,
                  };
                }),
              };
            }),
          );
        })
        .finally(() => setLoading(false));
    }

    loadFilters();
  }, [addToast]);

  const getStatusAttendences = useCallback(
    (
      numPage: number,
      demandId: number,
      competence: number,
      employeeId?: number,
    ): void => {
      setReportLoading(true);

      const params: {
        page: number;
        competence: number;
        employee_id?: number;
      } = { page: numPage, competence };

      if (employeeId) {
        params.employee_id = employeeId;
      }

      api
        .get<MonthlyAttedanceProps>(`reports/monthly-demands/${demandId}`, {
          params,
        })
        .then((response) => {
          const atenPage = response.data;
          const { data } = atenPage;

          if (employeeId) {
            const foundEmployee = atenPage.employees?.find(
              (emp) => emp.id === employeeId,
            );

            if (foundEmployee) {
              setSelectedEmployee({
                id: employeeId,
                name: foundEmployee.name,
                avatar: foundEmployee?.avatar,
              });
            }
          } else {
            setSelectedEmployee(undefined);
          }

          // console.log(atenPage);

          // console.log(employees);
          // handleExportTable();
          setMonthlyAttendances({
            ...atenPage,
            data: atenPage.data.map((dt) => {
              return {
                ...dt,
                status_comp: dt.status_comp.map((sc) => {
                  return {
                    ...sc,
                    created_at: format(
                      parseISO(sc.created_at),
                      "dd/MM/yyyy' às 'HH:mm:ss",
                    ),
                  };
                }),
              };
            }),
            employees: atenPage.employees?.map((emp) => {
              return { ...emp, value: emp.id, label: emp.name };
            }),
          });
          setSelectedYear(competence);

          if (!data.length && employeeId) {
            // Necessário ficar aqui para o estado que exibe os dados na tabela setar vazio quando a condição for verdadeira
            addToast({
              position: 'top-right',
              title: can('list-user')
                ? 'O funcionário selecionado não possui atendimentos no ano de competência selecionado'
                : 'Você não tem atendimentos na demanda ou ano selecionado',
              status: 'warning',
              isClosable: true,
            });

            /* Necessário fazer verificação pq se o usuário não tem permissão de listar usuários, apenas dados dele serão buscados
            portanto não é necessário limpar o funcionário selecionado, o que faria com o que na próxima busca, não considerasse o funcionário
            para trazer os dados */
            if (can('list-user')) setSelectedEmployee(undefined);

            // return;
          }

          /* Se adicionasse aqui o setEmployeeActivity a cada página seria necessário atualizar o estado, enquanto os
          useMemos employeeAvatar e employeeName, buscam o valor apenas uma vez até que se altere o usuário selecionado */
          /* if (employeeId) {
            const foundEmployee = employees?.find(
              (emp) => emp.id === employeeId,
            );

            if (foundEmployee) {
              setSelectedEmployee({
                id: employeeId,
                name: foundEmployee.name,
                avatar: foundEmployee?.avatar,
              });
            }
          } else {
            setSelectedEmployee(undefined);
          } */
        })
        .finally(() => setReportLoading(false));
    },
    [
      addToast,
      can,
      /* handleExportTable */
    ],
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          demand: Yup.string().required('Necessário escolher uma demanda'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const userId = !can('list-user') ? Number(user.id) : undefined;

        getStatusAttendences(
          1,
          data.demand,
          data.year,
          data.employee ? Number(data.employee) : userId,
        );

        // formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [getStatusAttendences, can, user.id],
  );

  const employeeAvatar: string | undefined = useMemo(() => {
    // console.log('buscou avatar');

    if (selectedEmployee) return selectedEmployee?.avatar;

    return user.avatar;
  }, [selectedEmployee, user]);

  const employeeName: string | undefined = useMemo(() => {
    // console.log('buscou nome func');

    if (selectedEmployee) return selectedEmployee?.name;

    return user.name;
  }, [selectedEmployee, user]);

  function getBgCell(statusId: number): string {
    let bg = '';

    switch (statusId) {
      case 10:
        bg = 'FFFFC000';
        break;
      case 11:
        bg = 'FF538DD5';
        break;
      case 12:
        bg = 'FF00B050';
        break;
      case 13:
        bg = 'FFE53E3E';
        break;

      default:
        bg = 'FF000001';
        break;
    }

    return bg;
  }

  const handleExportTable = useCallback(
    (dataAttendances: MonthlyAttendanceData[]): void => {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      // Fazer um for utilizando o range para o header

      const demandName = demandFilters.find((dem) => dem.id === selectedDemand);
      const ws: any = {};

      let sheetTitle = `${demandName?.name} / ${selectedYear}`;

      if (selectedEmployee) sheetTitle += ` (${selectedEmployee.name})`;

      const headerHeight = 2; // Linhas que o titulo da tabela ocupa, os dados iniciarão na linha seguinte

      ws.A1 = {
        v: sheetTitle,
        s: {
          font: { bold: true, sz: 18 },
        },
      };

      const rangeData = {
        s: { c: 0, r: 0 },
        e: { c: 13, r: dataAttendances.length + headerHeight },
      };
      const rangeHeader = {
        s: { c: 0, r: headerHeight },
        e: { c: 13, r: headerHeight },
      };

      const borderStyle = {
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
        },
      };

      const cellStyles = {
        font: {
          sz: 14,
          color: { rgb: '#FF000000' },
          bold: true,
        },
      };

      const wscols: { wch: number }[] = [];
      // row
      for (let row = rangeHeader.s.r; row <= rangeHeader.e.r; row += 1) {
        for (let col = rangeHeader.s.c; col <= rangeHeader.e.c; col += 1) {
          const cellRef = XLSX.utils.encode_cell({ c: col, r: row });

          if (col === 0) {
            ws[cellRef] = { v: 'Cód. Alterdata', s: cellStyles };
            wscols.push({ wch: 20 });
          } else if (col === 1) {
            ws[cellRef] = { v: 'Empresas', s: cellStyles };
            wscols.push({ wch: 30 });
          } else {
            ws[cellRef] = {
              v: monthNames[col - 2].title,
              s: { ...cellStyles, alignment: { horizontal: 'center' } },
            };
            wscols.push({ wch: 15 });
          }
        }
      }
      ws['!cols'] = wscols;

      // row
      dataAttendances.forEach((comp, iComp) => {
        // const dataExcel: FormattedDataCompany = {} as FormattedDataCompany;

        const rowRef = iComp + (headerHeight + 1);

        const cellRefId = XLSX.utils.encode_cell({ c: 0, r: rowRef });
        const cellRefCompanies = XLSX.utils.encode_cell({ c: 1, r: rowRef });

        ws[cellRefId] = {
          v: comp.id_alterdata || '',
          s: { alignment: { horizontal: 'right' } },
        };
        ws[cellRefCompanies] = {
          v: comp.company_name,
          s: { font: { bold: true } },
        };

        // dataExcel['Cód. Alterdata'] = comp.id;
        // dataExcel.Empresas = comp.company_name;

        // column
        monthNames.forEach((month, iMonth) => {
          const nMonth = iMonth + 1;

          const statusComp = comp.status_comp.find(
            (sc) => sc.month_competence === nMonth,
          );

          const cellRefCompetence = XLSX.utils.encode_cell({
            c: 1 + nMonth,
            r: rowRef,
          });

          if (!statusComp) {
            // Necessário pois o cabeçalho da tabela é feito com o método json_to_sheet a partir dos índices do primeiro objeto do array
            ws[cellRefCompetence] = { v: '' };
          } else {
            const value =
              selectedEmployee && statusComp.count_plannings_employee > 0
                ? `${statusComp.status} (${selectedEmployee.name})`
                : statusComp.status;

            ws[cellRefCompetence] = {
              v: value,
              s: {
                alignment: {
                  horizontal: 'center',
                  vertical: 'center',
                  wrapText: 1,
                },
                /*   fill: {
                  patternType: 'solid',
                  fgColor: { rgb: getBgCell(statusComp.status_id) },
                  bgColor: { rgb: getBgCell(statusComp.status_id) },
                }, */
                font: {
                  color: { rgb: getBgCell(statusComp.status_id) },
                  bold: true,
                  underline: true,
                },
              },
            };
            if (statusComp.status_id !== 13) {
              const link = linkStatusUrl(
                selectedDemand,
                statusComp.attendance_id,
                statusComp.archived,
              );

              ws[cellRefCompetence].l = {
                Target: link,
                Tooltip: `Clique para ir para o atendimento ${statusComp.attendance_id}`,
              };

              if (
                statusComp.count_delay > 0 ||
                statusComp.count_planning_delay > 0
              ) {
                ws[cellRefCompetence].c = [];
                ws[cellRefCompetence].c.hidden = true;

                let comment = '';

                if (statusComp.count_delay > 0) {
                  comment += `${statusComp.count_delay} atraso(s) em Relação à data fatal \n`;
                }

                if (statusComp.count_planning_delay > 0) {
                  comment += `${statusComp.count_planning_delay} atraso(s) em relação ao planejamento`;
                }
                ws[cellRefCompetence].c.push({
                  a: 'MyIPAC',
                  t: comment,
                });
              }
            }
          }
        });
      });

      ws['!ref'] = XLSX.utils.encode_range(rangeData);
      ws['!autofilter'] = { ref: XLSX.utils.encode_range(rangeHeader) };
      /* ws['!freeze'] = {
        xSplit: '1',
        ySplit: '1',
        topLeftCell: 'B2',
        activePane: 'bottomRight',
        state: 'frozen',
      }; */

      // console.log(ws);
      // return;

      const wb = {
        Sheets: { 'Demandas Mensais': ws },
        SheetNames: ['Demandas Mensais'],
      };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      let filename = `Relatório de Demandas Mensais - ${demandName?.name} - ${selectedYear}`;

      if (selectedEmployee) filename += ` - ${selectedEmployee.name}`;

      FileSaver.saveAs(data, filename + fileExtension);
    },
    [
      monthNames,
      selectedDemand,
      linkStatusUrl,
      demandFilters,
      selectedYear,
      selectedEmployee,
    ],
  );

  const getStatusAttendencesExcel = useCallback((): void => {
    setExportLoading(true);

    api
      .get<MonthlyAttendanceData[]>(
        `reports/monthly-demands/${selectedDemand}`,
        {
          params: {
            competence: selectedYear,
            employee_id: selectedEmployee?.id,
          },
        },
      )
      .then((response) => {
        const { data } = response;

        if (!data.length) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title:
              'Não foi possível obter os dados para a geração do relatório',
          });
          return;
        }
        // setMonthlyAttendancesExcel(data);
        handleExportTable(data);

        // setSelectedDemand(demandId);
      })
      .finally(() => setExportLoading(false));
  }, [
    selectedDemand,
    selectedYear,
    selectedEmployee,
    handleExportTable,
    addToast,
  ]);

  return (
    <Box position="relative" width="full" height="full" minHeight="100vh">
      <SectionHeader
        title="Demandas Mensais"
        pagename="Relatório de demandas mensais"
        goBackLink="/dashboard"
      />

      {loading && (
        <Box
          pos="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          bg="rgba(255,255,255,0.5)"
          zIndex={6666}
        >
          <Loading />
        </Box>
      )}

      {!loading && (
        <>
          <Box>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <Flex
                flexDirection={['column', 'column', 'column', 'row']}
                justifyContent="space-between"
                width="full"
                mb={12}
              >
                <Flex
                  flexDirection="column"
                  width="full"
                  pr={[0, 0, 0, 4]}
                  mb={[4, 4, 4, 0]}
                >
                  <SelectChakra
                    name="demand"
                    label="Demanda"
                    placeholder="Selecione uma demanda"
                    options={demandFilters}
                    onChange={(el) => {
                      setSelectedDemand(Number(el.target.value));
                      setMonthlyAttendances({} as MonthlyAttedanceProps);
                    }}
                    isInline
                  />
                </Flex>

                <Flex flexDirection="column" width="full" pr={[0, 4]}>
                  <SelectChakra
                    help="Atenção: verifique se o ano filtrado corresponde ao desejado"
                    name="year"
                    label="Ano da demanda"
                    options={
                      demandFilters.find((dem) => dem.id === selectedDemand)
                        ?.competencesSelect || []
                    }
                    /* onChange={(el) => {
                      setSelectedYear(Number(el.target.value));
                    }} */
                    disabled={!selectedDemand}
                    isInline
                  />
                </Flex>
                <Box pt="23.19px">
                  <Button
                    colorScheme="blue"
                    size="lg"
                    onClick={() => formRef.current?.submitForm()}
                    px={[0, 4]}
                    mx={[0, 'auto']}
                    minWidth={['auto', '300px']}
                    width="full"
                  >
                    Filtrar
                  </Button>
                </Box>
              </Flex>
              {(reportLoading || !!monthlyAttendances?.data?.length) && (
                <Flex
                  flexDir={['column', 'row']}
                  mb={5}
                  justifyContent="space-between"
                  alignItems={['center', 'flex-end']}
                >
                  <Box
                    mb={['1.5rem', 0]}
                    alignItems="center"
                    display="inline-block"
                  >
                    <Text fontWeight={600}>Legenda:</Text>
                    <Box display="flex" mt={2}>
                      <Box>
                        <Flex alignItems="center">
                          <FaStopCircle color="#ED8936" size={15} />{' '}
                          <Text ml={1}>Pendente</Text>
                        </Flex>
                        <Flex alignItems="center">
                          <FaCheckCircle color="#38A169" size={15} />{' '}
                          <Text ml={1}>Concluido</Text>
                        </Flex>
                      </Box>
                      <Box ml={3}>
                        <Flex alignItems="center">
                          <FaPlayCircle color="#3182ce" size={15} />{' '}
                          <Text ml={1}>Em andamento</Text>
                        </Flex>
                        <Flex alignItems="center">
                          <FaTimesCircle color="#E53E3E" size={15} />{' '}
                          <Text ml={1}>Cancelado</Text>
                        </Flex>
                      </Box>
                    </Box>
                  </Box>

                  <Flex
                    flexDir={['column', 'row']}
                    alignItems={['center', 'flex-end']}
                  >
                    {/* Esse componente só será exibido se a pessoa tiver permissão */}
                    <Flex alignItems="center" mb={[4, 0]}>
                      {/* {!!selectedEmployee && page === 1 && reportLoading ? (
                        <AvatarCustom size="sm" />
                      ) : (
                        !!selectedEmployee && (
                          <AvatarCustom
                            src={selectedEmployee?.avatar}
                            name={selectedEmployee?.name}
                            size="sm"
                          />
                        )
                      )} */}
                      {!!selectedEmployee && (
                        <AvatarCustom
                          src={selectedEmployee?.avatar}
                          name={selectedEmployee?.name}
                          size="sm"
                        />
                      )}
                      {can('list-user') ? (
                        <Flex minWidth={['auto', '300px']} ml={2}>
                          <SelectChakra
                            name="employee"
                            sizeCustom="md"
                            placeholder="Funcionário"
                            options={monthlyAttendances.employees}
                            onChange={() => formRef.current?.submitForm()}
                            disabled={reportLoading}
                            isInline
                          />
                        </Flex>
                      ) : (
                        // provisorio, usuarios sem permissao devem conseguir filtrar apenas as demandas em que eles participam(se for possivel)
                        <>
                          {selectedEmployee && (
                            <Flex minWidth={['auto', '300px']} ml={2}>
                              <InputChakra
                                name="current_employee"
                                defaultValue={user.name}
                                sizeCustom="md"
                                readOnly
                                disabled={reportLoading}
                                isInline
                              />
                            </Flex>
                          )}
                        </>
                      )}
                    </Flex>
                    <Button
                      colorScheme="green"
                      bgColor="green.600"
                      size="md"
                      onClick={getStatusAttendencesExcel}
                      _hover={{ backgroundColor: '#38A169' }}
                      isLoading={exportLoading}
                      disabled={reportLoading}
                      ml={[0, 4]}
                      w={['full', 'auto']}
                    >
                      <FaFileExcel />
                      <Text display="inline-block" ml={2}>
                        Exportar para Excel
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Form>
          </Box>

          {reportLoading || monthlyAttendances?.data?.length ? (
            <>
              <Table
                id="tableAttendances"
                /* freezeThead */
                loading={reportLoading}
                theadData={tableTitles}
                tHeadBorder
                pagination={{
                  current_page: monthlyAttendances.current_page,
                  last_page: monthlyAttendances.last_page,
                  per_page: monthlyAttendances.per_page,
                  to: monthlyAttendances.to,
                  total: monthlyAttendances.total,
                }}
                newPage={(pg) =>
                  getStatusAttendences(
                    pg,
                    selectedDemand,
                    selectedYear,
                    selectedEmployee?.id,
                  )
                }
              >
                {!reportLoading &&
                  monthlyAttendances.data?.map((comp) => (
                    <Box
                      as="tr"
                      key={comp.id}
                      _hover={{ bg: 'gray.50' }}
                      /* height="1px" */
                    >
                      <Box as="td" p={3} borderWidth={2} borderColor="gray.100">
                        {comp.company_name}
                      </Box>
                      {monthNames.map((month, i) => {
                        const nMonth = i + 1;

                        /* O find já retorna o primeiro valor que corresponde a condição, portanto para a exibição no modal
                          passaremos todos que correspondem ao mês de competência exceto o que foi encontrado no find (first) */
                        const statusComp = comp.status_comp.find(
                          (sc) => sc.month_competence === nMonth,
                        );
                        const extraStatusComp = comp.status_comp.filter(
                          (sc) =>
                            sc.month_competence === nMonth &&
                            sc.attendance_id !== statusComp?.attendance_id,
                        );

                        return (
                          <Box
                            as="td"
                            key={nMonth}
                            borderWidth={2}
                            borderColor="gray.100"
                            minW="85px"
                            /* height="inherit" */
                            position="relative"
                          >
                            <Box
                              /* flexDir="column" */
                              p={2}
                              h="full"
                              /* minH="40px" */
                            >
                              {!!statusComp && (
                                <>
                                  {!!statusComp.count_plannings_employee && (
                                    <Flex justifyContent="center">
                                      <Tooltip
                                        placement="right"
                                        label={`${
                                          selectedEmployee
                                            ? selectedEmployee.name
                                            : 'Você'
                                        } tem ${
                                          statusComp.count_plannings_employee
                                        } atividade(s) nesse atendimento`}
                                        aria-label="A tooltip"
                                      >
                                        <Box>
                                          <AvatarCustom
                                            src={employeeAvatar}
                                            name={employeeName}
                                            size="xs"
                                            mb={2}
                                          />
                                        </Box>
                                      </Tooltip>
                                    </Flex>
                                  )}
                                  <Box display="flex" justifyContent="center">
                                    <Box
                                      position="absolute"
                                      left={1}
                                      right={1}
                                      top={0}
                                    >
                                      {(!!statusComp.count_delay ||
                                        !!statusComp.count_planning_delay) && (
                                        <Popover
                                          placement="left"
                                          autoFocus={false}
                                        >
                                          {/* <PopoverTrigger>
                                            <IconButtonStatus
                                              mr={2}
                                              aria-label="atrasos"
                                              icon={<FiClock />}
                                              bgColor="transparent"
                                              color="red.500"
                                              size="15px"
                                              isRound
                                            />
                                          </PopoverTrigger> */}

                                          <PopoverContent>
                                            <PopoverArrow />
                                            <PopoverCloseButton />
                                            <PopoverHeader>
                                              Atrasos!
                                            </PopoverHeader>
                                            <PopoverBody>
                                              <p>
                                                {statusComp.count_delay > 0 &&
                                                  `${statusComp.count_delay} Em relação à data fatal`}
                                              </p>
                                              <p>
                                                {statusComp.count_planning_delay >
                                                  0 &&
                                                  `${statusComp.count_planning_delay} Em relação ao planejamento`}
                                              </p>
                                            </PopoverBody>
                                          </PopoverContent>
                                        </Popover>
                                      )}
                                      {!!statusComp.archived && (
                                        <Tooltip
                                          placement="left"
                                          label="Arquivado"
                                        >
                                          <Box mt={1}>
                                            <FiArchive size={15} />
                                          </Box>
                                        </Tooltip>
                                      )}
                                    </Box>

                                    <Flex flexDir="column" alignItems="center">
                                      <LinkBadgeStatusAttendance
                                        showIconExternalLink={false}
                                        attendance={statusComp}
                                        demand_id={selectedDemand}
                                        onlyIcon
                                      />
                                      {!!extraStatusComp.length && (
                                        <Tooltip label="Atendimentos Extras">
                                          <Button
                                            colorScheme="blue"
                                            size="xs"
                                            display="block"
                                            fontWeight="bold"
                                            mt={1}
                                            onClick={() => {
                                              setExtraStatusAttendances({
                                                id_alterdata: comp.id_alterdata,
                                                company_name: comp.company_name,
                                                competence:
                                                  statusComp.competence,
                                                attendances: extraStatusComp,
                                              });
                                              onOpen();
                                            }}
                                            /*  onClick={() => null} */
                                            /*  px={[0, 4]}
                                            mx={[0, 'auto']}
                                            minWidth={['auto', '300px']} */
                                            /* width="full" */
                                          >
                                            {`+ ${extraStatusComp.length}`}
                                          </Button>
                                        </Tooltip>
                                      )}
                                    </Flex>
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  ))}
              </Table>

              {/* <ReactHTMLTableToExcel
                  table="tableAttendances"
                  filename="Emp Excel file"
                  sheet="Sheet"
                  buttonText="Exportar para Excel"
                /> */}
            </>
          ) : (
            <Empty
              style={{ fontSize: '25px', color: '#838383' }}
              description="Escolha uma demanda e o ano da competência para listar as informações relacionadas."
              imageStyle={{ width: '100%', height: 200 }}
            />
          )}
        </>
      )}
      <ModalChakra
        title={`Atendimentos (${extraStatusAttendances?.competence})`}
        onClose={() => {
          onClose();
          setExtraStatusAttendances(undefined);
        }}
        isOpen={isOpen}
      >
        <Box mb={3}>
          <Text color="#262626" fontWeight="600" fontSize="lg" mb=".5rem">
            {`Empresa: ${
              extraStatusAttendances?.id_alterdata
                ? `${extraStatusAttendances?.id_alterdata} - `
                : ''
            }${extraStatusAttendances?.company_name} `}
          </Text>
        </Box>
        <Table
          theadData={[
            { title: 'Cód.' },
            { title: 'Status' },
            { title: 'Criado em' },
          ]}
          alignHead="center"
        >
          {!!extraStatusAttendances?.attendances.length &&
            extraStatusAttendances.attendances.map((extra) => {
              return (
                <Box as="tr" key={extra.attendance_id}>
                  <Box as="td" p={2} fontWeight="bold">
                    <Flex alignItems="center" justifyContent="center">
                      {extra.status_id !== 13 ? (
                        <Tooltip label="Ver atendimento">
                          <Button
                            variant="ghost"
                            colorScheme="blue"
                            display="flex"
                            alignItems="center"
                            onClick={() =>
                              linkStatus(
                                selectedDemand,
                                extra.attendance_id,
                                extra.archived,
                              )
                            }
                          >
                            <FiExternalLink size={18} />
                            <Text ml={2} fontWeight="extrabold">
                              {extra.attendance_id}
                            </Text>
                          </Button>
                        </Tooltip>
                      ) : (
                        <Text ml={2} fontWeight="extrabold" color="blue.500">
                          {extra.attendance_id}
                        </Text>
                      )}
                    </Flex>
                  </Box>
                  <Box as="td" p={2}>
                    <Flex flexDir="column" alignItems="center">
                      {!!extra.count_plannings_employee && (
                        <Tooltip
                          placement="right"
                          label={`${
                            selectedEmployee ? selectedEmployee.name : 'Você'
                          } tem ${
                            extra.count_plannings_employee
                          } atividade(s) nesse atendimento`}
                          aria-label="A tooltip"
                        >
                          <Box mb={2}>
                            <AvatarCustom
                              src={employeeAvatar}
                              name={employeeName}
                              size="xs"
                            />
                          </Box>
                        </Tooltip>
                      )}
                      <BadgeStatusInfo
                        status={extra.status}
                        statusColor={extra.status_original_color}
                        size="md"
                      />
                    </Flex>
                  </Box>
                  <Box as="td" p={2} textAlign="center">
                    <Flex flexDir="column" alignItems="center">
                      <Text ml={2} fontWeight="bold">
                        {extra.created_at}
                      </Text>
                    </Flex>
                  </Box>
                </Box>
              );
            })}
        </Table>
      </ModalChakra>
    </Box>
  );
};

export default MonthlyDemands;
