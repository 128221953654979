import { Stat, StatGroup } from '@chakra-ui/react';
import styled from 'styled-components';

interface AllocationItemProps {
  allocationColor: 'danger' | 'warning' | 'primary' | 'success' | 'secundary';
}

export const StatGroupAllocation = styled(StatGroup)`
  &.custom-stat-group-allocation {
    justify-content: space-between;
  }

  @media (max-width: 480px) {
    &.custom-stat-group-allocation {
      flex-direction: column;
    }

    .accomplished-allocation-status dt {
      text-align: start;
    }

    .accomplished-allocation-status #stack_real_allocation {
      justify-content: start;
    }
  }
`;

export const StatShift = styled(Stat)`
  & {
    justify-content: flex-start;
  }
`;

export const StatTimeAllocation = styled(Stat)`
  &.time-total-allocation {
    justify-content: flex-end;
  }
  @media (max-width: 480px) {
    &.time-total-allocation {
      justify-content: start;
    }
  }
`;

const allocationColors = {
  danger: '#E20105',
  warning: '#FFA11A',
  primary: '#169BD6',
  success: '#2BBA85',
  secundary: '#fff',
};

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 80px);
  grid-gap: 10px;
  justify-content: space-between;
`;

export const AllocationItem = styled.div<AllocationItemProps>`
  min-height: 90px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => allocationColors[props.allocationColor]};
  padding-top: 10px;
  color: ${(props) =>
    props.allocationColor === 'secundary' ? '#1f1f1' : '#fff'};
  font-size: 15px;

  > strong {
    font-weight: 400;
    padding: 4px;
  }

  img {
    height: 40px;
  }

  span {
    font-size: 11px;
  }

  div {
    background: #bac3cc;
    border-radius: 4px;
    color: #2f2e38;
    width: 100%;
    padding: 1px;
    text-align: center;
    border: 1px solid #f3f4fd;
    font-weight: 400;

    &.loading {
      background: transparent;
      border: 0;
    }
  }
`;
