// import useCan from '~/hooks/useCan';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FiArrowLeft,
  FiArrowRight,
  FiChevronDown,
  FiClock,
  FiExternalLink,
  FiRepeat,
} from 'react-icons/fi';

import {
  Accordion,
  Box,
  Button,
  Flex,
  IconButton,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Empty } from 'antd';
import { isArray } from 'lodash';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import RangeDateWithTwoInput from '~/shared/components/RangeDateWithTwoInput';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import TableRowIndividualPerformance from '../../components/TableRowIndividualPerformance';
import { TrBox } from './styles';

interface FormData {
  periodo_avaliativo: string[];
  periodo_comparativo: string[];
  employee_evaluate: string;
  client_company?: string;
  demand?: string;
  activity?: string;
}

interface FormDataTable {
  comparative_employee: string;
}

interface SelectData {
  id?: number;
  name?: string;
  value: number;
  label: string;
}

interface InitialSelects {
  employees: SelectData[];
  companies: SelectData[];
  demands: SelectData[];
  activities: SelectData[];
}

export interface FilterData {
  client_company?: string;
  demand?: string;
  activity?: string;
  comparative_employee?: string;
  employee_start_date: string;
  employee_end_date: string;
  general_start_date: string;
  general_end_date: string;
  employee: string;
  page: number;
}

export interface ModalFilter extends FilterData {
  activity_id: number;
}

export interface IActivity {
  activity_id: number;
  activity_name: string;
  demand_id: number;
  general_average_allocation: string;
  general_average_allocation_sec: string;
  general_count_devolutions: number;
  general_qtd_plan: number;
  personal_average_allocation: string;
  personal_average_allocation_sec: string;
  personal_count_devolutions: number;
  personal_qtd_plan: number;
  result_general_allocation: string;
  result_opponent_allocation: string;
  result_general_color: string;
  result_oponent_color: string;
  result_general_icon: 'triangle-up' | 'triangle-down';
  result_opponent_icon: 'triangle-up' | 'triangle-down';
  opponent_average_allocation?: string;
  opponent_qtd_plan?: string;
  opponent_count_devolutions?: number;
  result_opponent_color?: string;
}

export interface IAttendance {
  data: {
    id: number;
    demanda_id: number;
    nome_fantasia: string;
    competencia?: string;
    created_at: string;
  };
  activities: {
    employee_total_allocation: string;
    employee_total_devolution: number;
    general_total_allocation?: string;
    general_total_devolution?: number;
    opponent_total_allocation?: string;
    opponent_total_devolution?: number;
  };
}

export interface AttendanceDTO {
  attendances: IAttendance[];
  total_pages: number;
}

export interface IDemand {
  name: string;
  activities: IActivity[];
}

interface IIndividualPerformance {
  demands: IDemand[];
  comparable_employees: Array<{
    id: number;
    label: string;
    name: string;
    value: number;
  }>;
  total_general_allocation: string;
  total_opponent_allocation: string;
  total_personal_allocation: string;
  total_pages: number;
  total_general_devolution: number;
  total_opponent_devolution: number;
  total_personal_devolution: number;
}

const IndividualPerformance: React.FC = () => {
  // const can = useCan();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const formTableRef = useRef<FormHandles>(null);
  const addToast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [employeeSelected, setEmployeeSelected] = useState('#');
  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [reportShowTime, setReportShowTime] = useState(true);
  const [dataSelects, setDataSelects] = useState<InitialSelects>(
    {} as InitialSelects,
  );
  const [dataFilters, setDataFilters] = useState<FilterData>({} as FilterData);
  const [
    individualPerformanceData,
    setIndividualPerformanceData,
  ] = useState<IIndividualPerformance>({} as IIndividualPerformance);

  const [attendances, setAttendances] = useState<IAttendance[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageModal, setPageModal] = useState(0);
  const [filterModal, setFilterModal] = useState<ModalFilter>(
    {} as ModalFilter,
  );
  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    function loadFilters(): void {
      api
        .get<SelectData[]>('reports/personal-performance/filters')
        .then((response) => {
          if (response.data && isArray(response.data)) {
            setDataSelects((state) => ({ ...state, employees: response.data }));
          }

          setLoading(false);
        })
        .catch((err) => {
          const error = err.response.data?.error;

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Erro ao carregar lista de funcionários, tente novamente!',
            description: error,
          });

          setLoading(false);
        });
    }

    loadFilters();
  }, [addToast, user.company_id]);

  const handleLoadFilters = useCallback(() => {
    const employee = formRef.current?.getFieldValue('employee_evaluate');
    const employee_start_end_date = formRef.current?.getFieldValue(
      'periodo_avaliativo',
    );
    const demand = formRef.current?.getFieldValue('demand');

    setLoadingFilters(true);
    if (!employee && !employee_start_end_date.lenght) return;

    api
      .get<InitialSelects>('reports/personal-performance/filters', {
        params: {
          employee,
          employee_start_date: String(employee_start_end_date[0]),
          employee_end_date: String(employee_start_end_date[1]),
          demand,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setDataSelects({ ...response.data });
        setLoadingFilters(false);
      })
      .catch((err) => {
        const error = err.response.data?.error;

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao carregar lista de funcionários, tente novamente!',
          description: error,
        });

        setLoadingFilters(false);
      });
  }, [addToast]);

  const loadDataIndividualPerformance = useCallback(
    async (data: FilterData) => {
      setReportLoading(true);
      setDataFilters(data);

      try {
        const response = await api.get<IIndividualPerformance>(
          `reports/personal-performance/comparative/${data.employee}`,
          {
            params: data,
          },
        );

        const employee = dataSelects.employees?.find(
          (employeeItem) => employeeItem.value === Number(data.employee),
        );
        if (employee) {
          setEmployeeSelected(employee.label);
        }

        const { demands } = individualPerformanceData;

        if (demands && data.page !== 1) {
          setIndividualPerformanceData({
            ...response.data,
            demands: [...demands, ...response.data.demands],
          });
        } else {
          setIndividualPerformanceData(response.data);
        }

        setReportLoading(false);
      } catch (err) {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error ||
              'Ocorreu um erro ao carregar dados do relatório.',
            isClosable: true,
          });
          setReportLoading(false);

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops! Ocorreu um erro no servidor.',
          isClosable: true,
        });
        setReportLoading(false);
      }
    },
    [addToast, individualPerformanceData, dataSelects.employees],
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      // console.log(data);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          employee_evaluate: Yup.string().required('Selecione um funcionário'),
          client_company: Yup.string(),
          demand: Yup.string(),
          activity: Yup.string(),
          periodo_avaliativo: Yup.array()
            .of(Yup.string().required('Período obrigatório'))
            .required(),
          periodo_comparativo: Yup.array()
            .of(Yup.string().required('Período obrigatório'))
            .required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formattedFilter: FilterData = {
          employee: data.employee_evaluate,
          client_company: data.client_company,
          demand: data.demand,
          activity: data.activity,
          comparative_employee: dataFilters.comparative_employee,
          employee_start_date: data.periodo_avaliativo[0],
          employee_end_date: data.periodo_avaliativo[1],
          general_start_date: data.periodo_comparativo[0],
          general_end_date: data.periodo_comparativo[1],
          page: 1,
        };

        await loadDataIndividualPerformance(formattedFilter);

        // formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          if (errors[`periodo_avaliativo[0]`]) {
            errors.periodo_avaliativo = 'Período obrigatório';
          }
          if (errors[`periodo_comparativo[0]`]) {
            errors.periodo_comparativo = 'Período obrigatório';
          }

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
            isClosable: true,
          });

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops! Ocorreu um erro no servidor.',
          isClosable: true,
        });
      }
    },
    [addToast, loadDataIndividualPerformance, dataFilters],
  );

  const handleFilterEmployeeCompare = useCallback(
    async (data: FormDataTable) => {
      const formattedFilter: FilterData = {
        ...dataFilters,
        comparative_employee: data.comparative_employee,
        page: 1,
      };
      // console.log(data.employee_compare);

      await loadDataIndividualPerformance(formattedFilter);
    },
    [dataFilters, loadDataIndividualPerformance],
  );

  const handleNexPageEmployeeCompare = useCallback(async () => {
    const formattedFilter: FilterData = {
      ...dataFilters,
      page: page + 1,
    };
    setPage(page + 1);
    // console.log(data.employee_compare);

    await loadDataIndividualPerformance(formattedFilter);
  }, [dataFilters, loadDataIndividualPerformance, page]);

  const handleLoadModal = useCallback(
    (data: ModalFilter, pageM: number) => {
      setLoadingModal(true);

      api
        .get<AttendanceDTO>(
          `reports/personal-performance/comparative/attendances/${data.activity_id}`,
          {
            params: {
              company: user.company_id,
              employee_start_date: data.employee_start_date,
              employee_end_date: data.employee_end_date,
              general_start_date: data.general_start_date,
              general_end_date: data.general_end_date,
              employee: data.employee,
              comparative_employee: data.comparative_employee,
              client_company: data.client_company,
              page: pageM,
            },
          },
        )
        .then((response) => {
          setAttendances(response.data?.attendances);
          setTotalPages(response.data?.total_pages);
          setFilterModal(data);
          setLoadingModal(false);
        })
        .catch((err) => {
          const error = err.response.data?.error;

          if (err.response?.status < 500) {
            addToast({
              position: 'top-right',
              status: 'error',
              title: 'Ooops!!!',
              description:
                err.response.data?.error ||
                'Ocorreu um erro ao carregar dados.',
              isClosable: true,
            });

            setLoadingModal(false);
            return;
          }

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Erro ao carregar lista, tente novamente!',
            description: error,
          });
          setLoadingModal(false);
        });
    },
    [addToast, user.company_id],
  );

  const handleNextPageModal = useCallback(
    (data: ModalFilter, pg: number) => {
      if (pg >= totalPages) {
        setPageModal(totalPages);
      } else {
        setPageModal((state) => state + 1);
      }
      handleLoadModal(data, pg);
    },
    [totalPages, handleLoadModal],
  );

  const handlePrevPageModal = useCallback(
    (data: ModalFilter, pg: number) => {
      if (pg <= 1) {
        setPageModal(1);
      } else {
        setPageModal((state) => state - 1);
      }
      handleLoadModal(data, pg);
    },
    [handleLoadModal],
  );

  const handleOpenModal = useCallback(
    (data: ModalFilter) => {
      setPageModal(0);
      if (data.activity_id === filterModal.activity_id) {
        onOpen();
        return;
      }
      handleLoadModal(data, 1);
      onOpen();
    },
    [onOpen, handleLoadModal, filterModal],
  );

  return (
    <>
      <Box position="relative" width="full" height="full" minHeight="100vh">
        <SectionHeader
          title="Desempenho Individual"
          pagename="Relatório de desempenho individual do funcionário"
          goBackLink="/dashboard"
        />

        {(loading || reportLoading) && (
          <Box
            pos="absolute"
            top={0}
            left={0}
            w="full"
            h="full"
            bg="#ffffff"
            zIndex={6666}
          >
            <Loading />
          </Box>
        )}
        {!loading && (
          <>
            <Box>
              <Form
                initialData={dataFilters}
                onSubmit={handleSubmit}
                ref={formRef}
              >
                <Flex
                  flexDirection={['column', 'row']}
                  justifyContent="space-between"
                  width="full"
                >
                  <Flex flexDirection="column" width="full" pr={[0, 4]}>
                    <SelectChakra
                      name="employee_evaluate"
                      label="Funcionário a Avaliar"
                      placeholder="Selecione um funcionário"
                      options={dataSelects.employees}
                    />

                    <RangeDateWithTwoInput
                      name="periodo_avaliativo"
                      label="Período avaliado"
                      onChange={handleLoadFilters}
                    />

                    <RangeDateWithTwoInput
                      name="periodo_comparativo"
                      label="Período comparativo (Média Geral)"
                    />
                  </Flex>
                  <Flex
                    flexDirection="column"
                    justifyContent="flex-start"
                    width="full"
                  >
                    <Flex>
                      <SelectChakra
                        name="client_company"
                        label="Empresa"
                        placeholder={loadingFilters ? 'Carregando...' : '...'}
                        options={dataSelects.companies}
                        onChange={handleLoadFilters}
                        disabled={!dataSelects.companies}
                        width="100%"
                      />
                    </Flex>
                    <Flex>
                      <SelectChakra
                        name="demand"
                        label="Demanda"
                        placeholder={loadingFilters ? 'Carregando...' : '...'}
                        options={dataSelects.demands}
                        onChange={handleLoadFilters}
                        disabled={!dataSelects.demands}
                        width="100%"
                      />
                    </Flex>
                    <Flex>
                      <SelectChakra
                        name="activity"
                        label="Atividade"
                        placeholder={loadingFilters ? 'Carregando...' : '...'}
                        options={dataSelects.activities}
                        disabled={!dataSelects.activities}
                        width="100%"
                      />
                    </Flex>
                  </Flex>
                </Flex>

                <Flex justifyContent="flex-start" mb={10}>
                  <Button
                    colorScheme="blue"
                    size="lg"
                    onClick={() => formRef.current?.submitForm()}
                    px={4}
                    mx="auto"
                    minWidth="300px"
                  >
                    Filtrar
                  </Button>
                </Flex>
              </Form>
            </Box>

            {individualPerformanceData.demands?.length ? (
              <>
                <Flex alignItems="center" justifyContent="flex-start" pb={10}>
                  <Button
                    overflowX="auto"
                    position="relative"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    p="20px"
                    textAlign="left"
                    rounded="md"
                    colorScheme={reportShowTime ? 'blue' : 'gray'}
                    color={reportShowTime ? 'blue.500' : 'gray.300'}
                    variant="ghost"
                    bg={reportShowTime ? 'blue.50' : 'gray.50'}
                    backgroundImage={
                      reportShowTime
                        ? 'linear-gradient(95deg, #ebf8ff, #ebf8ff, #fff)'
                        : 'linear-gradient(95deg, #F7FAFC, #F7FAFC, #fff)'
                    }
                    borderWidth={1}
                    borderColor={reportShowTime ? 'blue.100' : 'gray.100'}
                    height={[100, 126]}
                    width={234}
                    onClick={() => setReportShowTime(true)}
                    mr={2}
                  >
                    <Box
                      as={FiClock}
                      size="30px"
                      color={reportShowTime ? 'blue.500' : 'gray.300'}
                      mb="14px"
                    />
                    <Box as="span" display={['none', 'block']}>
                      Visualizar por Tempo <br />
                      HH:mm:ss
                    </Box>
                    <Box as="span" display={['block', 'none']}>
                      Tempo
                    </Box>
                  </Button>

                  <Button
                    overflowX="auto"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    p="20px"
                    textAlign="left"
                    rounded="md"
                    colorScheme={!reportShowTime ? 'green' : 'gray'}
                    color={!reportShowTime ? 'green.500' : 'gray.300'}
                    variant="ghost"
                    bg={!reportShowTime ? 'green.50' : 'gray.50'}
                    backgroundImage={
                      !reportShowTime
                        ? 'linear-gradient(95deg, #F0FFF4, #F0FFF4, #fff)'
                        : 'linear-gradient(95deg, #F7FAFC, #F7FAFC, #fff)'
                    }
                    borderWidth={1}
                    borderColor={!reportShowTime ? 'green.100' : 'gray.100'}
                    height={[100, 126]}
                    width={234}
                    onClick={() => setReportShowTime(false)}
                    mr={2}
                  >
                    <Box
                      as={FiRepeat}
                      size="30px"
                      color={!reportShowTime ? 'green.500' : 'gray.300'}
                      mb="14px"
                    />
                    <Box as="span" display={['none', 'block']}>
                      Visualizar por quantidade
                      <br /> de Devoluções
                    </Box>
                    <Box as="span" display={['block', 'none']}>
                      Devoluções
                    </Box>
                  </Button>
                </Flex>

                <Box width="full" overflowX="auto">
                  <Box
                    as="table"
                    w="full"
                    style={{
                      borderSpacing: 0,
                      borderCollapse: 'collapse',
                      border: '1px solid transparent',
                      overflowX: 'auto',
                    }}
                  >
                    <Box
                      as="thead"
                      w="full"
                      p={4}
                      fontWeight="medium"
                      fontSize="sm"
                      borderTopWidth={1}
                      borderBottomWidth={1}
                      borderColor="gray.100"
                      textAlign="right"
                      bg="gray.50"
                    >
                      <Box as="tr" bg="gray.50" verticalAlign="top">
                        <Box
                          as="th"
                          px="10px"
                          py="20px"
                          pb="5px"
                          textAlign="left"
                          fontSize="md"
                        >
                          {employeeSelected}
                        </Box>
                        <Box as="th" px="10px" py="20px" pb="5px" width="150px">
                          Média Geral
                        </Box>
                        <Box as="th" px="10px" py="20px" pb="5px" width="150px">
                          TMR do Avaliado
                        </Box>
                        <Box as="th" px="10px" py="20px" pb="5px" width="150px">
                          TMR do Comparado
                          <Form
                            onSubmit={handleFilterEmployeeCompare}
                            ref={formTableRef}
                          >
                            <SelectChakra
                              name="comparative_employee"
                              placeholder="Funcionário a comparar"
                              options={
                                individualPerformanceData.comparable_employees
                              }
                              onChange={() =>
                                formTableRef.current?.submitForm()
                              }
                              mb={0}
                              sizeCustom="sm"
                            />
                          </Form>
                        </Box>
                      </Box>
                      <Box as="tr" borderTopWidth={1}>
                        <Box
                          as="th"
                          px="10px"
                          py="15px"
                          width="100%"
                          minWidth="150px"
                        >
                          {' '}
                        </Box>
                        <Box
                          as="th"
                          px="10px"
                          py="15px"
                          width="150px"
                          maxWidth="150px"
                          minWidth="150px"
                        >
                          <Tag size="sm" colorScheme="cyan">
                            {reportShowTime
                              ? individualPerformanceData.total_general_allocation
                              : individualPerformanceData.total_general_devolution}
                          </Tag>
                        </Box>
                        <Box
                          as="th"
                          px="10px"
                          py="15px"
                          width="150px"
                          maxWidth="150px"
                          minWidth="150px"
                        >
                          <Tag size="sm" colorScheme="cyan">
                            {reportShowTime
                              ? individualPerformanceData.total_personal_allocation
                              : individualPerformanceData.total_personal_devolution}
                          </Tag>
                        </Box>
                        <Box
                          as="th"
                          px="10px"
                          py="15px"
                          width="150px"
                          maxWidth="150px"
                          minWidth="150px"
                        >
                          <Tag size="sm" colorScheme="cyan">
                            {reportShowTime
                              ? individualPerformanceData.total_opponent_allocation
                              : individualPerformanceData.total_opponent_devolution}
                          </Tag>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Accordion allowMultiple minWidth="800px">
                    {!reportLoading &&
                      individualPerformanceData.demands.map((demand) => {
                        return (
                          <TableRowIndividualPerformance
                            key={`row-157${demand.name}.${demand.activities[0].activity_id}`}
                            showTime={reportShowTime}
                            demand={demand}
                            onOpen={(data) => handleOpenModal(data)}
                            comparativeEmployee={
                              dataFilters.comparative_employee
                            }
                            filter={dataFilters}
                          />
                        );
                      })}
                  </Accordion>
                </Box>

                <Flex alignItems="center" justifyContent="center" mt={4} pt={4}>
                  {page < individualPerformanceData.total_pages && (
                    <Button
                      rightIcon={<FiChevronDown />}
                      variant="ghost"
                      colorScheme="gray"
                      onClick={handleNexPageEmployeeCompare}
                      isLoading={reportLoading}
                    >
                      {reportLoading ? 'Carregando...' : 'Carregar mais!'}
                    </Button>
                  )}
                </Flex>
              </>
            ) : (
              <Empty
                description={false}
                imageStyle={{ width: '100%', height: 400 }}
              />
            )}
          </>
        )}
      </Box>

      <ModalChakra
        isOpen={isOpen}
        onClose={onClose}
        title="Atendimentos"
        isLoading={loadingModal}
      >
        {loadingModal && (
          <Box
            pos="absolute"
            top={0}
            left={0}
            w="full"
            h="full"
            bg="rgba(255,255,255,0.5)"
            zIndex={6666}
          >
            <Loading />
          </Box>
        )}
        <Box width="full" overflowX="auto">
          <Box
            as="table"
            w="full"
            style={{
              borderSpacing: 0,
              borderCollapse: 'collapse',
              border: '1px solid transparent',
            }}
          >
            <Box
              as="thead"
              w="full"
              p={4}
              fontWeight="medium"
              fontSize="sm"
              borderTopWidth={1}
              borderBottomWidth={1}
              borderColor="gray.100"
              textAlign="right"
              bg="gray.50"
            >
              <Box as="tr" bg="gray.50" verticalAlign="top">
                <Box
                  as="th"
                  px="10px"
                  py="20px"
                  pb="5px"
                  textAlign="left"
                  fontSize="md"
                  width="auto"
                  minWidth="200px"
                >
                  {employeeSelected}
                </Box>
                <Box as="th" px="10px" py="20px" pb="5px" width="150px">
                  Média Geral
                </Box>
                <Box as="th" px="10px" py="20px" pb="5px" width="150px">
                  TMR do Avaliado
                </Box>
                <Box as="th" px="10px" py="20px" pb="5px" width="150px">
                  TMR do Comparado
                </Box>
              </Box>
            </Box>
            <Box as="tbody" w="full" p={4} fontSize="md">
              {attendances?.map((attendance) => {
                return (
                  <TrBox
                    key={`${attendance.data.id}-modal`}
                    as="tr"
                    className="activities"
                  >
                    <Box as="td" width="auto" minWidth="200px">
                      <Flex alignItems="center">
                        <a
                          href={`${process.env.REACT_APP_OLDMYIPAC}/planejamento/index/${attendance.data.demanda_id}?aten=${attendance.data.id}&pg=1`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton
                            aria-label="Ver atendimento"
                            title="Ver atendimento"
                            icon={<FiExternalLink />}
                            variant="ghost"
                            colorScheme="blue"
                            mr={2}
                          />
                        </a>
                        <Flex flexDirection="column">
                          <Flex alignItems="center">
                            <Text>Atendimento {attendance.data.id}</Text>
                            <Text
                              isTruncated
                              width="150px"
                              ml={2}
                              pl={2}
                              borderLeftWidth={1}
                              title={attendance.data.nome_fantasia}
                            >
                              {attendance.data.nome_fantasia}
                            </Text>
                          </Flex>
                          {attendance.data.competencia && (
                            <Text fontSize="11px">
                              Competencia: {attendance.data.competencia}
                            </Text>
                          )}
                        </Flex>
                      </Flex>
                    </Box>
                    <Box as="td" width="150px" textAlign="right" px="10px">
                      {reportShowTime
                        ? attendance.activities.general_total_allocation
                        : attendance.activities.general_total_devolution}
                    </Box>
                    <Box as="td" width="150px" textAlign="right" px="10px">
                      {reportShowTime
                        ? attendance.activities.employee_total_allocation
                        : attendance.activities.employee_total_devolution}
                    </Box>
                    <Box as="td" width="150px" textAlign="right" px="10px">
                      {reportShowTime
                        ? attendance.activities.opponent_total_allocation
                        : attendance.activities.opponent_total_devolution}
                    </Box>
                  </TrBox>
                );
              })}
            </Box>
          </Box>
        </Box>

        <Flex alignItems="center" justifyContent="space-between" mt={4}>
          <IconButton
            aria-label="Anterior"
            icon={<FiArrowLeft />}
            colorScheme="gray"
            isDisabled={pageModal <= 1}
            onClick={() => handlePrevPageModal(filterModal, pageModal - 1)}
          />

          <IconButton
            aria-label="Próxima"
            icon={<FiArrowRight />}
            colorScheme="gray"
            isDisabled={pageModal >= totalPages}
            onClick={() => handleNextPageModal(filterModal, pageModal + 1)}
          />
        </Flex>
      </ModalChakra>
    </>
  );
};

export default IndividualPerformance;
