import React, { useCallback, useRef, ChangeEvent, useState } from 'react';
import { FiUser, FiMail, FiLock, FiCamera } from 'react-icons/fi';
import ReactLoading from 'react-loading';

import {
  useToast,
  Flex,
  Text,
  Button,
  Box,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import userCoverDefault from '~/assets/capa.jpg';
import userAvatarDefault from '~/assets/user-avatar.png';
import { useAuth } from '~/hooks/auth';
import BarChartBSC from '~/shared/components/Chart/BarChartBSC';
import Input from '~/shared/components/InputChakra';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import ActivityAnalysis from '../../components/ActivityAnalysis';
import ActivityHistory from '../../components/ActivityHistory';
import Planning from '../../components/Planning';
import {
  Container,
  Header,
  Grid,
  Content,
  UserContent,
  ProfileContent,
  AvatarInput,
  ContentGrid,
  LoadingAvatar,
} from './styles';

interface ProfileFormData {
  name: string;
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
}

interface AvatarData {
  avatar: string;
  avatar_url: string;
}

interface CoverData {
  cover: string;
  cover_url: string;
}

const Profile: React.FC = () => {
  const { finishTourBSC, completedTourBSC } = useAuth();

  const tourBsc = localStorage.getItem('tourBsc') === 'false';

  const formRef = useRef<FormHandles>(null);

  const [editProfile, setEditProfile] = useState(false);
  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [loadingCover, setLoadingCover] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  const { user, updateUser } = useAuth();
  const addToast = useToast();

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      setLoadingUpdateProfile(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido!'),
          old_password: Yup.string()
            .nullable()
            .min(6, 'Mínimo 6 caracteres')
            .max(30, 'Máximo 30 caracteres'),
          password: Yup.string()
            .nullable()
            .min(6, 'Mínimo 6 caracteres')
            .max(30, 'Máximo 30 caracteres'),
          password_confirmation: Yup.string()
            .nullable()
            .when('password', {
              is: (val) => !!val,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
          email,
          old_password,
          password,
          password_confirmation,
        } = data;

        const formData = {
          name,
          email,
          ...(old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}),
        };

        await api.put('/me', formData);

        formRef.current?.reset();

        setEditProfile(false);

        updateUser({
          name,
          email,
        });

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Perfil atualizado!',
          description:
            'Suas informações do perfil foram atualizadas com sucesso!',
        });
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro ao atualizar perfil, tente novamente.',
        });
      } finally {
        setLoadingUpdateProfile(false);
      }
    },
    [addToast, updateUser],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        if (!/\.(jpg|png|gif)$/i.test(e.target.files[0].name)) {
          addToast({
            title: 'Ooops! Imagem inválida!',
            description:
              'Selecione uma imagem válida. Formatos aceitos PNG, JPG ou GIF.',
            position: 'top-right',
            isClosable: true,
            status: 'info',
          });

          return;
        }

        if (e.target.files[0].size > 802830) {
          addToast({
            title: 'Ooops! Imagem muito grande!',
            description: 'Selecione uma imagem válida com no máximo 800kb',
            position: 'top-right',
            isClosable: true,
            status: 'info',
          });

          return;
        }

        setLoadingAvatar(true);
        const data = new FormData();

        data.append('avatar', e.target?.files[0]);
        data.append('user_id', user.id);

        api
          .post<AvatarData>('/me/avatar', data)
          .then((response) => {
            updateUser(response.data);

            addToast({
              position: 'top-right',
              isClosable: true,
              status: 'success',
              title: 'Avatar atualizado!',
            });
          })
          .finally(() => {
            setLoadingAvatar(false);
          });
      }
    },
    [addToast, updateUser, user.id],
  );

  const handleCoverChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        if (!/\.(jpg|png|gif)$/i.test(e.target.files[0].name)) {
          addToast({
            title: 'Ooops! Imagem inválida!',
            description:
              'Selecione uma imagem válida. Formatos aceitos PNG, JPG ou GIF.',
            position: 'top-right',
            isClosable: true,
            status: 'info',
          });
          return;
        }

        if (e.target.files[0].size > 802830) {
          addToast({
            title: 'Ooops! Imagem muito grande!',
            description: 'Selecione uma imagem válida com no máximo 800kb',
            position: 'top-right',
            isClosable: true,
            status: 'info',
          });
          return;
        }

        setLoadingCover(true);
        const data = new FormData();

        data.append('cover', e.target?.files[0]);
        data.append('user_id', user.id);

        api
          .post<CoverData>('/me/cover', data)
          .then((response) => {
            updateUser(response.data);

            addToast({
              position: 'top-right',
              isClosable: true,
              status: 'success',
              title: 'Capa atualizada!',
            });
          })
          .catch(() => {
            addToast({
              position: 'top-right',
              isClosable: true,
              status: 'error',
              title: 'Erro ao atualizar a capa, tente novamente',
            });
          })
          .finally(() => {
            setLoadingCover(false);
          });
      }
    },
    [addToast, updateUser, user.id],
  );

  return (
    <Container showBscBadge={tourBsc}>
      <Header>
        <img src={user.cover || userCoverDefault} alt="Capa" />
        <div />
        <label htmlFor="cover">
          <FiCamera />
          {loadingCover ? 'Atualizando...' : 'Atualizar capa'}
          <input type="file" id="cover" onChange={handleCoverChange} />
        </label>
      </Header>

      <Grid>
        <UserContent>
          <ProfileContent>
            <AvatarInput>
              <img src={user.avatar || userAvatarDefault} alt={user.name} />
              <label htmlFor="avatar">
                <FiCamera />

                <input type="file" id="avatar" onChange={handleAvatarChange} />
              </label>
              {loadingAvatar && (
                <LoadingAvatar>
                  <ReactLoading
                    type="spin"
                    height={30}
                    width={30}
                    color="#fff"
                  />
                </LoadingAvatar>
              )}
            </AvatarInput>

            <strong>{user.name}</strong>
            <small>{user.email}</small>
            <small className="bold">{user.responsibility}</small>

            {/* Utilizar componente quando exibir estátisticas de alocação na página de perfil, no momento confundirá com
           a pontuação do BSC */}
            {/*  <AllocationUserBar /> */}

            <button
              type="button"
              id="btnUpdateProfile"
              className={editProfile ? 'cancel' : ''}
              onClick={() => {
                setEditProfile(!editProfile);
                setTogglePassword(editProfile);
              }}
            >
              {editProfile ? 'Cancelar' : 'Atualizar perfil'}
            </button>
          </ProfileContent>

          {editProfile && (
            <Form
              ref={formRef}
              initialData={{
                name: user.name,
                email: user.email,
              }}
              onSubmit={handleSubmit}
            >
              <Input name="name" icon={FiUser} placeholder="Nome" />

              <Input name="email" icon={FiMail} placeholder="E-mail" />

              <Flex alignItems="center" my={2}>
                <FormLabel htmlFor="togglePassword">Alterar senha?</FormLabel>
                <Switch
                  id="togglePassword"
                  name="toggle-password"
                  defaultIsChecked={false}
                  onChange={() => setTogglePassword(!togglePassword)}
                  size="sm"
                  mb={2}
                />
              </Flex>

              {togglePassword && (
                <>
                  <Input
                    containerStyle={{ marginTop: 12 }}
                    name="old_password"
                    icon={FiLock}
                    type="password"
                    placeholder="Senha atual"
                    isPassword
                  />
                  <Input
                    name="password"
                    icon={FiLock}
                    type="password"
                    placeholder="Nova senha"
                    isPassword
                  />
                  <Input
                    name="password_confirmation"
                    icon={FiLock}
                    type="password"
                    placeholder="Confirmar senha"
                    isPassword
                  />
                </>
              )}

              <Button
                type="submit"
                colorScheme="green"
                isLoading={loadingUpdateProfile}
              >
                Confirmar
              </Button>
            </Form>
          )}
        </UserContent>

        {!user.is_subscribed && (
          <Content>
            {/* <Allocation /> */}
            <ContentGrid>
              <Flex flexDir="column">
                <Flex
                  flexDir="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box id="containerEmployeeBsc">
                    <BarChartBSC
                      id={Number(user.id)}
                      title="Sua pontuação no BSC"
                      name={user.name}
                      enableDrillDownButtons
                      mode="employee"
                      maxYAxes={200}
                      stepSize={30}
                    />
                  </Box>

                  <Box id="containerTeamBsc">
                    <BarChartBSC
                      id={Number(user.department_id)}
                      title={user.department || ''}
                      name={user.department || ''}
                      enableDrillDownButtons
                      mode="team"
                      maxYAxes={300}
                      stepSize={50}
                    />
                  </Box>
                </Flex>
              </Flex>
            </ContentGrid>
            <Flex flexDir={['column', 'column', 'column', 'row']} mt={10}>
              <ActivityAnalysis />
              <Planning />
            </Flex>

            <ActivityHistory />
          </Content>
        )}
      </Grid>

      {/* Call Step Component Here */}
      {!user.is_subscribed && (
        <StepsIntro
          enabled={!completedTourBSC}
          onExit={() => null}
          onComplete={() => {
            localStorage.setItem('tourBsc', 'true');
            finishTourBSC();
            // setTourBsc(false);
            // window.location.reload();
          }}
          steps={[
            {
              element: '#btnProfile',
              title: 'Nova funcionalidade: Área BSC (Balanced Scorecard)',
              intro: (
                <Text>
                  Agora na sua página de <b>Pefil</b> você pode acompanhar o seu
                  desempenho e o desempenho da sua equipe no BSC.
                </Text>
              ),
              position: 'bottom',
            },
            {
              element: '#containerEmployeeBsc',
              title: 'Sua pontuação no BSC',
              intro: (
                <Text>
                  Aqui você acompanha o <b>seu desempenho pessoal</b> através da
                  pontuação possível e sua pontuação alcançada no período
                  observado. No decorrer do semestre sua pontuação será
                  acumulada podendo chegar a no máximo <b>200 pontos.</b>
                  <br />
                  <br />
                  <b>OBS:</b> Clique nas barras dos gráficos para exibir a
                  informação da etapa
                </Text>
              ),
              position: 'left',
            },
            {
              element: '#containerTeamBsc',
              title: 'Pontuação da sua Equipe',
              intro: (
                <Text>
                  Aqui você acompanha o <b>desempenho da sua equipe</b> do BSC
                  da mesma forma que você acompanha a sua pontuação pessoal.
                  Porém, no decorrer do semestre a pontuação da sua equipe será
                  acumulada podendo chegar a no máximo <b>300 pontos.</b>
                  <br />
                  <br />
                  <b>OBS:</b> Clique nas barras dos gráficos para exibir a
                  informação da etapa
                </Text>
              ),
              position: 'left',
            },
          ]}
        />
      )}
    </Container>
  );
};

export default Profile;
