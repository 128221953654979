import React, { useCallback, useMemo, useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/react';
import axios from 'axios';

import { useFetch } from '~/hooks/useFetch';
import { FilterProps } from '~/modules/activityExecution/@types/activity';
import Filter from '~/shared/components/Filter';
import CheckboxChakra from '~/shared/components/InputChakra/CheckboxChakra';
import CheckboxInput from '~/shared/components/InputChakra/CheckboxChakra';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import api from '~/shared/services/api';

interface FiltroDataItem {
  id: number | string;
  name: string;
}

interface FiltroDTO {
  activity: FiltroDataItem[];
  demand: FiltroDataItem[];
  company: FiltroDataItem[];
  competences: FiltroDataItem[];
}

interface FilterDataItem {
  value: number | string;
  label: string;
}

interface FiltroData {
  activity: FilterDataItem[];
  demand: FilterDataItem[];
  company: FilterDataItem[];
  competences: FilterDataItem[];
}

interface SelectedData {
  company?: string;
  demand?: string;
  activity?: string;
  competence?: string;
}

interface Props {
  isOpen: boolean;
  close(): void;
  filters?(data: FilterProps): void;
  data: FilterProps | null;
  employee: string | number;
  company: string | number;
  btnRef: React.RefObject<HTMLButtonElement>;
  loading?: boolean;
}

const FilterPlanning: React.FC<Props> = ({
  isOpen,
  close,
  filters,
  data,
  employee,
  company,
  btnRef,
  loading = false,
}) => {
  const [dataForm, setDataForm] = useState<FiltroData>({} as FiltroData);
  const [controlDataForm, setControlDataForm] = useState(0);

  const [params, setParams] = useState<SelectedData>({} as SelectedData);
  const [filterLoaded, setFilterLoaded] = useState(false);

  const formattedInitialData = useMemo(() => {
    const situation = [];
    if (data?.status_padding) {
      situation.push('1');
    }
    if (data?.status_running) {
      situation.push('2');
    }
    if (data?.status_paused) {
      situation.push('3');
    }
    if (data?.status_finish) {
      situation.push('4');
    }

    /* Verificar como limpar o filtro quando o botão referente for clicado
    na página de planejamentos, e buscar de novo todos os dados dos filtros */

    if (!data) {
      setParams({});
    }

    return {
      situation,
      order: data?.order,
      company_id: data?.company,
      demand_id: data?.demand,
      activity_id: data?.activity,
      competence: data?.competence,
    };
  }, [data]);

  const msgLoading = 'Carregando...';

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    setFilterLoaded(false);

    api
      .get<FiltroDTO>('plannings/data-filter', {
        params: {
          employee,
          ...params,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log(response);
        setDataForm({
          company: response.data?.company?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
          demand: response.data?.demand?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
          activity: response.data?.activity?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
          competences: response.data?.competences?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
        });
      })
      .finally(() => {
        setFilterLoaded(true);
      });

    return () => {
      source.cancel('Cancelado pelo usuários');
    };
  }, [employee, company, params]);

  const handleClose = useCallback(() => {
    // setParams({});
    close();
  }, [close]);

  const handleUpdateParam = (
    att: string,
    value: string | number | null,
  ): void => {
    setParams((state) => ({ ...state, [att]: value }));
  };

  const handleFilter = useCallback(
    async (dataSelected) => {
      let orderName;
      const arraySituations: string[] = [];

      switch (dataSelected?.order) {
        case 'last':
          orderName = 'Mais recente';
          break;
        case 'older':
          orderName = 'Mais antigo';
          break;
        default:
          break;
      }

      dataSelected?.situation?.forEach((element: string) => {
        arraySituations.push(element);
      });

      const filterActive =
        !!dataSelected?.order ||
        arraySituations.length > 0 ||
        !!dataSelected?.company_id ||
        !!dataSelected?.demand_id ||
        !!dataSelected?.competence ||
        !!dataSelected?.activity_id ||
        false;

      if (filters) {
        filters({
          order: dataSelected?.order || null,
          status_finish: !!arraySituations.find((item) => item === 'finished'),
          status_padding: !!arraySituations.find((item) => item === 'pedding'),
          status_paused: !!arraySituations.find((item) => item === 'paused'),
          status_running: !!arraySituations.find((item) => item === 'running'),
          company: dataSelected?.company_id,
          demand: dataSelected?.demand_id,
          activity: dataSelected?.activity_id,
          competence: dataSelected?.competence,
          competence_value: dataForm?.competences.find(
            (item) => item.value === dataSelected?.competence,
          )?.label,
          company_name: dataForm?.company.find(
            (item) => Number(item.value) === Number(dataSelected?.company_id),
          )?.label,
          demand_name: dataForm?.demand.find(
            (item) => Number(item.value) === Number(dataSelected?.demand_id),
          )?.label,
          activity_name: dataForm?.activity.find(
            (item) => Number(item.value) === Number(dataSelected?.activity_id),
          )?.label,
          order_name: orderName,
          situation_name: arraySituations,
          filterActivated: filterActive,
        });
      }
    },
    [filters, dataForm],
  );

  return (
    <>
      <Filter
        title="Filtrar Atividades"
        isOpen={isOpen}
        onClose={handleClose}
        finalFocusRef={btnRef}
        submit={(obData) => handleFilter(obData)}
        loading={loading}
        initialData={formattedInitialData}
      >
        <RadioChakra
          name="order"
          label="Ordem"
          options={[
            {
              value: 'last',
              label: 'Mais recente',
            },
            {
              value: 'older',
              label: 'Mais antigo',
            },
          ]}
        />

        <CheckboxChakra
          name="situation"
          description="Status"
          options={[
            {
              id: '1',
              value: 'pedding',
              label: 'Não iniciada',
              variantColor: 'gray',
            },
            {
              id: '2',
              value: 'running',
              label: 'Executando',
              variantColor: 'blue',
            },
            {
              id: '3',
              value: 'paused',
              label: 'Pausada',
              variantColor: 'orange',
            },
            {
              id: '4',
              value: 'finished',
              label: 'Finalizada',
              variantColor: 'green',
            },
          ]}
        />
        {/* {dataForm.company.map((comp) => {
          return (
            <CheckboxInput
              name="conmpany_id"
              options={[
                {
                  id: comp.value.toString(),
                  label: comp.label,
                  value: comp.value.toString(),
                },
              ]}
            />
          );
        })} */}

        <SelectChakra
          label="Empresa"
          name="company_id"
          options={dataForm.company}
          reset={() => handleUpdateParam('company', null)}
          placeholder={
            !filterLoaded && !params.company
              ? msgLoading
              : 'Selecione a empresa'
          }
          disabled={!filterLoaded && !params.company}
          onChange={(e) => handleUpdateParam('company', e.target.value)}
        />

        {/* {dataForm.demand.map((dem) => {
          return (
            <CheckboxInput
              name="conmpany_id"
              options={[
                {
                  id: dem.value.toString(),
                  label: dem.label,
                  value: dem.value.toString(),
                },
              ]}
            />
          );
        })} */}

        <SelectChakra
          label="Demanda"
          name="demand_id"
          reset={() => handleUpdateParam('demand', null)}
          options={dataForm.demand}
          placeholder={
            !filterLoaded && !params.demand ? msgLoading : 'Selecione a demanda'
          }
          disabled={!filterLoaded && !params.demand}
          onChange={(e) => handleUpdateParam('demand', e.target.value)}
        />

        {/* {dataForm.activity.map((act) => {
          return (
            <CheckboxInput
              name="conmpany_id"
              options={[
                {
                  id: act.value.toString(),
                  label: act.label,
                  value: act.value.toString(),
                },
              ]}
            />
          );
        })} */}

        <SelectChakra
          label="Atividade"
          name="activity_id"
          reset={() => handleUpdateParam('activity', null)}
          options={dataForm.activity}
          placeholder={
            !filterLoaded && !params.activity
              ? msgLoading
              : 'Selecione a atividade'
          }
          disabled={!filterLoaded && !params.activity}
          onChange={(e) => handleUpdateParam('activity', e.target.value)}
        />

        {/* {dataForm.competences.map((comp) => {
          return (
            <CheckboxInput
              name="conmpany_id"
              options={[
                {
                  id: comp.value.toString(),
                  label: comp.label,
                  value: comp.value.toString(),
                },
              ]}
            />
          );
        })} */}

        <SelectChakra
          label="Competência"
          name="competence"
          reset={() => handleUpdateParam('competence', null)}
          options={dataForm.competences}
          placeholder={
            !filterLoaded && !params.competence
              ? msgLoading
              : 'Selecione a competência'
          }
          disabled={!filterLoaded && !params.competence}
          onChange={(e) => handleUpdateParam('competence', e.target.value)}
        />
      </Filter>
    </>
  );
};

export default FilterPlanning;
