import React, { useState, useCallback, useEffect } from 'react';
import { FiDownload } from 'react-icons/fi';

import {
  Box,
  IconButton,
  Flex,
  Text,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { Markup } from 'interweave';

import { Pop as PopInterface } from '~/modules/activityExecution/@types/activity';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import download from '~/utils/downloadFile';
import FileIconWithType, { getPreviewFile } from '~/utils/getFileIconWithType';

import { PopFiles, PopContent, LinkDownload } from './styles';

interface Props {
  data: PopInterface;
  onClosePop(): void;
}

const Pop: React.FC<Props> = ({ data, onClosePop }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <ModalChakra
      title={`POP da Atividade: ${data?.name}`}
      onClose={() => {
        onClose();
        onClosePop();
      }}
      isOpen={isOpen}
    >
      {data.description && (
        <Box mb={3}>
          <Text color="#262626" fontWeight="600" fontSize="lg" mb=".5rem">
            Descrição
          </Text>
          <Box>
            <textarea
              disabled
              rows={4}
              defaultValue={data?.description}
              style={{
                width: '100%',
                borderRadius: '5px',
                padding: '0.6rem',
                backgroundColor: '#F7FAFC',
              }}
            />
          </Box>
        </Box>
      )}

      <PopContent>
        <Markup content={data?.pop} />
      </PopContent>
      {data?.pop_files && !!data.pop_files.length && (
        <PopFiles>
          <Flex alignItems="center" marginTop="1.5rem" marginBottom="0.5rem">
            <Text color="#262626" fontWeight="600" fontSize="lg">
              Arquivos
            </Text>
          </Flex>
          <Box id="containerFiles">
            {data.pop_files.map((file) => {
              const fileDir = `${process.env.REACT_APP_API_STORAGE}/${file.dir}`;
              return (
                <LinkDownload key={file.pivot.arquivo_id}>
                  <Flex
                    flexDir={['column', 'row']}
                    className="item-file"
                    alignItems="center"
                    justifyContent="space-between"
                    boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);"
                    borderRadius="0.5rem"
                    p="1.1rem"
                    position="relative"
                  >
                    <a href={fileDir} target="__blank">
                      <Image
                        minWidth="34px"
                        width="34px"
                        mb={[3, 0]}
                        src={
                          file.mimetype
                            ? getPreviewFile(
                                fileDir,
                                file.mimetype,
                                file.extensao,
                              )
                            : FileIconWithType({ type: 'default' })
                        }
                        objectFit="cover"
                        cursor="pointer"
                      />
                    </a>
                    <a href={fileDir} target="__blank">
                      <Flex
                        className="img-text-file"
                        alignItems="center"
                        minH="80px"
                      >
                        <Box className="info-text-file">
                          <Text noOfLines={2} maxWidth="180px">
                            {file.nome_original}
                          </Text>
                          <Text fontSize="xs">{file.tamanho}</Text>
                        </Box>
                      </Flex>
                    </a>
                    <IconButton
                      className="button-download"
                      aria-label="Download"
                      icon={<FiDownload />}
                      size="md"
                      height="2.1rem"
                      minWidth="2.1rem"
                      background="transparent"
                      position={['absolute', 'initial']}
                      right="5px"
                      top="5px"
                      onClick={() =>
                        download(
                          document.body,
                          file.dir,
                          file.nome_original,
                          file.mimetype,
                        )
                      }
                    />
                  </Flex>
                </LinkDownload>
              );
            })}
          </Box>
        </PopFiles>
      )}
    </ModalChakra>
  );
};

export default Pop;
