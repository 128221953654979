import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const CardLicense = styled(Flex)`
  cursor: pointer;
  &:hover {
    border-color: #63b3ed;
    button {
      background: #63b3ed;
    }
    p {
      color: #63b3ed;
    }
  }
`;

export const HoverAvatar = styled.div`
  position: relative;
  height: 64px;
  width: 64px;
  border-radius: 100%;
  margin-left: 10px;

  a {
    text-decoration: none;
  }

  button {
    position: absolute;
    left: 0;
    top: 0;
    background: #fffc;
    object-fit: cover;
    object-position: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 2px solid #acacac;
    opacity: 0;
  }

  &:hover {
    a {
      color: black;
    }

    button {
      display: flex;
      background: #fffc;
      opacity: 1;

      svg {
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
