import styled, { css } from 'styled-components';

export const Container = styled.div``;

interface ContainerBSCProps {
  alingGrid: boolean;
}

export const ContainerBSC = styled.div<ContainerBSCProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 700px));
  gap: 2rem;
  justify-content: space-around;
  // width: 100%;

  /* @media (max-width: 1577px) {
    grid-template-columns: repeat(auto-fit, minmax(auto, 1fr)) !important;
  } */
  ${(props) =>
    props.alingGrid &&
    css`
      grid-template-columns: auto;
    `}
`;

export const ContainerBSCEmployees = styled.div`
  border-top: 1px solid #d5dee5;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 650px));
  gap: 3rem;
  //transform: scale(0.75);
  padding-top: 3rem;
  //margin-left: -15rem;
  justify-content: space-around;
  // width: 100%;
`;
