import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

import {
  Box,
  Flex,
  Button,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { WorkDeal } from '~/modules/management/pages/BSCManagement';
import api from '~/shared/services/api';

import ModalIndicatorsStep, {
  ModalGeneralIndicators,
} from '../../Modal/ModalIndicatorsStep';
import {
  DatasetsBSC,
  EmployeePerformData,
  TeamPerformData,
  AccumulatedPoints,
} from '../@types/chart';
import { SkeletonLegendBSC, ButtonsBSC, BarContent } from './styles';

interface Props {
  id: number;
  name: string;
  mode: 'employee' | 'team';
  workDeal?: WorkDeal;
  title?: string;
  maxYAxes: number;
  stepSize: number;
  enableDrillDownButtons?: boolean;
  defaultPerformDataSet?: AccumulatedPoints[];
  selectChart?(value: number): void;
}

const BarChartBSC: React.FC<Props> = ({
  id,
  name,
  mode,
  workDeal,
  title,
  maxYAxes,
  stepSize,
  enableDrillDownButtons = false,
  defaultPerformDataSet,
  selectChart,
  children,
}) => {
  // const month = date; // Os meses no JS começa do 0

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenTeam,
    onOpen: onOpenTeam,
    onClose: onCloseTeam,
  } = useDisclosure();

  const [selectedStep, setSelectedStep] = useState(0);
  const [selectedCompetence, setSelectedCompentece] = useState('');
  const [selectedYear, setSelectedYear] = useState(0);

  const [selectedSemester, setSelectedSemester] = useState<string[]>([]);
  const [selectedWorkDealId, setSelectedWorkDealId] = useState<number>();

  const possiblePointsChartColor = 'rgba(0, 112, 192, 0.8)';
  const finalPointsChartColor = 'rgb(0, 176, 80)';

  const displayLabels = [true, true, true, true, true, true];

  const [loading, setLoading] = useState(!defaultPerformDataSet);

  const [performDataSets, setPerformDataSets] = useState<
    DatasetsBSC | undefined
  >();

  const [finalPointsStep, setFinalPointsStep] = useState(0);
  const [possiblePointsStep, setPossibilePointsStep] = useState(0);

  useEffect(() => {
    function handleGetCurrentSemester(finalDate: Date): string[] {
      const workDealDate = new Date(finalDate);
      const finalMonth = workDealDate.getMonth();

      setSelectedYear(workDealDate.getFullYear());
      const semesters = [
        ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
        ['Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      ];

      return finalMonth < 6 ? semesters[0] : semesters[1];
    }

    function handleSetAccumulatedPoints(data: AccumulatedPoints[]): void {
      const possiblePoints: (number | null)[] = [];
      const finalPoints: (number | null)[] = [];
      const possiblePointsRaw: number[] = [];
      const finalPointsRaw: number[] = [];

      /* data.map((dt) => dt.etapa_acordo_trabalho);

      data[0].etapa_acordo_trabalho;
      data[1].etapa_acordo_trabalho; */
      // console.log(data[i].etapa_acordo_trabalho);

      for (let step = 1; step <= 6; step += 1) {
        const foundStepData = data.find(
          (dt) => dt.etapa_acordo_trabalho === step,
        );
        if (foundStepData) {
          possiblePoints.push(
            Math.round(foundStepData.pontuacao_possivel_acumulada_etapa),
          );
          possiblePointsRaw.push(
            foundStepData.pontuacao_possivel_acumulada_etapa,
          );

          finalPoints.push(
            Math.round(foundStepData.pontuacao_final_acumulada_etapa),
          );
          finalPointsRaw.push(foundStepData.pontuacao_final_acumulada_etapa);
        } else {
          possiblePoints.push(null);
          possiblePointsRaw.push(0);

          finalPoints.push(null);
          finalPointsRaw.push(0);
        }
      }
      /* do {
        const foundStepData = data.find(
          (dt) => dt.etapa_acordo_trabalho === step,
        );
        if (foundStepData) {
          possiblePoints.push(
            Math.round(foundStepData.pontuacao_possivel_acumulada_etapa),
          );
          possiblePointsRaw.push(
            foundStepData.pontuacao_possivel_acumulada_etapa,
          );

          finalPoints.push(
            Math.round(foundStepData.pontuacao_final_acumulada_etapa),
          );
          finalPointsRaw.push(foundStepData.pontuacao_final_acumulada_etapa);
        } else {
          possiblePoints.push(null);
          possiblePointsRaw.push(0);

          finalPoints.push(null);
          finalPointsRaw.push(0);
        }
        step += 1;
        console.log(foundStepData);
      } while (step < 6); */

      /* data.forEach((dataPerf) => {
        if (countStep === dataPerf.etapa_acordo_trabalho) {
        possiblePoints.push(
          Math.round(dataPerf.pontuacao_possivel_acumulada_etapa),
        );
        possiblePointsRaw.push(dataPerf.pontuacao_possivel_acumulada_etapa);

        finalPoints.push(Math.round(dataPerf.pontuacao_final_acumulada_etapa));
        finalPointsRaw.push(dataPerf.pontuacao_final_acumulada_etapa);
        } else {
          possiblePoints.push(0);
          possiblePointsRaw.push(0);

          finalPoints.push(0);
          finalPointsRaw.push(0);
        }
      }); */
      setPerformDataSets({
        possiblePoints,
        finalPoints,
        possiblePointsRaw,
        finalPointsRaw,
      });
    }

    if (defaultPerformDataSet) {
      // console.log('teste', defaultPerformDataSet);
      if (workDeal) {
        setSelectedSemester(handleGetCurrentSemester(workDeal?.data_fim));
        setSelectedWorkDealId(workDeal.id);
      }

      handleSetAccumulatedPoints(defaultPerformDataSet);
      return;
    }

    setLoading(true);

    async function getEmployeePerformanceBSC(): Promise<void> {
      await api
        .get<EmployeePerformData>(`analytic/bsc/employee/${id}`, {
          params: {
            work_deal_id: workDeal?.id,
          },
        })
        .then((response) => {
          const { data, acordo_trabalho } = response.data;

          // Verificar se é necessário retornar um 404 do backend nesse caso
          if (!data.length) return;

          setSelectedWorkDealId(acordo_trabalho.id);

          setSelectedSemester(
            handleGetCurrentSemester(acordo_trabalho.data_fim),
          );

          handleSetAccumulatedPoints(data);
          // const lastStep = data[data.length - 1].etapa_acordo_trabalho;
        })
        .finally(() => {
          setLoading(false);
        });
    }
    async function getDepartmentPerformanceBSC(): Promise<void> {
      await api
        .get<TeamPerformData>(`analytic/bsc/department/${id}`, {
          params: {
            work_deal_id: workDeal?.id,
          },
        })
        .then((response) => {
          const { data, acordo_trabalho } = response.data;

          setSelectedWorkDealId(acordo_trabalho.id);

          if (!data.length) return;

          // console.log(acordo_trabalho.id);

          setSelectedSemester(
            handleGetCurrentSemester(acordo_trabalho.data_fim),
          );

          handleSetAccumulatedPoints(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (mode === 'employee') {
      getEmployeePerformanceBSC();
    } else {
      getDepartmentPerformanceBSC();
    }
  }, [id, mode, defaultPerformDataSet, workDeal]);

  const handleDrillDownChart = useCallback(
    (index) => {
      setFinalPointsStep(
        performDataSets?.finalPointsRaw?.length
          ? performDataSets?.finalPointsRaw[index]
          : 0,
      );

      setPossibilePointsStep(
        performDataSets?.possiblePointsRaw?.length
          ? performDataSets?.possiblePointsRaw[index]
          : 0,
      );

      /* console.log(
        performDataSets?.finalPointsRaw?.length
          ? performDataSets?.finalPointsRaw[index]
          : 0,
      ); */

      // console.log(performDataSets ? index : 0, performDataSets?.finalPointsRaw);

      const month = selectedSemester.find((semester, key) => key === index);

      setSelectedCompentece(`${month}/${selectedYear}`);
      setSelectedStep(index + 1);
      // Abrirá o modal para exibir detalhadamente os indicadores
      onOpen();
    },

    [performDataSets, onOpen, selectedYear, selectedSemester],
  );

  const handleElementChart = useCallback(
    (chartElement) => {
      // console.log(chartElement);

      if (chartElement[0]?._view) {
        const index = chartElement[0]?._index;

        if (selectChart) selectChart(index);

        handleDrillDownChart(index);
      }
    },
    [selectChart, handleDrillDownChart],
  );

  return (
    <>
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        w="full"
      >
        <Text textAlign="center" fontSize="3xl" fontWeight="bold" mb={5}>
          {/* Aqui era sua pontuação do bsc, se precisar passar dessa forma, adicionar uma prop, isProfilePage, ou algo do tipo */}
          {title || `Pontuação ${name}`}
        </Text>
        {!loading && enableDrillDownButtons && (
          <ButtonsBSC>
            {children}
            <Button
              onClick={() => {
                // getEmployeePerformIndicatorAll();
                onOpenTeam();
              }}
              colorScheme="blue"
              borderColor="#0070c0cc"
              color="#0070c0cc"
              variant="outline"
              disabled={!loading && !performDataSets}
              title={
                !loading && !performDataSets
                  ? 'Nenhuma pontuação foi encontrada'
                  : ''
              }
            >
              {`Indicadores Gerais ${mode === 'team' ? 'Eq.' : ''}`}
            </Button>

            {/*  <Button
              onClick={() => {
                //getEmployeePerformIndicator(page);
                //onOpen();
              }}
              bgColor="#00b050"
              color="#fff"
              _hover={{ bgColor: '#00be56' }}
            >
              {`Indicadores do Período ${mode === 'team' ? 'Eq.' : ''}`}
            </Button> */}
          </ButtonsBSC>
        )}
        {/* <Flex
          justifyContent="center"
          alignItems="center"
          width="50vw"
          borderTop="1px solid #d5dee5"
        /> */}
      </Flex>
      <Box width={{ sm: 'full', xl: '700px' }}>
        {performDataSets && !loading && (
          <Box width="full" mb={5}>
            <BarContent
              flexDir={['column', 'row']}
              alignItems="center"
              justifyContent={['center', 'space-between']}
            >
              <Bar
                // redraw
                // legend={{ display: true }}
                /* getElementAtEvent={(e) => {
                console.log(e);
              }} */
                getElementAtEvent={(e) => {
                  handleElementChart(e);
                  // if (selectChart) selectChart(e);
                }}
                type="bar"
                plugins={[ChartDataLabels]}
                legend={{
                  position: 'bottom',
                  labels: { fontSize: 15 },
                }}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          min: 0,
                          max: maxYAxes,
                          stepSize,
                          padding: 10,
                        },
                      },
                    ],
                  },
                  layout: {
                    padding: {
                      top: 32,
                    },
                  },
                  animation: {
                    // easing: 'easeOutQuint',
                    duration: 5000,
                    // animateScale: true,
                  },
                }}
                data={{
                  labels: selectedSemester,
                  datasets: [
                    {
                      label: 'Pontuação Possível',
                      data: performDataSets.possiblePoints,
                      borderColor: possiblePointsChartColor,
                      pointBorderColor: possiblePointsChartColor,
                      pointBackgroundColor: possiblePointsChartColor,
                      backgroundColor: possiblePointsChartColor,
                      lineTension: 0,
                      fill: true,
                      // hoverBackgroundColor: 'blue',
                      // pointStyle: 'dash',
                      // borderDashOffset: 1,
                      borderDash: [8, 5],
                      // borderDashOffset: 10,
                      // hoverRadius: 10,
                      // pointRadius: 10,
                      // pointStyle: 'rectRounded',
                      // pointBorderWidth: 10,
                      datalabels: {
                        display: displayLabels,
                        // anchor: 'center',
                        borderRadius: 5,
                        // backgroundColor: backgroundPossiblePointsChart,
                        borderWidth: 8,
                        color: possiblePointsChartColor, // '#000000',
                        font(context) {
                          const widthChart = context.chart?.width;
                          const sizeFont = Math.round(
                            widthChart ? widthChart / 40 : 0,
                          );

                          return {
                            weight: 'bold',
                            size: sizeFont,
                          };
                        },
                        // borderWidth: 50,
                        padding: -5,
                        align: 'top',
                        anchor: 'end',
                      },
                    },
                    {
                      label: 'Pontuação Alcançada',
                      // teste
                      // data: [...performDataSets.finalPoints, 50],
                      data: performDataSets.finalPoints,
                      borderColor: finalPointsChartColor,
                      pointBorderColor: finalPointsChartColor,
                      pointBackgroundColor: finalPointsChartColor,
                      backgroundColor: finalPointsChartColor,
                      // hoverBackgroundColor: 'green',
                      fill: false,
                      datalabels: {
                        display: displayLabels,
                        borderRadius: 5,
                        // backgroundColor: backgroundPossiblePointsChart,
                        borderWidth: 8,
                        color: finalPointsChartColor, // '#000000'
                        font(context) {
                          const widthChart = context.chart?.width;
                          const sizeFont = Math.round(
                            widthChart ? widthChart / 40 : 0,
                          );

                          return {
                            weight: 'bold',
                            size: sizeFont,
                          };
                        },
                        padding: -5,
                        align: 'top',
                        anchor: 'end',
                      },
                    },
                  ],
                }}
              />
            </BarContent>
          </Box>
        )}

        {loading && (
          <Flex
            flexDir="column"
            width={{ sm: 'full', xl: '700px' }}
            alignItems="center"
            justifyContent="center"
            mb={5}
          >
            <Box w="full">
              {/* Buttons */}
              <SkeletonLegendBSC>
                <Skeleton height="40px" width="220px" />
                {/*  <Skeleton height="40px" width="220px" /> */}
              </SkeletonLegendBSC>

              {/* Substituindo a propriedade height por padding-bottom */}
              <Skeleton pb="50%" width="calc(100% - 30px)" />
            </Box>
            <Flex mt={3}>
              <Skeleton height="25px" width="220px" />
              <Skeleton height="25px" width="220px" />
            </Flex>
          </Flex>
        )}
        {!loading && selectedWorkDealId && (
          <>
            <ModalIndicatorsStep
              id={id}
              isOpen={isOpen}
              onClose={onClose}
              onCLickStep={(step) => handleDrillDownChart(step - 1)}
              step={selectedStep}
              workDealId={selectedWorkDealId}
              mode={mode}
              title={name}
              competence={selectedCompetence}
              pontuacaoFinalAcumulada={finalPointsStep.toFixed(2)}
              pontuacaoPossivelAcumulada={possiblePointsStep.toFixed(2)}
            />
            <ModalGeneralIndicators
              id={id}
              isOpen={isOpenTeam}
              onClose={onCloseTeam}
              workDealId={selectedWorkDealId}
              mode={mode}
              title={`Indicadores de ${name}`}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default BarChartBSC;
