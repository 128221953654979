import { Form } from '@unform/web';
import styled, { keyframes, css } from 'styled-components';

const transformAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0px);
  }
`;

const pulseAnimation = keyframes`
  0%
  {
    transform: scale(.8);
  }
  20%
  {
    transform: scale(1);
  }
  40%
  {
    transform: scale(.8);
  }
  60%
  {
    transform: scale(1);
  }
  80%
  {
    transform: scale(.8);
  }
  100%
  {
    transform: scale(.8);
  }
`;

interface MenuProps {
  active: boolean;
}

export const FormCustom = styled(Form)`
  width: 100%;
  padding: 0 20px;
`;

export const Container = styled.div<MenuProps>`
  position: fixed;
  //padding-top: 64px;
  z-index: 1;
  min-width: 250px;
  width: 250px;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    left: 0;
    min-height: calc(100vh - 64px);
    background: ${({ theme }) => theme.colors.background_gradient};
    width: 100%;
    display: none;

    ${(props) =>
      props.active &&
      css`
        display: flex;
        animation: 0.4s ${transformAnimation};
        z-index: 3;
      `}
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 256px;
  padding-top: 30px;
  position: relative;

  > strong {
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 8px;
    margin-bottom: 4px;
    width: 100%;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);

    span {
      //margin-right: 10px;

      & + span {
        margin-right: 0;
        margin-left: 10px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -10px;
          top: 5px;
          width: 4px;
          height: 4px;
          background: rgba(255, 255, 255, 0.6);
          border-radius: 50%;
        }
      }
    }
  }
`;

export const ProfileLinks = styled.div`
  width: 100%;
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  a {
    position: relative;
    flex: 1;
    border: 1px solid rgba(255, 255, 255, 0.6);
    color: ${({ theme }) => theme.colors.link_light};
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    > span {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      align-items: center;
      display: flex;
      animation: 1s infinite ${pulseAnimation};
    }

    span.notification {
      position: absolute;
      right: 0;
      top: 0;
      height: 7px;
      width: 7px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.warning};
    }

    > svg {
      margin-right: 8px;
    }
  }
`;

export const LinkOldSystem = styled.a.attrs({
  target: '_parent',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #fff;
  background: linear-gradient(90deg, #211fa0, #245ddc, #245ddc, #211fa0);
  font-weight: 500;

  svg {
    margin-right: 10px;
  }
`;
