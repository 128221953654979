import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { FaArrowsAltH, FaBullseye, FaRedo } from 'react-icons/fa';
import { FiArrowRight, FiHelpCircle } from 'react-icons/fi';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  Progress,
  Skeleton,
  Badge,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Step } from 'intro.js';
import queryString from 'query-string';

import BadgeStatusInfo from '~/modules/dashboard/components/BadgeStatusInfo';
import ToggleCustom from '~/modules/dashboard/components/ToggleCustom';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';

import { BoxCustom, CustomBoxSectionHeader } from './styles';

interface AllocationEmployeeProps {
  departamentoId: string;
  alocacaoReal: string;
  alocacaoRealMetaDia: string;
  planejamentosPrevistos: PlannigProvided[];
  alocacaoPrevistaHoras: string;
  alocacaoPrevistaRealizadas: string;
  alocacaoRealizadaStatus: AllocationDoneStatus[];
  dadosUsuario: DataUser;
  atividadesAlocacao: ActivitieAllocation[];
  porcentagemAlocacao: number;
  porcentagemAlocacaoMetaDia: number;
  porcentagemAlocacaoPrevista: number;
  porcentagemAlocacaoPrevistaRealizadas: number;
  formatSelectedDate: string;
}

interface ActivitieAllocation {
  devolvida: boolean;
  planejamento_id: number;
  alocacao_real: string;
  alocacao_real_segundos: number;
  alocacao_prevista: string;
  funcionario_id: number;
  data_inicio_planejado: string;
  hora_inicio_planejado: string;
  data_fim_planejado: string;
  hora_fim_planejado: string;
  format_date_start_plannig: string;
  format_date_end_plannig: string;
  status_id: number;
  atendimento_id: number;
  inicio_atendimento: string;
  fim_atendimento: string;
  alocacao_valida: string;
  situacao_prazo_atividade: string;
  atividade_nome: string;
  demanda_id: number;
  nome_fantasia: string;
  razao_social: string;
  demanda_nome: string;
  id_status: string;
  status_nome: string;
  status_cor: string;
  alocacao_prevista_exata: string;
}

interface DataUser {
  id: number;
  nome: string;
  total_turnos: string;
  total_turnos_seg: number;
}

interface AllocationDoneStatus {
  alocacao_status_ordem: number;
  alocacao_status_cor: string;
  alocacao_status: string;
  alocacao_real: string;
}

interface PlannigProvided {
  id: number;
  planejamento_id: number;
  devolvida: boolean;
  atividade_duracao: string;
  alocacao_prevista: string;
  atividade_duracao_seg: number;
  data_inicio_planejado: string;
  hora_inicio_planejado: string;
  data_fim_planejado: string;
  hora_fim_planejado: string;
  date_diff: number;
  status: number;
  atendimento_id: number;
  inicio_atendimento: string;
  fim_atendimento: string;
  atividade_nome: string;
  demanda_id: number;
  nome_fantasia: string;
  razao_social: string;
  demanda_nome: string;
  id_status: number;
  status_nome: string;
  status_cor: string;
  alocacao_prevista_exata: string;
}

interface QueryParams {
  department_id?: string;
  select_date?: string;
  start_date?: string;
  end_date?: string;
  adjust?: string;
}

interface ParamsUrl {
  id?: string;
}

const AllocationEmployee: React.FC = () => {
  const location = useLocation();
  const { id: employeeId } = useParams<ParamsUrl>();

  const [user, setUser] = useState<DataUser>();
  const [allocation, setAllocation] = useState<AllocationEmployeeProps>();
  const [activitiesAllocation, setActivitiesAllocation] = useState<
    ActivitieAllocation[]
  >([]);
  const [allocationDoneStatus, setAllocationDoneStatus] = useState<
    AllocationDoneStatus[]
  >([]);
  const [enableTourAllocation, setEnableTourAllocation] = useState(false);
  const [stepsAllocation, setStepsAllocation] = useState<Step[]>([
    {
      element: '#execRealizada',
      title: 'Execução Realizada',
      intro:
        'Representa o tempo total gasto pelo funcionário na execução das atividades que foram iniciadas/retomadas no dia.',
    },
    {
      element: '#execPrevista',
      title: 'Execução Prevista',
      intro:
        'Representa o tempo total previsto para a finalização das atividades que o funcionário iniciou/retomou no dia.',
    },
    {
      element: '#meta',
      title: 'Meta Diária',
      intro:
        'Exibe a alocação prevista através da duração das atividades planejadas para o funcionário no dia comparando com as atividades que ele executou desse mesmo planejamento.',
    },
  ]);

  const [loading, setLoading] = useState(true);

  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const [
    toggleAdjustExpectedAllocation,
    setToggleAdjustExpectedAllocation,
  ] = useState(() => !queryParams?.adjust || queryParams?.adjust === '1');

  /* const goLink = useMemo(() => {
    // Lembrando que o ajuste da alocação prevista pode alterar
    return `/relatorios/alocacao?start_date=${queryParams.start_date}&end_date${
      queryParams.end_date
    }&adjust=${toggleAdjustExpectedAllocation ? 1 : 0}`;
  }, [queryParams, toggleAdjustExpectedAllocation]); */

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'P/ PREVISTO',
      },
      {
        title: 'P/ REALIZADO',
      },
      {
        title: 'ALOCAÇÃO DO DIA',
      },
      {
        title: 'ATIVIDADE',
        align: 'center',
      },
      {
        title: 'DEMANDA',
      },
      {
        title: 'EMPRESA',
      },
      {
        title: 'STATUS',
      },
    ];
  }, []);

  useEffect(() => {
    setLoading(true);

    api
      .get<AllocationEmployeeProps>(
        `/allocation/department/employee/${employeeId}`,
        {
          params: {
            department_id: queryParams.department_id,
            select_date: queryParams.select_date,
            adjust_expected_allocation: toggleAdjustExpectedAllocation ? 1 : 0,
          },
        },
      )
      .then((response) => {
        const allocationPercentAndTime = response.data;
        const {
          dadosUsuario,
          atividadesAlocacao,
          alocacaoRealizadaStatus,
        } = response.data;

        setUser({
          ...dadosUsuario,
          total_turnos: dadosUsuario?.total_turnos
            ? format(
                parseISO(
                  `${queryParams.select_date} ${dadosUsuario.total_turnos}`,
                ),
                "HH'h'",
              )
            : '',
        });
        setAllocation({
          ...allocationPercentAndTime,
          formatSelectedDate: queryParams.select_date
            ? format(parseISO(queryParams.select_date), "EEEE',' dd/MM/yyyy", {
                locale: ptBR,
              })
            : '',
          alocacaoReal: allocationPercentAndTime.alocacaoReal
            ? format(
                parseISO(
                  `${queryParams.select_date} ${allocationPercentAndTime.alocacaoReal}`,
                ),
                "HH'h 'mm'min'",
              )
            : '',
          alocacaoPrevistaRealizadas:
            allocationPercentAndTime.alocacaoPrevistaRealizadas &&
            allocationPercentAndTime.alocacaoPrevistaRealizadas < '24:00:00'
              ? format(
                  parseISO(
                    `${queryParams.select_date} ${allocationPercentAndTime.alocacaoPrevistaRealizadas}`,
                  ),
                  "HH'h 'mm'min'",
                )
              : allocationPercentAndTime.alocacaoPrevistaRealizadas ||
                '' /* Em algumas alocações do funcionario a data prevista excede 24h fazendo com q o format de erro */,
          alocacaoRealMetaDia: allocationPercentAndTime.alocacaoRealMetaDia
            ? format(
                parseISO(
                  `${queryParams.select_date} ${allocationPercentAndTime.alocacaoRealMetaDia}`,
                ),
                "HH'h 'mm'min'",
              )
            : '',
          alocacaoPrevistaHoras:
            allocationPercentAndTime.alocacaoPrevistaHoras &&
            allocationPercentAndTime.alocacaoPrevistaHoras < '24:00:00'
              ? format(
                  parseISO(
                    `${queryParams.select_date} ${allocationPercentAndTime.alocacaoPrevistaHoras}`,
                  ),
                  "HH'h 'mm'min'",
                )
              : allocationPercentAndTime.alocacaoPrevistaHoras || '',
          /*  porcentagemAlocacao: format(
            parseISO(allocationPercentAndTime.porcentagemAlocacao.toString()),
            'H',
          ), */
        });
        setActivitiesAllocation([
          ...atividadesAlocacao.map((actAlloc) => {
            return {
              ...actAlloc,
              inicio_atendimento: actAlloc.inicio_atendimento
                ? format(
                    parseISO(actAlloc.inicio_atendimento),
                    'dd LLL, HH:mm',
                    { locale: ptBR },
                  )
                : '',
              fim_atendimento: actAlloc.fim_atendimento
                ? format(parseISO(actAlloc.fim_atendimento), 'dd LLL, HH:mm', {
                    locale: ptBR,
                  })
                : '',
              format_date_start_plannig: actAlloc.data_inicio_planejado
                ? format(
                    parseISO(
                      `${actAlloc.data_inicio_planejado} ${actAlloc.hora_inicio_planejado}`,
                    ),
                    'dd LLL, HH:mm',
                    { locale: ptBR },
                  )
                : '',
              format_date_end_plannig: actAlloc.data_fim_planejado
                ? format(
                    parseISO(
                      `${actAlloc.data_fim_planejado} ${actAlloc.hora_fim_planejado}`,
                    ),
                    'dd LLL, HH:mm',
                    { locale: ptBR },
                  )
                : '',
              alocacao_prevista: actAlloc.alocacao_prevista
                ? format(
                    parseISO(
                      `${queryParams.select_date} ${actAlloc.alocacao_prevista}`,
                    ),
                    "HH'h'mm'min'",
                  )
                : '',
              alocacao_real: actAlloc.alocacao_real
                ? format(
                    parseISO(
                      `${queryParams.select_date} ${actAlloc.alocacao_real}`,
                    ),
                    "HH'h'mm'min'",
                  )
                : '',
              alocacao_prevista_exata: actAlloc.alocacao_prevista_exata
                ? format(
                    parseISO(
                      `${queryParams.select_date} ${actAlloc.alocacao_prevista_exata}`,
                    ),
                    "HH'h'mm",
                  )
                : '',
            };
          }),
        ]);
        setAllocationDoneStatus(alocacaoRealizadaStatus);
      }) /*
      .catch((err) => {
        console.log(err);
      }) */
      .finally(() => setLoading(false));
  }, [queryParams, employeeId, toggleAdjustExpectedAllocation]);

  const goLink = useMemo(() => {
    // Lembrando que o ajuste da alocação prevista pode alterar
    return `/relatorios/alocacao/${queryParams.department_id}?start_date=${
      queryParams.start_date
    }&end_date=${queryParams.end_date}&adjust=${
      toggleAdjustExpectedAllocation ? 1 : 0
    }`;
  }, [queryParams, toggleAdjustExpectedAllocation]);

  const getColorAllocation = useCallback((value: string) => {
    let color;

    switch (value) {
      case 'Atrasadas':
        color = '#e32121';
        break;
      case 'atrasada':
        color = '#e32121';
        break;
      case 'regular':
        color = '#00c851';
        break;
      case 'Regulares':
        color = '#00c851';
        break;
      default:
        color = '#219ce3';
        break;
    }
    return color;
  }, []);
  return (
    <Box>
      <Flex
        flexDir={['column', 'row']}
        justifyContent={['center', 'space-between']}
        height={['auto', '120px']}
      >
        <Flex flexDir={['column', 'row']}>
          <CustomBoxSectionHeader>
            <SectionHeader
              title={`Alocação ${user?.nome || ''}`}
              pagename={`Funcionário(a): ${user?.nome}`}
              goBackLink={goLink}
              loading={loading}
              fullSkeleton
            />
          </CustomBoxSectionHeader>
          {loading ? (
            <Flex
              height="75px"
              mt={-6}
              mb={4}
              mx={3}
              alignItems="center"
              justifyContent="center"
              display={['flex', 'none']}
              flexWrap="nowrap"
            >
              <Skeleton height="60px" width="80px" />
              <Skeleton height="60px" width="80px" ml={3} />
              <Skeleton height="60px" width="80px" ml={3} />
            </Flex>
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              flexWrap="nowrap"
              borderLeft={['', 'solid 1px #219ce3']}
              mx={[0, 3]}
              height="75px"
              mt={[-6, 0]}
              mb={[4, 0]}
            >
              {allocationDoneStatus.map((allocPercent, index) => {
                const key = index + 1;
                return (
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    mt={[0, 3]}
                    ml={3}
                    key={key}
                  >
                    <Text
                      color={getColorAllocation(allocPercent.alocacao_status)}
                      fontSize={['2xl', '3xl']}
                      ml={2}
                    >
                      {Number(allocPercent.alocacao_real).toFixed(1)}%
                    </Text>
                    <Text
                      fontSize="md"
                      color={getColorAllocation(allocPercent.alocacao_status)}
                    >
                      {allocPercent.alocacao_status}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          )}
        </Flex>
        <Flex
          // display={['none', 'flex']}
          flexDir="column"
          alignItems={['start', 'end']}
          mr={[0, 3]}
        >
          <ToggleCustom
            defaultIsChecked={toggleAdjustExpectedAllocation}
            toogleAdjust={() =>
              setToggleAdjustExpectedAllocation((state) => !state)
            }
            loading={loading}
            tittle="Ajustar alocação prevista"
            id="adjustExpectedAllocation"
          />
          {loading ? (
            <Skeleton height={['30px', '36px']} width="195px" />
          ) : (
            <Text fontSize={['xl', '2xl']} color="#6c757d">
              {allocation?.formatSelectedDate}
            </Text>
          )}

          <Button
            aria-label="help"
            onClick={() => {
              setEnableTourAllocation(true);
            }}
            variant="outline"
            colorScheme="blue"
            mt={3}
            alignSelf="end"
            height={[8, 12]}
          >
            <FiHelpCircle />
            <Text ml={2} display={['none', 'block']}>
              Ajuda
            </Text>
          </Button>
        </Flex>
      </Flex>
      {loading && (
        <Flex flexDir="column">
          <Flex
            flexDir="column"
            justifyContent={['center', 'start']}
            // align={['center', 'start']}
            mb={3}
          >
            <Flex display={['none', 'flex']}>
              <Text mr={2}>Funcionário(a):</Text>
              <Skeleton height="20px" width="150px" />
            </Flex>
            <Flex>
              <Text mr={2}>Turno:</Text>
              <Skeleton height={['24px', '20px']} width="150px" />
            </Flex>
          </Flex>
          <Flex flexDir={['column', 'row']} mb={2}>
            <Text mr={2} as="b">
              EXECUÇÃO REALIZADA:
            </Text>
            <Skeleton height="20px" width="150px" />
          </Flex>
          <Flex flexDir={['column', 'row']} mb={2}>
            <Text mr={2} as="b">
              EXECUÇÃO PREVISTA:
            </Text>
            <Skeleton height="20px" width="150px" />
          </Flex>
          <Box>
            <Flex alignItems="center">
              <FaBullseye color="#2175e3" />
              <Text fontSize="lg" as="b" ml={2} color="#2175e3">
                META DIÁRIA
              </Text>
            </Flex>
            <Text pl={6} as="b" fontSize="sm">
              PLANEJAMENTO:
            </Text>
            <Flex alignItems="center" height="24px">
              <Progress
                ml={6}
                colorScheme="blue"
                size="lg"
                value={undefined}
                width="250px"
              />
              <Skeleton height="20px" width="50px" ml={2} />
            </Flex>

            <Text pl={6} as="b" fontSize="sm">
              EXECUÇÃO:
            </Text>
            <Flex alignItems="center" height="24px">
              <Progress
                ml={6}
                colorScheme="green"
                size="lg"
                value={undefined}
                width="250px"
              />
              <Skeleton height="20px" width="50px" ml={1} />
            </Flex>
          </Box>
        </Flex>
      )}

      {!loading && user && allocation && (
        <Box>
          <Flex flexDir="column" mb={3}>
            <Flex display={['none', 'flex']}>
              <Text mr={2}>Funcionário(a):</Text>
              <Text as="b" fontSize={['sm', 'md']}>
                {user?.nome}
              </Text>
            </Flex>
            <Text>Turno: {user?.total_turnos}</Text>
          </Flex>
          <Flex
            flexDir={['column', 'row']}
            alignItems="start"
            id="execRealizada"
            width={['280px', '400px']}
            mb={2}
          >
            <Text as="b" mr={3}>
              EXECUÇÃO REALIZADA:
            </Text>
            <Flex alignItems="center" flexWrap="nowrap">
              <Text>{allocation?.alocacaoReal}</Text>
              <FiArrowRight />
              <Text ml={[0, 3]}>
                {allocation.porcentagemAlocacao
                  ? allocation.porcentagemAlocacao.toFixed(1)
                  : '0'}
                %
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDir={['column', 'row']}
            alignItems="start"
            id="execPrevista"
            width={['280px', '400px']}
            mb={2}
          >
            <Text as="b" mr={3}>
              EXECUÇÃO PREVISTA:
            </Text>
            <Flex alignItems="center" flexWrap="nowrap">
              <Text>{allocation?.alocacaoPrevistaRealizadas}</Text>
              <FiArrowRight />
              <Text ml={[0, 3]}>
                {allocation.porcentagemAlocacaoPrevistaRealizadas
                  ? allocation.porcentagemAlocacaoPrevistaRealizadas.toFixed(1)
                  : '0'}
                %
              </Text>
            </Flex>
          </Flex>
          <Box id="meta" width={['300px', '350px']}>
            <Flex alignItems="center">
              <FaBullseye color="#2175e3" />
              <Text fontSize="lg" as="b" ml={2} color="#2175e3">
                META DIÁRIA
              </Text>
            </Flex>

            <Text pl={6} as="b" fontSize="sm">
              PLANEJAMENTO: {allocation?.alocacaoPrevistaHoras}
            </Text>
            <Flex alignItems="center">
              <Progress
                ml={6}
                colorScheme="blue"
                size="lg"
                value={allocation?.porcentagemAlocacaoPrevista}
                width="250px"
              />
              <Text ml={3}>
                {allocation?.porcentagemAlocacaoPrevista
                  ? allocation.porcentagemAlocacaoPrevista.toFixed(1)
                  : '0'}
                %
              </Text>
            </Flex>

            <Text pl={6} as="b" fontSize="sm">
              EXECUÇÃO: {allocation?.alocacaoRealMetaDia}
            </Text>
            <Flex alignItems="center">
              <Progress
                ml={6}
                colorScheme="green"
                size="lg"
                value={allocation?.porcentagemAlocacaoMetaDia}
                width="250px"
              />
              <Text ml={3}>
                {allocation?.porcentagemAlocacaoMetaDia
                  ? allocation.porcentagemAlocacaoMetaDia.toFixed(1)
                  : '0'}
                %
              </Text>
            </Flex>
          </Box>
        </Box>
      )}

      <Box mt={3}>
        <Text fontSize="4xl" as="b" color="#6c757d">
          Atividades
        </Text>
      </Box>
      <Flex flexDir="column" alignItems={['start', 'inherit']} mt={2}>
        <Table theadData={tableTitles} loading={loading}>
          {activitiesAllocation?.map((actAlloc, index) => {
            const i = index;
            return (
              <Box key={i} as="tr" _hover={{ bg: 'gray.50' }}>
                <Flex as="td" py={3} alignItems="center">
                  <Box borderRight="solid 1px blue" minW="115px" mr={2} pr={2}>
                    <Text
                      color={
                        actAlloc.data_inicio_planejado ===
                          queryParams.select_date ||
                        actAlloc.data_fim_planejado === queryParams.select_date
                          ? '#00c851'
                          : ''
                      }
                    >
                      {actAlloc.format_date_start_plannig}
                    </Text>
                    <Text
                      color={
                        actAlloc.data_inicio_planejado ===
                          queryParams.select_date ||
                        actAlloc.data_fim_planejado === queryParams.select_date
                          ? '#00c851'
                          : ''
                      }
                      textAlign="end"
                    >
                      às
                    </Text>
                    <Text
                      color={
                        actAlloc.data_inicio_planejado ===
                          queryParams.select_date ||
                        actAlloc.data_fim_planejado === queryParams.select_date
                          ? '#00c851'
                          : ''
                      }
                    >
                      {actAlloc.format_date_end_plannig}
                    </Text>
                  </Box>
                  <Text
                    color={
                      actAlloc.data_inicio_planejado ===
                        queryParams.select_date ||
                      actAlloc.data_fim_planejado === queryParams.select_date
                        ? '#00c851'
                        : ''
                    }
                  >
                    {`${actAlloc.alocacao_prevista}${
                      actAlloc.alocacao_prevista_exata &&
                      actAlloc.data_inicio_planejado !==
                        actAlloc.data_fim_planejado
                        ? `(${actAlloc.alocacao_prevista_exata})`
                        : ''
                    }`}
                  </Text>
                </Flex>
                <Box as="td" py={3}>
                  <Flex alignItems="center">
                    <Box
                      borderRight="solid 1px blue"
                      minW="115px"
                      mr={2}
                      pr={2}
                    >
                      {actAlloc.inicio_atendimento ||
                      actAlloc.fim_atendimento ? (
                        <>
                          <Text
                            color={getColorAllocation(
                              actAlloc.situacao_prazo_atividade,
                            )}
                          >
                            {actAlloc.inicio_atendimento}
                          </Text>
                          {actAlloc.fim_atendimento ? (
                            <Text
                              color={getColorAllocation(
                                actAlloc.situacao_prazo_atividade,
                              )}
                              textAlign="end"
                            >
                              às
                            </Text>
                          ) : (
                            ''
                          )}

                          <Text
                            color={getColorAllocation(
                              actAlloc.situacao_prazo_atividade,
                            )}
                          >
                            {actAlloc.fim_atendimento}
                          </Text>
                        </>
                      ) : (
                        <BadgeStatusInfo
                          status={actAlloc.status_nome}
                          statusColor={actAlloc.status_cor}
                        />
                      )}
                    </Box>
                    <Text
                      color={getColorAllocation(
                        actAlloc.situacao_prazo_atividade,
                      )}
                    >
                      {actAlloc.alocacao_real}
                    </Text>
                  </Flex>
                </Box>
                <Box as="td" py={3} textAlign="center">
                  {!!actAlloc.alocacao_valida && (
                    <>
                      {actAlloc.status_id === 16 &&
                        !actAlloc.fim_atendimento && (
                          <Text textAlign="center">0</Text>
                        )}
                      {actAlloc.status_id !== 16 ||
                        (actAlloc.fim_atendimento && user && (
                          <Text
                            color={getColorAllocation(
                              actAlloc.situacao_prazo_atividade,
                            )}
                            textAlign="center"
                            as="b"
                          >
                            {(
                              (actAlloc.alocacao_real_segundos * 100) /
                              user.total_turnos_seg
                            ).toFixed(1)}
                            %
                          </Text>
                        ))}
                    </>
                  )}
                  {!actAlloc.alocacao_valida && (
                    <Text textAlign="center">--</Text>
                  )}
                </Box>
                <Box as="td" py={3}>
                  <Flex alignItems="center">
                    <Tooltip
                      label={actAlloc.atividade_nome}
                      aria-label="Nome atividade"
                    >
                      <BoxCustom>{actAlloc.atividade_nome}</BoxCustom>
                    </Tooltip>
                    {!!actAlloc.devolvida && (
                      <Badge display="flex" alignItems="center" ml={2}>
                        <Text mr={2}>Devolvida</Text>
                        <FaRedo />
                      </Badge>
                    )}
                  </Flex>
                </Box>
                <Box as="td" py={3}>
                  <Tooltip
                    label={actAlloc.demanda_nome}
                    aria-label="Nome demanda"
                  >
                    <BoxCustom>{actAlloc.demanda_nome}</BoxCustom>
                  </Tooltip>
                </Box>
                <Box as="td" py={3}>
                  <Tooltip
                    label={actAlloc.nome_fantasia}
                    aria-label="Nome Fantasia"
                  >
                    <BoxCustom>{actAlloc.nome_fantasia}</BoxCustom>
                  </Tooltip>
                </Box>
                <Box as="td" py={3}>
                  <BadgeStatusInfo
                    status={actAlloc.status_nome}
                    statusColor={actAlloc.status_cor}
                  />
                </Box>
              </Box>
            );
          })}
        </Table>
      </Flex>
      <StepsIntro
        enabled={enableTourAllocation}
        onExit={() => setEnableTourAllocation(false)}
        steps={stepsAllocation}
      />
    </Box>
  );
};

export default AllocationEmployee;
