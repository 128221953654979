import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 150px;

  padding: 10px;

  p {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #8694a0;
    margin-bottom: 10px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8694a0;

    span {
      border: 1px solid #8694a0;
      border-radius: 5px;
      padding: 4px;
      margin: 2px;
      font-size: 16px;
      color: #f1f1f1;
      background-color: #2f2e38;
      font-weight: 400;
    }
  }
  strong {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2f2e38;

    small {
      padding: 0 4px;
      margin: 1px 5px;
      font-size: 13px;
      font-weight: 300;
      font-style: italic;
    }
  }
`;
