import React from 'react';
import ReactLoading from 'react-loading';

import { Box, Flex, Stack, Text } from '@chakra-ui/react';

import manutencaoMobIcon from '../../../../assets/manutencao_mob.png';
import manutencaoIcon from '../../../../assets/manutencaoP.png';
import {
  FlexBack,
  ImgMob,
  ImgWeb,
  TextTitle,
  TextDesc,
  ReactLoad,
} from './styles';

function Maintenance(): any {
  return (
    // definir div com valor menor que a pag
    /*    <div /> */

    <Box minH="100%" w="full" background="gray.100">
      <FlexBack
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        w="full"
      >
        <Box>
          <TextTitle>Site em manutenção</TextTitle>
        </Box>
        <Box w="full">
          <Flex
            background="gray.300"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            h="575px"
            w="full"
          >
            <ImgWeb
              src={manutencaoIcon}
              alt="Manutençao Web"
              style={{ height: '600px' }}
            />
            <ImgMob
              src={manutencaoMobIcon}
              alt="Manutenção Mobile"
              style={{ height: '300px' }}
            />
          </Flex>
          <Box p={8}>
            <TextDesc>Estamos fazendo alterações no site </TextDesc>
            <Flex justifyContent="center">
              <TextTitle>Aguarde</TextTitle>
              <ReactLoad type="bubbles" height="100%" color="#2d3748" />
            </Flex>
          </Box>
        </Box>
      </FlexBack>
    </Box>
  );
}

export default Maintenance;
