import { Box, Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const TdCustom = styled.td`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  label div {
    height: 70px;
  }
`;
