import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  Fragment,
} from 'react';
import { FaUsers } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';

import {
  Box,
  Flex,
  Text,
  Button,
  Tag,
  Badge,
  IconButton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import introJs, { Step } from 'intro.js';

import BarChartBSC from '~/shared/components/Chart/BarChartBSC';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import SectionHeader from '~/shared/components/SectionHeader';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';

import { ContainerBSC, ContainerBSCEmployees } from './styles';

interface Teams {
  id: number;
  nome: string;
  created_at: string;
  updated_at: string;
}

export interface WorkDeal {
  id: number;
  nome: string;
  data_inicio: Date;
  data_fim: Date;
  created_at: string;
  updated_at: string;
  current_deal: boolean;
  value: number;
  label: string;
}

interface FiltersProps {
  work_deals: WorkDeal[];
  teams?: Teams[];
  periods?: string[];
}

interface BSCManagementProps {
  id: number;
  departamento_id: number;
  equipe: string;
  etapas: Etapas[];
  show?: boolean;
  lastStep: number;
}

interface PerformanceEmployees {
  id: number;
  funcionario: string;
  etapas: EtapasEmployee[];
  possiblePoints: number[];
  finalPoints: number[];
  show?: boolean;
  lastStep: number;
}

interface Etapas {
  departamento_id: number;
  etapa_acordo_trabalho: number;
  pontuacao_equipe_total_acordo: number;
  pontuacao_equipe_possivel_etapa: number;
  pontuacao_equipe_final_etapa: number;
  pontuacao_possivel_acumulada_etapa: number;
  pontuacao_final_acumulada_etapa: number;
  total_base_300_etapa: number;
  total_base_300_geral_loading: number;
  desempenho_geral: number;
}

interface EtapasEmployee {
  etapa_acordo_trabalho: number;
  pontuacao_individual_total_acordo: number;
  pontuacao_individual_possivel_etapa: number;
  pontuacao_individual_final_etapa: number;
  pontuacao_possivel_acumulada_etapa: number;
  pontuacao_final_acumulada_etapa: number;
  total_base_200_etapa: number;
  total_base_200_geral_loading: number;
  desempenho_geral: number;
}

interface Selected {
  id: number;
  name: string;
  lastStep: number;
  finalPointsAcumulated: number;
}

const BSCManagement: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const introRef = useRef<HTMLDivElement[]>([]);

  const [bscTeam, setBscTeam] = useState<BSCManagementProps[]>([]);
  const [bscFiltersTeam, setBscFiltersTeam] = useState<FiltersProps>();
  const [bscEmployees, setBscEmployees] = useState<PerformanceEmployees[]>([]);
  const [workDeal, setWorkDeal] = useState(1);
  const [selectedWorkDeal, setSelectedWorkDeal] = useState<WorkDeal>();
  const [chartFocus, setChartFocus] = useState(false);
  const [loadingBSC, setLoadingBSC] = useState(true);
  const [loadingEmployeesBSC, setLoadingEmployeesBSC] = useState(true);
  const [enableTourBSC, setEnableTourBSC] = useState(false);
  const [stepsBSC, setStepsBSC] = useState<Step[]>([
    {
      element: '#containerTeamsBSC',
      title:
        'Nessa página, você acompanha os indicadores de todas as equipes do BSC',
      intro:
        'Clique na barra dos gráficos para exibir a pontuação de uma etapa específica da equipe selecionada',
    },
    {
      element: '.team_graph',
      title: 'Visualizando pontuação em um mês específico',
      intro:
        'Cada gráfico exibe o desempenho da equipe até o último mês do acordo de trabalho selecionado. Clique nas barras do gráfico para exibir a pontuação da equipe em um mês específico',
    },
  ]);

  const date = new Date();
  const month = date.getMonth() + 1;

  const getInitialPoints = useCallback(() => {
    return month < 6 ? [0] : [];
  }, [month]);

  useEffect(() => {
    api.get<FiltersProps>('analytic/bsc/filters').then((response) => {
      const { work_deals } = response.data;
      setSelectedWorkDeal(work_deals.find((work) => work.current_deal));
      setBscFiltersTeam({
        work_deals: work_deals.map((wd) => {
          return {
            ...wd,
            label: wd.nome,
            value: wd.id,
          };
        }),
      });

      // console.log(work_deals[0]);
    });
  }, [getInitialPoints]);

  useEffect(() => {
    setLoadingBSC(true);
    setChartFocus(false);
    api
      .get<BSCManagementProps[]>('analytic/bsc', {
        params: { work_deal_id: selectedWorkDeal?.id },
      })
      .then((response) => {
        const { data } = response;

        setBscTeam(
          data.map((bscData) => {
            return {
              ...bscData,
              show: true,
            };
          }),
        );
      })
      .finally(() => setLoadingBSC(false));
  }, [selectedWorkDeal]);

  /* const handleDrillDownChartPersonal = useCallback(
    (value: string | number): void => {
      const indexLabel = bscDataSets?.finalPoints.findIndex(
        (label) => label === value,
      );
      // getEmployeePerformIndicator(page);
      // indexLabel ? onOpen() : '';
    },
    [bscDataSets],
  ); */

  const getEmployeeData = useCallback(
    (bscEquipeId: number) => {
      setLoadingEmployeesBSC(true);
      api
        .get<PerformanceEmployees[]>(`analytic/bsc/${bscEquipeId}`, {
          params: { work_deal_id: selectedWorkDeal?.id },
        })
        .then((response) => {
          const { data } = response;

          let possiblePoints: number[] = [];
          let finalPoints: number[] = [];
          setBscEmployees(
            data.map((employee) => {
              employee.etapas.forEach((stage) => {
                possiblePoints.push(
                  Math.round(stage.pontuacao_possivel_acumulada_etapa),
                );
                finalPoints.push(
                  Math.round(stage.pontuacao_final_acumulada_etapa),
                );
              });

              const graphEmployee: PerformanceEmployees = {
                ...employee,
                finalPoints,
                possiblePoints,
                lastStep:
                  employee.etapas[employee.etapas.length - 1]
                    .etapa_acordo_trabalho,
                show: true,
              };
              possiblePoints = [];
              finalPoints = [];
              return graphEmployee;
            }),
          );
        })
        .finally(() => setLoadingEmployeesBSC(false));
    },
    [selectedWorkDeal],
  );

  const handleSubmit = useCallback(
    (data) => {
      try {
        formRef.current?.setErrors({});
        setSelectedWorkDeal(
          bscFiltersTeam?.work_deals.find(
            (wd) => wd.id === Number(data.work_deal),
          ),
        );
        setWorkDeal(data.work_deal);
        // dataBSC();
      } catch (error) {
        // console.log('a');
      }
    },
    [bscFiltersTeam],
  );

  const handleShowGraph = useCallback(
    (bscEquipeId: number, focus: boolean): void => {
      /* Não será necessário utilziar o ref.current.focus, para a rolagem subir e focar o elemento na parte de cima,
      pois a rolagem só não está subindo no momento por conta do ContainerEmployees fixo para testes */
      setChartFocus(focus);
      setBscTeam((state) =>
        state.map((bsc) => {
          return {
            ...bsc,
            show: bsc.id !== bscEquipeId ? !focus : true,
          };
        }),
      );
      getEmployeeData(bscEquipeId);
    },
    [getEmployeeData],
  );

  return (
    <Box h="full" position="relative">
      {loadingBSC && <LoadingAbsolute z_index={1111} min_height={500} />}
      <SectionHeader
        title="Balanced Scorecard"
        pagename="Listar resultados do BSC"
      />
      {bscFiltersTeam && (
        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          initialData={{ work_deal: selectedWorkDeal?.id }}
        >
          <Flex w="full" justifyContent="space-between">
            <Box w={['full', '400px']}>
              <SelectChakra
                name="work_deal"
                options={bscFiltersTeam.work_deals}
                onChange={() => formRef.current?.submitForm()}
              />
            </Box>

            <Button
              aria-label="help"
              onClick={() => {
                setEnableTourBSC(true);
              }}
              variant="outline"
              colorScheme="blue"
            >
              <FiHelpCircle />
              <Text ml={2}>Ajuda</Text>
            </Button>
          </Flex>
        </Form>
      )}

      <Box id="containerTeamsBSC">
        {!loadingBSC && (
          <ContainerBSC alingGrid={chartFocus}>
            {bscTeam?.map((data) => {
              return (
                data?.show && (
                  <Fragment key={`${data.id}.${data.equipe}`}>
                    {chartFocus && (
                      <Box>
                        <Tag colorScheme="blue" mr={2} mb={2}>
                          <Badge
                            variant="subtle"
                            mr={1}
                            bg="blue.200"
                            color="white"
                          >
                            Equipe:
                          </Badge>
                          {data.equipe}
                        </Tag>
                      </Box>
                    )}
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      flexDir="column"
                      className="team_graph"
                      id={`${data.departamento_id}_introjs`}
                    >
                      <BarChartBSC
                        id={data.departamento_id}
                        name={data.equipe}
                        workDeal={selectedWorkDeal}
                        enableDrillDownButtons
                        defaultPerformDataSet={data.etapas}
                        mode="team"
                        maxYAxes={300}
                        stepSize={30}
                      >
                        {!chartFocus ? (
                          <Button
                            onClick={() =>
                              handleShowGraph(data.id, !chartFocus)
                            }
                            mb={4}
                            colorScheme="blue"
                            borderColor="#0070c0cc"
                            color="#fff"
                          >
                            <FaUsers size={24} />
                            <Text ml={3}>Colaboradores</Text>
                          </Button>
                        ) : (
                          <Flex justifyContent="center" mb={4}>
                            <Button
                              onClick={() =>
                                handleShowGraph(data.id, !chartFocus)
                              }
                              mb={4}
                              colorScheme="blue"
                              borderColor="#0070c0cc"
                              color="#fff"
                              w="200px"
                              mr={4}
                            >
                              <FaUsers size={24} />
                              <Text ml={3}>Exibir Equipes</Text>
                            </Button>
                          </Flex>
                        )}
                      </BarChartBSC>
                    </Flex>
                  </Fragment>
                )
              );
            })}
          </ContainerBSC>
        )}

        {chartFocus && (
          <ContainerBSCEmployees>
            {!loadingEmployeesBSC ? (
              bscEmployees.map((employee) => {
                return (
                  <Flex flexDir="column" key={employee.id}>
                    <BarChartBSC
                      id={employee.id}
                      title={employee.funcionario}
                      name={employee.funcionario}
                      enableDrillDownButtons
                      workDeal={selectedWorkDeal}
                      defaultPerformDataSet={employee.etapas}
                      mode="employee"
                      maxYAxes={200}
                      stepSize={30}
                    />
                  </Flex>
                );
              })
            ) : (
              <Loading />
            )}
          </ContainerBSCEmployees>
        )}
      </Box>

      <StepsIntro
        enabled={enableTourBSC}
        onExit={() => setEnableTourBSC(false)}
        steps={stepsBSC}
      />
    </Box>
  );
};

export default BSCManagement;
