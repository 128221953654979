import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    outline: 0;
  }

  html, body {
    min-height: 100vh;
    /* o antd adiciona um height 100%, o que não cobre toda a tela */
    height: auto;
  }

  body, input, textarea, button, a {
    font-family: 'Nunito', 'Roboto', sans-serif;
    font-size: 16px;
  }

  body {
    background: ${(props) => props.theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:active {
      opacity: 0.6;
    }
  }

  .draft-editor a {
    color: #1890ff;
  }

  .draft-editor a:hover {
    color: #40a9ff;
    text-decoration: underline;
  }

  button {
    cursor: pointer;
  }

  @-webkit-keyframes autofill {
    to {
        font-family: 'Nunito', sans-serif;
        color: ${(props) => props.theme.colors.text_input};
        background: transparent;
        font-size: 16px;
      }
  }
  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-text-size-adjust: 16px !important;
    -webkit-text-fill-color: ${(props) =>
      props.theme.colors.text_input} !important;
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) =>
      theme.colors.shape_input} inset !important;
  }

  /*React Select*/
  .react-select__control {
    min-height: 40px !important;
    border-radius: 10px !important;

    .react-select__value-container.react-select__value-container--has-value {
      padding-left: 16px !important;
    }
  }

  table tbody tr td {
    border-top: 1px solid #edf2f7;
    color: #4A5568;
  }


  /** MEDIAS */
  @media (max-width: 480px) {
    .mobile-none {
      display: none !important;
      visibility: hidden;
    }
  }
  .none {
    display: none !important;
    visibility: hidden;
  }
`;
