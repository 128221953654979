import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { FiChevronDown } from 'react-icons/fi';

import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Tag,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import DoughnutChart from '~/modules/dashboard/components/Doughnut';
import TableDemand from '~/modules/dashboard/components/TableDemand';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';

import { Card } from './styles';

export interface Demand {
  nome: string;
  id: string;
}

export interface QuantityStatusDemand {
  delayed: number;
  delayed_fatal_date: number;
  delay_forecast: number;
  running: number;
}

export interface Demands {
  demands: Demand[];
  total: QuantityStatusDemand;
}

export interface OptionDemand {
  label: string;
  value: string | number;
}

export interface Department {
  id: number;
  nome: string;
  total: number;
}

export interface DepartmentProps {
  data: Department[];
  labels: string[];
  dataLabels: number[];
  value: number[];
}

export interface DemandData {
  id: number;
  qtd_planejamentos: number;
  competencia?: string;
  descricao?: string;
  nome_empresa: string;
  nome_demanda: string;
  demanda_id: number;
  created_at: string;
}

const AnalyzeDemand: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const addToast = useToast();

  const [loading, setLoading] = useState(false);

  const [disableNextPage, setDisableNextPage] = useState(false);
  const [page, setPage] = useState(1);

  const [demands, setDemands] = useState<OptionDemand[]>([]);
  const [demandsData, setDemandsData] = useState<DemandData[]>([]);
  const [totalStatus, setTotalStatus] = useState<number[]>([]);

  const [departments, setDepartments] = useState<DepartmentProps>(
    {} as DepartmentProps,
  );
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [statusDemand, setStatusDemand] = useState('');
  const [selectedDemand, setSelectedDemand] = useState('');
  const [selectedDemandId, setSelectedDemandId] = useState<number>();
  const demandChartLabes = useMemo(() => {
    return {
      labels: [
        'Atrasadas planejamento',
        'Atrasada data fatal',
        'Previsão de atraso',
        'Em execução',
      ],
      dataLabels: [
        'delayed',
        'delayed_fatal_date',
        'delay_forecast',
        'running',
      ],
    };
  }, []);

  const loadDemandsData = useCallback(() => {
    setLoading(true);
    setSelectedDepartment('');
    setDepartments({} as DepartmentProps);
    setPage(2);
    api
      .get<Demands>('analytic/demand')
      .then((response) => {
        const dataDemand = response.data;
        const optionsDemands = dataDemand.demands?.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        const {
          delayed,
          delayed_fatal_date,
          delay_forecast,
          running,
        } = dataDemand.total;

        setDemands([{ label: 'Todas', value: 'all' }, ...optionsDemands]);
        setTotalStatus([delayed, delayed_fatal_date, delay_forecast, running]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadDemandsData();
  }, [loadDemandsData]);

  const handleSubmit = useCallback(
    (data) => {
      setLoading(true);
      setSelectedDemand('');
      setSelectedDemandId(undefined);

      if (!data.demand || data.demand === 'all') {
        loadDemandsData();
        return;
      }

      setSelectedDemandId(data.demand);
      setDepartments({
        data: [],
        labels: [],
        dataLabels: [],
        value: [],
      });
      setSelectedDepartment('');

      api
        .get<QuantityStatusDemand>(`analytic/demand/${data.demand}`)
        .then((response) => {
          const {
            delayed,
            delayed_fatal_date,
            delay_forecast,
            running,
          } = response.data;

          setTotalStatus([
            delayed,
            delayed_fatal_date,
            delay_forecast,
            running,
          ]);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [loadDemandsData],
  );

  const handleLoadDepartment = useCallback(
    (status: string | number): void => {
      setLoading(true);
      setPage(2);

      const urlD = selectedDemandId
        ? `analytic/demand/departments/${status}/${selectedDemandId}`
        : `analytic/demand/departments/${status}`;

      api
        .get<Department[]>(urlD)
        .then((response) => {
          const dataDepartments = response.data;
          if (dataDepartments.length > 0) {
            const labels = dataDepartments.map((department) => department.nome);
            const dataLabels = dataDepartments.map(
              (department) => department.id,
            );
            const value = dataDepartments.map((department) => department.total);

            setDepartments({
              data: dataDepartments,
              labels,
              dataLabels,
              value,
            });
          } else {
            setDepartments({
              data: [],
              labels: [],
              dataLabels: [],
              value: [],
            });
          }
        })
        .catch((err) => {
          if (err.response.error) {
            addToast({
              position: 'top-right',
              isClosable: true,
              status: 'error',
              title: 'Ooops!!!',
              description:
                err.response.data?.error ||
                `Ocorreu um erro, tente novamente. Cod: ${err.response.status}`,
            });
          } else {
            addToast({
              position: 'top-right',
              isClosable: true,
              status: 'error',
              title: 'Ooops!!!',
              description: `Ocorreu um erro, tente novamente. Cod: ${err.response.status}`,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [selectedDemandId, addToast],
  );

  const handleSelectDemand = useCallback(
    (value: string | number): void => {
      const indexLabel = demandChartLabes.dataLabels.findIndex(
        (label) => label === value,
      );
      // console.log(indexLabel, 'teste');
      setSelectedDemand(demandChartLabes.labels[indexLabel]);
      handleLoadDepartment(value);
      setStatusDemand(String(value));
      setSelectedDepartment('');
    },
    [demandChartLabes, handleLoadDepartment],
  );

  const handleSelectDepartment = useCallback(
    (id: string | number) => {
      setLoading(true);
      setPage(2);
      setDisableNextPage(false);

      const findDepartment = departments.data.find(
        (department) => department.id === id,
      );

      if (findDepartment) {
        setSelectedDepartment(findDepartment.nome);
      }

      // chamar rota para listar demandas
      const hasIdDemand = selectedDemandId ? `/${selectedDemandId}` : '';
      api
        .get(
          `analytic/demand/departments/list/${id}/${statusDemand}${hasIdDemand}`,
        )
        .then((response) => {
          if (response.data?.last_page && response.data?.last_page === 1) {
            setDisableNextPage(true);
          } else {
            setDisableNextPage(false);
          }
          setDemandsData(response.data?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [departments.data, selectedDemandId, statusDemand],
  );

  const handleNewPageList = useCallback(() => {
    setLoading(true);

    const findDepartment = departments.data.find(
      (department) => department.nome === selectedDepartment,
    );

    if (!findDepartment) {
      addToast({
        position: 'top-right',
        isClosable: true,
        status: 'error',
        title: 'Ooops!!!',
        description: 'Não foi possível carregar mais dados. Tente novamente!',
      });

      return;
    }
    // chamar rota para listar demandas
    const hasIdDemand = selectedDemandId ? `/${selectedDemandId}` : '';
    api
      .get(
        `analytic/demand/departments/list/${findDepartment.id}/${statusDemand}${hasIdDemand}`,
        {
          params: {
            page,
          },
        },
      )
      .then((response) => {
        if (response.data?.last_page && response.data?.last_page === page) {
          setDisableNextPage(true);
        } else {
          setDisableNextPage(false);
          setPage(page + 1);
        }

        setDemandsData([...demandsData, ...response.data?.data]);
      })
      .catch((err) => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response?.data?.error ||
            `Ocorreu um erro, tente novamente. Cod: ${err.response?.status}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    selectedDemandId,
    statusDemand,
    demandsData,
    addToast,
    page,
    departments.data,
    selectedDepartment,
  ]);

  return (
    <Box>
      <SectionHeader
        title="Relatório de Demandas"
        pagename="Análise de atendimentos por demandas e departamentos"
        goBackPage
      />

      <Flex flexDir="column" w="full" mb={5}>
        <Form
          className="formSelect"
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            demand: 'all',
          }}
        >
          <Flex alignItems="flex-start" w="full" maxWidth={['350px', '545px']}>
            <SelectChakra
              name="demand"
              placeholder={!loading ? undefined : 'Carregando...'}
              options={demands}
              containerStyle={{ width: '100%' }}
              onChange={() => formRef.current?.submitForm()}
              disabled={loading}
              width="full"
            />
          </Flex>
        </Form>
      </Flex>

      <Flex mb={4} flexWrap="wrap" alignItems="center">
        {selectedDemand && (
          <Tag colorScheme="blue" mr={2} mb={2}>
            <Badge variant="subtle" mr={1} bg="blue.200" color="white">
              Status:
            </Badge>
            {selectedDemand}
          </Tag>
        )}

        {selectedDepartment && (
          <Tag colorScheme="blue" mr={2} mb={2}>
            <Badge variant="subtle" mr={1} bg="blue.200" color="white">
              Departamento:
            </Badge>
            {selectedDepartment}
          </Tag>
        )}
      </Flex>

      <Flex
        justifyContent="space-evenly"
        flexDir={['column', 'row']}
        position="relative"
      >
        {loading && <LoadingAbsolute />}
        {totalStatus.length > 0 && (
          <Box width={['auto', '500px']} mb={[3, 0]}>
            <Card>
              <DoughnutChart
                data={{
                  datasets: [
                    {
                      data: [...totalStatus],
                    },
                  ],
                  title: 'Atendimentos por Status',
                  labels: demandChartLabes.labels,
                  dataLabels: demandChartLabes.dataLabels,
                }}
                selectChart={(value) => handleSelectDemand(value)}
              />
            </Card>
          </Box>
        )}

        {departments && departments?.value?.length > 0 && (
          <Box width={['auto', '500px']} mb={[3, 0]}>
            <Card>
              <DoughnutChart
                data={{
                  datasets: [
                    {
                      data: [...departments.value],
                    },
                  ],
                  title: 'Atividades por Departamentos',
                  labels: departments.labels,
                  dataLabels: departments.dataLabels,
                }}
                selectChart={(value) => handleSelectDepartment(value)}
                colors={[
                  '#C4F1F9',
                  '#76E4F7',
                  '#00B5D8',
                  '#805AD5',
                  '#00B5D8',
                  '#D53F8C',
                ]}
              />
            </Card>
          </Box>
        )}
      </Flex>

      {demandsData.length > 0 && selectedDepartment && (
        <>
          <Box
            w="full"
            height={4}
            display="flex"
            justifyContent="space-between"
            my={5}
            opacity={0.7}
          >
            <Divider w="full" mr={8} />
            <Divider w="full" />
          </Box>
          <Flex>
            <TableDemand demands={demandsData} status={selectedDemand} />
          </Flex>

          <Flex justifyContent="center">
            <Button
              mt={5}
              mx="auto"
              variant="ghost"
              colorScheme="teal"
              rightIcon={<FiChevronDown />}
              isLoading={loading}
              isDisabled={disableNextPage}
              onClick={handleNewPageList}
            >
              Carregar mais
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default AnalyzeDemand;
