import React from 'react';
import { Switch } from 'react-router-dom';

import ActivitiesStatus from '~/modules/accessControl/pages/ActivitiesStatus';
import Permission from '~/modules/accessControl/pages/Permission';
import Responsibility from '~/modules/accessControl/pages/Responsibility';
import Role from '~/modules/accessControl/pages/Role';
import { StructureConsumption } from '~/modules/accessControl/pages/StructureConsumption';
import { StructureConsumptionDepartment } from '~/modules/accessControl/pages/StructureConsumption/StructureConsumptionDepartment';
import StructureConsumptionEmployee from '~/modules/accessControl/pages/StructureConsumption/StructureConsumptionEmployee';
import User from '~/modules/accessControl/pages/User';
import { JourneyWork } from '~/modules/accessControl/pages/User/JourneyWork';
import { UserEdit } from '~/modules/accessControl/pages/User/UserEdit';
import Planning from '~/modules/activityExecution/pages/Planning';
import PlanningInfo from '~/modules/activityExecution/pages/PlanningInfo';
import SendEmailToClient from '~/modules/activityExecution/pages/SendEmailToClient';
import ForgotPassword from '~/modules/auth/pages/ForgotPassword';
import ResetPassword from '~/modules/auth/pages/ResetPassword';
import SignIn from '~/modules/auth/pages/SignIn';
import SignUp from '~/modules/auth/pages/SignUp';
import ActivitiesAttendance from '~/modules/dashboard/pages/ActivitiesAttendance';
import ActivityExecutionReport from '~/modules/dashboard/pages/ActivitiesExecutionReport';
import ActivitiesReport from '~/modules/dashboard/pages/ActivitiesReport';
import AllocationGraph from '~/modules/dashboard/pages/AllocationGraph';
import AllocationDepartment from '~/modules/dashboard/pages/AllocationGraph/AllocationDepartment';
import AllocationEmployee from '~/modules/dashboard/pages/AllocationGraph/AllocationEmployee';
import AnalyzeActivities from '~/modules/dashboard/pages/AnalyzeActivities';
import AnalyzeDemand from '~/modules/dashboard/pages/AnalyzeDemand';
import Dashboard from '~/modules/dashboard/pages/Dashboard';
import IndividualPerformance from '~/modules/dashboard/pages/IndividualPerformance';
import MonthlyDemands from '~/modules/dashboard/pages/MonthlyDemand';
import WeeklyDemands from '~/modules/dashboard/pages/WeeklyDemands';
import EstructureDefault from '~/modules/documents/pages/EstructureDefault';
import EstructureDefaultShow from '~/modules/documents/pages/EstructureDefaultShow';
import Files from '~/modules/documents/pages/Files';
import Concept from '~/modules/gdp/Concept';
import Dimension from '~/modules/gdp/Dimension';
import PerformanceReview from '~/modules/gdp/PerformanceReview';
import ShowPerformanceReview from '~/modules/gdp/PerformanceReview/ShowPerformanceReview';
import SettingsPerformanceReview from '~/modules/gdp/SettingsPerformanceReview';
import AttendancesPlanning from '~/modules/management/AttendancesPlanning';
import Attendances from '~/modules/management/pages/Attendances';
import BSCManagement from '~/modules/management/pages/BSCManagement';
import CompanyClient from '~/modules/management/pages/CompanyClient';
import CorporateEmail from '~/modules/management/pages/CorporateEmail';
import DemandActivities from '~/modules/management/pages/DemandActivities';
import DemandActivitiesList from '~/modules/management/pages/DemandActivities/DemandActivitiesList';
import ActivityCreate from '~/modules/management/pages/DemandActivities/DemandActivitiesList/ActivityCreate';
import Department from '~/modules/management/pages/Department';
import ManageDepartment from '~/modules/management/pages/Department/ManageDepartment';
import Maintenance from '~/modules/management/pages/Maintenance';
import Signature from '~/modules/management/pages/Signature';
import SignatureCreate from '~/modules/management/pages/Signature/SignatureCreate';
import Profile from '~/modules/user/pages/Profile';
import LayoutAuth from '~/shared/layouts/auth';
import LayoutDefault from '~/shared/layouts/default';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} layout={LayoutAuth} />
    <Route
      path="/signin/:email?"
      exact
      component={SignIn}
      layout={LayoutAuth}
    />

    <Route
      path="/dashboard"
      component={Dashboard}
      isPrivate
      // permissions="list-dashboard"
      layout={LayoutDefault}
    />

    <Route path="/cadastro" component={SignUp} layout={LayoutAuth} />
    <Route
      path="/esqueci-senha"
      component={ForgotPassword}
      layout={LayoutAuth}
    />
    <Route
      path="/redefinir-senha"
      component={ResetPassword}
      layout={LayoutAuth}
    />

    <Route
      path="/relatorios/avaliacao-desempenho/:id"
      component={ShowPerformanceReview}
      isPrivate
      permissions="list-performance-reviews"
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/avaliacao-desempenho"
      component={PerformanceReview}
      isPrivate
      permissions="list-performance-reviews"
      layout={LayoutDefault}
    />
    {/* <Route
      path="/relatorios/avaliacao-desempenho"
      component={PerformanceReview}
      isPrivate
      layout={LayoutDefault}
    /> */}

    <Route
      path="/relatorios/alocacao/funcionario/:id"
      component={AllocationEmployee}
      isPrivate
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/alocacao/:id"
      component={AllocationDepartment}
      isPrivate
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/alocacao"
      component={AllocationGraph}
      isPrivate
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/analise-de-demandas"
      component={AnalyzeDemand}
      isPrivate
      permissions="list-analysis-demand"
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/analise-de-atividades"
      component={AnalyzeActivities}
      isPrivate
      permissions="list-analysis-demand"
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/desempenho-individual"
      component={IndividualPerformance}
      isPrivate
      permissions="list-individual-performance"
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/demandas-semanais"
      component={WeeklyDemands}
      isPrivate
      permissions="list-weekly-demands"
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/demandas-mensais"
      component={MonthlyDemands}
      isPrivate
      permissions="list-monthly-demands"
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/atividades/execucao"
      component={ActivityExecutionReport}
      isPrivate
      // permissions="list-signature"
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/atividades"
      component={ActivitiesReport}
      isPrivate
      permissions="list-report-activities"
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/consumo-estrutura/funcionarios/:funcionarioId"
      component={StructureConsumptionEmployee}
      isPrivate
      permissions="show-structure-consumption"
      layout={LayoutDefault}
    />

    <Route
      path="/relatorios/consumo-estrutura/empresas/:empresaId"
      component={StructureConsumptionDepartment}
      isPrivate
      permissions="show-structure-consumption"
      layout={LayoutDefault}
    />
    <Route
      path="/relatorios/consumo-estrutura"
      component={StructureConsumption}
      isPrivate
      permissions="show-structure-consumption"
      layout={LayoutDefault}
    />

    <Route
      path="/atendimento/atividades/:id"
      component={ActivitiesAttendance}
      isPrivate
      permissions=""
      layout={LayoutDefault}
    />

    <Route
      path="/atividade/cadastrar/:demandId"
      component={ActivityCreate}
      isPrivate
      permissions=""
      layout={LayoutDefault}
    />

    <Route
      path="/atividade/editar/:activityId"
      component={ActivityCreate}
      isPrivate
      permissions=""
      layout={LayoutDefault}
    />

    <Route
      path="/dimensao/:dimensionId/conceito"
      component={Concept}
      isPrivate
      permissions=""
      layout={LayoutDefault}
    />

    <Route
      path="/dimensao"
      component={Dimension}
      isPrivate
      permissions="list-dimension-gdp"
      layout={LayoutDefault}
    />

    {/* As rotas derivadas devem vir antes das principais, como no exemplo a seguir /demandas/{id} e /demandas */}
    <Route
      path="/demandas/:demandId"
      component={DemandActivitiesList}
      isPrivate
      permissions="list-demands"
      layout={LayoutDefault}
    />

    <Route
      path="/demandas"
      component={DemandActivities}
      isPrivate
      permissions="list-demands"
      layout={LayoutDefault}
    />

    <Route
      path="/perfil"
      component={Profile}
      isPrivate
      layout={LayoutDefault}
    />

    <Route
      path="/planejamento/:planningId/informacoes"
      component={PlanningInfo}
      isPrivate
      permissions="execute-activity"
      layout={LayoutDefault}
    />

    <Route
      path="/planejamento/:planningId/enviar-email"
      component={SendEmailToClient}
      isPrivate
      permissions="execute-activity"
      layout={LayoutDefault}
    />
    <Route
      path="/planejamento"
      component={Planning}
      isPrivate
      permissions="execute-activity"
      layout={LayoutDefault}
    />

    {/** Gerenciamento */}
    <Route
      path="/gerenciar/departamentos"
      component={Department}
      isPrivate
      permissions="manage-department"
      layout={LayoutDefault}
    />
    <Route
      path="/gerenciar/departamento/:departmentId"
      component={ManageDepartment}
      isPrivate
      permissions="manage-department"
      layout={LayoutDefault}
    />
    <Route
      path="/gerenciar/atendimentos/:attendanceId"
      component={AttendancesPlanning}
      isPrivate
      permissions=""
      layout={LayoutDefault}
    />
    <Route
      path="/gerenciar/atendimentos"
      component={Attendances}
      isPrivate
      permissions="list-attendances"
      layout={LayoutDefault}
    />

    <Route
      path="/gerenciar/email"
      component={CorporateEmail}
      isPrivate
      permissions="list-emails"
      layout={LayoutDefault}
    />

    <Route
      path="/empresas-cliente"
      component={CompanyClient}
      isPrivate
      permissions="list-companies"
      layout={LayoutDefault}
    />

    {/** Controle de acesso */}

    <Route
      path="/controle/atividades/status"
      component={ActivitiesStatus}
      isPrivate
      permissions="activity-control"
      layout={LayoutDefault}
    />

    <Route
      path="/controle/cargos"
      component={Responsibility}
      isPrivate
      permissions="list-responsibility"
      layout={LayoutDefault}
    />
    <Route
      path="/controle/usuarios/jornada/:userId"
      component={JourneyWork}
      isPrivate
      permissions="list-user"
      layout={LayoutDefault}
    />
    <Route
      path="/controle/usuarios/:userId"
      component={UserEdit}
      isPrivate
      permissions="list-user"
      layout={LayoutDefault}
    />
    <Route
      path="/controle/usuarios"
      component={User}
      isPrivate
      permissions="list-user"
      layout={LayoutDefault}
    />
    <Route
      path="/controle/funcoes"
      component={Role}
      isPrivate
      permissions="list-role"
      layout={LayoutDefault}
    />
    <Route
      path="/controle/permissoes"
      component={Permission}
      isPrivate
      permissions="list-permission"
      layout={LayoutDefault}
    />
    {/* <Route
      path="/controle"
      exact
      component={Control}
      isPrivate
      layout={LayoutDefault}
    /> */}

    {/** Estrutura Padrão de Documentos */}
    <Route
      path="/documentos/estrutura-padrao/:id"
      component={EstructureDefaultShow}
      isPrivate
      permissions="list-estructure-default"
      layout={LayoutDefault}
    />
    <Route
      path="/documentos/estrutura-padrao"
      component={EstructureDefault}
      isPrivate
      permissions="list-estructure-default"
      layout={LayoutDefault}
    />

    <Route
      path="/arquivos"
      component={Files}
      isPrivate
      permissions="list-files"
      layout={LayoutDefault}
    />

    <Route
      path="/bsc"
      component={BSCManagement}
      isPrivate
      permissions="list-bsc"
      layout={LayoutDefault}
    />
    <Route
      path="/assinaturas/:idSignature/usuarios"
      component={User}
      isPrivate
      permissions="list-user"
      layout={LayoutDefault}
    />
    <Route
      path="/assinaturas/cadastrar"
      component={SignatureCreate}
      isPrivate
      permissions="list-signature"
      layout={LayoutDefault}
    />
    <Route
      path="/assinaturas"
      component={Signature}
      isPrivate
      permissions="list-signature"
      layout={LayoutDefault}
    />

    <Route
      path="/configuracao-avaliacao"
      component={SettingsPerformanceReview}
      isPrivate
      // permissions="list-signature"
      layout={LayoutDefault}
    />

    <Route path="/manutencao" component={Maintenance} layout={LayoutAuth} />

    {/** 404 */}
    <Route
      path="*"
      component={() => <h1>Ooops! Página não encontrada.</h1>}
      isPrivate
      layout={LayoutDefault}
    />
  </Switch>
);

export default Routes;
