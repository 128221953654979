import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  FiEdit,
  FiLock,
  FiMail,
  FiPlus,
  FiShield,
  FiTrash,
} from 'react-icons/fi';

import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  Icon,
  IconButton,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import AlertDialog from '~/shared/components/AlertDialog';
import EmptyPage from '~/shared/components/EmptyPage';
import Input from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import { Container } from './styles';

interface CorporateEmailForm {
  id: number;
  email: string;
  password?: string;
  domain_id?: number;
}
interface CorporateEmail {
  id: number;
  company_id: number;
  email: string;
  created_at: string;
  updated_at: string;
}
interface CorporateEmailProps extends IPaginationProps {
  data: CorporateEmail[];
}

interface Domain {
  id: number;
  company_id: number;
  name: string;
  created_at: string;
  updated_at: string;
  value: number;
  label: string;
}

const CorporateEmail: React.FC = () => {
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formRef = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);

  const cancelDeleteRef = useRef(null);
  const btnCreateRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const [loadedDom, setLoadedDom] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const [
    selectedEmailEdit,
    setSelectedEmailEdit,
  ] = useState<CorporateEmailForm>({} as CorporateEmailForm);

  const [selectEmailDelete, setSelectEmailDelete] = useState(0);

  const [modalSuccess, setModalSuccess] = useState(0);
  const [page, setPage] = useState(1);
  const [domains, setDomains] = useState<Domain[]>([]);

  const [email, setEmail] = useState<CorporateEmailProps>(
    {} as CorporateEmailProps,
  );

  /* const [steps, setSteps] = useState(1); */

  const addToast = useToast();

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Id',
      },
      {
        title: 'Email',
      },
      {
        title: 'Criado em',
      },
      {
        title: 'Atualizado em',
      },
      {
        title: 'Ações',
      },
    ];
  }, []);

  useEffect(() => {
    setLoaded(false);

    api
      .get<CorporateEmailProps>('corporate-emails', {
        params: { page },
      })
      .then((response) => {
        const emailPag = response.data;
        setEmail({
          ...emailPag,
          data: emailPag.data.map((mail) => {
            return {
              ...mail,
              created_at: format(
                parseISO(mail.created_at),
                "dd/MM/yyyy 'às' HH:mm",
              ),
              updated_at: format(
                parseISO(mail.updated_at),
                "dd/MM/yyyy 'às' HH:mm",
              ),
            };
          }),
          /* console.log(response.data); */
        });
      })
      .finally(() => setLoaded(true));
  }, [page]);

  useEffect(() => {
    // Não haverá outro carregamento dessa function
    // setLoadedDom(false);
    api
      .get<Domain[]>('domains')
      .then((response) => {
        const { data } = response;

        setDomains(
          data.map((dom) => {
            return {
              ...dom,
              created_at: format(
                parseISO(dom.created_at),
                "dd/MM/yyyy 'às' HH:mm",
              ),
              updated_at: format(
                parseISO(dom.updated_at),
                "dd/MM/yyyy 'às' HH:mm",
              ),

              label: `@${dom.name}`,
              value: dom.id,
            };
          }),
        );
        /* console.log(d); */
        /* setDomains(d); */
        /* return {
    ...mail,
    created_at: format(parseISO(mail.created_at), "dd/MM/yyyy 'às' HH:mm"),
    updated_at: format(parseISO(mail.updated_at), "dd/MM/yyyy 'às' HH:mm"),
  }; */
      })
      .finally(() => setLoadedDom(true));
  }, []);

  const handleFindDomain = useCallback(
    (domainName: string): number | undefined => {
      return domains.find((dom) => dom.name === domainName)?.id;
    },
    [domains],
  );

  const handleEditEmail = useCallback(
    (data: CorporateEmailForm) => {
      const { email: dataEmail } = data;
      const emailName = dataEmail.slice(0, dataEmail.indexOf('@'));
      const domName = dataEmail.substring(dataEmail.indexOf('@') + 1);

      setSelectedEmailEdit({
        ...data,
        email: emailName,
        domain_id: handleFindDomain(domName),
      });
      onOpenEdit();
    },
    [onOpenEdit, handleFindDomain],
  );

  const handleEditEmailForm = useCallback(
    async (data) => {
      const domSelect = domains.find((dom) => {
        return dom.id === Number(data.domains);
      })?.label;
      data.email = `${data.email}${domSelect}`;

      setLoaded(false);
      setLoadingEdit(true);
      try {
        formRef.current?.setErrors({});
        /* console.log(data);
        data.password = null; */

        if (!data.password) {
          data.password = null;
        }

        const validation = Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido!'),
          password: Yup.string()
            .nullable()
            .min(6, 'Mínimo 6 caracteres')
            .max(30, 'Máximo 30 caracteres'),
        });
        await validation.validate(data, {
          abortEarly: false,
        });

        const response = await api.put<CorporateEmail>(
          `corporate-emails/${selectedEmailEdit.id}`,
          data,
        );

        const { data: newEmail } = response;

        setEmail((state) => {
          return {
            ...state,

            data: [
              ...state.data.map((dataEmail) => {
                return dataEmail.id === selectedEmailEdit.id
                  ? {
                      ...dataEmail,
                      email: newEmail.email,
                      updated_at: format(
                        parseISO(newEmail.updated_at),
                        "dd/MM/yyyy 'às' HH:mm",
                      ),
                    }
                  : dataEmail;
              }),
            ],
          };
        });

        formRef.current?.reset();

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'E-mail atualizado!',
          description: 'O E-mail foi atualizado com sucesso.',
          isClosable: true,
        });

        /* setLoadReload((state) => state + 1); */

        onCloseEdit();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current?.setErrors(errors);
          return;
        }
        const { error } = err.response.data;

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            error || 'Ocorreu um erro ao atualizar o email, tente novamente.',
          isClosable: true,
        });
      } finally {
        setLoaded(true);
        setLoadingEdit(false);
      }
    },
    [addToast, onCloseEdit, selectedEmailEdit, domains],
  );

  const handleOpenModalDelete = useCallback(
    (id: number) => {
      setSelectEmailDelete(id);
      onOpenDelete();
    },
    [onOpenDelete],
  );

  const handleSubmitDelete = useCallback(() => {
    if (email) {
      onCloseDelete();
    }

    api
      .delete(`corporate-emails/${selectEmailDelete}`)
      .then(() => {
        const filteredEmails = email.data.filter((mail) => {
          return mail.id !== selectEmailDelete;
        });
        if (!filteredEmails.length) {
          setPage((state) => state - 1);
        }
        setEmail((state) => {
          return {
            ...state,
            data: filteredEmails,
            total: state.total - 1,
            to: state.to - 1,
          };
        });
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'E-mail deletado!',
          description: 'E-mail deletado com sucesso!',
        });

        setModalSuccess(modalSuccess + 1);
      })
      .catch((err) => {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'E-mail não deletada!',
          description: 'Erro ao deletar o email',
        });
      });
  }, [selectEmailDelete, addToast, modalSuccess, email, onCloseDelete]);

  const handleCreateEmail = useCallback(
    async (data) => {
      const domSelected = domains.find((dom) => {
        return dom.id === Number(data.domains);
      })?.label;
      data.email = `${data.email}${domSelected}`;

      setLoaded(false);
      try {
        formRef.current?.setErrors({});

        const validation = Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido!'),
          password: Yup.string()
            .required('Senha é obrigatória')
            .min(6, 'Mínimo 6 caracteres')
            .max(30, 'Máximo 30 caracteres'),
        });

        await validation.validate(data, {
          abortEarly: false,
        });

        const response = await api.post<CorporateEmail>(
          'corporate-emails',
          data,
        );

        const { data: newEmail } = response;

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'E-mail cadastrado!',
          description: 'O E-mail foi cadastrado com sucesso.',
          isClosable: true,
        });

        formRef.current?.reset();

        /* setLoadReload((state) => state + 1); */
        setEmail((state) => {
          return {
            ...state,
            total: state.total + 1,
            to: state.to + 1,

            data: [
              ...state.data,
              {
                ...newEmail,
                created_at: format(
                  parseISO(newEmail.created_at),
                  "dd/MM/yyyy 'às' HH:mm",
                ),
                updated_at: format(
                  parseISO(newEmail.updated_at),
                  "dd/MM/yyyy 'às' HH:mm",
                ),
              },
            ],
          };
        });
        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            err.response.data?.error || 'Ocorreu um erro ao cadastrar o email',
          isClosable: true,
        });
      } finally {
        setLoaded(true);
      }
    },
    [addToast, onClose, domains],
  );

  const handleCloseEmail = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Box pos="relative">
      <Container>
        <SectionHeader title="Controle de Email" pagename="Emails">
          <Button
            colorScheme="blue"
            isLoading={!loaded}
            isDisabled={!loaded}
            onClick={onOpen}
            ref={btnCreateRef}
          >
            <FiPlus />
            Cadastrar
          </Button>
        </SectionHeader>
      </Container>
      {!loaded && (
        <Box
          pos="absolute"
          top={0}
          left={0}
          w="full"
          h="500px"
          bg="rgba(255,255,255,0.5)"
        >
          <Loading />
        </Box>
      )}
      {email && !!email.data?.length && loaded && (
        <Table
          theadData={tableTitles}
          pagination={{
            current_page: email.current_page,
            last_page: email.last_page,
            per_page: email.per_page,
            to: email.to,
            total: email.total,
          }}
          newPage={(pg) => setPage(pg)}
        >
          {loaded &&
            email.data.map((corpEmail) => (
              <Box
                as="tr"
                key={String(corpEmail.id)}
                _hover={{ bg: 'gray.50' }}
              >
                <Box as="td" py={1}>
                  {corpEmail.id}
                </Box>
                <Box as="td" py={1}>
                  {corpEmail.email}
                </Box>
                <Box as="td" py={1}>
                  {corpEmail.created_at}
                </Box>
                <Box as="td" py={1}>
                  {corpEmail.updated_at}
                </Box>
                <Box as="td" py={1} className="row">
                  <Flex justifyContent="flex-end">
                    <IconButton
                      aria-label="Editar"
                      icon={<FiEdit />}
                      variant="ghost"
                      bg="yellow.50"
                      ml={[0, 2]}
                      colorScheme="yellow"
                      onClick={() => handleEditEmail(corpEmail)}
                    />
                    <IconButton
                      aria-label="Deletar"
                      icon={<FiTrash />}
                      variant="ghost"
                      bg="red.50"
                      ml={[0, 2]}
                      colorScheme="red"
                      onClick={() => handleOpenModalDelete(corpEmail.id)}
                    />
                  </Flex>
                </Box>
              </Box>
            ))}
        </Table>
      )}

      <ModalChakra
        title="Atualizar Email"
        onClose={onCloseEdit}
        isOpen={isOpenEdit}
        onSubmit={() => formRefEdit.current?.submitForm()}
        isLoading={loadingEdit}
      >
        <Form
          initialData={selectedEmailEdit}
          ref={formRefEdit}
          onSubmit={handleEditEmailForm}
        >
          <InputGroup mb={0}>
            <Input
              icon={FiMail}
              label="E-mail"
              name="email"
              type="text"
              placeholder="E-mail"
              autoFocus
              rightAddon={
                <SelectChakra
                  defaultValue={selectedEmailEdit.domain_id}
                  name="domains"
                  options={domains}
                  isInline
                  style={{ borderWidth: 0 }}
                  focusBorder="transparent"
                />
              }
            />
          </InputGroup>
          <FormControl>
            <Input
              icon={FiLock}
              isPassword
              label="Senha"
              name="password"
              type="text"
              placeholder="Senha"
              isInline
            />
            <FormHelperText color="red.500">
              Campo não obrigatório!
            </FormHelperText>
          </FormControl>
        </Form>
      </ModalChakra>
      <AlertDialog
        title="Deletar E-mail?"
        description="Tem certeza que deseja deletar o e-mail selecionado"
        isOpen={isOpenDelete}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDelete}
        onSubmit={handleSubmitDelete}
      />

      {loadedDom && (
        <Drawer
          preserveScrollBarGap
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnCreateRef}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              Cadastrando E-mail
            </DrawerHeader>

            <DrawerBody overflowY="auto">
              <Box minH={200}>
                <Form ref={formRef} onSubmit={handleCreateEmail}>
                  <Stack>
                    <InputGroup mb={0}>
                      <Input
                        icon={FiMail}
                        label="E-mail"
                        name="email"
                        type="text"
                        placeholder="E-mail"
                        autoFocus
                        rightAddon={
                          <SelectChakra
                            name="domains"
                            options={domains}
                            isInline
                            style={{ borderWidth: 0 }}
                            focusBorder="transparent"
                          />
                        }
                      />
                    </InputGroup>
                    <Input
                      icon={FiLock}
                      isPassword
                      label="Senha"
                      name="password"
                      type="text"
                      placeholder="Senha"
                    />
                  </Stack>
                </Form>
              </Box>
            </DrawerBody>

            <DrawerFooter justifyContent="flex-end">
              {loaded && (
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => onClose()}
                  size="lg"
                >
                  Fechar
                </Button>
              )}

              <Button
                variant="solid"
                colorScheme="blue"
                isLoading={!loaded}
                isDisabled={!loaded}
                size="lg"
                type="submit"
                ml="auto"
                onClick={() => formRef.current?.submitForm()}
              >
                Finalizar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

export default CorporateEmail;
