import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const SkeletonLegendBSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.75rem;
  width: calc(100% - 30px);

  /* div {
    flex: 1;
  } */
  div ~ div {
    margin-left: 0.75rem;
  }

  @media (max-width: 769px) {
    flex-direction: column;
    margin-right: 0;

    div {
      height: 35px;
      width: 220px;
      margin-bottom: 8px;
      flex: auto;
    }

    div ~ div {
      margin-left: 0;
    }
  }
`;

export const ButtonsBSC = styled.div`
  display: flex;
  button {
    min-width: 200px;
    margin-bottom: 1rem;
  }
  button ~ button {
    margin-left: 1rem;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    button ~ button {
      margin-left: 0;
    }
  }
`;

export const BarContent = styled(Flex)`
  font-size: 50px !important;
  @media (max-width: 1280px) {
    font-size: 12px;
  }
`;
