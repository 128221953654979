import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormControl, FormErrorMessage, Heading } from '@chakra-ui/react';
import { useField } from '@unform/core';
import { DatePicker, message } from 'antd';
import 'antd/dist/antd.css';

interface RangeValue {
  d: Date;
}

interface Dates {
  from: string;
  to?: string;
}

interface Props {
  name: string;
  label?: string;
  onChange?(dates: Date[]): void;
  containerStyle?: object;
}

const RangeDateMonthly: React.FC<Props> = ({
  name,
  label,
  onChange,
  containerStyle,
}) => {
  const { RangePicker } = DatePicker;

  const [dates, setDates] = useState<Date[]>([]);
  const [datesDefault, setDatesDefault] = useState<string[]>([]);

  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [], error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.map((ref) => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref) => {
          ref.value = '';
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref, index) => {
          if (values.includes(ref.id)) {
            ref.value = `${dates[index].getFullYear()}-${
              dates[index].getMonth() + 1
            }`;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField, dates]);

  useEffect(() => {
    if (onChange && dates.length) {
      onChange(dates);
    }
  }, [dates, onChange]);

  const handleChange = useCallback((datesCalendar, dateStringCalendar) => {
    const parseDates: RangeValue[] = datesCalendar;

    setDates(parseDates?.map((date: RangeValue) => date.d) || []);

    setDatesDefault(dateStringCalendar || []);

    if (dateStringCalendar && dateStringCalendar[0]) {
      message.info(
        `Datas selecionadas: ${dateStringCalendar[0]} até ${dateStringCalendar[1]}`,
      );
    }
  }, []);

  return (
    <FormControl mb={4} style={containerStyle} isInvalid={!!error} flex={1}>
      <Heading
        as="h5"
        size="sm"
        fontWeight={500}
        mb={1}
        cursor="default"
        color="gray.500"
      >
        {label || 'Selecione o périodo'}
      </Heading>
      <RangePicker
        onChange={handleChange}
        picker="month"
        format="MM/yyyy"
        style={{
          height: '3rem',
          borderRadius: '5px',
          borderColor: error ? '#E53E3E' : '#E2E8F0',
          boxShadow: error ? '#E53E3E' : '#E2E8F0',
          outlineColor: error ? '#E53E3E' : '#E2E8F0',
          color: '#2f2e38',
          width: '100%',
        }}
      />
      {error && <FormErrorMessage mb={2}>{error}</FormErrorMessage>}

      <input
        type="hidden"
        name={`${name}[0]`}
        ref={(ref: HTMLInputElement) => {
          inputRefs.current[0] = ref as HTMLInputElement;
        }}
        defaultValue={datesDefault[0] || undefined}
      />
      <input
        type="hidden"
        name={`${name}[1]`}
        ref={(ref: HTMLInputElement) => {
          inputRefs.current[1] = ref as HTMLInputElement;
        }}
        defaultValue={datesDefault[1] || undefined}
      />
    </FormControl>
  );
};

export default RangeDateMonthly;
