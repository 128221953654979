import { fadeIn } from 'react-animations';

import { Box } from '@chakra-ui/react';
import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled(Box)`
  animation: 0.4s ${fadeInAnimation};

  > div.isloading {
    position: absolute;
    top: 30px;
    left: 0;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h3 {
    font-weight: bold;
    font-size: 20px;
    color: #081132;
  }

  @media (max-width: 480px) {
    h3 {
      text-align: center;
    }

    > span button {
      margin-bottom: 20px;
    }

    > div {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  strong {
    font-size: 13px;
    margin-bottom: 5px;
  }

  textarea {
    border-radius: 5px;
    background: #fff;
    border: 1px solid rgba(71, 98, 235, 0.1);
    padding: 10px;
    max-width: 500px;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    color: #8694a0;
  }
`;

export const Information = styled.div`
  display: flex;
  width: auto;
  font-size: 16px;
  line-height: 19px;
  color: #2f2e38;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 10px;
  }

  > div {
    display: flex;
    flex-direction: column;

    small {
      margin-top: 0.25rem;
      font-size: 13px;
      line-height: 15px;
      color: #8694a0;
    }
  }
`;
