import styled, { css } from 'styled-components';

interface TheadCustomProps {
  freezeThead: boolean;
}

export const TheadCustom = styled.thead<TheadCustomProps>`
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  top: 0;
  border-color: #edf2f7;
  text-transform: uppercase;
  color: #718096;
  border-bottom-width: 3px;

  ${(props) =>
    props.freezeThead
      ? css`
          position: sticky;
          border-top-width: 0;
          z-index: 100;
          background: #fff;

          th {
            padding: 0;
            border-color: #edf2f7;
          }

          th > div {
            padding: 15px;
            // border-width: 1px 0;
            border-color: #edf2f7;
          }

          th div p {
            // white-space: nowrap;
          }

          th:first-child > div {
            border-left-width: 1px;
          }

          th:last-child > div {
            border-right-width: 1px;
          }
        `
      : css`
          position: unset;
          border-top-width: 1px;
          z-index: auto;
          background: unset;

          th {
            padding: 15px;
            border-color: #edf2f7;
          }
        `}
`;
