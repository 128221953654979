function cnpjMask(value: string): string {
  /* retu value.replace(/\D/g, ''); */ // Limpa a string removendo tudo que não for digito de forma global (em toda a string)

  // A instrução regex é colada dentro de duas "/"

  /* value = value.replace(/(\d{2})(\d{3})/g, '$1.$2');
  value = value.replace(/(\d{3})(\d{3})/g, '$1.$2');
  value = value.replace(/(\d{3})(\d{4})/g, '$1/$2');
  value = value.replace(/(\d{4})(\d{2})$/, '$1-$2'); */

  return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números

  /* value = value.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5',
  );

  return value; */
}

function cpfMask(value: string): string {
  return (
    value
      .replace(/\D+/g, '') // Limpa a string removendo tudo que não for digito de forma global (em toda a string)

      // A instrução regex é colada dentro de duas "/"

      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  );

  /*  value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); */
}

function phoneMask(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
}

function cepMask(value: string): string {
  return (
    value
      .replace(/\D+/g, '') // Limpa a string removendo tudo que não for digito de forma global (em toda a string)
      /* A instrução regex é colada dentro de duas "/" */
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d$/, '$1')
  );

  /*  value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); */
}

function competenceMask(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2') // Insere a barra após o segundo dígito
    .replace(/(\/\d{2})\d$/, '$1'); // Limita a entrada a 2 dígitos após a barra
}

export { cnpjMask, cpfMask, phoneMask, cepMask, competenceMask };
