import {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from 'react';
import React from 'react';
import {
  FaBuilding,
  FaSearch,
  FaEraser,
  FaChevronCircleRight,
} from 'react-icons/fa';
import { FiX, FiGrid, FiList, FiExternalLink } from 'react-icons/fi';
import { useParams, useLocation } from 'react-router-dom';

import {
  Box,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  IconButton,
  Tooltip,
  Icon,
  useDisclosure,
  Badge,
  Link,
  Button,
  Skeleton,
  Heading,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import queryString from 'query-string';

import useLinkStatusAttendance from '~/hooks/useLinkStatusAttendance';
import BadgeStatusInfo from '~/modules/dashboard/components/BadgeStatusInfo';
import Highlights from '~/shared/components/Highlights';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';

interface DemandsConsumption extends IPaginationProps {
  data: Data[];
}

interface Data {
  id: number;
  name: string;
  qtd_aten: number;
  real_allocation_sec: string;
  real_allocation: string;
  avaliable_time: string;
  avaliable_time_sec: string;
  demand_consumption: string;
  department_consumption: string;
  provider_consumption: string;
  activities: Activities[];
}

interface Activities {
  service_id: number;
  competencia: string;
  service_status: number;
  planning_id: number;
  funcionario_id: number;
  planning_status: string;
  preview_allocation: string;
  real_allocation: string;
  actitivies_name: string;
  actitivies_order: number;
  color_planning_status: string;
  employee_name: string;
  status: string;
  color_name: string;
}

interface TotalDemandsConsumption {
  real_allocation: string;
  avaliable_time: string;
  total_demand_consumption: string;
  total_department_consumption: string;
  total_provider_consumption: string;
}

interface Department {
  id: number;
  name: string;
}

interface Company {
  id: number;
  company_name: string;
}

interface DepartmentEmployees {
  id: number;
  name: string;
  department_id: number;
}

interface StructureConsumptionEmployee {
  demands_consumption: DemandsConsumption;
  total_demands_consumption: TotalDemandsConsumption;
  activities: Activities[];
  department_employees: DepartmentEmployees[];
  department: Department;
  company: Company;
}

interface Params {
  funcionarioId?: string;
}

interface QueryParams {
  department_id?: number;
  company_id?: string;
  start_date?: string;
  end_date?: string;
}

export const StructureConsumptionEmployee: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const { funcionarioId } = useParams<Params>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(funcionarioId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [firstRender, setFirstRender] = useState(true);
  const location = useLocation();
  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);
  const [filters, setFilters] = useState<QueryParams>({
    company_id: queryParams.company_id,
    department_id: queryParams.department_id,
    start_date: queryParams.start_date,
    end_date: queryParams.end_date,
  });
  const [page, setPage] = useState(1);
  const [
    demandsConsumption,
    setDemandsConsumption,
  ] = useState<DemandsConsumption>();
  const [
    totalDemandsConsumption,
    setTotalDemandsConsumption,
  ] = useState<TotalDemandsConsumption>();
  const [employees, setEmployees] = useState<DepartmentEmployees[]>([]);
  // const [activities, setActivities] = useState<Activities[]>([]);
  const [selectedDemand, setSelectedDemand] = useState<Data | null>();

  const goLink = useMemo(() => {
    return `/relatorios/consumo-estrutura/empresas/${filters.company_id}?department_id=${filters.department_id}&start_date=${filters.start_date}&end_date=${filters.end_date}`;
  }, [filters]);

  const [selectedComapany, setSelectedCompany] = useState<Company>();
  const [selectedDepartment, setSelectedDepartment] = useState<Department>();

  const linkAttendance = useLinkStatusAttendance();

  const tableTitle = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Demanda',
      },
      {
        title: 'Alocação Real (Horas)',
      },
      {
        title: 'Consumo (Individual)',
      },
      {
        title: 'Consumo (Departamento)',
      },
      {
        title: 'Consumo (Fornecedor)',
      },
    ];
  }, []);

  const tableTitleModal = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Atividade',
      },
      {
        title: 'Funcionário',
      },
      {
        title: 'Situação',
      },
      {
        title: 'Alocação Prevista',
      },
      {
        title: 'Alocacao Realizada',
      },
    ];
  }, []);

  useEffect(() => {
    /*  if (firstRender) {
      setLoading(true);
      setLoadingFilters(true);
    } */
    setLoadingFilters(true);
    setLoading(true);

    api
      .get<StructureConsumptionEmployee>(
        `/reports/structure-consumption/employees/${
          selectedEmployeeId || funcionarioId
        }/demands`,
        {
          params: {
            page,
            department_id: filters.department_id,
            company_client_id: filters.company_id,
            start_date: filters.start_date,
            end_date: filters.end_date,
          },
        },
      )
      .then((response) => {
        const {
          demands_consumption,
          total_demands_consumption,
          department_employees,
          department,
          company,
        } = response.data;

        setSelectedDepartment(department);
        setSelectedCompany(company);
        setSelectedEmployeeId(selectedEmployeeId || funcionarioId);

        // console.log(department_employees);

        setDemandsConsumption(demands_consumption);
        setTotalDemandsConsumption(total_demands_consumption);
        setEmployees(department_employees);
      })
      .finally(() => {
        setLoading(false);
        setLoadingFilters(false);
      });
  }, [queryParams, page, filters, selectedEmployeeId, funcionarioId]);

  const handleNewPage = useCallback((pageNew: number) => {
    setPage(pageNew);
  }, []);

  const handleSubmit = useCallback((data) => {
    setFilters((state) => ({
      ...state,
      start_date: data.start_date,
      end_date: data.end_date,
    }));

    // fazer validação com yup
    setSelectedEmployeeId(data.funcionario_id);
    setPage(1);
    // console.log(data);
  }, []);

  const handleModal = useCallback(
    (id: number) => {
      setSelectedDemand(
        demandsConsumption?.data.find((state) => state.id === id),
      );
      onOpen();
      // console.log(demandsConsumption, 'modal');
    },
    [onOpen, demandsConsumption],
  );

  return (
    <Box pos="relative">
      <SectionHeader
        title=" Consumo de Estrutura - Demandas"
        pagename="Visualizar o percentual de consumo de estrutura com base no tempo disponível dos funcionários e a hora gasta na realização das demandas das empresas clientes"
        goBackLink={goLink}
        // goBackPage
      />

      <Breadcrumb display="flex" justifyContent="left" mb="30px">
        <BreadcrumbItem color="#989898">
          <BreadcrumbLink
            href={`/relatorios/consumo-estrutura/?start_date=${filters.start_date}&end_date=${filters.end_date}`}
          >
            Consumo de Estrutura - Empresas
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem color="#989898">
          <BreadcrumbLink
            href={`/relatorios/consumo-estrutura/empresas/${filters.company_id}&?department_id=${filters.department_id}&start_date=${filters.start_date}&end_date=${filters.end_date}`}
          >
            Consumo de Estrutura - Funcionários
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem color="#989898">
          <Text>Consumo de Estrutura - Demandas</Text>
        </BreadcrumbItem>
      </Breadcrumb>

      <Highlights
        data={[
          {
            icon: FaBuilding,
            label: 'Empresa',
            text: loading ? 'Carregando...' : selectedComapany?.company_name,
          },
          {
            icon: FiGrid,
            label: 'Departamento',
            text: loading ? 'Carregando...' : selectedDepartment?.name,
          },
        ]}
      />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        /* initialData={{ funcionario_id: funcionarioId }} */
      >
        <Flex alignItems="center" justifyContent="center" mb="16px">
          {loadingFilters ? (
            <Box flex={1} mr="30px">
              <Heading
                as="h5"
                size="sm"
                fontWeight={500}
                mb={1}
                cursor="default"
                color="gray.500"
              >
                Funcionário
              </Heading>
              <Skeleton height="48px" />
            </Box>
          ) : (
            <SelectChakra
              background="#e9ecef"
              borderColor=""
              name="funcionario_id"
              options={
                (!!employees?.length &&
                  employees?.map((employee) => {
                    return {
                      label: employee.name,
                      value: employee.id.toString(),
                    };
                  })) ||
                []
              }
              placeholder={loading ? 'Carregando...' : ''}
              defaultValue={selectedEmployeeId}
              mb="0"
              mr="30px"
              label="Funcionário"
              disabled={loading}
            />
          )}

          {loadingFilters ? (
            <Box flex={1} mr="8px">
              <Heading
                as="h5"
                size="sm"
                fontWeight={500}
                mb={0}
                cursor="default"
                color="gray.500"
              >
                Período início
              </Heading>
              <Skeleton height="48px" />
            </Box>
          ) : (
            <InputChakra
              background="#e9ecef"
              borderColor=""
              name="start_date"
              defaultValue={filters.start_date}
              type="month"
              mb="0px"
              label="Período início"
            />
          )}

          {loadingFilters ? (
            <Skeleton height="40px" marginTop="20px" marginRight="38px" />
          ) : (
            <IconButton
              aria-label="Limpar Início"
              icon={<FiX />}
              variant="ghost"
              colorScheme="red"
              mr="30px"
              mt="15px"
              onClick={() => formRef.current?.clearField('start_date')}
            />
          )}

          {loadingFilters ? (
            <Box flex={1} mr="8px">
              <Heading
                as="h5"
                size="sm"
                fontWeight={500}
                mb={1}
                cursor="default"
                color="gray.500"
              >
                Período Fim
              </Heading>
              <Skeleton height="48px" />
            </Box>
          ) : (
            // <Skeleton height="48px" width="282px"/>

            <InputChakra
              background="#e9ecef"
              borderColor=""
              name="end_date"
              defaultValue={filters.end_date}
              type="month"
              mb="0px"
              label="Período Fim"
            />
          )}
          {loadingFilters ? (
            <Skeleton height="40px" marginTop="20px" marginRight="38px" />
          ) : (
            <IconButton
              aria-label="Limpar Fim"
              icon={<FiX />}
              variant="ghost"
              colorScheme="red"
              mr="30px"
              mt="15px"
              onClick={() => formRef.current?.clearField('end_date')}
            />
          )}
        </Flex>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="right"
          mb="10px"
        >
          <Tooltip aria-label="Filtrar" label="Filtrar">
            <IconButton
              aria-label="Filtrar"
              icon={<FaSearch />}
              mr="20px"
              width="70px"
              isLoading={loadingFilters}
              onClick={() => formRef.current?.submitForm()}
            />
          </Tooltip>

          <Tooltip aria-label="Limpar Filtro" label="Limpar Filtro">
            <IconButton
              aria-label="Limpar Filtro"
              icon={<FaEraser />}
              width="70px"
              mr="30px"
              onClick={() => formRef.current?.reset()}
            />
          </Tooltip>
        </Box>
      </Form>

      {!loading && (
        <>
          {demandsConsumption?.data.length ? (
            <Table
              theadData={tableTitle}
              pagination={
                demandsConsumption
                  ? {
                      current_page: demandsConsumption.current_page,
                      last_page: demandsConsumption.last_page,
                      per_page: demandsConsumption.per_page,
                      to: demandsConsumption.to,
                      total: demandsConsumption.total,
                    }
                  : undefined
              }
              loading={loading}
              newPage={(pg) => handleNewPage(pg)}
            >
              <Box as="tr">
                <Box as="td" p="10px">
                  <Text color="#2e8b57" as="b">
                    TOTAL
                  </Text>
                </Box>

                <Box as="td" p="10px">
                  <Text color="#2e8b57" as="b">
                    {totalDemandsConsumption?.real_allocation}
                  </Text>
                </Box>

                <Box as="td" p="10px">
                  <Text color="#2e8b57" as="b">
                    {`${totalDemandsConsumption?.total_demand_consumption}%`}
                  </Text>
                </Box>

                <Box as="td" p="10px">
                  <Text color="#2e8b57" as="b">
                    {`${totalDemandsConsumption?.total_department_consumption}%`}
                  </Text>
                </Box>

                <Box as="td" p="10px">
                  <Text color="#2e8b57" as="b">
                    {`${totalDemandsConsumption?.total_provider_consumption}%`}
                  </Text>
                </Box>
              </Box>

              {demandsConsumption.data.map((dc) => {
                return (
                  <Fragment key={dc.id}>
                    <Box as="tr">
                      <Box as="td" p="10px">
                        <IconButton
                          type="button"
                          aria-label="Demanda"
                          icon={<FiList />}
                          marginRight={4}
                          backgroundColor="transparent"
                          onClick={() => handleModal(dc.id)}
                        />

                        {dc.name}
                      </Box>
                      <Box as="td" p="10px">
                        {dc.real_allocation}
                      </Box>
                      <Box as="td" p="10px">
                        {dc.demand_consumption}
                      </Box>
                      <Box as="td" p="10px">
                        {`${dc.department_consumption}%`}
                      </Box>
                      <Box as="td" p="10px">
                        {`${dc.provider_consumption}%`}
                      </Box>
                    </Box>
                  </Fragment>
                );
              })}
            </Table>
          ) : (
            <Box
              fontSize="1.2rem"
              fontWeight="400"
              textAlign="center"
              marginTop="150px"
            >
              <Text color="#818182">
                Nenhum dado foi encontrado, verifique os filtros e tente
                novamente
              </Text>
            </Box>
          )}

          {selectedDemand && (
            <ModalChakra
              isOpen={isOpen}
              onClose={() => {
                onClose();
                setSelectedDemand(null);
              }}
              size="3xl"
              title={selectedDemand.name}
            >
              <Table theadData={tableTitleModal}>
                {selectedDemand.activities.map((act, idx) => {
                  const lastAttendanceId =
                    idx > 0 ? selectedDemand.activities[idx - 1].service_id : 0;

                  return (
                    <Fragment key={act.planning_id}>
                      {act.service_id !== lastAttendanceId && (
                        <Box as="tr">
                          <Box as="td" colSpan={5} width="100%">
                            <Badge
                              colorScheme="blue"
                              p="10px"
                              variant="solid"
                              width="100%"
                              textAlign="center"
                            >
                              <Button
                                onClick={() =>
                                  linkAttendance(
                                    selectedDemand.id,
                                    act.service_id,
                                  )
                                }
                                variant="link"
                                color="white"
                              >
                                {`Aten.${act.service_id} `}
                              </Button>
                            </Badge>
                          </Box>
                        </Box>
                      )}
                      <Box as="tr">
                        <Box
                          as="td"
                          paddingBottom="20px"
                          paddingTop="20px"
                          paddingLeft="12px"
                          paddingRight="12px"
                        >
                          {`${act.actitivies_order} - ${act.actitivies_name}`}
                        </Box>
                        <Box
                          as="td"
                          paddingBottom="20px"
                          paddingTop="20px"
                          paddingLeft="12px"
                          paddingRight="12px"
                        >
                          {act.employee_name}
                        </Box>
                        <Box as="td" paddingBottom="20px" paddingTop="20px">
                          <BadgeStatusInfo
                            status={act.status}
                            statusColor={act.color_name}
                          />
                        </Box>

                        <Box
                          as="td"
                          paddingBottom="20px"
                          paddingTop="20px"
                          paddingLeft="12px"
                          paddingRight="12px"
                        >
                          {act.preview_allocation}
                        </Box>
                        <Box
                          as="td"
                          paddingBottom="20px"
                          paddingTop="20px"
                          paddingLeft="12px"
                          paddingRight="12px"
                        >
                          {act.real_allocation}
                        </Box>
                      </Box>
                    </Fragment>
                  );
                })}
              </Table>

              {/*      <Button onClick={onClose}>Fechar</Button> */}
            </ModalChakra>
          )}
        </>
      )}
    </Box>
  );
};

export default StructureConsumptionEmployee;
