import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FaBan, FaToggleOff, FaTrash } from 'react-icons/fa';
import { FiPlusCircle, FiX, FiExternalLink } from 'react-icons/fi';

import {
  Box,
  Flex,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalFooter,
  Button,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import { UserEditProps } from '~/modules/accessControl/pages/User/UserEdit';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import InputChakra from '../InputChakra';
import SelectChakra from '../InputChakra/SelectChakra';
import Loading from '../Loading';
import { TimeLineContainer, LiTimeLine, UlTimeLine } from './styles';

interface StatusForm {
  user_id: string;
  status: string;
  start_date: string;
  end_date: string;
}

interface Props {
  userId: string | undefined;
  defaultTimeLine: TimeLine[];
  checkedRegisterWorkShift: boolean;
  blockUser?(): void;
}

export interface TimeLine {
  id_usuario: number;
  empresa_id: number;
  status: number;
  data_inicio: string;
  data_inicio_formated: string;
  data_fim: string;
  data_fim_formated: string;
  nome: string;
  cor: string;
}

export const TimeLineStatus: React.FC<Props> = ({
  userId,
  defaultTimeLine,
  checkedRegisterWorkShift,
  blockUser,
}) => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const addToast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenX,
    onOpen: onOpenX,
    onClose: onCloseX,
  } = useDisclosure();
  const {
    isOpen: isOpenTrash,
    onOpen: onOpenTrash,
    onClose: onCloseTrash,
  } = useDisclosure();

  const [statusTimeLine, setStatusTimeLine] = useState<TimeLine[]>([]);
  const [reloadStatusTimeLine, setReloadStatusTimeLine] = useState(0);
  const [
    selectedStatusTimeLine,
    setSelectedStatusTimeLine,
  ] = useState<TimeLine>();
  const [loadingTimeLine, setLoadingTimeLine] = useState(true);

  // const date = new Date().toISOString();

  useEffect(() => {
    setLoadingTimeLine(true);
    if (defaultTimeLine) {
      api
        .get<UserEditProps>(`users/${userId}`)
        .then((response) => {
          const { timeline } = response.data;
          // console.log(timeline);
          setStatusTimeLine(
            timeline.map((tl: TimeLine) => {
              // console.log(tl.data_inicio, tl.data_fim);
              return {
                ...tl,
                data_inicio_formated: format(
                  parseISO(tl.data_inicio),
                  "dd/MM/yyyy' às 'HH:mm",
                ),
                data_fim_formated: tl.data_fim
                  ? format(parseISO(tl.data_fim), "dd/MM/yyyy' às 'HH:mm")
                  : '(não definido)',
              };
            }),
          );
        })
        .finally(() => setLoadingTimeLine(false));
    }
  }, [userId, defaultTimeLine, reloadStatusTimeLine]);

  const statusSelect = [
    {
      label: 'Atestado Saúde',
      value: 2,
    },
    {
      label: 'Licença',
      value: 3,
    },
    {
      label: 'Abono',
      value: 4,
    },
    {
      label: 'Férias',
      value: 6,
    },
  ];

  const handleCreateStatus = useCallback(
    async (data: StatusForm) => {
      setLoadingTimeLine(true);

      try {
        /* console.log(data);
        return; */

        formRef.current?.setErrors({});

        const validation = Yup.object().shape({
          status: Yup.string().required('Campo obrigatório'),
          start_date: Yup.string().required('Campo obrigatório'),
          end_date: Yup.string().required('Campo obrigatório'),
        });

        await validation.validate(data, {
          abortEarly: false,
        });

        await api.post<TimeLine>('timeline', { ...data, user_id: userId });

        // const { data: newStatus } = response;

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Status cadastrado!',
          description: 'O status foi cadastrado com sucesso.',
          isClosable: true,
        });
        // primeiro criado objeto criado depois oque tinha no estado
        setReloadStatusTimeLine((state) => state + 1);
        onClose();

        formRef.current?.reset();
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro ao cadastrar',
          description:
            err.response.data?.error || 'Ocorreu um erro ao cadastrar status',
          isClosable: true,
        });
      } finally {
        setLoadingTimeLine(false);
      }
    },
    [addToast, userId, onClose],
  );

  async function handleIsActive(): Promise<void> {
    setLoadingTimeLine(true);
    const startDate = selectedStatusTimeLine?.data_inicio;
    try {
      api.post('timeline/active', { user_id: userId, start_date: startDate });

      setReloadStatusTimeLine((state) => state + 1);

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Status Ativo!',
        description: `O status foi ativo com sucesso!`,
        status: 'success',
      });
    } catch (err) {
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Não foi possível alterar o status para ativo',
        description: err.response.data?.error || '',
        status: 'error',
      });
      setLoadingTimeLine(false);
    }
  }

  async function handleDelete(): Promise<void> {
    setLoadingTimeLine(true);
    try {
      if (selectedStatusTimeLine) {
        const data: StatusForm = {
          user_id: selectedStatusTimeLine.id_usuario.toString(),
          end_date: selectedStatusTimeLine.data_fim,
          start_date: selectedStatusTimeLine.data_inicio,
          status: selectedStatusTimeLine.status.toString(),
        };

        await api.delete('timeline', {
          params: {
            ...data,
            user_id: userId,
          },
        });

        setReloadStatusTimeLine((state) => state + 1);

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Status Apagado!',
          description: `O status foi apagado com sucesso!`,
          status: 'success',
        });
      }
    } catch (err) {
      // console.log(err);
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Não foi possível apagar o status',
        description: err.response.data?.error || '',
        status: 'error',
      });
      setLoadingTimeLine(false);
    }
  }

  async function handleBlock(): Promise<void> {
    setLoadingTimeLine(true);
    const startDate = selectedStatusTimeLine?.data_inicio;
    try {
      await api.post('timeline/block', {
        user_id: userId,
        start_date: startDate,
      });

      setReloadStatusTimeLine((state) => state + 1);

      if (blockUser) blockUser();

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Status Bloqueado!',
        description: `O status foi bloqueado com sucesso!`,
        status: 'success',
      });
    } catch (err) {
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Não foi possível alterar o status para bloqueado',
        description: err.response?.data?.error || '',
        status: 'error',
      });
      setLoadingTimeLine(false);
    }
  }

  return (
    <>
      <TimeLineContainer>
        <Flex borderBottom="1px solid #acacac" mb={4}>
          <Text fontSize="2xl">Status</Text>
        </Flex>
        {/* <Text>Linha do tempo</Text> */}
        <UlTimeLine>
          {loadingTimeLine ? (
            <Box mt={6} height="200px">
              <Loading />
            </Box>
          ) : (
            <>
              {checkedRegisterWorkShift && !!statusTimeLine.length ? (
                <>
                  {statusTimeLine[0].status !== 5 && (
                    <li>
                      <IconButton
                        ml={-5}
                        rounded="100%"
                        background="transparent"
                        _hover={{ color: '#0ac600' }}
                        _active={{ background: 'transparent' }}
                        _focus={{ boxShadow: 'none' }}
                        aria-label="adicionar status"
                        icon={<FiPlusCircle size={20} />}
                        onClick={onOpen}
                      />
                    </li>
                  )}
                </>
              ) : (
                <>
                  {!user.is_subscribed && (
                    <Alert status="info" variant="left-accent" mx={-4} my={4}>
                      <AlertIcon />
                      Para adicionar um novo status siga para a página de{' '}
                      <a href={`/controle/usuarios/jornada/${userId}`}>
                        <Flex alignItems="center">
                          <Text mx={1}>jornada de trabalho</Text>
                          <FiExternalLink />
                        </Flex>
                      </a>
                    </Alert>
                  )}
                </>
              )}
              {statusTimeLine.map((timeLine) => {
                return (
                  <LiTimeLine
                    key={`${timeLine.cor}.${timeLine.data_inicio}`}
                    statusId={timeLine.status}
                  >
                    <strong>{timeLine.nome}</strong>
                    {timeLine.status === 1 && !timeLine.data_fim && (
                      <IconButton
                        onClick={onOpenX}
                        aria-label="icone block"
                        _hover={{ color: '#000' }}
                        icon={<FaBan />}
                      />
                    )}
                    {timeLine.status === 5 && !timeLine.data_fim && (
                      <IconButton
                        onClick={() => handleIsActive()}
                        aria-label="icone toggle"
                        _hover={{ color: '#0ac600' }}
                        icon={<FaToggleOff size={20} />}
                      />
                    )}
                    {timeLine.status !== 1 &&
                      timeLine.status !== 5 &&
                      timeLine.data_fim && (
                        <IconButton
                          onClick={() => {
                            setSelectedStatusTimeLine(timeLine);
                            onOpenTrash();
                          }}
                          _hover={{ color: '#ff0000' }}
                          aria-label="icone trash"
                          icon={<FaTrash />}
                        />
                      )}
                    {/* {index === 0 && timeLine.status === 5 && (
                  <IconButton
                    onClick={() => handleIsActive(statusTimeLine)}
                    aria-label="toggle"
                    icon={<FaToggleOff size={20} />}
                  />
                )}
                {index === 0 && timeLine.status === 1 && (
                  <IconButton
                    onClick={onOpenX}
                    aria-label="icone block"
                    icon={<FaBan />}
                  />
                )}
                {(index === 0) === (timeLine.status === 1) &&
                  (index === 1) !== (timeLine.status === (1 && 5)) && (
                    <IconButton
                      onClick={onOpenX}
                      aria-label="icon block"
                      icon={<FaTrash />}
                    />
                  )} */}
                    <Text color="#b8b8b8">
                      {timeLine.data_inicio_formated} até{' '}
                      {timeLine.data_fim_formated ?? '(não definido)'}
                    </Text>
                  </LiTimeLine>
                );
              })}
            </>
          )}
        </UlTimeLine>
      </TimeLineContainer>

      {/* ---------------------- Modal ---------------------- */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form ref={formRef} onSubmit={handleCreateStatus}>
              <SelectChakra
                label="Selecione um status"
                name="status"
                options={statusSelect}
              />
              <InputChakra
                label="Data de início"
                type="datetime-local"
                name="start_date"
              />
              <InputChakra
                label="Data de término"
                type="datetime-local"
                name="end_date"
              />
            </Form>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              variant="outline"
              mr={3}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              onClick={() => formRef.current?.submitForm()}
            >
              Adicionar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* -------------------Modal Status Block------------------- */}

      <Modal isOpen={isOpenX} onClose={onCloseX}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display="flex"
            justifyContent="center"
            style={{ background: '#ff3547' }}
          >
            Confirmar?
          </ModalHeader>
          <ModalBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
          >
            <FiX size={50} color="red" />
            <Text>Tem certeza de que deseja bloquear o status?</Text>
          </ModalBody>

          <ModalFooter display="flex" justifyContent="center">
            <Button
              onClick={() => {
                handleBlock();
                onCloseX();
              }}
              colorScheme="blue"
              variant="outline"
              mr={3}
            >
              Sim
            </Button>
            <Button onClick={onCloseX} colorScheme="red">
              Não
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* -------------------Modal Status Thash------------------- */}

      <Modal
        isOpen={isOpenTrash}
        onClose={() => {
          setSelectedStatusTimeLine(undefined);
          onCloseTrash();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display="flex"
            justifyContent="center"
            style={{ background: '#ff3547' }}
          >
            Confirmar?
          </ModalHeader>
          <ModalBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
          >
            <FiX size={50} color="red" />
            <Text>
              Tem certeza de que deseja deletar o status de{' '}
              <b>{selectedStatusTimeLine?.nome}</b> no dia{' '}
              <b>{selectedStatusTimeLine?.data_inicio_formated}</b> ao dia{' '}
              <b>{selectedStatusTimeLine?.data_fim_formated}</b>?
            </Text>
          </ModalBody>

          <ModalFooter display="flex" justifyContent="center">
            <Button
              onClick={() => {
                handleDelete();
                onCloseTrash();
              }}
              colorScheme="blue"
              variant="outline"
              mr={3}
            >
              Sim
            </Button>
            <Button onClick={onCloseTrash} colorScheme="red">
              Não
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
