import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  FiFolder,
  FiCheck,
  FiFolderPlus,
  FiArrowRight,
  FiArrowLeft,
  FiFile,
  FiHome,
} from 'react-icons/fi';
import ReactLoading from 'react-loading';

import {
  Box,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tooltip,
  Grid,
  Flex,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import InputChakra from '~/shared/components/InputChakra';
import Loading from '~/shared/components/Loading';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import { ModalFolders, BreadcrumbFolder } from '../index';
import { ClientCompany } from '../index';
import { BoxFoldersList, FlexFolderItem } from './styles';

interface Props {
  folders: ModalFolders[];
  breadCrumbs: BreadcrumbFolder[];
  activeDir: BreadcrumbFolder | null;
  clientCompany: ClientCompany;
  isLoading?: boolean;
  animation: string;
  hasSelectedFolderInList: boolean;
  selectFolderInList(basename: string, name: string): void;
  setActiveDir(folder?: BreadcrumbFolder): void;
  setSelectedFolder(isActiveDir: boolean): void;
  setNewModalFolder(newFolder: ModalFolders): void;
  cleanSelected(): void;
  onCloseModal(): void;
}

const NavigationModal: React.FC<Props> = ({
  folders,
  breadCrumbs,
  clientCompany,
  activeDir,
  hasSelectedFolderInList,
  setActiveDir,
  setSelectedFolder,
  setNewModalFolder,
  selectFolderInList,
  isLoading,
  animation,
  onCloseModal,
  cleanSelected,
}): any => {
  /* Ao setar o valor inicial como sendo o estado do componente anterior, o componente não observa mais aquele estado,
  para permanencer a observância do estado é necessário passar o próprio estado e utilizá-lo */
  // const [folders, setFolders] = useState<ModalFolders[]>(previousFolders);
  // const [loading, setLoading] = useState(loadingPage);

  const addToast = useToast();
  const [lastIndexCrumb, setLastIndexCrumb] = useState<number>();
  const [inputNewFolder, setInputNewFolder] = useState(false);
  const [loadingStoreNewFolder, setLoadingStoreNewFolder] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const memoizedLastCrumb = useMemo(() => {
    if (breadCrumbs.length <= 0) return undefined;

    setLastIndexCrumb(breadCrumbs.length - 1);
    return breadCrumbs[breadCrumbs.length - 1];
  }, [breadCrumbs]);

  /* O valor -1 é atribuído a constante apenas para uma comparação mais precisa, pois se o valor fosse undefined, a comparação
  esperando true do resultado, quando o index fosse 0 poderia gerar problemas */

  const [selectedFolderInActiveDir, setSelectedFolderInActiveDir] = useState(
    hasSelectedFolderInList,
  );

  function handleSelectFolderInList(basename: string, name: string): void {
    if (!selectedFolderInActiveDir) {
      setSelectedFolderInActiveDir(true);
    }

    selectFolderInList(basename, name);
  }

  async function handelSubmitNewFolder(data: {
    name_folder: string;
  }): Promise<void> {
    /* A validação com o Yup não é necessário nesse caso simples. Aparentemente
    não há restrições quanto ao nome da pasta, seja com carecteres especiais, números, etc...
    Se for identificada a necessidade de validação do nome realizar aqui */
    if (!data.name_folder) return;

    const newFolder = {
      name: data.name_folder,
      dirname: activeDir?.id,
      client_company: clientCompany.id,
    };
    setLoadingStoreNewFolder(true);
    api
      .post<ModalFolders>('cloud/navigate/new-folder', newFolder)
      .then((response) => {
        const {
          basename,
          dirname,
          filename,
          name,
          path,
          timestamp,
          type,
        } = response.data;

        setNewModalFolder({
          basename,
          dirname,
          filename,
          name,
          path,
          timestamp,
          type,
        });

        // Como não buscamos o valor do estado, então não há problemas de não encontrar o new Folder
        handleSelectFolderInList(basename, name);
      })
      .catch((err) => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error ||
            `Não foi possível criar a nova pasta no diretório ${activeDir?.name}`,
        });
      })
      .finally(() => {
        setInputNewFolder(false);
        setLoadingStoreNewFolder(false);
      });
  }
  /*
  Foi optado por não realizar essa alteração do estado pelo useEffect, pois há codições que
  será necessário renderizá-lo como true, como no caso de uma das pastas da listagem estiver sido
  selecionada, ao invés de apresentar a mensagem Selecionar pasta atual, irá aparecer selecionar pasta.
  Agora a função está sendo chamada no onClick de ir e voltar das pastas
  useEffect(() => {
    setSelectedFolderInActiveDir(false);
  }, [activeDir]); */

  return (
    <Box>
      <Text color="gray.400">
        Escolha a pasta onde será salvo os documentos anexados.
      </Text>

      <Flex
        width="full"
        mt={5}
        background="gray.100"
        p={2}
        color="gray.600"
        fontWeight="bolder"
        boxShadow="sm"
        height="55px"
        alignItems="center"
      >
        {/* Buscar sempre o último índice */}

        <Flex alignItems="center" w="full">
          {(activeDir || inputNewFolder) && (
            <Button
              onClick={() => {
                if (inputNewFolder) {
                  setInputNewFolder(false);
                } else {
                  setActiveDir(
                    memoizedLastCrumb
                      ? {
                          ...memoizedLastCrumb,
                          index: lastIndexCrumb,
                          return: true,
                        }
                      : undefined,
                  );
                  setSelectedFolderInActiveDir(false);
                }
              }}
              variant="outline"
              color="gray.600"
              borderRadius="50%"
              border="none"
              p={1}
            >
              <FiArrowLeft size="1.2rem" />
            </Button>
          )}
          {inputNewFolder ? (
            <Box flexGrow={1}>
              <Form onSubmit={handelSubmitNewFolder} ref={formRef}>
                <Flex height="32px">
                  <Box flexGrow={1}>
                    <InputChakra
                      name="name_folder"
                      placeholder="Digite o nome da nova pasta e confirme"
                      sizeCustom="sm"
                      mb="0"
                      mbInputGroup="0"
                      autoFocus
                      disabled={loadingStoreNewFolder}
                    />
                  </Box>
                  <IconButton
                    aria-label="Salvar"
                    alignSelf="start"
                    colorScheme="blue"
                    onClick={() => formRef.current?.submitForm()}
                    height="full"
                    p={1}
                    ml={2}
                    isLoading={loadingStoreNewFolder}
                  >
                    <FiCheck />
                  </IconButton>
                </Flex>
              </Form>
            </Box>
          ) : (
            <Flex w="full">
              <Text flex={1} mx={2}>
                {activeDir?.name || clientCompany.name}
              </Text>
              <Box>
                <Button
                  aria-label="Home"
                  colorScheme="white"
                  color="#4A5568"
                  height="full"
                  onClick={() => {
                    setActiveDir();
                    setSelectedFolderInActiveDir(false);
                  }}
                >
                  <FiHome />
                </Button>
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>

      {isLoading ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="450px"
          border="1px solid #E2E8F0"
        >
          <ReactLoading type="spokes" height={40} width={40} color="#7d7d7d" />
        </Flex>
      ) : (
        <Box
          height="450px"
          overflowY="auto"
          overflowX="hidden"
          border="1px solid #E2E8F0"
          pos="relative"
        >
          <BoxFoldersList className={animation}>
            {folders?.length ? (
              folders.map((folder) => {
                return (
                  <Flex
                    key={folder.basename}
                    background={folder.isSelected ? 'blue.400' : 'unset'}
                    color={folder.isSelected ? 'white' : 'gray.600'}
                    _hover={{
                      background: folder.isSelected ? 'blue.400' : 'gray.200',
                      transition: 'background 0.15s',
                    }}
                    height="48px"
                    opacity={folder.type !== 'dir' ? '65%' : 1}
                    onDoubleClick={() => {
                      setActiveDir({
                        id: folder.basename,
                        name: folder.name,
                      });
                      setSelectedFolderInActiveDir(false);
                    }}
                    cursor="pointer"
                  >
                    <FlexFolderItem
                      pl={4}
                      flexGrow={1}
                      alignItems="center"
                      onClick={() => {
                        if (folder.type === 'dir') {
                          handleSelectFolderInList(
                            folder.basename,
                            folder.name,
                          );
                        }
                      }}
                    >
                      <Box width="1.5em">
                        {folder.type === 'dir' ? (
                          <FiFolder size="1.5em" />
                        ) : (
                          <FiFile size="1.5em" />
                        )}
                      </Box>

                      {/* Corrigir para o mobile */}
                      <Tooltip label={folder.name}>
                        <Text
                          maxWidth="400px"
                          ml={2}
                          fontWeight="bolder"
                          isTruncated
                          /* cursor="default" */
                        >
                          {folder.name}
                        </Text>
                      </Tooltip>
                    </FlexFolderItem>
                    {folder.type === 'dir' && (
                      <Flex alignItems="center">
                        <Button
                          onClick={() => {
                            setActiveDir({
                              id: folder.basename,
                              name: folder.name,
                            });
                            setSelectedFolderInActiveDir(false);
                          }}
                          variant="outline"
                          border="none"
                          mr={1}
                          _hover={{
                            background: folder.isSelected
                              ? 'blue.300'
                              : 'gray.50',
                          }}
                        >
                          <FiArrowRight size="1.2em" />
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                );
              })
            ) : (
              <Button
                height="100px"
                width="100px"
                display="flex"
                flexDirection="column"
                variant="ghost"
                disabled
                colorScheme="gray"
                color="gray.600"
                padding="10px"
              >
                <FiCheck />
                <Text fontSize="xs" display="block">
                  Nenhuma
                  <br /> subpasta
                </Text>
              </Button>
            )}
          </BoxFoldersList>
        </Box>
      )}

      <Flex
        justifyContent="space-between"
        background="gray.100"
        padding="10px"
        color="gray.600"
        boxShadow="lg"
      >
        <Tooltip label="Adicionar Pasta">
          <Button onClick={() => setInputNewFolder(true)}>
            <FiFolderPlus />
          </Button>
        </Tooltip>

        <Flex>
          {selectedFolderInActiveDir && (
            <Button
              mr={2}
              colorScheme="gray"
              onClick={() => {
                setSelectedFolderInActiveDir(false);
                cleanSelected();
              }}
            >
              Cancelar
            </Button>
          )}

          <Button
            colorScheme="blue"
            onClick={() => {
              setSelectedFolder(!selectedFolderInActiveDir);

              onCloseModal();
            }}
          >
            {!selectedFolderInActiveDir
              ? 'Selecionar pasta atual'
              : 'Selecionar'}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavigationModal;
