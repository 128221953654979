import React, { useMemo, useCallback, useRef } from 'react';
import {
  FiMessageCircle,
  FiBookOpen,
  FiCalendar,
  FiPaperclip,
  FiRotateCcw,
} from 'react-icons/fi';

import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  IconButton,
  Badge,
  Text,
} from '@chakra-ui/react';

import clockImg from '~/assets/clock.png';
import {
  ActivityProps,
  Pop,
} from '~/modules/activityExecution/@types/activity';

import {
  Container,
  Content,
  Header,
  TimeLeft,
  ActivityStatus,
  Row,
  AttendanceDescription,
  ActivityInfoBox,
} from './styles';

interface Props {
  data: ActivityProps;
  pop?(data: Pop): void;
  viewPlanningInfo?(): void;
  modalDevolution?(id: number, observation?: string): void;
}

const ActivityFinished: React.FC<Props> = ({
  data,
  pop,
  modalDevolution,
  viewPlanningInfo,
  children,
}) => {
  const initialFocusRef = useRef(null);

  const formattedColorSituation = '#09CC1D';

  /*  const formattedColorSituation = useMemo(() => {
    if (data.situation === 'Pausado') {
      return '#FFA11A';
    }
    if (data.situation === 'Finalizado') {
      return '#09CC1D';
    }
    if (data.situation === 'Iniciado') {
      return '#76A1DF';
    }
    return '#D5DEE5';
  }, [data]); */

  const handlePopOpen = useCallback(() => {
    if (pop) {
      pop({
        name: data.name,
        description: data?.activity_description || '',
        pop: data?.activity_pop || '',
        pop_files: data?.pop_files || [],
      });
    }
  }, [pop, data]);

  const handleDevolutionModal = useCallback(() => {
    if (!modalDevolution) return;

    modalDevolution(data.id, data.observation);
  }, [modalDevolution, data.id, data.observation]);

  return (
    <Container>
      <Header>
        <Flex flexDirection={['column', 'row']} alignItems="center">
          <h3 title="Código do atendimento">
            <Badge colorScheme="blue" mr={1}>
              {data.attendance_id}
            </Badge>
            {data.name}
          </h3>

          <Box>
            {!!viewPlanningInfo &&
              (!!data.count_files ||
                (!!data?.count_files_attendance && !!data.predecessors)) && (
                <IconButton
                  aria-label="Anexos"
                  title="Anexos"
                  icon={<FiPaperclip size={20} />}
                  size="sm"
                  onClick={() => viewPlanningInfo()}
                />
              )}
            {data.description && (
              <Popover initialFocusRef={initialFocusRef} placement="auto">
                <PopoverTrigger>
                  <IconButton
                    aria-label="Descrição do atendimento"
                    title="Descrição do atendimento"
                    icon={<FiMessageCircle size={20} />}
                    size="sm"
                  />
                </PopoverTrigger>

                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>{data.description}</PopoverBody>
                </PopoverContent>
              </Popover>
            )}

            {(data.activity_pop || data.activity_description) && (
              <IconButton
                aria-label="ver o POP"
                title="Clique para ver o POP"
                onClick={handlePopOpen}
                icon={<FiBookOpen size={20} />}
                size="sm"
              />
            )}

            {!!data.returned && (
              <IconButton
                aria-label="Atividade devolvida"
                title="Atividade devolvida"
                icon={<FiRotateCcw size={20} />}
                size="sm"
                onClick={handleDevolutionModal}
              />
            )}
          </Box>
        </Flex>
        <ActivityStatus color={formattedColorSituation}>
          <span />
          <Box as="small">{data.situation}</Box>
        </ActivityStatus>
      </Header>
      <ActivityInfoBox>
        <Content>
          <Box>
            <Box>
              <Box>
                <strong>Demanda:</strong>
                <Text as="small">{data.demand}</Text>
              </Box>
              <Box>
                <strong>Empresa:</strong>
                <Text as="small">{data.company}</Text>
              </Box>
            </Box>

            <Box mt={1}>
              <Box className="info-finalizadas">
                <strong>Realizado:</strong>

                <Box as="small">{`${data.formatted_real_start_date} às ${data.formatted_real_start_time}`}</Box>
                <Box as="small">{`até ${data.formatted_real_end_date} às ${data.formatted_real_end_time}`}</Box>
              </Box>
              <Box>
                <strong>Planejado:</strong>

                <Box as="small">{`${data.formatted_start_date} às ${data.formatted_start_time}`}</Box>
                <Box as="small">{`até ${data.formatted_end_date} às ${data.formatted_end_time}`}</Box>
              </Box>
            </Box>
          </Box>
        </Content>
        {children && (
          <Content>
            <Row>{children}</Row>
          </Content>
        )}
        <Flex flex={2} justifyContent="center">
          <TimeLeft>
            <Flex alignItems="center">
              <img src={clockImg} alt="Clock" />

              <Box className="info-time" ml={4}>
                <strong>{data.real_allocation}</strong>
                <Text fontWeight="bold">Alocação realizada</Text>
                {data.situation === 'Finalizado' && (
                  <Box mt={1}>
                    <Text>{data.duration}</Text>
                    <Text>Alocação prevista</Text>
                  </Box>
                )}
              </Box>
            </Flex>
          </TimeLeft>
        </Flex>
      </ActivityInfoBox>
    </Container>
  );
};

export default ActivityFinished;
