import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import {
  Box,
  Button,
  Flex,
  Link,
  useToast,
  useDisclosure,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  Text,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import filesize from 'filesize';

import AlertDialog from '~/shared/components/AlertDialog';
import Loading from '~/shared/components/Loading';
import api from '~/shared/services/api';

import FilesGrid from './FilesGrid';

export type PropsDeleteUpdateFile = {
  id: number;
  basename: string;
  file_name: string;
  file_name_slug: string;
};

export interface NewFileDB {
  id: number;
  attendance_id: number;
  planning_id: number;
  user_id: number;
  mimetype: string;
  basename: string;
  preview_url: string;
  size: number;
  extension: string;
  file_name?: string;
  file_name_slug: string;
  created_at: string;
  updated_at: string;
  formatted_created_at?: string;
  formatted_updated_at?: string;
  file_classification: {
    id: number;
    name: string;
  };
  isPredecessorFile?: boolean;
  isDownloading?: boolean;
  isChecked?: boolean;
}

interface Planning {
  id: number;
  atividade_id: number;
  atendimento_id: number;
  attendance_file_classification: NewFileDB[];
  count_files?: number;
}

export interface Activity {
  id: number;
  planning_id: number;
  name: string;
  order: number;
}

interface PredecessorsData extends Planning {
  atividade: Activity;
}

interface DataPlanning {
  planning: Planning;
  predecessors: PredecessorsData[];
}

interface TemplateGrid {
  templateColumns: string | string[];
  justifyContent: string | string[];
  alignItems: string | string[];
  item: {
    flexDirection: 'column' | 'row';
    flexWrapButton:
      | 'inherit'
      | 'wrap'
      | '-moz-initial'
      | 'initial'
      | 'revert'
      | 'unset'
      | 'nowrap'
      | 'wrap-reverse';
    textAligin: 'center' | 'left';
    orderDownload: number;
    justifyContentButtons: string | string[];
  };
}

interface Props {
  newSavedFiles?: NewFileDB;
  isExecuting?: boolean;
  planningId?: string;
  clientCompany: number;
  sendEmail?: boolean;
  setSelectedFiles?(files: NewFileDB[]): void;
  sendLoading?: boolean;
  title?: string;
  isSessionUser?: boolean;
  isFinished?: boolean;
  canAttachFiles?: boolean;
}

const AttachedFiles: React.FC<Props> = ({
  newSavedFiles,
  isExecuting = false,
  planningId,
  clientCompany,
  sendEmail = false,
  setSelectedFiles,
  sendLoading,
  isFinished = false,
  title = 'ANEXOS DA ATIVIDADE',
  isSessionUser = false,
  canAttachFiles = true,
}) => {
  const cancelRef = useRef(null);
  const maxSizeAttachments = 25000000;
  const [savedFiles, setSavedFiles] = useState<NewFileDB[]>([]);
  const [predecessors, setPredecessors] = useState<Activity[]>([]);
  const [deleteSavedFile, setDeleteSavedFile] = useState<PropsDeleteUpdateFile>(
    {} as PropsDeleteUpdateFile,
  );
  const addToast = useToast();
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [countSelectedFiles, setCountSelectedFiles] = useState(0);
  const [sizeSelectedFiles, setSizeSelectedFiles] = useState(0);

  const [enablePreview, setEnablePreview] = useState(false);

  // Necessário criar rota que busque as informações dos arquivos com base no atendimento e não em apenas um planejamento

  useEffect(() => {
    /* A primeira coisa que deve ser feita, para garantir que nenhum elemento será renderizado em tela
    antes do carregamento total, é setar loading como True */
    if (!planningId) {
      // console.log('teste');
      addToast({
        title: 'Não foi possível obter os arquivos',
        position: 'top-right',
        isClosable: true,
        status: 'error',
        description:
          'Nenhuma referência encontrada do atendimento ou planejamento',
      });
      return;
    }

    api
      .get<DataPlanning>('cloud/planning/files', {
        params: {
          planning_id: planningId,
        },
      })
      .then((response) => {
        const { predecessors: predecessorsData } = response.data;
        const { attendance_file_classification } = response.data.planning;

        let countFiles = 0;
        let sizeFiles = 0;

        if (attendance_file_classification.length > 0) {
          setSavedFiles(
            attendance_file_classification.map((file) => {
              countFiles += 1;
              sizeFiles += file.size;
              return {
                ...file,
                formatted_created_at: format(
                  parseISO(file.created_at),
                  'dd/MM/yyyy HH:mm',
                ),
                formatted_updated_at: format(
                  parseISO(file.updated_at),
                  'dd/MM/yyyy HH:mm',
                ),
                isChecked: sendEmail,
              };
            }),
          );
        }

        /* Como utilizamos o valor do estado atual, precisamos zerar, pois a cada nova renderização
        ele acrescentaria as mesmas atividades novamente */
        setPredecessors([]);

        /* Verificar no backend se a predecessora possui arquivos para incluir na collect final */

        /* Será utilizado o planejamento id para relacionar os dados das atividades predecessoras e seus arquivos, mantendo uma
        lógica única dessa forma para manipular os arquivos */
        if (predecessorsData.length > 0) {
          predecessorsData.forEach((pred) => {
            setPredecessors((state) => [
              ...state,
              {
                id: pred.atividade.id,
                planning_id: pred.id,
                name: pred.atividade.name,
                order: pred.atividade.order,
              },
            ]);

            // countFiles += pred.attendance_file_classification.length;

            const predFiles = pred.attendance_file_classification.map(
              (file) => {
                countFiles += 1;
                sizeFiles += file.size;
                return {
                  ...file,
                  formatted_created_at: format(
                    parseISO(file.created_at),
                    'dd/MM/yyyy HH:mm',
                  ),
                  formatted_updated_at: format(
                    parseISO(file.updated_at),
                    'dd/MM/yyyy HH:mm',
                  ),
                  isChecked: sendEmail,
                  isPredecessorFile: true,
                };
              },
            );

            setSavedFiles((state) => state.concat(predFiles));
          });
        }
        /* Nesse primeiro momento todos os arquivos por padrão estão selecionados
      portanto, a quantidade de arquivos será a quantidade de arquivos selecionados */
        setCountSelectedFiles(countFiles);
        setSizeSelectedFiles(sizeFiles);
      })
      .catch((err) => {
        addToast({
          title: 'Não foi possível obter os arquivos da atividade',
          position: 'top-right',
          isClosable: true,
          status: 'error',
          description: err.response.data?.error,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, planningId, sendEmail]);

  const attachNewSavedFiles = useCallback((newFiles) => {
    setSavedFiles((state) => [{ ...newFiles }, ...state]);
  }, []);

  useEffect(() => {
    if (newSavedFiles) {
      attachNewSavedFiles(newSavedFiles);
    }
  }, [attachNewSavedFiles, newSavedFiles]);

  const handleRemoveFileList = useCallback((id: number) => {
    setSavedFiles((state) => state.filter((file) => file.id !== id));
  }, []);

  const handleOpenModalDeleteSavedFile = useCallback(
    (file: PropsDeleteUpdateFile) => {
      setDeleteSavedFile(file);

      onOpen();
    },
    [onOpen],
  );

  const handleDeleteSavedFile = useCallback(() => {
    if (!deleteSavedFile) return;

    setLoadingDelete(true);

    api
      .delete(`cloud/planning/files/${deleteSavedFile.id}`, {
        params: {
          client_company: clientCompany,
          basename: deleteSavedFile.basename,
        },
      })
      .then(() => {
        addToast({
          title: `Arquivo ${deleteSavedFile.file_name} deletado com sucesso!`,
          position: 'top-right',
          isClosable: true,
          status: 'success',
        });

        handleRemoveFileList(deleteSavedFile.id);
      })
      .catch((err) => {
        addToast({
          title: `Erro`,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          description: err.response.data?.error,
        });
      })
      .finally(() => {
        // É necessário colcoar essas funções dentro do finally para ter efeito
        // setLoadingDelete(false);
        setDeleteSavedFile({} as PropsDeleteUpdateFile);
        setLoadingDelete(false);
        onClose();
      });
  }, [addToast, clientCompany, handleRemoveFileList, deleteSavedFile, onClose]);

  function handleSetCountAndSizeSelectedFiles(fileId: number): void {
    setSavedFiles((state) =>
      state.map((fileState) => {
        if (fileState.id === fileId) {
          setCountSelectedFiles((stateCount) =>
            fileState.isChecked ? stateCount - 1 : stateCount + 1,
          );
          setSizeSelectedFiles((stateSize) =>
            fileState.isChecked
              ? stateSize - fileState.size
              : stateSize + fileState.size,
          );
        }
        return fileState.id === fileId
          ? {
              ...fileState,
              isChecked: !fileState.isChecked,
            }
          : { ...fileState };
      }),
    );
  }

  function handleSetDownloading(id: number, value: boolean): void {
    setSavedFiles((state) =>
      state.map((files) => {
        if (files.id === id) return { ...files, isDownloading: value };
        return files;
      }),
    );
  }

  function handleSetSelectedFiles(): void {
    if (!setSelectedFiles) return;

    // const infoSelectedFiles = document.getElementById('countSelected');
    const selectedFiles = savedFiles.filter((files) => !!files.isChecked);

    // infoSelectedFiles?.focus()

    /*  addToast({
        title: 'Não há documentos selecionados para enviar por e-mail',
        position: 'top-right',
        isClosable: true,
        status: 'warning',
      }); */

    setSelectedFiles(selectedFiles);
  }

  return (
    <>
      {loading ? (
        <Box w="full" h="200px" bg="rgba(255,255,255,0.5)">
          <Loading />
        </Box>
      ) : (
        <>
          {!!setSelectedFiles && !!savedFiles.length && (
            <Heading size="sm" mb={6}>
              {title}{' '}
              <span
                id="countSelected"
                style={{ color: !countSelectedFiles ? '#E53E3E' : 'initial' }}
              >
                ({countSelectedFiles} selecionados)
              </span>
              <span
                id="totalSizeSelected"
                style={{
                  marginLeft: '0.5rem',
                  color:
                    sizeSelectedFiles > maxSizeAttachments
                      ? '#E53E3E'
                      : 'initial',
                }}
              >
                TOTAL = {filesize(sizeSelectedFiles)}
              </span>
            </Heading>
          )}
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Heading size="sm">ANEXOS DA ATIVIDADE</Heading>
            {!!savedFiles.length && (
              <Button
                variant="outline"
                colorScheme="blue"
                onClick={() => setEnablePreview((state) => !state)}
              >
                {enablePreview ? (
                  <>
                    <FiEyeOff size={20} /> <Text ml={2}>Ocultar Prévias</Text>
                  </>
                ) : (
                  <>
                    <FiEye size={20} /> <Text ml={2}>Visualizar Prévias</Text>
                  </>
                )}
              </Button>
            )}
          </Flex>
          {!savedFiles.filter((file) => !file.isPredecessorFile).length ? (
            <Box mb={4}>
              <>
                {!canAttachFiles ? (
                  <Alert status="warning" variant="left-accent">
                    <AlertIcon />
                    <AlertTitle>
                      Essa atividade não permite anexar arquivos
                    </AlertTitle>
                  </Alert>
                ) : (
                  <Alert status="info" variant="left-accent">
                    <AlertIcon />
                    <AlertTitle>
                      {`A atividade ${
                        !isFinished ? ' ainda ' : ' '
                      } não possui nenhum anexo`}
                    </AlertTitle>
                  </Alert>
                )}
              </>
            </Box>
          ) : (
            <FilesGrid
              files={savedFiles.filter((file) => !file.isPredecessorFile)}
              isFinished={isFinished}
              sendEmail={sendEmail}
              isSessionUser={isSessionUser}
              setDownloadingFile={(fileId, value) =>
                handleSetDownloading(fileId, value)
              }
              openModalDeleteSavedFile={(file) =>
                handleOpenModalDeleteSavedFile(file)
              }
              setCountAndSizeSelectedFiles={(fileId) =>
                handleSetCountAndSizeSelectedFiles(fileId)
              }
              showVertical
              enablePreview={enablePreview}
            />
          )}

          {!!predecessors.length && (
            <>
              <Box my={8}>
                <Heading size="sm">ANEXOS DAS ATIVIDADES PREDECESSORAS</Heading>
              </Box>
              {predecessors.map((pred) => {
                return (
                  <Box key={pred.id}>
                    <FilesGrid
                      predecessor={pred}
                      files={savedFiles.filter(
                        (file) => file.planning_id === pred.planning_id,
                      )}
                      sendEmail={sendEmail}
                      isSessionUser={isSessionUser}
                      setDownloadingFile={(fileId, value) =>
                        handleSetDownloading(fileId, value)
                      }
                      openModalDeleteSavedFile={(file) =>
                        handleOpenModalDeleteSavedFile(file)
                      }
                      setCountAndSizeSelectedFiles={(fileId) =>
                        handleSetCountAndSizeSelectedFiles(fileId)
                      }
                      showVertical
                      enablePreview={enablePreview}
                    />
                  </Box>
                );
              })}
            </>
          )}

          {!!setSelectedFiles && (
            <Flex justifyContent="flex-end">
              <Button
                disabled={!isExecuting}
                colorScheme="green"
                onClick={() => handleSetSelectedFiles()}
                isLoading={sendLoading}
              >
                Enviar
              </Button>
            </Flex>
          )}
        </>
      )}

      <Link href="/" display="none" id="downloadFile" />
      {/* No Antigo lugar que o Dialog estava posicionado o fundo estava preto porque era o lugar errado, dentro do flex ou grid,
        já que ocupará toda a página é necessário que fique no nivel mais por fora possível */}
      <AlertDialog
        title={`Apagar o arquivo ${deleteSavedFile.file_name}`}
        description={`O arquivo será apagado da pasta, porém ainda é possível encontrá-lo na lixeira do drive com o nome: ${deleteSavedFile.file_name_slug}`}
        isOpen={isOpen}
        size="lg"
        isLoading={loadingDelete}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        onSubmit={() => handleDeleteSavedFile()}
      />
    </>
  );
};

export default AttachedFiles;
