import React, { useState, useEffect } from 'react';
import { FiFolder, FiCheck } from 'react-icons/fi';

import {
  Box,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tooltip,
  Grid,
} from '@chakra-ui/react';

import Loading from '~/shared/components/Loading';

import { ModalFolders, BreadcrumbFolder } from '../index';

interface Props {
  folders: ModalFolders[];
  breadCrumbs: BreadcrumbFolder[];
  selectedFolder: BreadcrumbFolder | null;
  setSelectedFolder(folder?: BreadcrumbFolder): void;
  isLoading?: boolean;
}

const NavigationPage: React.FC<Props> = ({
  folders,
  breadCrumbs,
  selectedFolder,
  setSelectedFolder,
  isLoading,
}): any => {
  /* Ao setar o valor inicial como sendo o estado do componente anterior, o componente não observa mais aquele estado,
  para permanencer a observância do estado é necessário passar o próprio estado e utilizá-lo */
  // const [folders, setFolders] = useState<ModalFolders[]>(previousFolders);
  // const [loading, setLoading] = useState(loadingPage);

  return (
    <Box>
      <Text color="gray.400">
        Escolha a pasta onde será salvo os documentos anexados.
      </Text>

      <Box
        width="full"
        mt={5}
        mb={3}
        overflowX="auto"
        background="gray.50"
        rounded="sm"
        padding="10px 5px"
        color="gray.600"
      >
        <Breadcrumb>
          <BreadcrumbItem isCurrentPage>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => setSelectedFolder()}
            >
              ...
            </Button>
          </BreadcrumbItem>
          {breadCrumbs?.map((folder, i) => {
            return folder.id === selectedFolder?.id ? (
              <BreadcrumbItem key={folder.id} isCurrentPage>
                <Button size="sm" variant="ghost">
                  {folder.name}
                </Button>
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem key={folder.id}>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() =>
                    setSelectedFolder({
                      id: folder.id,
                      name: folder.name,
                      index: i,
                      return: true,
                    })
                  }
                >
                  {folder.name}
                </Button>
              </BreadcrumbItem>
            );
          })}
        </Breadcrumb>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <Grid gap="10px" gridTemplateColumns="repeat(auto-fit, 100px)">
          {folders?.length ? (
            folders.map((folder) => {
              return (
                <Tooltip label={folder.name} key={`folder${folder.basename}`}>
                  <Button
                    height="100px"
                    width="100px"
                    onClick={() =>
                      setSelectedFolder({
                        id: folder.basename,
                        name: folder.name,
                      })
                    }
                    display="flex"
                    flexDirection="column"
                    variant="outline"
                    colorScheme="gray"
                    color="gray.600"
                    whiteSpace="normal"
                  >
                    <FiFolder size="50px" />

                    <Text width="80px" mt={2} isTruncated>
                      {folder.name}
                    </Text>
                  </Button>
                </Tooltip>
              );
            })
          ) : (
            <Button
              height="100px"
              width="100px"
              display="flex"
              flexDirection="column"
              variant="ghost"
              disabled
              colorScheme="gray"
              color="gray.600"
              padding="10px"
            >
              <FiCheck />
              <Text fontSize="xs" display="block">
                Nenhuma
                <br /> subpasta
              </Text>
            </Button>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default NavigationPage;
