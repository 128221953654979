import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// import ButtonPageTop from '~/shared/components/ButtonPageTop';
import { Box } from '@chakra-ui/react';

import Header, { Provider } from '~/shared/components/Header';
import Sidebar from '~/shared/components/Sidebar';
import api from '~/shared/services/api';

import { Container, Content, Section, BackgroundEffect } from './styles';

const LayoutDefault: React.FC = ({ children }) => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [companyProvider, setCompanyProvider] = useState<Provider[]>([]);
  const { location } = useHistory();

  useEffect(() => {
    if (location.pathname) {
      setToggleSidebar(false);
    }
    api.get<Provider[]>('company/provider').then((response) => {
      const { data } = response;
      setCompanyProvider(data);
    });
  }, [location.pathname]);

  return (
    <Container>
      <Header
        showSidebar={toggleSidebar}
        toggleSidebar={(toggle) => setToggleSidebar(toggle)}
        companyProvider={companyProvider}
      />
      <Content>
        <Sidebar
          showSidebar={toggleSidebar}
          companyProvider={companyProvider}
        />
        <Section>
          <Box id="mainContaint">{children}</Box>
        </Section>
      </Content>

      <BackgroundEffect />
    </Container>
  );
};

export default LayoutDefault;
