import React, { useState, useEffect } from 'react';

import { Tooltip } from '@chakra-ui/react';
import { parseISO } from 'date-fns';

import getDifferenceBetweenDates from '~/utils/getDifferenceBetweenDates';

import { Container } from './styles';

interface TimerProps {
  day: number;
  hour: number;
  minute: number;
  second: number;
}

interface Props {
  startTime: string;
}

const TimerControl: React.FC<Props> = ({ startTime, children }) => {
  const [timer, setTimer] = useState<TimerProps>(() => {
    const [days, hours, minutes, seconds] = getDifferenceBetweenDates(
      new Date(),
      parseISO(startTime),
    );

    return {
      day: days,
      hour: hours,
      minute: minutes,
      second: seconds,
    };
  });
  const [isTimeOut, setIsTimeOut] = useState(false);

  useEffect(() => {
    // exit early when we reach 0
    if (isTimeOut) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setTimeout(() => {
      let seconds;
      let minutes;
      let hours;
      let days;

      if (timer.second > 0) {
        seconds = timer.second - 1;
        minutes = timer.minute;
        hours = timer.hour;
        days = timer.day;
      } else if (timer.minute > 0) {
        seconds = 59;
        minutes = timer.minute - 1;
        hours = timer.hour;
        days = timer.day;
      } else if (timer.minute === 0 && timer.hour >= 0) {
        if (timer.hour > 0) {
          hours = timer.hour - 1;
          days = timer.day;
          minutes = 59;
          seconds = 59;
        } else if (timer.hour === 0 && timer.day > 0) {
          hours = 23;
          minutes = 59;
          seconds = 59;
          days = timer.day - 1;
        } else {
          hours = 0;
          days = 0;
          minutes = 0;
          seconds = 0;
        }
      } else {
        minutes = 0;
        hours = 0;
        days = timer.day;
        seconds = 0;
      }

      setTimer({
        day: days,
        hour: hours,
        minute: minutes,
        second: seconds,
      });

      if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        setIsTimeOut(true);
      }
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timer, isTimeOut]);

  return (
    <>
      {isTimeOut ||
      (timer.day === 0 &&
        timer.hour === 0 &&
        timer.minute === 0 &&
        timer.second === 0) ? (
        children
      ) : (
        <Tooltip
          hasArrow
          label="Atividade com início mínimo. Para iniciar está atividade você deve aguardar até o contador chegar a 00:00:00:00"
          placement="auto"
        >
          <Container>
            <div>
              <span>{timer.day < 10 ? `0${timer.day}` : timer.day}</span>:
              <span>{timer.hour < 10 ? `0${timer.hour}` : timer.hour}</span>:
              <span>
                {timer.minute < 10 ? `0${timer.minute}` : timer.minute}
              </span>
              :
              <span>
                {timer.second < 10 ? `0${timer.second}` : timer.second}
              </span>
            </div>
            <strong>
              <small>Dia</small>
              <small>hh</small>
              <small>mm</small>
              <small>ss</small>
            </strong>
          </Container>
        </Tooltip>
      )}
    </>
  );
};

export default TimerControl;
