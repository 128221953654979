import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled.div``;

export const BoxCompany = styled.div`
  /* padding: 0.25rem 0; */
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  max-width: 325px;
  text-overflow: ellipsis;
`;
