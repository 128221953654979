import React from 'react';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { ActivityDetails } from '~/modules/activityExecution/pages/PlanningInfo';

function getFormattedPlanningInfo(activity: ActivityDetails): ActivityDetails {
  if (!activity) return {} as ActivityDetails;

  const dateStartDetails = activity.start_real_date
    ? new Date(activity?.start_real_date)
    : new Date();

  const dateDurationDetails = new Date(
    `${activity.start_date} ${activity.duration}`,
  );

  dateStartDetails.setHours(
    dateStartDetails.getHours() + dateDurationDetails.getHours(),
  );
  dateStartDetails.setMinutes(
    dateStartDetails.getMinutes() + dateDurationDetails.getMinutes(),
  );
  return {
    ...activity,
    formatted_start_date:
      activity.start_date &&
      format(parseISO(activity.start_date), 'dd/MM/yyyy'),
    formatted_start_time:
      activity.start_date &&
      format(
        parseISO(`${activity.start_date} ${activity.start_time}`),
        'HH:mm',
      ),
    formatted_duration:
      activity.start_date &&
      format(
        parseISO(`${activity.start_date} ${activity.duration}`),
        "HH'h' mm'min'",
      ),
    blocked: !!activity.blocked,
    formatted_estimated_time: format(
      dateStartDetails,
      "dd ' de ' MMMM, yyyy 'às' HH:mm'h'",
      {
        locale: ptBR,
      },
    ),
    fatal_date: activity.fatal_date
      ? format(
          parseISO(activity.fatal_date),
          "dd ' de ' MMMM, yyyy 'às' HH:mm'h'",
          {
            locale: ptBR,
          },
        )
      : undefined,
    formatted_start_and_date_time: `${format(
      parseISO(`${activity.start_date} ${activity.start_time}`),
      'HH:mm',
    )} - ${format(
      parseISO(`${activity.end_date} ${activity.end_time}`),
      'HH:mm',
    )}`,
  };
}

export default getFormattedPlanningInfo;
