import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { FiEdit, FiPlus, FiShield, FiTrash } from 'react-icons/fi';

import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import AlertDialog from '~/shared/components/AlertDialog';
import EmptyPage from '~/shared/components/EmptyPage';
import Input from '~/shared/components/InputChakra';
import SwitchChakra from '~/shared/components/InputChakra/SwitchChakra';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import StepsForm from '~/shared/components/StepsForm';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import { Container } from './styles';

interface RoleDTO {
  id: number;
  name: string;
  slug: string;
  description: string;
  created_at: string;
}

interface PermissionsProps {
  id: number;
  name: string;
  slug: string;
  selected?: boolean;
  value: string | number;
  label: string;
}

interface RoleData {
  id: number;
  name: string;
  slug: string;
  description: string;
  permissions: PermissionsProps[];
}

interface RoleProps extends IPaginationProps {
  data: RoleDTO[];
}

const Role: React.FC = () => {
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenPerm,
    onOpen: onOpenPerm,
    onClose: onClosePerm,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);
  const formRefPerm = useRef<FormHandles>(null);
  const cancelDeleteRef = useRef(null);
  const btnCreateRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingPerm, setLoadingPerm] = useState(false);

  const [selectRoleDelete, setSelectRoleDelete] = useState(1);
  const [roleId, setRoleId] = useState(0);
  const [modalSuccess, setModalSuccess] = useState(0);
  const [page, setPage] = useState(1);
  const [roles, setRoles] = useState<RoleProps>({} as RoleProps);
  const [selectedRole, setSelectedRole] = useState<RoleDTO>({} as RoleDTO);
  const [permissions, setPermissions] = useState<PermissionsProps[]>([]);
  const [permissionsSelected, setPermissionsSelected] = useState<number[]>([]);

  const [steps, setSteps] = useState(1);

  const addToast = useToast();

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Id',
      },
      {
        title: 'Nome',
      },
      {
        title: 'Slug',
      },
      {
        title: 'Descrição',
      },
      {
        title: 'Permissões',
      },
      {
        title: 'Ações',
      },
    ];
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get<RoleProps>('roles', { params: { page } })
      .then((response) => {
        setRoles(response.data);
        setLoading(false);
      })
      .catch(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro no carregamento dos dados',
          description:
            'Não foi possível listar as funções. Tente novamente mais tarde.',
        });
        setLoading(false);
      });
  }, [addToast, page, roleId]);

  useEffect(() => {
    async function loadPermissions(): Promise<void> {
      const response = await api.get<PermissionsProps[]>('permissions/all');

      const permissionData = response.data;

      setPermissions(
        user.company_id !== 1
          ? permissionData
              .filter((perm) => perm.id > 4)
              .map((permission) => {
                return {
                  ...permission,
                  value: permission.id,
                  label: permission.name,
                };
              })
          : permissionData.map((permission) => {
              return {
                ...permission,
                value: permission.id,
                label: permission.name,
              };
            }),
      );
    }
    loadPermissions();
  }, [user.company_id]);

  const handleOpenModalDelete = useCallback(
    (id: number) => {
      setSelectRoleDelete(id);
      onOpenDelete();
    },
    [onOpenDelete],
  );

  const handleNewPage = useCallback((pageNew: number) => {
    setPage(pageNew);
  }, []);

  const handleSubmitDelete = useCallback(() => {
    if (roles) {
      onCloseDelete();
      const updatedData = roles?.data?.filter(
        (role) => role.id !== selectRoleDelete,
      );

      const newData = {
        ...roles,
        total: roles?.total - 1,
        to: roles?.to - 1,
        data: updatedData,
      };

      setRoles(newData);
    }

    api
      .delete(`roles/${selectRoleDelete}`)
      .then(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Função deletada!',
          description: 'Dados deletado com sucesso!',
        });

        setModalSuccess(modalSuccess + 1);
      })
      .catch((err) => {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Função não deletada!',
          description: 'Erro ao deletar os dados da função.',
        });
      });
  }, [selectRoleDelete, addToast, modalSuccess, roles, onCloseDelete]);

  const handleNextSteps = useCallback(
    async (data) => {
      setLoading(true);
      if (steps === 1) {
        try {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            name: Yup.string()
              .required('Nome obrigatório')
              .min(4, 'No mínimo 4 dígitos'),
            slug: Yup.string()
              .required('O slug é obrigatório')
              .min(4, 'No mínimo 4 dígitos'),
            description: Yup.string().min(4, 'No mínimo 4 dígitos'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await api.post('/roles', {
            ...data,
            permissions: null,
          });

          setRoleId(response.data?.id);

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'success',
            title: 'Função criada!',
            description:
              'A função foi criada com sucesso, prossiga adicionando permissões.',
          });

          setSteps(2);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
          } else {
            const { error } = err.response.data;

            addToast({
              position: 'top-right',
              isClosable: true,
              status: 'error',
              title: 'Erro no cadastro, tente novamente!',
              description: error,
            });
          }
        }
      } else if (steps === 2) {
        try {
          formRef.current?.setErrors({});

          if (!data.permissions || data.permissions?.length === 0) {
            addToast({
              position: 'top-right',
              isClosable: true,
              status: 'warning',
              title: 'Ooops!!!',
              description: 'Selecione no mínimo uma permissão.',
            });
            setLoading(false);
            return;
          }

          await api.put(`/roles/${roleId}`, data);
          setRoleId(0);

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'success',
            title: 'Permissões adicionadas!',
            description:
              'As permissões da função foram adicionadas com sucesso.',
          });

          setSteps(3);
        } catch (err) {
          const { error } = err.response.data;

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Erro no cadastro, tente novamente!',
            description: error,
          });
        }
      } else {
        setSteps(1);
      }
      setLoading(false);
    },
    [steps, roleId, addToast],
  );

  const handleOnCloseSteps = useCallback(() => {
    onClose();
    setSteps(1);
  }, [onClose]);

  const handleEditRole = useCallback(
    (data: RoleDTO) => {
      setSelectedRole(data);
      onOpenEdit();
    },
    [onOpenEdit],
  );

  const handleSubmitEdit = useCallback(
    async (data) => {
      setLoadingEdit(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Nome obrigatório')
            .min(4, 'No mínimo 4 dígitos'),
          slug: Yup.string()
            .required('O slug é obrigatório')
            .min(4, 'No mínimo 4 dígitos'),
          description: Yup.string().min(4, 'No mínimo 4 dígitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(`/roles/${selectedRole.id}`, data);

        const formattedRoles = roles.data.map((role) => {
          if (role.id === selectedRole.id) {
            return {
              ...role,
              ...data,
            };
          }
          return role;
        });

        setRoles({ ...roles, data: formattedRoles });

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Função editada!',
          description: 'Dados alterado com sucesso!',
        });

        setLoadingEdit(false);
        onCloseEdit();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current?.setErrors(errors);

          return;
        }

        const { error } = err.response.data;

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro na atualização',
          description: error,
        });
        setLoading(false);
      }
    },
    [onCloseEdit, roles, selectedRole, addToast],
  );

  const handleEditPermissions = useCallback(
    async (role: RoleDTO) => {
      onOpenPerm();
      setLoadingPerm(true);

      try {
        const responseRole = await api.get<RoleData>(`roles/${role.id}`);

        const { permissions: loadNewPermissions } = responseRole.data;
        setSelectedRole(role);
        setPermissionsSelected(
          loadNewPermissions.map((permission) => permission.id),
        );

        const response = await api.get<PermissionsProps[]>('permissions/all');

        const permissionData = response.data;

        const formattedPermissions =
          user.company_id !== 1
            ? permissionData
                .filter((perm) => perm.id > 4)
                ?.map((permission) => {
                  return {
                    ...permission,
                    value: permission.id,
                    label: permission.name,
                  };
                })
            : permissionData?.map((permission) => {
                return {
                  ...permission,
                  value: permission.id,
                  label: permission.name,
                };
              });

        setPermissions(formattedPermissions);
        setLoadingPerm(false);
      } catch (err) {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });
          setLoadingPerm(false);
          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao carregar permissões!',
          description:
            'Não foi possivel listar permissões, tente novamente mais tarde!',
        });
        setLoadingPerm(false);
      }
    },
    [addToast, onOpenPerm, user.company_id],
  );

  const handleSubmitPermissions = useCallback(
    async (data) => {
      setLoadingPerm(true);

      try {
        formRefPerm.current?.setErrors({});

        const schema = Yup.object().shape({
          permissions: Yup.array().of(Yup.string()).required('Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.permissions.length > 0) {
          await api.put(`/roles/${selectedRole.id}`, data);

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'success',
            title: 'Permissões atualizadas!',
            description: 'As permissões da função foram atualizadas.',
          });

          setPermissionsSelected([]);
        } else {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Erro na atualização de permissões',
          });
        }
        onClosePerm();
        setLoadingPerm(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setLoadingPerm(false);
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Erro na atualização de permissões',
          });
          return;
        }

        const { error } = err.response.data;

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro na atualização de permissões',
          description: error,
        });
        setLoadingPerm(false);
      }
    },
    [addToast, selectedRole, onClosePerm],
  );

  return (
    <Box pos="relative">
      <Container>
        <SectionHeader title="Controle de funções" pagename="Funções">
          <Button
            colorScheme="blue"
            isLoading={loading}
            isDisabled={loading}
            onClick={onOpen}
            ref={btnCreateRef}
          >
            <FiPlus />
            Cadastrar
          </Button>
        </SectionHeader>

        {loading && (
          <Box
            pos="absolute"
            top={0}
            left={0}
            w="full"
            h="400px"
            bg="rgba(255,255,255,0.5)"
          >
            <Loading />
          </Box>
        )}

        {roles && roles.data?.length > 0 && (
          <Table
            theadData={tableTitles}
            pagination={{
              current_page: roles.current_page,
              last_page: roles.last_page,
              per_page: roles.per_page,
              to: roles.to,
              total: roles.total,
            }}
            newPage={(pg) => handleNewPage(pg)}
            loading={false}
          >
            {roles.data?.length > 0 &&
              !loading &&
              roles.data?.map((role) => (
                <Box as="tr" key={String(role.id)} _hover={{ bg: 'gray.50' }}>
                  <Box as="td" py={1}>
                    {role.id}
                  </Box>
                  <Box as="td" py={1}>
                    {role.name}
                  </Box>
                  <Box as="td" py={1}>
                    {role.slug}
                  </Box>
                  <Box as="td" py={1}>
                    {role.description}
                  </Box>
                  <Box as="td" py={1}>
                    <IconButton
                      aria-label="Permissões"
                      icon={<FiShield />}
                      variant="ghost"
                      bg="green.50"
                      ml={[0, 2]}
                      colorScheme="green"
                      onClick={() => handleEditPermissions(role)}
                      title="Adicionar ou remover permissões da função"
                    />
                  </Box>
                  <Box as="td" py={1} className="row">
                    <Flex justifyContent="flex-end">
                      <IconButton
                        aria-label="Editar"
                        icon={<FiEdit />}
                        variant="ghost"
                        bg="yellow.50"
                        ml={[0, 2]}
                        colorScheme="yellow"
                        onClick={() => handleEditRole(role)}
                      />
                      <IconButton
                        aria-label="Deletar"
                        icon={<FiTrash />}
                        variant="ghost"
                        bg="red.50"
                        ml={[0, 2]}
                        colorScheme="red"
                        onClick={() => handleOpenModalDelete(role.id)}
                      />
                    </Flex>
                  </Box>
                </Box>
              ))}
          </Table>
        )}

        {!roles?.data?.length && !loading && (
          <EmptyPage title="Nenhum registro encontrado." />
        )}
      </Container>

      <ModalChakra
        title="Atualizar Função"
        onClose={onCloseEdit}
        isOpen={isOpenEdit}
        onSubmit={() => formRefEdit.current?.submitForm()}
        isLoading={loadingEdit}
      >
        <Form
          initialData={selectedRole}
          ref={formRefEdit}
          onSubmit={handleSubmitEdit}
        >
          <Input label="Nome" name="name" placeholder="Nome da função" />
          <Input
            label="Slug"
            name="slug"
            placeholder="Slug com (-) e sem espaços"
          />
          <Input
            label="Descrição"
            name="description"
            placeholder="Descrição resumida"
          />
        </Form>
      </ModalChakra>

      <ModalChakra
        title="Atualizar Permissões"
        onClose={onClosePerm}
        isOpen={isOpenPerm}
        onSubmit={() => formRefPerm.current?.submitForm()}
        isLoading={loadingPerm}
      >
        <Text fontSize="sm">Função</Text>
        <Heading as="h4" size="lg">
          {selectedRole.name}
        </Heading>
        <Text mb={3}>Descrição: {selectedRole.description}</Text>

        <Divider mb={3} />

        {loadingPerm ? (
          <Loading />
        ) : (
          <Form
            initialData={{ permissions: permissionsSelected }}
            ref={formRefPerm}
            onSubmit={handleSubmitPermissions}
          >
            <SwitchChakra
              name="permissions"
              label="Permissões"
              options={permissions}
              sizeCustom="sm"
              isReversed
            />
          </Form>
        )}
      </ModalChakra>

      <AlertDialog
        title="Deletar função?"
        description="Tem certeza que deseja deletar a função selecionada"
        isOpen={isOpenDelete}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDelete}
        onSubmit={handleSubmitDelete}
      />

      <Drawer
        preserveScrollBarGap
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnCreateRef}
        size="md"
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Cadastrando Funções
          </DrawerHeader>

          <Flex p="1rem 1.5rem">
            <StepsForm
              stepsTotal={3}
              currentSteps={steps}
              stepsTitles={[
                'Cadastrar Função',
                'Adicionar Permissões',
                'Finalizar',
              ]}
            />
          </Flex>

          <DrawerBody overflowY="auto">
            <Box minH={200}>
              <Form ref={formRef} onSubmit={handleNextSteps}>
                {steps === 1 && (
                  <>
                    <Input
                      label="Nome da função"
                      name="name"
                      type="text"
                      placeholder="Nome"
                      autoFocus
                    />
                    <Input
                      label="Slug"
                      name="slug"
                      type="text"
                      placeholder="Slug com (-) e sem espaços"
                    />
                    <Input
                      label="Descição resumida"
                      name="description"
                      type="text"
                      placeholder="Descrição"
                      maxLength={254}
                    />
                  </>
                )}
                {steps === 2 && (
                  <>
                    <SwitchChakra
                      name="permissions"
                      label="Permissões"
                      options={permissions}
                      justifyContent="flex-start"
                      sizeCustom="sm"
                      isReversed
                    />
                  </>
                )}
                {steps === 3 && (
                  <Flex
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                    py={8}
                  >
                    <Icon name="check-circle" size="154px" color="blue.100" />
                    <Text
                      color="blue.500"
                      mt={5}
                      fontWeight="medium"
                      textAlign="center"
                      fontSize="24px"
                    >
                      Tudo certo! <br /> A função e suas permissões foram salvas
                    </Text>
                  </Flex>
                )}
              </Form>
            </Box>
          </DrawerBody>

          <DrawerFooter justifyContent="flex-end">
            {!loading && (
              <Button
                variant="outline"
                mr={3}
                onClick={handleOnCloseSteps}
                size="lg"
              >
                Fechar
              </Button>
            )}
            {steps !== 3 && (
              <Button
                variant="solid"
                colorScheme="blue"
                isLoading={loading}
                isDisabled={loading}
                size="lg"
                type="submit"
                ml="auto"
                onClick={() => formRef.current?.submitForm()}
              >
                {steps === 3 ? 'Finalizar' : 'Próxima'}
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Role;
