import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

import {
  Box,
  Flex,
  Button,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { PropsChart } from '~/modules/dashboard/components/Doughnut';
import { EvaluationPeriodAvg } from '~/modules/dashboard/pages/Dashboard';
import { WorkDeal } from '~/modules/management/pages/BSCManagement';

import { DatasetsBSC } from '../@types/chart';
import { SkeletonLegendBSC, ButtonsBSC, BarContent } from './styles';

interface Props {
  id: number;
  name: string;
  workDeal?: WorkDeal;
  title?: string;
  maxYAxes: number;
  stepSize: number;
  loading: boolean;
  averagePoints: EvaluationPeriodAvg[];
  selectChart(value: string | number): void;
}

const BarChartPerformanceReview: React.FC<Props> = ({
  name,
  title,
  maxYAxes,
  stepSize,
  loading = true,
  averagePoints,
  selectChart,
}) => {
  const averagePointsChartColor = 'rgba(0, 112, 192, 0.8)';

  const handleElementChart = useCallback(
    (dataset) => {
      if (dataset[0]?._view) {
        const index = dataset[0]?._index;
        const value = averagePoints[index].id;

        selectChart(value);
      }
    },
    [averagePoints, selectChart],
  );

  return (
    <>
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        w="full"
      >
        <Text
          textAlign="center"
          fontSize={['md', '3xl']}
          fontWeight="bold"
          mx={5}
        >
          {/* Aqui era sua pontuação do bsc, se precisar passar dessa forma, adicionar uma prop, isProfilePage, ou algo do tipo */}
          {title || `Pontuação ${name}`}
        </Text>
      </Flex>
      <Box width={{ sm: 'full', xl: '700px' }}>
        {!loading ? (
          <Box width="full" mb={5}>
            <BarContent
              flexDir={['column', 'row']}
              alignItems="center"
              justifyContent={['center', 'space-between']}
            >
              <Bar
                getElementsAtEvent={handleElementChart}
                type="bar"
                plugins={[ChartDataLabels]}
                legend={{
                  position: 'bottom',
                  labels: { fontSize: 15 },
                }}
                options={{
                  scales: {
                    unitStepSize: 10,
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          min: 0,
                          max: maxYAxes,
                          stepSize,
                          padding: 10,
                        },
                      },
                    ],
                  },
                  // plugins: {datalabels: {}},
                  layout: {
                    padding: {
                      top: 30,
                    },
                  },
                  animation: {
                    // easing: 'easeOutQuint',
                    duration: 5000,
                    // animateScale: true,
                  },
                }}
                data={{
                  labels: averagePoints.length
                    ? averagePoints.map((avg, key) => {
                        return `${key + 1}° Avaliação`;
                      })
                    : ['Avaliação'],

                  datasets: [
                    {
                      label: 'Pontuação Media das Avaliações',
                      data: averagePoints.length
                        ? averagePoints.map((avg) => {
                            return avg.avg_score
                              ? Number(avg?.avg_score?.toFixed(2))
                              : 0;
                          })
                        : [0],
                      borderColor: averagePointsChartColor,
                      pointBorderColor: averagePointsChartColor,
                      pointBackgroundColor: averagePointsChartColor,
                      backgroundColor: averagePointsChartColor,
                      categoryPercentage: 0.3,
                      // fill: false,
                      datalabels: {
                        // display: [true, true],
                        borderRadius: 5,
                        borderWidth: 8,
                        color: averagePointsChartColor,
                        font(context) {
                          const widthChart = context.chart?.width;
                          const sizeFont = Math.round(
                            widthChart ? widthChart / 40 : 0,
                          );

                          return {
                            weight: 'bold',
                            size: sizeFont,
                          };
                        },
                        padding: -5,
                        align: 'top',
                        anchor: 'end',
                      },
                    },
                  ],
                }}
              />
            </BarContent>
          </Box>
        ) : (
          <Flex
            flexDir="column"
            width={{ sm: 'full', xl: '700px' }}
            alignItems="center"
            justifyContent="center"
            mb={5}
          >
            <Box w="full">
              {/* <SkeletonLegendBSC>
                <Skeleton height="40px" width="220px" />
              </SkeletonLegendBSC> */}

              <Skeleton
                pb="50%"
                width={['auto', 'calc(100% - 30px)']}
                height={['100px', 'auto']}
                mt={['10px', 0]}
              />
            </Box>
            <Flex mt={3}>
              <Skeleton height="25px" width="220px" />
            </Flex>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default BarChartPerformanceReview;
