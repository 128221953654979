import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { FaUsers } from 'react-icons/fa';
import {
  FiPlus,
  FiFilter,
  FiSettings,
  FiGrid,
  FiFileText,
  FiCrosshair,
  FiTrash,
  FiXCircle,
  FiMinus,
  FiChevronDown,
  FiHelpCircle,
} from 'react-icons/fi';
import { MdBlock } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Stack,
  IconButton,
  Grid,
  Avatar,
  Heading,
  Tooltip,
  Text,
  Flex,
  AvatarGroup,
  Badge,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  DrawerFooter,
  Switch,
  FormLabel,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import { Step } from 'intro.js';
import * as Yup from 'yup';

import { Replace } from '~/helpers/Replace';
import { useAuth } from '~/hooks/auth';
import { User } from '~/modules/accessControl/@types/user';
import AlertDialog from '~/shared/components/AlertDialog';
import AvatarCustom from '~/shared/components/AvatarCustom';
import MultipleInputs from '~/shared/components/Input/MultipleInputs';
import Input from '~/shared/components/InputChakra/';
import Select from '~/shared/components/InputChakra/SelectChakra';
import TextareaChakra from '~/shared/components/InputChakra/Textarea';
import Loading from '~/shared/components/Loading';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import MultiSelect from '~/shared/components/Select/MultiSelect';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import {
  Department as DepartmentInterface,
  CorporateEmail as CorporateEmailInterface,
} from '../../@types/management';
import ManageEmail from '../../components/ManageEmail';

export interface Member {
  id: number;
  name: string;
  email: string;
  avatar?: string;
  responsibility?: Responsibility;
}

export interface Responsibility {
  id: number;
  name: string;
}

/* export interface DepartmentMain {
  id: number;
  name: string;
  slug: string;
  responsible?: Member;
} */

// Utilizar com paginação

export interface DepartmentSelect extends DepartmentInterface {
  value: number;
  label: string;
}

interface DepartmentProps extends IPaginationProps {
  data: DepartmentInterface[];
}

export interface UserSelect extends Replace<User, { responsibility?: string }> {
  responsibility: string;
  responsibility_id: number;
  removed?: boolean;
  value: number;
  label: string;
}

export interface FormData {
  name: string;
  description?: string;
  responsible_id?: number;
  superior_department_id: number;
  subordinate_departments?: string[];
  email?: string[];
}

export interface CorporateEmail extends CorporateEmailInterface {
  label: string;
  value: number;
}

export async function getAllEmails(): Promise<CorporateEmail[]> {
  const response = await api.get<CorporateEmail[]>('corporate-emails');

  const { data } = response;

  return data;
}

const Department: React.FC = () => {
  const addToast = useToast();

  const [loading, setLoading] = useState(true);
  const [loadedCorporateEmails, setLoadedCorporateEmails] = useState(false);
  const [loadedFilters, setLoadedFilters] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [toggleSupDepartment, setToggleSupDepartment] = useState(false);
  const [toggleResponsible, setToggleResponsible] = useState(true);
  const [departmentRemoved, setDepartmentRemoved] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [departmentsSelect, setDepartmentsSelect] = useState<
    DepartmentSelect[]
  >([]);
  const [departments, setDepartments] = useState<DepartmentInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalData, setTotalData] = useState(0);

  const [users, setUsers] = useState<UserSelect[]>([]);

  const [corporateEmail, setCorporateEmail] = useState<CorporateEmail[]>([]);

  const [enableTourDepartment, setEnableTourDepartment] = useState(false);
  const [stepsDepartment, setStepsDepartment] = useState<Step[]>([
    {
      element: '#container',
      title: 'Controle de Departamentos',
      intro:
        'Nessa página você acompanha e administra todos os departamentos de sua empresa.',
    },
    {
      element: '#createDepartment',
      title: 'Cadastrar Departamento.',
      intro:
        'Aqui você pode criar os departamentos de sua empresa. clique no botão.',
      position: 'left',
    },
    {
      element: '#configDepartment',
      title: 'Abrir Informações do Departamento',
      intro:
        'Aqui você pode visualizar e editar os detalhes dos departamentos de sua empresa. clique no botão.',
    },
  ]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDestroy,
    onOpen: onOpenDestroy,
    onClose: onCloseDestroy,
  } = useDisclosure();

  const formRef = useRef<FormHandles>(null);
  const infinityLoaderRef = useRef(null);

  const btnRef = useRef(null);
  const cancelRef = useRef(null);

  const history = useHistory();

  function resetForm(): void {
    setToggleResponsible(true);
    setToggleSupDepartment(false);
    // setToggleEmail(false);
    // setCorporateEmailSelect(defaultEmailSelect);
    onClose();
  }

  const handleManageDepartment = useCallback(
    (id) => {
      history.push(`/gerenciar/departamento/${id}`);
    },
    [history],
  );

  const getDepartments = useCallback(async (numPage: number): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.get<DepartmentProps>('department', {
        params: {
          list_members: 1,
          list_subordinates: 1,
          page: numPage,
        },
      });

      const { data, current_page, last_page, total } = response.data;

      if (!data.length) return;

      const preparedData = data.map((dep) => {
        return {
          ...dep,
          members: dep.users,
        };
      });

      /* Necessário para quando testarmos o componente e ele renderizar novamente não incluir dados repetidos (que possuem a mesma key)
      no estado departments */
      if (numPage === 1) setDepartments(preparedData);
      else setDepartments((state) => [...state, ...preparedData]);

      setPage(numPage);
      setCurrentPage(current_page);
      setLastPage(last_page);
      setTotalData(total);
    } finally {
      setLoading(false);
    }
    /* Não podemos utilizar o estado page aqui porque ao atualizar a página essa callback seria chamada novamente e como
    está no array de dependencias do useEffect o executaria também pois a sua dependência mudou de valor */
  }, []);

  // const getCurrentPage = useMemo(() => currentPage, [currentPage]);

  useEffect(() => {
    // let didCancel = false;

    const { CancelToken } = axios;
    const source = CancelToken.source();
    // Departamentos paginados
    getDepartments(1);

    setLoadedFilters(false);

    async function getFilters(): Promise<void> {
      try {
        const responseDep = await api.get<DepartmentSelect[]>('department', {
          cancelToken: source.token,
        });

        const { data } = responseDep;
        // O navegador ainda aguarda a conclusão da solicitação HTTP, mas ignora seu resultado
        /* if (!didCancel) {
          setDepartmentsSelect(
            data.map((dep) => {
              return {
                ...dep,
                value: dep.id,
                label: dep.name,
              };
            }),
          );
        } */

        setDepartmentsSelect(
          data.map((dep) => {
            return {
              ...dep,
              value: dep.id,
              label: dep.name,
            };
          }),
        );

        const responseUser = await api.get<UserSelect[]>('users/all', {
          cancelToken: source.token,
        });

        setUsers(
          responseUser.data.map((us) => {
            return {
              ...us,
              value: us.id,
              label: us.name,
            };
          }),
        );
      } /* catch (e) {
        console.log(e);
      }  */ finally {
        setLoadedFilters(true);
      }
    }

    getFilters();

    /* Não é possível adicionar no getFilters, pois ele por si só já é uma async function, não é possível utilizar o await
    que já está sendo utilizado dentor do getAllEmails */
    getAllEmails()
      .then((dt) => {
        setCorporateEmail(
          dt.map((email) => {
            return {
              id: email.id,
              email: email.email,
              company_id: email.company_id,
              created_at: email.created_at,
              label: email.email,
              value: email.id,
            };
          }),
        );
      })
      .finally(() => setLoadedCorporateEmails(true));

    // Função chamada ao desmontar o componente
    /* Necessária, pois se ao carregar e exibir os departamentos e ainda não carregar os filtros, seria realizada uma tentativa de atualizar o estado
    em um componente desmontado, além de ainda esperar a requisição HTTP ser concluída sem necessidade alguma */
    return () => {
      // didCancel = true;
      source.cancel('Cancelado pelo usuário');
    };

    /* const emails = getAllEmails(); */
  }, [getDepartments]);

  /* const handleObserver = useCallback(
    (entries) => {
      // console.log('teste scroll');
      const target = entries[0];
      if (target.isIntersecting) {
        // console.log('teste scroll');
        getDepartments(page + 1);
      }
    },
    [getDepartments, page],
  ); */

  /* useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };

    //
    console.log('infinity scroll');

    const observer = new IntersectionObserver(handleObserver, option);
    if (infinityLoaderRef?.current)
      observer.observe(infinityLoaderRef.current || Element.prototype);
    // Element.prototype foi utilizado para burlar o typescript no método observe
  }, [handleObserver]); */

  async function handleSubmit(formData: FormData): Promise<void> {
    setLoadingSubmit(true);

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.array()
          .of(Yup.string().required('Se não possui e-mail desmarque a opção'))
          .notRequired(),
        superior_department_id: Yup.string().test(
          'superiorDepartmentValid',
          'O departamento superior não pode ser igual a um departamento subordinado',
          () => {
            return !formData.subordinate_departments?.find(
              (sub) => Number(formData.superior_department_id) === Number(sub),
            );
          },
        ),
        subordinate_departments: Yup.array()
          .of(
            Yup.string().required(
              'Se não possui departamento subordinado desmarque a opção',
            ),
          )
          .notRequired(),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      onClose();

      setLoading(true);

      const response = await api.post<DepartmentSelect>('department', formData);

      const { data } = response;

      /* console.log(data); */

      setDepartments((state) => [
        { ...response.data, value: data.id, label: data.name },
        ...state,
      ]);

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Departamento Cadastrado!',
        description: `O departamento ${response.data.name} foi adicionado à lista`,
        status: 'success',
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Erro ao cadastrar departamento',
        description: err?.response?.data?.error || '',
      });
    } finally {
      setLoading(false);
      setLoadingSubmit(false);
    }
  }

  async function handleDeleteDepartment(): Promise<void> {
    setLoadingDelete(true);
    onCloseDestroy();

    try {
      await api.delete(`department/${departmentRemoved?.id}`);

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Departamento Apagado!',
        description: `O departamento ${departmentRemoved?.name} foi apagado com sucesso!`,
        status: 'success',
      });

      setDepartments((state) =>
        state.filter((dep) => dep.id !== departmentRemoved?.id),
      );
    } catch (err) {
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Não foi possível apagar o departamento',
        description: err?.response.data?.error || '',
        status: 'error',
      });
    } finally {
      setLoadingDelete(false);
    }
  }

  /* function handleSetEmail(index: number, field: string, value: string): void {
    setCorporateEmailSelect((state) =>
      state.map((email, i) => {
        if (index === i) {
          return { ...email, [field]: value };
        }

        return email;
      }),
    );
  } */

  return (
    <>
      <Box id="container">
        <SectionHeader
          title="Departamentos"
          pagename="Gerenciando departamentos"
        >
          <Flex>
            <Button
              aria-label="help"
              onClick={() => {
                setEnableTourDepartment(true);
              }}
              variant="outline"
              colorScheme="blue"
              mr={3}
            >
              <FiHelpCircle />
              <Text ml={2}>Ajuda</Text>
            </Button>
            <Button
              id="createDepartment"
              leftIcon={<FiPlus />}
              colorScheme="blue"
              variant="solid"
              ref={btnRef}
              onClick={onOpen}
              isLoading={!loadedFilters}
            >
              Cadastrar
            </Button>
          </Flex>
        </SectionHeader>
        <Box pos="relative">
          {loading && <LoadingAbsolute z_index={1111} min_height={500} />}
          <Box>
            <Grid
              templateColumns={{
                base: '1fr',
                lg: 'repeat(auto-fit, minmax(500px, 1fr))',
              }}
              gap={[2, 6]}
              justifyContent="flex-start"
            >
              {departments &&
                departments.length > 0 &&
                departments.map((department) => (
                  <Box
                    key={`${department.id}-${department.responsible_id}`}
                    borderWidth="1px"
                    p={2}
                    rounded="md"
                    position="relative"
                    display="flex"
                    flexDir="column"
                    justifyContent="space-between"
                    textAlign="left"
                    backgroundColor={
                      !department.disabled_at ? 'gray.50' : 'red.200'
                    }
                    borderColor={
                      !department.disabled_at ? 'gray.100' : 'red.300'
                    }
                  >
                    <Flex
                      px={2}
                      alignItems="center"
                      justifyContent="space-between"
                      mb={3}
                    >
                      <Tooltip
                        aria-label={department.name}
                        label={department.name}
                        placement="top-start"
                      >
                        <Heading as="h4" size="md">
                          {department.name}
                        </Heading>
                      </Tooltip>
                      <Flex alignItems="center" ml={3}>
                        {!!department.subordinate_departments?.length && (
                          <Popover autoFocus={false} returnFocusOnClose={false}>
                            <Tooltip label="Equipes">
                              <Box>
                                <PopoverTrigger>
                                  <IconButton
                                    aria-label="Equipes"
                                    icon={<FaUsers />}
                                    size="lg"
                                    color="blue.600"
                                    variant="ghost"
                                    isRound
                                    mr={2}
                                    _hover={{
                                      background: '#2B6CB0',
                                      color: '#fff',
                                    }}
                                    _focus={{
                                      background: '#2B6CB0',
                                      color: '#fff',
                                    }}
                                    _active={{
                                      background: '#2B6CB0',
                                      color: '#fff',
                                    }}
                                  />
                                </PopoverTrigger>
                              </Box>
                            </Tooltip>
                            <PopoverContent
                              bg="blue.600"
                              color="#fff"
                              borderColor="blue.600"
                              borderWidth="2px"
                            >
                              {/* <PopoverArrow /> */}
                              <PopoverCloseButton />
                              <PopoverHeader>
                                <Flex alignItems="center">
                                  {/* <FaUsers size="16px" /> */}
                                  <Text fontWeight="bold">Equipes</Text>
                                </Flex>
                              </PopoverHeader>
                              <PopoverBody pl="1.75rem">
                                <ul>
                                  {department.subordinate_departments?.map(
                                    (sub) => (
                                      <li key={sub.id}>{sub.name}</li>
                                    ),
                                  )}
                                </ul>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        )}

                        <IconButton
                          id="configDepartment"
                          aria-label="Gerenciar"
                          icon={<FiSettings />}
                          size="lg"
                          isRound
                          variant="ghost"
                          mr={2}
                          onClick={() => handleManageDepartment(department.id)}
                        />
                        <IconButton
                          aria-label="Apagar"
                          icon={<FiXCircle />}
                          size="lg"
                          isRound
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => {
                            setDepartmentRemoved({
                              id: department.id,
                              name: department.name,
                            });
                            onOpenDestroy();
                          }}
                        />
                      </Flex>
                    </Flex>

                    <Box d="flex" flexDir="row" flexWrap="wrap">
                      <AvatarCustom
                        src={department?.responsible?.avatar}
                        name={department?.responsible?.name}
                        size="lg"
                        ml="auto"
                        mr={2}
                        borderWidth="1px"
                        borderColor="gray"
                        mx={[0, 2]}
                        mb={[3, 0]}
                      />

                      <Box
                        d="flex"
                        flexDir="column"
                        alignItems="flex-start"
                        flex={1}
                        px={2}
                        maxW={200}
                      >
                        <Tooltip
                          aria-label={
                            department?.responsible?.name || 'Sem responsável'
                          }
                          label={
                            department?.responsible?.name || 'Sem responsável'
                          }
                          placement="top-start"
                        >
                          <Heading
                            isTruncated
                            as="h3"
                            size="sm"
                            w={150}
                            textAlign="left"
                          >
                            {department?.responsible?.name || 'Sem responsável'}
                          </Heading>
                        </Tooltip>
                        {!!department.responsible && (
                          <>
                            <Tooltip
                              /* hasArrow */
                              aria-label={department?.responsible.email}
                              label={department?.responsible.email}
                              zIndex={1}
                            >
                              <Text
                                isTruncated
                                color="gray.500"
                                fontSize="xs"
                                w={150}
                                pr={2}
                              >
                                {department.responsible.email}
                              </Text>
                            </Tooltip>
                            <Flex justifyContent="center" alignItems="center">
                              <Badge mt={1} fontSize="xs" color="gray.500">
                                {department.responsible.responsibility?.name}
                              </Badge>

                              {!!department.disabled_at && (
                                <Badge
                                  colorScheme="red"
                                  ml="5px"
                                  mt={1}
                                  fontSize="xs"
                                >
                                  Desabilitado
                                </Badge>
                              )}
                            </Flex>
                          </>
                        )}
                      </Box>

                      <Box order={3} mx="auto" minW={200}>
                        <Text
                          isTruncated
                          color="gray.500"
                          fontSize="xs"
                          w={150}
                          pr={2}
                        >
                          Membros
                        </Text>
                        {department.users && department.users.length > 0 ? (
                          <AvatarGroup size="md" max={4} mt={[2, 0]}>
                            {department.users.map((member) => (
                              <AvatarCustom
                                key={member.id}
                                name={member.name}
                                src={member?.avatar}
                              />
                            ))}
                          </AvatarGroup>
                        ) : (
                          'N/D'
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Grid>

            {/* <Box ref={infinityLoaderRef} />
             */}
            {currentPage < lastPage && (
              <Stack p={2} justifyContent="center">
                <Button
                  colorScheme="teal"
                  variant="ghost"
                  rightIcon={<FiChevronDown />}
                  onClick={() => getDepartments(page + 1)}
                >
                  Carregar mais
                </Button>
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
      <Drawer
        preserveScrollBarGap
        isOpen={isOpen}
        placement="right"
        onClose={() => resetForm()}
        finalFocusRef={btnRef}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Cadastrar Departamento
          </DrawerHeader>

          <DrawerBody>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input name="name" type="text" placeholder="Nome" autoFocus />
              {/* <TextareaChakra name="description" placeholder="Descrição" /> */}
              <Box>
                <Flex alignItems="center">
                  <FormLabel htmlFor="set_responsible">
                    Definir um responsável?
                  </FormLabel>
                  <Switch
                    id="set_responsible"
                    onChange={() => setToggleResponsible(!toggleResponsible)}
                    defaultIsChecked={toggleResponsible}
                    size="sm"
                    mb={1}
                  />
                </Flex>
                {toggleResponsible && (
                  <Select
                    name="responsible_id"
                    options={users}
                    placeholder="Selecione o responsável"
                  />
                )}
              </Box>
              <Box>
                <Flex alignItems="center">
                  <FormLabel htmlFor="set_sup_department">
                    Possui departamento superior?
                  </FormLabel>
                  <Switch
                    id="set_sup_department"
                    onChange={() =>
                      setToggleSupDepartment(!toggleSupDepartment)
                    }
                    defaultIsChecked={toggleSupDepartment}
                    size="sm"
                    mb={1}
                  />
                </Flex>
              </Box>
              {toggleSupDepartment && (
                <Select
                  name="superior_department_id"
                  options={departmentsSelect}
                  placeholder="Selecione o departamento superior"
                />
              )}
              {loadedCorporateEmails && (
                <MultiSelect
                  data={corporateEmail}
                  name="email"
                  title="E-mail"
                  toggleQuest="Possui e-mail(s)?"
                  placeholder="Selecione o e-mail corporativo"
                />
              )}
              <MultiSelect
                data={departmentsSelect.filter(
                  (dep) => !dep.superior_department_id,
                )}
                name="subordinate_departments"
                title="Dept. Sub."
                toggleQuest="Possui departamentos subordinados(s)?"
                placeholder="Selecione o departamento subordinado"
              />
            </Form>
          </DrawerBody>

          <DrawerFooter>
            {!loading && (
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancelar
              </Button>
            )}
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={() => formRef.current?.submitForm()}
              isLoading={loadingSubmit}
              isDisabled={loadingSubmit}
            >
              Confirmar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <AlertDialog
        title={`Apagar o Departamento ${departmentRemoved?.name}?`}
        description="O departamento será apagado e todos os membros incluindo o responsável, serão removidos dele"
        isOpen={isOpenDestroy}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDestroy}
        onSubmit={() => handleDeleteDepartment()}
        isLoading={loadingDelete}
      />
      <StepsIntro
        enabled={enableTourDepartment}
        onExit={() => setEnableTourDepartment(false)}
        steps={stepsDepartment}
      />
    </>
  );
};

export default Department;
