import React, { useCallback, useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  FiLock,
  FiCheckCircle,
  FiAlertCircle,
  FiMinusCircle,
  FiAlertTriangle,
  FiLink,
} from 'react-icons/fi';

import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Heading,
  Icon,
  Link,
  useToast,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';

import AvatarCustom from '~/shared/components/AvatarCustom';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import api from '~/shared/services/api';

import { IFile } from '../FileClassification';
import { FilesClassification } from '../FileClassification';
import { FlexGroupInput } from './styles';

interface FileListProps {
  files: IFile[];
  filesClassification?: FilesClassification[];
  attendanceId?: number;
  // planningId?: number;
  companyClientId?: number;
  removeFile(id: string): void;
  selectedFolder: boolean;
  // bloqueio padrao antes de selecionar a pasta do GDrive
  defaultLock?: boolean;
  setFileMention?(name: string, url: string): void;
  readOnlyName?: boolean;
  showUploading?: boolean;
}

const FileList: React.FC<FileListProps> = ({
  files,
  attendanceId,
  companyClientId,
  removeFile,
  selectedFolder = false,
  filesClassification,
  defaultLock = true,
  setFileMention,
  readOnlyName = false,
  showUploading = true,
}) => {
  const maxFileSize = process.env.REACT_APP_UPLOAD_MAX_SIZE;
  const addToast = useToast();

  const [isLoadingDelete, setIsLoadingDelete] = useState('0');

  const handleDelete = useCallback(
    (nameSlug, id) => {
      setIsLoadingDelete(id);

      api
        .post('attendance/attach/destroy', {
          file_name_slug: nameSlug,
          attendance_id: attendanceId,
          company_client_id: companyClientId,
        })
        .then(() => {
          removeFile(id);

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'success',
            title: 'Arquivo deletado com sucesso!',
          });
        })
        .catch((err) => {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });
        });
    },
    [attendanceId, companyClientId, addToast, removeFile],
  );

  return (
    <>
      <Box position="relative">
        <Box as="ul" mt="20px">
          {files.map((uploadedFile, index) => (
            <Box
              as="li"
              key={`${uploadedFile.id}-${uploadedFile?.exists}`}
              display="flex"
              flexDirection="column"
              mb={4}
              rounded="sm"
              backgroundColor="gray.50"
              px={3}
              py={4}
              position="relative"
            >
              <Flex
                flexDir="row"
                justifyContent="space-between"
                w="full"
                position="relative"
              >
                {/* {uploadedFile?.originalSize > Number(maxFileSize) && (
                    <Text>
                      Não é possível realizar o upload do arquivo, pois
                      ultrapassa o limite de {UPLOAD}
                    </Text>
                  )} */}
                <Flex
                  alignItems={['center', 'flex-start']}
                  flexDir={['column', 'row']}
                  w="full"
                >
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    maxWidth="75px"
                    mr={[0, 3]}
                  >
                    <AvatarCustom
                      size="sm"
                      name={uploadedFile.name}
                      src={uploadedFile.preview}
                      mb={1}
                    />
                    <InputChakra
                      name={`files[${index}].redableSize`}
                      sizeCustom="sm"
                      defaultValue={uploadedFile.readableSize}
                      isInline
                      style={{
                        textAlign: 'center',
                        padding: 0,
                      }}
                      variant="unstyled"
                      mbInputGroup="none"
                      readOnly
                    />
                  </Flex>
                  <Box w="full" h="full">
                    <FlexGroupInput
                      alignItems="center"
                      flexDirection={['column', 'row']}
                      h="full"
                      maxWidth="550px"
                      flexWrap="wrap"
                    >
                      <InputChakra
                        name={`files[${index}].id`}
                        defaultValue={uploadedFile.id}
                        sizeCustom="sm"
                        type="hidden"
                        containerStyle={{
                          display: 'none',
                        }}
                      />

                      <InputChakra
                        name={`files[${index}].preview`}
                        defaultValue={uploadedFile.preview}
                        sizeCustom="sm"
                        isInline
                        type="hidden"
                        containerStyle={{
                          display: 'none',
                        }}
                      />
                      <InputChakra
                        name={`files[${index}].name_slug`}
                        defaultValue={uploadedFile.name}
                        sizeCustom="sm"
                        type="hidden"
                        containerStyle={{
                          display: 'none',
                        }}
                      />

                      <Box className="inputs-file">
                        <InputChakra
                          name={`files[${index}].name`}
                          placeholder="Nome de fácil entendimento"
                          defaultValue={uploadedFile.name}
                          readOnly={readOnlyName}
                          isInline
                          sizeCustom="sm"
                          borderColor={uploadedFile?.exists ? 'tomato' : ''}
                        />
                      </Box>

                      {uploadedFile?.exists && (
                        <Tooltip label="O arquivo já existe no sistema, se mantido nessa lista ele sobrescreverá o existente">
                          <Box>
                            <FiAlertTriangle size={18} color="tomato" />
                          </Box>
                        </Tooltip>
                      )}

                      {/* Exibimos o botão de mencionar arquivos apenas, àqueles que já estão no BD e no servidor, os que não estão não possuem
                      url, apenas preview através do blob  */}
                      {setFileMention && !!uploadedFile?.url && (
                        <IconButton
                          alignSelf="center"
                          aria-label="Mencionar Arquivo"
                          size="sm"
                          ml={2}
                          p={0}
                          icon={<FiLink />}
                          order={2}
                          onClick={() => {
                            // console.log('mencionar 1');
                            const urlFile = uploadedFile?.url
                              ? `${process.env.REACT_APP_API_STORAGE}/${uploadedFile?.url}`
                              : '';

                            setFileMention(uploadedFile.name, urlFile);
                          }}
                          colorScheme="yellow"
                          variant="outline"
                        />
                      )}

                      {filesClassification && filesClassification?.length > 0 && (
                        <Box className="inputs-file">
                          <SelectChakra
                            name={`files[${index}].classification_id`}
                            placeholder="Classifique o arquivo"
                            isInline
                            options={filesClassification.map(
                              (classification) => {
                                return {
                                  label: classification.name,
                                  value: classification.id,
                                };
                              },
                            )}
                            sizeCustom="sm"
                          />
                        </Box>
                      )}
                    </FlexGroupInput>
                  </Box>
                </Flex>
              </Flex>
              {showUploading && (
                <>
                  {uploadedFile.error ? (
                    <Box position="absolute" right={2} top={2}>
                      <Tooltip
                        label="Não foi possível salvar o arquivo na pasta selecionada do drive"
                        aria-label="Erro Drive"
                        placement="auto"
                      >
                        <Box>
                          <FiAlertCircle color="#f15642" size={18} />
                        </Box>
                      </Tooltip>
                    </Box>
                  ) : (
                    <>
                      <Flex
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="start"
                        position="absolute"
                        right={2}
                        top={2}
                      >
                        <CircularProgressbar
                          styles={{
                            root: { width: 28 },
                            path: { stroke: '#3182ce' },
                          }}
                          value={uploadedFile.progress || 0}
                        />
                        {uploadedFile.progress && (
                          <Text mt={1} color="#3182ce">
                            {uploadedFile.progress === 100
                              ? 'Salvando'
                              : 'Carregando'}
                          </Text>
                        )}
                      </Flex>
                    </>
                  )}
                </>
              )}

              {uploadedFile && (
                <Box
                  position="absolute"
                  className="delete-icon"
                  left={2}
                  top={2}
                  cursor="pointer"
                  variant="ghost"
                  onClick={() => removeFile(uploadedFile.id)}
                >
                  <Tooltip
                    label="Remover"
                    aria-label="Remover arquivo"
                    placement="auto"
                  >
                    <Box>
                      <FiMinusCircle color="#f15642" size={18} />
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Box>
          ))}
        </Box>

        {defaultLock && !selectedFolder && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="full"
            height="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(255,255,255,0.5)"
          >
            <Tooltip
              label="Antes de classificar os documentos, selecione a pasta onde serão
            salvos os arquivos"
              aria-label="Aviso de seleção de pasta"
            >
              <Text>
                <FiLock size={40} />
              </Text>
            </Tooltip>
          </Box>
        )}
      </Box>
    </>
  );
};

export default FileList;
