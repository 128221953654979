import styled, { keyframes } from 'styled-components';

const activityFinishedAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Container = styled.div``;

export const GlobalLoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  height: 100%;
  width: 100%;
  z-index: 6;

  display: flex;
  justify-content: center;
  padding-top: 300px;
  align-items: flex-start;

  > div {
    justify-content: flex-start;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 0 40px;

  display: grid;
  grid-template-areas:
    'calendar activity'
    'leftActivity activity';
  grid-template-rows: auto 1fr;
  grid-template-columns: 300px 1fr;
  grid-gap: 30px;

  @media (max-width: 980px) {
    grid-template-areas: 'calendar' 'activity' 'leftActivity';
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

export const Calendar = styled.div`
  grid-area: calendar;
`;

export const ContentSidebar = styled.div`
  grid-area: leftActivity;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
`;

export const ContainerActivity = styled.div`
  grid-area: activity;
  position: relative;
`;

export const ActivityHeader = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 40px;

  div {
    display: flex;
    flex-direction: column;
  }

  h3 {
    color: #2f2e38;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  strong {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #169bd6;
    margin-top: 5px;
    text-transform: capitalize;

    display: flex;
    align-items: center;

    span {
      display: block;
      height: 10px;
      width: 1px;
      background: #169bd6;
      margin: 0 10px;
    }
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 18px;
    }

    button {
      svg {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }
  }
`;

export const ActivityBlocked = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  max-width: 150px;
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.colors.danger};
  position: relative;
  transform: all 0.2s;

  small {
    font-size: 11px;
    margin-top: 5px;
  }
`;

export const ButtonIcon = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: 0;
  padding: 10px;

  @media (max-width: 480px) {
    svg {
      height: 40px;
      width: 40px;
    }
  }
`;

export const ContentLoading = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background: rgba(255, 255, 255, 0.8);

  > div {
    justify-content: flex-start;
  }
`;

export const Avatar = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  border: 0;
  background: transparent;
  transition: all 0.2s;

  &:hover {
    background: #fbfbff;
    box-shadow: 0 0 5px rgba(11, 11, 11, 0.5);
  }

  &:active {
    transform: scale(0.9);
  }

  img {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    object-fit: cover;
    object-position: center;
    margin-right: 8px;
  }

  h2 {
    font-size: 18px;
  }
`;

export const ContainerActivityFinished = styled.div`
  width: 100%;

  > div {
    width: 100%;
    display: flex;

    animation: 1s ${activityFinishedAnimation};
  }

  &.invisible {
    > div {
      display: none !important;
    }
  }

  button.hidden-items {
    border: none;
    background: transparent;
    margin: 20px auto;
    padding: 20px;
    color: #7d7d7d;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    svg {
      margin-bottom: 10px;
    }
  }
`;

export const ContainerActivityFinishedHeader = styled.div`
  width: 100%;
  display: flex;
  margin-top: 80px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;

  h5 {
    color: #2bba85;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }

  button {
    border: none;
    background: transparent;
    color: #7d7d7d;
    font-weight: 300;
    font-size: 14px;
    transition: all 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
`;

export const FilterParam = styled.div`
  width: 100%;
  margin-bottom: 20px;

  > div#removeFilters {
    height: 19px;
    align-items: center;
    display: flex;
    font-size: 13px;

    button {
      margin-left: 10px;
      height: 100%;
      //border-radius: 2px;
      //background-color: #fff5f5;
      color: #a90a0a;
      padding: 1px 5px;
      transition: all 0.2s;
      font-size: 13px;
      //text-decoration: underline;

      display: flex;

      &:active {
        transform: scale(0.9);
      }

      svg {
        margin-right: 5px;
      }
    }
  }

  > div#filters {
    width: 100%;
    margin: 10px -5px;

    > div {
      display: flex;
      flex-wrap: wrap;

      div {
        max-width: 260px;
        height: 22px;
        display: flex;
        align-items: center;
        border-radius: 22px;
        border: 1px solid #2089dc;
        padding: 5px 8px;
        margin: 5px;

        strong {
          background: #2f2e38;
          color: #eee;
          height: 100%;
          padding: 5px 8px;
          font-size: 13px;
          text-transform: capitalize;
          border-radius: 5px 0 0 5px;
          line-height: 20px;
          font-weight: 400;
        }

        p {
          display: block;
          font-family: 'Roboto';
          font-weight: 400;
          color: #2089dc;
          font-size: 13px;
        }
      }
    }
  }
`;

export const BtnViewMore = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  font-size: 14px;
  color: #2f2f2f;
  transition: all 0.2s;

  &:active {
    transform: scale(0.9);
  }
`;

export const ContainerPop = styled.div`
  width: 100%;

  ul {
    padding-left: 20px;
  }
`;

export const PopContent = styled.div`
  a {
    color: #40a9ff;
    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    padding-left: 2rem;
  }
`;

export const PopFiles = styled.div`
  #containerFiles {
    display: grid;
    /* O elemento se alongará até haver no mínimo o espaço para conter outro do seu mesmo tamanho,
    definido no parâmetro min de minmax. fr representa uma fração do espaço disponível no container do grid*/
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    gap: 1rem;
  }

  .info-text-file {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }

  .item-file {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    padding: 1.1rem;
    position: relative;
    /*width: 215px;*/
  }

  .img-text-file {
    display: flex;
    align-items: center;
  }

  .item-file .info-text-file {
    max-width: 185px;
  }

  .item-file .info-text-file a {
    width: 100%;
  }

  .item-file .info-text-file small {
    font-size: 11px;
  }

  .item-file .info-text-file a {
    margin-bottom: 2px;
    color: #0782c1;
    font-size: 0.85rem;
  }

  .item-file .info-text-file a:hover {
    text-decoration: underline;
  }

  .item-file .button-download {
    margin-left: 0.375rem;
    color: #1890ff !important;
  }
`;
