import React, { useState, useEffect } from 'react';

import { AvatarProps, Avatar } from '@chakra-ui/react';

/* O componente faz uma simples vareficação para saber se a pessoa possui alguma imagem de perfil
,caso possua, ao invés de carregar as iniciais do nome com um fundo de cor aleatória, será carregado
 o avatar default enquanto a imagem de perfil não é carregada (fallback avatar),
 representando que a pessoa possui uma imagem de perfil, caso não possua será carregado
 as iniciais do nome como já ocorria antes.

 Dessa forma é mais fácil padronizar o fallback avatar
 também em todas os componentes se necessário. */

const AvatarCustom: React.FC<AvatarProps> = ({ src, name, ...rest }) => {
  /* Necessário para retirar a referência da imagem, se não for encontrada, voltando
  a exibir o nome nesse caso */
  const [image, setImage] = useState(src);

  useEffect(() => {
    setImage(src);
  }, [src]);

  return (
    <Avatar
      src={image}
      name={!image ? name : undefined}
      onError={() => setImage(undefined)}
      {...rest}
    />
  );
};

export default AvatarCustom;
