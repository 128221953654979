import React, { useCallback, useEffect, useState } from 'react';
import { FiCheck, FiClock } from 'react-icons/fi';

import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Progress,
  Skeleton,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { format, parseISO, setDate } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import iconHappyFace from '~/assets/happy-face.svg';
import medalImg from '~/assets/medal.svg';
import iconMediumFace from '~/assets/medium-face.svg';
import iconNormalFace from '~/assets/normal-face.svg';
import iconSadFace from '~/assets/sad-face.svg';
import iconTodayFace from '~/assets/today-face.svg';
import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import { useFetch } from '~/hooks/useFetch';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import Table from '~/shared/components/Table';
import api from '~/shared/services/api';

import { AllocationData } from '../../@types/activity';
import { StatGroupAllocation, StatTimeAllocation, StatShift } from './styles';

interface AllocationDTO extends AllocationData {
  formatted_icon: string;
  data_alocacao: string;
}

interface Props {
  employee: number | string;
}

const icons = {
  danger: iconSadFace,
  warning: iconNormalFace,
  primary: iconMediumFace,
  success: iconHappyFace,
  secundary: iconTodayFace,
};

const statusAllocationProps = {
  1: { label: 'Atrasadas', color: 'red' },
  2: { label: 'Regulares', color: 'green' },
  3: { label: 'Atencipadas', color: 'blue' },
};

const colorBackEnd = {
  danger: 'red',
  warning: 'yellow',
  primary: 'blue',
  success: 'green',
  secundary: 'cyan',
};

interface StatusAllocationData {
  order_allocation: 1 | 2 | 3;
  color: string;
  label: string;
  allocation_status: string;
  real_allocation: string;
  formattedRealAllocation: string;
}

interface ModalProps {
  total: {
    allocation_date: string;
    total_shifts: string;
    real_allocation_percent: string;
    expected_allocation_percent: string;
    total_real_allocation: string;
    total_expected_allocation: string;
    formattedDate: string;
    formattedAllocationReal: string;
    formattedAllocationExpected: string;
  };
  expected_allocation: {
    total: number;
    formattedHour: string;
    hours: string;
    percent: number;
    percentParsed: string;
  };
  daily_goal_allocation: {
    total: number;
    formattedHour?: string;
    hours: string;
    percent: number;
    percentParsed: string;
  };
  status_real_allocation: StatusAllocationData[];
  plannings: Array<{
    id: number;
    start_date_planned: string;
    start_hour_planned: string;
    end_date_planned: string;
    end_hour_planned: string;
    duration_activity: string;
    attendance_id: number;
    activity_id: number;
    demand_id: number;
    client_company_id: number;
    activity: string;
    activity_order: number;
    demand: string;
    company: string;
    real_allocation_percent: number;
    total_real_allocation: string;
    status: string;
    status_color: 'danger' | 'warning' | 'primary' | 'success' | 'secundary';
    start_attendance: string;
    end_attendance: string;
    formattedAllocationExecution: string;
    formattedDateStart: string;
    formattedDateEnd: string;
    formattedDateExecution: string;
    formattedDateEndExecution: string;
    formattedTimeDuration: string;
    formattedTimeExecution: string;
  }>;
}

const Allocation: React.FC<Props> = ({ employee }) => {
  const addToast = useToast();
  const can = useCan();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [allocationPlanning, setAllocationPlanning] = useState<ModalProps>(
    {} as ModalProps,
  );
  const [data, setData] = useState<AllocationDTO[]>([]);

  useEffect(() => {
    setLoading(true);

    api
      .get<AllocationDTO[]>(`/allocation/personal/${employee}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [employee]);

  const handleOnOpen = useCallback(
    async (item: AllocationDTO) => {
      if (item.alocacao_percent === 0) {
        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Nenhuma atividade encontrada para a data selecionada.',
          status: 'info',
        });
        return;
      }

      const date = item.data_alocacao;
      onOpen();
      setLoadingModal(true);
      try {
        const response = await api.get<ModalProps>(
          `/allocation/day/personal/${employee}?date=${date}`,
        );

        if (!response.data) {
          return;
        }

        const {
          total,
          expected_allocation,
          daily_goal_allocation,
          status_real_allocation,
          plannings,
        } = response.data;

        const realAllocationParsed = status_real_allocation?.map(
          (realAllocation: StatusAllocationData) => ({
            ...realAllocation,
            label:
              statusAllocationProps[realAllocation.order_allocation || 1].label,
            color:
              statusAllocationProps[realAllocation.order_allocation || 1].color,
            formattedRealAllocation: `${Math.floor(
              Number(realAllocation.real_allocation),
            )}%`,
          }),
        );

        const expectedAllocationParsed = {
          ...expected_allocation,
          percentParsed: `${expected_allocation.percent}%`,
          formattedHour: expected_allocation.hours,
        };

        const dailyGoalAllocationParsed = {
          ...daily_goal_allocation,
          percentParsed: `${daily_goal_allocation.percent}%`,
          formattedHour: daily_goal_allocation.hours,
        };

        const planningsParsed = plannings.map((planning) => ({
          ...planning,
          formattedDateStart:
            planning.start_date_planned && planning.start_hour_planned
              ? format(
                  parseISO(
                    `${planning.start_date_planned} ${planning.start_hour_planned}`,
                  ),
                  "dd/MM/yyyy 'às' HH:mm",
                )
              : '',
          formattedDateEnd:
            planning.end_date_planned && planning.end_hour_planned
              ? format(
                  parseISO(
                    `${planning.end_date_planned} ${planning.end_hour_planned}`,
                  ),
                  "dd/MM/yyyy 'às' HH:mm",
                )
              : '',
          formattedDateExecution: planning.start_attendance
            ? format(
                parseISO(planning.start_attendance),
                "dd/MM/yyyy 'às' HH:mm",
              )
            : '',
          formattedDateEndExecution: planning.end_attendance
            ? format(parseISO(planning.end_attendance), "dd/MM/yyyy 'às' HH:mm")
            : '',
          formattedTimeDuration: planning.duration_activity
            ? format(
                parseISO(
                  `${planning.end_date_planned} ${planning.duration_activity}`,
                ),
                "HH'h' mm'm'",
              )
            : '',
          formattedTimeExecution: planning.total_real_allocation
            ? format(
                parseISO(
                  `${planning.end_date_planned} ${planning.total_real_allocation}`,
                ),
                "HH'h' mm'm'",
              )
            : '',
          formattedAllocationExecution: `${Math.floor(
            Number(planning.real_allocation_percent),
          )}%`,
        }));

        setAllocationPlanning({
          total: {
            ...total,
            formattedAllocationExpected: `${Math.floor(
              Number(total.expected_allocation_percent),
            )}%`,
            formattedAllocationReal: `${Math.floor(
              Number(total.real_allocation_percent),
            )}%`,
            formattedDate: format(parseISO(date), "dd 'de' MMMM, yyyy", {
              locale: ptBR,
            }),
          },
          expected_allocation: expectedAllocationParsed,
          daily_goal_allocation: dailyGoalAllocationParsed,
          plannings: planningsParsed,
          status_real_allocation: realAllocationParsed,
        });

        setLoadingModal(false);
      } catch (err) {
        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Ooops!!!',
          description: 'Nenhuma atividade encontrada. Tente outra data.',
          status: 'warning',
        });

        setLoadingModal(false);
      }
    },
    [onOpen, employee, addToast],
  );

  if (!data?.length && !loading) return <span />;

  return (
    <Box
      width="full"
      borderRadius="sm"
      marginBottom="30px"
      padding="20px"
      backgroundImage="linear-gradient(45deg, #f6f7ff, #fbfbff)"
    >
      <Heading as="h3" fontSize="md" mb={3} textAlign="center">
        Alocação
      </Heading>

      {loading ? (
        <Grid
          gridTemplateColumns="repeat(auto-fit, 80px)"
          gap="10px"
          justifyContent="space-between"
        >
          <Skeleton width="80px" height="115px" />
          <Skeleton width="80px" height="115px" />
          <Skeleton width="80px" height="115px" />
          <Skeleton width="80px" height="115px" />
          <Skeleton width="80px" height="115px" />
          <Skeleton width="80px" height="115px" />
        </Grid>
      ) : (
        <Grid
          gridTemplateColumns="repeat(auto-fit, 80px)"
          gap="10px"
          justifyContent="space-between"
        >
          {data &&
            data.length > 0 &&
            data.map((item) =>
              item.alocacao_percent > 96 && !item.data_atual ? (
                <Button
                  key={`${item.data_abrev}_allocation`}
                  bg="green.50"
                  borderColor="green.500"
                  _hover={{
                    bg: 'green.100',
                  }}
                  color="white"
                  height="auto"
                  p={0}
                  title={item.descricao}
                  borderWidth="1px"
                  rounded="sm"
                  overflow="hidden"
                  display="flex"
                  flexDir="column"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => handleOnOpen(item)}
                >
                  <Image
                    src={medalImg}
                    maxW="70%"
                    title="Show"
                    mx="auto"
                    my={3}
                  />
                  <Box
                    borderRadius="0 0 3px 3px"
                    bg="green.500"
                    display="flex"
                    w="full"
                    color="gray.50"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      alignItems="center"
                      textTransform="capitalize"
                      fontWeight="500"
                    >
                      <small>{item.data_abrev}</small>
                    </Text>
                  </Box>
                </Button>
              ) : (
                <Button
                  key={`${item.data_abrev}_allocation`}
                  bg={`${colorBackEnd[item.status]}.50`}
                  borderColor={`${colorBackEnd[item.status]}.500`}
                  _hover={{
                    bg: `${colorBackEnd[item.status]}.100`,
                  }}
                  color="white"
                  height="auto"
                  p={0}
                  borderWidth="1px"
                  rounded="sm"
                  overflow="hidden"
                  display="flex"
                  flexDir="column"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => handleOnOpen(item)}
                >
                  <Box p={3}>
                    <Image
                      src={icons[item.status]}
                      width="full"
                      title="Alocação"
                    />
                  </Box>
                  <Heading
                    color={`${colorBackEnd[item.status]}.500`}
                    size="xs"
                    alignItems="center"
                  >
                    {/** {item.alocacao_percent > 96 ? ( */}
                    {item.alocacao_percent > 96 ? (
                      <FiCheck size={14} />
                    ) : (
                      <>
                        {item.data_atual ? (
                          <Progress
                            colorScheme="cyan"
                            size="xs"
                            isIndeterminate
                            width="30px"
                          />
                        ) : (
                          <Tooltip
                            hasArrow
                            aria-label={item.descricao}
                            label={item.descricao}
                            zIndex={1}
                          >
                            <Text>
                              {item.alocacao_percent}
                              <span>%</span>
                            </Text>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Heading>
                  <Box
                    borderRadius="0 0 3px 3px"
                    bg={`${colorBackEnd[item.status]}.500`}
                    display="flex"
                    w="full"
                    color="gray.100"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      alignItems="center"
                      textTransform="capitalize"
                      fontWeight="500"
                    >
                      {item.data_atual ? (
                        <small>Hoje</small>
                      ) : (
                        <small>{item.data_abrev}</small>
                      )}
                    </Text>
                  </Box>
                </Button>
              ),
            )}
        </Grid>
      )}

      <ModalChakra
        title="Atividades / Alocação"
        isOpen={false || isOpen}
        onClose={onClose}
        isLoading={loadingModal}
      >
        <StatGroupAllocation className="custom-stat-group-allocation" mb={2}>
          <Stat>
            <StatLabel>Alocação</StatLabel>
            <StatNumber>
              {loadingModal ? (
                <Skeleton height="34px" width="64px" />
              ) : (
                allocationPlanning.total?.formattedAllocationReal || '0%'
              )}
            </StatNumber>
            {loadingModal ? (
              <Skeleton
                height="21px"
                width="auto"
                minWidth="100px"
                mt={1}
                mr={1}
              />
            ) : (
              <StatHelpText
                display="flex"
                alignItems="center"
                mt={1}
                fontSize="15px"
              >
                Prevista:{' '}
                {allocationPlanning.total?.formattedAllocationExpected || '0%'}
              </StatHelpText>
            )}
          </Stat>
          <Stat className="accomplished-allocation-status" flexGrow={2}>
            <StatLabel fontSize="sm" textAlign="center">
              Classificação
            </StatLabel>
            {loadingModal ? (
              <Skeleton height="34px" width="249px" />
            ) : (
              <StatNumber>
                <HStack
                  spacing="15px"
                  justify="center"
                  id="stack_real_allocation"
                >
                  {allocationPlanning.status_real_allocation?.map(
                    (realAllocation) => {
                      return (
                        <Tooltip
                          label={realAllocation.label}
                          key={realAllocation.order_allocation}
                        >
                          <Text color={`${realAllocation.color}.400`}>
                            {realAllocation.formattedRealAllocation}
                          </Text>
                        </Tooltip>
                      );
                    },
                  )}
                </HStack>
              </StatNumber>
            )}
          </Stat>
          <StatTimeAllocation display="flex" className="time-total-allocation">
            <Box>
              <StatLabel>Execução</StatLabel>
              <StatNumber>
                {loadingModal ? (
                  <Skeleton height="34px" width="64px" />
                ) : (
                  allocationPlanning.total?.total_real_allocation
                )}
              </StatNumber>
              {loadingModal ? (
                <Skeleton height="21px" width="auto" minWidth="100px" mt={1} />
              ) : (
                <StatHelpText
                  display="flex"
                  alignItems="center"
                  mt={1}
                  fontSize="15px"
                >
                  Prevista:{' '}
                  {allocationPlanning.total?.total_expected_allocation}
                </StatHelpText>
              )}
            </Box>
          </StatTimeAllocation>
          <StatTimeAllocation display="flex" className="time-total-allocation">
            <Box>
              <StatLabel>Turno</StatLabel>
              <StatNumber>
                {loadingModal ? (
                  <Skeleton height="34px" width="64px" />
                ) : (
                  allocationPlanning.total?.total_shifts
                )}
              </StatNumber>
            </Box>
          </StatTimeAllocation>
        </StatGroupAllocation>

        {can('detail-allocation') && (
          <Box>
            <Flex alignItems="center">
              <Text fontSize="lg" textTransform="uppercase">
                Meta Diária
              </Text>
            </Flex>

            <Box mt={2} pl={2}>
              {loadingModal ? (
                <>
                  <Flex>
                    <Text mr={1}>Planejamento: </Text>
                    <Skeleton height="27px" width="50px" />
                  </Flex>
                  <Flex alignItems="center">
                    <Skeleton w="full" h="12px" mt={1} />
                    <Skeleton h="24px" ml={2} minWidth="60px" />
                  </Flex>
                  <Flex>
                    <Text mr={1}>Execução: </Text>
                    <Skeleton height="27px" width="50px" />
                  </Flex>
                  <Flex alignItems="center">
                    <Skeleton w="full" h="12px" mt={1} />
                    <Skeleton h="24px" ml={2} minWidth="60px" />
                  </Flex>
                </>
              ) : (
                <>
                  <Text>
                    Planejamento:{' '}
                    {allocationPlanning.expected_allocation?.formattedHour || 0}
                  </Text>
                  <Flex alignItems="center">
                    <Progress
                      borderRadius="3px"
                      w="full"
                      value={allocationPlanning.expected_allocation?.percent}
                      max={100}
                    />
                    <Text ml={2} minWidth="60px">
                      {allocationPlanning.expected_allocation?.percentParsed}
                    </Text>
                  </Flex>
                  <Text mt={1}>
                    Execução:{' '}
                    {allocationPlanning.daily_goal_allocation?.formattedHour ||
                      0}
                  </Text>
                  <Flex alignItems="center">
                    <Progress
                      borderRadius="3px"
                      w="full"
                      colorScheme="green"
                      value={allocationPlanning.daily_goal_allocation?.percent}
                      max={100}
                    />
                    <Text ml={2} minWidth="60px">
                      {allocationPlanning.daily_goal_allocation?.percentParsed}
                    </Text>
                  </Flex>
                </>
              )}
            </Box>
          </Box>
        )}

        {loadingModal ? (
          <Skeleton height="21px" width="117px" my={3} />
        ) : (
          <Text my={3} fontSize="sm">
            {allocationPlanning.total?.formattedDate}
          </Text>
        )}

        {loadingModal ? (
          <>
            <Skeleton height="34px" width="auto" mb={1} />
            <Skeleton height="34px" width="auto" mb={1} />
            <Skeleton height="34px" width="auto" mb={1} />
            <Skeleton height="34px" width="auto" mb={1} />
            <Skeleton height="34px" width="auto" mb={1} />
            <Skeleton height="34px" width="auto" mb={1} />
            <Skeleton height="34px" width="auto" mb={1} />
          </>
        ) : (
          <>
            <Table
              theadData={[
                {
                  title: 'P/Previsto',
                },
                {
                  title: 'P/Realizado',
                },
                {
                  title: 'Alocação',
                },
                {
                  title: 'Demanda/Atividade',
                },
                {
                  title: 'Empresa',
                },
                {
                  title: 'Situação',
                },
              ]}
            >
              {allocationPlanning?.plannings?.map((planning) => {
                return (
                  <Box as="tr" whiteSpace="nowrap" key={`${planning.id}-modal`}>
                    <Box as="td" p={2}>
                      <Tooltip
                        label={`${planning.formattedDateStart} | ${planning.formattedDateEnd}`}
                        aria-label="A tooltip"
                      >
                        <Button size="sm" leftIcon={<FiClock />}>
                          {planning.formattedTimeDuration}
                        </Button>
                      </Tooltip>
                    </Box>
                    <Box as="td" p={2}>
                      <Tooltip
                        label={`${planning.formattedDateExecution} | ${planning.formattedDateEndExecution}`}
                        aria-label="A tooltip"
                      >
                        <Button size="sm" leftIcon={<FiClock />}>
                          {planning.formattedTimeExecution}
                        </Button>
                      </Tooltip>
                    </Box>
                    <Box as="td" p={2}>
                      {planning.formattedAllocationExecution}
                    </Box>
                    <Box as="td" p={2}>
                      <Text fontSize="sm">{planning.demand}</Text>
                      <br />
                      {planning.activity}
                    </Box>
                    <Box as="td" p={2}>
                      {planning.company}
                    </Box>
                    <Box as="td" p={2}>
                      <Tag
                        colorScheme={`${colorBackEnd[planning.status_color]}`}
                      >
                        {planning.status}
                      </Tag>
                    </Box>
                  </Box>
                );
              })}
            </Table>
          </>
        )}
      </ModalChakra>
    </Box>
  );
};

export default Allocation;
