import React from 'react';

import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';

interface Props {
  title: string;
}

const ChartBoxSkeleton: React.FC<Props> = ({ title }) => {
  return (
    <Box
      height={['390px', '515px', '515px', '440px', '515px']}
      width={['500px']}
      bg="#eef5ff"
    >
      <Flex flexDir="column" justifyContent="center" alignItems="center">
        <Flex
          bg="#f7fafc"
          height="35px"
          width={['200px', '250px']}
          p={4}
          mt={5}
          mb={4}
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
        >
          <Text color="gray.500" bg="#f7fafc" textAlign="center">
            {title}
          </Text>
        </Flex>
        <Flex position="relative" justifyContent="center" alignItems="center">
          <Skeleton
            color="white"
            width={['200px', '350px', '350px', '250px', '350px']}
            height={['200px', '350px', '350px', '250px', '350px']}
            rounded="100%"
          />
          <Box
            bgColor="#eef5ff"
            position="absolute"
            width={['105px', '180px', '180px', '140px', '180px']}
            height={['105px', '180px', '180px', '140px', '180px']}
            rounded="100%"
          />
        </Flex>

        <Skeleton width={['150px', '230px']} height={['16px', '20px']} mt={3} />
        <Skeleton width={['100px', '230px']} height={['16px', '20px']} mt={3} />
      </Flex>
    </Box>
  );
};

export default ChartBoxSkeleton;
