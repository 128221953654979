/* eslint-disable react/no-children-prop */
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiFile } from 'react-icons/fi';

import {
  Icon as IconChakra,
  FormControl,
  FormErrorMessage,
  Heading,
  Tooltip,
  Flex,
  FormLabel,
  Switch,
  Stack,
  Avatar,
  Box,
  Text,
  Icon,
  InputLeftElement,
} from '@chakra-ui/react';
import { useField } from '@unform/core';

import AvatarCustom from '../../AvatarCustom';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  options: {
    id: string | number;
    value: string | number;
    label?: string;
    hasAvatar?: boolean;
    avatar?: string;
    variantColor?: string;
    isDisabled?: boolean;
  }[];
  sizeCustom?: 'sm' | 'md' | 'lg' | undefined;
  containerStyle?: object;
  help?: string;
  zIndex?: number;
  justifyContent?: string;
  isReversed?: boolean;
  mb?: number | string;
  icon?: React.ComponentType<IconBaseProps>;
}

const SwitchChakra: React.FC<InputProps> = ({
  name,
  label,
  options,
  sizeCustom,
  containerStyle,
  help,
  zIndex,
  justifyContent,
  isReversed,
  mb,
  icon,
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [], error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <FormControl mb={mb || 4} style={containerStyle} isInvalid={!!error}>
      <Flex alignItems="center" fontWeight="medium">
        <Heading
          as="h5"
          size="sm"
          mb={1}
          fontWeight={500}
          cursor="default"
          color="gray.500"
        >
          {label}
        </Heading>

        {help && (
          <Tooltip aria-label={help} label={help} zIndex={zIndex}>
            <IconChakra name="info-outline" ml="auto" color="gray.500" />
          </Tooltip>
        )}
      </Flex>

      <Stack spacing={1}>
        {options.map((option, index) => (
          <Stack isInline key={option.id} justifyContent={justifyContent}>
            <Flex alignItems="center" order={isReversed ? 2 : 1}>
              {option.hasAvatar && (
                <AvatarCustom
                  src={option.avatar}
                  name={option.label}
                  size="xs"
                />
              )}
              <FormLabel
                htmlFor={`switchId-000${option.id}`}
                fontSize="md"
                fontWeight={400}
                pb={0}
                justifyContent="center"
              >
                <Flex ml={isReversed ? 3 : 0} alignItems="center">
                  {icon && <Box children={<Box as={icon} size="20px" />} />}
                  <Text ml={isReversed ? 2 : 0} userSelect="none">
                    {option.label}
                  </Text>
                </Flex>
              </FormLabel>
            </Flex>
            <Flex order={isReversed ? 1 : 2}>
              <Switch
                id={`switchId-000${option.id}`}
                value={option.value}
                defaultIsChecked={defaultValue.find(
                  (dv: string) => dv === option.id,
                )}
                ref={(ref: HTMLInputElement) => {
                  inputRefs.current[index] = ref as HTMLInputElement;
                }}
                isDisabled={option.isDisabled}
                color={option?.variantColor || 'blue'}
                size={sizeCustom || 'md'}
              />
            </Flex>
          </Stack>
        ))}
      </Stack>

      {error && <FormErrorMessage mb={2}>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default SwitchChakra;
