import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { FaOldRepublic } from 'react-icons/fa';
import { FiFolder, FiCheck } from 'react-icons/fi';

import {
  Box,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tooltip,
  Grid,
  useToast,
} from '@chakra-ui/react';

import Loading from '~/shared/components/Loading';
import api from '~/shared/services/api';

import NavigationModal from './NavigationModal';
import NavigationPage from './NavigationPage';

export interface ModalFolders {
  name: string;
  type: string;
  path: string;
  filename: string;
  timestamp: number;
  dirname: string;
  basename: string;
  isSelected?: boolean;
}

interface InfoDir {
  basename: string;
  name: string;
}

interface DirContents {
  info_dir?: InfoDir;
  contents: ModalFolders[];
}

export interface BreadcrumbFolder {
  id: string;
  name: string;
  isActiveDir?: boolean;
  index?: number;
  return?: boolean;
}

export interface FolderWithCrumbs {
  folder: BreadcrumbFolder | null;
  breadCrumbFolders: BreadcrumbFolder[];
  activeDir?: BreadcrumbFolder | null;
  isActiveDir?: boolean;
}

export interface ClientCompany {
  id: number;
  name: string;
}

interface Props {
  clientCompany: ClientCompany;
  navigationMode?: 'modal' | 'page';
  // Obrigatório para navegação no modal
  onCloseModal?(): void;

  /* É preciso dos seguintes métodos para manter a referência de crumbers e folders entre os componenetes pai e filho,
  pq o modal é renderizado toda vez que é aberto */
  previousSelectedFolder: FolderWithCrumbs | null;
  handleSelectedFolder(folder: FolderWithCrumbs | null): void;
}

const NavigationFoldersDrive: React.FC<Props> = ({
  clientCompany,
  previousSelectedFolder,
  handleSelectedFolder,
  navigationMode = 'page',
  onCloseModal,
}): any => {
  const [modalFolders, setModalFolders] = useState<ModalFolders[]>([]);
  const [breadcrumbFolders, setBreadcrumbFolders] = useState<
    BreadcrumbFolder[]
  >(previousSelectedFolder?.breadCrumbFolders || []);

  const [loading, setLoading] = useState(false);
  const [slideAnimation, setSlideAnimation] = useState('');
  const [activeDir, setActiveDir] = useState<BreadcrumbFolder | null>(
    previousSelectedFolder?.activeDir || null,
  );
  const [selectedFolder, setSelectedFolder] = useState<BreadcrumbFolder | null>(
    previousSelectedFolder?.folder || null,
  );
  const [infoRootDir, setInfoRootDir] = useState<InfoDir>();

  /* Necessário para não setar na página como o folder selecionado antes do usuário clicar no botão selecionar
   e para não recarregar o useEffect, como ocorria utilizando activeDir */

  const [
    preSelectedFolder,
    setPreSelectedFolder,
  ] = useState<BreadcrumbFolder | null>(previousSelectedFolder?.folder || null);

  const addToast = useToast();

  /* Verificar selected da condição do useEffect, quando selecionar a pasta não deve realizar a consulta novamente */
  /* Verificar se é possível criar outro estado para navigationPage, pois o selected não pode realizar uma nova consulta,
  se a navegação esta sendo por meio do modal, já que para essa ação utilizamos o state activeDir */
  useEffect(() => {
    setLoading(true);
    setSlideAnimation('hide');

    /* importante passar valores esperados como booleans no backend, como número,
      pois ao enviar por meio do get, tudo se torna string
     */

    /* Ao utilizar o objeto clientCompany no array de dependência por algum motivo este
      useEffect entra em loop, para que isos não ocorra passamos apenas o atributo id para verificar */
    api
      .get<DirContents>('cloud/navigate', {
        params: {
          dir: activeDir?.id,
          client_company: clientCompany.id,
          include_files: 1,
          recursive: 0,
        },
      })
      .then((response) => {
        /* Para a comparação é melhor utilizar previousSelectedFolder, pois no array de dependência, ele
        não altera o seu valor, apenas quando o componente é renderizado, o que não aconteceria com
        o state selectedFolder */
        // Dessa forma evitamos fazer o map para todas os diretórios abertos

        const { contents, info_dir } = response.data;

        const folders =
          previousSelectedFolder &&
          activeDir?.id === previousSelectedFolder.activeDir?.id
            ? contents.map((folder) => {
                return {
                  ...folder,
                  isSelected:
                    folder.basename === previousSelectedFolder?.folder?.id,
                };
              })
            : contents;

        setModalFolders(folders);

        if (info_dir) {
          setPreSelectedFolder({ id: info_dir.basename, name: info_dir.name });
          setInfoRootDir(info_dir);
        }
      })
      .catch((err) => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            err.response.data?.error ||
            'Não foi possível encontrar as pastas desta empresa',
        });
      })
      .finally(() => {
        setSlideAnimation('show');
        setLoading(false);
      });
  }, [
    addToast,
    clientCompany,
    activeDir,
    navigationMode,
    previousSelectedFolder,
  ]);

  const handleActiveDir = useCallback(
    (folder?: BreadcrumbFolder) => {
      if (!folder) {
        setBreadcrumbFolders([]);
        setActiveDir(null);
        setPreSelectedFolder(null);

        return;
      }

      if (!folder.return) {
        // Setamos o crumb que está selecionado antes de setar as informações do novo folder selecionado
        // Adicionamos o diretório anterior (selectedFolder a lista ja existente)
        // Se não tiver um active folder não será preciso setar algo no crumb, ele permanece com o mesmo valor
        if (activeDir)
          setBreadcrumbFolders(breadcrumbFolders.concat(activeDir));
      } else {
        setBreadcrumbFolders((state) =>
          state.filter(
            (crumb, i) => folder.index !== undefined && i < folder.index,
          ),
        );
      }

      setPreSelectedFolder({ id: folder.id, name: folder.name });

      setActiveDir(folder);
    },
    [activeDir, breadcrumbFolders],
  );

  const handleSelectFolder = useCallback(
    (folder?: BreadcrumbFolder) => {
      if (!folder) {
        setBreadcrumbFolders([]);

        setSelectedFolder(null);

        handleSelectedFolder(null);

        return;
      }

      let updatedCrumbs: BreadcrumbFolder[] = [];

      updatedCrumbs = !folder.return
        ? breadcrumbFolders.concat({
            id: folder.id,
            name: folder.name,
          })
        : breadcrumbFolders.filter(
            (crumb, i) => folder.index !== undefined && i <= folder.index,
          );

      setBreadcrumbFolders(updatedCrumbs);
      // Ao atualizar o selected folder o useEffect será chamado
      setSelectedFolder(folder);

      /* breadCrumbFolders corresponde ao elemento que está renderizado em tela, mesmo se o método para setar um novo valor
        a este estado fosse chamado o comportamento seria o mesmo, atualizando o valor de breadCrumbFolders apenas na nova
        renderização */

      handleSelectedFolder({
        folder,
        breadCrumbFolders: updatedCrumbs,
      });
    },
    [handleSelectedFolder, breadcrumbFolders],
  );

  function handleSelectFolderInList(basename: string, name: string): void {
    setModalFolders((state) =>
      state.map((folder) => {
        return { ...folder, isSelected: folder.basename === basename };
      }),
    );

    setPreSelectedFolder({ id: basename, name });
  }

  function handleCleanSelectFolderInList(): void {
    setModalFolders((state) =>
      state.map((folder) => {
        return { ...folder, isSelected: false };
      }),
    );

    if (activeDir) {
      setPreSelectedFolder(activeDir);
    } else {
      setPreSelectedFolder(
        infoRootDir
          ? {
              id: infoRootDir?.basename,
              name: infoRootDir?.name,
            }
          : null,
      );
    }
  }

  return (
    <>
      {navigationMode === 'modal' ? (
        onCloseModal && (
          <NavigationModal
            folders={modalFolders}
            breadCrumbs={breadcrumbFolders}
            activeDir={activeDir}
            isLoading={loading}
            clientCompany={{
              id: clientCompany.id,
              name: clientCompany.name,
            }}
            setActiveDir={(active) => handleActiveDir(active)}
            /* o active dir no caso de ser o diretório
            raiz, não existe, quando abre o modal por exemplo. Porém o isActiveDir
            se refere ao diretório aberto no momento, podendo também ser o diretório raiz. A principal
            verificação é se o folder selecionado se trata de uma pasta aberta, ou uma das pastas na lista */
            setSelectedFolder={(isActiveDir: boolean) =>
              handleSelectedFolder({
                folder: preSelectedFolder,
                breadCrumbFolders: preSelectedFolder ? breadcrumbFolders : [],
                activeDir,
                isActiveDir,
              })
            }
            setNewModalFolder={(modalFolder: ModalFolders) =>
              setModalFolders((state) => [{ ...modalFolder }, ...state])
            }
            selectFolderInList={(basename: string, name: string) =>
              handleSelectFolderInList(basename, name)
            }
            onCloseModal={onCloseModal}
            animation={slideAnimation}
            hasSelectedFolderInList={
              !!previousSelectedFolder && !previousSelectedFolder.isActiveDir
            }
            // Utilizamos essa sintaxe de arrow function quando a função n tem retorno
            cleanSelected={() => handleCleanSelectFolderInList()}
          />
        )
      ) : (
        <NavigationPage
          folders={modalFolders}
          breadCrumbs={breadcrumbFolders}
          selectedFolder={selectedFolder}
          setSelectedFolder={(selected) => handleSelectFolder(selected)}
          isLoading={loading}
        />
      )}
    </>
  );
};

export default NavigationFoldersDrive;
