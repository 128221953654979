import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  FiCodepen,
  FiBox,
  FiEdit,
  FiLock,
  FiMail,
  FiPlus,
  FiShield,
  FiTrash,
  FiAperture,
  FiCodesandbox,
  FiHelpCircle,
} from 'react-icons/fi';

import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  Icon,
  IconButton,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Alert,
  AlertIcon,
  Tooltip,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { Step } from 'intro.js';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import AlertDialog from '~/shared/components/AlertDialog';
import EmptyPage from '~/shared/components/EmptyPage';
import Input from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import { IPaginationProps } from '~/shared/components/Pagination';
import Search from '~/shared/components/Search';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';
import { cnpjMask } from '~/utils/mask';

import { Company } from '../../@types/management';
import { Container, BoxCompany } from './styles';

interface CompanyClientForm {
  id: number;
  cnpj?: string;
  company_name: string;
  name: string;
}

interface CompanyClientProps extends IPaginationProps {
  data: Company[];
}

const CompanyClient: React.FC = () => {
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formRef = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);

  const cancelDeleteRef = useRef(null);
  const btnCreateRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const [
    selectedCompanyEdit,
    setSelectedCompanyEdit,
  ] = useState<CompanyClientForm>({} as CompanyClientForm);

  const [selectCompanyDelete, setSelectCompanyDelete] = useState(0);

  const [modalSuccess, setModalSuccess] = useState(0);
  const [page, setPage] = useState(1);

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');

  const [companies, setCompanies] = useState<CompanyClientProps>(
    {} as CompanyClientProps,
  );

  const [enableTourCompany, setEnableTourCompany] = useState(false);
  const [stepsCompany, setStepsCompany] = useState<Step[]>([
    {
      element: '#container',
      title: 'Controle de Empresas',
      intro:
        'Nessa página você acompanha e administra todos as empresas cliente presentes no seu sistema',
    },
    {
      element: '#createCompany',
      title: 'Cadastrar Empresa.',
      intro:
        'Aqui você pode criar as empresas de seus clientes. clique no botão',
      position: 'left',
    },
  ]);

  /* const [steps, setSteps] = useState(1); */

  const addToast = useToast();

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      /*  {
        title: 'Id',
      }, */
      {
        title: 'CÓD. Alterdata',
        align: 'left',
      },
      {
        title: 'Razão Social',
      },
      {
        title: 'Nome Fantasia',
      },
      {
        title: 'CNPJ',
      },
      {
        title: 'Criado em',
      },
      {
        title: 'Atualizado em',
      },
      {
        title: 'Ações',
      },
    ];
  }, []);

  const getCompanies = useCallback(
    async (numPage: number, searchComp?: string): Promise<() => void> => {
      if (searchComp) {
        setLoadingSearch(true);
      }
      setLoaded(false);

      const { CancelToken } = axios;
      const source = CancelToken.source();

      api
        .get<CompanyClientProps>('companies', {
          params: { page: numPage, search: searchComp },
          cancelToken: source.token,
        })
        .then((response) => {
          const compPage = response.data;
          // console.log(compPage);
          setCompanies({
            ...compPage,
            data: compPage.data.map((comp) => {
              return {
                ...comp,
                cnpj: comp.cnpj ? cnpjMask(comp.cnpj) : comp.cnpj,
                created_at: format(
                  parseISO(comp.created_at),
                  "dd/MM/yyyy 'às' HH:mm",
                ),
                updated_at: format(
                  parseISO(comp.updated_at),
                  "dd/MM/yyyy 'às' HH:mm",
                ),
              };
            }),
            /* console.log(response.data); */
          });
          setPage(compPage.current_page);
          setSearch(searchComp || '');
        })
        .finally(() => {
          setLoadingSearch(false);
          setLoaded(true);
        });
      return () => {
        /* Após chamar novamente a api, sem que se tenha resposta da primeira solicitação (pendente)
          o pedido anterior é cancelado e se realiza um novo pedido */
        source.cancel('Operação cancelada pelo usuário');
      };
    },
    [],
  );

  useEffect(() => {
    getCompanies(1);
  }, [getCompanies]);

  const handleEditComp = useCallback(
    (data: CompanyClientForm) => {
      setSelectedCompanyEdit(data);
      onOpenEdit();
    },
    [onOpenEdit],
  );

  const handleEditCompanyForm = useCallback(
    async (data: CompanyClientForm) => {
      setLoaded(false);
      setLoadingEdit(true);
      try {
        formRef.current?.setErrors({});
        // console.log(data);

        // Volta para 14 dígitos, retirando caracteres especiais
        data.cnpj = data?.cnpj ? data.cnpj.replace(/\D+/g, '') : undefined;

        const validation = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          company_name: Yup.string().required('Campo obrigatório'),
          cnpj: Yup.string()
            .nullable()
            .length(14, 'Não contém a quantidade de números exata'),
        });
        await validation.validate(data, {
          abortEarly: false,
        });

        const response = await api.put<Company>(
          `companies/${selectedCompanyEdit.id}`,
          data,
        );

        const { data: newComp } = response;

        // console.log(newComp);

        setCompanies((state) => {
          return {
            ...state,

            data: [
              ...state.data.map((dataComp) => {
                return dataComp.id === selectedCompanyEdit.id
                  ? {
                      ...dataComp,
                      name: newComp.name,
                      id_alterdata: newComp.id_alterdata,
                      company_name: newComp.company_name,
                      cnpj: newComp.cnpj
                        ? cnpjMask(newComp.cnpj)
                        : newComp.cnpj,
                      updated_at: format(
                        parseISO(newComp.updated_at),
                        "dd/MM/yyyy 'às' HH:mm",
                      ),
                    }
                  : dataComp;
              }),
            ],
          };
        });

        formRef.current?.reset();

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Empresa atualizado!',
          description: 'A empresa foi atualizada com sucesso.',
          isClosable: true,
        });

        /* setLoadReload((state) => state + 1); */

        onCloseEdit();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current?.setErrors(errors);
          return;
        }
        const { error } = err.response.data;

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            error || 'Ocorreu um erro ao atualizar a empresa, tente novamente.',
          isClosable: true,
        });
      } finally {
        setLoaded(true);
        setLoadingEdit(false);
      }
    },
    [addToast, onCloseEdit, selectedCompanyEdit],
  );

  const handleOpenModalDelete = useCallback(
    (id: number) => {
      setSelectCompanyDelete(id);
      onOpenDelete();
    },
    [onOpenDelete],
  );

  const handleSubmitDelete = useCallback(() => {
    if (companies) {
      onCloseDelete();
    }

    api
      .delete(`companies/${selectCompanyDelete}`)
      .then(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Empresa deletada!',
          description: 'Empresa deletada com sucesso!',
        });

        const filteredCompany = companies.data.filter((comp) => {
          return comp.id !== selectCompanyDelete;
        });

        if (!filteredCompany.length) {
          getCompanies(page - 1, search);
        } else {
          setCompanies((state) => {
            return {
              ...state,
              data: filteredCompany,
              total: state.total - 1,
              to: state.to - 1,
            };
          });
        }
      })
      .catch((err) => {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          duration: 5000,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Empresa não deletada!',
          description: err.response.data?.error || 'Erro ao deletar empresa',
        });
      });
  }, [
    selectCompanyDelete,
    addToast,
    onCloseDelete,
    getCompanies,
    search,
    page,
    companies,
  ]);

  const handleCreateCompany = useCallback(
    async (data: CompanyClientForm) => {
      setLoaded(false);

      // Volta para 14 dígitos, retirando caracteres especiais
      data.cnpj = data?.cnpj ? data.cnpj.replace(/\D+/g, '') : undefined;

      try {
        formRef.current?.setErrors({});

        const validation = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          company_name: Yup.string().required('Campo obrigatório'),
          cnpj: Yup.string()
            .nullable()
            .length(14, 'Não contém a quantidade de números exata'),
        });

        await validation.validate(data, {
          abortEarly: false,
        });

        const response = await api.post<Company>('companies', data);

        const { data: newComp } = response;

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Empresa cadastrada!',
          description: 'A Empresa foi cadastrada com sucesso.',
          isClosable: true,
        });

        formRef.current?.reset();

        /* setLoadReload((state) => state + 1); */
        setCompanies((state) => {
          return {
            ...state,
            total: state.total + 1,
            to: state.to + 1,

            data: [
              ...state.data,
              {
                ...newComp,
                cnpj: newComp.cnpj ? cnpjMask(newComp.cnpj) : newComp.cnpj,
                created_at: format(
                  parseISO(newComp.created_at),
                  "dd/MM/yyyy 'às' HH:mm",
                ),
                updated_at: format(
                  parseISO(newComp.updated_at),
                  "dd/MM/yyyy 'às' HH:mm",
                ),
              },
            ],
          };
        });
        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro no Cadastro',
          description:
            err.response.data?.error ||
            'Ocorreu um erro ao cadastrar a empresa',
          isClosable: true,
        });
      } finally {
        setLoaded(true);
      }
    },
    [addToast, onClose],
  );

  return (
    <Box id="container" pos="relative">
      <Container>
        <SectionHeader title="Controle de Empresas" pagename="Empresas Cliente">
          <Flex>
            <Button
              aria-label="help"
              onClick={() => {
                setEnableTourCompany(true);
              }}
              variant="outline"
              colorScheme="blue"
              mr={3}
            >
              <FiHelpCircle />
              <Text ml={2}>Ajuda</Text>
            </Button>
            <Button
              id="createCompany"
              colorScheme="blue"
              isLoading={!loaded}
              isDisabled={!loaded}
              onClick={onOpen}
              ref={btnCreateRef}
            >
              <FiPlus />
              Cadastrar Empresa
            </Button>
          </Flex>
        </SectionHeader>
        <Box mb={4}>
          <Search
            loading={loadingSearch}
            results={companies.total}
            searchValue={(value) => getCompanies(1, value)}
            searchClear={() => getCompanies(1, '')}
            tooltipPlacement="top-start"
          />
        </Box>
      </Container>
      {loaded && (!companies || !companies.data?.length) ? (
        <Alert status="info" variant="left-accent">
          <AlertIcon />
          Nenhuma empresa foi encontrada, limpe o filtro e tente novamente
        </Alert>
      ) : (
        <Table
          loading={!loaded}
          theadData={tableTitles}
          pagination={{
            current_page: companies.current_page,
            last_page: companies.last_page,
            per_page: companies.per_page,
            to: companies.to,
            total: companies.total,
          }}
          newPage={(pg) => getCompanies(pg, search)}
        >
          {loaded &&
            companies.data.map((comp) => (
              <Box as="tr" key={String(comp.id)} _hover={{ bg: 'gray.50' }}>
                {/* <Box as="td" py={1} textAlign="center">
                  {comp.id}
                </Box> */}
                <Box as="td" py={1} textAlign="center" fontWeight="bold">
                  {comp?.id_alterdata || '-'}
                </Box>
                <Box as="td" py={1}>
                  <Tooltip label={comp.name} aria-label="Razão social">
                    <BoxCompany>{comp.name}</BoxCompany>
                  </Tooltip>
                </Box>
                <Box as="td" py={1}>
                  <Tooltip label={comp.company_name} aria-label="Nome Fantasia">
                    <BoxCompany>{comp.company_name}</BoxCompany>
                  </Tooltip>
                </Box>
                <Box as="td" py={1}>
                  {comp.cnpj}
                </Box>
                <Box as="td" py={1}>
                  {comp.created_at}
                </Box>
                <Box as="td" py={1}>
                  {comp.updated_at}
                </Box>
                <Box as="td" py={1} className="row">
                  <Flex justifyContent="flex-end">
                    <IconButton
                      aria-label="Editar"
                      icon={<FiEdit />}
                      variant="ghost"
                      bg="yellow.50"
                      ml={[0, 2]}
                      colorScheme="yellow"
                      onClick={() => handleEditComp(comp)}
                      textAlign="center"
                    />
                    <IconButton
                      aria-label="Deletar"
                      icon={<FiTrash />}
                      variant="ghost"
                      bg="red.50"
                      ml={[0, 2]}
                      colorScheme="red"
                      onClick={() => handleOpenModalDelete(comp.id)}
                      textAlign="center"
                    />
                  </Flex>
                </Box>
              </Box>
            ))}
        </Table>
      )}

      <ModalChakra
        title="Atualizar Empresa"
        onClose={onCloseEdit}
        isOpen={isOpenEdit}
        onSubmit={() => formRefEdit.current?.submitForm()}
        isLoading={loadingEdit}
      >
        <Form
          initialData={selectedCompanyEdit}
          ref={formRefEdit}
          onSubmit={handleEditCompanyForm}
        >
          <Input
            icon={FiCodesandbox}
            label="Cod. Alterdata"
            name="id_alterdata"
            type="text"
            placeholder="Codigo Alterdata"
            mask="alterdata"
            maxLength={5}
          />
          <Input
            icon={FiAperture}
            label="Razão Social"
            name="name"
            type="text"
            placeholder="Razão Social"
            autoFocus
            toUpperCase
          />
          <Input
            icon={FiCodepen}
            label="Nome Fantasia"
            name="company_name"
            type="text"
            placeholder="Nome Fantasia"
            toUpperCase
          />
          <Input
            icon={FiBox}
            label="CNPJ"
            name="cnpj"
            type="text"
            placeholder="CNPJ"
            mask="cnpj"
            /* maxLength={18} */
          />
        </Form>
      </ModalChakra>
      <AlertDialog
        title="Deletar Empresa?"
        description="Tem certeza que deseja deletar a empresa selecionada"
        isOpen={isOpenDelete}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDelete}
        onSubmit={handleSubmitDelete}
      />

      <Drawer
        preserveScrollBarGap
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnCreateRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Cadastrando Empresa
          </DrawerHeader>

          <DrawerBody overflowY="auto">
            <Box minH={200}>
              <Form ref={formRef} onSubmit={handleCreateCompany}>
                <Stack>
                  <Input
                    icon={FiCodesandbox}
                    label="Cod. Alterdata"
                    name="id_alterdata"
                    type="text"
                    placeholder="Codigo Alterdata"
                    mask="alterdata"
                    maxLength={5}
                  />
                  <Input
                    icon={FiAperture}
                    label="Razão Social"
                    name="name"
                    type="text"
                    placeholder="Razão Social"
                    autoFocus
                    toUpperCase
                  />
                  <Input
                    icon={FiCodepen}
                    label="Nome Fantasia"
                    name="company_name"
                    type="text"
                    placeholder="Nome Fantasia"
                    toUpperCase
                  />
                  <Input
                    icon={FiBox}
                    label="CNPJ"
                    name="cnpj"
                    type="text"
                    placeholder="CNPJ"
                    mask="cnpj"
                  />
                </Stack>
              </Form>
            </Box>
          </DrawerBody>

          <DrawerFooter justifyContent="flex-end">
            {loaded && (
              <Button
                variant="outline"
                mr={3}
                onClick={() => onClose()}
                size="lg"
              >
                Fechar
              </Button>
            )}

            <Button
              variant="solid"
              colorScheme="blue"
              isLoading={!loaded}
              isDisabled={!loaded}
              size="lg"
              type="submit"
              ml="auto"
              onClick={() => formRef.current?.submitForm()}
            >
              Finalizar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <StepsIntro
        enabled={enableTourCompany}
        onExit={() => setEnableTourCompany(false)}
        steps={stepsCompany}
      />
    </Box>
  );
};

export default CompanyClient;
