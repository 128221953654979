import styled from 'styled-components';

interface TimeOverProps {
  timerOver?: boolean;
}

export const Container = styled.div<TimeOverProps>`
  flex: 1;
  min-width: 250px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
  }
`;

export const Timer = styled.div<TimeOverProps>`
  position: relative;
  width: 230px;
  height: 230px;
  border-radius: 100%;
  background: #2f2e38;
  border: 2px solid ${(props) => (props.timerOver ? '#E24A4A' : '#1fbd2f')};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  small {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #8694a0;
  }

  strong {
    font-size: 40px;
    line-height: 47px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${(props) => (props.timerOver ? '#E24A4A' : '#1fbd2f')};
    font-weight: 500;
  }

  @media (max-width: 1100px) {
    width: 180px;
    height: 180px;

    strong {
      font-size: 30px;
    }
  }
`;

export const TimerCircle = styled.svg<TimeOverProps>`
  position: absolute;
  top: -2px;
  left: -2px;
  width: 230px;
  height: 230px;
  border-radius: 100%;
  background: transparent;
  border: 10px solid ${(props) => (props.timerOver ? '#E24A4A' : '#1fbd2f')};

  @media (max-width: 1100px) {
    width: 180px;
    height: 180px;
  }
`;

export const ReminderNote = styled.div`
  margin-bottom: 10px;
  max-width: 290px;
  background: rgb(247 250 252 / 80%);
  border-radius: 5px;
  padding: 0.8rem;
  color: rgb(43 108 176 / 80%);

  > svg {
    margin: 0 auto;
  }

  > p {
    font-weight: 500;
    font-family: Roboto;
    font-size: 12px;
    text-align: center;
  }
`;
