import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  FiHelpCircle,
  FiPlus,
  FiUser,
  FiMail,
  FiXCircle,
  FiSettings,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Flex,
  Button,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  useDisclosure,
  Grid,
  IconButton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import { Step } from 'intro.js';

import { Subscriptions } from '~/modules/auth/pages/SignIn';
import Input from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import SectionHeader from '~/shared/components/SectionHeader';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';

import { Company } from '../../@types/management';

interface SubscriptionStatus {
  id: number;
  name: string;
  barte_value: string;
  created_at: string;
  updated_at: string;
}

interface SubscriptionPeriod {
  id: number;
  period: string;
  discount_rate: string;
  created_at: string;
  updated_at: string;
}

export interface Signature extends Subscriptions {
  company: Company;
  subscription_status: SubscriptionStatus;
  subscription_period: SubscriptionPeriod;
}

const Signature: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const btnRef = useRef(null);

  const [subscription, setSubscription] = useState<Signature[]>();
  const [loading, setLoading] = useState(true);
  const [enableTourSignature, setEnableTourSignature] = useState(false);
  const [stepsSignature, setStepsSignature] = useState<Step[]>([
    {
      element: '#container',
      title: 'Controle de Assinaturas',
      intro:
        'Nessa página você acompanha suas assinaturas juntamente com seus dados.',
    },
    {
      element: '#createSignature',
      title: 'Adicionar licenças ou Criar assinatura.',
      intro:
        'Aqui você pode criar uma nova assinatura ou adicionar novas licenças. Botão em manutenção.',
      position: 'left',
    },
    {
      element: '#configLicense',
      title: 'Visualizar licenças',
      intro: 'Aqui você pode visualizar e criar licenças. clique no botão',
    },
  ]);

  useEffect(() => {
    setLoading(true);
    api
      .get<Signature[]>('subscriptions')
      .then((response) => {
        const { data } = response;
        setSubscription(
          data.map((dt) => {
            return {
              ...dt,
              start_date: dt.start_date
                ? format(parseISO(dt.start_date), 'dd/MM/yyyy')
                : '',
              end_date: dt.end_date
                ? format(parseISO(dt.end_date), 'dd/MM/yyyy')
                : '',
            };
          }),
        );
      })
      .finally(() => setLoading(false));
  }, []);

  /* const handleSubmit = useCallback(() => {
    console.log('submit');
  }, []); */

  const handleSignatureLicense = useCallback(
    (idSignature: number) => {
      history.push(`/assinaturas/${idSignature}/usuarios`);
    },
    [history],
  );

  const handleSignatureCreate = useCallback(() => {
    history.push(`/assinaturas/cadastrar`);
  }, [history]);
  return (
    <Box id="container">
      <SectionHeader title="Controle de assinaturas" pagename="Assinatura">
        <Flex>
          <Button
            aria-label="help"
            onClick={() => {
              setEnableTourSignature(true);
            }}
            variant="outline"
            colorScheme="blue"
            mr={3}
          >
            <FiHelpCircle />
            <Text ml={2}>Ajuda</Text>
          </Button>
          <Button
            id="createSignature"
            leftIcon={<FiPlus />}
            colorScheme="blue"
            variant="solid"
            onClick={handleSignatureCreate}
            ref={btnRef}
          >
            Cadastrar
          </Button>
        </Flex>
      </SectionHeader>
      <Box>
        {!loading ? (
          <Grid
            templateColumns={{
              base: '1fr',
              lg: 'repeat(auto-fit, minmax(400px, 1fr))',
            }}
            gap={[2, 6]}
            justifyContent="flex-start"
          >
            {!!subscription?.length &&
              subscription?.map((subs) => {
                return (
                  <Box
                    key={subs.id}
                    bg="red"
                    borderWidth="1px"
                    p={2}
                    rounded="md"
                    position="relative"
                    display="flex"
                    flexDir="column"
                    justifyContent="space-between"
                    textAlign="left"
                    backgroundColor="gray.50"
                    borderColor="gray.100"
                    // width={['auto', '500px']}
                  >
                    <Flex
                      px={2}
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Flex
                        height="full"
                        alignItems="center"
                        pr={[3, 6]}
                        borderRight="1px solid black"
                      >
                        <Text fontSize="2xl">#{subs.id}</Text>
                      </Flex>
                      <Flex alignItems="center">
                        <Box pl={[3, 0]}>
                          <Text>
                            Tipo de assinatura:{' '}
                            {subs.subscription_period.period}
                          </Text>
                          <Text>Status: {subs.subscription_status.name}</Text>
                          <Text>Total de licenças: {subs.licenses_qty}</Text>
                          <Text>
                            Licenças disponiveis:{' '}
                            {subs.subscription_available_licenses_count}
                          </Text>
                          <Text>
                            Vigência: {subs.start_date} até {subs.end_date}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex
                        flexDir="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <IconButton
                          id="configLicense"
                          aria-label="Gerenciar"
                          icon={<FiSettings />}
                          size="lg"
                          isRound
                          variant="ghost"
                          onClick={() => handleSignatureLicense(subs.id)}
                        />
                        {/* <IconButton
                          aria-label="Apagar"
                          icon={<FiXCircle />}
                          size="lg"
                          isRound
                          variant="ghost"
                          colorScheme="red"
                        /> */}
                      </Flex>
                    </Flex>
                  </Box>
                );
              })}
          </Grid>
        ) : (
          <Loading />
        )}
      </Box>
      <Drawer
        preserveScrollBarGap
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Cadastrar Nova Assinatura
          </DrawerHeader>

          <DrawerBody>
            <Form ref={formRef} onSubmit={() => null}>
              <SelectChakra
                width="full"
                label="Tipo de assinatura"
                name="period"
                options={[
                  { label: 'semestral', value: 1 },
                  { label: 'anual', value: 2 },
                ]}
              />
              <Input
                name="name"
                type="text"
                placeholder="Nome"
                icon={FiUser}
                autoFocus
              />
              <Input
                name="email"
                type="email"
                placeholder="E-mail"
                icon={FiMail}
              />
            </Form>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={() => formRef.current?.submitForm()}
              /* isLoading={loading}
              isDisabled={loading} */
            >
              Confirmar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <StepsIntro
        enabled={enableTourSignature}
        onExit={() => setEnableTourSignature(false)}
        steps={stepsSignature}
      />
    </Box>
  );
};

export default Signature;
