import React, { useCallback, useRef, useState, useMemo } from 'react';
import { FiLock, FiArrowLeft } from 'react-icons/fi';
import { useHistory, useLocation, Link as ReactLink } from 'react-router-dom';

import {
  Button,
  Flex,
  Heading,
  Box,
  useColorMode,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Input from '~/shared/components/InputChakra';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const { colorMode } = useColorMode();
  const bgColor = { light: 'gray.50', dark: 'rgb(18, 18, 20)' };
  const bgInputColor = { light: '#fff', dark: 'rgb(23, 23, 26)' };
  const bgBorderInputColor = { light: 'inherit', dark: 'rgb(40, 39, 44)' };
  const textInputColor = { light: 'black', dark: 'rgb(225, 225, 230)' };
  const textTitle = { light: 'gray.700', dark: 'rgb(225, 225, 230)' };

  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const addToast = useToast();
  const history = useHistory();

  const { search } = useLocation();

  const token = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('token');
  }, [search]);

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('Senha obrigatória'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Confirmação incorreta',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!token) {
          throw new Error();
        }

        const { password, password_confirmation } = data;

        await api.post('/password/reset', {
          password,
          password_confirmation,
          token,
        });

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Senha redefinida com sucesso!',
          description: 'Use sua nova senha para fazer login',
        });

        setLoading(false);

        history.push('/');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao resetar senha',
          description: 'Ocorreu um erro ao resetar sua senha. tente novamente',
        });
      }
    },
    [addToast, history, token],
  );

  return (
    <>
      <Box
        w="full"
        display="flex"
        flexDir="column"
        alignItems="center"
        minH="100vh"
        bg={bgColor[colorMode]}
        transition="all 0.2s"
      >
        <Box
          display="flex"
          flex="1 1 0%"
          flexDir="column"
          alignItems="center"
          w="100%"
          padding="32px"
          maxW={1000}
        >
          <Flex
            flex="1 1 0%"
            justifyContent="center"
            w="full"
            alignItems="center"
            padding="28px 0 50px"
          >
            <Box w="full" maxW="320px">
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Heading
                  as="h1"
                  fontSize="25px"
                  mb="29px"
                  color={textTitle[colorMode]}
                  textAlign="center"
                >
                  Recuperar senha
                </Heading>

                <Input
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder="Sua nova senha"
                  autoFocus
                  background={bgInputColor[colorMode]}
                  borderColor={bgBorderInputColor[colorMode]}
                  color={textInputColor[colorMode]}
                />

                <Input
                  name="password_confirmation"
                  icon={FiLock}
                  type="password"
                  placeholder="Confirme sua senha"
                  background={bgInputColor[colorMode]}
                  borderColor={bgBorderInputColor[colorMode]}
                  color={textInputColor[colorMode]}
                />

                <Button
                  mt={4}
                  colorScheme="blue"
                  isLoading={loading}
                  isDisabled={loading}
                  width="100%"
                  size="lg"
                  type="submit"
                >
                  ALTERAR SENHA
                </Button>

                <ReactLink to="/">
                  <Text
                    fontSize="xs"
                    color="gray.500"
                    w="full"
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={5}
                  >
                    <FiArrowLeft />
                    Voltar
                  </Text>
                </ReactLink>
              </Form>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
