import React, {
  useEffect,
  useRef,
  InputHTMLAttributes,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { FiCheck } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons/lib/esm/iconBase';

import {
  Checkbox,
  Heading,
  Stack,
  FormErrorMessage,
  FormControl,
  Flex,
  Tooltip,
  Icon as IconChakra,
  Text,
  Box,
} from '@chakra-ui/react';
import { useField } from '@unform/core';

import ButtonIcon from '../../Button/ButtonIcon';

interface OptionsCb {
  id: string;
  value: string;
  label: string;
  icon?: React.ReactElement;
  variantColor?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  description?: string;
  onChange?(): void;
  options: OptionsCb[];
  isInline?: boolean;
  containerStyle?: object;
  help?: string;
  mb?: number | string;
  sizeChakra?: string;
  hasChecked?(status: boolean): void;
  countChecked?(n: number): void;
  enableParentOption?: boolean;
  textParentOption?: string;
  icon?: React.ReactElement;
}

const CheckboxInput: React.FC<Props> = ({
  name,
  onChange,
  description,
  options,
  isInline = false,
  containerStyle,
  help,
  mb,
  sizeChakra,
  hasChecked,
  countChecked,
  enableParentOption = false,
  textParentOption,
  icon,
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [], error } = useField(name);

  /* Independente de possuir ou não a parentOption precisaremos sempre desse estado "espelho" da opções, porém só com os valores
  boolean relativo a isChecked */
  /* const [allOptions, setAllOptions] = useState(
    options.map((op) => !!defaultValue.find((dv: string) => dv === op.id)),
  ); */

  const [allOptions, setAllOptions] = useState<OptionsCb[]>(
    // Nem sempre haverá um defaultValue, apenas quando utilizar a prop initialData no componente <Form />
    options.map((op) => {
      return {
        ...op,
        isChecked:
          !!op.isChecked || !!defaultValue.find((dv: string) => dv === op.id),
      };
    }),
  );

  // Quando alterar o allOptions, atualizará os valores de allChecked e isIndeterminate por allOptions ser um estado
  // O construtor boolean retorna o valor padrão true através de uma callBack
  // Passamos um valor padrão se não precisar de parentOptions para não precisar fazer um "map" nas opções
  const allChecked = enableParentOption
    ? allOptions.every((op) => !!op.isChecked)
    : false;

  const isIndeterminate = enableParentOption
    ? allOptions.some((op) => !!op.isChecked) && !allChecked
    : false;

  useEffect(() => {
    registerField({
      name: fieldName,
      // Alterado de inputRefs.current para inputRefs
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  const handleCheckParentOption = useCallback((parentChecked: boolean) => {
    setAllOptions((state) =>
      state.map((op) => {
        return { ...op, isChecked: parentChecked };
      }),
    );

    if (!parentChecked) {
      inputRefs.current.forEach((ref) => {
        ref.checked = false;
      });
    } else {
      inputRefs.current.forEach((ref) => {
        ref.checked = true;
      });
    }
  }, []);

  return (
    <FormControl mb={mb || 4} style={containerStyle} isInvalid={!!error}>
      {description && (
        <Flex alignItems="center" fontWeight="medium">
          <Heading
            as="h5"
            size="sm"
            mb={1}
            fontWeight={500}
            cursor="default"
            color="gray.500"
          >
            {description}
          </Heading>

          {help && (
            <Tooltip aria-label={help} label={help} zIndex={1}>
              <IconChakra name="info-outline" ml="auto" color="gray.500" />
            </Tooltip>
          )}
        </Flex>
      )}
      {enableParentOption && (
        <Checkbox
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={(e) => {
            handleCheckParentOption(e.target.checked);
          }}
          mb={4}
        >
          {textParentOption || 'Marcar todos'}
        </Checkbox>
      )}

      <Stack isInline={isInline} spacing={1}>
        {allOptions.map((option, index) => (
          <Checkbox
            icon={option.isChecked ? icon : undefined}
            // name={`${fieldName}[${index}]`}
            id={option.id}
            key={option.id}
            /* defaultIsChecked={
              defaultValue.find((dv: string) => dv === option.id) ||
              option.isChecked
            } */
            isChecked={option.isChecked}
            size={sizeChakra || 'md'}
            ref={(ref: HTMLInputElement) => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            value={option.value}
            colorScheme={option.variantColor || 'blue'}
            isDisabled={option.isDisabled}
            onChange={() => {
              onChange && onChange();
              hasChecked &&
                hasChecked(
                  !!inputRefs.current.filter((ref) => ref.checked).length,
                );
              countChecked &&
                countChecked(
                  inputRefs.current.filter((ref) => ref.checked).length,
                );
              /* enableParentOption && updateParentOption(); */
              setAllOptions((state) =>
                state.map((op) => {
                  return op.id === option.id
                    ? { ...op, isChecked: !op.isChecked }
                    : op;
                }),
              );
            }}
            alignSelf="start"
          >
            <Flex alignItems="center">
              {option.icon && <Box mr={2}>{option.icon}</Box>}
              <Text>{option.label}</Text>
            </Flex>
          </Checkbox>
        ))}
      </Stack>
      {error && <FormErrorMessage mb={2}>{error}</FormErrorMessage>}
    </FormControl>
  );
};
export default CheckboxInput;
