import { Flex } from '@chakra-ui/react';
import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Card = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background: rgb(238, 245, 255);
  min-width: 250px;
  color: ${({ theme }) => theme.colors.text};

  border-bottom: 4px solid transparent;
  transition: all 0.2s;

  &:hover {
    border-color: ${darken(0.05, 'rgb(238, 245, 255)')};
  }
`;
