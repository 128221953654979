/* eslint-disable react/no-children-prop */
import React, { useEffect, useRef, TextareaHTMLAttributes } from 'react';

import {
  FormErrorMessage,
  FormControl,
  Heading,
  Textarea,
  Flex,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { useField } from '@unform/core';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  containerStyle?: object;
  placeholder?: string;
  help?: string;
  mb?: number | string;
}

const TextareaChakra: React.FC<TextareaProps> = ({
  name,
  label,
  containerStyle,
  placeholder,
  help,
  mb,
  ...rest
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormControl mb={mb || 4} style={containerStyle} isInvalid={!!error}>
      {label && (
        <Flex alignItems="center" fontWeight="medium">
          <Heading
            as="h5"
            size="sm"
            fontWeight={500}
            mb={1}
            onClick={() => textareaRef.current?.focus()}
            cursor="default"
            color="gray.500"
          >
            {label}
          </Heading>

          {help && (
            <Tooltip aria-label={help} label={help} zIndex={1}>
              <Icon name="info-outline" ml="auto" color="gray.500" />
            </Tooltip>
          )}
        </Flex>
      )}

      <Textarea
        isInvalid={!!error}
        name={name}
        id={name}
        defaultValue={defaultValue}
        ref={textareaRef}
        resize="vertical"
        placeholder={placeholder}
        {...rest}
      />

      {error && <FormErrorMessage mb={2}>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default TextareaChakra;
