import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
  Fragment,
} from 'react';
import {
  FaTrash,
  FaSearch,
  FaEraser,
  FaCaretUp,
  FaArrowDown,
  FaChevronDown,
  FaChevronUp,
  FaFileExcel,
} from 'react-icons/fa';
import { FiX, FiUserX, FiVolumeX } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

import {
  Box,
  Text,
  Flex,
  IconButton,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { parseISO, format, subMonths } from 'date-fns';
import queryString from 'query-string';

import InputChakra from '~/shared/components/InputChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import exportExcel from '~/utils/exportExcel';

interface CompaniesConsumption extends IPaginationProps {
  data: Data[];
}

interface DataExport {
  [id: string]: number;
}

interface Data {
  real_allocation: string;
  real_allocation_sec: string;
  available_time: string;
  available_time_sec: string;
  total_consumption: string;
  company_name: string;
  company_client_id: number;
  total_consumption_department: number;
  departament_consumption: DepartamentConsumption[];
  visible?: boolean;
}

interface DepartamentConsumption {
  departamento_id: number;
  departament: string;
  real_allocation: string;
  real_allocation_sec: string;
  available_shifts: string;
  departament_consumption: string;
  company_consumption: string;
}

interface ResumeTotalConsumption {
  real_allocation: string;
  real_allocation_sec: string;
  available_time: string;
  available_time_sec: string;
  total_consumption: string;
}

interface StructureConsumption {
  companies_consumption: CompaniesConsumption;
  resume_total_consumption: ResumeTotalConsumption;
}

interface DateFilter {
  start_date: string;
  end_date: string;
}

interface QueryParams {
  start_date?: string;
  end_date?: string;
}

export const StructureConsumption: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [
    companiesConsumption,
    setCompaniesConsumption,
  ] = useState<CompaniesConsumption>({} as CompaniesConsumption);

  const location = useLocation();
  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const [
    resumeTotalConsumption,
    setResumeTotalConsumption,
  ] = useState<ResumeTotalConsumption>();
  const [startDate, setStartDate] = useState(
    queryParams.start_date ?? format(subMonths(new Date(), 1), 'yyyy-MM'),
  );
  const [endDate, setEndDate] = useState(
    queryParams.end_date ?? format(new Date(), 'yyyy-MM'),
  );

  const goLink = useMemo(() => {
    return `/dashboard`;
  }, []);

  /* const [date, setDate] = useState<DateFilter>(() => {
    const currentDate = new Date();

    return {
      start_date:
        queryParams.start_date ?? format(subMonths(currentDate, 1), 'yyyy-MM'),
      end_date: queryParams.end_date ?? format(currentDate, 'yyyy-MM'),
    };
  }); */

  const [page, setPage] = useState(1);

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Empresa',
      },
      {
        title: 'Alocação Real (Horas)',
      },
      {
        title: 'Tempo Disponível (Horas)',
      },
      {
        title: 'Consumo (Departamento)',
      },
      {
        title: 'Consumo (Fornecedor)',
      },
    ];
  }, []);

  const tableTitlesExcel = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Empresa',
      },
      {
        title: 'Departamento',
      },
      {
        title: 'Funcionário',
      },
      {
        title: 'Alocação Real (Horas)',
      },
      {
        title: 'Tempo Disponível (Horas)',
      },
      {
        title: 'Consumo (Funcionário)',
      },
      {
        title: 'Consumo (Departamento)',
      },
      {
        title: 'Consumo (Fornecedor)',
      },
    ];
  }, []);

  useEffect(() => {
    setLoading(true);

    api
      .get<StructureConsumption>(`/reports/structure-consumption/companies`, {
        params: { page, start_date: startDate, end_date: endDate },
      })
      .then((response) => {
        const {
          companies_consumption,
          resume_total_consumption,
        } = response.data;

        setCompaniesConsumption(companies_consumption);
        // console.log(startDate, endDate, 'dd');

        if (resume_total_consumption)
          setResumeTotalConsumption(resume_total_consumption);
      })

      .finally(() => setLoading(false));
  }, [page, startDate, endDate]);

  const handleNewPage = useCallback((pageNew: number) => {
    setPage(pageNew);
  }, []);

  const handleSubmit = useCallback((data: any) => {
    setStartDate(data.start_date);
    setEndDate(data.end_date);
    setPage(1);
    // console.log(data);
  }, []);

  const handleShowDepartments = useCallback((companyId: number): void => {
    setCompaniesConsumption((state) => ({
      ...state,
      data: state.data.map((dp) => {
        return {
          ...dp,
          visible:
            dp.company_client_id === companyId ? !dp.visible : dp.visible,
        };
      }),
    }));
  }, []);

  const handleExportExcel = useCallback(async () => {
    setLoadingExcel(true);
    await api
      .get<DataExport[]>(`/reports/structure-consumption/companies`, {
        params: { export: 1, start_date: startDate, end_date: endDate },
      })
      .then((response) => {
        // console.log(response.data);
        let name = 'Relatório de Consumo de Estrutura';

        if (startDate || endDate) {
          const reportStartDate = startDate
            ? format(parseISO(`${startDate}-01`), 'MM-yyyy')
            : 'indeterminado';
          const reportEndDate = endDate
            ? format(parseISO(`${endDate}-01`), 'MM-yyyy')
            : 'indeterminado';

          // console.log(reportStartDate, reportEndDate);

          name += ` de ${reportStartDate} a ${reportEndDate}`;
        }
        // exportExcel()

        // let titleSheet = 'RELATORIO_CONSUMO_ESTRUTURA';

        // console.log(startDate, endDate, 'datas');
        exportExcel({
          fileExtension: '.xlsx',
          fileType:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
          title: name,
          name,
          colName: tableTitlesExcel.map((tt) => {
            return tt.title;
          }),
          thead: tableTitlesExcel.map((tt) => {
            return { title: tt.title };
          }),
          titleDataOrder: [
            'company_name',
            'department_name',
            'name',
            'real_allocation',
            'available_time',
            'employee_consumption',
            'total_consumption_department',
            'total_consumption_provider',
          ],
          data: response.data.map((dt) => {
            return {
              company_name: dt.company_name.toString(),
              department_name: dt.department_name.toString(),
              name: dt.name.toString(),
              real_allocation: dt.real_allocation.toString(),
              available_time: dt.available_time.toString(),
              employee_consumption: dt.employee_consumption.toString(),
              total_consumption_department: dt.total_consumption_department.toString(),
              total_consumption_provider: dt.total_consumption_provider.toString(),
            };
          }),

          // employee: 'funcionario',
          // rangeData: { s: { c: 0, r: 0 }, e: { c: 13, r: 10 } },
          // rangeHeader: 'rangeHeader',
          titleLine: 1,
          enableAutoFilter: true,
        });
      })

      .finally(() => setLoadingExcel(false));
  }, [startDate, endDate, tableTitlesExcel]);
  return (
    <Box pos="relative">
      {loading && <LoadingAbsolute z_index={1111} min_height={500} />}

      <SectionHeader
        title="Consumo de Estrutura - Empresas"
        pagename="Visualizar o percentual de consumo de estrutura com base no tempo disponível dos funcionários e a hora gasta na realização das demandas das empresas clientes"
        // goBackPage
        goBackLink={goLink}
      />

      <Breadcrumb display="flex" justifyContent="left" mb="30px">
        <BreadcrumbItem color="#989898">
          <Text>Relatório de Consumo de Estrutura</Text>
        </BreadcrumbItem>
      </Breadcrumb>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Flex alignItems="center" justifyContent="center" mb="16px">
          <InputChakra
            background="#e9ecef"
            borderColor=""
            name="start_date"
            defaultValue={startDate}
            type="month"
            mb="0px"
            label="Período início"
          />
          <IconButton
            aria-label="Limpar Início"
            icon={<FiX />}
            variant="ghost"
            colorScheme="red"
            mr="30px"
            mt="15px"
            onClick={() => formRef.current?.clearField('start_date')}
          />

          <InputChakra
            background="#e9ecef"
            borderColor=""
            name="end_date"
            defaultValue={endDate}
            type="month"
            mb="0px"
            label="Período Fim"
          />
          <IconButton
            aria-label="Limpar Fim"
            icon={<FiX />}
            variant="ghost"
            colorScheme="red"
            mr="30px"
            mt="15px"
            onClick={() => formRef.current?.clearField('end_date')}
          />
          <Tooltip aria-label="Filtrar" label="Filtrar">
            <IconButton
              aria-label="Filtrar"
              icon={<FaSearch />}
              mr="20px"
              width="70px"
              mt="15px"
              onClick={() => formRef.current?.submitForm()}
            />
          </Tooltip>

          <Tooltip aria-label="Limpar Filtro" label="Limpar Filtro">
            <IconButton
              aria-label="Limpar Filtro"
              icon={<FaEraser />}
              width="70px"
              mt="15px"
              onClick={() => formRef.current?.reset()}
            />
          </Tooltip>
        </Flex>
      </Form>

      {!loading && (
        <>
          {companiesConsumption?.data?.length ? (
            <>
              <Button
                colorScheme="green"
                bgColor="green.600"
                size="md"
                onClick={() => handleExportExcel()}
                _hover={{ backgroundColor: '#38A169' }}
                disabled={loadingExcel}
                ml={[0, 4]}
                w={['full', 'auto']}
                isLoading={loadingExcel}
              >
                <FaFileExcel />
                <Text display="inline-block" ml={2}>
                  Exportar para Excel
                </Text>
              </Button>

              <Table
                theadData={tableTitles}
                pagination={{
                  current_page: companiesConsumption.current_page,
                  last_page: companiesConsumption.last_page,
                  per_page: companiesConsumption.per_page,
                  to: companiesConsumption.to,
                  total: companiesConsumption.total,
                }}
                newPage={(pg) => handleNewPage(pg)}
              >
                <Box as="tr">
                  <Box as="td">
                    <Text color="#2e8b57" as="b">
                      TOTAL
                    </Text>
                  </Box>
                  <Box as="td">
                    <Text color="#2e8b57" as="b">
                      {resumeTotalConsumption?.real_allocation}
                    </Text>
                  </Box>
                  <Box as="td">
                    <Text color="#2e8b57" as="b">
                      {resumeTotalConsumption?.available_time}
                    </Text>
                  </Box>
                  <Box as="td">
                    <Text />
                  </Box>
                  <Box as="td">
                    <Text color="#2e8b57" as="b">
                      {`${resumeTotalConsumption?.total_consumption}%`}
                    </Text>
                  </Box>
                </Box>

                {companiesConsumption.data.map((company) => {
                  return (
                    <Fragment key={company.company_client_id}>
                      {/* <Accordion
                        allowMultiple
                        as="tbody"
                        border="2px solid #f5f5f5"
                      > */}
                      {/* <AccordionItem as="tr" width="full"> */}
                      <Box
                        as="tr"
                        width="full"
                        border="2px solid #f5f5f5"
                        p="10px"
                      >
                        <Box
                          textAlign="left"
                          width="100%"
                          my={2}
                          alignItems="center"
                          as="td"
                          p="5px"
                        >
                          {/* <AccordionButton display="flex" alignItems="center"> */}
                          <Box display="flex">
                            <IconButton
                              aria-label="mostrar departamentos"
                              icon={
                                company.visible ? (
                                  <FaChevronUp />
                                ) : (
                                  <FaChevronDown />
                                )
                              }
                              onClick={() =>
                                handleShowDepartments(company.company_client_id)
                              }
                              variant="ghost"
                              height="auto"
                            />
                            <Text>{company.company_name}</Text>
                          </Box>
                          {/* <AccordionIcon /> */}
                          {/* </AccordionButton> */}
                        </Box>
                        <Box
                          textAlign="left"
                          width="100%"
                          my={2}
                          alignItems="center"
                          as="td"
                          p="5px"
                        >
                          <Text>{company.real_allocation}</Text>
                        </Box>
                        <Box
                          textAlign="left"
                          width="100%"
                          my={2}
                          alignItems="center"
                          as="td"
                          p="5px"
                        >
                          <Text>{company.available_time}</Text>
                        </Box>
                        <Box
                          textAlign="left"
                          width="100%"
                          my={2}
                          alignItems="center"
                          as="td"
                          p="5px"
                        >
                          <Text />
                        </Box>
                        <Box
                          textAlign="left"
                          width="100%"
                          my={2}
                          alignItems="center"
                          as="td"
                          p="5px"
                        >
                          <Text>{`${company.total_consumption}%`}</Text>
                        </Box>
                      </Box>
                      {/*  <AccordionPanel pb={4}> */}
                      {/* <Box background="#f6fbff">
                        <Box> */}
                      {/*  <Table> */}
                      {company.visible && (
                        <>
                          {company.departament_consumption.map((dep) => {
                            return (
                              <Box
                                as="tr"
                                key={dep.departamento_id}
                                background="#f6fbff"
                              >
                                <Box as="td" p="5px" pl="40px">
                                  <>
                                    {Number(dep.real_allocation_sec) >= 30 ? (
                                      <Link
                                        to={`/relatorios/consumo-estrutura/empresas/${company.company_client_id}?department_id=${dep.departamento_id}&start_date=${startDate}&end_date=${endDate}`}
                                        style={{
                                          cursor: 'pointer',
                                          textDecoration: 'underline',
                                        }}
                                      >
                                        <Text>{dep.departament}</Text>
                                      </Link>
                                    ) : (
                                      <Text>{dep.departament}</Text>
                                    )}
                                  </>
                                </Box>
                                <Box as="td" p="5px">
                                  <Text>{dep.real_allocation}</Text>
                                </Box>
                                <Box as="td" p="5px">
                                  <Text>{dep.available_shifts}</Text>
                                </Box>
                                <Box as="td" p="5px">
                                  <Text>{`${dep.departament_consumption}%`}</Text>
                                </Box>
                                <Box as="td" p="5px">
                                  <Text>{`${dep.company_consumption}%`}</Text>
                                </Box>
                              </Box>
                            );
                          })}
                        </>
                      )}

                      {/* </Table> */}
                      {/* </Box>
                      </Box> */}
                      {/* </AccordionPanel> */}
                      {/*  </AccordionItem>
                      </Accordion> */}
                    </Fragment>
                  );
                })}
              </Table>
            </>
          ) : (
            <Box
              fontSize="1.2rem"
              fontWeight="400"
              textAlign="center"
              marginTop="150px"
            >
              <Text color="#818182">
                Nenhum dado foi encontrado, verifique os filtros e tente
                novamente
              </Text>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default StructureConsumption;
