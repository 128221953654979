import { Box } from '@chakra-ui/react';
import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled(Box)`
  min-width: 300px;
  width: 100%;
  min-height: 377px;
  display: block !important;
  position: relative;

  small {
    text-align: center;
    margin: 0 auto;
    display: block;
  }

  .DayPicker {
    background-image: linear-gradient(45deg, #f6f7ff, #fbfbff);
    border-radius: 10px;
    border: 1px solid #f3f4fd;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    padding-right: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 0 0 16px;
  }

  .DayPicker-Caption {
    padding: 16px 10px;
    background-image: linear-gradient(45deg, #081132, #151f93);
    box-shadow: 0 4px 15px rgba(24, 24, 25, 0.45);
    color: #fff;
    border-radius: 5px 5px 0 0;
    text-align: center;
  }

  .DayPicker-NavButton--prev {
    left: 16px;
    right: auto;
  }

  .DayPicker-NavButton--next {
    right: 16px;
  }

  .DayPicker-Day {
    width: 30px;
    height: 30px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #ccd3df;
    border-radius: 10px;
    color: #2f2e38;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#CCD3DF')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: #323c79 !important;
    border-radius: 5px;
    color: #fff !important;
  }

  @media (max-width: 480px) {
    //max-width: 350px;
    width: 100%;

    .DayPicker-Day {
      width: 35px;
      height: 35px;
    }
  }
`;
