import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  Fragment,
} from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import {
  Box,
  Flex,
  Text,
  Tooltip,
  AccordionPanel,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  IconButton,
} from '@chakra-ui/react';

import useCan from '~/hooks/useCan';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import { IPaginationProps } from '~/shared/components/Pagination';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';

interface DataPerformIndicatorBSC extends IPaginationProps {
  data: Indicators[];
}

interface Indicators {
  etapa_acordo_trabalho: number;
  perspectiva_nome: string;
  grupo_indicador_nome: string;
  indicador_id: number;
  indicador_nome: string;
  periodo: string;
  meta: string;
  alcancado: string;
  meta_desempenho: string;
  desempenho: string;
  pontuacao_possivel_acumulada_indicador: string;
  pontuacao_possivel_mes_indicador: string;
  pontuacao_final: string;
  etapa_valida_indicador: number;
  employees?: Employee[];
}

interface Employee {
  usuario_id: number;
  funcionario: string;
  etapa_acordo_trabalho: number;
  meta: string;
  alcancado: string;
  meta_desempenho: string;
  desempenho: string;
  pontuacao_possivel_acumulada_indicador: string;
  pontuacao_possivel_mes_indicador: string;
  pontuacao_final: string;
  etapa_valida_indicador: number;
}

interface Props {
  id: number;
  mode: 'employee' | 'team';
  workDealId: number;
  title: string;
  pontuacaoFinalAcumulada: string;
  pontuacaoPossivelAcumulada: string;
  competence: string;
  step?: number;
  isOpen: boolean;
  onClose: () => void;
  onCLickStep: (step: number) => void;
}

interface PropsGeneralIndicators {
  id: number;
  title: string;
  mode: 'employee' | 'team';
  workDealId: number;
  isOpen: boolean;
  onClose: () => void;
}

const ModalIndicatorsStep: React.FC<Props> = ({
  id,
  title,
  pontuacaoPossivelAcumulada,
  pontuacaoFinalAcumulada,
  step = 1,
  workDealId,
  mode,
  competence,
  isOpen,
  onClose,
  onCLickStep,
}) => {
  const can = useCan();

  const [page, setPage] = useState(step);
  const [loading, setLoading] = useState(true);
  const [lastWorkDealStep, setLastWorkDealStep] = useState<number>();
  const [
    performIndicator,
    setPerformIndicator,
  ] = useState<DataPerformIndicatorBSC>();
  const [
    totalMesPossiblePerformIndicator,
    setTotalMesPossiblePerformIndicator,
  ] = useState(0);
  const [
    totalPossiblePerformIndicator,
    setTotalPossiblePerformIndicator,
  ] = useState(0);
  const [
    totalMesFinalPerformIndicator,
    setMesTotalFinalPerformIndicator,
  ] = useState(0);

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Perspectiva',
      },
      {
        title: 'Grupo do Indicador',
      },
      {
        title: 'Indicador',
      },
      {
        title: 'Meta',
      },
      {
        title: 'Alcançado',
      },
      {
        title: 'Desempenho',
      },
      {
        title: 'Pontuação possivel',
      },
      {
        title: 'Pontuação Final',
      },
    ];
  }, []);

  function handleAddSymbolValue(indicatorId: number, value: number): string {
    switch (indicatorId) {
      case 1:
        return `${value * 100}%`;
      /* Indicadores booleanos inversos */
      case 2:
      case 30:
        return !value ? 'Sim' : 'Não';
      /* Indicadores booleanos normais */
      case 6:
      case 8:
      case 17:
      case 18:
      case 20:
      case 25:
        return value ? 'Sim' : 'Não';
      /* Indicadores de segundos */
      case 10:
      case 9:
        return `${value.toFixed()}s`;
      default:
        return value.toString();
    }
  }

  const getPerformIndicator = useCallback(
    (numPage: number): void => {
      setLoading(true);
      const url =
        mode === 'employee'
          ? `/analytic/bsc/indicators/employee/${id}`
          : `/analytic/bsc/indicators/department/${id}`;

      api
        .get<DataPerformIndicatorBSC>(url, {
          params: { work_deal_id: workDealId, step: numPage },
        })
        .then((response) => {
          const { last_page, total, data } = response.data;

          if (!data.length) return;

          const lastStep = data[data.length - 1].etapa_acordo_trabalho;

          setLastWorkDealStep(lastStep);
          setPage(lastStep);

          let totalPossiblePts = 0;
          let totalMesPossiblePts = 0;
          let totalFinalPts = 0;
          let pontuacaoPossivel = 0;
          let pontuacaoMes = 0;
          let pontuacaoFinal = 0;

          setPerformIndicator({
            ...response.data,
            data: data.map((teamPerf) => {
              pontuacaoPossivel = Number(
                teamPerf.pontuacao_possivel_acumulada_indicador,
              );

              pontuacaoMes = Number(teamPerf.pontuacao_possivel_mes_indicador);

              pontuacaoFinal = Number(teamPerf.pontuacao_final);
              totalPossiblePts += pontuacaoPossivel;
              totalMesPossiblePts += pontuacaoMes;
              totalFinalPts += pontuacaoFinal;

              let indicators: Indicators;
              indicators = {
                ...teamPerf,
                meta: handleAddSymbolValue(
                  teamPerf.indicador_id,
                  Number(teamPerf.meta),
                ),
                alcancado: handleAddSymbolValue(
                  teamPerf.indicador_id,
                  Number(teamPerf.alcancado),
                ),
                desempenho: (Number(teamPerf.desempenho) * 100).toFixed(),
                pontuacao_possivel_acumulada_indicador: pontuacaoPossivel.toFixed(
                  2,
                ),
                pontuacao_possivel_mes_indicador: pontuacaoMes.toFixed(2),
                pontuacao_final: pontuacaoFinal.toFixed(2),
              };
              if (mode === 'team' && teamPerf.employees?.length) {
                indicators = {
                  ...indicators,
                  employees: teamPerf.employees.map((empBSC) => {
                    return {
                      ...empBSC,
                      meta: handleAddSymbolValue(
                        teamPerf.indicador_id,
                        Number(empBSC.meta),
                      ),
                      alcancado: handleAddSymbolValue(
                        teamPerf.indicador_id,
                        Number(empBSC.alcancado),
                      ),
                      desempenho: (Number(empBSC.desempenho) * 100).toFixed(),
                      pontuacao_possivel_acumulada_indicador: Number(
                        empBSC.pontuacao_possivel_acumulada_indicador,
                      ).toFixed(2),
                      pontuacao_possivel_mes_indicador: Number(
                        empBSC.pontuacao_possivel_mes_indicador,
                      ).toFixed(2),
                      pontuacao_final: Number(empBSC.pontuacao_final).toFixed(
                        2,
                      ),
                    };
                  }),
                };
              }
              return indicators;
            }),
            last_page,
            total,
          });
          setTotalPossiblePerformIndicator(totalPossiblePts);
          setTotalMesPossiblePerformIndicator(totalMesPossiblePts);
          setMesTotalFinalPerformIndicator(totalFinalPts);
          /* const ptsTotalFinal = performIndicator?.data.find(
            (emp) => emp.etapa_acordo_trabalho === numPage,
          )?.pontuacao_final;
          setTotalFinalPerformIndicator(ptsTotalFinal); */
          setPage(numPage);
        })
        .finally(() => setLoading(false));
    },
    [id, mode, workDealId],
  );

  useEffect(() => {
    if (isOpen) getPerformIndicator(step);
  }, [getPerformIndicator, step, isOpen]);

  const handlePaginationModal = useCallback(
    (n: number) => {
      // Para avançar a página, n é +1
      // Para voltar de página, n é -1
      getPerformIndicator(page + n);
      onCLickStep(page + n);
    },
    [getPerformIndicator, page, onCLickStep],
  );

  return (
    <ModalChakra
      title={!loading ? `${competence}, ${title}` : 'Carregando...'}
      onClose={() => {
        onClose();
        // setPerformIndicator(undefined);
        // setTotalPossiblePerformIndicator(0);
        // setMesTotalFinalPerformIndicator(0);
        setPage(lastWorkDealStep ?? 1);
      }}
      isOpen={isOpen}
      size="7xl"
      isLoading={loading}
    >
      {loading ? (
        <Box height="700px" width="auto" px={5}>
          <Loading />
        </Box>
      ) : (
        <Box position="relative" px="50px">
          <Box
            overflowY="auto"
            overflowX="auto"
            display="block"
            width="100%"
            h="700px"
          >
            <Box>
              <Table
                theadData={tableTitles}
                freezeThead
                newPage={(pg) => getPerformIndicator(pg)}
              >
                {performIndicator?.data?.map((perfIndic) => {
                  return (
                    <Fragment
                      key={`${perfIndic.indicador_id}.${perfIndic.etapa_acordo_trabalho}`}
                    >
                      <Box as="tr" _hover={{ bg: 'gray.50' }}>
                        <Box
                          as="td"
                          p={3}
                          borderWidth={2}
                          borderColor="gray.100"
                          width="170px"
                          verticalAlign="middle"
                        >
                          <Text as="b">{perfIndic.perspectiva_nome}</Text>
                        </Box>
                        <Box
                          as="td"
                          p={3}
                          borderWidth={2}
                          borderColor="gray.100"
                          verticalAlign="middle"
                        >
                          <Tooltip
                            aria-label={perfIndic.grupo_indicador_nome}
                            label={perfIndic.grupo_indicador_nome}
                            zIndex={1}
                          >
                            <Text isTruncated maxW={200}>
                              {perfIndic.grupo_indicador_nome}
                            </Text>
                          </Tooltip>
                        </Box>
                        <Box
                          as="td"
                          p={3}
                          borderWidth={2}
                          borderColor="gray.100"
                          // width="170px"
                          verticalAlign="middle"
                        >
                          <Text>{perfIndic.indicador_nome}</Text>
                        </Box>
                        <Box
                          as="td"
                          p={3}
                          borderWidth={2}
                          borderColor="gray.100"
                          width="170px"
                          verticalAlign="middle"
                        >
                          <Text as="b">{perfIndic.meta}</Text>
                        </Box>
                        <Box
                          as="td"
                          p={3}
                          borderWidth={2}
                          borderColor="gray.100"
                          width="170px"
                          verticalAlign="middle"
                        >
                          <Text as="b">{perfIndic.alcancado}</Text>
                        </Box>
                        <Box
                          as="td"
                          p={3}
                          borderWidth={2}
                          borderColor="gray.100"
                          width="170px"
                          verticalAlign="middle"
                        >
                          <Text as="b">{`${perfIndic.desempenho}%`}</Text>
                        </Box>
                        <Box
                          as="td"
                          p={3}
                          borderWidth={2}
                          borderColor="gray.100"
                          width="170px"
                          verticalAlign="middle"
                        >
                          <Text as="b">
                            {perfIndic.pontuacao_possivel_mes_indicador}
                          </Text>
                        </Box>
                        <Box
                          as="td"
                          p={3}
                          borderWidth={2}
                          borderColor="gray.100"
                          width="170px"
                          verticalAlign="middle"
                        >
                          <Text as="b">{perfIndic.pontuacao_final}</Text>
                        </Box>
                      </Box>
                      {mode === 'team' &&
                        can('show-employees-team-indicators') && (
                          <Accordion
                            allowMultiple
                            as="tr"
                            key={`${perfIndic.indicador_id}.b`}
                            border="2px solid #f5f5f5"
                          >
                            <AccordionItem as="td" colSpan={8}>
                              <AccordionButton
                                display="flex"
                                alignItems="center"
                              >
                                <Box textAlign="left" width="100%" my={2}>
                                  <Text fontSize="md" color="blue.400">
                                    Ver participantes desse indicador
                                  </Text>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>

                              <AccordionPanel pl={4} pr={0}>
                                <Box background="#f1f1f163">
                                  <Box>
                                    <Table>
                                      {perfIndic.employees?.map((empBSC) => {
                                        return (
                                          <Box
                                            key={`${empBSC.usuario_id}`}
                                            as="tr"
                                            _hover={{ bg: 'gray.50' }}
                                            borderBottom="2px solid #f1f1f1"
                                          >
                                            <Box
                                              as="td"
                                              p={1}
                                              borderWidth={2}
                                              borderColor="gray.100"
                                              verticalAlign="middle"
                                              colSpan={3}
                                            >
                                              <Flex justifyContent="center">
                                                <Text textAlign="start" as="b">
                                                  {empBSC.funcionario}
                                                </Text>
                                              </Flex>
                                            </Box>
                                            <Box
                                              as="td"
                                              p={3}
                                              borderWidth={2}
                                              borderColor="gray.100"
                                              width="170px"
                                              verticalAlign="middle"
                                            >
                                              <Text as="b">{empBSC.meta}</Text>
                                            </Box>
                                            <Box
                                              as="td"
                                              p={3}
                                              borderWidth={2}
                                              borderColor="gray.100"
                                              width="170px"
                                              verticalAlign="middle"
                                            >
                                              <Text as="b">
                                                {empBSC.alcancado}
                                              </Text>
                                            </Box>
                                            <Box
                                              as="td"
                                              p={3}
                                              borderWidth={2}
                                              borderColor="gray.100"
                                              width="170px"
                                              verticalAlign="middle"
                                            >
                                              <Text as="b">{`${empBSC.desempenho}%`}</Text>
                                            </Box>
                                            <Box
                                              as="td"
                                              p={3}
                                              borderWidth={2}
                                              borderColor="gray.100"
                                              width="170px"
                                              verticalAlign="middle"
                                            >
                                              <Text as="b">
                                                {
                                                  empBSC.pontuacao_possivel_mes_indicador
                                                }
                                              </Text>
                                            </Box>
                                            <Box
                                              as="td"
                                              p={3}
                                              borderWidth={2}
                                              borderColor="gray.100"
                                              width="170px"
                                              verticalAlign="middle"
                                            >
                                              <Text as="b">
                                                {empBSC.pontuacao_final}
                                              </Text>
                                            </Box>
                                          </Box>
                                        );
                                      })}
                                    </Table>
                                  </Box>
                                </Box>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        )}
                    </Fragment>
                  );
                })}
                <Box as="tr">
                  <Box as="td" borderLeftWidth={2} borderColor="gray.100" />
                  <Box as="td" borderWidth={0} />
                  <Box as="td" borderWidth={0} />
                  <Box as="td" borderWidth={0} />
                  <Box as="td" borderWidth={0} />
                  <Box
                    as="td"
                    p={3}
                    borderWidth={2}
                    borderColor="gray.100"
                    width="170px"
                    verticalAlign="middle"
                    textAlign="center"
                    fontWeight="bold"
                  >
                    TOTAL
                  </Box>
                  <Box
                    as="td"
                    p={3}
                    borderWidth={2}
                    borderColor="gray.100"
                    width="170px"
                    verticalAlign="middle"
                    fontWeight="bold"
                  >
                    {totalMesPossiblePerformIndicator.toFixed(2)}
                  </Box>
                  <Box
                    as="td"
                    p={3}
                    borderWidth={2}
                    borderColor="gray.100"
                    width="170px"
                    verticalAlign="middle"
                    fontWeight="bold"
                  >
                    {totalMesFinalPerformIndicator.toFixed(2)}
                  </Box>
                </Box>
                {page > 1 && (
                  <Box as="tr">
                    <Box
                      as="td"
                      borderLeftWidth={2}
                      borderTopWidth={0}
                      borderColor="gray.100"
                    />
                    <Box as="td" borderWidth={0} />
                    <Box as="td" borderWidth={0} />
                    <Box as="td" borderWidth={0} />
                    <Box as="td" borderWidth={0} />
                    <Box
                      as="td"
                      p={3}
                      borderWidth={2}
                      borderColor="gray.100"
                      width="170px"
                      verticalAlign="middle"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      TOTAL ACUMULADO
                    </Box>
                    <Box
                      as="td"
                      p={3}
                      borderWidth={2}
                      borderColor="gray.100"
                      width="170px"
                      verticalAlign="middle"
                      fontWeight="bold"
                    >
                      {pontuacaoPossivelAcumulada}
                    </Box>
                    <Box
                      as="td"
                      p={3}
                      borderWidth={2}
                      borderColor="gray.100"
                      width="170px"
                      verticalAlign="middle"
                      fontWeight="bold"
                    >
                      {pontuacaoFinalAcumulada}
                    </Box>
                  </Box>
                )}
              </Table>
            </Box>
          </Box>
          {/* Navigation Buttons */}
          <>
            {page > 1 && (
              <Box>
                <Tooltip label="Voltar etapa">
                  <IconButton
                    position="absolute"
                    left={0}
                    top="50%"
                    transform="translateY(-50%)"
                    icon={<FiArrowLeft />}
                    aria-label="Voltar período"
                    isRound
                    size="md"
                    onClick={() => handlePaginationModal(-1)}
                  />
                </Tooltip>
              </Box>
            )}
            {!!performIndicator && page < performIndicator.last_page && (
              <Box>
                <Tooltip label="Avançar etapa">
                  <IconButton
                    position="absolute"
                    right={0}
                    top="50%"
                    transform="translateY(-50%)"
                    icon={<FiArrowRight />}
                    aria-label="Avançar período"
                    isRound
                    size="md"
                    onClick={() => handlePaginationModal(+1)}
                  />
                </Tooltip>
              </Box>
            )}
          </>
        </Box>
      )}
    </ModalChakra>
  );
};

export const ModalGeneralIndicators: React.FC<PropsGeneralIndicators> = ({
  id,
  title,
  isOpen,
  workDealId,
  onClose,
  mode,
}) => {
  const [loading, setLoading] = useState(true);
  const [
    performIndicator,
    setPerformIndicator,
  ] = useState<DataPerformIndicatorBSC>();

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Perspectiva',
      },
      {
        title: 'Grupo do Indicador',
      },
      {
        title: 'Indicador',
      },
      {
        title: 'Periodos',
      },
    ];
  }, []);

  function handleIndicators(periodo: string): string {
    switch (periodo) {
      case '1':
        return 'Mensal';
      case '2':
        return 'Bimestral';
      case '3':
        return 'Trimestral';
      case '6':
        return 'Semestral';
      default:
        return `${periodo}° mês`;
    }
  }

  const getPerformIndicator = useCallback((): void => {
    setLoading(true);
    const url =
      mode === 'employee'
        ? `/analytic/bsc/indicators/employee/${id}`
        : `/analytic/bsc/indicators/department/${id}`;

    api
      .get<DataPerformIndicatorBSC>(url, {
        params: {
          work_deal_id: workDealId,
          list_all_indicators: 1,
        },
      })
      .then((response) => {
        const { last_page, total, data } = response.data;

        if (!data.length) return;

        setPerformIndicator({
          ...response.data,
          data: data.map((dt) => {
            return {
              ...dt,
              periodo: handleIndicators(dt.periodo.toString()),
            };
          }),

          last_page,
          total,
        });
      })
      .finally(() => setLoading(false));
  }, [id, mode, workDealId]);

  useEffect(() => {
    if (isOpen) getPerformIndicator();
  }, [getPerformIndicator, isOpen]);

  return (
    <ModalChakra
      title={!loading ? title : 'Carregando...'}
      onClose={onClose}
      isOpen={isOpen}
      size="7xl"
      isLoading={loading}
    >
      {loading ? (
        <Box height="700px" width="auto" px={5}>
          <Loading />
        </Box>
      ) : (
        <Flex alignItems="center">
          <Box
            overflowY="auto"
            overflowX="auto"
            display="block"
            width="100%"
            height="100%"
            h="700px"
            position="relative"
          >
            <Box px="50px">
              <Table theadData={tableTitles} freezeThead>
                {performIndicator?.data?.map((perfIndic) => {
                  return (
                    <Box
                      key={`${perfIndic.indicador_id}.${perfIndic.etapa_acordo_trabalho}`}
                      as="tr"
                      _hover={{ bg: 'gray.50' }}
                    >
                      <Box
                        as="td"
                        p={3}
                        borderWidth={2}
                        borderColor="gray.100"
                        width="170px"
                        verticalAlign="middle"
                      >
                        <Text as="b">{perfIndic.perspectiva_nome}</Text>
                      </Box>
                      <Box
                        as="td"
                        p={3}
                        borderWidth={2}
                        borderColor="gray.100"
                        verticalAlign="middle"
                      >
                        <Tooltip
                          aria-label={perfIndic.grupo_indicador_nome}
                          label={perfIndic.grupo_indicador_nome}
                          zIndex={1}
                        >
                          <Text isTruncated maxW={200}>
                            {perfIndic.grupo_indicador_nome}
                          </Text>
                        </Tooltip>
                      </Box>
                      <Box
                        as="td"
                        p={3}
                        borderWidth={2}
                        borderColor="gray.100"
                        // width="170px"
                        verticalAlign="middle"
                      >
                        <Text>{perfIndic.indicador_nome}</Text>
                      </Box>
                      <Box
                        as="td"
                        p={3}
                        borderWidth={2}
                        borderColor="gray.100"
                        width="170px"
                        verticalAlign="middle"
                      >
                        <Text as="b">{perfIndic.periodo}</Text>
                      </Box>
                    </Box>
                  );
                })}
              </Table>
            </Box>
          </Box>
        </Flex>
      )}
    </ModalChakra>
  );
};

export default ModalIndicatorsStep;
