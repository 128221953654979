import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

export interface Errors {
  [key: string]: string;
}

export default function getValidationErrors(err: ValidationError): Errors {
  const validationErrors: Errors = {};

  err.inner.forEach((error) => {
    validationErrors[error.path] = error.message;
  });

  return validationErrors;
}

/**  Utilizado para validação de formulários que possuem inputs únicos com vários valores que necessitam de apenas uma mensagem.
Para focar o primeiro erro encontrado na ordem que aparece no formulário, é necessário realizar a validação no Yup.object.shape nas mesma
ordem que os inputs são exibidos */
export function getValidationErrorUniqueFields(
  msg: { [key: string]: string },
  idUniqueField: string,
  err: ValidationError,
  ref: React.RefObject<FormHandles>,
): void {
  const validationErrors: Errors = {};

  const pathsUniqueFields = Object.keys(msg);

  err.inner.forEach((error) => {
    const pathIndex = error.path.includes('[')
      ? error.path.slice(0, error.path.indexOf('['))
      : '';

    if (!pathIndex) {
      validationErrors[
        pathsUniqueFields.includes(error.path)
          ? `${error.path}${idUniqueField}`
          : error.path
      ] = error.message;
      return;
    }

    if (!validationErrors[`${pathIndex}${idUniqueField}`]) {
      validationErrors[
        `${pathIndex}${idUniqueField}`
      ] = `${msg[pathIndex]}: ${error.value}`;
    } else {
      validationErrors[`${pathIndex}${idUniqueField}`] += `, ${error.value}`;
    }
  });

  const firstError = document.getElementById(Object.keys(validationErrors)[0]);
  ref?.current?.setErrors(validationErrors);
  firstError?.focus();
}

/* Foca o primeiro elemento com erro, para isso deve possui o id igual ao name
Recomendado para formulários grandes, ou com conteúdo extenso, impossibilitando
o usuário quando enviar, verificar os erros apontados */
export function getValidationErrorWithFocus(err: ValidationError): Errors {
  const validationErrors = getValidationErrors(err);

  if (err.inner.length) {
    const firstError = document.getElementById(err.inner[0].path);
    firstError?.focus();
  }

  return validationErrors;
}
