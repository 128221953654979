import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  Grid,
  Button,
  useDisclosure,
  Badge,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import * as Yup from 'yup';

import { Responsibility } from '~/modules/accessControl/@types/user';
import { ResponsibilityProps } from '~/modules/accessControl/pages/Responsibility';
import InputChakra from '~/shared/components/InputChakra';
import CheckboxInput from '~/shared/components/InputChakra/CheckboxChakra';
import TextareaChakra from '~/shared/components/InputChakra/Textarea';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import {
  Concept as ConceptProps,
  Dimension,
} from '../@types/performanceReview';

interface Params {
  dimensionId: string;
}

const Concept: React.FC = () => {
  const addToast = useToast();

  const formRefCreate = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);
  const formRefDelete = useRef<FormHandles>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();
  const { dimensionId } = useParams<Params>();
  const [dimension, setDimension] = useState<Dimension>();
  const [concept, setConcept] = useState<ConceptProps[]>([]);
  const [selectConcept, setSelectConcept] = useState<ConceptProps>();
  const [responsibilities, setResponsibilities] = useState<Responsibility[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const getConcepts = useCallback(() => {
    setLoading(true);

    api
      .get<Dimension>(`dimensions/${dimensionId}/concepts`)
      .then((response) => {
        const { data } = response;

        setDimension(data);
        setConcept(
          data.dimension_concepts.map((conc) => {
            return {
              ...conc,
              created_at: conc.created_at
                ? format(parseISO(conc.created_at), "dd/MM/yyyy 'às' HH'h'mm")
                : '',
              updated_at: conc.updated_at
                ? format(parseISO(conc.updated_at), "dd/MM/yyyy 'às' HH'h'mm")
                : '',
            };
          }),
        );
      })
      .finally(() => setLoading(false));
  }, [dimensionId]);

  const getResponsibilities = useCallback(() => {
    api.get<Responsibility[]>('responsibilities').then((resp) => {
      const { data } = resp;

      setResponsibilities(data);
      /* data.map((res) => {
          return {
            ...res.data.map((dt) => {
              return {
                ...dt,
                id: dt.id,
                name: dt.name,
              };
            }),
          };
        }),
       */
    });
  }, []);

  useEffect(() => {
    getConcepts();
    getResponsibilities();
  }, [getConcepts, getResponsibilities]);

  const handleCreateConcept = useCallback(
    async (data) => {
      setLoadingCreate(true);
      try {
        formRefEdit.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          responsibilities: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post<ConceptProps>(
          'dimensions/concepts',
          data,
        );
        const { data: resp } = response;

        setConcept((state) => [
          ...state,
          {
            ...resp,
            created_at: resp.created_at
              ? format(parseISO(resp.created_at), "dd/MM/yyyy 'às' HH'h'mm")
              : '',
            updated_at: resp.updated_at
              ? format(parseISO(resp.updated_at), "dd/MM/yyyy 'às' HH'h'mm")
              : '',
            responsibilities: resp.responsibilities,
          },
        ]);

        onCloseCreate();

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Conceito criado!',
          description: 'Conceito cadastrado com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefCreate.current?.setErrors(errors);

          return;
        }
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro ao cadastrar conceito',
          description: error.response.data?.error,
          isClosable: true,
        });
      } finally {
        setLoadingCreate(false);
      }
    },
    [addToast, onCloseCreate],
  );

  const handleEditConcept = useCallback(
    async (data, conceptId) => {
      setLoadingEdit(true);
      try {
        formRefEdit.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          responsibilities: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put<ConceptProps>(
          `dimensions/concepts/${conceptId}`,
          data,
        );
        const { data: resp } = response;

        setConcept((state) =>
          state?.map((conc) => {
            return conc.id === Number(conceptId)
              ? {
                  ...conc,
                  name: resp.name,
                  created_at: resp.created_at
                    ? format(
                        parseISO(resp.created_at),
                        "dd/MM/yyyy 'às' HH'h'mm",
                      )
                    : '',
                  updated_at: resp.updated_at
                    ? format(
                        parseISO(resp.updated_at),
                        "dd/MM/yyyy 'às' HH'h'mm",
                      )
                    : '',
                  responsibilities: resp.responsibilities,
                }
              : conc;
          }),
        );

        onClose();

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Conceito Editado!',
          description: 'Conceito editado com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefEdit.current?.setErrors(errors);

          return;
        }
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro ao editar conceito',
          description: error.response.data?.error,
          isClosable: true,
        });
      } finally {
        setLoadingEdit(false);
      }
    },
    [addToast, onClose],
  );

  const handleDeleteConcept = useCallback(
    (prDimensionId, conceptId) => {
      // console.log(conceptId);
      api.delete(`dimensions/concepts/${conceptId}`, prDimensionId);
      addToast({
        position: 'top-right',
        isClosable: true,
        status: 'success',
        title: 'Conceito deletado!',
        description: 'Conceito deletado com sucesso!',
      });

      setConcept((state) => state.filter((conc) => conc.id !== conceptId));

      onCloseDelete();
    },
    [addToast, onCloseDelete],
  );
  return (
    <Box>
      <SectionHeader
        title="Conceitos"
        pagename="Gerenciar Conceitos"
        goBackPage
      >
        <Flex flexDirection="column" mt={3}>
          <Button
            colorScheme="blue"
            isLoading={loading}
            onClick={() => onOpenCreate()}
          >
            <FiPlus />
            <Text ml={1}>Cadastrar Conceito</Text>
          </Button>
        </Flex>
      </SectionHeader>
      <Box position="relative">
        {loading && <LoadingAbsolute min_height={500} />}
        {!loading && (
          <Flex mb="24px">
            <Text as="b" fontSize="2xl" mr={2}>
              Dimensão:
            </Text>
            <Text fontSize="2xl">{dimension?.name}</Text>
          </Flex>
        )}

        {!loading && !concept.length ? (
          <Alert status="info">
            <AlertIcon />
            Esta dimensão ainda não possui nenhum conceito.
          </Alert>
        ) : (
          <Grid
            gap="50px"
            gridTemplateColumns="repeat(auto-fit, 300px)"
            justifyContent={['center', 'start']}
          >
            {concept?.map((conc) => (
              <Flex
                key={conc.id}
                border="1px solid #E2E8F0"
                rounded="8px"
                boxShadow="xl"
                height="250px"
                background="#ffffff"
                flexDir="column"
                justifyContent="space-between"
              >
                <Flex
                  background="#E2E8F0"
                  justifyContent="space-between"
                  alignItems="center"
                  h="40px"
                  roundedTop="6px"
                >
                  <Text ml={4}>{conc.id}</Text>
                  <Text fontSize="sm" mr={4}>
                    {conc.updated_at}
                  </Text>
                </Flex>

                <Text textAlign="center" px="20px">
                  {conc.name}
                </Text>
                <Flex
                  justifyContent="space-around"
                  borderTop="1px solid #E2E8F0"
                  background="#EDF2F7"
                >
                  <Button
                    height="50px"
                    width="100%"
                    variant="ghost"
                    colorScheme="yellow"
                    color="#000"
                    _hover={{ color: '#B7791F', background: '#FFFFF0' }}
                    onClick={() => {
                      onOpen();
                      setSelectConcept(conc);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    height="50px"
                    width="100%"
                    variant="ghost"
                    colorScheme="red"
                    color="#000"
                    _hover={{ color: '#C53030', background: '#FFF5F5' }}
                    onClick={() => {
                      onOpenDelete();
                      setSelectConcept(conc);
                    }}
                  >
                    Deletar
                  </Button>
                </Flex>
              </Flex>
            ))}
          </Grid>
        )}
        {/* ------------------Create------------------ */}
        <ModalChakra
          onSubmit={() => formRefCreate.current?.submitForm()}
          title="Criar Conceito"
          onClose={onCloseCreate}
          isOpen={isOpenCreate}
          size="2xl"
          isLoading={loadingCreate}
        >
          <Form
            ref={formRefCreate}
            onSubmit={(value) => handleCreateConcept(value)}
          >
            <TextareaChakra
              label="Nome do conceito"
              name="name"
              placeholder="Digite o nome do conceito"
              maxLength={190}
              autoFocus
            />
            <InputChakra
              name="pr_dimension_id"
              type="hidden"
              value={dimensionId}
            />

            <CheckboxInput
              name="responsibilities"
              options={responsibilities?.map((respon) => {
                return {
                  id: respon.id.toString(),
                  value: respon.id.toString(),
                  label: respon.name,
                };
              })}
              enableParentOption
              textParentOption="Marcar todas"
            />
          </Form>
        </ModalChakra>
        {/* ------------------Edit------------------ */}
        <ModalChakra
          onSubmit={() => formRefEdit.current?.submitForm()}
          title="Editar Conceito"
          onClose={onClose}
          isOpen={isOpen}
          size="2xl"
          isLoading={loadingEdit}
        >
          <Form
            ref={formRefEdit}
            onSubmit={(value) => handleEditConcept(value, selectConcept?.id)}
            initialData={{
              responsibilities: selectConcept?.responsibilities?.map((resp) =>
                resp.id.toString(),
              ),
            }}
          >
            <TextareaChakra
              label="Nome do conceito"
              name="name"
              placeholder="Nome"
              maxLength={190}
              autoFocus
              defaultValue={selectConcept?.name}
            />
            <InputChakra
              name="pr_dimension_id"
              type="hidden"
              value={dimensionId}
            />

            <Text mb={4} fontSize="xl">
              Quem pode ser avaliado?
            </Text>
            <CheckboxInput
              name="responsibilities"
              options={responsibilities?.map((respon) => {
                return {
                  id: respon.id.toString(),
                  value: respon.id.toString(),
                  label: respon.name,
                };
              })}
              enableParentOption
              textParentOption="Marcar todas"
            />
          </Form>
        </ModalChakra>

        {/* ------------------Del------------------ */}
        <ModalChakra
          onSubmit={() => formRefDelete.current?.submitForm()}
          title="Apagar Conceito"
          onClose={onCloseDelete}
          isOpen={isOpenDelete}
          size="2xl"
          colorScheme="red"
        >
          <Form
            ref={formRefDelete}
            onSubmit={(value) => handleDeleteConcept(value, selectConcept?.id)}
          >
            <InputChakra
              name="pr_dimension_id"
              type="hidden"
              value={dimensionId}
            />
          </Form>
          <Text>Tem certeza que deseja apagar esse conceito?</Text>
        </ModalChakra>
      </Box>
    </Box>
  );
};

export default Concept;
