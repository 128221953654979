import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

import SelectChakra from '~/shared/components/InputChakra/SelectChakra';

export const Container = styled.ul`
  margin-top: 20px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
    & + li {
      margin-top: 15px;
    }
  }
`;

export const FlexGroupInput = styled(Flex)`
  div.inputs-file {
    @media (min-width: 380px) {
      width: 250px;
    }
    width: 100%;
    height: 32px;
    margin: 0.25rem;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  button {
    border: 0;
    background: transparent;
    color: #e83f5b;
    margin-left: 5px;
    cursor: pointer;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
    }
  }
`;
