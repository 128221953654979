import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {
  useToast,
  Alert,
  AlertIcon,
  Avatar,
  Text,
  Grid,
  Box,
  Flex,
  CloseButton,
  AlertTitle,
} from '@chakra-ui/react';

import { useAuth } from '~/hooks/auth';
import AvatarCustom from '~/shared/components/AvatarCustom';
import api from '~/shared/services/api';

interface UserDTO {
  id: number;
  name: string;
  avatar: string;
}

interface Props {
  handleUser(id: string): void;
  handleUserName(name: string): void;
  handleUserAvatar(avatar: string): void;
  handleDisplayTeamButton(display: boolean): void;
  close(): void;
  title?: string;
  onlyTeam?: boolean;
}

const UserPlanning: React.FC<Props> = ({
  handleUser,
  handleUserName,
  handleUserAvatar,
  handleDisplayTeamButton,
  close,
  title,
  onlyTeam = false,
}) => {
  const { user: userSession } = useAuth();
  const [users, setUsers] = useState<UserDTO[]>([]);
  const addToast = useToast();

  useEffect(() => {
    async function loadUsers(): Promise<void> {
      const url = !onlyTeam ? 'users/all' : 'users/team';

      try {
        const response = await api.get<UserDTO[]>(url);

        setUsers(response.data);
      } catch (err) {
        if (onlyTeam) handleDisplayTeamButton(false);

        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: onlyTeam ? 'info' : 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }
        addToast({
          position: 'top-right',
          isClosable: true,
          status: onlyTeam ? 'info' : 'error',
          title: 'Erro ao carregar funcionários!',
          description: `Não foi possivel listar ${
            onlyTeam
              ? 'você não está em nenhum departamento'
              : 'os funcionários, tente novamente mais tarde!'
          },`,
        });
      }
    }

    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectUser = useCallback(
    (user: UserDTO) => {
      handleUser(String(user.id));
      handleUserName(user.name);
      handleUserAvatar(user.avatar);
      close();
    },
    [handleUser, handleUserName, handleUserAvatar, close],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  return (
    <Flex
      flexDir="column"
      pos="relative"
      mb={30}
      borderWidth="1px"
      rounded="sm"
    >
      <Alert status="info" mb={4}>
        <AlertIcon />
        <AlertTitle mr={2}>
          {title || 'Selecione um funcionário para ver o planejamento'}
        </AlertTitle>
      </Alert>

      <CloseButton onClick={handleClose} pos="absolute" top={1} right={1} />

      {users.length > 0 && (
        <Grid
          justifyContent="space-around"
          templateColumns="repeat(auto-fit, 80px)"
          gap="10px"
          mb={5}
        >
          {users.map((us) => (
            <Box
              key={us.id}
              display="flex"
              flexDir="column"
              alignItems="center"
              cursor="pointer"
            >
              <AvatarCustom
                src={us.avatar}
                name={us.name}
                onClick={() => handleSelectUser(us)}
                ml="auto"
                mr="auto"
              />
              <Box>
                <Text
                  fontSize="xs"
                  maxWidth="60px"
                  textAlign="center"
                  isTruncated
                >
                  {us.name}
                </Text>
              </Box>
            </Box>
          ))}
        </Grid>
      )}
    </Flex>
  );
};

export default UserPlanning;
