import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled.div``;

export const TrBox = styled(Box)`
  border-bottom: 1px solid #f7fafc;
  &.activities td:first-child {
    padding-left: 1.25rem;
    position: relative;

    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 1rem;
      height: 100%;
      background-color: #f7fafc;
    }
  }

  &.activities.sub {
    color: #a0aec0;

    td:first-child {
      padding-left: 2.25rem;
      position: relative;

      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 2rem;
        height: 100%;
        background-color: #f7fafc;
      }
    }
  }

  td {
    padding: 0.25rem auto;
  }

  &.activities:hover {
    background-color: #f7fafc;
  }
`;
