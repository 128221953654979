import { differenceInSeconds, isBefore } from 'date-fns';

export default function DifferenceBetweenDates(
  dateBase: Date,
  date: Date,
): number[] {
  const isDateBefore = isBefore(dateBase, date);

  if (!isDateBefore) {
    return [0, 0, 0, 0];
  }

  const diffSeconds = differenceInSeconds(dateBase, date);

  const days = Math.abs(Math.ceil(diffSeconds / 60 / 60 / 24));
  const hours = Math.abs(Math.ceil((diffSeconds / 60 / 60) % 24));
  const minutes = Math.abs(Math.ceil((diffSeconds / 60) % 60));
  const seconds = Math.abs(Math.ceil(diffSeconds % 60));

  return [days, hours, minutes, seconds];
}
