import { Box, IconButton, Grid } from '@chakra-ui/react';
import styled from 'styled-components';

export const BoxUser = styled(Box)`
  //3 elementos filhos

  //Representa contando do primeiro elemento, o 3º elemento ou posterior para aplicar o estilo
  &:nth-last-child(n + 3) .info-user,
  &:nth-last-child(n + 3) ~ & .info-user {
    max-width: 100px;

    h4:not(.responsibility),
    p:not(.responsibility) {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  /*  //2 elementos filhos

  &:first-child:nth-last-child(2),
  &:first-child:nth-last-child(2) ~ & {
    max-width: '100px';
  }

  //3 elementos filhos

  &:first-child:nth-last-child(3),
  &:first-child:nth-last-child(3) ~ & {
    max-width: '100px';
  } */
`;

export const AlertNoContent = styled(Box)`
  display: flex;
  align-items: center;
  color: #3182ce;
  p {
    margin-left: 0.5rem;
    font-weight: 600;
  }
`;

export const IconButtonUser = styled(IconButton)`
  opacity: 0;
  transition: all 0.2s;
  border-radius: 9999px !important;
  &:hover ~ .avatar-custom {
    background: none;
  }
  &:hover,
  &:active {
    opacity: 0.85;
  }
`;

interface PropsUserButton {
  color:
    | 'success'
    | 'danger'
    | 'info'
    | 'secundary'
    | 'primary'
    | 'notification'
    | 'warning';
}

interface StatusProps {
  isActive?: boolean;
}

export const Content = styled.div`
  h3 {
    padding: 2px 5px;
    border-radius: 5px;
    background: #eee;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 20px;
  }

  & + & {
    border-top: 1px solid #eee;
    padding-top: 20px;
    margin-top: 20px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  grid-gap: 20px;
`;

export const UserCard = styled.div`
  width: auto;
  height: auto;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background: #fbfbfb;
  border-radius: 5px;
  border: 1px solid #eee;

  strong {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
  }
`;

export const UserContent = styled.button<PropsUserButton>`
  width: 70px;
  height: 70px;
  border-radius: 0;
  border: 0;
  margin-right: 8px;
  position: relative;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    border-radius: 5px 0 0 5px;
    transition: all 0.2s;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    background: transparent;
    opacity: 0;
  }

  &:hover {
    > div {
      background: ${({ theme, color }) =>
        theme.colors[color] || theme.colors.success}b8;
      opacity: 1;
    }

    border-color: #eee;
  }

  &:active {
    transform: scale(0.9);
  }
`;

export const UserCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  strong {
    font-weight: 500;
    font-size: 15px;
  }

  small {
    font-weight: 400;
    font-size: 11px;
  }
`;

export const UserStatus = styled.button<StatusProps>`
  position: absolute;
  right: 0;
  top: 0;

  background: ${(props) => (props.isActive ? '#09CC1D' : '#E24A4A')};
  padding: 1px 5px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  border-radius: 0 5px 0 5px;
  border: 0;
  transition: all 0.2s;

  display: flex;
  align-items: center;

  &:active {
    transform: scale(0.9);
  }

  svg {
    margin-right: 5px;
  }
`;
