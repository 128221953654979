import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
`;

export const Title = styled.h3`
  text-align: center;
  font-size: 24px;
  color: #666;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  display: block;

  canvas {
    height: auto !important;
    width: 100% !important;
    max-width: 500px;
    margin: 0 auto;
  }
`;

export const Legend = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  strong {
    font-size: 26px;
    max-width: 180px;
    text-align: center;
    color: ${({ theme }) => theme.colors.title};
  }
`;
