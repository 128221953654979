import React, { useState } from 'react';
import { FiPlus, FiMinus, FiX } from 'react-icons/fi';

import {
  Box,
  Flex,
  FormLabel,
  Switch,
  Text,
  Button,
  IconButton,
} from '@chakra-ui/react';

import Select from '~/shared/components/InputChakra/SelectChakra';

interface Data {
  id?: number; // Para comparar com value
  label: string;
  value: number;
  saved?: boolean;
}

interface MultiSelectProps {
  hasData?: boolean;
  /* Quando compartilhar o componente data e dafaultData devem ter apenas label, value e id simplesmente */
  data: Data[];
  name: string;
  title: string;
  placeholder?: string;
  defaultValue?: Data[];
  toggleQuest?: string;
  noToggle?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  hasData,
  data,
  name,
  title,
  placeholder,
  toggleQuest,
  defaultValue,
  noToggle = false,
}) => {
  const [toggle, setToggle] = useState(!!defaultValue?.length || hasData);
  // Como não atualizaremos esses dados, são apenas para listagem e comparação, não precisamos criar um estado para tal
  const dataSelect: Data[] = data;

  const defaultDataSelect = [
    {
      label: title,
      value: 0,
    },
  ];

  const [selectGroup, setSelectGroup] = useState<Data[]>(
    (!!defaultValue?.length &&
      defaultValue.map((value) => {
        return { ...value, saved: true };
      })) ||
      defaultDataSelect,
  );

  return (
    <Box mb={2}>
      {!noToggle && (
        <Box mb={2}>
          <Flex alignItems="center">
            <FormLabel htmlFor={`set_${name}`}>{toggleQuest}</FormLabel>
            <Switch
              id={`set_${name}`}
              onChange={() => {
                if (toggle) setSelectGroup(defaultDataSelect);

                setToggle(!toggle);
              }}
              defaultIsChecked={toggle}
              size="sm"
              mb={1}
            />
          </Flex>
        </Box>
      )}

      {(noToggle || toggle) && (
        <>
          <Box>
            {selectGroup.map((selectItem, i) => {
              const count = i + 1;
              return (
                <Box key={`${name}${count}`} mb={4}>
                  {/* <Text>{cEmail.label}</Text> */}
                  <Text>
                    {selectItem.label} {count}
                  </Text>
                  <Flex alignItems="center">
                    <Select
                      name={`${name}[${i}]`}
                      isInline
                      options={dataSelect.filter(
                        (em) =>
                          !selectGroup.find((m, index) => {
                            return index !== i && m.value === em.id;
                          }),
                      )}
                      // defaultValue={selectItem?.value}
                      value={selectItem?.value}
                      onChange={(e) =>
                        setSelectGroup((state) =>
                          state.map((emSelect, index) => {
                            return index === i
                              ? {
                                  ...emSelect,
                                  value: Number(e.target.value),
                                }
                              : emSelect;
                          }),
                        )
                      }
                      placeholder={placeholder}
                    />
                    {selectItem.saved && (
                      <IconButton
                        icon={<FiX />}
                        aria-label={`Apagar ${title}`}
                        isRound
                        size="md"
                        variant="ghost"
                        ml={2}
                        onClick={() =>
                          setSelectGroup((state) =>
                            state.filter((mail) => {
                              /* Para atualizar o valor dos selects ao retirar um item específico é necessário utilizar
                              value ao invés de defaultValue prop de Select, pois os itens mantém os names quando retirado
                              exemplo email[2] com o default Value ele mantém o valo que estava definido para esse índice */
                              return (
                                !mail.saved || selectItem.value !== mail.value
                              );
                            }),
                          )
                        }
                      />
                    )}
                  </Flex>
                </Box>
              );
            })}
          </Box>
          <Flex alignItems="center" justifyContent="space-between">
            {!!dataSelect.length && selectGroup.length < dataSelect.length && (
              <Button
                variant="ghost"
                colorScheme="green"
                onClick={() =>
                  setSelectGroup((state) => {
                    return [
                      ...state,
                      {
                        label: title,
                        value: 0,
                      },
                    ];
                  })
                }
              >
                <Flex alignItems="center">
                  <FiPlus />
                  <Text ml={2}>{title}</Text>
                </Flex>
              </Button>
            )}

            {selectGroup.length > 1 && (
              <Button
                variant="ghost"
                colorScheme="red"
                onClick={() =>
                  setSelectGroup((state) =>
                    state.filter((mail, i) => i !== selectGroup.length - 1),
                  )
                }
              >
                <Flex alignItems="center">
                  <FiMinus />
                  <Text ml={2}>{title}</Text>
                </Flex>
              </Button>
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default MultiSelect;
