import { Flex, Box } from '@chakra-ui/react';
import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Card = styled(Flex)`
  justify-content: center;
  // margin: 20px;
  background-color: #fff !important;
  border-radius: 8px;
  background: rgb(238, 245, 255);
  max-width: 500px;
  height: fit-content;
  // max-height: 480px;
  border-bottom: 4px solid transparent;
  transition: all 0.2s;

  &:hover {
    border-color: ${darken(0.05, 'rgb(238, 245, 255)')};
  }
`;

export const ContainerCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 1rem;

  @media (min-width: 1275px) {
    width: 100%;
  }

  @media (max-width: 750px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;
