import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FaBuilding } from 'react-icons/fa';
import { FiPower, FiMenu, FiChevronDown, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Text, Flex, Box, Input } from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';

import logoImg from '~/assets/logo.svg';
import webmail from '~/assets/webmail.png';
import { useAuth } from '~/hooks/auth';
import { useNotification } from '~/hooks/notification';
import { useTimer } from '~/hooks/timer';
import useCan from '~/hooks/useCan';
import api from '~/shared/services/api';

import SelectChakra from '../InputChakra/SelectChakra';
import LoadingAbsolute from '../LoadingAbsolute';
import {
  Container,
  MenuAction,
  Logo,
  Content,
  LeftHeader,
  RightHeader,
} from './styles';

export interface Provider {
  company_id: number;
  company_name: string;
}

interface Props {
  showSidebar: boolean;
  toggleSidebar(toggle: boolean): void;
  companyProvider: Provider[];
}

const Header: React.FC<Props> = ({
  showSidebar,
  toggleSidebar,
  companyProvider,
}) => {
  const refForm = useRef<FormHandles>(null);
  const { signOut, updateUser, user } = useAuth();
  const { reset } = useTimer();
  const can = useCan();
  const { removeNotifications } = useNotification();

  const handleShowSidebar = useCallback(() => {
    if (showSidebar) {
      toggleSidebar(false);
    } else {
      toggleSidebar(true);
    }
  }, [toggleSidebar, showSidebar]);

  const handleLogout = useCallback(() => {
    reset();
    signOut();
    removeNotifications();
  }, [reset, signOut, removeNotifications]);

  const handleProviderCompany = useCallback(
    async (data) => {
      // console.log(data.provider_company_id);
      await updateUser({ ...user, company_id: data.provider_company_id });
      api.defaults.headers.company = data.provider_company_id;

      // console.log('atualizadas as informações');

      window.location.reload();
    },
    [updateUser, user],
  );

  return (
    <Container>
      {/* {loading && <LoadingAbsolute />} */}
      <MenuAction type="button" onClick={handleShowSidebar}>
        {showSidebar ? <FiX size={24} /> : <FiMenu size={24} />}
      </MenuAction>

      <Logo to="/dashboard">
        <img src={logoImg} alt="MyIPAC" />
      </Logo>

      {!can('select-company-provider') ? (
        <Flex display={['none', 'none', 'flex']}>
          <Input
            readOnly
            background="#fff"
            defaultValue={
              companyProvider?.find((comp) => {
                return comp.company_id === user.company_id;
              })?.company_name
            }
          />
        </Flex>
      ) : (
        <>
          <Form
            ref={refForm}
            onSubmit={(value) => handleProviderCompany(value)}
            initialData={{
              provider_company_id: user.company_id?.toString(),
            }}
          >
            <Flex display={['none', 'none', 'flex']}>
              <LeftHeader>
                {/* <Text
                    fontSize="xl"
                    color="#ffffff"
                    mr={4}
                    display={['none', 'flex']}
                  >
                    Empresa:{' '}
                  </Text> */}
                {!!companyProvider.length && (
                  <Box maxWidth="320px">
                    <SelectChakra
                      name="provider_company_id"
                      options={companyProvider.map((comp) => {
                        return {
                          ...comp,
                          value: comp.company_id,
                          label: comp.company_name,
                        };
                      })}
                      background="#fff"
                      mt={5}
                      onChange={() => refForm.current?.submitForm()}
                    />
                  </Box>
                )}
              </LeftHeader>
            </Flex>
          </Form>
        </>
      )}
      <Content>
        <RightHeader>
          <a
            href="https://ipaconline.com.br:2096/"
            target="_blank"
            rel="noreferrer"
            title="Acesse o seu e-mail com o webmail"
          >
            <img src={webmail} alt="logo webmail" />
          </a>
          <button onClick={handleLogout} type="button">
            <FiPower /> <span className="none">Sair do sistema</span>
          </button>
        </RightHeader>
      </Content>
    </Container>
  );
};

export default Header;
