import React, { useState, useEffect, useMemo } from 'react';
import { FiShield, FiPlus, FiMinus, FiInfo } from 'react-icons/fi';

import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

import { useAuth } from '~/hooks/auth';
import AvatarCustom from '~/shared/components/AvatarCustom';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import api from '~/shared/services/api';

import AlertDialog from '../AlertDialog';
import { BoxUser, IconButtonUser, AlertNoContent } from './styles';

interface Props {
  mode?: 'responsibility' | 'department';
  initialAdded?: UserProps[];
  others?: UserProps[];
  title: string;
  // Apenas quando fecharmos o modal, os dados serão atualizados no componente pai
  onClose(user: UpdatedUsers): void;
  isOpen: boolean;
}

interface UpdatedUsers {
  added?: UserProps[];
  removed?: UserProps[];
}

export interface UserProps {
  id: number;
  name: string;
  email: string;
  avatar?: string;
  responsibility_id?: number;
  responsibility?: string;
  department_id?: number;
  department?: string;
  /* Será a flag que nos permitirá, saber se o membro no caso de ter sido removido
  do departamento */
  // originalUser?: boolean;
}

const ModalManageUsers: React.FC<Props> = ({
  mode,
  initialAdded,
  title,
  onClose,
  isOpen,
}) => {
  const [loading, setLoading] = useState(true);

  // const [originalUsers, setOriginalusers] = useState<UserProps[]>([]);
  const [others, setOthers] = useState<UserProps[]>([]);
  const [added, setAdded] = useState<UserProps[]>([]);
  const lenghtGrid = 3;

  const addToast = useToast();

  useEffect(() => {
    // console.log(initialAdded);
    // Apenas o added será modificado, para comparar com o originalUsers
    setAdded(initialAdded || []);
  }, [initialAdded]);

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    setLoading(true);

    api
      .get<UserProps[]>(`users/all`, {
        params: {
          list_all: 1,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        const usersData = response.data;

        /* Os outros funcionários a serem listados atendem uma condição específica
        em relação a departamento, critério ou qualquer outra condição */

        setOthers(
          initialAdded?.length
            ? usersData?.filter(
                (user) =>
                  (mode === 'department' &&
                    !user.department_id &&
                    !initialAdded?.find(
                      (findUserSelected) => !!(findUserSelected.id === user.id),
                    )) ||
                  (mode === 'responsibility' &&
                    !user.responsibility_id &&
                    !initialAdded?.find(
                      (findUserSelected) => !!(findUserSelected.id === user.id),
                    )),
              )
            : usersData?.filter(
                (user) =>
                  (mode === 'department' && !user.department_id) ||
                  (mode === 'responsibility' && !user.responsibility_id),
              ),
        );
      })
      /* .catch(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao carregar funcionários!',
          description:
            'Não foi possivel listar funcionários, tente novamente mais tarde!',
        });
      }) */
      .finally(() => {
        setLoading(false);
      });

    return () => {
      // A mensagem não aparece nesse caso, porque há um catch nessa chamada api, se usassemos o err no catch seria possível exibir
      source.cancel('Cancelado pelo usuário');
    };
  }, [addToast, initialAdded, mode]);

  // const fullAddedGrid = useMemo(() => added.length >= lenghtGrid, [added]);
  // const fullOthersGrid = useMemo(() => others.length >= lenghtGrid, [others]);

  const modeTranslated = (): string => {
    switch (mode) {
      case 'department':
        return 'departamento';
      case 'responsibility':
        return 'cargo';
      default:
        return '';
    }
  };

  const handleAddUser = (user: UserProps): void => {
    setOthers((state) => state.filter((us) => us.id !== user.id));

    setAdded([...added, user]);
  };

  const handleRemoveUser = (user: UserProps): void => {
    setAdded((state) => state.filter((us) => us.id !== user.id));

    setOthers([...others, user]);
  };

  const handleUpdateUsers = (): void => {
    const orignalUsersKeys: number[] = [];
    const addedUsersKeys: number[] = [];

    initialAdded?.forEach((userDep) => {
      orignalUsersKeys.push(userDep.id);
    });

    added.forEach((userDep) => {
      addedUsersKeys.push(userDep.id);
    });

    /* Se for um originalUser, sabemos que foi removido e estamos tentando inseri-lo novamente, mesmo
    que esteja incluso no initialUsers Array */

    const newMembers = added.filter((us) => !orignalUsersKeys?.includes(us.id));
    const removedMembers = initialAdded?.filter(
      (us) => !addedUsersKeys?.includes(us.id),
    );

    const updatdUsers: UpdatedUsers = {
      added: newMembers,
      removed: removedMembers,
    };

    // setUpdatedUsers({ added: newMembers, removed: removedMembers });

    onClose(updatdUsers);

    /* const removedMembers = department.users?.filter(
      (us) => !teste.includes(us.id),
    ); */
  };

  return (
    <Box>
      <ModalChakra
        title={title}
        onClose={handleUpdateUsers}
        isOpen={isOpen}
        isLoading={loading}
        size="2xl"
      >
        <Flex flexDirection="column" width="full">
          <Flex flexDirection="column" width="full" mb={5}>
            <Heading size="sm">
              Funcionários adicionados ({added?.length || 0})
            </Heading>
            <Box
              backgroundColor={added?.length ? '#edfbff' : 'none'}
              padding={4}
              mt={2}
            >
              {!added?.length ? (
                <AlertNoContent alignItems="center">
                  <FiInfo size={18} color="#3182ce" />
                  <Text>
                    O {modeTranslated()} ainda não possui nenhum membro
                  </Text>
                </AlertNoContent>
              ) : (
                <Grid
                  gap="20px"
                  gridTemplateColumns="repeat(auto-fit, minmax(180px, 1fr))"
                  justifyContent="space-between"
                  rounded="sm"
                >
                  {added?.map((user) => {
                    return (
                      <BoxUser
                        bg="white"
                        borderWidth="1px"
                        w="100%"
                        p="10px"
                        rounded="md"
                        position="relative"
                        display="flex"
                        flexDir="row"
                        justifyContent="space-between"
                        borderColor="gray.100"
                        key={`${user.id}-selected`}
                      >
                        <AvatarCustom
                          src={user.avatar}
                          name={user.name}
                          size="md"
                        />
                        <Flex
                          flexDir="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          px={2}
                          className="info-user"
                          width="full"
                        >
                          <Flex
                            flexDir="column"
                            alignItems="flex-start"
                            width="full"
                            // mt="15px"
                          >
                            <Tooltip
                              hasArrow
                              aria-label={user.name}
                              label={user.name}
                            >
                              <Heading
                                // isTruncated={fullAddedGrid}
                                as="h4"
                                size="sm"
                                textAlign="left"
                                width="full"
                              >
                                {user.name}
                              </Heading>
                            </Tooltip>

                            <Tooltip
                              hasArrow
                              aria-label={user.email}
                              label={user.email}
                            >
                              <Text
                                /*   isTruncated={fullAddedGrid}
                                wordBreak={
                                  !fullAddedGrid ? 'break-word' : 'normal'
                                } */
                                color="gray.500"
                                fontSize="xs"
                                width="full"
                              >
                                {user.email}
                              </Text>
                            </Tooltip>
                          </Flex>
                          <Heading
                            d="inline-flex"
                            alignItems="center"
                            mt={1}
                            justifyContent="center"
                            fontSize="xs"
                            color="gray.500"
                            width="full"
                            // whiteSpace="break-spaces"
                          >
                            {mode === 'responsibility' ||
                            user?.responsibility ? (
                              <>
                                <FiShield size={16} />
                                <Text
                                  ml={1}
                                  width="full"
                                  className="responsibility"
                                >
                                  {mode === 'responsibility'
                                    ? title
                                    : user.responsibility}
                                </Text>
                              </>
                            ) : (
                              <Text
                                width="full"
                                color="red.500"
                                className="responsibility"
                              >
                                Sem cargo
                              </Text>
                            )}
                          </Heading>
                        </Flex>
                        <IconButtonUser
                          position="absolute"
                          top="10px"
                          left="10px"
                          aria-label="Remover usuário"
                          title="Remover usuário"
                          icon={<FiMinus />}
                          size="lg"
                          colorScheme="red"
                          onClick={() => {
                            handleRemoveUser(user);
                          }}
                          style={{
                            background: '#F56565',
                          }}
                        />
                      </BoxUser>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </Flex>
          <Flex flexDirection="column" width="full">
            <Heading size="sm">
              Outros ({(!loading && others.length) || 0})
            </Heading>

            {loading ? (
              <Box padding={4}>
                <Loading />
              </Box>
            ) : (
              <Box padding={4}>
                {!others.length ? (
                  <AlertNoContent alignItems="center">
                    <FiInfo size={18} color="#3182ce" />
                    <Text>{`Não há funcionários sem ${modeTranslated()}`}</Text>
                  </AlertNoContent>
                ) : (
                  <Grid
                    gap="20px"
                    gridTemplateColumns="repeat(auto-fit, minmax(180px, 1fr))"
                    justifyContent="space-between"
                    rounded="sm"
                  >
                    {others.map((user) => {
                      return (
                        <BoxUser
                          bg="white"
                          borderWidth="1px"
                          w="100%"
                          p="10px"
                          rounded="md"
                          position="relative"
                          display="flex"
                          flexDir="row"
                          justifyContent="space-between"
                          borderColor="gray.100"
                          key={`${user.id}-noselected`}
                          // opacity={user.responsibility && !user.removed ? 0.5 : 1}
                        >
                          <AvatarCustom
                            src={user.avatar}
                            name={user.name}
                            size="md"
                            className="avatar-custom"
                          />

                          <IconButtonUser
                            /* display={
                            user.responsibility && !user.removed ? 'none' : 'flex'
                            } */
                            position="absolute"
                            top="10px"
                            left="10px"
                            aria-label="Adicionar usuário"
                            title="Adicionar usuário"
                            colorScheme="green"
                            icon={<FiPlus />}
                            size="lg"
                            onClick={() => {
                              handleAddUser(user);
                              // addUser(user);
                            }}
                            style={{
                              background: '#48bb78',
                            }}
                          />

                          <Flex
                            flexDir="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            px={2}
                            width="full"
                            className="info-user"
                          >
                            <Flex
                              flexDir="column"
                              alignItems="flex-start"
                              width="full"
                            >
                              <Tooltip
                                hasArrow
                                aria-label={user.name}
                                label={user.name}
                              >
                                <Heading
                                  as="h4"
                                  size="sm"
                                  textAlign="left"
                                  width="full"
                                >
                                  {user.name}
                                </Heading>
                              </Tooltip>

                              <Tooltip
                                hasArrow
                                aria-label={user.email}
                                label={user.email}
                              >
                                <Text
                                  color="gray.500"
                                  fontSize="xs"
                                  width="full"
                                >
                                  {user.email}
                                </Text>
                              </Tooltip>
                            </Flex>
                            <Heading
                              d="inline-flex"
                              alignItems="center"
                              mt={1}
                              justifyContent="center"
                              fontSize="xs"
                              color="gray.500"
                            >
                              {user?.responsibility ? (
                                <>
                                  <FiShield size={16} />
                                  <Text ml={1} className="responsibility">
                                    {user.responsibility}
                                  </Text>
                                </>
                              ) : (
                                <Text
                                  color="red.500"
                                  className="responsibility"
                                >
                                  Sem cargo
                                </Text>
                              )}
                            </Heading>
                          </Flex>
                        </BoxUser>
                      );
                    })}
                  </Grid>
                )}
              </Box>
            )}
          </Flex>
        </Flex>
      </ModalChakra>
    </Box>
  );
};

export default ModalManageUsers;
