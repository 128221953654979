import React, { useMemo, useEffect, useState } from 'react';
import { FaBuilding } from 'react-icons/fa';
import {
  FiClipboard,
  FiCalendar,
  FiFlag,
  FiLoader,
  FiRefreshCcw,
  FiRepeat,
  FiRotateCcw,
} from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import { Box, Flex, Text, HStack, Skeleton, Tooltip } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';

import {
  Attendance,
  ActivityProps,
} from '~/modules/activityExecution/@types/activity';
import Highlights from '~/shared/components/Highlights';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';

import BadgeStatusInfo from '../../components/BadgeStatusInfo';

interface ActivityFuncProps extends ActivityProps {
  func_name: string;
  status_original_name: string;
  status_color_id: number;
}

interface Data {
  attendance: Attendance;
  activities: ActivityFuncProps[];
}

const ActivitiesAttendance: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [loaded, setLoaded] = useState(false);

  const [activities, setActivities] = useState<ActivityFuncProps[]>([]);
  const [attendance, setAttendance] = useState<Attendance>({} as Attendance);

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Funcionario',
      },
      {
        title: 'Atividade',
      },
      /* {
        title: 'Duração',
      }, */
      {
        title: 'Data Inicio Previsto',
      },
      {
        title: 'Status',
      },
      {
        title: 'Data Término',
      },
    ];
  }, []);

  useEffect(() => {
    api
      .get<Data>(`/attendance/activities/${id}`)
      .then((response) => {
        const { activities: activi, attendance: atten } = response.data;
        // console.log(atten);

        const activitiesOriginal: ActivityFuncProps[] = [];

        activi.forEach((act: any) => {
          activitiesOriginal.push({
            ...act,
            id: act.id_aten_fun,
            duration: act.atividade_duracao,
            situation_id: act.sit_func,
            activity_id: act.ativ_id,
            formatted_start_date_time: format(
              parseISO(`${act.data_inicio_planejado}`),
              'dd/MM/yyyy',
            ),
            formatted_end_date_time:
              act.fim_atendimento &&
              format(parseISO(act.fim_atendimento), "dd/MM/yyyy' às 'HH:mm"),
            start_date:
              act.data_inicio_planejado &&
              format(parseISO(act.data_inicio_planejado), 'dd/MM/yyyy'),
            start_time: act.hora_inicio_planejado,
            returned: act.refeita,
            situation: act.status,
            name: act.atividade,
            func_name: act.nome,
            order_activity: act.ordem,
            status_color_id: act.status_cor_id,
            status_original_name: act.status_nome_original,
          });
        });
        setActivities(activitiesOriginal);
        setAttendance({
          ...atten,
          created_at: format(
            parseISO(atten.created_at),
            "dd/MM/yyyy' às 'HH:mm",
          ),
        });
      })
      .finally(() => setLoaded(true));
  }, [id]);

  return (
    <Box>
      <Box w="full">
        {/* <Flex alignItems="start" flex={1}> */}
        <SectionHeader
          title={attendance.id ? `Atendimento: ${attendance?.id}` : ''}
          pagename="Informações do Atendimento"
          loading={!loaded}
          childrenJustify="flex-start"
        >
          <Text
            display="flex"
            justifyContent="center"
            w="80px"
            ml={2}
            mt="0.3rem"
            shadow="0 2px 5px 0 #00000040"
            background="gray.400"
            color="white"
            borderRadius={10}
          >
            {attendance.competence}
          </Text>
        </SectionHeader>
        {/* </Flex> */}
      </Box>

      <HStack
        spacing={[0, '100px']}
        flexDir={['column', 'row']}
        align={['start', 'center']}
        mb={10}
        pl={4}
      >
        {loaded && !!attendance && (
          <>
            <Highlights
              data={[
                {
                  icon: FiFlag,
                  label: 'Status',
                  children: (
                    <>
                      <Flex w="full" flexDir="column">
                        <Box mb={3}>
                          <BadgeStatusInfo
                            status={attendance?.status?.name}
                            statusColor={attendance?.status?.color?.name}
                            variant="outline"
                            displayFull
                          />
                        </Box>
                        {!!attendance.archived && (
                          <Box w="full">
                            <BadgeStatusInfo
                              status="Arquivado"
                              statusColor="gray"
                              displayFull
                            />
                          </Box>
                        )}
                      </Flex>
                    </>
                  ),
                },
                {
                  icon: FiClipboard,
                  label: 'Demanda:',
                  text: attendance?.demand?.name,
                },
                {
                  icon: FaBuilding,
                  label: 'Empresa:',
                  text: attendance?.company_client?.name,
                },
                {
                  icon: FiCalendar,
                  label: 'Data da Solicitação:',
                  text: attendance?.created_at,
                },
              ]}
            />
          </>
        )}
      </HStack>

      <Table theadData={tableTitles}>
        {loaded &&
          !!activities.length &&
          activities.map((activ) => (
            <Box as="tr" key={activ.id} _hover={{ bg: 'gray.50' }}>
              <Box as="td" py={3} px={4}>
                {activ.func_name}
              </Box>
              <Box as="td" py={3} px={4}>
                <Flex alignItems="center">
                  <Text mr={3}>
                    {activ.order_activity} - {activ.name}
                  </Text>
                  {!!activ.returned && (
                    <Tooltip label="Atividade Devolvida" fontSize="md">
                      <Box>
                        <FiRotateCcw display="inline-block" />
                      </Box>
                    </Tooltip>
                  )}
                </Flex>
              </Box>
              {/*  <Box as="td" py={3} px={4}>
                {activ.duration}
              </Box> */}
              <Box as="td" py={3} px={4}>
                {activ.formatted_start_date_time}
              </Box>
              <Box as="td" py={3} px={4}>
                <Box>
                  <BadgeStatusInfo
                    status={activ.situation}
                    statusColor={activ.status_original_name}
                  />
                </Box>
              </Box>
              <Box as="td" py={3} px={4}>
                {activ.formatted_end_date_time}
              </Box>
            </Box>
          ))}
      </Table>
    </Box>
  );
};

export default ActivitiesAttendance;
