import useCan from '~/hooks/useCan';

type Response = (
  demandId: number,
  attendanceId: number,
  archived?: boolean,
) => void;

type ResponseUrl = (
  demandId: number,
  attendanceId: number,
  archived?: boolean,
) => string;

const useLinkStatusAttendance = (): Response => {
  const can = useCan();
  const showAttendancePerm = can('show-attendance');
  return function getLinkStatusAttendance(
    demandId: number,
    attendanceId: number,
    archived?: boolean,
  ) {
    if (!showAttendancePerm || (showAttendancePerm && archived)) {
      window.open(`/atendimento/atividades/${attendanceId}`, '_blank');
    } else {
      window.open(
        `${process.env.REACT_APP_OLDMYIPAC}/planejamento/index/${demandId}?aten=${attendanceId}&pg=1`,
        '_blank',
      );
    }
  };
};

export function useUrlLinkStatusAttendance(): ResponseUrl {
  const can = useCan();
  const showAttendancePerm = can('show-attendance');

  return function getLink(
    demandId: number,
    attendanceId: number,
    archived?: boolean,
  ) {
    if (!showAttendancePerm || (showAttendancePerm && archived)) {
      return `${window.location.origin}/atendimento/atividades/${attendanceId}`;
    }

    return `${process.env.REACT_APP_OLDMYIPAC}/planejamento/index/${demandId}?aten=${attendanceId}&pg=1`;
  };
}

export default useLinkStatusAttendance;
