import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  Fragment,
} from 'react';
import {
  FaUser,
  FaAddressCard,
  FaEraser,
  FaTrash,
  FaDiagnoses,
  FaCircle,
} from 'react-icons/fa';
import { FiTrash, FiCircle, FiExternalLink } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { inputCSS } from 'react-select/src/components/Input';

import {
  Box,
  Text,
  Flex,
  IconButton,
  Tooltip,
  Button,
  useDisclosure,
  useToast,
  Skeleton,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
  CloseButton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Input } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { format, parseISO, addDays, differenceInDays } from 'date-fns';
import * as Yup from 'yup';

import AlertDialog from '~/shared/components/AlertDialog';
import Highlights from '~/shared/components/Highlights';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import { Icon } from '~/shared/components/Modal/ModalAction/styles';
import SectionHeader from '~/shared/components/SectionHeader';
import Tab from '~/shared/components/Tab';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import { DeletePeriod } from './styles';

interface Params {
  userId?: string;
}

interface ListDataEmployee {
  id: number;
  usuario_nome: string;
  username: string;
  email: string;
  avatar: string;
  departamento: string;
  cargo_id: number;
  cargo_nome: string;
}

interface ListWeekDay {
  id: number;
  sigla: string;
  dia: string;
}

interface ListShiftPeriod {
  periodo_id: number;
  data_inicio: string;
  data_fim?: string;
  diferenca_datas?: number;
  limite_data_atual?: boolean;
  min_date: string;
  max_date?: string;
}

interface ListShiftPeriodCurrent {
  diferenca_datas: number;
  limite_data_atual: boolean;
  periodo_id: number;
  data_inicio: string;
  data_fim: string;
  min_date: string;
  max_date?: string;
}

interface ListShiftPeriodPast {
  periodo_id: number;
  data_inicio: string;
  data_fim: string;
}

interface ListShift {
  primeiro_turno_entrada: string;
  primeiro_turno_saida: string;
  segundo_turno_entrada?: string;
  segundo_turno_saida?: string;
  data_correspondente?: string;
  auto_fill?: boolean;
}

interface ListShiftWeek extends ListShift {
  dia_semana_id?: number | string;
  tempo_disponivel: string;
}

interface DataForm {
  user_id?: string;
  periodo: {
    periodo_id?: number;
    data_inicio: string;
    data_fim: string;
  };
  dia: ListShiftWeek[];
}

interface JourneyWork {
  data_inicio_default: string;
  listDadosFunc: ListDataEmployee;
  list_dia_semana: ListWeekDay[];
  list_turno_periodo: ListShiftPeriod[];
  list_turno_periodo_atual: ListShiftPeriodCurrent;
  // list_turno_periodos_passados: ;
  list_turno_semana: ListShiftWeek[];
  list_turno_periodos_passados: ListShiftPeriodPast[];
}

interface DeleteShift {
  periodo_id: number;
  dia: string;
  dia_id: number;
}
interface PeriodDel {
  periodo_id: number;
  data_inicio: string;
  data_fim?: string;
}

interface InfoNextAttendance {
  msg: string;
  next_attendance: boolean;
}

export const JourneyWork: React.FC = () => {
  const addToast = useToast();
  const cancelRef = useRef(null);
  const companyId: number = api.defaults.headers.company;
  const formRef = useRef<FormHandles>(null);
  const { userId } = useParams<Params>();

  const {
    isOpen: isOpenPeriod,
    onClose: onClosePeriod,
    onOpen: onOpenPeriod,
  } = useDisclosure();
  const {
    isOpen: isOpenShift,
    onClose: onCloseShift,
    onOpen: onOpenShift,
  } = useDisclosure();
  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure();
  const [reloadWorkShift, setReloadWorkShift] = useState(0);
  const [
    selectedPeriod,
    setSelectedPeriod,
  ] = useState<ListShiftPeriod | null>();
  const [loadingShifts, setLoadingShifts] = useState(false);
  const [dataEmployee, setDataEmployee] = useState<ListDataEmployee>();
  const [weekDay, setWeekDay] = useState<ListWeekDay[]>([]);
  const [shiftPeriod, setShiftPeriod] = useState<ListShiftPeriod[]>([]);
  const [shiftPeriodPast, setShiftPeriodPast] = useState<ListShiftPeriodPast[]>(
    [],
  );
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDeletePeriod, setLoadingDeletePeriod] = useState(false);
  const [workShiftDelete, setWorkShiftDelete] = useState<DeleteShift>();
  const [
    shiftPeriodCurrent,
    setShiftPeriodCurrent,
  ] = useState<ListShiftPeriodCurrent>();
  // const [shiftperiodospassados, setShiftPeriodo] = useState<List>();
  const [shiftWeek, setShiftWeek] = useState<ListShiftWeek[]>([]);
  const [hasShiftWeekOriginal, setHasShiftWeekOriginal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [periodDays, setPeriodDays] = useState<string[]>([]);
  const [currentDays, setCurrentDays] = useState<ListShiftWeek[]>([]);
  const [delPeriod, setDelPeriod] = useState<PeriodDel>();

  const [attendance, setAttendance] = useState<InfoNextAttendance>();

  const currentDate = format(new Date(), 'yyyy-MM-dd');

  const timeAvailable = [
    {
      label: '--:--',
      value: '',
    },
    {
      label: '30min',
      value: '00:30:00',
    },
    {
      label: '1h',
      value: '01:00:00',
    },
    {
      label: '1h30min',
      value: '01:30:00',
    },
    {
      label: '2h',
      value: '02:00:00',
    },
  ];

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: '',
      },
      {
        title: '1° Turno Entrada',
      },
      {
        title: '1° Turno Saída',
      },
      {
        title: '2° Turno Entrada',
      },
      {
        title: '2° Turno Saída',
      },
      {
        title: 'Tempo Disponível',
      },
      {
        title: 'Ações',
      },
    ];
  }, []);

  const partTimeMorning = useMemo((): ListShift => {
    return {
      primeiro_turno_entrada: '08:00',
      primeiro_turno_saida: '13:00',
    };
  }, []);

  const partTimeAfternoon = useMemo((): ListShift => {
    return {
      primeiro_turno_entrada: '13:00',
      primeiro_turno_saida: '18:00',
    };
  }, []);

  const fullPeriod = useMemo((): ListShift => {
    return {
      primeiro_turno_entrada: '08:00',
      primeiro_turno_saida: '12:00',
      segundo_turno_entrada: '13:00',
      segundo_turno_saida: '18:00',
    };
  }, []);

  const usedFillShifts = useMemo(
    () => !shiftPeriod.length && !!shiftWeek.length,
    [shiftPeriod, shiftWeek],
  );

  const handleFormatTime = useCallback(
    (date: string, time?: string): string => {
      /* console.log(date, time); */
      return time ? format(parseISO(`${date} ${time}`), 'HH:mm') : '';
    },
    [],
  );

  const handleDateShifts = useCallback(
    async (
      formattedShiftWeek: ListShiftWeek[],
      has_list_turno_periodo: boolean,
      list_turno_periodo_atual?: ListShiftPeriod,
    ) => {
      let startDate: string;
      let endDate: string;
      let dayId: number;
      let validDays: any[] = [];
      const days: string[] = [];

      setCurrentDays([]);

      if (
        (has_list_turno_periodo && list_turno_periodo_atual) ||
        !has_list_turno_periodo
      ) {
        if (formattedShiftWeek.length) {
          if (
            list_turno_periodo_atual?.limite_data_atual &&
            list_turno_periodo_atual?.data_fim
          ) {
            endDate = list_turno_periodo_atual.data_fim;

            setPeriodDays(() => {
              startDate = format(new Date(), 'yyyy-MM-dd');

              while (startDate <= endDate) {
                // console.log(startDate, endDate, startDate <= endDate);
                const dtStartDate = parseISO(startDate);

                dayId = Number(format(dtStartDate, 'i'));
                days[dayId] = format(dtStartDate, 'dd/MM/yy');

                startDate = format(
                  addDays(dtStartDate, 1),
                  'yyyy-MM-dd',
                ).toString();
              }

              return days;
            });
          } else if (
            list_turno_periodo_atual?.diferenca_datas &&
            list_turno_periodo_atual?.data_fim &&
            (list_turno_periodo_atual?.limite_data_atual ||
              list_turno_periodo_atual?.diferenca_datas <= 7)
          ) {
            startDate = list_turno_periodo_atual.data_inicio;

            endDate = list_turno_periodo_atual.data_fim;

            setPeriodDays(() => {
              while (startDate <= endDate) {
                const dtStartDate = parseISO(startDate);

                dayId = Number(format(dtStartDate, 'i'));
                days[dayId] = format(dtStartDate, 'dd/MM/yy');

                startDate = format(
                  addDays(dtStartDate, 1),
                  'yyyy-MM-dd',
                ).toString();
              }

              return days;
            });
          }

          if (days.length) {
            validDays = Object.keys(days);
            setCurrentDays(
              formattedShiftWeek
                .filter((sw) =>
                  validDays.includes(sw.dia_semana_id?.toString()),
                )
                ?.map((sw) => {
                  // console.log(sw);
                  return {
                    ...sw,
                    data_correspondente: days.find(
                      (d, key) => key === sw.dia_semana_id,
                    ),
                  };
                }),
            );
          }
        }
      }
    },
    [],
  );

  useEffect(() => {
    setLoading(true);
    setLoadingShifts(true);
    api
      .get<JourneyWork>(`/journeywork/${userId}`)
      .then((response) => {
        const {
          // data_inicio_default,
          listDadosFunc,
          list_dia_semana,
          list_turno_periodo,
          list_turno_periodo_atual,
          list_turno_semana,
          list_turno_periodos_passados,
        } = response.data;
        setDataEmployee(listDadosFunc);
        // console.log(list_dia_semana);
        setWeekDay(list_dia_semana);
        setShiftPeriodCurrent(list_turno_periodo_atual);
        let selectedPeriodFormatted: ListShiftPeriod;
        if (list_turno_periodo_atual) {
          selectedPeriodFormatted = {
            periodo_id: Number(list_turno_periodo_atual.periodo_id),
            data_inicio: list_turno_periodo_atual.data_inicio,
            data_fim: list_turno_periodo_atual.data_fim,
            diferenca_datas: list_turno_periodo_atual.diferenca_datas,
            limite_data_atual: list_turno_periodo_atual.limite_data_atual,
            min_date: list_turno_periodo_atual.min_date,
          };

          setSelectedPeriod(selectedPeriodFormatted);
        } else {
          setSelectedPeriod(undefined);
        }
        setShiftPeriod(list_turno_periodo);
        setShiftPeriodPast(list_turno_periodos_passados);

        // console.log(list_turno_semana);

        /* passar logica para quando alterar o período nas tabs */
        if (list_turno_periodo_atual) {
          setHasShiftWeekOriginal(!!list_turno_semana.length);

          const formattedShiftWeek: ListShiftWeek[] = list_turno_semana.map(
            (turnoSemana) => {
              return {
                ...turnoSemana,
                primeiro_turno_entrada: handleFormatTime(
                  list_turno_periodo_atual.data_inicio,
                  turnoSemana.primeiro_turno_entrada,
                ),
                primeiro_turno_saida: handleFormatTime(
                  list_turno_periodo_atual.data_inicio,
                  turnoSemana.primeiro_turno_saida,
                ),
                segundo_turno_entrada: handleFormatTime(
                  list_turno_periodo_atual.data_inicio,
                  turnoSemana.segundo_turno_entrada,
                ),
                segundo_turno_saida: handleFormatTime(
                  list_turno_periodo_atual.data_inicio,
                  turnoSemana.segundo_turno_saida,
                ),
              };
            },
          );

          setShiftWeek(formattedShiftWeek);
          handleDateShifts(
            formattedShiftWeek,
            !!list_turno_periodo.length,
            list_turno_periodo_atual,
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setLoadingShifts(false);
      });
  }, [userId, handleFormatTime, reloadWorkShift, handleDateShifts]);

  const showShifts = useCallback(
    (periodId: number) => {
      setLoadingShifts(true);
      /* Setar id selecionado */
      setCurrentDays([]);
      setPeriodDays([]);

      const selectedPeriodTab = shiftPeriod.find(
        (sp) => Number(sp.periodo_id) === periodId,
      );

      setSelectedPeriod(selectedPeriodTab);

      api
        .get<ListShiftWeek[]>(`/journeywork/periods/${periodId}`, {
          params: {
            user_id: userId,
          },
        })
        .then((response) => {
          const { data } = response;

          const formattedShiftWeek: ListShiftWeek[] = data.map(
            (turnoSemana) => {
              // console.log(turnoSemana);
              return selectedPeriodTab
                ? {
                    ...turnoSemana,
                    primeiro_turno_entrada: handleFormatTime(
                      selectedPeriodTab.data_inicio,
                      turnoSemana.primeiro_turno_entrada,
                    ),
                    primeiro_turno_saida: handleFormatTime(
                      selectedPeriodTab.data_inicio,
                      turnoSemana.primeiro_turno_saida,
                    ),
                    segundo_turno_entrada: handleFormatTime(
                      selectedPeriodTab.data_inicio,
                      turnoSemana.segundo_turno_entrada,
                    ),
                    segundo_turno_saida: handleFormatTime(
                      selectedPeriodTab.data_inicio,
                      turnoSemana.segundo_turno_saida,
                    ),
                  }
                : turnoSemana;
            },
          );
          setShiftWeek(formattedShiftWeek);

          if (selectedPeriodTab)
            handleDateShifts(
              formattedShiftWeek,
              !!shiftPeriod.length,
              selectedPeriodTab,
            );
        })
        .catch((err) => {
          addToast({
            position: 'top-right',
            isClosable: true,
            title: 'Erro ao buscar turnos do usuário',
            description: err?.response?.data?.error || '',
          });
        })
        .finally(() => setLoadingShifts(false));
    },
    [userId, addToast, shiftPeriod, handleFormatTime, handleDateShifts],
  );

  const handleSubmit = useCallback(
    async (dataForm: DataForm) => {
      setLoadingSubmit(true);
      setLoadingShifts(true);

      // setShiftPeriod([]);

      // console.log(dataForm);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          periodo: Yup.object().shape({
            data_inicio: Yup.string().required('Data de início obrigatória'),
            data_fim: Yup.string().nullable(),
            // data_fim: Yup.date().min(dataForm.periodo.data_fim).nullable(),
          }),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        dataForm.user_id = userId;

        /* Verificar alterações necessárias na lógica para quando for alterar um período existente (maioria dos casos)
        ou quando for criar (quando não houver nenhum período)
        */
        if (selectedPeriod?.periodo_id) {
          dataForm.periodo.periodo_id = selectedPeriod.periodo_id;

          /* if (
            !selectedPeriod &&
            (dataForm.periodo.data_inicio < selectedPeriod.min_date ||
              dataForm.periodo.data_fim > selectedPeriod?.max_date)
          ) {
            addToast({
              position: 'top-right',
              isClosable: true,
              title: 'Erro ao ',
              description: err?.response?.data?.error || '',
            });
            return;
          } */
        }

        // Retirando dia_semana_id antes de enviar para a rota

        const dataFormPost = {
          ...dataForm,
          dia: dataForm.dia.map((day) => {
            const {
              primeiro_turno_entrada,
              primeiro_turno_saida,
              segundo_turno_entrada,
              segundo_turno_saida,
              tempo_disponivel,
            } = day;
            return {
              primeiro_turno_entrada,
              primeiro_turno_saida,
              segundo_turno_entrada,
              segundo_turno_saida,
              tempo_disponivel,
            };
          }),
        };

        const resp = await api.post(`journeywork`, dataFormPost);

        const { data, msg, nextAttendance } = resp.data;

        const { new_period_id, cloned_period_id } = data;

        setAttendance({
          ...resp.data,
          msg,
          next_attendance: nextAttendance,
        });

        // console.log(nextAttendance);

        /* setShiftPeriod((state) =>
        state.map((sp) => {
          return sp.periodo_id === selectedPeriod?.periodo_id
            ? {
                ...sp,
                data_inicio: dataForm.periodo.data_inicio,
                data_fim: dataForm.periodo.data_fim,
                diferenca_datas: 0,
                limite_data_atual: false,
              }
            : sp;
        }),
      ); */

        // setCurrentDays([]);

        /* setShiftWeek(
        dataFormShifts.filter(
          (dt) => !!dt.primeiro_turno_entrada && !!dt.primeiro_turno_saida,
        ),
      ); */

        // setLoadingShifts(true);

        if (nextAttendance) onOpenAlert();

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Turno Cadastrado!',
          description: `Turnos editados e cadastrados com sucesso`,
          status: 'success',
        });

        const diffDate = dataForm.periodo.data_fim
          ? differenceInDays(
              parseISO(dataForm.periodo.data_fim),
              parseISO(dataForm.periodo.data_inicio),
            )
          : 0;

        // console.log(diffDate);

        /* Obter o id do periodo criado através da rota acima */
        const newPeriod: ListShiftPeriod = {
          ...dataForm.periodo,
          periodo_id: new_period_id ?? selectedPeriod?.periodo_id,
          diferenca_datas: diffDate,
          limite_data_atual: diffDate <= 7,
          min_date: selectedPeriod?.min_date || '',
        };

        if (new_period_id) {
          setShiftPeriod([newPeriod]);
        } else {
          setShiftPeriod((state) =>
            state.map((period) => {
              return period.periodo_id === selectedPeriod?.periodo_id
                ? newPeriod
                : period;
            }),
          );
        }

        if (cloned_period_id) {
          setShiftPeriod((state) => [
            ...state,
            {
              periodo_id: cloned_period_id,
              data_inicio: format(
                addDays(parseISO(newPeriod?.data_fim || ''), 1),
                'yyyy-MM-dd',
              ),
              min_date: selectedPeriod?.min_date || '',
            },
          ]);
        }
        setSelectedPeriod(newPeriod);

        const newShiftWeek: ListShiftWeek[] = dataForm.dia
          .filter(
            (day) => !!day.primeiro_turno_entrada && !!day.primeiro_turno_saida,
          )
          .map((day) => {
            return {
              ...day,
              dia_semana_id: Number(day.dia_semana_id),
            };
          });
        await handleDateShifts(newShiftWeek, true, newPeriod);

        setShiftWeek(newShiftWeek);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        // console.log('deu erro');

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao tentar cadastrar turno',
          description: err?.response?.data?.error || '',
        });
      } finally {
        setLoadingSubmit(false);
        setLoadingShifts(false);
      }
    },
    [addToast, handleDateShifts, selectedPeriod, userId, onOpenAlert],
  );

  function handleFillShifts(ls: ListShift): void {
    let i = 1;

    setShiftWeek([]);

    for (i; i <= 5; i += 1) {
      const dayId = i;

      setShiftWeek((state) => [
        ...state,
        {
          ...ls,
          dia_semana_id: dayId,
          segundo_turno_entrada:
            ls === fullPeriod && dayId === 5
              ? '14:00'
              : ls.segundo_turno_entrada,
          tempo_disponivel: '',
          auto_fill: true,
        },
      ]);
    }
  }

  async function deleteShift(): Promise<void> {
    setLoadingDelete(true);
    try {
      await api.delete<JourneyWork>(
        `journeywork/deleteShifts/${workShiftDelete?.periodo_id}`,
        {
          params: {
            user_id: dataEmployee?.id,
            day_id: workShiftDelete?.dia_id,
          },
        },
      );

      setShiftWeek((state) =>
        state?.filter((sw) => sw.dia_semana_id !== workShiftDelete?.dia_id),
      );

      if (workShiftDelete) {
        formRef.current?.clearField(
          `dia[${workShiftDelete.dia_id - 1}][primeiro_turno_entrada]`,
        );
        formRef.current?.clearField(
          `dia[${workShiftDelete.dia_id - 1}][primeiro_turno_saida]`,
        );
        formRef.current?.clearField(
          `dia[${workShiftDelete.dia_id - 1}][segundo_turno_entrada]`,
        );
        formRef.current?.clearField(
          `dia[${workShiftDelete.dia_id - 1}][segundo_turno_saida]`,
        );

        formRef.current?.setFieldValue(
          `dia[${workShiftDelete.dia_id - 1}][tempo_disponivel]`,
          '',
        );
      }

      // console.log(shiftWeek, newShiftWeek);

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Turno Apagado!',
        description: `O turno de ${workShiftDelete?.dia} foi apagado com sucesso!`,
        status: 'success',
      });

      if (shiftWeek?.length === 1) {
        setReloadWorkShift((state) => state + 1);
      }
    } catch (err) {
      // console.log(err);
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Não foi possível apagar o turno',
        description: err.response.data?.error || 'Tente novamente, mais tarde',
        status: 'error',
      });
    } finally {
      setLoadingDelete(false);
      onCloseShift();
    }
  }

  /*   const handleMinMaxDate = useCallback(() => {
    if (shiftPeriodCurrent) {
      foreac
    }
  }, [shiftPeriodCurrent]); */

  async function deletePeriod(): Promise<void> {
    try {
      setLoadingDeletePeriod(true);

      // await sleep(3000);

      await api.delete<JourneyWork>(`/journeywork/${delPeriod?.periodo_id}`, {
        params: {
          user_id: dataEmployee?.id,
        },
      });

      setReloadWorkShift((state) => state + 1);

      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Período Apagado!',
        description: `Período deletado com sucesso!`,
        status: 'success',
      });
      setShiftWeek([]);
    } catch (err) {
      // console.log(err);
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Não foi possível apagar o período',
        description: err.response.data?.error || 'Tente novamente, mais tarde',
        status: 'error',
      });
    } finally {
      setLoadingDeletePeriod(false);
      onClosePeriod();
    }
  }

  return (
    <Box pos="relative">
      {loading && <LoadingAbsolute z_index={1111} min_height={500} />}

      <SectionHeader
        title="Turnos de Trabalho"
        pagename="Registrar os turnos de trabalho do funcionário"
        goBackPage
      />

      {!loading && dataEmployee && (
        <>
          <Highlights
            data={[
              {
                icon: FaUser,
                label: 'Funcionário',
                text: dataEmployee.usuario_nome,
              },
              {
                icon: FaAddressCard,
                label: 'Cargo',
                text: dataEmployee.cargo_nome,
              },
            ]}
          />

          {attendance?.next_attendance && isOpenAlert && (
            <>
              <Alert
                status="info"
                marginTop="10px"
                marginBottom="20px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex">
                  <AlertIcon />

                  <a
                    href={`${process.env.REACT_APP_OLDMYIPAC}/historico-replanejamentos/index?status=*`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    <Flex alignItems="center">
                      {attendance?.msg}
                      <Box ml="5px">
                        <FiExternalLink />
                      </Box>
                    </Flex>
                  </a>
                </Box>

                <Box>
                  <CloseButton
                    alignSelf="flex-start"
                    position="relative"
                    onClick={onCloseAlert}
                  />
                </Box>
              </Alert>
            </>
          )}

          <Form ref={formRef} onSubmit={handleSubmit}>
            {companyId === 1 && !!shiftPeriod.length && (
              <Text>Período Selecionado</Text>
            )}

            <Flex>
              <Box>
                <Tab
                  defaultIndex={selectedPeriod || !shiftPeriod.length ? 0 : -1}
                  onClickTab={(periodId) => periodId && showShifts(periodId)}
                  data={
                    shiftPeriod.length
                      ? shiftPeriod.map((period) => {
                          return {
                            id: period.periodo_id,
                            title: `${
                              period
                                ? format(
                                    parseISO(period?.data_inicio || ''),
                                    'dd/MM/yyyy',
                                  )
                                : format(new Date(), 'dd/MM/yyyy')
                            } ${
                              period?.data_fim
                                ? ` até ${format(
                                    parseISO(period?.data_fim || ''),
                                    'dd/MM/yyyy',
                                  )}`
                                : ' até indeterminado'
                            }`,
                            content:
                              selectedPeriod?.periodo_id ===
                              period.periodo_id ? (
                                <Flex alignItems="center">
                                  <Flex width="700px" alignItems="center">
                                    <InputChakra
                                      background="#e9ecef"
                                      borderColor=""
                                      name="periodo[data_inicio]"
                                      isInline
                                      type="date"
                                      defaultValue={
                                        period?.data_inicio ||
                                        format(new Date(), 'yyyy-MM-dd')
                                      }
                                      min={period.min_date}
                                      max={period?.max_date}
                                    />
                                    <Text mx={4}>até</Text>
                                    <InputChakra
                                      isInline
                                      background="#e9ecef"
                                      borderColor=""
                                      name="periodo[data_fim]"
                                      type="date"
                                      defaultValue={period?.data_fim}
                                      min={period.min_date}
                                      max={period?.max_date}
                                    />
                                  </Flex>
                                  {!!shiftPeriod.length &&
                                    shiftPeriod[shiftPeriod.length - 1]
                                      ?.periodo_id ===
                                      selectedPeriod?.periodo_id &&
                                    !loadingShifts && (
                                      <Tooltip
                                        aria-label="Apagar Período"
                                        label="Apagar Período"
                                      >
                                        <IconButton
                                          aria-label="Apagar Período"
                                          icon={<FaTrash />}
                                          colorScheme="red"
                                          onClick={() => {
                                            setDelPeriod({
                                              periodo_id: Number(
                                                selectedPeriod.periodo_id,
                                              ),
                                              data_inicio:
                                                selectedPeriod.data_inicio,
                                              data_fim: selectedPeriod.data_fim,
                                            });
                                            onOpenPeriod();
                                          }}
                                          ml={4}
                                          isLoading={loadingDeletePeriod}
                                        />
                                      </Tooltip>
                                    )}
                                </Flex>
                              ) : (
                                <Flex width="700px" alignItems="center">
                                  <Skeleton height="48px" width="full" />
                                  <Text mx={4}>até</Text>
                                  <Skeleton height="48px" width="full" />
                                </Flex>
                              ),
                          };
                        })
                      : [
                          {
                            id: 0,
                            title: `${format(
                              new Date(),
                              'dd/MM/yyyy',
                            )} até indeterminado`,
                            content: (
                              <Flex width="700px" alignItems="center">
                                <InputChakra
                                  background="#e9ecef"
                                  borderColor=""
                                  name="periodo[data_inicio]"
                                  type="date"
                                  defaultValue={format(
                                    new Date(),
                                    'yyyy-MM-dd',
                                  )}
                                  min={format(new Date(), 'yyyy-MM-dd')}
                                />
                                <Text mb={4} mx={4}>
                                  até
                                </Text>
                                <InputChakra
                                  background="#e9ecef"
                                  borderColor=""
                                  name="periodo[data_fim]"
                                  type="date"
                                  min={selectedPeriod?.max_date}
                                />
                              </Flex>
                            ),
                          },
                        ]
                  }
                  tooltip
                  variant="soft-rounded"
                />
              </Box>
            </Flex>

            {companyId === 1 && !shiftPeriod.length && (
              <HStack>
                <Tooltip
                  aria-label="08:00 às 18:00"
                  label="Período com turnos de segunda a quinta com duração de 9 horas, e sexta com duração de 8 horas."
                >
                  <Button
                    marginBottom="1rem"
                    variant="outline"
                    colorScheme="gray"
                    color="black"
                    onClick={() => {
                      handleFillShifts(fullPeriod);
                    }}
                  >
                    Período completo
                  </Button>
                </Tooltip>

                <Tooltip
                  aria-label="08:00 às 13:00"
                  label="Meio período de manhã com duração de 5 horas"
                >
                  <Button
                    marginBottom="1rem"
                    variant="outline"
                    colorScheme="gray"
                    color="black"
                    onClick={() => {
                      handleFillShifts(partTimeMorning);
                    }}
                  >
                    Estágio meio período manhã
                  </Button>
                </Tooltip>

                <Tooltip
                  aria-label="13:00 às 18:00"
                  label="Meio período de tarde com duração de 5 horas"
                >
                  <Button
                    marginBottom="1rem"
                    variant="outline"
                    colorScheme="gray"
                    color="black"
                    onClick={() => {
                      handleFillShifts(partTimeAfternoon);
                    }}
                  >
                    Estágio meio período a tarde
                  </Button>
                </Tooltip>
              </HStack>
            )}
            {selectedPeriod || !shiftPeriod.length ? (
              <>
                <Table theadData={tableTitles}>
                  {weekDay.map((diasSemana, index) => {
                    const turno = shiftWeek?.find(
                      (sw) =>
                        Number(sw.dia_semana_id) === Number(diasSemana.id),
                    );

                    const matchDate = currentDays?.find(
                      (cd) => cd.dia_semana_id === turno?.dia_semana_id,
                    )?.data_correspondente;

                    return (
                      <Box
                        as="tr"
                        key={`${diasSemana.id}.${diasSemana.sigla}`}
                        _hover={{ bg: 'gray.50' }}
                      >
                        <Box as="td" py={3} px={4}>
                          <Text as="b" color="#000">
                            <Flex marginLeft="20px">{diasSemana.sigla}</Flex>

                            {matchDate && !loadingShifts && turno && (
                              <>
                                {currentDays?.some(
                                  (cd) =>
                                    (selectedPeriod &&
                                      selectedPeriod.limite_data_atual &&
                                      cd.data_correspondente === currentDate) ||
                                    cd.data_correspondente ===
                                      selectedPeriod?.data_inicio,
                                )}
                                {matchDate}
                              </>
                            )}
                          </Text>
                        </Box>
                        {turno ? (
                          <>
                            <Box as="td" py={3} px={4}>
                              <InputChakra
                                name={`dia[${index}][dia_semana_id]`}
                                type="hidden"
                                isInline
                                value={turno.dia_semana_id}
                              />
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <InputChakra
                                  name={`dia[${index}][primeiro_turno_entrada]`}
                                  type="time"
                                  defaultValue={turno.primeiro_turno_entrada}
                                  /* defaultValue={turno.primeiro_turno_entrada} */
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <InputChakra
                                  name={`dia[${index}][primeiro_turno_saida]`}
                                  type="time"
                                  defaultValue={turno.primeiro_turno_saida}
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <InputChakra
                                  name={`dia[${index}][segundo_turno_entrada]`}
                                  type="time"
                                  defaultValue={turno.segundo_turno_entrada}
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <InputChakra
                                  name={`dia[${index}][segundo_turno_saida]`}
                                  type="time"
                                  defaultValue={turno.segundo_turno_saida}
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <SelectChakra
                                  name={`dia[${index}][tempo_disponivel]`}
                                  options={timeAvailable}
                                  defaultValue={turno.tempo_disponivel}
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts ? (
                                <Skeleton height="40px" width="40px" mb="4" />
                              ) : (
                                <>
                                  {!turno.auto_fill && (
                                    <Tooltip aria-label="Apagar" label="Apagar">
                                      <IconButton
                                        aria-label="apagar"
                                        icon={<FaEraser />}
                                        colorScheme="red"
                                        onClick={() => {
                                          setWorkShiftDelete({
                                            periodo_id: Number(
                                              selectedPeriod?.periodo_id,
                                            ),
                                            dia: diasSemana.dia,
                                            dia_id: diasSemana.id,
                                          });
                                          onOpenShift();
                                        }}
                                        mb={4}
                                      />
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box as="td" py={3} px={4}>
                              <InputChakra
                                name={`dia[${index}][dia_semana_id]`}
                                isInline
                                type="hidden"
                                value={diasSemana.id}
                              />
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <InputChakra
                                  name={`dia[${index}][primeiro_turno_entrada]`}
                                  type="time"
                                  defaultValue=""
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <InputChakra
                                  name={`dia[${index}][primeiro_turno_saida]`}
                                  type="time"
                                  defaultValue=""
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <InputChakra
                                  name={`dia[${index}][segundo_turno_entrada]`}
                                  type="time"
                                  defaultValue=""
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <InputChakra
                                  name={`dia[${index}][segundo_turno_saida]`}
                                  type="time"
                                  defaultValue=""
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts ? (
                                <Skeleton mb={4} height="48px" width="full" />
                              ) : (
                                <SelectChakra
                                  name={`dia[${index}][tempo_disponivel]`}
                                  options={timeAvailable}
                                />
                              )}
                            </Box>
                            <Box as="td" py={3} px={4}>
                              {loadingShifts && (
                                <Skeleton height="40px" width="40px" />
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    );
                  })}
                </Table>

                <Flex justifyContent="end">
                  <Button
                    m={4}
                    colorScheme="green"
                    width="150px"
                    height="50px"
                    onClick={() => {
                      formRef.current?.submitForm();
                    }}
                    isLoading={loadingSubmit}
                    isDisabled={loadingSubmit}
                  >
                    Salvar
                  </Button>
                  <Button
                    m={4}
                    colorScheme="red"
                    width="150px"
                    height="50px"
                    type={usedFillShifts ? 'button' : 'reset'}
                    onClick={() => usedFillShifts && setShiftWeek([])}
                  >
                    Desfazer
                  </Button>
                </Flex>
              </>
            ) : (
              !selectedPeriod &&
              !!shiftPeriod.length && (
                <Alert status="info" marginTop="30px">
                  <AlertIcon />
                  <Flex flexDirection="column">
                    <Text fontSize="18px">
                      Não foi encontrado nenhum período para a data atual ou
                      nenhum período foi selecionado.
                    </Text>
                    Selecione um período para visualizar e poder fazer
                    modificações.
                  </Flex>
                </Alert>
              )
            )}
          </Form>
        </>
      )}
      <AlertDialog
        title={`Apagar o turno de ${workShiftDelete?.dia}?`}
        description={`Deseja apagar o turno registrado para ${dataEmployee?.usuario_nome} de ${workShiftDelete?.dia}`}
        isOpen={isOpenShift}
        onClose={onCloseShift}
        onSubmit={() => deleteShift()}
        isLoading={loadingDelete}
        leastDestructiveRef={cancelRef}
      />
      {delPeriod && (
        <AlertDialog
          title="Confirmar"
          description={`Deseja apagar o período de ${format(
            parseISO(delPeriod.data_inicio),
            'dd/MM/yy',
          )} até indeterminado e todos os turnos relacionados ?`}
          isOpen={isOpenPeriod}
          onClose={onClosePeriod}
          onSubmit={() => deletePeriod()}
          isLoading={loadingDeletePeriod}
          leastDestructiveRef={cancelRef}
        />
      )}
    </Box>
  );
};
