import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { FiEye, FiPlus, FiEdit, FiTrash } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';

import useCan from '~/hooks/useCan';
import InputChakra from '~/shared/components/InputChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

interface DimensionProps {
  id: number;
  company_id: number;
  start_date: string;
  start_date_formated?: string;
  end_date: string;
  end_date_formated?: string;
  name: string;
  created_at: string;
  created_at_formated?: string;
  updated_at: string;
  updated_at_formated?: string;
}

const Dimension: React.FC = () => {
  const history = useHistory();
  const addToast = useToast();
  const formRefCreate = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);
  const formRefDelete = useRef<FormHandles>(null);

  const can = useCan();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const [dimensions, setDimensions] = useState<DimensionProps[]>([]);
  const [
    selectDimensionEdit,
    setSelectDimensionEdit,
  ] = useState<DimensionProps>();
  const [selectDimensionDelete, setSelectDimensionDelete] = useState<Number>();
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Id',
      },
      {
        title: 'Nome',
      },
      {
        title: 'Data Inicio',
      },
      {
        title: 'Data Fim',
      },
      {
        title: 'Data de Atualização',
      },
      {
        title: 'Visualiza Conceito',
      },
    ];
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get<DimensionProps[]>('dimensions')
      .then((response) => {
        // console.log(response.data);
        const { data } = response;
        setDimensions(
          data.map((dimen) => {
            return {
              ...dimen,
              start_date_formated: dimen.start_date
                ? format(parseISO(dimen.start_date), "dd/MM/yyyy 'às' HH'h'mm")
                : '',
              end_date_formated: dimen.end_date
                ? format(parseISO(dimen.end_date), "dd/MM/yyyy 'às' HH'h'mm")
                : '',
              created_at_formated: dimen.created_at
                ? format(parseISO(dimen.created_at), "dd/MM/yyyy 'às' HH'h'mm")
                : '',
              updated_at_formated: dimen.updated_at
                ? format(parseISO(dimen.updated_at), "dd/MM/yyyy 'às' HH'h'mm")
                : '',
            };
          }),
        );
      })
      .finally(() => setLoading(false));
  }, []);

  const handleConceptList = (dimensionId: number): void => {
    history.push({
      pathname: `dimensao/${dimensionId}/conceito`,
    });
  };

  const handleCreateDimension = useCallback(
    async (data) => {
      setLoadingCreate(true);
      try {
        formRefCreate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          start_date: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post<DimensionProps>('dimensions', data);
        const { data: dimen } = response;

        setDimensions((state) => [
          ...state,
          {
            ...dimen,
            start_date_formated: format(
              parseISO(dimen.start_date),
              "dd/MM/yyyy 'às' HH'h'mm",
            ),
            end_date_formated: dimen.end_date
              ? format(parseISO(dimen.end_date), "dd/MM/yyyy 'às' HH'h'mm")
              : '',
            created_at_formated: dimen.created_at
              ? format(parseISO(dimen.created_at), "dd/MM/yyyy 'às' HH'h'mm")
              : '',
            updated_at_formated: dimen.updated_at
              ? format(parseISO(dimen.updated_at), "dd/MM/yyyy 'às' HH'h'mm")
              : '',
          },
        ]);
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Dimensão criada!',
          description: 'Dimensão cadastrada com sucesso!',
        });

        formRefCreate.current?.reset();

        onCloseCreate();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefCreate.current?.setErrors(errors);

          return;
        }
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro ao cadastrar dimensão',
          description: err.response.data?.error,
          isClosable: true,
        });
      } finally {
        setLoadingCreate(false);
      }
    },
    [addToast, onCloseCreate],
  );

  const handleEditDimension = useCallback(
    async (dataDimen, dimension) => {
      setLoadingEdit(true);
      try {
        formRefEdit.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          start_date: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(dataDimen, {
          abortEarly: false,
        });

        const response = await api.put<DimensionProps>(
          `dimensions/${dimension.id}`,
          dataDimen,
        );
        const { data } = response;

        setDimensions((state) =>
          state?.map((dimen) => {
            return dimen.id === Number(dimension.id)
              ? {
                  ...dimen,
                  name: data.name,
                  start_date: data.start_date
                    ? format(
                        parseISO(data.start_date),
                        "dd/MM/yyyy 'às' HH'h'mm",
                      )
                    : '',
                  end_date: data.end_date
                    ? format(parseISO(data.end_date), "dd/MM/yyyy 'às' HH'h'mm")
                    : '',
                  created_at: data.created_at
                    ? format(
                        parseISO(data.created_at),
                        "dd/MM/yyyy 'às' HH'h'mm",
                      )
                    : '',
                  updated_at: data.updated_at
                    ? format(
                        parseISO(data.updated_at),
                        "dd/MM/yyyy 'às' HH'h'mm",
                      )
                    : '',
                }
              : dimen;
          }),
        );
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Dimensão editada!',
          description: 'Dimensão alterada com sucesso!',
        });
        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current?.setErrors(errors);

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro ao editar dimensão',
          description: err.response.data?.error,
          isClosable: true,
        });
      } finally {
        setLoadingEdit(false);
      }

      // .finally(() => onClose());
    },
    [addToast, onClose],
  );

  const handleDeleteDimension = useCallback(
    async (dimensionId) => {
      try {
        await api.delete(`dimensions/${dimensionId}`);
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Dimensão deletada!',
          description: 'Dimensão deletada com sucesso!',
        });

        setDimensions((state) =>
          state.filter((dimen) => dimen.id !== dimensionId),
        );
      } catch (err) {
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro ao deletar dimensão',
          description:
            'Verifique se algum conceito relacionado já está sendo ultilizado',
          isClosable: true,
        });
      } finally {
        onCloseDelete();
      }
    },
    [addToast, onCloseDelete],
  );

  return (
    <Box>
      <SectionHeader
        title="Dimensões"
        pagename="Gerenciar Dimensões"
        goBackPage
      >
        {can('create-dimension-gdp') && (
          <Flex flexDirection="column" mt={3}>
            <Button colorScheme="blue" onClick={() => onOpenCreate()}>
              <FiPlus />
              <Text ml={1}>Cadastrar Dimensão</Text>
            </Button>
          </Flex>
        )}
      </SectionHeader>
      {loading && <LoadingAbsolute />}
      <Table theadData={tableTitles}>
        {dimensions.map((dim) => (
          <Box as="tr" _hover={{ bg: 'gray.50' }} key={dim.id}>
            <Box
              as="td"
              py={1}
              textAlign="center"
              fontWeight="bold"
              width="30px"
            >
              {dim.id}
            </Box>
            <Box as="td" py={1}>
              <Text>{dim.name}</Text>
            </Box>
            <Box as="td" py={1}>
              <Text>{dim.start_date_formated}</Text>
            </Box>
            <Box as="td" py={1}>
              <Text>{dim.end_date_formated}</Text>
            </Box>
            <Box as="td" py={1}>
              <Text>{dim.updated_at_formated}</Text>
            </Box>
            <Box as="td" py={1}>
              <Flex>
                <IconButton
                  aria-label="icone de visualizar"
                  icon={<FiEye />}
                  colorScheme="blue"
                  variant="outline"
                  mx={4}
                  onClick={() => handleConceptList(dim.id)}
                />
                <IconButton
                  aria-label="icone de editar"
                  icon={<FiEdit />}
                  colorScheme="yellow"
                  variant="outline"
                  mr={4}
                  onClick={() => {
                    onOpen();
                    setSelectDimensionEdit(dim);
                  }}
                />
                <IconButton
                  aria-label="icone de apagar"
                  icon={<FiTrash />}
                  colorScheme="red"
                  variant="outline"
                  onClick={() => {
                    onOpenDelete();
                    setSelectDimensionDelete(dim.id);
                  }}
                />
              </Flex>
            </Box>
          </Box>
        ))}
      </Table>
      {/* ------------------Create------------------ */}
      <ModalChakra
        onSubmit={() => formRefCreate.current?.submitForm()}
        title="Novo Conceito"
        onClose={onCloseCreate}
        isOpen={isOpenCreate}
        size="2xl"
        isLoading={loadingCreate}
      >
        <Form
          ref={formRefCreate}
          onSubmit={(value) => handleCreateDimension(value)}
        >
          <InputChakra
            label="Nome do conceito"
            name="name"
            type="text"
            placeholder="Nome"
            // icon={FiShield}
            autoFocus
          />
          <InputChakra
            label="Data inicio"
            name="start_date"
            type="datetime-local"
            autoFocus
          />
          <InputChakra
            label="Data fim (não obrigatorio)"
            name="end_date"
            type="datetime-local"
            autoFocus
          />
        </Form>
      </ModalChakra>
      {/* ------------------Edit------------------ */}
      <ModalChakra
        title="Editar Conceito"
        onSubmit={() => formRefEdit.current?.submitForm()}
        onClose={onClose}
        isOpen={isOpen}
        size="2xl"
        isLoading={loadingEdit}
      >
        <Form
          ref={formRefEdit}
          onSubmit={(value) => {
            handleEditDimension(value, selectDimensionEdit);
          }}
        >
          <InputChakra
            label="Nome do conceito"
            name="name"
            type="text"
            placeholder="Nome"
            defaultValue={selectDimensionEdit?.name}
            autoFocus
          />
          <InputChakra
            label="Data inicio"
            name="start_date"
            type="datetime-local"
            defaultValue={selectDimensionEdit?.start_date}
            autoFocus
          />
          <InputChakra
            label="Data fim (não obrigatorio)"
            name="end_date"
            type="datetime-local"
            defaultValue={selectDimensionEdit?.end_date}
            autoFocus
          />
        </Form>
      </ModalChakra>

      {/* ------------------Del------------------ */}
      <ModalChakra
        onSubmit={() => formRefDelete.current?.submitForm()}
        title="Apagar Dimensão!"
        onClose={onCloseDelete}
        isOpen={isOpenDelete}
        size="2xl"
        colorScheme="red"
      >
        <Form
          ref={formRefDelete}
          onSubmit={() => handleDeleteDimension(selectDimensionDelete)}
        >
          <Text>Tem certeza que deseja apagar essa dimensão?</Text>
        </Form>
      </ModalChakra>
    </Box>
  );
};

export default Dimension;
