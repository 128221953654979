import React, { useMemo, useEffect } from 'react';
import {
  FaStopCircle,
  FaCheckCircle,
  FaTimesCircle,
  FaPlayCircle,
  FaUserSecret,
} from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';

import { Box, Tooltip, Text, Flex } from '@chakra-ui/react';

export interface StatusAttendance {
  status: string;
  status_id: number;
  attendance_id: number;
  archived?: boolean;
}

interface BadgeStatus {
  color: string;
  icon: React.ReactElement;
  name: string;
}

export interface Props {
  attendance: StatusAttendance;
  showCodeAttendance?: boolean;
  showIconExternalLink?: boolean;
  onlyIcon?: boolean;
  variant?: 'solid' | 'statusHighlighted';
}

const BadgeStatusAttendance: React.FC<Props> = ({
  attendance,
  showCodeAttendance = false,
  showIconExternalLink = false,
  onlyIcon = false,
  variant = 'solid',
}) => {
  /* useEffect(() => console.log(attendance)); */

  const bgStatus = useMemo<BadgeStatus>(() => {
    if (attendance.status === 'Pendente') {
      return {
        color: 'yellow',
        icon: <FaStopCircle color="#ED8936" size={20} />,
        name: 'Pendente',
      };
    }
    if (attendance.status === 'Em Andamento') {
      return {
        color: 'blue',
        icon: <FaPlayCircle color="#3182ce" size={20} />,
        name: 'Em Andamento',
      };
    }
    if (attendance.status === 'Concluído') {
      return {
        color: 'green',
        icon: <FaCheckCircle color="#38A169" size={20} />,
        name: 'Concluído',
      };
    }
    if (attendance.status === 'Cancelado') {
      return {
        color: 'red',
        icon: <FaTimesCircle color="#E53E3E" size={20} />,
        name: 'Cancelado',
      };
    }

    return {
      color: 'gray',
      icon: <FaUserSecret />,
      name: 'Padrão',
    };
  }, [attendance.status]);

  return (
    <Tooltip
      hasArrow
      placement="left"
      label={`Atend. ${attendance.attendance_id}`}
    >
      {showCodeAttendance ? (
        <Box
          display="block"
          key={attendance.attendance_id}
          bg={variant === 'solid' ? `${bgStatus.color}.500` : 'transparent'}
          className="badge-attendance"
          color={variant === 'solid' ? 'white' : '#4A5568'}
          boxShadow={variant === 'solid' ? 'xl' : ''}
          width="150px"
          borderRadius="10px"
        >
          <Text
            fontWeight={variant === 'statusHighlighted' ? 'bold' : ''}
            bg={variant === 'solid' ? '#00000052' : 'transparent'}
            borderTopLeftRadius="10px"
            borderTopRightRadius="10px"
            textAlign="center"
          >
            {`Atend. ${attendance.attendance_id}`}
          </Text>
          <Text
            fontWeight="500"
            textAlign="center"
            bg={variant === 'statusHighlighted' ? `${bgStatus.color}.500` : ''}
            color="white"
          >
            {onlyIcon ? bgStatus.icon : attendance.status}{' '}
            {attendance.archived ? <small>(Arquiv.)</small> : ''}
          </Text>
        </Box>
      ) : (
        <>
          {onlyIcon ? (
            <Tooltip
              hasArrow
              placement="left"
              label={`Aten. ${attendance.attendance_id}`}
            >
              <Box>{bgStatus.icon}</Box>
            </Tooltip>
          ) : (
            <Flex
              key={attendance.attendance_id}
              bg={`${bgStatus.color}.500`}
              className="badge-attendance"
              alignItems="center"
              color="white"
              /* boxShadow="xl" */
              borderRadius="10px"
              paddingX="10px"
            >
              {showIconExternalLink && (
                <FiExternalLink className="external-link" />
              )}
              <Text
                fontWeight="600"
                fontSize="1rem"
                lineHeight={1.5}
                textAlign="center"
                ml={1}
              >
                {onlyIcon ? bgStatus.icon : attendance.status}{' '}
                {attendance.archived ? <small>(Arquiv.)</small> : ''}
              </Text>
            </Flex>
          )}
        </>
      )}
    </Tooltip>
  );
};

export default BadgeStatusAttendance;
