import React, { useEffect, useState } from 'react';
import { FiCalendar, FiLock } from 'react-icons/fi';

import {
  Stack,
  Avatar,
  Text,
  Badge,
  Tooltip,
  useDisclosure,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Flex,
  Box,
  Icon,
  Textarea,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  AlertIcon,
  Alert,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';

import clockImg from '~/assets/stopwatch.png';
import {
  ActivityProps as ActivityDTO,
  AttendaceCustom,
} from '~/modules/activityExecution/@types/activity';
import AvatarCustom from '~/shared/components/AvatarCustom';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import api from '~/shared/services/api';

import {
  Container,
  ContentActivity,
  CardActivity,
  ActivityStatus,
  AttendanceInfo,
} from './styles';

interface ActivityProps {
  id: number;
  name: string;
  order_activity: number;
  description?: string;
  start_date: string;
  start_time: string;
  minimum_start?: string;
  time_used: string;
  time_lapsed: string;
  situation_id: number;
  situation: 'Pausado' | 'Finalizado' | 'Iniciado' | 'Não Iniciado';
  created_at: string;
  duration: string;
  end_date: string;
  end_time: string;
  attach_file: boolean;
  send_email: boolean;
  blocked?: boolean;
  returned: boolean | number;
  activity_id: number;
  formatted_start_date: string;
  formatted_start_time: string;
  formatted_end_date: string;
  formatted_end_time: string;
  formatted_duration: string;
  formatted_start_and_date_time: string;
  formatted_end_date_time?: string;
  formatted_start_date_time?: string;
  user: string;
  avatar?: string;
  color: string;
  listPredecessors: Array<Predecessor> | null;
}

interface Predecessor {
  id: number;
  name: string;
  order_activity: number;
}

interface Props {
  attendance: number;
  data: AttendaceCustom;
  controlModal: number;
  onlyPredecessors?: boolean;
  listPredecessors?: boolean;
}

const colors = {
  Pausado: '#FFA11A',
  Finalizado: '#09CC1D',
  Iniciado: '#76A1DF',
  'Não Iniciado': '#D5DEE5',
};

const AttendanceActivities: React.FC<Props> = ({
  attendance,
  data,
  controlModal,
  onlyPredecessors = false,
  listPredecessors = false,
}) => {
  const [activities, setActivities] = useState<ActivityProps[]>([]);
  const [loaded, setloaded] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, [attendance, onOpen, controlModal]);

  useEffect(() => {
    setloaded(false);

    const url = !onlyPredecessors
      ? `/plannings/attendance/${attendance}`
      : `/plannings/attendance/${attendance}/before-activity`;

    const params = !onlyPredecessors
      ? { list_predecessors: listPredecessors }
      : { activity_id: data.activity_id };

    api
      .get<ActivityProps[]>(url, { params })
      .then((response) => {
        const dataActivities = response.data;

        setActivities(
          dataActivities.map((activity) => {
            return {
              ...activity,
              formatted_start_date: format(
                parseISO(activity.start_date),
                'dd/MM/yyyy',
              ),
              formatted_start_time: format(
                parseISO(`${activity.start_date} ${activity.start_time}`),
                'HH:mm',
              ),
              formatted_duration: format(
                parseISO(`${activity.start_date} ${activity.duration}`),
                'HH:mm:ss',
              ),
              blocked: !!activity.blocked,
              color: colors[activity.situation],
              listPredecessors: activity?.listPredecessors?.length
                ? activity.listPredecessors.map((predecessor: Predecessor) => {
                    const { id, name, order_activity } = predecessor;

                    return {
                      id,
                      name,
                      order_activity,
                    };
                  })
                : null,
            };
          }),
        );
      })
      .finally(() => {
        setloaded(true);
      });
  }, [attendance, data.activity_id, onlyPredecessors, listPredecessors]);

  return (
    <ModalChakra
      title={`${attendance} | Atendimento`}
      onClose={onClose}
      isOpen={isOpen}
      size="2xl"
    >
      <AttendanceInfo>
        <StatGroup flexDirection={['column', 'row']} mb={1.5}>
          <Stat>
            <StatLabel className="label">Demanda</StatLabel>
            <StatNumber fontSize="md">{data?.demand}</StatNumber>
          </Stat>

          <Stat>
            <StatLabel className="label">Empresa</StatLabel>
            <StatNumber fontSize="md">{data?.company}</StatNumber>
          </Stat>
        </StatGroup>

        {data?.description && (
          <Box>
            <Text className="label" mb={0.8}>
              Descrição
            </Text>
            <Textarea disabled rows={3} defaultValue={data.description} />
          </Box>
        )}

        <Alert status="info" my={4}>
          <AlertIcon />
          <Text size="sm" fontWeight="bolder">
            {onlyPredecessors
              ? 'Abaixo estão as todas atividades predecessoras pendentes'
              : 'Abaixo estão todas as atividades deste atendimento'}
          </Text>
        </Alert>
      </AttendanceInfo>
      <Container>
        {!loaded ? (
          <Loading />
        ) : (
          <ContentActivity>
            {activities?.length > 0 &&
              activities.map((activity) => (
                <CardActivity
                  key={`${activity.id}-activity`}
                  selected={activity.activity_id === data?.activity_id || false}
                >
                  <Flex flexDirection="column" w="full">
                    <Flex alignItems="center" w="full" mb={2}>
                      <Box as="h3" fontSize="md">
                        <Tooltip
                          hasArrow
                          aria-label="Ordem da atividade"
                          label="Ordem da atividade"
                          zIndex={1}
                        >
                          <Badge w={30} textAlign="center">
                            {activity.order_activity}
                          </Badge>
                        </Tooltip>
                        <Text display="inline" ml={2}>
                          {activity.name}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex
                      flexDirection={['column', 'row']}
                      mb={2}
                      alignItems="center"
                      fontSize="sm"
                      height="full"
                      width="full"
                    >
                      <Stack align="center">
                        <AvatarCustom
                          size="md"
                          name={activity.user}
                          src={activity.avatar}
                        />
                        <Text
                          isTruncated
                          w="100px"
                          fontSize="xs"
                          color="gray.400"
                          px={1}
                          align="center"
                        >
                          {activity.user}
                        </Text>
                      </Stack>
                      <Flex width="full" height="full">
                        <Flex
                          flexDirection={['column', 'row']}
                          width="full"
                          justifyContent="space-between"
                          mx={2}
                        >
                          <Flex
                            alignSelf={['normal', 'center']}
                            justifyContent={['space-between', 'start']}
                            mb={[4, 0]}
                          >
                            <Flex flexDirection="column">
                              <Box>
                                <strong>Planejamento:</strong>
                              </Box>

                              <Flex flexDirection="row">
                                <Text>{activity.formatted_start_date}</Text>
                                <Text mx={1}>às</Text>
                                <Text className="colorblue">
                                  {activity.formatted_start_time}
                                </Text>
                              </Flex>
                            </Flex>

                            <Flex
                              ml={4}
                              justifyContent="center"
                              alignItems="center"
                              alignSelf="flex-end"
                              height="21px"
                            >
                              <img
                                src={clockImg}
                                alt="Clock"
                                title="Duração prevista da atividade"
                                style={{
                                  height: '18px',
                                  opacity: '75%',
                                }}
                              />
                              {/* O fontsize com 12px e o line height com 1.5 define a tag p com 18px de altura
                              pois 12 * 1.5 = 18, por padrão o line height é 1.5, para que a fonte e a linha tenham o mesmo tamanho
                              basta utilizar line height = 1. Foi definido o tamanho 14px pois a imagem tem 21px e o line height
                              com fontsize 14px é 21 também
                                IMPORTANTE: para funcionar o verticalAlign é necessário utilizar o line-height e o elemento
                                deve ser display inline-block, com inline apenas não é possível utilizar o line height,
                                não é possivel utilizar com display flex
                              */}
                              <Box height="full" lineHeight="21px" ml={1}>
                                <Text
                                  fontSize="14px"
                                  lineHeight={1}
                                  display="inline-block"
                                  verticalAlign="middle"
                                >
                                  {activity.formatted_duration}
                                </Text>
                              </Box>
                            </Flex>
                          </Flex>
                          <Flex
                            alignSelf="center"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {!!activity.blocked && (
                              <Box mb={2}>
                                <Popover>
                                  <PopoverTrigger>
                                    <Button
                                      type="button"
                                      backgroundColor="red.50"
                                      colorScheme="red"
                                      variant="ghost"
                                      borderColor="red.200"
                                      borderWidth={1}
                                      py={6}
                                      px={4}
                                      _focus={{
                                        boxShadow: 'none',
                                      }}
                                    >
                                      <FiLock
                                        size={30}
                                        color="#E24A4A"
                                        title="Atividade bloqueada"
                                      />
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <PopoverHeader fontWeight="bold">
                                      Predecessoras Pendentes
                                    </PopoverHeader>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                      <Box className="predecessors-list">
                                        <ul>
                                          {!!activity.listPredecessors
                                            ?.length &&
                                            activity.listPredecessors.map(
                                              (predecessor) => (
                                                <li key={predecessor.id}>
                                                  {`${predecessor.order_activity} - ${predecessor.name}`}
                                                </li>
                                              ),
                                            )}
                                        </ul>
                                      </Box>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              </Box>
                            )}
                            <ActivityStatus color={activity.color}>
                              <span />
                              <small>{activity.situation}</small>
                            </ActivityStatus>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </CardActivity>
              ))}
          </ContentActivity>
        )}
      </Container>
    </ModalChakra>
  );
};

export default AttendanceActivities;
