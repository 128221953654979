import { Link, Box, Grid } from '@chakra-ui/react';
import styled from 'styled-components';

export const BoxLink = styled(Link)`
  position: relative;

  @media (max-width: 480px) {
    height: 100px;
  }

  height: 125px;

  &:hover div.file-preview {
    opacity: 1;
  }
  &:hover {
    color: black;
  }
`;

export const GridFiles = styled(Grid)`
  width: 100%;

  @media (max-width: 1760px) {
    grid-template-columns: auto auto auto auto;
  }

  @media (max-width: 1460px) {
    grid-template-columns: auto auto auto;
  }

  @media (max-width: 1180px) {
    grid-template-columns: auto auto;
  }

  @media (max-width: 890px) {
    grid-template-columns: 1fr;
  }

  grid-template-columns: auto auto auto auto auto;
`;

export const BoxPreviewIcon = styled(Box)`
  opacity: 0;
  transition: opacity 0.2s;
`;
