import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

interface ExportExcelProps {
  name: string;
  fileType?: string;
  fileExtension?: string;
  rangeData?: RangeProps;
  rangeHeader?: RangeProps;
  title: string;
  titleLine?: number; // index
  year?: number;
  employee?: string;
  // sheetNames: string;
  colName: string[];
  titleDataOrder: string[];
  thead: Thead[];
  data: DataObj[];
  enableAutoFilter?: boolean;
}

interface DataObj {
  [key: string]: string | { v: string; s?: object; l?: object };
}

export interface Thead {
  title: string;
  // value?: string | number;
  style?: object;
  width?: number;
  // dataStyle?: object;
}
interface TableStructure {
  c: number;
  r: number;
}

interface RangeProps {
  s: TableStructure;
  e: TableStructure;
}

export default function exportExcel(props: ExportExcelProps): void {
  const {
    // rangeData,
    // rangeHeader,
    titleLine,
    title,
    fileExtension = '.xlsx',
    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    name,
    employee,
    titleDataOrder,
    colName,
    thead,
    data,
    enableAutoFilter = false,
  } = props;

  const ws: any = {};

  // Linhas que o titulo da tabela ocupa, os dados iniciarão na linha seguinte
  const headerHeight = titleLine ? titleLine - 1 : 2;

  // O componente é configurado para criar uma pasta de trabalho com uma planilha apenas
  ws.A1 = {
    v: title,
    s: {
      font: { bold: true, sz: 18 },
    },
  };

  const rangeData = {
    s: { c: 0, r: 0 },
    e: { c: thead.length - 1, r: data.length + headerHeight },
  };
  const rangeHeader = {
    s: { c: 0, r: headerHeight },
    e: { c: thead.length - 1, r: headerHeight },
  };

  const cellStyles = {
    font: {
      sz: 14,
      color: { rgb: '#FF000000' },
      bold: true,
    },
  };

  const wscols: { wch: number }[] = [];

  const row = headerHeight;
  let col = 0;

  if (thead?.length) {
    thead.forEach((item) => {
      const cellRef = XLSX.utils.encode_cell({ c: col, r: row });

      // Acessaria o company_name, por exemplo
      // dt[dataTitleOrder[i]]

      ws[cellRef] = { v: item?.title, s: item?.style ?? cellStyles };
      wscols.push({ wch: item.width || 30 });

      // row += 1;
      col += 1;
    });
  }

  if (data?.length) {
    data.forEach((dt, i) => {
      const rowRef = i + (headerHeight + 1);

      thead.forEach((th, idx) => {
        const cellRef = XLSX.utils.encode_cell({ c: idx, r: rowRef });

        const dataValue = dt[titleDataOrder[idx]];

        if (typeof dataValue !== 'string') {
          ws[cellRef] = {
            v: dataValue?.v,
            s: dataValue.s,
            l: dataValue.l,
          };
          return;
        }

        ws[cellRef] = {
          v: dataValue,
        };

        /*  if (th.dataStyle) {
          ws[cellRef].s = th.dataStyle;
        } */
      });
    });
    // console.log(data);
  }
  // row
  /* for (row = rangeHeader.s.r; row <= rangeHeader.e.r; row += 1) {
    for (col = rangeHeader.s.c; col <= rangeHeader.e.c; col += 1) {
      const cellRef = XLSX.utils.encode_cell({ c: col, r: row });

      ws[cellRef] = {
        v: colName[col - 2],
        s: { ...cellStyles, alignment: { horizontal: 'center' } },
      };

    }
  } */

  ws['!cols'] = wscols;

  // ws[]

  ws['!ref'] = XLSX.utils.encode_range(rangeData);

  if (enableAutoFilter)
    ws['!autofilter'] = { ref: XLSX.utils.encode_range(rangeHeader) };

  const wb = {
    Sheets: { Plan1: ws },
    SheetNames: ['Plan1'],
  };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const dataBlob = new Blob([excelBuffer], { type: fileType });

  let fileName = name || title;

  if (employee) fileName += `_${employee.toUpperCase()}`;

  FileSaver.saveAs(dataBlob, fileName + fileExtension);

  // console.log(props, 'props');
}
