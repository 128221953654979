import React from 'react';
import { FaArrowsAltH } from 'react-icons/fa';

import { Flex, FormLabel, Box, Text, Switch } from '@chakra-ui/react';

interface Props {
  defaultIsChecked?: boolean;
  toogleAdjust?(): void;
  loading?: boolean;
  disabled?: boolean;
  icon?: any;
  tittle: string;
  id: string;
}

const ToggleCustom: React.FC<Props> = ({
  defaultIsChecked,
  toogleAdjust,
  loading,
  disabled,
  tittle,
  icon,
  id,
}) => {
  return (
    <Flex alignItems="center" width="full" flexShrink={1}>
      <FormLabel htmlFor={id} userSelect="none">
        <Flex alignItems="center" justifyContent="space-between">
          {!icon && (
            <Box
              borderRight="3px solid #3182ce"
              borderLeft="3px solid #3182ce"
              mr={2}
            >
              <FaArrowsAltH color="#3182ce" />
            </Box>
          )}
          <Box mr={2}>{icon}</Box>
          <Text whiteSpace="nowrap">{tittle}</Text>
        </Flex>
      </FormLabel>

      <Switch
        id={id}
        onChange={toogleAdjust}
        defaultIsChecked={defaultIsChecked}
        size="md"
        mb={1}
        marginLeft="auto"
        isDisabled={disabled || loading}
      />
    </Flex>
  );
};

export default ToggleCustom;
