import React from 'react';
// import ReactLoading from 'react-loading';

import logoAnimate from '~/assets/gif-logo-mini-transparent.gif';

import { Container } from './styles';

interface Props {
  z_index?: number;
  min_height?: number;
}

const LoadingAbsolute: React.FC<Props> = ({ z_index, min_height }) => {
  return (
    <Container className="isloading" zIndex={z_index} minHeight={min_height}>
      <div>
        <img
          src={logoAnimate}
          alt="gif-logo"
          style={{
            height: '100%',
            width: '150px',
            marginBottom: '8px',
            opacity: 0.65,
          }}
        />
        <strong>Carregando...</strong>
      </div>
    </Container>
  );
};

export default LoadingAbsolute;
