import React from 'react';
import { IconType } from 'react-icons';
import { FiXOctagon } from 'react-icons/fi';

import {
  Text,
  Tooltip,
  Icon,
  Box,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  HStack,
} from '@chakra-ui/react';

import AvatarCustom from '../AvatarCustom';
import { Container } from './styles';

interface Badge {
  /* Se o label não for passado, quando o texto for truncado o que será exibido no tooltip
  será o próprio valor do badge, caso contrário o label é o exibido no Tooltip */

  /* Para se exibido o ícone, o label obrigatóriamente deve ser passado */

  /* Para utilizar o avatar é recomendado utilizar size lg */

  label?: string;
  icon?: IconType;
  iconPosition?: 'left' | 'right';
  avatar?: string;
  close?(): void;
  value: string;
  isTruncated?: boolean;
}

interface Props {
  data: Badge[];
  cleanFilter?(): void;
  variant?: 'outline' | 'solid' | 'subtle';
  background?: string;
  colorScheme?:
    | 'whiteAlpha'
    | 'blackAlpha'
    | 'gray'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'green'
    | 'teal'
    | 'blue'
    | 'cyan'
    | 'purple'
    | 'pink'
    | 'linkedin'
    | 'facebook'
    | 'messenger'
    | 'whatsapp'
    | 'twitter'
    | 'telegram';
  textColor?: string;
  size?: 'sm' | 'md' | 'lg';
}

const FilterTags: React.FC<Props> = ({
  data,
  variant = 'subtle',
  cleanFilter,
  background,
  colorScheme = 'blue',
  size,
  textColor,
}) => {
  return (
    <Container>
      <div id="removeFilters">
        <strong>Filtro aplicado</strong>

        <button type="button" onClick={cleanFilter}>
          <FiXOctagon size={16} />

          <Text>Limpar Filtro</Text>
        </button>
      </div>
      {/* <div id="filters">
        {!!data.length &&
          data.map((dt) => {
            return (

                <Box key={`${dt.label}-${dt.value}`} maxWidth="260px">
                  <Tooltip label={dt.label || dt.value || ''}>
                    <Box
                      className="badge"
                      background={variant === 'solid' ? 'blue.50' : ''}
                    >
                      {!!dt.icon && !!dt.label && (
                        <Icon as={dt.icon} w="16px" mr={2} color="#2089dc" />
                      )}
                      <Text isTruncated>{dt?.value}</Text>
                    </Box>
                  </Tooltip>
                </Box>
            );
          })}
      </div> */}
      {!!data.length && (
        <HStack spacing="10px" my="15px">
          {!!data.length &&
            data.map((dt) => {
              return (
                dt.value && (
                  <Box maxWidth="260px" key={`${dt.label}-${dt.value}`}>
                    <Tooltip label={dt.label || dt.value || ''}>
                      <Tag
                        size={size}
                        variant={variant}
                        colorScheme={colorScheme}
                        borderRadius="22px"
                        // height="24px"
                        style={{
                          background,
                        }}
                      >
                        {!!dt.icon &&
                          dt.label &&
                          dt.iconPosition !== 'right' && (
                            <TagLeftIcon
                              boxSize="16px"
                              color={textColor}
                              as={dt.icon}
                            />
                          )}

                        {!!dt.avatar && (
                          <AvatarCustom
                            src={dt.avatar}
                            size="xs"
                            name={dt.value}
                            ml={-1}
                            mr={2}
                          />
                        )}

                        <TagLabel
                          isTruncated
                          fontFamily="inter"
                          fontWeight={500}
                          fontSize="13px"
                          color={textColor}
                        >
                          {dt.value}
                        </TagLabel>

                        {!!dt.icon &&
                          dt.label &&
                          dt.iconPosition === 'right' && (
                            <TagRightIcon
                              boxSize="16px"
                              color={textColor}
                              as={dt.icon}
                            />
                          )}

                        {!!dt.close && (
                          <TagCloseButton
                            onClick={dt.close}
                            _focus={{ boxShadow: 'none' }}
                          />
                        )}
                      </Tag>
                    </Tooltip>
                  </Box>
                )
              );
            })}
        </HStack>
      )}
    </Container>
  );
};

export default FilterTags;
