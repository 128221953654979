import React, { useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Box, Text, Flex, Button, Badge, Tooltip } from '@chakra-ui/react';

import {
  DataAllocationSeg,
  AllocationFunc,
} from '~/modules/dashboard/pages/AllocationGraph';
import { Department } from '~/modules/management/@types/management';
import selectTheme, { themeGraph } from '~/utils/getThemeGraphics';

import DoughnutChart from '../../../modules/dashboard/components/Doughnut';
import {
  Props as DoughnutProps,
  PropsChart,
} from '../../../modules/dashboard/components/Doughnut';
import AvatarCustom from '../AvatarCustom';

interface DepartmentTotal extends Department {
  center_top_value?: number;
  center_lower_value?: number;
  dataChart: PropsChart;
}

export interface DepartmentChart extends DepartmentTotal {
  subordinate_departments?: DepartmentTotal[];
}

interface DateLink {
  start_date: string;
  end_date: string;
  adjust?: number;
}

interface Props {
  department: DepartmentChart;
  theme?: themeGraph;
  selectChart(value: string | number): void;
  colors?: string;
  enablePluginDataLabel?: boolean;
  avatar?: string;
  name?: string;
  enableLink?: boolean;
  date?: DateLink;
}

export const CardGraphicDepartment: React.FC<Props> = ({
  department,
  selectChart,
  theme,
  avatar,
  name,
  enableLink,
  date,
}) => {
  const { dataChart } = department;

  const [toggleTeam, setToggleTeam] = useState(true);

  const showCenterScore =
    department.center_lower_value !== undefined ||
    department.center_top_value !== undefined;

  return (
    <Box ml={['0px', '24px']} pt={4}>
      {/* <Text pt={3} pl="24px" fontSize="18px">
        Gerente: {department.responsible?.name}
      </Text> */}

      <Flex flexDir={['column', 'row']}>
        <Box w="full">
          <Flex alignItems="center" justifyContent="center">
            <AvatarCustom
              src={avatar}
              name={name}
              size="md"
              mr={1}
              borderWidth="1px"
              borderColor="gray"
            />
            <Tooltip
              aria-label={department?.responsible?.name || 'Sem responsável'}
              label={department?.responsible?.name || 'Sem responsável'}
              placement="top-start"
            >
              <Text
                isTruncated
                w={145}
                fontWeight={700}
                ml={1}
                fontSize="16px"
                justifyContent="center"
              >
                {department.responsible?.name}
              </Text>
            </Tooltip>
          </Flex>

          <Tooltip
            aria-label={department.name}
            label={department.name}
            placement="top-start"
          >
            <Text
              isTruncated
              whiteSpace="break-spaces"
              textAlign={['center', 'start']}
              fontWeight="bold"
              fontSize="24px"
              w={130}
              mt={3}
              ml={[2, 0]}
              noOfLines={3}
            >
              {department.name}
            </Text>
          </Tooltip>
          {department.disabled_at && (
            <Badge colorScheme="red">Desabilitado</Badge>
          )}
        </Box>
        <Box position="relative" width="200px" mx="auto">
          {showCenterScore && (
            <Flex
              position="absolute"
              left="51.3%"
              top="45%"
              transform="translate(-50%, -50%)"
            >
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  as="b"
                  textAlign="center"
                  fontSize="2xl"
                  color={
                    department.disabled_at || theme === 'delay'
                      ? '#b22222'
                      : '#090979'
                  }
                  mb={-1.5}
                >
                  {department.center_top_value?.toFixed(1)}%
                </Text>
                <Text
                  as="b"
                  textAlign="center"
                  fontSize="2xl"
                  color={
                    department.disabled_at || theme === 'delay'
                      ? '#ff0000'
                      : '#00bfff'
                  }
                >
                  {department.center_lower_value?.toFixed(1)}%
                </Text>
              </Flex>
            </Flex>
          )}
          {enableLink ? (
            <Box position="relative" width="full" height="full">
              <Link
                to={`/relatorios/alocacao/${department.id}?start_date=${date?.start_date}&end_date=${date?.end_date}&adjust=${date?.adjust}`}
              >
                <DoughnutChart
                  colors={selectTheme(theme)}
                  data={dataChart}
                  selectChart={selectChart}
                  width={200}
                  height={200}
                  enablePluginDataLabel
                />
              </Link>
            </Box>
          ) : (
            <Box position="relative" width="full" height="full">
              <DoughnutChart
                colors={selectTheme(theme)}
                data={dataChart}
                selectChart={selectChart}
                width={200}
                height={200}
                enablePluginDataLabel
              />
            </Box>
          )}
        </Box>
      </Flex>
      {!!department.subordinate_departments?.length && (
        <Box mt={-5}>
          <Flex
            /* pl="24px" */
            w="full"
            alignItems="center"
            justifyContent={['center', 'start']}
          >
            <Button
              mb={3}
              mt={[5, 0]}
              colorScheme="gray"
              background={showCenterScore ? 'white' : ''}
              /* variant={toggleTeam ? 'outline' : 'solid'} */
              onClick={() => setToggleTeam(!toggleTeam)}
            >
              <FaUsers />
              <Text mx={2}>Equipes</Text>
              <Badge borderRadius="50px" colorScheme="blue" p={1}>
                {department.subordinate_departments.length}
              </Badge>
            </Button>
          </Flex>
          {/* Teste */}
          {toggleTeam && (
            <Flex
              flexDir={['column', 'row']}
              justifyContent="center"
              alignItems="center"
            >
              {department.subordinate_departments.map((depSub) => {
                const { dataChart: dataChartSub } = depSub;

                return (
                  <Box key={depSub.id}>
                    {/* <Flex
                      width="250px"
                      minH="250px"
                      key={depSub.id}
                      flexDir="column"
                      alignItems="center"
                    >
                      <DoughnutChart
                        colors={selectTheme(theme)}
                        data={dataChartSub}
                        selectChart={selectChart}
                        height={70}
                        enablePluginDataLabel
                      />

                      <Text textAlign="center" mt={-2}>
                        {depSub.name}
                      </Text>
                    </Flex> */}

                    <Flex
                      width="250px"
                      minH="250px"
                      key={depSub.id}
                      flexDir="column"
                      alignItems="center"
                      position="relative"
                    >
                      {(depSub.center_top_value !== undefined ||
                        depSub.center_lower_value !== undefined) && (
                        <Flex
                          position="absolute"
                          left="50%"
                          top="33%"
                          transform="translate(-50%, -50%)"
                        >
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Text
                              as="b"
                              fontSize="lg"
                              color={
                                depSub.disabled_at || theme === 'delay'
                                  ? '#b22222'
                                  : '#090979'
                              }
                              mb={-1.5}
                              textAlign="center"
                            >
                              {depSub.center_top_value?.toFixed(1)}%
                            </Text>
                            <Text
                              as="b"
                              textAlign="center"
                              fontSize="lg"
                              color={
                                depSub.disabled_at || theme === 'delay'
                                  ? '#ff0000'
                                  : '#00bfff'
                              }
                            >
                              {depSub.center_lower_value?.toFixed(1)}%
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                      {enableLink ? (
                        <Box position="relative">
                          <Link
                            to={`/relatorios/alocacao/${depSub.id}?start_date=${date?.start_date}&end_date=${date?.end_date}&adjust=${date?.adjust}`}
                          >
                            <DoughnutChart
                              colors={selectTheme(theme)}
                              data={dataChartSub}
                              selectChart={selectChart}
                              height={160}
                              width={160}
                              enablePluginDataLabel
                            />
                          </Link>
                        </Box>
                      ) : (
                        <Box position="relative">
                          <DoughnutChart
                            colors={selectTheme(theme)}
                            data={dataChartSub}
                            selectChart={selectChart}
                            height={160}
                            width={160}
                            enablePluginDataLabel
                          />
                        </Box>
                      )}

                      <Text textAlign="center" mt={-2}>
                        {depSub.name}
                      </Text>
                    </Flex>
                  </Box>
                );
              })}
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};

export interface DepartmentPeriod {
  name: string;
  departamento_id: number;
  responsavel_id: number;
  responsavel: string;
  responsavel_avatar?: string;
  disabled_at?: string;
  // subordinate_departments: SubordinateDepartmentPeriod[];
  teams_count: number;
  alocacao: string;
  alocacao_prevista: number;
  alocacao_geral: number;
  total_alocacao_geral: number;
  alocacao_prevista_geral: number;
  total_alocacao_prevista_geral: number;
}

interface SubordinateDepartmentPeriod {
  id: number;
  departamento_superior_id: number;
  empresa_id: number;
  responsavel_id: number;
  name: string;
  created_at: string;
  updated_at: string;
  nome_responsavel: string;
  responsavel: string;
  alocacao: number;
  alocacao_prevista: number;
  total_alocacao: number;
  total_alocacao_prevista: number;
}

interface DepartmentTotalPeriod extends DepartmentPeriod {
  center_top_value?: number;
  center_lower_value?: number;
  dataChart: PropsChart;
}
interface SubordinateTotalPeriod extends SubordinateDepartmentPeriod {
  center_top_value?: number;
  center_lower_value?: number;
  dataChart: PropsChart;
  dados_alocacao_segundos?: DataAllocationSeg;
}

export interface DepartmentChartPeriod extends DepartmentTotalPeriod {
  subordinate_departments?: SubordinateTotalPeriod[];
  funcionarios: AllocationFunc[];
  dados_alocacao_geral_segundos?: DataAllocationSeg;
  dados_alocacao_segundos?: DataAllocationSeg;
}

interface PropsPeriod {
  department: DepartmentChartPeriod;
  theme?: themeGraph;
  selectChart(value: string | number): void;
  colors?: string;
  enablePluginDataLabel?: boolean;
  avatar?: string;
  name?: string;
  enableLink?: boolean;
  date?: DateLink;
  adjust?: number;
}
export const CardGraphicDepartmentPeriod: React.FC<PropsPeriod> = ({
  department,
  selectChart,
  theme,
  avatar,
  name,
  enableLink,
  date,
  adjust,
}) => {
  const { dataChart } = department;

  const [toggleTeam, setToggleTeam] = useState(true);

  const showCenterScore =
    department.center_lower_value !== undefined ||
    department.center_top_value !== undefined;

  return (
    <Box ml={['0px', '24px']} pt={4}>
      {/* <Text pt={3} pl="24px" fontSize="18px">
        Gerente: {department.responsible?.name}
      </Text> */}

      <Flex flexDir={['column', 'row']}>
        <Box w="full">
          <Flex alignItems="center" justifyContent="center">
            <AvatarCustom
              src={avatar}
              name={name}
              size="md"
              mr={1}
              borderWidth="1px"
              borderColor="gray"
            />
            <Tooltip
              aria-label={department?.responsavel || 'Sem responsável'}
              label={department?.responsavel || 'Sem responsável'}
              placement="top-start"
            >
              <Text
                isTruncated
                w={145}
                fontWeight={700}
                ml={1}
                fontSize="16px"
                justifyContent="center"
              >
                {department.responsavel}
              </Text>
            </Tooltip>
          </Flex>

          <Tooltip
            aria-label={department.name}
            label={department.name}
            placement="top-start"
          >
            <Text
              isTruncated
              whiteSpace="break-spaces"
              textAlign={['center', 'start']}
              fontWeight="bold"
              fontSize="24px"
              w={130}
              mt={3}
              ml={[2, 0]}
              noOfLines={3}
            >
              {department.name}
            </Text>
          </Tooltip>
          {department.disabled_at && (
            <Badge colorScheme="red">Desabilitado</Badge>
          )}
        </Box>
        <Box position="relative" width="200px" mx="auto">
          {showCenterScore && (
            <Flex
              position="absolute"
              left="51.3%"
              top="45%"
              transform="translate(-50%, -50%)"
            >
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  as="b"
                  fontSize="2xl"
                  color={department.disabled_at ? '#e44c4c' : '#090979'}
                  mb={-1.5}
                >
                  {department.center_top_value?.toFixed(1)}%
                </Text>
                <Text
                  as="b"
                  fontSize="2xl"
                  color={department.disabled_at ? '#f72424' : '#00bfff'}
                >
                  {department.center_lower_value?.toFixed(1)}%
                </Text>
              </Flex>
            </Flex>
          )}
          {enableLink ? (
            <Box position="relative">
              <Link
                to={`/relatorios/alocacao/${department.departamento_id}?start_date=${date?.start_date}&end_date=${date?.end_date}&adjust=${adjust}`}
              >
                <DoughnutChart
                  colors={selectTheme(theme)}
                  data={dataChart}
                  selectChart={selectChart}
                  width={500}
                  height={500}
                  enablePluginDataLabel
                />
              </Link>
            </Box>
          ) : (
            <Box position="relative">
              <DoughnutChart
                colors={selectTheme(theme)}
                data={dataChart}
                selectChart={selectChart}
                width={500}
                height={500}
                enablePluginDataLabel
              />
            </Box>
          )}
        </Box>
      </Flex>
      {!!department.subordinate_departments?.length && (
        <Box mt={-5}>
          <Flex
            /* pl="24px" */
            w="full"
            alignItems="center"
            justifyContent={['center', 'start']}
          >
            <Button
              mb={3}
              mt={[5, 0]}
              colorScheme="gray"
              background={showCenterScore ? 'white' : ''}
              /* variant={toggleTeam ? 'outline' : 'solid'} */
              onClick={() => setToggleTeam(!toggleTeam)}
            >
              <FaUsers />
              <Text mx={2}>Equipes</Text>
              <Badge borderRadius="50px" colorScheme="blue" p={1}>
                {department.subordinate_departments.length}
              </Badge>
            </Button>
          </Flex>
          {/* Teste */}
          {toggleTeam && (
            <Flex
              flexDir={['column', 'row']}
              justifyContent="center"
              alignItems="center"
            >
              {department.subordinate_departments.map((depSub) => {
                const { dataChart: dataChartSub } = depSub;

                return (
                  <Box key={depSub.id}>
                    {/* <Flex
                      width="250px"
                      minH="250px"
                      key={depSub.id}
                      flexDir="column"
                      alignItems="center"
                    >
                      <DoughnutChart
                        colors={selectTheme(theme)}
                        data={dataChartSub}
                        selectChart={selectChart}
                        height={70}
                        enablePluginDataLabel
                      />

                      <Text textAlign="center" mt={-2}>
                        {depSub.name}
                      </Text>
                    </Flex> */}

                    <Flex
                      width="250px"
                      minH="250px"
                      key={depSub.id}
                      flexDir="column"
                      alignItems="center"
                      position="relative"
                    >
                      {(department.center_top_value !== undefined ||
                        department.center_lower_value !== undefined) && (
                        <Flex
                          position="absolute"
                          left="50%"
                          top="33%"
                          transform="translate(-50%, -50%)"
                        >
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Text
                              as="b"
                              fontSize="lg"
                              color={
                                department.disabled_at ? '#e44c4c' : '#090979'
                              }
                              mb={-1.5}
                            >
                              {depSub.center_top_value?.toFixed(1)}%
                            </Text>
                            <Text
                              as="b"
                              fontSize="lg"
                              color={
                                department.disabled_at ? '#f72424' : '#00bfff'
                              }
                            >
                              {depSub.center_lower_value?.toFixed(1)}%
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                      {enableLink ? (
                        <Box position="relative">
                          <Link
                            to={`/relatorios/alocacao/${depSub.id}?start_date=${date?.start_date}&end_date=${date?.end_date}&adjust=${adjust}`}
                          >
                            <DoughnutChart
                              colors={selectTheme(theme)}
                              data={dataChartSub}
                              selectChart={selectChart}
                              height={160}
                              width={160}
                              enablePluginDataLabel
                            />
                          </Link>
                        </Box>
                      ) : (
                        <Box position="relative">
                          <DoughnutChart
                            colors={selectTheme(theme)}
                            data={dataChartSub}
                            selectChart={selectChart}
                            height={160}
                            width={160}
                            enablePluginDataLabel
                          />
                        </Box>
                      )}

                      <Text textAlign="center" mt={-2}>
                        {depSub.name}
                      </Text>
                    </Flex>
                  </Box>
                );
              })}
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};
