import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from 'react';

import {
  Box,
  Button,
  Flex,
  Input,
  UseRadioProps,
  useRadio,
  useRadioGroup,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useField } from '@unform/core';

import {
  Dimension,
  Score,
  Concept,
} from '~/modules/gdp/@types/performanceReview';
import InputChakra from '~/shared/components/InputChakra';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';

import { TdCustom } from './styles';

/* Verificar se a opção ta marcada com uma prop, de modo que exiba o X */
interface Props extends UseRadioProps {
  // eslint-disable-next-line react/require-default-props
  bgColor?: string;
  pointer?: string;
  allowFillScoreProp?: boolean;
  // selectedValue: string;
  // getRef?(ref: HTMLInputElement | null): void;
}
interface ScoreOption extends Score {
  isChecked?: boolean;
}

interface PropsRowDimension {
  index: number;
  indexDimension: number;
  isRatedUser: boolean;
  dimensionId: number;
  concept: Concept;
  score: ScoreOption[];
  allowMaxScore?: boolean;
  allowMinScore?: boolean;
  allowViewScore?: boolean;
  allowFillScore: boolean;
  allowWriteNote?: boolean;
  countNote?: number;
  initialValue?: number | null;
  onClickNote?(): void;
  onClickScore?(value: string): void;
}

interface AllProps extends UseRadioProps {
  bgColor?: string;
  name: string;
  dimension: Dimension;
  score: ScoreOption[];
}

const RadioBox: React.FC<Props> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const inputRef = useRef<HTMLInputElement>(null);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const { bgColor, value, isChecked, pointer, allowFillScoreProp } = props;

  // console.log(allowFillScoreProp);

  return (
    <Box as="label" pointerEvents={allowFillScoreProp ? 'auto' : 'none'}>
      <input
        {...input}
        // onClick={(e) => console.log()}
        ref={inputRef}
        value={value}
        checked={isChecked}
      />
      <Flex
        {...checkbox}
        cursor={pointer}
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        borderColor="transparent"
        _checked={{
          bg: bgColor || 'teal.600',
          color: 'black',
          borderColor: bgColor || 'teal.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={3}
        height="100px"
        justifyContent="center"
        alignItems="center"
      >
        {isChecked || inputRef.current?.checked ? (
          <Text fontSize="lg" textAlign="center">
            X
          </Text>
        ) : (
          ''
        )}
        {/* {scoreChecked === `${dimension.id}-${scor.id}` ? 'X' : ''} */}
      </Flex>
    </Box>
  );
};

const RowDimension: React.FC<PropsRowDimension> = ({
  concept,
  score,
  index,
  indexDimension,
  isRatedUser,
  dimensionId,
  initialValue,
  onClickNote,
  onClickScore,
  countNote = 0,
  allowMaxScore = false,
  allowMinScore = false,
  allowViewScore,
  allowFillScore = false,
  allowWriteNote = true,
}) => {
  // const options = ['1', '2', '3', '4', '5'];

  const name = `scores[${indexDimension}][${index}][pr_score_id]`;

  /* const [scoreChecked, setScoreChecked] = useState<string>(); */

  const [value, setValue] = useState<string>(initialValue?.toString() ?? '');

  // useEffect(() => console.log(`input selecionado:${value}`), [value]);

  const inputRefs = useRef<HTMLInputElement>(null);

  // const myRefs = React.useRef([]);

  const { fieldName, registerField, defaultValue = [] } = useField(name);

  /*   const refInput = useRef<HTMLInputElement>(null); */
  // const refInput = React.createRef<HTMLInputElement>();

  const getColor = useCallback((valueColor: number) => {
    let color;

    switch (valueColor) {
      case 1:
        color = '#ff8b8b';
        break;
      case 5:
        color = '#75b5ff';
        break;
      default:
        color = '#d3ffcf';
        break;
    }
    return color;
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const { getRadioProps, getRootProps } = useRadioGroup({
    name,
    onChange: (valueRadio) => {
      // console.log('checked', valueRadio);
      /* if (inputRefs && inputRefs.current) {
        inputRefs.current.value = valueRadio.toString();

      } */
      onClickScore && onClickScore(valueRadio.toString());
      setValue(valueRadio.toString());
    },
    defaultValue: initialValue?.toString(),
  });

  // const group = getRootProps();

  const handleAllowScore = useCallback(
    (scoreId) => {
      if (scoreId === 1) {
        return allowMinScore;
      }
      if (scoreId === 5) {
        return allowMaxScore;
      }
      return false;
    },
    [allowMinScore, allowMaxScore],
  );

  return (
    <>
      {/* <HStack {...group}>
      <RadioCard key={value} {...radio}>
        {value}
      </RadioCard>
    </HStack>
    ----------------
      <Box as="td" py={3} key={value} {...group}>
        <RadioBox {...radio}>X</RadioBox>
      </Box> */}
      <Box as="tr" key={concept.id} _hover={{ bg: 'gray.50' }}>
        <Box as="td" py={3}>
          <Text pl={3}>{concept.name}</Text>
        </Box>
        <Flex as="td" py={3}>
          <Button
            position="relative"
            mt="15px"
            onClick={() => {
              onClickNote && onClickNote();
              /* handleSelectedDimension(concept.id);
                setToogleNote(!toogleNote); */
            }}
            disabled={!allowWriteNote && !countNote}
            variant="outline"
          >
            Anotação
            {countNote > 0 && (
              <Flex
                justifyContent="center"
                alignItems="center"
                rounded="50%"
                position="absolute"
                right="-10px"
                top="-10px"
                background="#dc3545"
                width="20px"
                height="20px"
              >
                <Text color="#fff" fontSize="smaller">
                  {countNote}
                </Text>
              </Flex>
            )}
          </Button>
          <Input type="hidden" display="none" ref={inputRefs} value={value} />
          <InputChakra
            type="hidden"
            name={`scores[${indexDimension}][${index}][note]`}
            value={value}
          />
          <InputChakra
            type="hidden"
            name={`scores[${indexDimension}][${index}][pr_dimension_concept_id]`}
            value={concept.id}
          />
          <InputChakra
            type="hidden"
            name={`scores[${indexDimension}][${index}][pr_dimension_id]`}
            defaultValue={dimensionId}
          />
        </Flex>
        {!!score.length &&
          allowViewScore &&
          score
            // .filter((sc, idx) => idx <= 0)
            .map((scor) => {
              const radio = getRadioProps({ value: scor.id?.toString() || '' });
              const allowScore = handleAllowScore(scor.id);
              // console.log(allowScore);
              return (
                <TdCustom key={scor.id}>
                  {((scor.id === 1 && !allowMinScore) ||
                    (scor.id === 5 && !allowMaxScore)) &&
                  !isRatedUser ? (
                    <Tooltip
                      placement="top"
                      label={
                        !allowScore
                          ? 'Essa pontuação necessita de uma anotação antes de ser preenchida'
                          : ''
                      }
                    >
                      <Box background={getColor(scor.id)} mx={3}>
                        <RadioBox
                          {...radio}
                          allowFillScoreProp={allowFillScore}
                          bgColor={getColor(scor.id)}
                          id={`${concept.id}-${scor.id}`}
                          value={scor.id}
                          pointer={
                            !allowScore
                              ? 'not-allowed !important'
                              : 'pointer !important'
                          }
                          isDisabled={!allowScore}
                        />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Box background={getColor(scor.id)} mx={3}>
                      <RadioBox
                        {...radio}
                        allowFillScoreProp={allowFillScore}
                        bgColor={getColor(scor.id)}
                        id={`${concept.id}-${scor.id}`}
                        value={scor.id}
                        pointer="pointer"
                      />
                    </Box>
                  )}
                </TdCustom>
              );
            })}
      </Box>
    </>
  );
};

export default RowDimension;
