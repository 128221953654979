import React, { useMemo } from 'react';
import {
  FaStopCircle,
  FaPlayCircle,
  FaCheckCircle,
  FaTimesCircle,
  FaUserSecret,
} from 'react-icons/fa';

import { Box, Tooltip, Text, Icon } from '@chakra-ui/react';

import { Attendances } from '../../pages/WeeklyDemands';
import { FlexBadge } from './styles';

interface BadgeStatus {
  color: string;
  icon?: React.ReactElement;
  name: string;
}

export interface Props {
  statusColor: string;
  tooltip?: string;
  status: string;
  size?: 'lg' | 'md';
  variant?: 'solid' | 'outline' | 'ghost' | 'link';
  displayFull?: boolean;
  onlyIcon?: boolean;
}

const BadgeStatusInfo: React.FC<Props> = ({
  statusColor,
  status,
  tooltip,
  variant = 'solid',
  displayFull = false,
  size,
  onlyIcon = false,
}) => {
  /* const bgStatus = useMemo<BadgeStatus>(() => {
    if (status === 'Pendente') {
      return {
        color: 'yellow',
        icon: <FaStopCircle color="#ED8936" size={20} />,
        name: 'Pendente',
      };
    }
    if (status === 'Em Andamento') {
      return {
        color: 'blue',
        icon: <FaPlayCircle color="#3182ce" size={20} />,
        name: 'Em Andamento',
      };
    }
    if (status === 'Concluído') {
      return {
        color: 'green',
        icon: <FaCheckCircle color="#38A169" size={20} />,
        name: 'Concluído',
      };
    }
    if (status === 'Finalizado') {
      return {
        color: 'green',
        icon: <FaCheckCircle color="#38A169" size={20} />,
        name: 'Finalizado',
      };
    }
    if (status === 'Cancelado') {
      return {
        color: 'red',
        icon: <FaTimesCircle color="#E53E3E" size={20} />,
        name: 'Cancelado',
      };
    }

    return {
      color: 'gray',
      icon: <FaUserSecret />,
      name: 'Padrão',
    };
  }, [status]); */

  const getColor = useMemo(() => {
    switch (statusColor) {
      case 'blue':
        return {
          color: 'blue.500',
          name: status,
          icon: <FaPlayCircle color="#3182ce" size={20} />,
        };
      case 'light blue':
        return {
          color: 'blue.300',
          name: status,
          icon: <FaTimesCircle color="#E53E3E" size={20} />,
        };
      case 'gray':
        return {
          color: 'gray.500',
          name: status,
          icon: <FaTimesCircle color="#6c757d" size={20} />,
        };
      case 'green':
        return {
          color: 'green.500',
          name: status,
          icon: <FaCheckCircle color="#38A169" size={20} />,
        };
      case 'red':
        return {
          color: 'red.500',
          name: status,
          icon: <FaTimesCircle color="#E53E3E" size={20} />,
        };
      case 'orange':
        return {
          color: 'orange.500',
          name: status,
          icon: <FaStopCircle color="#ED8936" size={20} />,
        };
      case 'white':
        return {
          color: 'white',
          name: status,
        };
      case 'black':
        return {
          color: 'black.500',
          name: status,
        };

      default:
        return {
          color: 'gray.500',
          name: status,
          icon: <FaUserSecret />,
        };
    }
  }, [status, statusColor]);

  function getSize(): string | number {
    switch (size) {
      case 'lg':
        return 16;
      case 'md':
        return '90%';
      default:
        return 16;
    }
  }
  return (
    <>
      {onlyIcon ? (
        <Tooltip hasArrow placement="left" label={tooltip}>
          <Box
            display={displayFull ? 'block' : 'inline-block'}
            borderColor={variant === 'outline' ? `${getColor.name}` : 'none'}
            borderWidth={variant === 'outline' ? 1 : 0}
            className="badge-attendance"
            alignItems="center"
            color="white"
            borderRadius="10px"
            paddingX="10px"
          >
            <Tooltip hasArrow placement="left" label={getColor.name}>
              <Box>{getColor.icon}</Box>
            </Tooltip>
          </Box>
        </Tooltip>
      ) : (
        <Tooltip hasArrow placement="left" label={tooltip}>
          <Box
            display={displayFull ? 'block' : 'inline-block'}
            bg={variant === 'solid' ? getColor.color : 'none'}
            borderColor={variant === 'outline' ? getColor.color : 'none'}
            borderWidth={variant === 'outline' ? 1 : 0}
            className="badge-attendance"
            alignItems="center"
            color="white"
            borderRadius="10px"
            paddingX="10px"
          >
            <Text
              fontSize={getSize()}
              fontWeight="600"
              textAlign="center"
              color={variant === 'outline' ? getColor.color : 'white'}
            >
              {status}
            </Text>
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default BadgeStatusInfo;
