import React, { Children } from 'react';
import { IconBaseProps } from 'react-icons';

import { Box, Flex, Text, HStack } from '@chakra-ui/react';

interface HighlightsProps {
  data: HighlightData[];
}

interface HighlightData {
  label: string;
  icon: React.ComponentType<IconBaseProps>;
  text?: string;
  children?: React.ReactNode;
  length?: number;
}

const Highlights: React.FC<HighlightsProps> = ({ data }) => {
  return (
    <HStack
      spacing={[0, '100px']}
      flexDir={['column', 'row']}
      align={['start', 'center']}
      mb={10}
      pl={4}
    >
      {data.map((high, i) => {
        return (
          <Box maxWidth="400px" mb={[4, 0]} pl={4} key={`${i + 1}`}>
            <Flex w="full" alignItems="center" mb={2}>
              {high?.icon && (
                <Box as={high?.icon} size="20px" mr={4} minW="20px" />
              )}
              <Text fontWeight={700}>{high?.label}</Text>
            </Flex>
            {high.text && <Text fontSize={20}>{high.text}</Text>}
            {high.children && high.children}
          </Box>
        );
      })}
    </HStack>
  );
};

export default Highlights;
