/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import useSWR from 'swr';

import api from '~/shared/services/api';

export function useFetch<Data = any, Error = any>(
  urlParam: string,
  params?: object,
) {
  const { data, error, mutate } = useSWR<Data, Error>(urlParam, async (url) => {
    const response = await api.get<Data>(url, {
      params,
    });
    return response.data;
  });

  return { data, error, mutate };
}
