import React, { useCallback, useRef, useState } from 'react';
import { FiArrowLeft, FiUser, FiMail, FiLock } from 'react-icons/fi';
import { Link as ReactLink, useHistory } from 'react-router-dom';

import {
  useToast,
  Button,
  Image,
  Flex,
  Heading,
  Box,
  useColorMode,
  Text,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import logoBlueImg from '~/assets/logo-blue.svg';
import logoImg from '~/assets/logo.svg';
import Input from '~/shared/components/InputChakra';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const { colorMode } = useColorMode();
  const bgColor = { light: 'gray.50', dark: 'rgb(18, 18, 20)' };
  const bgInputColor = { light: '#fff', dark: 'rgb(18, 18, 20)' };
  const bgBorderInputColor = { light: 'inherit', dark: 'rgb(18, 18, 20)' };
  const bgBoxColor = { light: '#fff', dark: 'rgb(32, 32, 36)' };
  const textColor = { light: 'gray.400', dark: 'rgb(225, 225, 230)' };
  const textInputColor = { light: 'black', dark: 'rgb(225, 225, 230)' };
  const textTitle = { light: 'gray.700', dark: 'rgb(225, 225, 230)' };

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const addToast = useToast();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido!'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', data);

        history.push('/');

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Cadastro realizado!',
          description:
            'Verifique na sua caixa de entrar o link de confirmação de e-mail!',
        });
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao fazer o cadastro, tente novamente.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Box
        w="full"
        display="flex"
        flexDir="column"
        alignItems="center"
        minH="100vh"
        bg={bgColor[colorMode]}
      >
        <Box
          display="flex"
          flex="1 1 0%"
          flexDir="column"
          alignItems="center"
          w="100%"
          padding="32px"
          maxW={1000}
        >
          <Flex
            flex="1 1 0%"
            justifyContent="center"
            w="full"
            alignItems="center"
            padding="28px 0 50px"
          >
            <Flex
              flexDir={{ base: 'column', md: 'column', lg: 'row-reverse' }}
              alignItems="center"
              justifyContent="space-between"
              w="full"
            >
              <Flex
                flexDir="column"
                w="full"
                maxW="480px"
                paddingLeft={{ base: 0, lg: '100px' }}
              >
                <Image
                  width={150}
                  objectFit="contain"
                  src={colorMode === 'dark' ? logoImg : logoBlueImg}
                  alt="MyIPAC"
                  mb={10}
                />

                <Heading
                  as="h1"
                  fontSize="36px"
                  lineHeight="44px"
                  mb="24px"
                  color={textTitle[colorMode]}
                >
                  Junte-se a nós, <br /> preencha o formulário <br />e confirme
                  o seu <br />
                  E-mail
                </Heading>

                <ReactLink to="/">
                  <Heading
                    size="xs"
                    color="blue.500"
                    mb={5}
                    display="flex"
                    alignItems="center"
                  >
                    <FiArrowLeft size={18} />
                    Voltar para login
                  </Heading>
                </ReactLink>
              </Flex>
              <Box
                w="full"
                maxW="480px"
                bg={bgBoxColor[colorMode]}
                rounded="sm"
                padding={{ base: '32px', sm: '64px' }}
                pos="relative"
              >
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Heading
                    as="h1"
                    fontSize="25px"
                    mb="39px"
                    color={textTitle[colorMode]}
                  >
                    Crie sua conta
                  </Heading>
                  <Input
                    name="name"
                    icon={FiUser}
                    placeholder="Seu nome"
                    autoFocus
                    background={bgInputColor[colorMode]}
                    borderColor={bgBorderInputColor[colorMode]}
                    color={textInputColor[colorMode]}
                  />

                  <Input
                    name="email"
                    icon={FiMail}
                    placeholder="Seu E-mail"
                    background={bgInputColor[colorMode]}
                    borderColor={bgBorderInputColor[colorMode]}
                    color={textInputColor[colorMode]}
                  />

                  <Input
                    name="password"
                    icon={FiLock}
                    type="password"
                    placeholder="Sua senha"
                    isPassword
                    background={bgInputColor[colorMode]}
                    borderColor={bgBorderInputColor[colorMode]}
                    color={textInputColor[colorMode]}
                  />

                  <Input
                    name="password_confirmation"
                    icon={FiLock}
                    type="password"
                    placeholder="Confirme sua senha"
                    isPassword
                    background={bgInputColor[colorMode]}
                    borderColor={bgBorderInputColor[colorMode]}
                    color={textInputColor[colorMode]}
                  />

                  <Box mt={5} textAlign="center">
                    <Text
                      fontSize="sm"
                      color={textColor[colorMode]}
                      lineHeight="1.25"
                      display="inline-flex"
                    >
                      Ao se registrar, você aceita nossos
                    </Text>
                    <ReactLink to="/">
                      <Heading
                        size="xs"
                        color="blue.500"
                        display="inline-flex"
                        mx={1}
                      >
                        termos de uso
                      </Heading>
                    </ReactLink>
                    <Text
                      fontSize="sm"
                      color={textColor[colorMode]}
                      lineHeight="1.25"
                      display="inline-flex"
                    >
                      e a nossa
                    </Text>
                    <ReactLink to="/">
                      <Heading
                        size="xs"
                        color="blue.500"
                        display="inline-flex"
                        mx={1}
                      >
                        política de privacidade.
                      </Heading>
                    </ReactLink>
                  </Box>

                  <Button
                    mt={5}
                    variant="solid"
                    colorScheme="blue"
                    isLoading={loading}
                    isDisabled={loading}
                    width="100%"
                    size="lg"
                    type="submit"
                  >
                    CADASTRAR
                  </Button>
                </Form>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default SignUp;
