import React, { useMemo } from 'react';

import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';

interface Props {
  stepsTotal: number;
  currentSteps: number;
  stepsTitles: string[];
}

const StepsForm: React.FC<Props> = ({
  stepsTotal,
  currentSteps,
  stepsTitles,
}) => {
  const value = useMemo(() => {
    return (100 / stepsTotal) * currentSteps;
  }, [stepsTotal, currentSteps]);

  return (
    <Box w="full">
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <CircularProgress
            value={value}
            color="green"
            size="60px"
            thickness={0.15}
          >
            <CircularProgressLabel>{`${currentSteps}/${stepsTotal}`}</CircularProgressLabel>
          </CircularProgress>
        </Box>
        <Flex
          flexDir="column"
          alignItems="flex-end"
          justifyContent="center"
          mb={2}
        >
          <Heading size="md" as="h5" color="gray.700" fontWeight="medium">
            {stepsTitles[currentSteps - 1]}
          </Heading>
          {currentSteps !== stepsTotal && (
            <Text fontSize="xs" color="gray.500">
              Próxima: {stepsTitles[currentSteps]}
            </Text>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default StepsForm;
