/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Box, Heading } from '@chakra-ui/react';
import Chart, {
  ChartDataSets,
  ChartOptions,
  Chart as ChartLabel,
  ChartColor,
  Scriptable,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Content } from './styles';

interface Color {
  backgroundColor: string;
  borderColor: string;
  color: string;
}

export interface PropsChart {
  title?: string;
  // values: number[] | string[];
  labels: string[];
  dataLabels: string[] | number[];
  datasets: ChartDataSets[];
  options?: ChartOptions;
}

export interface Props {
  data: PropsChart;
  enablePluginDataLabel?: boolean;
  width?: number;
  height?: number;
  selectChart(value: string | number): void;
  colors?: ChartColor | ChartColor[] | Scriptable<ChartColor>;
  enableLegend?: boolean;
  // datasets: Dataset[];
}

const DoughnutChart: React.FC<Props> = ({
  data,
  enablePluginDataLabel = false,
  height,
  width,
  selectChart,
  colors,
  enableLegend = true,
  // datasets,
}) => {
  const { title, datasets, labels, dataLabels, options } = data;

  ChartLabel.plugins.unregister(ChartDataLabels);
  // labels.push('a');
  const defaultTheme = [
    '#F56565',
    '#2D3748',
    '#ECC94B',
    '#2A69AC',
    '#f72424',
    '#1485ff',
  ];

  const dataChart = {
    labels,
    datasets: datasets.map((dataSet) => {
      const { data: dataValues } = dataSet;

      return {
        ...dataSet,
        // data: dataValues,
        backgroundColor: colors || defaultTheme,
      };
    }),
    /*  datasets: [
      {
        label: datasets[0].label,
        data: [...datasets[0].data, 0, 0],
        backgroundColor: colors || ['#F56565', '#2D3748', '', ''],
        hoverBackgroundColor: colors || [
          '#F56565',
          '#2D3748',
          '#ECC94B',
          '#2A69AC',
        ],
        hoverBorderColor: colors || [
          '#F56565',
          '#2D3748',
          '#ECC94B',
          '#2A69AC',
        ],
        // margin: '20px',
        // fill: { above: 'blue', below: 'red', target: { value: 350 } },
        // borderWidth: 10,
        // borderJoinStyle: 'round',
        // borderAlign: 'inner',
        // circular: true,
        // borderDashOffset: 2.5,
      },
      {
        label: datasets[1].label,
        data: [0, 0, ...datasets[1].data],
        backgroundColor: colors || ['', '', '#f72424', '#1485ff'],
      },
    ], */
  };

  // useEffect(())

  const handleElementChart = useCallback(
    (dataset) => {
      if (dataset[0]?._view) {
        const index = dataset[0]?._index;
        const value = dataLabels[index];
        selectChart(value);
      }
    },
    [dataLabels, selectChart],
  );
  return (
    <Box
      display="flex"
      flexDir="column"
      justifyContent="center"
      rounded="sm"
      w="full"
      maxW={400}
      /* borderWidth={1}
      borderColor="gray.50" */
      mx="auto"
      mb={5}
    >
      {title && (
        <Heading
          as="h5"
          size="sm"
          fontWeight="normal"
          mb={4}
          color="gray.500"
          textAlign="center"
          bg="gray.50"
          p={2}
        >
          {title}
        </Heading>
      )}

      <Doughnut
        height={height || 100}
        width={width || 100}
        data={dataChart}
        getElementsAtEvent={handleElementChart}
        // redraw // Habilitar para testes
        plugins={enablePluginDataLabel ? [ChartDataLabels] : undefined}
        options={
          options || {
            legend: {
              display: enableLegend,
              position: 'bottom',
              labels: {
                boxWidth: 15,
                fontSize: 15,
              },
            },
          }
        }
      />
    </Box>
  );
};

export default DoughnutChart;
