import React, { useMemo, useState } from 'react';
import { FaRegEye, FaEye } from 'react-icons/fa';
import {
  FiEye,
  FiDownload,
  FiTrash,
  FiMoreVertical,
  FiLink,
} from 'react-icons/fi';

import {
  Flex,
  Checkbox,
  Image,
  Text,
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Heading,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import filesize from 'filesize';

import { PopFile } from '~/modules/activityExecution/@types/activity';
import CheckboxInput from '~/shared/components/InputChakra/CheckboxChakra';
import api from '~/shared/services/api';
import download from '~/utils/downloadFile';
import { getPreviewFile } from '~/utils/getFileIconWithType';

import { NewFileDB, PropsDeleteUpdateFile, Activity } from '../index';
import { BoxLink, BoxPreviewIcon, GridFiles } from './styles';

interface TemplateGrid {
  templateColumns: string | string[];
  justifyContent: string | string[];
  alignItems: string | string[];
  item: {
    flexDirection: 'column' | 'row';
    flexWrapButton:
      | 'inherit'
      | 'wrap'
      | '-moz-initial'
      | 'initial'
      | 'revert'
      | 'unset'
      | 'nowrap'
      | 'wrap-reverse';
    textAligin: 'center' | 'left';
    orderDownload: number;
    justifyContentButtons: string | string[];
  };
}

interface Props {
  isFinished?: boolean;
  files: NewFileDB[];
  isSessionUser: boolean;
  enablePreview: boolean;
  sendEmail?: boolean;
  predecessor?: Activity;
  showVertical?: boolean;
  openModalDeleteSavedFile(file: PropsDeleteUpdateFile): void;
  setCountAndSizeSelectedFiles(fileId: number): void;
  setDownloadingFile(fileId: number, value: boolean): void;
}

export interface PopFileSelect extends PopFile {
  dirOriginal?: string;
  isChecked: boolean;
  isDownloading: boolean;
}

interface PropsLocal {
  files: PopFileSelect[];
  enablePreview: boolean;
  sendEmail?: boolean;
  showVertical?: boolean;
  openModalDeleteSavedFile(file: PropsDeleteUpdateFile): void;
  switchSelectedFile(fileId: number): void;
  setDownloadingFile(fileId: number, value: boolean): void;
  setFileMention?(name: string, url: string): void;
}

const FilesGrid: React.FC<Props> = ({
  files,
  isFinished = false,
  predecessor = null,
  isSessionUser,
  enablePreview = false,
  sendEmail = false,
  showVertical = true,
  openModalDeleteSavedFile,
  setCountAndSizeSelectedFiles,
  setDownloadingFile,
}) => {
  const addToast = useToast();

  const handleDownloadFile = (id: number): void => {
    // Resolver problema em que as renderizações fazem os componentes com imagem piscarem

    // setLoadingImg(true);

    // const selectedPredecessor = predecessors.find((pred) => pred.id === predecessorId);

    setDownloadingFile(id, true);

    api
      .get(`cloud/planning/files/download/${id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const downloadLink = document.getElementById('downloadFile');

        downloadLink?.setAttribute('href', url);
        downloadLink?.setAttribute(
          'download',
          response.headers['content-description'],
        );

        downloadLink?.click();
      })
      .catch((err) => {
        addToast({
          title: `Erro`,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          description: err.response.data?.error,
        });
      })
      .finally(() => {
        setDownloadingFile(id, false);
      });
  };

  const stylesGrid = useMemo<TemplateGrid>(() => {
    if (showVertical) {
      return {
        templateColumns: '1fr',
        justifyContent: 'flex-start',
        alignItems: 'center',
        item: {
          flexDirection: 'row',
          flexWrapButton: 'nowrap',
          textAligin: 'left',
          orderDownload: 2,
          justifyContentButtons: 'flex-end',
        },
      };
    }

    return {
      templateColumns: 'repeat(auto-fit, 150px)',
      justifyContent: ['space-between', 'space-start'],
      alignItems: 'center',
      item: {
        flexDirection: 'column',
        flexWrapButton: 'wrap',
        textAligin: 'center',
        orderDownload: 4,
        justifyContentButtons: 'space-betwen',
      },
    };
  }, [showVertical]);

  const FlexButtons: React.FC<{
    fileProps: NewFileDB;
  }> = ({ fileProps }): any => {
    /* Apesar de manter o código mais limpo, utilizando vários componentes, pode ser que essa forma de renderização separada
    cause comportamentos inesperados. Por exemplo, ao utilizar o draft js, estando o foco no editor, ao clicar no botão de download
    ou de menção as ações não aconteciam na primeira vez, é como se o foco saisse do componente editor, fosse para o componente fileGrid
    mas os botões ainda não receberam o foco, para executar o onClick, clicando uma segunda vez era possível realizar a ação. Independente de
    ser uma React.FC ou função simples. */
    return (
      <Flex>
        <IconButton
          aria-label="Baixar"
          size="sm"
          mr={2}
          icon={<FiDownload />}
          order={2}
          id={fileProps.id.toString()}
          onClick={() => handleDownloadFile(fileProps.id)}
          colorScheme="blue"
          variant="outline"
          isLoading={fileProps.isDownloading}
        />
        {isSessionUser && !sendEmail && !predecessor && !isFinished && (
          <IconButton
            aria-label="Deletar"
            size="sm"
            onClick={() =>
              openModalDeleteSavedFile({
                id: fileProps.id,
                file_name: fileProps.file_name || fileProps.file_name_slug,
                file_name_slug: fileProps.file_name_slug,
                basename: fileProps.basename,
              })
            }
            variant="outline"
            colorScheme="red"
            icon={<FiTrash />}
            order={3}
          />
        )}
      </Flex>
    );
  };

  return (
    <>
      {predecessor && (
        <Box>
          <hr
            style={{
              marginBottom: '-13px',
            }}
          />

          <Heading size="sm" px={4} background="#FFFF" display="inline-block">
            {`${predecessor.order} - ${predecessor.name}`}
          </Heading>
        </Box>
      )}

      <GridFiles gap="20px" my={6}>
        {files.map((file) => {
          return (
            <Flex
              justifyContent="space-between"
              alignItems="flex-end"
              p={3}
              key={file.id}
              opacity={!sendEmail || file.isChecked ? '1' : '65%'}
              borderWidth="2px"
              borderColor={
                !sendEmail || file.isChecked ? '#8dc2ed' : 'gray.100'
              }
              borderRadius={4}
              minWidth="270px"
              bg="#FFF"
              boxShadow="md"
              transition="opacity 0.25s"
            >
              <Flex flexDirection="column" w="full">
                {sendEmail && (
                  <Flex justifyContent="space-between">
                    <Checkbox
                      size="lg"
                      colorScheme="green"
                      mb={2}
                      onChange={() => setCountAndSizeSelectedFiles(file.id)}
                      defaultIsChecked
                    />
                  </Flex>
                )}
                {enablePreview && (
                  <BoxLink
                    position="relative"
                    href={file.preview_url}
                    isExternal
                    rel="noopener noreferrer"
                    _focus={{
                      boxShadow: 'none',
                    }}
                    mb={4}
                  >
                    <Flex width="full" height="full" justifyContent="center">
                      <Image
                        borderRadius="sm"
                        height="full"
                        maxWidth="270px"
                        src={getPreviewFile(
                          file.preview_url,
                          file.mimetype,
                          file.extension,
                        )}
                        objectFit="cover"
                      />
                    </Flex>
                    <BoxPreviewIcon
                      position="absolute"
                      top={0}
                      left={0}
                      width="full"
                      height="full"
                      alignItems="center"
                      display={!sendEmail || file.isChecked ? 'flex' : 'none'}
                      justifyContent="center"
                      className="file-preview"
                      backgroundColor="rgba(255,255,255,0.5)"
                    >
                      <Flex flexDirection="column" alignItems="center">
                        {/* <FiEye size={30} /> */}
                        <FaRegEye size={30} />
                        <Text fontWeight="500" fontSize="2xl">
                          Visualizar
                        </Text>
                      </Flex>
                    </BoxPreviewIcon>
                  </BoxLink>
                )}

                {/* 240px porque as bordas e os preenchimentos dão 30px, sem esse valor fixo no elementos o Grid auto da problemas */}
                <Box width="240px">
                  <Text
                    // isTruncated
                    title={file.file_name}
                    noOfLines={1}
                    fontSize="18px"
                    color="blue.500"
                    wordBreak="break-word"
                  >
                    {file.file_name}
                  </Text>
                </Box>

                <Flex justifyContent="space-between">
                  <Box>
                    <Text fontSize="sm">
                      <b>Classificação:</b> {file.file_classification.name}
                    </Text>

                    <Text fontSize="sm">
                      <b>Tipo:</b> {file.extension}
                    </Text>
                    <Text fontSize="sm">
                      <b>Tamanho:</b> {filesize(file.size)}
                    </Text>
                  </Box>
                  <Box alignSelf="flex-end">
                    {showVertical ? (
                      <FlexButtons fileProps={file} />
                    ) : (
                      <Popover>
                        <PopoverTrigger>
                          <Button size="sm" leftIcon={<FiMoreVertical />}>
                            Ações
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverBody justifyContent="center">
                            <FlexButtons fileProps={file} />
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          );
        })}

        {/* {savedFiles &&
  savedFiles.map((file) => {
    return (
      <>
        <Image
          borderRadius="sm"
          height="60px"
          src={test}
          fallbackSrc="https://via.placeholder.com/150"
          mr={2}
        />
        <Flex
          flexDirection="column"
          textAlign={stylesGrid.item.textAligin}
        >
          <Text>{file.file_name}</Text>
          <Text>{file.file_classification_id}</Text>
          <Text fontSize="xs">Tipo: PDF</Text>
        </Flex>
        {showVertical ? (
          <FlexButtons />
        ) : (
          <Popover>
            <PopoverTrigger>
              <Button size="sm" leftIcon={<FiMoreVertical />}>
                Ações
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody justifyContent="center">
                <FlexButtons />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </>
    );
  })} */}
      </GridFiles>
    </>
  );
};

export default FilesGrid;

export const FilesGridLocal: React.FC<PropsLocal> = ({
  files,
  enablePreview = false,
  sendEmail = false,
  showVertical = true,
  setDownloadingFile,
  switchSelectedFile,
  setFileMention,
}) => {
  const addToast = useToast();

  const handleDownloadFile = (id: number): void => {
    // Resolver problema em que as renderizações fazem os componentes com imagem piscarem

    // setLoadingImg(true);

    // const selectedPredecessor = predecessors.find((pred) => pred.id === predecessorId);

    setDownloadingFile(id, true);

    api
      .get(`cloud/planning/files/download/${id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const downloadLink = document.getElementById('downloadFile');

        downloadLink?.setAttribute('href', url);
        downloadLink?.setAttribute(
          'download',
          response.headers['content-description'],
        );

        downloadLink?.click();
      })
      .catch((err) => {
        addToast({
          title: `Erro`,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          description: err.response.data?.error,
        });
      })
      .finally(() => {
        setDownloadingFile(id, false);
      });
  };

  const stylesGrid = useMemo<TemplateGrid>(() => {
    if (showVertical) {
      return {
        templateColumns: '1fr',
        justifyContent: 'flex-start',
        alignItems: 'center',
        item: {
          flexDirection: 'row',
          flexWrapButton: 'nowrap',
          textAligin: 'left',
          orderDownload: 2,
          justifyContentButtons: 'flex-end',
        },
      };
    }

    return {
      templateColumns: 'repeat(auto-fit, 150px)',
      justifyContent: ['space-between', 'space-start'],
      alignItems: 'center',
      item: {
        flexDirection: 'column',
        flexWrapButton: 'wrap',
        textAligin: 'center',
        orderDownload: 4,
        justifyContentButtons: 'space-betwen',
      },
    };
  }, [showVertical]);

  function FlexButtonsLocal(props: { fileProps: PopFileSelect }): any {
    const { fileProps } = props;
    return (
      <Flex>
        <IconButton
          aria-label="Baixar"
          size="sm"
          mr={2}
          icon={<FiDownload />}
          order={2}
          id={fileProps.id.toString()}
          onClick={() =>
            download(
              document.body,
              fileProps.dirOriginal || '',
              fileProps.nome,
              fileProps.mimetype,
            )
          }
          colorScheme="blue"
          variant="outline"
          isLoading={fileProps.isDownloading}
        />
        {setFileMention && (
          <Tooltip label="Mencionar arquivo no POP" hasArrow>
            <IconButton
              aria-label="Mencionar Arquivo"
              size="sm"
              mr={2}
              icon={<FiLink />}
              order={2}
              onClick={() => {
                // console.log('mencionar 1');
                setFileMention(fileProps.nome_original, fileProps.dir);
              }}
              colorScheme="yellow"
              variant="outline"
              isLoading={fileProps.isDownloading}
              autoFocus={false}
            />
          </Tooltip>
        )}
        {/* <IconButton
          aria-label="Deletar"
          size="sm"
          onClick={() =>
            openModalDeleteSavedFile({
              id: fileProps.id,
              file_name: fileProps.nome_original,
              file_name_slug: fileProps.nome,
              basename: fileProps.basename,
            })
          }
          variant="outline"
          colorScheme="red"
          icon={<FiTrash />}
          order={3}
        /> */}
      </Flex>
    );
  }

  return (
    <>
      <GridFiles gap="20px" my={6}>
        {files.map((file, i) => {
          return (
            <Flex
              justifyContent="space-between"
              alignItems="flex-end"
              p={3}
              key={file.id}
              opacity={!sendEmail || file.isChecked ? '1' : '65%'}
              borderWidth="2px"
              borderColor={
                !sendEmail || file.isChecked ? '#8dc2ed' : 'gray.100'
              }
              borderRadius={4}
              minWidth="270px"
              bg="#FFF"
              boxShadow="md"
              transition="opacity 0.25s"
            >
              <Flex flexDirection="column" w="full">
                {sendEmail && (
                  <Flex justifyContent="space-between">
                    <CheckboxInput
                      name={`saved_files[${i}]`}
                      options={[
                        {
                          id: file.id.toString(),
                          label: '',
                          value: file.id.toString(),
                          variantColor: 'green',
                          isChecked: true,
                        },
                      ]}
                      sizeChakra="lg"
                      mb={2}
                      onChange={() => switchSelectedFile(file.id)}
                    />
                  </Flex>
                )}
                {enablePreview && (
                  <BoxLink
                    position="relative"
                    href={file.dir}
                    isExternal
                    rel="noopener noreferrer"
                    _focus={{
                      boxShadow: 'none',
                    }}
                    mb={4}
                  >
                    <Flex width="full" height="full" justifyContent="center">
                      <Image
                        borderRadius="sm"
                        height="full"
                        maxWidth="270px"
                        src={getPreviewFile(
                          file.dir,
                          file.mimetype,
                          file.extensao,
                        )}
                        objectFit="cover"
                      />
                    </Flex>
                    <BoxPreviewIcon
                      position="absolute"
                      top={0}
                      left={0}
                      width="full"
                      height="full"
                      alignItems="center"
                      display={!sendEmail || file.isChecked ? 'flex' : 'none'}
                      justifyContent="center"
                      className="file-preview"
                      backgroundColor="rgba(255,255,255,0.5)"
                    >
                      <Flex flexDirection="column" alignItems="center">
                        {/* <FiEye size={30} /> */}
                        <FaRegEye size={30} />
                        <Text fontWeight="500" fontSize="2xl">
                          Visualizar
                        </Text>
                      </Flex>
                    </BoxPreviewIcon>
                  </BoxLink>
                )}

                {/* 240px porque as bordas e os preenchimentos dão 30px, sem esse valor fixo no elementos o Grid auto da problemas */}
                <Box width="240px">
                  <Text
                    // isTruncated
                    title={file.nome_original}
                    noOfLines={1}
                    fontSize="18px"
                    color="blue.500"
                    wordBreak="break-word"
                  >
                    {file.nome_original}
                  </Text>
                </Box>

                <Flex justifyContent="space-between">
                  <Box>
                    <Text fontSize="sm">
                      <b>Tipo:</b> {file.extensao}
                    </Text>
                    <Text fontSize="sm">
                      <b>Tamanho:</b> {file.tamanho}
                    </Text>
                  </Box>
                  <Box alignSelf="flex-end">
                    {showVertical ? (
                      <>
                        <Flex>
                          <IconButton
                            aria-label="Baixar"
                            size="sm"
                            mr={2}
                            icon={<FiDownload />}
                            order={2}
                            id={file.id.toString()}
                            onClick={() =>
                              download(
                                document.body,
                                file.dirOriginal || '',
                                file.nome,
                                file.mimetype,
                              )
                            }
                            colorScheme="blue"
                            variant="outline"
                            isLoading={file.isDownloading}
                          />
                          {setFileMention && (
                            <Tooltip label="Mencionar arquivo no POP" hasArrow>
                              <IconButton
                                aria-label="Mencionar Arquivo"
                                size="sm"
                                mr={2}
                                icon={<FiLink />}
                                order={2}
                                onClick={() => {
                                  // console.log('mencionar 1');
                                  setFileMention(file.nome_original, file.dir);
                                }}
                                colorScheme="yellow"
                                variant="outline"
                                isLoading={file.isDownloading}
                                autoFocus={false}
                              />
                            </Tooltip>
                          )}
                          {/* <IconButton
                     aria-label="Deletar"
                     size="sm"
                     onClick={() =>
                       openModalDeleteSavedFile({
                         id: fileProps.id,
                         file_name: fileProps.nome_original,
                         file_name_slug: fileProps.nome,
                         basename: fileProps.basename,
                       })
                     }
                     variant="outline"
                     colorScheme="red"
                     icon={<FiTrash />}
                     order={3}
                   /> */}
                        </Flex>
                      </>
                    ) : (
                      <Popover>
                        <PopoverTrigger>
                          <Button size="sm" leftIcon={<FiMoreVertical />}>
                            Ações
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverBody justifyContent="center">
                            <>
                              <Flex>
                                <IconButton
                                  aria-label="Baixar"
                                  size="sm"
                                  mr={2}
                                  icon={<FiDownload />}
                                  order={2}
                                  id={file.id.toString()}
                                  onClick={() =>
                                    download(
                                      document.body,
                                      file.dirOriginal || '',
                                      file.nome,
                                      file.mimetype,
                                    )
                                  }
                                  colorScheme="blue"
                                  variant="outline"
                                  isLoading={file.isDownloading}
                                />
                                {setFileMention && (
                                  <Tooltip
                                    label="Mencionar arquivo no POP"
                                    hasArrow
                                  >
                                    <IconButton
                                      aria-label="Mencionar Arquivo"
                                      size="sm"
                                      icon={<FiLink />}
                                      order={2}
                                      onClick={() => {
                                        // console.log('mencionar 1');
                                        setFileMention(
                                          file.nome_original,
                                          file.dir,
                                        );
                                      }}
                                      colorScheme="yellow"
                                      variant="outline"
                                    />
                                  </Tooltip>
                                )}
                                {/* <IconButton
                       aria-label="Deletar"
                       size="sm"
                       onClick={() =>
                         openModalDeleteSavedFile({
                           id: fileProps.id,
                           file_name: fileProps.nome_original,
                           file_name_slug: fileProps.nome,
                           basename: fileProps.basename,
                         })
                       }
                       variant="outline"
                       colorScheme="red"
                       icon={<FiTrash />}
                       order={3}
                     /> */}
                              </Flex>
                            </>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          );
        })}

        {/* {savedFiles &&
  savedFiles.map((file) => {
    return (
      <>
        <Image
          borderRadius="sm"
          height="60px"
          src={test}
          fallbackSrc="https://via.placeholder.com/150"
          mr={2}
        />
        <Flex
          flexDirection="column"
          textAlign={stylesGrid.item.textAligin}
        >
          <Text>{file.file_name}</Text>
          <Text>{file.file_classification_id}</Text>
          <Text fontSize="xs">Tipo: PDF</Text>
        </Flex>
        {showVertical ? (
          <FlexButtons />
        ) : (
          <Popover>
            <PopoverTrigger>
              <Button size="sm" leftIcon={<FiMoreVertical />}>
                Ações
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody justifyContent="center">
                <FlexButtons />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </>
    );
  })} */}
      </GridFiles>
    </>
  );
};
