// import useCan from '~/hooks/useCan';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  IconButton,
  useToast,
  Text,
  Heading,
  useDisclosure,
  Radio,
  RadioGroup,
  HStack,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Empty } from 'antd';
import { isArray } from 'lodash';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import RangeDateMonthly from '~/shared/components/RangeDateMonthly';
import Search from '~/shared/components/Search';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import Pagination from '../../../../shared/components/Pagination';
import BadgeStatusAttendance from '../../components/BadgeStatusAttendance';
import LinkBadgeStatusAttendance from '../../components/LinkBadgeStatusAttendance';
import { GroupBadge, TableTheadFixed, TrBorder, BoxRadio } from './styles';

interface FormData {
  demanda: number;
  periodo: string[];
  definicao_semana: string;
}

interface SelectData {
  id: number;
  name: string;
  value: number;
  label: string;
}

interface InitialSelects {
  demands: SelectData[];
}

export interface FilterData {
  demand: number;
  start_date: string;
  end_date: string;
  week_definition: string;
  page: number;
  search?: string;
}

interface WeeklyDemandsReport {
  period: Period[];
  companies: Companies[];
  search?: string;
  pagination: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    prev_page_url?: number;
    to: number;
    total: number;
  };
}

interface Companies {
  company_id: number;
  company_name: string;
  attendances: Attendances[];
}

export interface Attendances {
  attendance_id: number;
  demand_id?: number;
  archived?: boolean;
  status_id: number;
  created_at: string;
  status: string;
  status_cor: string;
  date_sets_week: string;
  month: number;
  year: number;
  year_reference: number;
  week_year: number;
  day_week: number;
}

interface Period {
  year: number;
  months: Month[];
}

interface Month {
  month: number;
  month_name: string;
  weeks: Array<{
    year: number;
    month_name: string;
    year_reference: number;
    month: number;
    week_year: number;
    start_week: number;
    end_week: number;
  }>;
}

const WeeklyDemands: React.FC = () => {
  // const can = useCan();
  const formRef = useRef<FormHandles>(null);
  const addToast = useToast();

  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [loadReload, setloadReload] = useState(false);
  const [dataSelects, setDataSelects] = useState<InitialSelects>(
    {} as InitialSelects,
  );
  const [dataFilters, setDataFilters] = useState<FilterData>({} as FilterData);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();

  const [
    weeklyDemandsData,
    setWeeklyDemandsData,
  ] = useState<WeeklyDemandsReport>({} as WeeklyDemandsReport);

  useEffect(() => {
    setLoading(true);
    function loadFilters(): void {
      api
        .get<SelectData[]>('/reports/weekly-demands/filters')
        .then((response) => {
          if (response.data && isArray(response.data)) {
            setDataSelects((state) => ({
              ...state,
              demands: response.data?.map((dem) => {
                return {
                  ...dem,
                  value: dem.id,
                  label: dem.name,
                };
              }),
            }));
          }

          setLoading(false);
        })
        .catch((err) => {
          const error = err.response?.data?.error;

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Erro não foi possível listar as demandas, tente novamente!',
            description: error,
          });

          setLoading(false);
        });
    }

    loadFilters();
  }, [addToast]);

  const loadDataWeeklyDemands = useCallback(
    async (data: FilterData) => {
      setReportLoading(true);
      setDataFilters(data);

      try {
        const response = await api.get<WeeklyDemandsReport>(
          `reports/weekly-demands`,
          {
            params: {
              ...data,
            },
          },
        );

        setWeeklyDemandsData(response.data);

        setReportLoading(false);
      } catch (err) {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error ||
              'Ocorreu um erro ao carregar dados do relatório.',
            isClosable: true,
          });

          if (weeklyDemandsData)
            setWeeklyDemandsData({} as WeeklyDemandsReport);

          if (dataFilters.search)
            setDataFilters({ ...dataFilters, search: undefined });

          setReportLoading(false);

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops! Ocorreu um erro no servidor.',
          isClosable: true,
        });
        setReportLoading(false);
      }
    },
    [addToast, weeklyDemandsData, dataFilters],
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      // console.log(data);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          demanda: Yup.string().required('Necessário escolher uma demanda'),
          periodo: Yup.array()
            .of(Yup.string().required('Período obrigatório'))
            .required(),
          definicao_semana: Yup.string().required(
            'Necessário escolher a opção de definição de semana',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formattedFilter: FilterData = {
          demand: data.demanda,
          start_date: data.periodo[0],
          end_date: data.periodo[1],
          week_definition: data.definicao_semana,
          search: dataFilters.search || undefined,
          page: 1,
        };

        await loadDataWeeklyDemands(formattedFilter);

        // formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          if (errors.demanda) {
            errors.demanda = 'Necessário escolher uma demanda';
          }

          if (errors[`periodo[0]`]) {
            errors.periodo = 'Período obrigatório';
          }

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
            isClosable: true,
          });

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops! Ocorreu um erro no servidor.',
          isClosable: true,
        });
      }
    },
    [addToast, loadDataWeeklyDemands, dataFilters.search],
  );

  const handleNewPage = useCallback(
    (pageNew: number) => {
      loadDataWeeklyDemands({ ...dataFilters, page: pageNew });
    },
    [dataFilters, loadDataWeeklyDemands],
  );

  const handleSearch = useCallback(
    async (searchValue: string) => {
      setSearchActive(true);
      setLoadingSearch(true);
      setDataFilters((state) => ({ ...state, page: 1, search: searchValue }));

      const dataSearch = { ...dataFilters, page: 1, search: searchValue };

      try {
        const response = await api.get<WeeklyDemandsReport>(
          `reports/weekly-demands`,
          {
            params: {
              ...dataSearch,
            },
          },
        );

        setWeeklyDemandsData(response.data);
      } catch (err) {
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
            isClosable: true,
          });

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops!!!',
          description: 'Ocorreu um erro, tente novamente.',
          isClosable: true,
        });
      } finally {
        setLoadingSearch(false);
      }
    },
    [addToast, dataFilters],
  );

  const handleSearchClear = useCallback(() => {
    if (searchActive) {
      setloadReload((state) => !state);
      setSearchActive(false);

      loadDataWeeklyDemands({ ...dataFilters, page: 1, search: undefined });
    }
  }, [searchActive, dataFilters, loadDataWeeklyDemands]);

  return (
    <>
      <Box position="relative" width="full" height="full" minHeight="100vh">
        <SectionHeader
          title="Demandas Semanais"
          pagename="Relatório de demandas semanais"
          goBackLink="/dashboard"
        />

        {(loading || reportLoading) && (
          <Box
            pos="absolute"
            top={0}
            left={0}
            w="full"
            h="full"
            bg="rgba(255,255,255,0.5)"
            zIndex={6666}
          >
            <Loading />
          </Box>
        )}

        {!loading && (
          <>
            <Box>
              <Form
                initialData={dataFilters}
                onSubmit={handleSubmit}
                ref={formRef}
              >
                <Flex
                  flexDirection={['column', 'row']}
                  justifyContent="space-between"
                  width="full"
                >
                  <Flex flexDirection="column" width="full" pr={[0, 4]}>
                    <SelectChakra
                      name="demanda"
                      label="Demanda"
                      placeholder="Selecione uma demanda"
                      options={dataSelects.demands}
                      width="full"
                    />
                  </Flex>
                  <Flex
                    flexDirection="column"
                    justifyContent="flex-start"
                    width="full"
                  >
                    <RangeDateMonthly name="periodo" />
                  </Flex>
                </Flex>
                <BoxRadio>
                  <RadioChakra
                    help="Atenção: Ao definir a semana através das atividades iniciadas, se o atendimento não possuir nenhuma, ele não será listado"
                    name="definicao_semana"
                    isInline
                    label="Definição de semana para o atendimento"
                    options={[
                      {
                        value: 'min_planned_date',
                        label: 'Menor data entre atividades planejadas',
                      },
                      {
                        value: 'max_planned_date',
                        label: 'Maior data entre atividades planejadas',
                      },
                      {
                        value: 'min_accomplished_date',
                        label: 'Menor data entre atividades iniciadas',
                      },
                      {
                        value: 'max_accomplished_date',
                        label: 'Maior data entre atividades iniciadas',
                      },
                    ]}
                  />
                </BoxRadio>
                <Flex justifyContent="flex-start" mt={8} mb={5}>
                  <Button
                    colorScheme="blue"
                    size="lg"
                    onClick={() => formRef.current?.submitForm()}
                    px={4}
                    mx="auto"
                    minWidth="300px"
                  >
                    Filtrar
                  </Button>
                </Flex>
              </Form>
            </Box>

            {weeklyDemandsData?.period?.length ? (
              <>
                <Flex justifyContent="flex-end" alignItems="center" mb={5}>
                  <Search
                    loading={loadingSearch}
                    searchValue={(value) => handleSearch(value)}
                    searchClear={handleSearchClear}
                  />
                </Flex>
                <Box overflowX="auto">
                  <TableTheadFixed as="table" width="100%">
                    <Box
                      as="thead"
                      w="full"
                      p={4}
                      fontWeight="medium"
                      fontSize="sm"
                      borderTopWidth={1}
                      borderBottomWidth={1}
                      borderColor="gray.100"
                      textAlign="left"
                      bg="gray.50"
                    >
                      <TrBorder as="tr">
                        <th
                          rowSpan={2}
                          style={{
                            maxWidth: '280px',
                            paddingRight: 0,
                            paddingLeft: 0,
                          }}
                        >
                          <Text
                            px="10px"
                            py="10px"
                            fontSize="md"
                            textAlign="center"
                          >
                            Empresas
                          </Text>
                        </th>
                        {weeklyDemandsData.period &&
                          weeklyDemandsData.period?.map((periodMap) => {
                            return periodMap.months.map((monthMap) => {
                              return (
                                <th
                                  colSpan={monthMap.weeks.length}
                                  key={`${periodMap.year}.${monthMap.month}`}
                                >
                                  <Text textAlign="center" px="10px" py="10px">
                                    {monthMap.month_name}
                                  </Text>
                                </th>
                              );
                            });
                          })}
                      </TrBorder>
                      <TrBorder as="tr">
                        {weeklyDemandsData.period &&
                          weeklyDemandsData.period.map((periodMap) => {
                            return periodMap.months.map((monthMap) => {
                              return monthMap.weeks.map((weekMap) => {
                                return (
                                  <th
                                    key={`${periodMap.year}.${monthMap.month}.${weekMap.week_year}`}
                                    style={{
                                      minWidth: '200px',
                                    }}
                                  >
                                    <Text
                                      textAlign="center"
                                      py="10px"
                                      px="10px"
                                      whiteSpace="nowrap"
                                    >
                                      {`${weekMap.start_week} à ${weekMap.end_week}`}
                                    </Text>
                                  </th>
                                );
                              });
                            });
                          })}
                      </TrBorder>
                    </Box>
                    <Box as="tbody" w="full">
                      {weeklyDemandsData.companies &&
                        weeklyDemandsData.companies?.map((company) => {
                          return (
                            <TrBorder as="tr" key={company.company_id}>
                              <Box
                                as="td"
                                borderBottomWidth={1}
                                px="10px"
                                maxWidth="280px"
                                width="280px"
                              >
                                <Heading
                                  fontSize="1rem"
                                  title={company.company_name}
                                  isTruncated
                                >
                                  {company.company_name}
                                </Heading>
                              </Box>
                              {weeklyDemandsData?.period?.map(
                                (periodAttendence) => {
                                  return periodAttendence.months?.map(
                                    (monthMap) => {
                                      return monthMap.weeks?.map(
                                        (weekAttendance) => {
                                          return (
                                            <Box
                                              as="td"
                                              key={`${periodAttendence.year}.${monthMap.month}.${weekAttendance.week_year}`}
                                              borderBottomWidth={1}
                                              textAlign="center"
                                              py="15px"
                                              px="0"
                                            >
                                              <GroupBadge
                                                w="full"
                                                flexDirection="column"
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                {company.attendances
                                                  ?.filter(
                                                    (attendance) =>
                                                      attendance.year ===
                                                        periodAttendence.year &&
                                                      attendance.week_year ===
                                                        weekAttendance.week_year,
                                                  )
                                                  ?.map((attendanceFilter) => {
                                                    return (
                                                      <LinkBadgeStatusAttendance
                                                        key={
                                                          attendanceFilter.attendance_id
                                                        }
                                                        attendance={
                                                          attendanceFilter
                                                        }
                                                        demand_id={
                                                          dataFilters.demand
                                                        }
                                                      />
                                                    );
                                                  })}
                                              </GroupBadge>
                                            </Box>
                                          );
                                        },
                                      );
                                    },
                                  );
                                },
                              )}
                            </TrBorder>
                          );
                        })}
                    </Box>
                  </TableTheadFixed>
                </Box>

                <Box>
                  <Pagination
                    current_page={weeklyDemandsData.pagination.current_page}
                    to={weeklyDemandsData.pagination.to}
                    total={weeklyDemandsData.pagination.total}
                    last_page={weeklyDemandsData.pagination.last_page}
                    per_page={weeklyDemandsData.pagination.per_page}
                    setPage={(pg) => handleNewPage(pg)}
                  />
                </Box>
              </>
            ) : (
              <Empty
                style={{ fontSize: '25px', color: '#838383' }}
                description="Escolha uma demanda para listar as informações relacionadas"
                imageStyle={{ width: '100%', height: 200 }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default WeeklyDemands;
