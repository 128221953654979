import React, { useCallback } from 'react';
import { FiLogIn } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import rocketImg from '~/assets/rocket.png';
import { useAuth } from '~/hooks/auth';
import { useFetch } from '~/hooks/useFetch';

import { Container } from './styles';

const Planning: React.FC = () => {
  const { push } = useHistory();
  const { user } = useAuth();

  const { data: totalActivity } = useFetch<number>(
    `plannings/total-activity/${user.id}`,
  );

  const handlePlanning = useCallback(() => {
    push('/planejamento');
  }, [push]);
  return (
    <Container>
      <h4>Planejamento</h4>

      <div>
        <span>
          <h3>Olá, você tem</h3>
          <h5>atividades pendentes</h5>
        </span>

        <h2>{totalActivity || 0}</h2>
      </div>

      {totalActivity && totalActivity > 0 ? (
        <p>Acesse a página de planejamento para executa-la(s)</p>
      ) : (
        <span />
      )}

      <img src={rocketImg} alt="Rocket" />

      <div>
        <button type="button" onClick={handlePlanning}>
          <strong>Ver planejamento</strong>
          <span>
            <FiLogIn />
          </span>
        </button>
      </div>
    </Container>
  );
};

export default Planning;
