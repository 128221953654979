import { fadeInUp } from 'react-animations';

import { Box } from '@chakra-ui/react';
import styled, { keyframes } from 'styled-components';

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const transformAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface StatusProps {
  color: string;
}

export const CActivity = styled.div`
  display: flex;
  flex-direction: column;

  hr {
    //margin-top: 8px;
    margin-bottom: 15px;
  }

  animation: 0.3s ${fadeInUpAnimation};
`;

export const CInfoActivity = styled.div`
  position: relative;
  width: 100%;

  //margin-bottom: 10px;

  @media (max-width: 480px) {
    .btn-info-activity {
      display: none;
    }
  }

  .btn-info-activity {
    left: 0;
    top: 0;
    //padding-bottom: 0.4rem;
  }

  .btn-info-activity .container-slider-options .slider-options {
    width: 20px;
    transition: width 0.75s;
    > div.slider-options-buttons {
      overflow: hidden;
    }

    > .slider-options-icon {
      opacity: 1;
      transition-delay: 0.75s;
      transition-property: opacity;
    }
  }

  .btn-info-activity .container-slider-options .slider-options-icon {
    position: absolute;
    left: 10px; //O padding está sendo 20px
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }

  .btn-info-activity
    .container-slider-options
    .slider-options-buttons
    button
    ~ button {
    margin-left: 0.5rem;
  }

  .btn-info-activity .container-slider-options:hover .slider-options {
    width: 100%;
  }

  .btn-info-activity
    .container-slider-options:hover
    .slider-options
    .slider-options-buttons {
    margin: 0;
    // Necessário o padding para exibir a notificação de quantidade de arquivos
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .btn-info-activity .container-slider-options:hover .slider-options-icon {
    opacity: 0;
    transition: none;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  //border-bottom: 1px solid #f8f9fe;
  padding: 5px 0 5px 25px;
  opacity: 1;
  z-index: 1;

  //border-left: 4px solid #f1f5f7;

  @media (max-width: 1100px) {
    flex-direction: column;
    margin-left: 0;
    border-left: 0;
    //padding: 0 0 20px;
  }

  @media (max-width: 480px) {
    opacity: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  width: auto;

  > span,
  > div span {
    font-size: 13px;
    //line-height: 20px;

    display: flex;
    align-items: center;
    vertical-align: middle;

    &.spotlight {
      color: #169bd6;
      font-weight: 500;
      margin-top: 5px;

      small {
        font-size: 13px;
      }
    }

    strong {
      font-size: 13px;
    }

    small {
      margin-left: 5px;
      font-size: 13px;

      &.colorblue {
        color: #169bd6;
        font-weight: 500;
      }

      span {
        display: flex;
        align-items: center;
      }
    }

    &.competence {
      small {
        font-size: 14px;
        font-weight: 500;
        color: #169bd6;
      }
    }
  }

  > span.planning-execution-info {
    margin-top: 0.2em;

    @media (max-width: 365px) {
      flex-direction: column;
      align-items: flex-start;

      > div {
        > small {
          margin-left: 0;

          > span {
            margin-left: 0;

            > small:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  > span.realized-group {
    margin-top: 0.2em;

    div.badge-finish {
      align-self: baseline;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 86px;
      text-align: center;
      background-color: #f2f7ed;
      color: #2bb04e;
      /* background-color: #edf2f7;
      color: #2b6cb0; */
      font-weight: bold;
      padding: 1px 2px;
      border-radius: 4px;
      line-height: 1.3;

      strong {
        margin-right: 0.2rem;
      }
    }

    div.group-period {
      display: flex;
      color: #2b6cb0;
    }

    div.badge-date {
      background-color: #edf2f7;
      color: #2b6cb0;
      font-weight: 600;
      padding: 1px 10px;

      display: flex;
    }
  }

  /* @media (max-width: 1100px) {
    & + div {
      margin-top: 20px;
    }
  } */
`;

export const Header = styled(Box)`
  h3 {
    font-weight: 500;
    font-size: 16px;
    align-self: center;
    span {
      //border: 1px solid #1a202c;
      margin: 0 5px 0 0;
      font-size: 13px;
      font-weight: 600;
    }
  }

  > span {
    border: 1px solid #f3f5f76b;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
  }

  button {
    position: relative;
    background: transparent;
    border: 0;
    padding: 0 4px;

    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    &.help {
      cursor: help;

      &:active {
        transform: scale(1);
      }
    }

    &:active {
      transform: scale(0.9);
    }

    & + button {
      margin-left: 5px;
    }

    &:hover {
      > div {
        display: flex;
      }
      color: #169bd6;
    }
  }

  @media (max-width: 1100px) {
    h3 {
      width: 100%;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1100px) {
    justify-content: center;
  }
`;

export const AttendanceDescription = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 200px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  bottom: 20px;
  z-index: 1;

  animation: 0.2s ${transformAnimation};
  display: none;

  strong {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    font-size: 14px;
    background: #2f2e38;
    color: #fff;
    border-radius: 5px 5px 0 0;
    font-weight: 400;
  }

  small {
    padding: 10px;
    font-size: 2px 12px;
    text-align: left;
    color: #8694a9;
  }

  @media (max-width: 1100px) {
    right: 20px;
  }
`;
