import api from '~/shared/services/api';

export default function download(
  targetLink: HTMLElement,
  url: string,
  filename: string,
  mimetype?: string,
): void {
  const data = {
    url,
    filename,
    mimetype,
  };

  api.post('/download', data, { responseType: 'blob' }).then((response) => {
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));

    const downloadLink = document.createElement('a');

    downloadLink?.setAttribute('href', urlBlob);
    downloadLink?.setAttribute('download', filename);

    targetLink.appendChild(downloadLink);

    downloadLink?.click();

    downloadLink.remove();
  });
}
