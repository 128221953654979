import React, { ButtonHTMLAttributes } from 'react';
import {
  FiPlus,
  FiUser,
  FiCheckCircle,
  FiCalendar,
  FiEdit,
  FiEye,
  FiFlag,
  FiMaximize,
  FiMail,
  FiMinimize,
  FiMinusSquare,
  FiMoreVertical,
  FiRefreshCw,
  FiRepeat,
  FiShield,
  FiSlash,
  FiTrash,
  FiLock,
  FiUnlock,
  FiUsers,
  FiPlayCircle,
  FiX,
} from 'react-icons/fi';

import { BtnContainer } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon:
    | 'user'
    | 'users'
    | 'plus'
    | 'check_circle'
    | 'calendar'
    | 'edit'
    | 'eye'
    | 'flag'
    | 'maximize'
    | 'mail'
    | 'minimize'
    | 'minus'
    | 'more_vertical'
    | 'refresh'
    | 'repeat'
    | 'shield'
    | 'slash'
    | 'trash'
    | 'lock'
    | 'unlock'
    | 'play_circle'
    | 'x';
  title?: string;
  disabled?: boolean;
  typeColor?:
    | 'success'
    | 'danger'
    | 'info'
    | 'secundary'
    | 'primary'
    | 'notification'
    | 'warning';
};

const icons = {
  user: <FiUser size={20} />,
  users: <FiUsers size={20} />,
  plus: <FiPlus size={20} />,
  check_circle: <FiCheckCircle size={20} />,
  calendar: <FiCalendar size={20} />,
  edit: <FiEdit size={20} />,
  eye: <FiEye size={20} />,
  flag: <FiFlag size={20} />,
  maximize: <FiMaximize size={20} />,
  mail: <FiMail size={20} />,
  minimize: <FiMinimize size={20} />,
  minus: <FiMinusSquare size={20} />,
  more_vertical: <FiMoreVertical size={20} />,
  refresh: <FiRefreshCw size={20} />,
  repeat: <FiRepeat size={20} />,
  shield: <FiShield size={20} />,
  slash: <FiSlash size={20} />,
  trash: <FiTrash size={20} />,
  lock: <FiLock size={20} />,
  unlock: <FiUnlock size={20} />,
  play_circle: <FiPlayCircle size={20} />,
  x: <FiX size={20} />,
};

const ButtonIcon: React.FC<ButtonProps> = ({
  icon,
  disabled = false,
  typeColor = 'info',
  ...rest
}) => {
  return (
    <BtnContainer
      typeColor={typeColor}
      disabled={disabled}
      type="button"
      {...rest}
    >
      {icons[icon || 'plus']}
    </BtnContainer>
  );
};

export default ButtonIcon;
