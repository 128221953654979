import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { FiEdit, FiPlus, FiTrash, FiUser, FiHelpCircle } from 'react-icons/fi';

import {
  Box,
  IconButton,
  Button,
  Tag,
  Text,
  useDisclosure,
  useToast,
  Tooltip,
  Flex,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Step } from 'intro.js';
import * as Yup from 'yup';

import { Replace } from '~/helpers/Replace';
import { useAuth } from '~/hooks/auth';
import { useFetch } from '~/hooks/useFetch';
import AlertDialog from '~/shared/components/AlertDialog';
import EmptyPage from '~/shared/components/EmptyPage';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import ModalManageUsers, {
  UserProps,
} from '~/shared/components/ModalManageUsers';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import {
  Responsibility as ResponsibilityGeralInterface,
  User,
  Role,
} from '../../@types/user';
import { Container } from './styles';

type UserInterface = Replace<User, { responsibility?: string }>;

interface ResponsibilityFilter {
  responsibilities: FilterData[];
  roles: FilterData[];
}

interface FilterData {
  id: number;
  name: string;
  company_id: number;
  slug: string;
  label: string;
  value: number;
}

interface SelectedResponsibility {
  id: number;
  name: string;
}

interface StoreEditResponsibility {
  name: string;
  description: string;
  role_id: number;
  subordination_id: number;
}

interface ResponsibilityInterface extends ResponsibilityGeralInterface {
  users?: UserInterface[];
  usersModal?: UserProps[];
  updatingUsers?: boolean;
}

interface RefreshUsersResult {
  user: UserInterface;
  success?: boolean;
}

interface RoleSelect extends Role {
  id: number;
  name: string;
  slug: string;
  label: string;
  value: number;
}

export interface ResponsibilityProps {
  data?: ResponsibilityInterface[];
  responsibilities: ResponsibilityInterface[];
  current_page: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

/* O mutate mesmo com o parâmetro false, não está confiável, utilizar o cargo Contador PF com dois usuários para testar
 , se não funcionar, passar a utilizar state, como em departamentos, que está funcionando */

const Responsibility: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenUser,
    onOpen: onOpenUser,
    onClose: onCloseUser,
  } = useDisclosure();
  const formRef = useRef<FormHandles>(null);
  const cancelDeleteRef = useRef(null);
  const formRefEdit = useRef<FormHandles>(null);

  const [loadedResponsibilities, setLoadedResponsibilities] = useState(false);

  const [loading, setLoading] = useState(false);

  const [
    selectedResponsibilityDelete,
    setSelectedResponsibilityDelete,
  ] = useState<SelectedResponsibility | null>(null);
  const [
    selectedResponsibility,
    setSelectedResponsibility,
  ] = useState<ResponsibilityInterface | null>(null);

  const [
    toggleSuperiorResponsibility,
    setToggleSuperiorResponsibility,
  ] = useState(false);

  const [
    selectedSuperiorResponsibility,
    setSelectedSuperiorResponsibility,
  ] = useState<ResponsibilityInterface>();

  const [modalDataEdit, setModalDataEdit] = useState<ResponsibilityInterface>(
    {} as ResponsibilityInterface,
  );
  const [page, setPage] = useState(1);

  const [data, setData] = useState<ResponsibilityProps>(
    {} as ResponsibilityProps,
  );

  const [loadedFilters, setLoadedFilters] = useState(false);
  const [
    loadingDeleteResponsibility,
    setLoadingDeleteResponsibility,
  ] = useState(false);
  const [filters, setFilters] = useState<ResponsibilityFilter>();

  const addToast = useToast();

  const [
    updatingUsersResponsibilityId,
    setUpdatingUsersResponsibilityId,
  ] = useState<number | null>(null);

  const [enableTourResponsibility, setEnableTourResponsibility] = useState(
    false,
  );
  const [stepsResponsibility, setStepsResponsibility] = useState<Step[]>([
    {
      element: '#container',
      title: 'Controle de Cargos',
      intro:
        'Nessa página você acompanha e administra todos os cargos e atribui eles para seus funcionários.',
    },
    {
      element: '#button-create',
      title: 'Criar cargo ao funcionário',
      intro:
        'Aqui você pode criar o cargo que precisa em sua empresa. clique no botão.',
      position: 'left',
    },
    {
      element: '#button-cargo',
      title: 'Atribuir cargo ao funcionário',
      intro:
        'Aqui você pode selecionar os funcionários que deseja para o respectivo cargo. clique no botão.',
    },
  ]);

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Id',
      },
      {
        title: 'Nome',
      },
      {
        title: 'Subordinação',
      },
      {
        title: 'Função no sistema',
      },
      {
        title: 'Usuários',
      },
      {
        title: 'Ações',
        align: 'right',
      },
    ];
  }, []);

  // const [users, setUsers] = useState<UserProps[]>([]);

  useEffect(() => {
    setLoadedResponsibilities(false);

    api
      .get<ResponsibilityProps>('responsibilities', {
        params: { page },
      })
      .then((response) => {
        if (!response.data) return;

        const {
          data: responsibilitiesData,
          current_page,
          to,
          last_page,
          per_page,
          total,
        } = response.data;

        setData({
          current_page,
          to,
          last_page,
          per_page,
          responsibilities: responsibilitiesData || [],
          total,
        });
      })
      .finally(() => {
        setLoadedResponsibilities(true);
      });
  }, [page]);

  useEffect(() => {
    // Adicionar loading
    setLoadedFilters(false);
    api
      .get<ResponsibilityFilter>('responsibilities/filters')
      .then((response) => {
        const { responsibilities, roles } = response.data;

        setFilters({
          responsibilities: responsibilities?.map((resp) => {
            return {
              ...resp,
              label: resp.name,
              value: resp.id,
            };
          }),
          roles: roles?.map((role) => {
            return {
              ...role,
              label: role.name,
              value: role.id,
            };
          }),
        });
      })
      .finally(() => {
        setLoadedFilters(true);
      });
  }, []);

  const handleEditModal = useCallback(
    (dataResponsibility: ResponsibilityInterface) => {
      setToggleSuperiorResponsibility(!!dataResponsibility.subordination_id);
      setModalDataEdit(dataResponsibility);
      onOpenEdit();
    },
    [onOpenEdit],
  );

  const handleSubmitEditResponsibility = useCallback(
    async (dataEdit: StoreEditResponsibility) => {
      try {
        formRefEdit.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          role_id: Yup.string().required('É obrigatório selecionar uma função'),
        });

        await schema.validate(dataEdit, {
          abortEarly: false,
        });

        onCloseEdit();
        setLoadedResponsibilities(false);

        const { name, description, role_id, subordination_id } = dataEdit;

        await api.put(`/responsibilities/${modalDataEdit.id}`, {
          name,
          description,
          role_id,
          subordination_id,
        });

        setData((state) => ({
          ...state,
          responsibilities: state?.responsibilities?.map((responsibility) => {
            return responsibility.id === modalDataEdit.id
              ? {
                  ...responsibility,
                  name,
                  description,
                  role_id,
                  role: filters?.roles?.find(
                    (role) => Number(role.id) === Number(role_id),
                  ),
                  subordination_id,
                  superior_responsibility: subordination_id
                    ? filters?.responsibilities?.find(
                        (resp) => Number(resp.id) === Number(subordination_id),
                      )
                    : undefined,
                }
              : responsibility;
          }),
        }));

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Cargo atualizado!',
          description: `Os dados do cargo ${dataEdit.name} foram atualizados com sucesso!`,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current?.setErrors(errors);

          return;
        }

        const { error } = err.response.data;

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro na atualização',
          description: error,
        });
      } finally {
        setLoadedResponsibilities(true);
      }
    },
    [addToast, modalDataEdit.id, onCloseEdit, filters],
  );

  const formattedUpdatedUsers = (addedUsers: UserProps[]): UserInterface[] => {
    return addedUsers.map((userAdded) => {
      return {
        id: userAdded.id,
        email: userAdded.email,
        name: userAdded.name,
        avatar: userAdded.avatar,
        responsibility_id: userAdded.responsibility_id,
        responsibility: userAdded.responsibility,
      };
    });
  };

  const handleUpdateUsers = useCallback(
    (users: UserProps[], remove?: boolean) => {
      if (!selectedResponsibility) return;

      /* Quando retornava o array toda vez, ao vez de retornar o objeto, a função handleAddUser e a handleDeleteUser
      o estado demorava de atualizar em tela, mesmo tendo finalizado a função
       */

      function handleAddUser(
        userAdded: UserInterface,
        responsibility: ResponsibilityInterface,
      ): ResponsibilityInterface {
        return {
          ...responsibility,
          users: [userAdded, ...(responsibility?.users || [])],
        };
      }

      function handleDeleteUser(
        userDeleted: UserInterface,
        responsibility: ResponsibilityInterface,
      ): ResponsibilityInterface {
        return {
          ...responsibility,
          users: responsibility?.users?.filter(
            (us) => us.id !== userDeleted.id,
          ),
        };
      }

      const usersUpdated = formattedUpdatedUsers(users);

      setUpdatingUsersResponsibilityId(selectedResponsibility.id);

      Promise.all(
        usersUpdated.map(
          async (us): Promise<RefreshUsersResult> => {
            try {
              await api.put(
                `responsibilities/user/${selectedResponsibility.id}`,
                {
                  user_id: us.id,
                },
              );

              return { user: us, success: true };
            } catch (err) {
              addToast({
                position: 'top-right',
                status: 'error',
                title: `Não foi possível ${
                  remove
                    ? `remover o membro ${us.name} do cargo`
                    : `adicionar o membro ${us.name} ao cargo`
                } ${selectedResponsibility.name}`,
                description: 'Ocorreu um erro, tente novamente.',
                isClosable: true,
              });

              return { user: us, success: false };
            }
          },
        ),
      ).then((results) => {
        const successResults = results.filter((res) => res.success);

        successResults.forEach((res) => {
          const { user: userUpdated } = res;

          // console.log(userUpdated);

          setData((state) => ({
            ...state,
            responsibilities: [
              ...state.responsibilities.map((responsibility) => {
                if (responsibility.id === selectedResponsibility.id) {
                  return remove
                    ? handleDeleteUser(userUpdated, responsibility)
                    : handleAddUser(userUpdated, responsibility);
                }
                return responsibility;
              }),
            ],
          }));
        });

        const countSuccess = successResults.length || 0;

        if (countSuccess) {
          addToast({
            position: 'top-right',
            status: 'success',
            title: `${countSuccess} membro(s) ${
              remove ? 'removido(s) do cargo' : 'adicionado(s) ao cargo'
            } ${selectedResponsibility.name}`,
            isClosable: true,
          });
        }

        setUpdatingUsersResponsibilityId(null);
        setSelectedResponsibility(null);
      });
    },
    [addToast, selectedResponsibility],
  );

  const handleOpenModalDelete = useCallback(
    (id: number, name: string) => {
      onOpenDelete();
      setSelectedResponsibilityDelete({ id, name });
    },
    [onOpenDelete],
  );

  const usersModal = useMemo(() => {
    if (!selectedResponsibility) return [];

    return selectedResponsibility.users?.map((us) => {
      return {
        id: us.id,
        name: us.name,
        email: us.email,
        avatar: us?.avatar,
        responsibility_id: selectedResponsibility.id,
        responsibility: selectedResponsibility.name,
      };
    });
  }, [selectedResponsibility]);

  const handleOpenModalUsers = useCallback(
    (responsibility: ResponsibilityInterface) => {
      // console.log(responsibility);
      setSelectedResponsibility(responsibility);
      onOpenUser();
    },
    [onOpenUser],
  );

  const handleSubmitDelete = useCallback(() => {
    if (!selectedResponsibilityDelete) return;

    setLoadingDeleteResponsibility(true);

    api
      .delete(`responsibilities/${selectedResponsibilityDelete.id}`)
      .then(() => {
        setData((state) => ({
          ...state,
          to: state.to - 1,
          total: state.total - 1,
          responsibilities: state?.responsibilities?.filter(
            (responsibility) =>
              responsibility.id !== selectedResponsibilityDelete.id,
          ),
        }));

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Cargo deletado com sucesso!',
          description: `Cargo ${selectedResponsibilityDelete.name} deletado com sucesso!`,
        });
      })
      .catch(() => {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao deletar o cargo!',
          description: `Não foi possível deletar o cargo ${selectedResponsibilityDelete.name}`,
        });
      })
      .finally(() => {
        setLoadingDeleteResponsibility(false);
        setSelectedResponsibilityDelete(null);
        onCloseDelete();
      });
  }, [selectedResponsibilityDelete, addToast, onCloseDelete]);

  const handleSubmit = useCallback(
    async (dataSubmit: StoreEditResponsibility) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          role_id: Yup.string().required('É obrigatório selecionar uma função'),
        });

        await schema.validate(dataSubmit, {
          abortEarly: false,
        });

        setLoadedResponsibilities(false);
        onClose();

        const formattedData = {
          ...dataSubmit,
        };

        const {
          data: dataResponsibility,
        } = await api.post<ResponsibilityInterface>(
          'responsibilities',
          formattedData,
        );

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'success',
          title: 'Cadastro realizado!',
          description: `Adicione as funções e o usuários para o novo cargo ${dataResponsibility.name}!`,
        });

        // Adicionar o cargo subordinado
        const newResponsibility: ResponsibilityInterface = {
          ...dataResponsibility,
          role: filters?.roles?.find(
            (role) => Number(role.id) === Number(dataResponsibility.role_id),
          ),
          superior_responsibility: filters?.responsibilities.find(
            (resp) =>
              Number(resp.id) === Number(dataResponsibility.subordination_id),
          ),
          /* superior_responsibility: dataResponsibility, */
        };

        setData((state) => ({
          ...state,
          to: state.to + 1,
          total: state.total + 1,
          responsibilities: [...state.responsibilities, newResponsibility],
        }));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        const { error } = err.response.data;

        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro no cadastro',
          description:
            error || 'Não foi possível realizar o cadastro, tente novamente!',
        });
      } finally {
        setLoadedResponsibilities(true);
      }
    },
    [addToast, onClose, filters],
  );

  return (
    <>
      <Container id="container">
        <SectionHeader title="Controle de cargos" pagename="Cargos">
          <Flex>
            <Button
              aria-label="help"
              onClick={() => {
                setEnableTourResponsibility(true);
              }}
              variant="outline"
              colorScheme="blue"
              mr={3}
            >
              <FiHelpCircle />
              <Text ml={2}>Ajuda</Text>
            </Button>
            <Button
              id="button-create"
              colorScheme="blue"
              onClick={onOpen}
              isLoading={!loadedFilters}
            >
              <FiPlus size={18} />
              Cadastrar
            </Button>
          </Flex>
        </SectionHeader>

        <Table
          theadData={tableTitles}
          pagination={
            (loadedResponsibilities &&
              !!data?.current_page && {
                current_page: data.current_page,
                last_page: data.last_page,
                per_page: data.per_page,
                to: data.to,
                total: data.total,
              }) ||
            undefined
          }
          newPage={(pg) => setPage(pg)}
          loading={!loadedResponsibilities}
        >
          {loadedResponsibilities &&
            !!data.responsibilities?.length &&
            data?.responsibilities?.map((responsibility) => (
              <Box
                as="tr"
                key={String(responsibility.id)}
                _hover={{ bg: 'gray.50' }}
              >
                <Box as="td" py={1}>
                  {responsibility.id}
                </Box>
                <Box as="td" py={1}>
                  <Tooltip
                    label={responsibility.description}
                    aria-label={responsibility.description}
                    hasArrow
                    placement="right"
                  >
                    <Text display="inline">{responsibility.name}</Text>
                  </Tooltip>
                </Box>
                <Box as="td" py={1}>
                  {responsibility?.superior_responsibility && (
                    <Tag size="sm" colorScheme="cyan">
                      {responsibility.superior_responsibility.name}
                    </Tag>
                  )}
                </Box>
                <Box as="td" py={1}>
                  {responsibility?.role?.name && (
                    <Tag size="sm" colorScheme="cyan">
                      {responsibility.role.name}
                    </Tag>
                  )}
                </Box>

                <Box id="button-cargo" as="td" py={1}>
                  <IconButton
                    aria-label="Usuários"
                    icon={<FiUser />}
                    variant="ghost"
                    bg="purple.50"
                    ml={[0, 'auto']}
                    colorScheme="purple"
                    onClick={() => handleOpenModalUsers(responsibility)}
                    isLoading={
                      !!updatingUsersResponsibilityId &&
                      updatingUsersResponsibilityId === responsibility.id
                    }
                    disabled={!!updatingUsersResponsibilityId}
                  />
                </Box>
                <Box as="td" py={1} className="row" textAlign="right">
                  <IconButton
                    aria-label="Editar"
                    icon={<FiEdit />}
                    variant="ghost"
                    bg="yellow.50"
                    ml={[0, 2]}
                    colorScheme="yellow"
                    onClick={() => handleEditModal(responsibility)}
                  />
                  <IconButton
                    aria-label="Deletar"
                    icon={<FiTrash />}
                    variant="ghost"
                    bg="red.50"
                    ml={[0, 2]}
                    colorScheme="red"
                    onClick={() =>
                      handleOpenModalDelete(
                        responsibility.id,
                        responsibility.name,
                      )
                    }
                  />
                </Box>
              </Box>
            ))}
        </Table>

        {loadedResponsibilities && !data.current_page && (
          <EmptyPage title="Nenhum registro encontrado." />
        )}
      </Container>

      {!!selectedResponsibility && (
        <ModalManageUsers
          mode="responsibility"
          title={selectedResponsibility?.name || ''}
          initialAdded={usersModal}
          isOpen={isOpenUser}
          onClose={(updatedUsers) => {
            if (updatedUsers.removed?.length)
              handleUpdateUsers(updatedUsers.removed, true);

            if (updatedUsers.added?.length)
              handleUpdateUsers(updatedUsers.added);

            onCloseUser();
          }}
        />
      )}

      <ModalChakra
        title="Novo cargo"
        onClose={onClose}
        isOpen={isOpen}
        titleButtonCancel="Cancelar"
        onSubmit={() => formRef.current?.submitForm()}
        isLoading={loading}
        size="2xl"
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputChakra
            label="Nome"
            name="name"
            placeholder="Nome do cargo"
            autoFocus
          />
          <InputChakra
            label="Descrição"
            name="description"
            placeholder="Descrição resumida"
          />

          {filters && (
            <>
              <Flex alignItems="center" mb={2}>
                <FormLabel htmlFor="setSuperiorResponsibility">
                  Definir um cargo superior?
                </FormLabel>
                <Switch
                  id="setSuperiorResponsibility"
                  onChange={() =>
                    setToggleSuperiorResponsibility(
                      !toggleSuperiorResponsibility,
                    )
                  }
                  defaultIsChecked={toggleSuperiorResponsibility}
                  size="sm"
                />
              </Flex>
              {!!filters.responsibilities?.length &&
                toggleSuperiorResponsibility && (
                  <SelectChakra
                    name="subordination_id"
                    label="Cargo superior"
                    placeholder="Selecione o cargo superior"
                    options={filters.responsibilities}
                    /* onChange={(el) =>
                      setSelectedSuperiorResponsibility(
                        data?.responsibilities.find(
                          (resp) => Number(el.target.value) === resp.id,
                        ),
                      )
                    } */
                  />
                )}

              {!!filters.roles?.length && (
                <SelectChakra
                  name="role_id"
                  label="Função"
                  placeholder="Selecione uma função do sistema"
                  options={filters.roles}
                />
              )}
            </>
          )}
        </Form>
      </ModalChakra>

      <ModalChakra
        title="Atualizar cargo"
        onClose={() => {
          onCloseEdit();
          setToggleSuperiorResponsibility(false);
        }}
        isOpen={isOpenEdit}
        titleButtonCancel="Cancelar"
        onSubmit={() => formRefEdit.current?.submitForm()}
        isLoading={loading}
        size="2xl"
      >
        <Form
          ref={formRefEdit}
          onSubmit={handleSubmitEditResponsibility}
          initialData={modalDataEdit}
        >
          <InputChakra
            label="Nome"
            name="name"
            placeholder="Nome do cargo"
            autoFocus
          />
          <InputChakra
            label="Descrição"
            name="description"
            placeholder="Descrição resumida"
          />
          {filters && (
            <>
              <Flex alignItems="center" mb={2}>
                <FormLabel htmlFor="setSuperiorResponsibility">
                  Definir um cargo superior?
                </FormLabel>
                <Switch
                  id="setSuperiorResponsibility"
                  onChange={() =>
                    setToggleSuperiorResponsibility(
                      !toggleSuperiorResponsibility,
                    )
                  }
                  defaultIsChecked={!!modalDataEdit?.subordination_id}
                  size="sm"
                />
              </Flex>

              {!!filters.responsibilities?.length &&
                toggleSuperiorResponsibility && (
                  <SelectChakra
                    name="subordination_id"
                    label="Cargo superior"
                    placeholder="Selecione o cargo superior"
                    options={filters.responsibilities}
                  />
                )}

              {!!filters.roles?.length && (
                <SelectChakra
                  name="role_id"
                  label="Função"
                  placeholder="Selecione uma função do sistema"
                  options={filters.roles}
                />
              )}
            </>
          )}
        </Form>
      </ModalChakra>

      <AlertDialog
        title="Deletar cargo?"
        description="Tem certeza que deseja deletar o cargo selecionado?"
        isOpen={isOpenDelete}
        isLoading={loadingDeleteResponsibility}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDelete}
        onSubmit={handleSubmitDelete}
      />
      <StepsIntro
        enabled={loadedFilters && enableTourResponsibility}
        onExit={() => setEnableTourResponsibility(false)}
        steps={stepsResponsibility}
      />
    </>
  );
};

export default Responsibility;
