import { Link } from 'react-router-dom';

import { Box, Progress, ProgressLabel } from '@chakra-ui/react';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface AttedanceProps {
  background: string;
  borderColor: string;
}

interface ProgressProps {
  value: number;
}

export const CardBox = styled(Box)<AttedanceProps>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background: rgb(238, 245, 255);
  min-width: 300px;
  height: 200px;
  color: ${({ theme }) => theme.colors.text};

  border-bottom: 4px solid transparent;
  transition: all 0.2s;

  &:hover {
    border-color: ${darken(0.05, 'rgb(238, 245, 255)')};
  }

  @media (min-width: 1600px) {
    max-width: 700px;
  }
  ${(props) =>
    props.background &&
    css`
      background: ${props.background} !important;
      border-color: ${props.borderColor} !important;
    `}}
`;
