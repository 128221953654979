import React, { useCallback } from 'react';
import { FiCheck, FiClock, FiCircle, FiX } from 'react-icons/fi';

import { Box, Text, Flex, IconButton } from '@chakra-ui/react';

import { CardBox } from './styles';

interface Props {
  color: string;
  title: string;
  total: string;
  description: string;
}
const CardAttendance: React.FC<Props> = ({
  color,
  description,
  title,
  total,
}) => {
  const iconCard = useCallback((colorCard: string): any => {
    switch (colorCard) {
      case 'orange':
        return <FiCircle />;
      case 'blue':
        return <FiClock />;
      case 'green':
        return <FiCheck />;
      case 'red':
        return <FiX />;

      default:
        return null;
    }
  }, []);

  return (
    <Box>
      <CardBox background={color} borderColor={color}>
        <Text fontSize="20px" color="#3f373b" fontWeight="500" mb="25px">
          {`Atendimento ${title}`}
        </Text>
        <Flex>
          <Text color="#53555f" fontSize="40px" fontWeight="bold">
            {total}
          </Text>
        </Flex>
        <Text color="#9197a0" fontSize="14px" fontWeight="400">
          {description}
        </Text>
      </CardBox>
    </Box>
  );
};

export default CardAttendance;
